import React, { useEffect, useRef } from 'react';
import FloatingLabelInput from '../common/components/FloatingLabelInput';

let autoComplete;

export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";


  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName('head')[0].appendChild(script);
};

const handleScriptLoad = (updateQuery, autoCompleteRef) => {
  autoComplete = new window.google.maps.places.Autocomplete(
    autoCompleteRef.current,
    { types: [], componentRestrictions: { country: 'us' } },
  );
  autoComplete.setFields(['address_components', 'formatted_address']);
  autoComplete.addListener('place_changed', (event) => {
    handlePlaceSelect(updateQuery);
  });
};

async function handlePlaceSelect(updateQuery) {
  const addressObject = autoComplete.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
}

const GoogleAutocomplete = ({
  dataTestHook,
  id,
  input,
  label,
  name,
  onChange,
  onEnter,
}) => {
  const autoCompleteRef = useRef(null);

  useEffect(() => {
    if (google != undefined) {
      handleScriptLoad(onChange, autoCompleteRef);
    } else {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`,
        () => handleScriptLoad(onChange, autoCompleteRef),
      );
    }
  }, []);

  return (
    <div className="google-autocomplete-input">
      <FloatingLabelInput
        id={id}
        dataTestHook={dataTestHook}
        inputRef={autoCompleteRef}
        onChange={(event) => onChange(event.target.value)}
        label={label}
        name={name}
        prependIcon="map-marker-alt"
        value={input}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnter()
          }
        }}
      />
    </div>
  );
};

export const useAutocomplete = (inputRef) => {
  useEffect(() => {
    if (inputRef) {
      const autocomplete = new google.maps.places.Autocomplete(
        inputRef.current,
      );

      const changeListener = autocomplete.addListener(
        'place_changed',
        function () {
          const place = autocomplete.getPlace();
          const address = place.formatted_address;

          var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value',
          ).set;
          nativeInputValueSetter.call(inputRef.current, address);

          inputRef.current.dispatchEvent(
            new Event('change', { bubbles: true }),
          );
        },
      );

      return () => google.maps.event.removeListener(changeListener);
    }
  }, [inputRef]);
};

export default GoogleAutocomplete;
