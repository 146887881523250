import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import BarcodeScan from '../../common/components/Icons/BarcodeScan';
import BarcodeScanner from '../../common/components/BarcodeScanner';
import ModalHeader from '../../common/components/ModalHeader';
import { LocationContextProvider } from '../../LocationSelector/components/LocationContext';
import LocationContext from '../../LocationSelector/context/LocationContext';
import Select from '../../common/components/Select';

// NOTE: Route should look like
// /test_groups/cdhp/test_group_users?qc_test_type=rapid_antigen_abbott_covid19&qc_access_code=6B484DCA759BB982
const getQuickCreateParams = (searchParams) => {
  const accessCode = searchParams.get('qc_access_code');
  const testType = searchParams.get('qc_test_type');
  const selectedLocation = searchParams.get('qc_location');

  return {
    accessCode,
    testType,
    selectedLocation,
  };
};

const ScanModalContent = ({ onHide, onScan }) => (
  <React.Fragment>
    <ModalHeader closeButton onHide={onHide}>
      <h2 className="modal-title">Save by barcode</h2>
    </ModalHeader>
    <Modal.Body>
      <BarcodeScanner containerClassName="w-75" onScan={onScan} />
    </Modal.Body>
  </React.Fragment>
);

const SelectLocationModalContent = ({
  onHide,
  selectedLocation: propSelectedLocation = null,
  setSelectedLocation: propSetSelectedLocation,
  availableLocations,
}) => {
  const [selectedLocation, setSelectedLocation] =
    useState(propSelectedLocation);

  return (
    <React.Fragment>
      <ModalHeader closeButton onHide={onHide}>
        <h2 className="modal-title">Select Location</h2>
      </ModalHeader>
      <Modal.Body>
        Available Locations
        <Select
          value={selectedLocation}
          onChange={setSelectedLocation}
          options={availableLocations}
          valueKey="id"
          labelKey="title"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          disabled={!selectedLocation}
          onClick={() => propSetSelectedLocation(selectedLocation)}
        >
          Proceed to Checkout
        </Button>
      </Modal.Footer>
    </React.Fragment>
  );
};

const ScanModal = ({
  show,
  onHide,
  useLocationContext,
  quickCreateParams,
  setQuickCreateParams,
  availableLocations,
}) => {
  const { accessCode, testType, selectedLocation } = quickCreateParams;
  const { selectedLocation: contextSelectedLocation } =
    useContext(LocationContext);

  const setSelectedLocation = (locationId) =>
    setQuickCreateParams({
      ...quickCreateParams,
      selectedLocation: locationId,
    });

  const handleScan = (quickCreateUrl) =>
    setQuickCreateParams({
      ...quickCreateParams,
      ...getQuickCreateParams(new URL(quickCreateUrl).searchParams),
    });

  useEffect(() => {
    if (useLocationContext) setSelectedLocation(contextSelectedLocation);
  }, []);

  return (
    <Modal show={show} onHide={onHide} autoFocus={true}>
      {!(accessCode && testType) ? (
        <ScanModalContent onHide={onHide} onScan={handleScan} />
      ) : !selectedLocation ? (
        <SelectLocationModalContent
          onHide={onHide}
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          availableLocations={availableLocations}
        />
      ) : (
        <React.Fragment>
          <ModalHeader>
            <h2 className="modal-title">Redirecting...</h2>
          </ModalHeader>
          <Modal.Body>You are being redirected.</Modal.Body>
        </React.Fragment>
      )}
    </Modal>
  );
};

const QuickAppointmentCreateScan = ({
  checkout_path: checkoutPath,
  use_location_context: useLocationContext = false,
  locations: availableLocations,
}) => {
  const [show, setShow] = useState(false);
  const [quickCreateParams, setQuickCreateParams] = useState(
    getQuickCreateParams(new URLSearchParams(window.location.search)),
  );

  useEffect(() => {
    const { accessCode, testType, selectedLocation } = quickCreateParams;
    // redirect to quick checkout route
    if (accessCode && testType && selectedLocation)
      window.location.href = `${checkoutPath}?test_type=${testType}&access_code=${accessCode}&appointment_slot_group_id=${selectedLocation}`;
  }, [quickCreateParams]);

  // Open modal if quick create params detected
  useEffect(() => {
    const { accessCode, testType } = quickCreateParams;
    accessCode && testType && setShow(true);
  }, []);

  return (
    <React.Fragment>
      {show && (
        <LocationContextProvider>
          <ScanModal
            show={show}
            onHide={() => setShow(false)}
            useLocationContext={useLocationContext}
            quickCreateParams={quickCreateParams}
            setQuickCreateParams={setQuickCreateParams}
            availableLocations={availableLocations}
          />
        </LocationContextProvider>
      )}
      <Button
        variant="outline-tertiary"
        size="sm"
        onClick={() => setShow(true)}
      >
        <span className="me-2">Scan QR Code</span>
        <BarcodeScan />
      </Button>
    </React.Fragment>
  );
};

export default QuickAppointmentCreateScan;
