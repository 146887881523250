export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "လါ",
    "day": "နံၤ",
    "year": "နံၣ်",
    "january": "လါယနူၤအါရံၤ",
    "february": "လါဖ့ၣ်ဘြူၤအါရံၤ",
    "march": "လါမာ်ရှး",
    "april": "လါအ့ဖြ့ၣ်",
    "may": "လါမ့ၤ",
    "june": "လါယူၤ",
    "july": "လါယူၤလံ",
    "august": "လါအီးကူာ်",
    "september": "လါစံးပတ့ဘၢၣ်",
    "october": "လါအီးကထိဘၢၣ်",
    "november": "လါနိၣ်၀့ဘၢၣ်",
    "december": "လါဒံၣ်စ့ဘၢၣ်"
  },
  "user": {
    "send_appointment_confirmation_message": "နတၢ်သ့ၣ်ဆၢကတီၢ်ဘၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလီၤလၢ {{name}} အဂီၢ်လံန့ၣ်လီၤႋ",
    "landline_appointment_reminder_message": "ဟဲလိၣ်, {{full_name}} တၢ်အံၤမ့ၢ်တၢ်ဒုးသ့ၣ်နီၣ်ထီၣ်က့ၤနတၢ်သ့ၣ်ဆၢကတီၢ်ဖဲ {{name}} လီၤႋ နတၢ်သ့ၣ်ဆၢကတီၢ်ဘၣ်လၢ {{date}} ဖဲ {{time}} ဖဲ {{address}} လီၤႋ",
    "send_at_home_visit_confirmation_message": "နတၢ်သ့ၣ်ဆၢကတီၢ်ဘၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလီၤလၢ {{date}} ဖဲ {{time}} အလီၢ်ခံန့ၣ်လီၤႋ",
    "send_mail_order_confirmation_message": "နတၢ်မၤလိာ်တၢ်အဂ့ၢ်ဘၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလံ",
    "send_waitlist_message": "တၢ်ထၢနုာ်လီၤတမံၤလၢတၢ်အိၣ်ခိးစရီပူၤလၢ {{name}}လီၤႋ ပမ့ၢ်တပာ်ဂၢၢ်ပာ်ကျၢၤလီၤတၢ်သ့ၣ်ဆၢကတီၢ်အဆၢကတီၢ်ဒံးဘၣ်န့ၣ် တဘၣ်ဟဲထီၣ်ဒံးတဂ့ၤႋ",
    "verify_contact": {
      "phone": "၀ံသးစူၤပာ်ဂၢၢ်ပာ်ကျၢၤနလီတဲစိနီၣ်ဂံၢ်ခီဖျိတၢ်စံၢ်လီၤ- {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "ဆဲးမၤတၢ်ဆဲးလီၤမံၤလၢတၢ်သ့ၣ်ဆၢကတီၢ်အဂီၢ်ဖဲအံၤ- {{url}}",
    "on_demand_confirmation_message": "နဆဲးလီၤမံၤလၢ {{name}} အဂီၢ်၀ံၤလၢလၢပှဲၤပှဲၤလံန့ၣ်လီၤႋ",
    "appointment_reminder": "တချုးနတၢ်သ့ၣ်ဆၢကတီၢ်လၢ {{start_time}} န့ၣ် ၀ံသးစူၤ စံၢ်လီၤပှာ်ဘျးစဲလၢကမၤပှဲၤဘၣ် တၢ်ဖံးတၢ်မၤအလီၤဆီထီရီၤတမံၤ ဒီးကွၢ်သမံသမိးဘၣ်နတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်ဂ့ၢ်တက့ၢ်ႋ ၀ံသးစူၤ တဘၣ်ဟဲတုၤဆိန့ၢ် 15 မံးနံးတချုးနတၢ်သ့ၣ်ဆၢကတီၢ်တဂ့ၤႋ",
    "appointment_reminder_base": "{{text}} စံၢ်လီၤဖဲအံၤ- {{link}}",
    "test_result_notifier": {
      "text_message": "ပှၤဆါအဖီထၢၣ်လၢ {{first_name_with_last_initial}} လၢတၢ်မၤသီထီၣ်. Go to {{url}}\nဒီးစူးကါနီၣ်ဂံၢ်ခူသူၣ်-{{access_code}}",
      "email_subject": "တၢ်ဂ့ၢ်အသီလၢနပှၤဆါအဖီးထၢၣ်",
      "email_intro": "ဟဲလိၣ် {{first_name_with_last_initial}}",
      "email_p1": "တၢ်ကစီၣ်အသီ မ့တမ့ၢ် ပှၤဆါအဂ့ၢ်အသီ အိၣ်လၢနဂီၢ်ႋ",
      "email_p2": "စံၢ်လီၤပနီၣ်လၢလာ် မ့တမ့ၢ် စူးကါပှာ်ဘျးစဲလၢကကွၢ် နပှၤဆါအဖီးထၢၣ်ဒီးစူးကါနီၣ်ဂံၢ်ခူသူၣ်",
      "email_p3": "ကွၢ်ပှၤဆါအဖီးထၢၣ်ဖဲ",
      "email_button": "ကွၢ်ဖီးထၢၣ်"
    },
    "landline_appointment_reminder_message_without_time": "ဟဲလိၣ်, {{full_name}} တၢ်အံၤမ့ၢ်တၢ်ဒုးသ့ၣ်နီၣ်ထီၣ်က့ၤနတၢ်သ့ၣ်ဆၢကတီၢ်ဖဲ {{name}} လီၤႋ နတၢ်သ့ၣ်ဆၢကတီၢ်ဘၣ်လၢ  {{date}} ဖဲ {{address}} လီၤႋ",
    "appointment_reminder_on_demand": "တချုးနတၢ်သ့ၣ်ဆၢကတီၢ်ဖဲ {{date}} န့ၣ် ၀ံသးစူၤ စံၢ်လီၤပှာ်ဘျးစဲလၢကမၤပှဲၤဘၣ် တၢ်ဖံးတၢ်မၤအလီၤဆီထီရီၤတမံၤ ဒီးကွၢ်သမံသမိးဘၣ်နတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်ဂ့ၢ်တက့ၢ်ႋ",
    "new_test_result_notifier": {
      "email_intro": "နပှၤဆါအဖီးထၢၣ်ဘၣ်တၢ်မၤသီထီၣ်ႋ",
      "email_text_1": "နုာ်လီၤကွၢ် နဆူၣ်ချ့ယါဘျါဂ့ၢ်စံၣ်စိၤ အဖီးထၢၣ်လၢလာ်လၢကထံၣ်ဘၣ် ပှၤဆါအတၢ်မၤနီၣ်မၤဃါ မ့တမ့ၢ် တၢ်မၤကွၢ်တၢ်ဆါဃၢ် အတၢ်ပာ်ဖျါလၢအသီတမံၤလၢ်လၢ်တက့ၢ်ႋ",
      "email_button": "ဆူၣ်ချ့ဂ့ၢ်စံၣ်စိၤ",
      "email_text_2": "နကွဲးဒိဒီးပာ်လီၤ URL အံၤလၢနပှာ်ယဲၤသန့ထံးသ့န့ၣ်လီၤႋ"
    },
    "mailers": {
      "email_contact_us": "တၢ်သံကွၢ်အိၣ်ဧါႋ ဆဲးကျၢပှၤဖဲ"
    }
  },
  "errors": {
    "generic": {
      "message": "တၢ်တဘၣ်လီၢ်ဘၣ်စးတမံၤမံၤ ၀ံသးစူၤမၤလၢအသီတဘျီ"
    },
    "messages": {
      "blank": "ပျဲအိၣ်လီၤဟိတသ့"
    },
    "incorrect_credentials": "Your credentials were incorrect, please try again.",
    "error_activating_test_kit": "There was an error activating the test kit."
  },
  "type": "ဒိလီၤ",
  "payment": {
    "continue_label": "လဲၤဆူတၢ်ဟ့ၣ်ဘူးလဲ",
    "policy_text": "နမ့ၢ်ဆိကတီၢ်ကွံာ် မ့တမ့ၢ် တလဲၤထီၣ်တၢ်သ့ၣ်ဆၢကတီၢ်န့ၣ် ပကဟ့ၣ်က့ၤနတၢ်ဟ့ၣ်ဘူးလဲ 80% 7 သီဖဲနတၢ်သ့ၣ်ဆၢကတီၢ်အနံၤသီ၀ံၤလီၢ်ခံန့ၣ်လီၤႋ နကွၢ်ဘၣ် (တၢ်ဖံးတၢ်မၤအတၢ်သိၣ်တၢ်သီ) အလၢပှဲၤ ({{link}}) သ့",
    "card_number": "ခးနီၣ်ဂံၢ်",
    "expiry_date": "နံၤသီကတၢၢ်",
    "pay_button": "ဟ့ၣ်စ့",
    "no_credit_card": "ယကဟ့ၣ်တၢ်အဘူးလဲလၢတၢ်မၤအလီၢ်",
    "cash_payment_helper": "၀ံသးစူၤမၤလီၤတံၢ်လၢကဟဲစိာ်ဘၣ် **{{payment}}** ဆူနတၢ်သ့ၣ်ဆၢကတီၢ်",
    "invoice": "လံာ်ဃ့စ့",
    "pay_by_card": "ဟ့ၣ်စ့ခီဖျိခး",
    "cost": "တၢ်ဘူးတၢ်လဲ",
    "total": "ခဲလၢာ်",
    "pay_cash_at_appointment": "ယကဟ့ၣ်တၢ်ဘူးလဲလၢစ့လဲဖဲယတၢ်သ့ၣ်ဆၢကတီၢ်ဖဲ {{appointment}}",
    "view_invoice": "ကွၢ်/စဲးကျံးထီၣ်လံာ်ဃ့စ့",
    "refund_policy": "နမ့ၢ်ဆိကတီၢ်ကွံာ်နတၢ်သ့ၣ်ဆၢကတီၢ် ပကဟ့ၣ်ဘၣ်က့ၤနတၢ်ဟ့ၣ်တၢ်အဘူးလဲ {{amount}} လၢ 7 သီဖဲနတၢ်သ့ၣ်ဆၢကတီၢ်အနံၤသီန့ၣ်လီၤႋ နကွၢ်ဘၣ်တၢ်ဂ့ၢ်အလၢပှဲၤ [တၢ်ဖံးတၢ်မၤအတၢ်သိၣ်တၢ်သီတဖၣ်]({{link}})",
    "refund_window_passed": "နတၢ်သ့ၣ်ဆၢကတီၢ်တကြၢး၀ဲဘၣ်၀ဲလၢတၢ်ဟ့ၣ်က့ၤကျိၣ်စ့ မ့ၢ်လၢတၢ်သ့ၣ်ဆၢကတီၢ်အနံၤသီလဲၤတလၢကွံာ်အဃိန့ၣ်လီၤႋ ၀ံသးစူၤဆဲးကျၢတၢ်မၤလီၢ်မူဒါခိၣ်လၢတၢ်ကျဲၤဘၣ်လိာ်ဖိးဒ့က့ၤတၢ်ဂ့ၢ်အဂီၢ်တက့ၢ်ႋ",
    "amount_paid": "စ့နီၣ်ဂံၢ်",
    "balance": "စ့အိၣ်တ့ၢ်"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "တီၣ်ထီၣ်တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးတၢ်ဆိကတီၢ်ခွဲး မ့တမ့ၢ် ဆဲးလီၤမံၤဖဲတၢ်ဆဲးလီၤမံၤကွီၢ်ပူၤလၢလာ်",
    "stop": "တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးအလီၢ်အိၣ်လၢတၢ်မၤပှဲၤတၢ်ဆဲးလီၤမံၤအဂီၢ်",
    "parent_or_guardian_required_html": "ဒ်သိးကမၤ၀ံၤလၢပှဲၤကွံာ်နတၢ်ဆဲးလီၤမံၤအဂီၢ် နမိၢ်ပၢ် ပှၤကွၢ်ထွဲတၢ် မတမ့ၢ် ပှၤဆၢတဲာ်တၢ်လၢတၢ်ဟ့ၣ်ခွဲးစိကမီၤ ကဘၣ်အၢၣ်လီၤတူၢ်လိာ်တၢ်ဂ့ၢ်လၢ ကွီၣ်ဒိလၢလာ်ဒီးဆဲးလီၤအမံၤန့ၣ်လီၤႋ နအၢၣ်လီၤတူၢ်လိာ်တၢ်ဖံးတၢ်မၤအံၤအဂ့ၢ်လၢနနီၢ်ကစၢ်ဒၣ်နဲ <b>တသ့</b>",
    "send_parent_or_guardian": "နဆှၢန့ၢ်ပှာ်ဘျးစဲအံၤ ဆူနမိၢ်ပၢ် မ့တမ့ၢ် ပှၤကွၢ်ထွဲတၢ် မ့တမ့ၢ် မၢအ၀ဲသ့ၣ်ဆဲးလီၤမံၤလၢကဘျံးပၤအံၤဖီလာ်သ့န့ၣ်လီၤႋ",
    "consent_guardian_name_signature": "မံၤခီၣ်ထံး",
    "consent_for_minor": "ဆဲးလီၤမံၤတၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးလၢပှၤသးစၢ်အဂီၢ်",
    "guardian_signature": "မိၢ်ပၢ် ပှၤကွၢ်ထွဲတၢ် မ့တမ့ၢ် ပှၤဆၢတဲာ်ဆူၣ်ချ့ဂ့ၢ်၀ီဒိးန့ၢ်ခွဲးဖိးသဲစး ဆဲးလီၤမံၤ",
    "after_you_schedule": "ပှၤပၣ်ဃုာ်မၤသကိးတၢ်အသးနံၣ် {{age}} ဆူဖီလာ် ကဘၣ်အိၣ်ဒီးတၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးလၢအတၢ်ကဲဘျုးအဂီၢ်ခီဖျိ မိၢ်ပၢ် မ့တမ့ၢ် ပှၤကွၢ်ထွဲတၢ်တဂၤန့ၣ်လီၤႋ ၀ံသးစူၤ ဆဲးမၤကတၢၢ်ကွံာ်တၢ်ဆဲးလီၤမံၤ လၢကဆှၢဘၣ်အီၤဆူ နပှၤကွၢ်ထွဲတၢ် မ့တမ့ၢ် ဆဲးလီၤမံၤလၢနပှၤဒိးသန့ၤသးလၢနၤတက့ၢ်ႋ",
    "consent_guardian_last_name_signature": "မံၤလီၤစၢၤ",
    "consent_first_and_last_name": "မံၤခီၣ်ထံးဒီးမံၤလီၤစၢၤ",
    "phone_number": "ပှၤကွၢ်ထွဲတၢ်အလီတဲစိနီၣ်ဂံၢ်",
    "email": "ပှၤကွၢ်ထွဲတၢ်အလီပရၢ",
    "consent_registrar_with_guardian_name_signature": "ပှၤကွၢ်ထွဲတၢ်အမံၤခီၣ်ထံး",
    "consent_registrar_with_guardian_last_name_signature": "ပှၤကွၢ်ထွဲတၢ်အမံၤကတၢၢ်",
    "consent_registrar_first_name_signature": "ပှၤမၤနီၣ်မၤဃါတူၢ်လိာ်တၢ်ဆဲးလီၤမံၤအမံၤခီၣ်ထံး",
    "consent_registrar_last_name_signature": "ပှၤမၤနီၣ်မၤဃါတူၢ်လိာ်တၢ်ဆဲးလီၤမံၤအမံၤကတၢၢ်",
    "consent_on_behalf": "ယသး၀ံၣ်တၢ်လၢ {{first_name}} {{last_name}}အခၢၣ်စး.",
    "consent_on_behalf_registrar": "ယဘၣ်တၢ်ဟ့ၣ်စိဟ့ၣ်ကမီၤယၤလၢထးခိၣ်လၢ {{first_name}} {{last_name}} ကဆဲးလီၤမံၤလၢအ၀ဲသ့ၣ်အခၢၣ်စးလီၤ.",
    "consent_on_behalf_registrar_with_guardian": "ယဘၣ်တၢ်ဟ့ၣ်စိဟ့ၣ်ကမီၤယၤလၢ {{first_name}} {{last_name}} အပှၤကွၢ်ထွဲတၢ်လၢကဆဲးလီၤမံၤလၢအ၀ဲသ့ၣ်အခၢၣ်စးလီၤ.",
    "registrar_phone": "ပှၤဆဲးလီၤမံၤအလီတဲစိနီၣ်ဂံၢ်",
    "registrar_email": "ပှၤဆဲးလီၤမံၤအလီပရၢ",
    "consented_by_parent_guardian": "မိၢ်ပၢ် / ပှၤကွၢ်ထွဲတၢ်",
    "consented_by_decision_maker": "ပှၤဆၢတဲာ်ဆူၣ်ချ့ဂ့ၢ်၀ီလၢတၢ်ဟ့ၣ်ခွဲးစိကမီၤ",
    "consented_by_guardian_verbal": "ပှၤဆဲးလီၤမံၤဃုာ်ဒီးပှၤကွၢ်ထွဲတၢ် (တၢ်ဟ့ၣ်ခွဲးလၢတၢ်ကတိၤ)",
    "consented_by_registrar": "ပှၤဆဲးလီၤမံၤ (တၢ်ဟ့ၣ်ခွဲးလၢတၢ်ကတိၤ)",
    "full_name": "မံၤအပှဲၤ",
    "print": "Print"
  },
  "registration": {
    "contact_information": "မံၤဒီးလီၢ်အိၣ်ဆိးထံး",
    "contact_information_additional": "မိၢ်ပၢ် မ့တမ့ၢ် ပှၤကွၢ်ထွဲတၢ် အတၢ်ဂ့ၢ်တၢ်ကျိၤလၢ တၢ်ဆဲးကျၢဆဲးကျိးတၢ်သ့ၣ်ဆၢကတီၢ်ဒီးတၢ်အစၢအဂီၢ်",
    "personal_information": "ပှၤဂ့ၢ်၀ီတၢ်အိၣ်ဖျဲၣ်စရီတၢ်ဂ့ၢတၢ်ကျိၤ",
    "address_placeholder": "၀ံသးစူၤကွဲးလီၤနဟံၣ်လီၢ်အိၣ်ဆိးထံး",
    "first_name_label": "မံၤခီၣ်ထံး",
    "last_name_label": "မံၤလီၤစၢၤ",
    "errors": {
      "state": "ကီၢ်စဲၣ်ကဘၣ်မ့ၢ် ကီၢ်စဲၣ်နီၣ်ဂံၢ် 2 ဖျၢၣ်လၢဖိးသဲစး",
      "phone_number_invalid": "လီတဲစိနီၣ်ဂံၢ်",
      "required": "တၢ်အလုၢ်ပှ့ၤအံၤမ့ၢ်တၢ်လၢအလီၢ်အိၣ်",
      "email_domain": "နတၢ်ဂ့ၢ်တကဲထီၣ်- ၀ံသးစူၤသမံသမိးကွၢ်လီၤတံၢ်ကဒါက့ၤတက့ၢ်ႋ တၢ်ဆဲးလီၤမံၤ ဘၣ်တၢ်ပာ်ပနီၣ်အီၤလၢ ပှၤနုာ်လီၤပာ်ဃုာ်မၤသကိးတၢ်တဖၣ်အဂီၢ်ခီဖျိ တၢ်ကရၢကရိဆီၣ်ထွဲမၤစၢၤတၢ်န့ၣ်လီၤႋ ၀ံသးစူၤစူးကါကွၢ်ဘၣ် နတၢ်မၤလီၢ်လီပရၢမံၤ မ့တမ့ၢ် ပှၤတ၀ၢပှၤလီပရၢမံၤတက့ၢ်ႋ နမ့ၢ်သ့ၣ်ညါလၢအိၣ်ဒီးခွဲးဒီးတၢ်ကမၣ်အိၣ်ဖဲန့ၣ် ၀ံသးစူၤဆဲးကျၢတၢ်ဆီၣ်ထွဲမၤစၢၤသနၢၣ်တက့ၢ်ႋ",
      "age_requirement": "အိၣ်ဖျဲၣ်နံၤသီတဘၣ်လိာ်ဒီးတၢ်ပာ်ပနီၣ်",
      "signature_required": "အလီၢ်အိၣ်လၢဘၣ်ဆဲးလီၤမံၤ",
      "regex_invalid": "တၢ်အလုၢ်ပှ့ၤအံၤဖျါလၢတဘၣ်လိာ်ဘၣ်စးလီၤႋ",
      "date_invalid": "နံၤသီအံၤတအိၣ်ဘၣ်",
      "invalid_entry": "၀ံသးစူၤဆဲးမၤတၢ်ဆဲးလီၤမံၤဆူညါပတီၢ် လူၤပိာ်မၤထွဲတၢ်နဲၣ်လီၤလၢခီၣ်ဖၠူထၢၣ်မဲာ်သၣ် မ့တမ့ၢ် ဆဲးကျၢနပှၤဘၣ်ထွဲတက့ၢ်ႋ",
      "city": "ဘၣ်မ့ၢ်၀့ၢ်လၢဖိးသဲစး",
      "survey_unanswered": "၀ံသးစူၤ မၤနီၣ်တၢ်သံကွၢ်အလီၢ်အိၣ်ခဲလၢာ်တဖၣ် လၢတၢ်မၤနီၣ်အီၤဒီး * လၢတၢ်စံးဆၢဘၣ်အီၤတက့ၢ်ႋ",
      "postal_code": "ကဘၣ်မ့ၢ်လံာ်တၢးနီၣ်ဂံၢ် 5 ဖျၢၣ်လၢဖိးသဲစး",
      "field_required": "{{field}} ဘၣ်အိၣ်ဒီးအလုၢ်ပှ့ၤတမံၤ",
      "option_required": "နဘၣ်ဃုထၢတၢ်ဃုထၢတခါ",
      "year_length_invalid": "ထၢနုာ်လီၤအိၣ်ဖျဲၣ်နံၤသီဃုာ်ဒီးနီၣ်ဂံၢ်ဖျၢၣ်လွံၢ်ဖျၢၣ် (yyyy)",
      "invalid_day": "နံၤသီကဘၣ်အိၣ်လၢ 1-31 အဘၢၣ်စၢၤ",
      "reached_max_chars": "နတုၤထီၣ်ဒီး လံာ်ဖျၢၣ်နီၣ်ဂံၢ်အအါကတၢၢ်လီၤ.",
      "chars_max": "လံာ်ဖျၢၣ်အအါကတၢၢ်",
      "minimum_length": "လံာ်ကျိၤအထီကဘၣ်အိၣ်အစှၤကတၢၢ် {{length}} ဖျၢၣ်န့ၣ်လီၤႋ"
    },
    "insurance_status": {
      "question": "နဆူၣ်ချ့တၢ်အုၣ်ကီၤအိၣ်ဧါႋ",
      "have_health_insurance": "မ့ၢ် ယအိၣ်ဒီးဆူၣ်ချ့တၢ်အုၣ်ကီၤ",
      "do_not_have_health_insurance": "တမ့ၢ် ယဆူၣ်ချ့တၢ်အုၣ်ကီၤတအိၣ်"
    },
    "insurance_policy_holder": {
      "question": "ပှၤဟံးန့ၢ်ကျဲသနူ မ့ၢ်မတၤလဲၣ်ႋ",
      "i_am": "မ့ၢ်ယၤ",
      "my_spouse": "ယပှၤဒီမါ၀ၤ မ့တမ့ၢ် တၢ်အဲၣ်တီ",
      "my_parents": "ယမိၢ်ပၢ်",
      "someone_else": "ပှၤဂုၤဂၤ",
      "policy_first_name": "ပှၤဟံးန့ၢ်ကျဲသနူ အမံၤခီၣ်ထံး",
      "policy_last_name": "ပှၤဟံးန့ၢ်ကျဲသနူ အမံၤလီၤစၢၤ",
      "policy_dob": "ပှၤဟံးန့ၢ်ကျဲသနူ အတၢ်အိၣ်ဖျဲၣ်နံၤသီ",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "တၢ်အုၣ်ကီၤအတၢ်ဂ့ၢ်တၢ်ကျိၤ",
      "company_name": "တၢ်အုၣ်ကီၤအခီပနံာ်မံၤ",
      "id_number": "ကရၢဖိခိၣ်ဂီၤနီၣ်ဂံၢ်",
      "group_number": "ကရၢဖိနီၣ်ဂံၢ်",
      "secondary_insurance_label": "ယအိၣ်ဒီးတၢ်အုၣ်ကီၤခံခါတခါ",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "တၢ်န့ၢ်ဖံးန့ၢ်မၤ",
      "address_1": "ခီပနံာ်လီၢ်အိၣ်ဆိးထံး",
      "address_2": "အဒိ- Suite 200",
      "phone_number": "ခီပနံာ်လီတဲစိနီၣ်ဂံၢ်",
      "company": "ခီပနံာ်အမံၤ",
      "postal_code": "တၢ်မၤကစၢ်တၢ်လီၢ်တၢ်ကျဲနီၣ်ဂံၢ်",
      "employed": "န့ၢ်ဖံးန့ၢ်မၤ",
      "sole_prorietor": "နီၢ်တဂၤတၢ်စုလီၢ်ခီၣ်ခိၣ်",
      "not_employed": "တန့ၢ်ဖံးန့ၢ်မၤ",
      "reporting_supervisor": "တၢ်တီၣ်ဖျါတၢ်ဂ့ၢ်ပၢဆှၢရဲၣ်ကျဲၤတၢ်မူဒါခိၣ်",
      "reporting_department": "တၢ်တီၣ်ဖျါတၢ်ဂ့ၢ်၀ဲၤကျိၤ",
      "supervisor_name": "ပၢဆှၢရဲၣ်ကျဲၤတၢ်မူဒါခိၣ်အမံၤ",
      "supervisor_email": "ပၢဆှၢရဲၣ်ကျဲၤတၢ်မူဒါခိၣ်အလီပရၢမံၤ"
    },
    "location_availability": "တၢ်မၤကွၢ်န့ၣ်ဘၣ်တၢ်ပာ်အီၤဒ်အမ့ၢ်တၢ်လၢအကါဒိၣ်လၢဆၢကတီၢ်ခဲအံၤဖဲပှၤတ၀ၢအိၣ်ဆိးဟီၣ်က၀ီၤတဖၣ်အံၤပူၤန့ၣ်လီၤႋ",
    "custom_survey": "တၢ်သံကွၢ်ရဲၣ်",
    "confirmation": "တၢ်ကွၢ်ကဒါက့ၤ",
    "waitlisted": "အိၣ်လၢပှၤအိၣ်ခိးတၢ်အမံၤစရီပူၤ",
    "schedule_your_appointment": "ရဲၣ်ကျဲၤလီၤတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "information": "တၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "consent": "တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲး",
    "location": "တၢ်လီၢ်တၢ်ကျဲ",
    "symptoms": "တၢ်ဆါအပနီၣ်တဖၣ်",
    "address_required": "၀ံသးစူၤထၢနုာ်လီၤနအိၣ်ဆိးလီၢ်ကျဲ",
    "consent_required": "၀ံသးစူၤအၢၣ်အီၤဟ့ၣ်တၢ်ပျဲလၢတၢ်မၤဆူညါအဂီၢ်",
    "required_field": "ပာ်ဖျါထီၣ်တၢ်မၤအပျီလၢတၢ်လိၣ်ဘၣ်",
    "phone_number": "လီတဲစိစိာ်စုနီၣ်ဂံၢ်",
    "email": "လီပရၢ",
    "date_of_birth": "အိၣ်ဖျဲၣ်မုၢ်နံၤ",
    "minimum_age": "တၢ်ပာ်ပနီၣ်သးနံၣ်စှၤကတၢၢ်လၢတၢ်နုာ်လီၤပၣ်ဃုာ်မ့ၢ်၀ဲ {{year}} နံၣ်ႋ",
    "no_minimum_age": "တၢ်တပာ်ပနီၣ်သးနံၣ်စှၤကတၢၢ်လၢတၢ်မၤကွၢ်အဂီၢ်ဘၣ်ႋ",
    "continue_button": "မၤဆူညါ",
    "email_required": "တၢ်ပာ်ပနီၣ်အံၤကဘၣ်မ့ၢ်လီပရၢနီၣ်ဂံၢ်အမ့ၢ်အတီ",
    "done": "၀ံၤလံ",
    "signature": "ဆဲးလီၤမံၤ",
    "clear_button": "တအိၣ်",
    "back_button": "ဆူလီၢ်ခံ",
    "choose_location": "ဃုထၢတၢ်လီၢ်တၢ်ကျဲ",
    "no_slots": "တၢ်ဆွံနုာ်လီၤကျိၤအံၣ်အံၣ်ဖိတအိၣ်လၢတၢ် {{location}}",
    "choose_appointment": "ဃုထၢထီၣ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်အမုၢ်နံၤလၢ {{location}}",
    "appointment_required": "၀ံသးစူၤဃုထၢတၢ်သ့ၣ်ဆၢဖးကတီၢ်အတၢ်ဆၢကတီၢ်",
    "phone_number_required": "လီတီစိနီၣ်ဂံၢ်တမ့ၢ်အမ့ၢ်အတီ",
    "phone_number_label": "၀ံသးစူၤထၢနုာ်လီၤနီၣ်ဂံၢ်လၢတၢ်ဒိးန့ၢ်ဘၣ်လံာ်ပရၢသ့လၢတၢ်မၤကွၢ်အဆၢချ့ထီၣ်အဂီၢ်တက့ၢ်ႋ",
    "symptoms_experiencing": "၀ံသစူၤစံၢ်လီၤတၢ်ဆါအပနီၣ်လၢနတူၢ်ဘၣ်ခဲအံၤတဖၣ်တက့ၢ်ႋ နမ့ၢ်တအိၣ်ဒီးတၢ်ဆါအပနီၣ်နီတမံၤဘၣ်န့ၣ်, ၀ံသးစူၤလဲၤဆူညါႋ",
    "household": "ဟံၣ်ဖိဃီဖိအနီၣ်ဂံၢ်",
    "household_p1": "တၢ်ဃုထၢကျဲ, ဒုးအိၣ်ထီၣ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်လၢဟံၣ်ဖိဃီဖိ\r\nကဘၣ်မၤကွၢ်သးတဖၣ်အဂီၢ်ႋ",
    "add_dependent": "ထၢနုာ်လီၤအါထီၣ်ဟံၣ်ဖိဃီဖိနီၣ်ဂံၢ်",
    "remove_dependent": "ထုးကွံာ်ပှၤအိၣ်သန့ၤထီၣ်သး",
    "dependent_consents": "အၢၣ်လီၤဟ့ၣ်တၢ်ပျဲ",
    "submit": "မၤပှဲၤလီၤ",
    "add_waitlist": "မၤအါထီၣ်ဖဲပှၤအိၣ်ခိးတၢ်စရီပူၤ",
    "address": "ဟံၣ်အိၣ်လီၢ်ဆိးထံး",
    "address_1": "အိၣ်ဆိးလီၢ်ဂ့ၢ်ကျိၤရဲၣ် 1",
    "address_2": "ဟံၣ်ဒၢးဖှိၣ်/ဟံၣ်ဒွဲအဒၢးစူၣ်နီၣ်ဂံၢ်",
    "address_city": "၀့ၢ်",
    "address_state": "ကီၢ်စဲၣ်",
    "postal_code": "လံာ်ပရၢတၢးနီၣ်ဂံၢ်",
    "race_ethnicity": "ကလုာ်ဖိ",
    "gender": "ပာ်ပနီၣ်လီၤမုၣ်/ခွါတၢ်အိၣ်သးပတီၢ်",
    "self_described_gender": "နီၢ်ကစၢ်ပာ်ဖျါထီၣ်မုၣ်/ခွါပနီၣ်",
    "interpreter": "လိၣ်ဘၣ်ပှၤကျိာ်ထံတၢ်ဧါ? မ့ၢ်လိၣ်ဘၣ်တခီ, ကျိာ်ဖဲလဲၣ်တခါလဲၣ်?",
    "consent_to_terms": "ယအၢၣ်လီၤတူၢ်လိာ်တၢ်ရဲၣ်တၢ်ကျဲၤလၢတၢ်ပာ်ပနီၣ်လီၤတဖၣ်အံၤ",
    "reg_not_open": "တၢ်ထၢနုာ်လီၤမံၤတအိးထီၣ်သးဘၣ်",
    "no_more_avail_spots": "တၢ်ဆၢကတီၢ်လၢနဃုထၢန့ၣ်တအိၣ်လၢၤဘၣ် ၀ံးသးစူၤမၤကဒီးတဘီ",
    "consent_helper": "စံၢ်လီၤဒီးထုးတြူာ်ဃီၤနခီၣ်ဖၠူထၢၣ်စုဖီၣ်မ့တမ့ၢ်နစုနၢဆူတၢ်ဒၢဖိပူၤလၢတၢ်ဆဲးလီၤမံၤအဂီၢ်",
    "phone_number_unreachable_label": "ဟံၣ်လီတဲစိအိၣ်ဧါ?",
    "select": "ဃုထၢ",
    "test_surveys": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်အတၢ်သံကွၢ်တဖၣ်",
    "edit": "ပဲာ်ထံ",
    "continue_to_registration": "ဆဲးမၤဆူညါလၢတၢ်ထၢနုာ်လီၤမံၤအဂီၢ်",
    "accounts": {
      "already_have_an_account": "အိၣ်ဒီးတၢ်ဂ့ၢ်တၢ်ကျိၤစရီလံဧါ?",
      "log_in": "နုာ်လီၤ",
      "sign_up": "ဆဲးလီၤမံၤ (Sign up)",
      "sign_up_description": "၀ံသးစူၤထၢနုာ်လီၤနတၢ်ဂ့ၢ်တၢ်ကျိၤဆူနတၢ်ဂ့ၢ်တၢ်ကျိၤစရီပူၤဒီးမၤလၢာ်စှၤလီၤတၢ်ဆၢကတီၢ်လၢခါဆူညါတၢ်ဆဲးလီၤမံၤတဖၣႋ",
      "log_in_description": "၀ံသးစူၤထၢနုာ်လီၤလီပရၢနီၣ်ဂံၢ်ဒီးအနီၣ်ဂံၢ်ခူသူၣ်, မ့တမ့ၢ် sign in လီၤလၢ Google မ့တမ့ၢ် Google ပူၤလၢလာ်တက့ၢ်ႋ",
      "sign_in_with_label": "ဆဲးနုာ်လီၤမံၤ (Sign in) ဒီး{{provider}}",
      "password_must_match": "နီၣ်ဂံၢ်ခူသူၣ်ကဘၣ်ဘၣ်လိာ်အသး",
      "password_uppercase": "နနီၣ်ဂံၢ်ခူသူၣ်ကဘၣ်ပၣ်ဃုာ်ဒီးလံာ်ဖျၢၣ်ဒိၣအဖျၢၣ်အစှၤကတၢၢ် (%s)",
      "password_lowercase": "နနီၣ်ဂံၢ်ခူသူၣကဘၣ်ပၣ်ဃုာ်ဒီးလံာ်ဖျၢၣ်ဆံးအဖျၣအစှၤကတၢၢ် (%s)",
      "password_number": "နနီၣ်ဂံၢ်ခူသူၣ်ကဘၣ်ပၣ်ဃုာ်အစှၤကတၢၢ် (%s) နီၣ်ဂံၢ်အဖျၢၣ်တဖၣ်",
      "password_special": "နနီၣ်ဂံၢ်ခူသူၣ်ကဘၣ်ပၣ်ဃုာ်အစှၤကတၢၢ် (%s) တၢ်ပနီၣ်လီၤဆီအဖျၢၣ်တဖၣ်"
    },
    "password": "လံာ်ဖျၢၣ်ခူသူၣ်",
    "password_confirmation": "ပာ်ဂၢၢ်ပာ်ကျၢၤလံာ်ဖျၢၣ်ခူသူၣ်",
    "consent_for": "အၢၣ်လီၤဟ့ၣ်ခွဲးလၢ{{name}}",
    "book_one_time_appointment": "မၤတၢ်သ့ၣ်ဆၢကတီၢ်ဆိထဲတဘျီ",
    "duplicate_users": {
      "exists": "နဆဲးလီၤနမံၤ၀ံလံ",
      "overlapping_email_and_phone_p1": "ပဆှၢလံာ်ပရၢလၢနလီတဲစိနီၣ်ဂံၢ်ဒီးလီပရၢနီၣ်ဂံၢ်ပူၤ",
      "p2": "စူးကါနလံာ်ပရၢလၢ, နဆီတလဲနတၢ်သ့ၣ်ဆၢဖးကတီၢ်မ့တမ့ၢ်\r\nထၢနုာ်လီၤတၢ်မၤကွၢ်အစၢတဖၣ်သ့န့ၣ်လီၤႋ",
      "overlapping_phone_and_email_p3": "၀ံသးစူၤခၠဲးနအံမ့လ် မ့တမ့ၢ် လီတဲစိလၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးဂီၢ်ႋ",
      "overlapping_email_p3": "၀ံသးစူၤခၠဲးနအံမ့လ်လၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးအဂီၢ်ႋ",
      "overlapping_phone_p3": "၀ံသးစူၤခၠဲးနလီတဲစိလၢတၢ်ဂ့ၢ်တၢ်ကျိၤလီၤတံၢ်လီၤဆဲးအဂီၢ်ႋ",
      "overlapping_phone_p1": "ပဆှၢတၢ်ပရၢလၢနလီတဲစိနီၣ်ဂံၢ်ပူၤႋ",
      "overlapping_email_p1": "ပဆှၢတၢ်ပရၢလၢနအံမ့လ်နီၣ်ဂံၢ်ပူၤႋ",
      "p4": "နမ့ၢ်ဆိမိၣ်လၢတၢ်စူးကါအီၤတသ့ဘၣ်န့ၣ် ၀ံသးစူၤဆဲးကျိးပှၤလၢ support@primary.health တက့ၢ်ႋ",
      "overlapping_email_and_phone_p3": "၀ံသးစူၤခၠဲးနအံမ့လ် မ့တမ့ၢ် လီတဲစိလၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးဂီၢ်ႋ"
    },
    "duplicate_waitlist": {
      "exists": "နမံၤအိၣ်လံလၢပှၤဘၣ်အိၣ်ခိးတၢ်စရီပူၤ",
      "overlapping_email_and_phone_p1": "ပဆှၢကဒီးတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအသီတဘျီလၢနလီတဲစိဒီးအံမ့လ်နီၣ်ဂံၢ်ပူၤ",
      "p2": "စူးကါနတၢ်ဂ့ၢ်တၢ်ကျိၤလၢ, နဟ့ၣ်အါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤမ့တမ့ၢ်\r\nထုးကွံာ်နသးလၢပှၤအိၣ်ခိးတၢ်မံၤစရီပူၤန့ၣ်သ့လီၤႋ",
      "overlapping_phone_and_email_p3": "၀ံသးစူၤခၠဲးနအံမ့လ် မ့တမ့ၢ် လီတဲစိလၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးဂီၢ်ႋ",
      "overlapping_email_p3": "၀ံသးစူၤခၠဲးနအံမ့လ်လၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးဂီၢ်ႋ",
      "overlapping_phone_p3": "၀ံသးစူၤခၠဲးနလီတဲစိလၢတၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲးဂီၢ်ႋ",
      "overlapping_email_p1": "ပဆှၢကဒီးတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအသီတဘျီလၢနအံမ့လ်ပူၤႋ"
    },
    "insist_guardian_consent": "ယဟ့ၣ်လီၤတၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးခၢၣ်စးလၢ",
    "confirmation_section": {
      "title": "ကွၢ်ကဒါက့ၤဒီးပာ်ဂၢၢ်ပာ်ကျၢၤ",
      "p1": "၀ံသးစူၤကွၢ်ကဒါက့ၤနတၢ်ဂ့ၢ်တၢ်ကျိၤတချုးမၤ၀ံၤတၢ်ဆဲးလီၤမံၤန့ၣ်တက့ၢ်ႋ",
      "edit_information": "က့ၤကဒါက့ၤဒီးဘှီဘၣ်က့ၤ"
    },
    "iemodal": {
      "title": "အ့ထၢၣ်နဲးသန့ထံးလၢတမၤစၢၤတၢ်",
      "body": "ဆၢကတီၢ်ခဲအံၤတၢ်ထၢနုာ်လီၤမံၤသ့၀ဲဒၣ်လၢအ့ထၢၣ်နဲးထံးအံၤပူၤ"
    },
    "show_other_locations": "စံၢ်လီၤလၢ show other locations (ဒုးနဲၣ်တၢ်လီၢ်တၢ်ကျဲအဂၤတဖၣ်)",
    "non_us_address": "တၢ်အံၤမ့ၢ်၀ဲဟီၣ်ခိၣ်ဒီဘ့ၣ်အိၣ်ဆိးလီၢ်ထံး",
    "test_group_user_survey": "တၢ်ဂုၤကွၢ်ပှၤနုာ်လီၤပၣ်ဃုာ်",
    "self_consent": "ယအၢၣ်လီၤဟ့ၣ်ခွဲးလၢယနီၢ်ကစၢ်အဂီၢ်",
    "address_country": "ထံကီၢ်",
    "appointment": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "employee_id": "ပှၤမၤတၢ်ဖိ ID",
    "appointment_time": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်ဆၢကတီၢ်",
    "appointment_location": "တၢ်လီၢ်တၢ်ကျဲ",
    "phone_or_email": "လီတဲစိနီၣ်ဂံၢ် မ့တမ့ၢ် အံမ့(လ)",
    "no_self_consent": "ယဆိကတီၢ်ကွံာ်တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးလၢယနီၢ်ကစၢ်ဂီၢ်",
    "no_insist_guardian_consent": "ယဆိကတီၢ်ကွံာ်တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးလၢ {{full_name}} အဂီၢ်",
    "additional_consents_helpertext": "တၢ်ကဃ့အါထီၣ်တၢ်အၢၣ်လီၤဟ့ၣ်ခွဲးအဂၤဆူညါသ့",
    "minimum_age_with_months": "ပါဝင်ရန် အနည်းဆုံးအသက်မှာ {{year}} နှစ်နှင့် {{month}} လဖြစ်သည်။",
    "assistive_technology": "စဲးဖီကဟၣ်ပီညါလၢအမၤစၢၤတၢ်",
    "covid_vaccine_y_n": "နအဲၣ်ဒိးဆဲးကသံၣ်ဒီသဒၢ COVID-19 လၢတၢ်မၤနီၣ်ပာ်တၢ်ဂ့ၢ်အံၤဧါႋ",
    "received_covid_vaccine_y_n": "နဆဲးဘၣ် COVID-19 ကသံၣ်ဒီသဒၢ၀ံၤလံဧါႋ",
    "covid_vaccinations": "တၢ်ဆဲးကသံၣ်ဒီသဒၢ Covid-19",
    "select_vaccine_dose": "၀ံသးစူၤဃုထၢကသံၣ်ဒီသဒၢအဆဲးလၢနအဲၣ်ဒိးဆဲးအီၤ",
    "demographic_info": "ပှၤအိၣ်ဖျဲၣ်ဂ့ၢ်၀ီတၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "additional_info": "တၢ်ဂ့ၢ်တၢ်ကျိၤအဂုၤ",
    "self_described_race": "ပာ်ဖျါထီၣ်နီၢ်ကစၢ်အစၢၤသွဲၣ်ကလုာ်ဒူၣ်",
    "verify_contact_information": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤ",
    "verify_contact": {
      "we_sent_you_a_code": "၀ံသးစူၤဒိလီၤနီၣ်ဂံၢ်လၢပဆှၢဘၣ်ဆူ {{contact}}",
      "confirmation_code": "ပာ်ဂၢၢ်ပာ်ကျၢၤနီၣ်ဂံၢ်ခူသူၣ်",
      "code_resent": {
        "email": "တၢ်ဆှၢလီၤနီၣ်ဂံၢ်ခူသူၣ်အဂၤတဖျၢၣ်ဆူနလီပရၢ",
        "phone_number": "တၢ်ဆှၢလီၤနီၣ်ဂံၢ်ခူသူၣ်အဂၤတဖျၢၣ်ဆူနလီတဲစိ"
      },
      "did_not_receive_code": "နဒိးန့ၢ်ဘၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤနီၣ်ဂံၢ်ခူသူၣ်ဧါႋ",
      "verify_by": {
        "email": "ပာ်ဂၢၢ်ပာ်ကျၢၤခီဖျိလီပရၢ",
        "phone_number": "ပာ်ဂၢၢ်ပာ်ကျၢၤခီဖျိလီတဲစိနီၣ်ဂံၢ်"
      }
    },
    "preferred_method_of_communication": {
      "question": "တၢ်ဆဲးကျၢဆဲးကျိးအကျဲလၢဘၣ်သး",
      "helper_text": "ပကဆဲးကျၢနၤလၢတၢ်သ့ၣ်ဆၢကတီၢ်အဂ့ၢ်အသီ",
      "phone_number": "လီတဲစိ (စူးကါထဲ တၢ်ဒိဆှၢလံာ်ကျိၤကစီၣ်ဒီးတၢ်ဂ့ၢ်ထၢဖှိၣ်ပတီၢ်)",
      "landline": "ဆၢကတီၢ်အံၤပတစူးကါလီတဲစိဘျးစဲလီပျံၤဘၣ်ႋ ၀ံသးစူၤဟ့ၣ်ဘၣ်နလီပရၢအမံၤလၢတၢ်ဂ့ၢ်တၢ်ကျိၤအကါဒိၣ်တက့ၢ်ႋ",
      "verification_method": "နဘၣ်သးဒိးန့ၢ်ဘၣ်န တၢ်ဟ့ၣ်ခွဲးနီၣ်ဂံၢ် လၢကျဲဒ်လဲၣ်ႋ",
      "verification_helper_text": "တၢ်ကဆှၢနၤနီၣ်ဂံၢ်တဖျၢၣ်လၢကပာ်ဂၢၢ်ပာ်ကျၢၤနနီၢ်ကစၢ်တချုးနုာ်လီၤန့ၣ်လီၤႋ"
    },
    "skip_for_now": "လဲၤကပာ်ကွံာ်လၢတဘျီအံၤ",
    "default_information": {
      "title": "အိၣ်က့ၣ် ပကစူးကါလီၢ်အိၣ်ဆိးအံၤ",
      "title_v2": "အိုကေ၊ ကျွန်တော်တို့ဤဆက်သွယ်ရန်အချက်အလက်ကိုသုံးပါမည်"
    },
    "middle_name_label": "မံၤခၢၣ်သး",
    "confirm_appointment": "Confirm appointment",
    "dob": "DOB",
    "different_appointment": "Different appointment",
    "select_appointment_time": "Select appointment time",
    "decline_address": "I decline to provide an address. Please use the program's address instead.",
    "patient_information": "Patient information",
    "scan_license": "Scan driver's license",
    "how_to_contact": "How should we contact you?",
    "decline_email_or_phone": "I decline to provide an email or phone number. I authorize program administrators to receive my results.",
    "medical_screening": "Medical screening",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "တၢ်ဃုထၢသ့",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "ပိာ်ခွါ",
    "female": "ပိာ်မုၣ်",
    "cisgender": "Cisgender (မုၣ်ခွါသွံၣ်ထံးတၢ်အိၣ်ဖျါ)",
    "non_binary": "Genderqueer (ပှၤသးလီၤမုၣ်ခွါခံမံၤလၢာ်) မ့တမ့ၢ် Non-binary (ပှၤအိၣ်ဒီးမုာ်ခွါက့ၢ်ဂီၤခံခါလိာ်)",
    "other": "အဂုၤအဂၤ",
    "prefer_to_self_describe": "တပာ်ပနီၣ်လီၤလၢအမ့ၢ်မတၤ",
    "prefer_not_to_disclose": "ဂ့ၢ်လိာ်သမၢလၢကစံးဆၢ",
    "transgender_male": "ဆီတလဲကွံာ်က့ၢ်ဂီၤဆူခွါက့ၢဂီၤ/မုာ်ကဲထီၣ်က့ၤခွါ (Transman)",
    "transgender_female": "ဆီတလဲကွံာ်က့ၢ်ဂီၤဆူမုၣ်က့ၢ်ဂီၤ/ခွါကဲထီၣ်က့ၤပိာ်မုၣ်(Transwoman)",
    "unknown": "တသ့ၣ်ညါဘၣ်",
    "non_binary_only": "ပှၤအဲၣ်ပကၤတၢ်",
    "intersex": "တမ့ၢ်ပိာ်မုၣ်တမ့ၢ်ပိာ်ခွါ",
    "transgender": "ပှၤပိာ်မုၣ်လၢအသးလီၤပိာ်ခွါပှၤပိာ်ခွါလၢအသးလီၤပိာ်မုၣ်",
    "gender_fluid": "လိင်တည်နေရာပြောင်းလဲ",
    "not_applicable": "မသက်ဆိုင်ပါ"
  },
  "ethnicity": {
    "american_indian_alaska_native": "American Indian မ့တမ့ၢ် Alaska ပှၤထူလံၤဖိ",
    "american_indian_central_america": "American Indian လၢ South မ့တမ့ၢ် Central America",
    "asian": "Asian (ပှၤအ့ရှၣ်)",
    "black": "ပှၤသူဖံးဖိမ့တမ့ၢ် African American",
    "latinx": "Latino, Latinx မ့တမ့ၢ် Hispanic",
    "middle_eastern_north_african": "Middle Eastern မ့တမ့ၢ် North African",
    "native_hawaiian_pacific_islander": "Hawaiian ပှၤထူလံၤဖိမ့တမ့ၢ် Pacific Islander",
    "white_european": "ပှၤ၀ါဖံးဖိမ့တမ့ၢ် Caucasian",
    "unknown": "တသ့ၣ်ညါဘၣ်",
    "prefer_not_to_disclose": "တအဲၣ်ဒိးပာ်အိၣ်ဖျါထီၣ်ဘၣ်",
    "asian_indian": "ပှၤအ့ရှၣ် အ့ဒံယၣ်ကလုာ်",
    "filipino": "ဖံလံပံး",
    "japanese": "ယပၣ်",
    "korean": "ခိးရံယါ",
    "vietnamese": "ဘံၣ်ယဲနၣ်",
    "other_asian": "Asian အဂၤတဖၣ်",
    "native_hawaiian": "Hawaiian ပှၤထူလံၤဖိ",
    "guamanian_or_chamorro": "Guamanian မ့တမ့ၢ် Chamorro",
    "samoan": "Samoan (ပှၤဖဲစၣ်မိးကီးဖီခိၣ်)",
    "other_pacific_islander": "Pacific အဂၤ ပှၤကီးဖိ",
    "chinese": "တရူး",
    "help": "ကလုာ်ဒူၣ်အခီပညီမ့ၢ်နီၢ်ခိတၢ်ဘၣ်ထွဲအရူၢ်အသဲးလၢအပာ်ဖျါထီၣ်ပှၤတဂၤအကရူၢ်တၢ်လီၤဆီလိာ်သးန့ၣ်လီၤႋ  ဘၣ်သ့ၣ်သ့ၣ်ကလုာ်ဒူၣ်က မ့တမ့ၢ် တဘၣ်လိာ်ဃုာ်ဒီးထံကီၢ်အပှၤကလုာ်, မ့တမ့ၢ် ထံကီၢ်လၢအအိၣ်ဒီးအထံဖိကီၢ်ဖိလံာ်အုၣ်သးဘၣ်န့ၣ်လီၤႋ",
    "subtitle": "တၢ်မၤအတၢ်ဘျၢအိၣ်လၢပကဘၣ်ထၢဖှိၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤခဲလၢာ်",
    "laotian": "ပှၤလဲးတ့ၣ်ဖိ",
    "cambodian": "ခဲၣ်ဘိဒံယါ",
    "other": "အဂုၤအဂၤ",
    "bangladeshi": "Bangladeshi",
    "hmong": "Hmong",
    "indonesian": "Indonesian",
    "malaysian": "Malaysian",
    "pakistani": "Pakistani",
    "sri_lankan": "Sri Lankan",
    "thai": "Thai",
    "taiwanese": "Taiwanese",
    "fijian": "Fijian",
    "guamanian": "Guamanian",
    "tongan": "Tongan"
  },
  "languages": {
    "en": "ပှၤအဲကလံး",
    "fr": "ဖြၣ်စ့ၣ်",
    "es": "Español (ပှၤစပ့ၣ်)",
    "so": "စိၣ်မါလံ",
    "hmn": "မိ",
    "asl": "American တၢ်ကတိၤနဲၣ်စုပၠးခီၣ်လၢအိၣ်ဒီးတၢ်မၤသီၣ်ကလုၢ် (ASL)",
    "kar": "ကညီ",
    "am": "Amharic (ပှၤအါဟါရံး)",
    "ru": "русский (ပှၤရၢရှါ)",
    "om": "Oromo (ပှၤအံးသံအိပံယါ)"
  },
  "symptoms": {
    "fever": "တၢ်လိၤကိၢ် မ့တမ့ၢ် တၢ်ဂိၢ်ထီၣ်",
    "cough": "ကူး",
    "muscle_aches": "ညၣ်ထူၣ်တဖၣ်ဆါ",
    "severe_fatigue": "တၢ်ဘှံးထီၣ်နးနး(အါန့ၢ်ဒံးညီနူၢ်အသိး)",
    "trouble_breathing": "ကသါကီခဲ",
    "diarrhea": "ဟၢဖၢလူ",
    "loss_of_smell": "နါတနၢတၢ်လၢၤဘၣ်",
    "loss_of_taste": "တပလၢၢ်ဘၣ်လၢၤတၢ်အီၣ်အရီၢ်လၢၤဘၣ်",
    "shortness_of_breath": "ကသါဃံးမ့တမ့ၢ်ကသါဘၣ်ကီခဲ",
    "headache": "ခိၣ်သၣ်ဃံးဆါ",
    "sore_throat": "ကိာ်ယူၢ်ဆါ",
    "congestion": "နါဒ့တံာ်တၢၤမ့တမ့ၢ်နါအ့ၣ်ထံယွၤ",
    "nausea": "သးကလဲၤမ့တမ့ၢ်ဘှီး",
    "close_contact": "အိၣ်ဘူးဘၣ်ဒီးပှၤဘၣ်ဂာ်န့ၢ်တၢ်ဆါ*",
    "helper_text": {
      "close_contact": "နအိၣ်ဘူးဘၣ်ဒီးပှၤအဂၤ (လၢ 6 ခီၣ်ယီၢ်အစှၤကတၢၢ် 15 မံးနံး) လၢတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအဒိးန့ၢ် COVID-19 န့ၣ်ဧါ?"
    },
    "suspected_exposure": "အိၣ်ဖဲတၢ်ဆိမိၣ်တယာ်တၢ်ဆါဘၣ်ဂာ်အလီၢ်",
    "none": "တၢ်ဆါပနီၣ်တအိၣ်နီတမံၤ"
  },
  "instructions": {
    "title": "နဆဲးလီၤလံနမံၤဘၣ်ဆၣ်တချုးနလဲၤႋႋႋႋႋ",
    "p1": "လံာ်ကဘျံးပၤအံၤအိၣ်ဃုာ်ဒီးတၢ်ဂ့ၢ်တၢ်ကျိၤဘၣ်ဃးနတၢ်သ့ၣ်ဆၢဖးကတီၢ်ဃုာ်ဒီးနတၢ်သ့ၣ်ဆၢကတီၢ် နီၣ်ဂံၢ်ခူသူၣ်လၢခီၣ်ဖၠူထၢၣ်ဖးသ့ (barcode) န့ၣ်လီၤႋ",
    "appointment_scheduled": "နတၢ်သ့ၣ်ဆၢဖးကတီၢ်ဘၣ်တၢ်ရဲၣ်လီၤလံအီၤ",
    "verify_info": {
      "title": "သမံသမိးပာ်ဂၢၢ်ပာ်ကျၢၤနတၢ်ဂ့ၢ်တၢ်ကျိၤ",
      "p1": "တၢ်အံၤအကါဒိၣ်၀ဲလၢတၢ်ကမၤလီၤတံၢ်နတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤဒ်သိးနကနုာ်လီၤဆူပှၤဆါပှဲာ်ယဲၤသန့ကျဲစၢၤကသ့ချ့ထီၣ်အဂီၢ်ႋ",
      "p2": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤဘၣ်တၢ်ဆှၢဃီၤဆူနအံမ့လ်ပူၤႋ ၀ံသးစူၤစံၢ်လီၤ link (ပှၤယဲၤဘျးစဲ) လၢတၢ်ဟ့ၣ်လီၤအီၤႋ",
      "p3": "နမ့ၢ်အဲၣ်ဒိးဆီတလဲတၢ်ဆဲးကျိးအတၢ်ဂ့ၢ်တၢ်ကျိၤ, ၀ံသးစူၤဆီၣ်လီၤ help button တက့ၢ်ႋ"
    },
    "verified": "သမံသမိးပာ်ဂၢၢ်ပာ်ကျၢၤ",
    "not_verified": "တသမံသမိးပာ်ဂၢၢ်ပာ်ကျၢၤ",
    "resend_text": "တဒိးန့ၢ်ဘၣ် link (ပှၤယဲၤဘျးစဲ) ဘၣ်ဧါ?",
    "resend": "ဆှၢကဒါက့ၤ",
    "skip": "လဲၤကပာ်ကွံာ် Validation (တၢ်မၤလီၤတံၢ်) ဒီး Show Confirmation (ပာ်ဖျါထီၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤ)",
    "name": "မံၤ",
    "appointment_details": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "date_and_time": "မုၢ်နံၤဒီးတၢ်ဆၢကတီၢ်",
    "add_to_calendar": "မၤနီၣ်လီၤဆူလံာ်နံၣ်လံာ်လါပူၤ",
    "instructions": "တၢ်နဲၣ်လီၤထီရီၤတဖၣ်",
    "successfully_flash": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်၀ံၤလံ",
    "success_flash_email": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအံမ့လ်၀ံၤလံ",
    "success_flash_phone": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလီတဲစိနီၣ်ဂံၢ်၀ံၤလံ",
    "mail_order": "နကဒိးန့ၢ်ဘၣ်တၢ်မၤကွၢ်ပိးလီလၢတၢ်ဆှၢလံာ်ပရၢဖဲတနွံအတီၢ်ပူၤ",
    "at_home": "ပှၤဟ့ၣ်ဆူၣ်ချ့တၢ်မၤစၢၤကဟဲဆူနဟံၣ်ဖဲတၢ်ဆၢကတီၢ်၀ဲန့ၣ်လီၤႋ",
    "at_home_instructions": "တၢ်နဲၣ်လီၤလၢဟံၣ်",
    "mail_order_instructions": "တၢ်မၤလိာ်တၢ်လၢတၢ်ဆှၢလံာ်ပရၢကျဲလံာ်တၢ်နဲၣ်လီၤ",
    "request_additional_appointment": "မၤဆိပာ်တၢ်လူၤပိာ်ထွဲအတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "book_additional_appointment": "မၤဆိပာ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်အသီတဘျီ",
    "confirmation_code": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်ခူသူၣ်လံာ်ဖျၢၣ်",
    "completed": "မၤ၀ံၤလၢပှဲၤလံ",
    "appointment_barcode": "တၢ်သ့ၣ်ဆၢဖးကတီၢ် Barcode",
    "dependent_links": "ဟံၣ်ဖိဃီဖိတၢ်သ့ၣ်ဆၢဖးကတီၢ် Links တဖၣ်",
    "on_demand_title": "နဆဲးလီၤလံနမံၤဘၣ်ဆၣ်တချုးနလဲၤႋႋႋႋႋ",
    "save_link": "ပာ်ဃာ် Links ၀ဲအံၤ",
    "verify_phone": "ပာ်ဂၢၢ်ပာ်ကျၢၤနလီတဲစိနီၣ်ဂံၢ်",
    "verify_phone_description": "တၢ်အံၤအကါဒိၣ်၀ဲလၢကဘၣ်မၤလီၤတံၢ်နတၢ်ဆဲးကျၢအတၢ်ဂ့ၢ်တၢ်ကျိၤဒ်နနုာ်လီၤကွၢ်တၢ်မၤနီၣ်မၤဃါချ့သ့ထီၣ်အဂီၢ်ႋ တၢ်ပာ်ဂၢၢ်ပာ်ကျၢအလံာ်ပရၢကွဲးဘၣ်တၢ်ဆှၢဃီၤဆူနနီၣ်ဂံၢ်ပူၤႋ",
    "verify_email_description": "တၢ်အံၤအကါဒိၣ်၀ဲလၢကဘၣ်မၤလီၤတံၢ်နတၢ်ဆဲးကျၢအတၢ်ဂ့ၢ်တၢ်ကျိၤဒ်နနုာ်လီၤကွၢ်တၢ်မၤနီၣ်မၤဃါချ့သ့ထီၣ်အဂီၢ်ႋ တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလီပရၢဘၣ်တၢ်ဆှၢဃီၤဆူနအံမ့လ်နီၣ်ဂံၢ်ပူၤႋ",
    "information": "တၢ်ထၢနုာ်လီၤမံၤတၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "follow_up_appointment": "တၢ်လူၤပိာ်ထွဲအတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "get_directions": "ဒိးန့ၢ်တၢ်နဲၣ်ကျဲတဖၣ်",
    "cancel": "ဆိကတီၢ်ကွံာ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "reschedule_appointment": "ရဲၣ်ကျဲၤလီၤက့ၤတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "reschedule_linked_appointments": "ရဲၣ်ကျဲၤလီၤက့ၤတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "no_slots": "တၢ်ဆၢကတီၢ်တအိၣ်လၢၤဘၣ်",
    "check_results": "ခၠဲးပှၤဆါပှၤယဲၤသန့ကျဲစၢၤ",
    "follow_up_modal_header": "၀ံသးစူၤရဲၣ်ကျဲၤလီၤနတၢ်ဆဲးကသံၣ်ခံဖျၢၣ်တဖျၢၣ်",
    "are_you_sure_you_want_to_cancel": "နလီၤတံၢ်လံဟါလၢနကဆိကတီၢ်ကွံာ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်အံၤ?",
    "please_choose_cancellation_reason": "၀ံသးစူၤဃုထၢတၢ်ဆိကတီၢ်ကွံာ်အဂ့ၢ်အကျိၤလၢလာ်အံၤ",
    "additional_details": "၀ံသးစူၤထၢနုာ်အါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလီၤတံၢ်လီၤဆဲးလၢလာ်အံၤ",
    "errors": {
      "missing_cancellation_reason": "၀ံသးစူၤဃုထၢတၢ်ဆိကတီၢ်ကွံာ်အဂ့ၢ်အကျိၤ"
    },
    "verify_email": "ပာ်ဂၢၢ်ပာ်ကျၢၤလီၤနအံမ့လ်",
    "redcap_url": "စံၢ်လီၤ complete your survey (တၢ်မၤပှဲၤ၀ံၤလီၤတၢ်လီၤဃုထံၣ်သ့ၣ်ညါတၢ်ဂ့ၢ်)",
    "verify_contact_information": "ပာ်ဂၢၢ်ပာ်ကျၢၤနတၢ်ဆဲးကျိးတၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "please_also": "ဝံသးစူၤမၤစ့ၢ်ကီး",
    "new_title": "Confirmation for {{name}}",
    "contact_method": "တၢ်ဆဲးကျၢဆဲးကျိးအကျဲ",
    "next_steps": {
      "title": "ပတီၢ်ဆူညါတဖၣ်",
      "p1": "နအိၣ်ဒီး test kit (တၢ်မၤကွၢ်အပီးလီတစူၣ်) လၢနကအိးထီၣ်စူကါဝဲအဂီၢ်န့ၣ်လီၤႋ ဖဲနအိၣ်ကတီၤသးလၢနကမၤဝဲဒၣ်တၢ်မၤကွၢ်အံၤအခါ, မၤထွဲ link (ပှာ်ဘျးစဲ) လၢတၢ်ဆှၢဃီၤဝဲဆူနလီပရၢအပူၤဒီးဆှၢကဒါက့ၤလံာ်ကဘျံးပၤအံၤဒီးအိးထီၣ်စူးကါန kit ဖဲလာ်အံၤတက့ၢ်ႋ",
      "administered": "ပကဆဲးကျိးနၤဖဲန kit (တၢ်ပီးတၢ်လီအစူၣ်) တုၤဃီၤဖဲတၢ်မၤကွၢ်ဒၢးအခါဒီးဖဲတၢ်ဒိးန့ၢ်တၢ်အစၢအခါန့ၣ်လီၤႋ အါတက့ၢ်တၢ်ဒိးန့ၢ်တၢ်မၤကွၢ်အစၢဖဲ 3-5 သီအတီၢ်ပူၤဖဲတၢ်မၤကွၢ်အစူၣ်တဖၣ်န့ၣ်တုၤဃီၤဆူတၢ်မၤကွၢ်ဒၢးဝံၤအလီၢ်ခံန့ၣ်လီၤႋ နကွၢ်ဝဲဒၣ်တၢ်မၤကွၢ်အစၢတဖၣ်သ့ဖဲန [Medical history]({{link}}) ကဘျံးပၤအပူၤဖဲတၢ်ဒိးန့ၢ်တၢ်အစၢဝံၤအခါန့ၣ်လီၤႋ",
      "resulted": "တၢ်အစၢတဖၣ်ကအိၣ်ဖျါဝဲဖဲန [Medical history]({{link}}) ကဘျံးပၤအပူၤန့ၣ်လီၤႋ",
      "shipping_information": "တၢ်ဆှၢဟးထီၣ်တၢ်အဂ့ၢ်အကျိၤ"
    },
    "save_this_page": {
      "title": "ပာ်ကီၤဃာ်လံာ်ကဘျံးပၤအံၤ",
      "p1": "စူးကါလံာ်ကဘျံးပၤအံၤလၢတၢ်ပာ်ဖျါထီၣ်နီၢ်ကစၢ်တၢ်မၤကွၢ်အဂ့ၢ် မ့တမ့ၢ် တၢ်ကွၢ်ဝဲဒၣ်တၢ်မၤကွၢ်အစၢတဖၣ်ခီဖျိတၢ်ကူစါဆူၣ်ချ့အဂ့ၢ်အကျိၤပူၤန့ၣ်တက့ၢ်ႋ",
      "p2": "လၢတၢ်နုာ်လီၤဘၣ်ဖဲကဘျံးပၤအံၤအပူၤလၢခါဆူညါအဂီၢ်စံၢ်လီၤဖဲ link လၢနလီပရၢတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအပူၤ မ့တမ့ၢ် SMS တၢ်ကွဲးတၢ်ဂ့ၢ်အပူၤန့ၣ်တက့ၢ်ႋ",
      "p3": "နပာ်ကီၤဃာ်လံာ်ကဘျံၤပၤအံၤခီဖျိ bookmarking (တၢ်ပာ်ပနီၣ်ဃာ်) အီၤအဂီၢ်, ထၢနုာ်အါထီၣ်အီၤဖဲ home screen (ပှာ်ယဲၤသန့အမဲာ်သၣ်လိၤ) မ့တမ့ၢ် ကွဲးကူန့ၢ် link ဖဲတၢ်ပူၤဖျဲးအလီၢ်တတီၤတီၤတက့ၢ်ႋ"
    },
    "show_my_barcode": "ပာ်ဖျါထီၣ်ယ barcode (လံာ်ကျိၤတၢ်ခူသူၣ်နီၣ်ဂံၢ်)",
    "my_account": "ယစရီ",
    "register_another": "ဆဲးလီၤန့ၢ်ပှၤနုာ်လီၤပၣ်ဃုာ်လၢအဂၤတဂၤဂၤအမံၤ",
    "update_vaccine": "တၢ်မၤသီထီၣ်ကသံၣ်ဆဲးဒီသဒၢတၢ်ဆါအဂ့ၢ်အကျိၤ",
    "medical_history": "တၢ်ကူစါဆူၣ်ချ့အဂ့ၢ်အကျိၤ",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "နီၣ်ဂံၢ်ခူသူၣ်နုာ်လီၤတန့ၢ်ဘၣ်"
    },
    "landing": {
      "p1": "လၢတၢ်ကွၢ်နတၢ်အဆၢတဖၣ်, ၀ံသးစူၤဒိနုာ်လီၤ-",
      "access_code_label": "နုာ်လီၤနီၣ်ဂံၢ်ခူသူၣ်",
      "submit_button": "တီၣ်ထီၣ်",
      "recent_results": "နတၢ်အဆၢလၢတယံာ်ဒံးဘၣ်တဖၣ်"
    },
    "view_results": {
      "headline": "တၢ်မၤကွၢ်အဆၢတဖၣ်လၢ{{name}}",
      "reregister": "ထၢနုာ်လီၤကဒါက့ၤမံၤ",
      "status": {
        "likely_positive": "တၢ်အဆၢတဖၣ်လၢတၢ်တသ့ၣ်ညါအဂ့ၢ်လီၤတံၢ်လီၤဆဲး",
        "test_not_performed": "လိၣ်ဘၣ်တၢ်မၤကွၢ်ကဒါက့ၤ",
        "results_ready": "တၢ်အဆၢတဖၣ်အိၣ်ကတီၤသးလံ",
        "processing": "တၢ်မၤကျိၤကျဲၤမၤတၢ်အဖၢမုၢ်",
        "test_status": "တၢ်မၤကွၢ်အပတီၢ်တဖၣ်",
        "test_result": "တၢ်မၤကွၢ်အဆၢတဖၣ်",
        "administered": "တၢ်ပၢဆှၢရဲၣ်ကျဲၤအီၤ",
        "results_pending": "တၢ်အိၣ်ခိးတၢ်အစၢ",
        "test_results": "တၢ်မၤကွၢ်အစၢတဖၣ်"
      },
      "at": "ဖဲ {{address}}"
    },
    "result_label": "တၢ်အဆၢတဖၣ်",
    "result": {
      "flu_a": {
        "result_label": "မၤကွၢ်တၢ်လိၤကိၢ်ဘၣ်သမုၣ်တၢ်ဆါအစၢ A"
      },
      "flu_b": {
        "result_label": "မၤကွၢ်တၢ်လိၤကိၢ်ဘၣ်သမုၣ်တၢ်ဆါအစၢ B"
      },
      "covid19": {
        "result_label": "မၤကွၢ် COVID-19 တၢ်ဆါအစၢ"
      },
      "covid": {
        "result_label": "မၤကွၢ် COVID-19 တၢ်ဆါအစၢ"
      },
      "sars": {
        "result_label": "SARS အစၢ"
      },
      "control": {
        "result_label": "ပၢဆှၢမၤဂၢၢ်ကျၢၤ"
      },
      "rsv": {
        "result_label": "RSV အစၢ"
      },
      "result": {
        "result_label": "အစၢ"
      },
      "hba1c": {
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး",
        "warning": "တချုးကဲထီၣ်ဆံၣ်ဆၢတၢ်ဆါ",
        "danger": "ဆံၣ်ဆၢတၢ်ဆါ"
      },
      "lead_venous": {
        "danger": "တမ့ၢ်တၢ်ဒ်ညီနုၢ်အသိး",
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး"
      },
      "tc": {
        "result_label": "TC အစၢ"
      },
      "hdl": {
        "result_label": "HDL အစၢ"
      },
      "trg": {
        "result_label": "TRG အစၢ"
      },
      "ldl": {
        "result_label": "LDL အစၢ"
      },
      "non_hdl": {
        "result_label": "တမ့ၢ် HDL အစၢ"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL Ratio (တၢ်ချံးဃ့)"
      },
      "glu": {
        "result_label": "GLU အစၢ"
      },
      "alere_cholestech_ldx": {
        "danger": "အိၣ်ဖဲတၢ်လီၤဘၣ်ယိၣ်အပူၤ",
        "warning": "တမ့ၢ်တၢ်ဒ်ညီနုၢ်အသိး",
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး"
      },
      "lead": {
        "result_label": "Lead (စၢၢ်ထး)"
      },
      "zinc": {
        "result_label": "Zinc (ပှာ်ဝါ)"
      },
      "lead_standard_profile": {
        "danger": "တမ့ၢ်တၢ်ဒ်ညီနုၢ်အသိး",
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး"
      },
      "creatinine": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး",
        "warning": "မြင့်မား",
        "danger": "မြင့်မား"
      },
      "total_cholesterol_fasted": {
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး",
        "elevated": "မြင့်မား",
        "high": "မြင့်မား",
        "low": "နိမ့်ကျဆင်း"
      },
      "total_cholesterol_unfasted": {
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး",
        "elevated": "မြင့်မား",
        "high": "မြင့်မား"
      },
      "a1c_now": {
        "normal": "မ့ၢ်ဒ်ညီနုၢ်အသိး",
        "warning": "ဆီးခဲအချက်ကပင်",
        "danger": "ဆီးခဲရောဂါ"
      },
      "blood_glucose": {
        "warning": "နိမ်",
        "normal": "ပုံမှန်",
        "prediabetes": "ဘာသာစကား",
        "danger": "ဘာသာစကား"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "လံာ်တီလံာ်မီ",
    "self_administered": "နီၢ်ကစၢ်တၢ်ပၢဆှၢရဲၣ်ကျဲၤလီၤတၢ်မၤကွၢ်လၢဘၣ်တၢ်မၤန့ၢ်ဘၣ်အီၤဖဲ {{location}}",
    "patient": "ပှၤဆူးပှၤဆါ",
    "medical_history": "တၢ်ကူစါဆူၣ်ချ့အဂ့ၢ်အကျိၤ",
    "overview_title": "ဃုထၢထီၣ်တၢ်မၤကွၢ် မ့တမ့ၢ် တၢ်မၤစၢၤလၢတၢ်ကွၢ်အါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢပှဲၤဒီးတၢ်မၤအါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤတမံၤမံၤလၢအလိၣ်မ့ၢ်အိၣ်ဝဲန့ၣ်က့ၢ်ႋ မၤနီၣ်ဃာ်လၢတၢ်မၤကွၢ်အစၢတနီၤနီၤတအိၣ်ဖျါဒံးဝဲ မ့တမ့ၢ် ကအိၣ်ဖျါထဲလၢတၢ်ကတိၤသကိးဒီးပှၤရဲၣ်ကျဲၤမၤစၢၤတၢ်ဖိဝံၤအလီၢ်ခံန့ၣ်လီၤ",
    "insurance_information": "ထၢနုာ်ပှဲၤတၢ်အုၣ်ကီၤအဂ့ၢ်အကျိၤ",
    "contact_support": "နမ့ၢ်လိၣ်ဘၣ်တၢ်တိစၢၤမၤစၢၤ, မ့တမ့ၢ် အဲၣ်ဒိးဆီတလဲနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤ, ဝံသးစူၤဆဲးကျိးဘၣ်တၢ်ဆီၣ်ထွဲမၤစၢၤအလီၢ်တက့ၢ်ႋ",
    "show": "ပာ်ဖျါထီၣ်",
    "hide": "ပာ်ခူသူၣ်",
    "lab_report": "တၢ်မၤကွၢ်ဒၢးအတၢ်ပာ်ဖျါ",
    "contact_provider": {
      "header": "You have positive results",
      "description": "Do you want to talk to a provider to discuss your results and treatment?",
      "yes_option_text": "Yes, I want to talk to a provider",
      "no_option_text": "No, I have read and understand my results, and do not want to talk to a provider",
      "confirm_phone_number_header": "Confirm your phone number",
      "confirm_phone_number_description": "Please confirm the best phone number for your consultation.",
      "confirm_button_text": "Confirm",
      "consultation_confirmed_header": "Consultation confirmed",
      "consultation_confirmed_description": "A provider will call you at {{phone_number}} within 2-3 business days.",
      "acknowledgement_option_helper_text": "Something that explains the importance of treatment and lets the user know how to schedule a call if they change their mind.",
      "acknowledgement_confirmed_header": "Acknowledgement confirmed",
      "acknowledgement_confirmed_description": "Something about the importance of getting treatment with linked resources. Reminder that the consultation is completely free and the provider can prescribe or whatever else to help them resolve the infection.",
      "acknowledgement_confirmed_change_mind_text": "If you change your mind, simply click “I’d like a consultation” below.",
      "request_consultation_button_text": "I’d like a consultation"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "နအိၣ်ဆိးလီၢ်ထံးတဘၣ်လိာ်သးဒီးတၢ်ပာ်ပနီၣ်လီၤလၢတၢ်ဘှီထီၣ်လီၢ်အတၢ်ရဲၣ်တၢ်ကျဲၤအံၤပူၤဘၣ်န့ၣ်လီၤႋ"
    }
  },
  "member": {
    "medical_history": "တၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "view_instructions": "ကွၢ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအဂ့ၢ်",
    "next_appointment": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်လၢခံတပတီၢ်",
    "over_18": "ယအုၣ်ယသးလၢယသးအိၣ်18 နံၣ် မ့တမ့ၢ်ဆူအဖီခိၣ်",
    "choose_test_configurations_title": "၀ံသးစူၤဃုထၢထီၣ်တၢ်မၤစၢၤ(တဖၣ်) လၢနအဲၣ်ဒိးဖဲတၢ်သ့ၣ်ဆၢဖးကတီၢ်အံၤဂီၢ်",
    "member_taken_error": "ပှၤစူးကါတၢ်အိၣ်ဒီးအစရီ (account) လံ",
    "choose_test_configurations_subtitle": "တၢ်မၤစၢၤ(တဖၣ်)ကအိၣ်၀ဲလၢဟံၣ်ဖိဃီဖိကိးဂၤဒဲးအဂီၢ်",
    "service": "တၢ်ဖံးစၢၤမၤစၢၤ",
    "group_or_location": "ကရူၢ်/တၢ်လီၢ်တၢ်ကျဲ",
    "details": "တၢ်ဂ့ၢ်လီၤတံၢ်လီၤဆဲး",
    "see_all": "ကွၢ်ဘၣ်ခဲလၢာ်",
    "no_history": "စံၣ်စိၤတၢ်ဂ့ၢ်လၢခဲအံၤတအိၣ်ဘၣ်ႋ",
    "medical_history_title_with_name": "{{name}} အဂ့ၢ်အကျိၤ",
    "no_dashboard": "ခဲအံၤတၢ်ဂ့ၢ်တအိၣ်နီတမံၤဘၣ်",
    "product": "ပနံာ်",
    "price": "အပှ့ၤ",
    "quantity": "နီၣ်ဂံၢ်နီၣ်ဒွး",
    "total_services_selected": "တၢ်မၤစၢၤခဲလၢာ်ပာ်ဖှိၣ်လၢတၢ်ဃုထၢအီၤ",
    "total_price": "အပှ့ၤခဲလၢာ်ပာ်ဖှိၣ်"
  },
  "or": "မ့တမ့ၢ်",
  "account": {
    "errors": {
      "must_be_13": "ကဘၣ်အိၣ်13 နံၣ်လၢတၢ်ထၢနုာ်လီၤမံၤဆူစရီပူၤဂီၢ်"
    }
  },
  "self_administration": {
    "title": "Self-Administration (နီၢ်ကစၢ်တၢ်ပၢဆှၢလီၤသး)",
    "self_administer_action": "Self-Administer (နီၢ်ကစၢ်ပၢဆှၢလီၤသး)",
    "skip_to_link": "လဲၤကပာ်ကွံာ်ဆူ Self-Administration (နီၢ်ကစၢ်တၢ်ပၢဆှၢလီၤသး)",
    "select_person": "ဃုထၢပှၤလၢကသမံသမိးတၢ်ဟးထီၣ်",
    "adult": "ပှၤနီၢ်ဒိၣ်",
    "child": "ဖိသၣ်",
    "checked_out": "သမံသမိးတၢ်ဟးထီၣ်",
    "go_back": "က့ၤကဒါ",
    "switch_to_scanner": "ခီလဲလိာ်က့ၤဆူစဲးကွဲးဒိလံာ်",
    "enter_barcode": "ထၢနုာ်လီၤ Barcode",
    "scan_barcode": "ဒိလီၤ Barcode",
    "cancel": "ဆိကတီၢ်",
    "barcode_for": "Barcode လၢ",
    "enter_barcode_manually": "ထၢနုာ်လီၤ Barcode လၢနီၢ်ကစၢ်",
    "instructions": "ကွဲးဒိမ့တမ့ၢ်ထၢနုာ်လီၤ barcode လၢတၢ်ဆီလီၤပာ်လီၤန့ၣ်နတၢ်မၤကွၢ်ပီၤဘိလၢလာ်အံလိၤ",
    "regex_prefix": "Barcode ကဘၣ်အိၣ်ဃုာ်ဒီး",
    "all_completed": "မၤ၀ံၤလၢပှဲၤ- ဟံၣ်ဖိဃီဖိကိးဒူၣ်ဒဲးမၤ၀ံၤလၢပှဲၤတၢ်မၤတပတီၢ်အံၤ",
    "errors": {
      "no_appointment": "တၢ်ပာ်လီၤတၢ်သ့ၣ်ဆၢကတီၢ်တၢ်ကီတၢ်ခဲ",
      "barcode_in_use": "Barcode အံၤဘၣ်တၢ်စူးကါလံအီၤလၢညါတဘျီႋ နမ့ၢ်တစူးကါဘၣ်တၢ်ပိးတၢ်လီအံၤလၢနနီၢ်ကစၢ်လၢညါတဘျီဘၣ်န့ၣ်၀ံသးစူၤတဲဘၣ်ပှၤမၤတၢ်ဖိလၢကဟ့ၣ်လီၤနၤတၢ်ပိးတၢ်လီမ့တမ့ၢ်တၢ်ပိးတၢ်လီအသီန့ၣ်တက့ၢ်ႋ မ့တမ့ၢ်ဘၣ်န့ၣ်, စံၢ်လီၤ တၢ်မၤစၢၤပနီၣ် လၢလာ်အံၤတက့ၢ်ႋ",
      "test_error_general": "တၢ်မၤလိၣ်ထီၣ်ဖးထီၣ်တၢ်မၤကွၢ်အတၢ်ကီတၢ်ခဲ",
      "different_barcodes": "Barcodes လၢနထၢနုာ်လီၤန့ၣ်တဘၣ်လိာ်အသးဘၣ်ႋ"
    },
    "confirm_barcode_input": "ထၢနုာ်လီၤ barcode ကဒီးတဘျီလၢတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအဂီၢ်",
    "click_to_self_test": "စံၢ်လီၤဖဲအံၤလၢ Self-Test (တၢ်မၤကွၢ်လီၤနီၢ်ကစၢ်သး)"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "လံာ်အိၣ်ခိးတၢ်မံၤစရီ",
      "unavailable": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်တလီၤဟိဘၣ်"
    },
    "labels": {
      "address": "အိၣ်ဆိးလီၢ်ကျဲ",
      "next_appointment": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်လၢခံတဘျီအိၣ်၀ဲ",
      "services": "တၢ်မၤစၢၤလၢအအိၣ်တဖၣ်"
    }
  },
  "waiting_room": {
    "header": "နအိၣ်ဖဲပှၤဘၣ်အိၣ်ခိးတၢ်အဒၢးပူၤ- ၀ံသးစူၤအိၣ်ခိးဖဲပဘျးစဲနၤဒီးတၢ်ရဲၣ်ကျဲၤလီၤတၢ်အတၢ်မၤကျဲ",
    "p1": "၀ံသးစူၤအိၣ်ဒီးတၢ်သးစူၤႋ ပကမၤကတၢၢ်ကွံာ်တၢ်ဆါသတြိာ်အံၤတပူၤဃီႋ",
    "signature": "ပှဲၤဒီးတၢ်သူၣ်တီသးရၤ",
    "p2": "ပသ့ၣ်ညါလၢတၢ်လဲၤခီဖျိဘၣ် COVID-19 မ့ၢ်တၢ်လၢအဒုးအိၣ်ထီၣ်တၢ်သးဘၣ်တံာ်တာ်ႋ ၀ံသးစူၤအိၣ်ခိးဖဲအံၤဖဲပမၤတၢ်တသ့ဖဲအသ့လၢအချ့ကတၢၢ်လၢကဒုးနုာ်လီၤနၤဆူတၢ်မၤဆိပာ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်လီၢ်အဂီၢ်ႋ"
  },
  "users": {
    "send_appointment_confirmation_message": "ဟဲလိ{{full_name}}. တၢ်အံၤမ့ၢ်တၢ်ဒုးသ့ၣ်နီၣ်ထီၣ်က့ၤနတၢ်သ့ၣ်ဆၢကတီၢ်ဖဲ {{name}} လီၤႋ နတၢ်သ့ၣ်ဆၢကတီၢ်ဘၣ်လၢ {{date}} ဖဲ {{time}} ဖဲ {{address}} လီၤႋ",
    "send_registration_link": "ဟဲလိၣ်, {{full_name}} ပိာ်ထွဲ link အံၤလၢတၢ်ထၢနုာ်လီၤန့ၢ်ပှၤအမံၤ{{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "ကသံၣ်တၢတဖျၢၣ်",
    "previous_appointment": "တၢ်သ့ၣ်ဆၢဖးကတီၢ်ပူၤကွံာ်",
    "booked_appointment": "နတၢ်သ့ၣ်ဆၢဖးကတီၢ်",
    "second_dose": "ကသံၣ်ခံဖျၢၣ်တဖျၢၣ်",
    "choose_second_location": "ဃုထၢတၢ်သ့ၣ်ဆၢဖးကတီၢ်ခံဘျီတဘျီတၢ်လီၢ်တၢ်ကျဲ"
  },
  "cancellation_types": {
    "cant_get_to_location": "ယအိၣ်ဒီးတၢ်ဆၢကတီၢ်အတၢ်ဘၣ်ဂံာ်ဂူာ်",
    "timing_conflict": "ယဆိးက့ထီၣ်ဒီး COVID-19 တၢ်ဆါ",
    "became_ill_with_covid19": "ယဒိးန့ၢ်ဘၣ်တၢ်မၤစၢၤအံၤလၢတၢ်လီၢ်အဂၤတပူၤ",
    "received_service_elsewhere": "အဂုၤအဂၤ",
    "other": "ဒိလီၤခၢၣ်စးမံၤ",
    "duplicate_appointment": "တၢ်အံၤမ့ၢ်ဝဲတၢ်သ့ၣ်နံၤဖးသီလၢအကဲထီၣ်သးဒ်သိးသိး"
  },
  "translation": {
    "consent": {
      "type_name_instead": "ဒိးလီၤမံၤ",
      "type_name": "လၢအလီၢ်ဒိလီၤလၢတၢ်ဆဲးလီၤမံၤ"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "နတၢ်နုာ်လီၤဒိးန့ၢ်ဘၣ်တၢ်အနီၣ်ဂံၢ်ခူသူၣ်မ့ၢ်၀ဲ",
      "please_confirm_your_email": "၀ံသးစူၤပာ်ဂၢၢ်ပာ်ကျၢၤနအံမ့လ်လၢတၢ်စံၢ်လီၤ button လၢလာ်အံၤ",
      "hello": "ဟဲလိၣ်",
      "click_to_reschedule": "စံၢ်လီၤဖဲ Show Instructions/Reschedule (တၢ်ဒုးနဲၣ်တၢ်နဲၣ်ကျဲ/တၢ်ရဲၣ်ကျဲၤလီၤကဒါက့ၤ",
      "click_to_reschedule_on_demand": "စံၢ်လီၤဖဲ Show Instructions"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "နတၢ်သ့ၣ်နံၤဖးသီအဆၢကတီၢ်ဖဲ {{time}} ဖဲ {{location}} ဘၣ်တၢ်ဆိကတီၢ်ဝဲလံန့ၣ်လီၤႋ တၢ်အံၤမ့ၢ်ကမၣ်န့ၣ်, ဝံသးစူၤကွဲးလီပရၢဆူ support@primary.health"
    }
  },
  "signature_lines": {
    "name": "ပှၤနုာ်လီၤပၣ်ဃုာ်တၢ်အမံၤ",
    "date": "မုၢ်နံၤ",
    "signature": "ပှၤနုာ်လီၤပၣ်ဃုာ်တၢ်ဆဲးလီၤမံၤ",
    "and_or": "ဒီး/မ့တမ့ၢ်",
    "guardian_name": "မိၢ်ပၢ်/ပှၤကွၢ်ထွဲတၢ်အမံၤ",
    "guardian_signature": "မိၢ်ပၢ်/ပှၤကွၢ်ထွဲတၢ်ဆဲးလီၤမံၤ"
  },
  "employer_testing": {
    "hello_name": "ဟဲလိၣ် {{name}}",
    "results": {
      "negative": "တၢ်ဆါတအိၣ်ဘၣ်",
      "positive": "အိၣ်ဒီးတၢ်ဆါအဃၢ်",
      "did_not_result": "တသ့ၣ်ညါဘၣ်",
      "unknown": "တသ့ၣ်ညါ",
      "invalid": "တၢ်စူးကါအီၤတသ့",
      "pending": "အိၣ်ခိးတၢ်"
    },
    "code_reader": {
      "scan_your_code": "Scan (ကွဲးဒိ) နီၣ်ဂံၢ်ခူသူၣ်လၢနတၢ်မၤကွၢ်ခးက့လိၤ",
      "find_in_top_right_hand_corner": "Scan (တၢ်ကွဲးဒိ)နီၣ်ဂံၢ်ခူသူၣ်အံၤထဲဒၣ်လၢနတၢ်မၤကွၢ်ဒီးတၢ်ထံၣ်န့ၢ်အီၤသ့ဖဲနတၢ်မၤကွၢ်ပူၤအဖီခိၣ်စုထွဲနၢၣ်ထံးတပၤန့ၣ်လီၤႋ",
      "center_code": "ပာ်လီၤနီၣ်ဂံၢ်ခူသူၣ်ဖဲအံၤ",
      "already_used_error": "တၢ်မၤကွၢ် barcode ဘၣ်တၢ်စူးကါလံအီၤႋ ၀ံသးစူၤစဲးကျိးပှၤဟ့ၣ်လီၤနၤတၢ်မၤကွၢ်ပိးလီလၢတၢ်ဆီၣ်ထွဲမၤစၢၤအဂီၢ်တက့ၢ်",
      "click_to_scan": "စံၢ်လီၤ scan QR code (ကွဲးဒိ QR နီၣ်ဂံၢ်ခူသူၣ်)",
      "scan_new_test_card": "စံၢ်လီၤဖဲအံၤလၢတၢ်ကွဲးဒိ (scan) တၢ်မၤကွၢ်ခးက့သီဂီၢ်တက့ၢ်",
      "not_working": "တမၤတၢ်ဘၣ်ဧါ?",
      "try_again": "Click here (စံၢ်လီၤဖဲအံၤ)လၢကမၤကဒီးတဘျီတက့ၢ်ႋ"
    },
    "continue": "မၤဆူညါ",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "၀ံသးစူၤပာ်ဂၢၢ်ပာ်ကျၢၤပှၤနုာ်လီၤပၣ်ဃုာ်တၢ်အိၣ်ဖျဲၣ်မုၢ်နံၤလၢတၢ်မၤဆူညါတက့ၢ်",
      "contact_administrator": "တၢ်ဂ့ၢ်တၢ်ကျိၤလၢတၢ်ဒုးနဲၣ်ဖျါထီၣ်တဖၣ်မ့ၢ်တဘၣ်ဘၣ်န့ၣ်, ၀ံသးစူၤစဲးကျိးပှၤပၢဆှၢတၢ်လၢတၢ်ဆီၣ်ထွဲအဂီၢ်",
      "error": "အိၣ်ဖျဲၣ်မုၢ်နံၤလၢတၢ်ထၢနုာ်လီၤအီၤန့ၣ်တဘၣ်ဘၣ်-၀ံသးစူၤမၤကဒီးတဘျီမ့တမ့ၢ်ဆဲးကျိးပှၤပဆှၢတၢ်လီၢ်လၢတၢ်မၤသီထီၣ်တၢ်ဂ့ၢ်အံၤတက့ၢ်ႋ"
    },
    "result_entry": {
      "code_is_registered": "နနီၣ်ဂံၢ်ခူသူၣ်ဘၣ်တၢ်ထၢနုာ်လီၤအီၤလံ",
      "take_the_test": "ဟံးန့ၢ်တၢ်မၤကွၢ်",
      "follow_the_instructions": "ပိာ်ထွဲတၢ်နဲၣ်လီၤလၢအပာ်ဃုာ်ဖဲနတၢ်မၤကွၢ်ပူၤဒီးအိၣ်ခိး 15 မံးနံး\r\nဖဲနထၢနုာ်လီၤ nasal swab (နါဒ့ထံအဒိလၢတၢ်မၤကွၢ်တၢ်ဆါ)\r\nတၢ်မၤကွၢ်ခးဆူပလီဖိပူၤန့ၣ်တက့ၢ်ႋ\r\n",
      "cant_find_instructions": "တထံၣ်တၢ်နဲၣ်လီၤဘၣ်ဧါ?",
      "view_instructions": "ကွၢ် Binax Now တၢ်နဲၣ်လီၤန့ၣ်တက့ၢ်",
      "start_timer": "ထိၣ်တၢ်ဆၢကတီၢ် 15 မံးနံး (တၢ်ဃုထၢသ့)",
      "submit_within_15": "Tip တီၣ်ထီၣ်နတၢ်မၤကွၢ်အဆၢလၢ 15 မံးနံးတီၢ်ပူၤ",
      "enter_test_results": "ထၢနုာ်လီၤနတၢ်မၤကွၢ်အဆၢ",
      "choose_a_result": "ဃုထၢတၢ်မၤအကျဲလၢအပာ်ဖျါထီၣ်နတၢ်မၤကွၢ်ခးအဆၢတက့ၢ်ႋ",
      "submit": "တီၣ်ထီၣ်",
      "must_log_result_and_photo": "နကဘၣ်မၤနီၣ်တၢ်မၤကွၢ်အဆၢဒီးဒိအဂီၤဒ်ကမၤတၢ်ဆူညါအဂီၢ်ႋ",
      "submit_within_15_v2": "Tip တီၣ်ထီၣ်နတၢ်မၤကွၢ်အစၢဖဲ တၢ်အိၣ်ခိး 15 မံးနံး၀ံၤအလီၢ်ခံန့ၣ်တက့ၢ်ႋ",
      "click_to_take_photo": "စံၢ်လီၤဖဲ ဒိတၢ်ဂီၤ (take a photo)",
      "results_may_be_invalid": "ဘၣ်သ့ၣ်တၢ်မၤကွၢ်အစၢတတီလိၤ၀ဲဘၣ်"
    },
    "summary": {
      "title": "တၢ်အစၢတဖၣ်အဂ့ၢ်ကွဲးဖှိၣ်",
      "negative_subtitle": "နတအိၣ်ဒိး COVID-19",
      "negative_message": "ပဆှၢလံာ်တၢ်ဒုးသ့ၣ်ညါဆူနတၢ်မၤကစၢ်အိၣ်လၢနတအိၣ်ဒီးတၢ်ဆါလၢကက့ၤမၤက့ၤတၢ်ဖဲတၢ်မၤလီၢ်ႋ",
      "positive_subtitle": "ပသးတမုာ်ဘၣ်, ဘၣ်ဆၣ်နအိၣ်ဒီး COVID-19",
      "positive_message": "ပဆှၢလံာ်တၢ်ဒုးသ့ၣ်ညါဆူနတၢ်မၤကစၢ်အိၣ်လၢနအိၣ်ဒီး COVID-19 န့ၣ်လီၤႋ",
      "what_should_you_do": "နကြၢးမၤမနုၤတဖၣ်လဲၣ်-",
      "employer_name_resources": "နတၢ်မၤကစၢ်မံၤအတၢ်ဂ့ၢ်တၢ်ကျိၤတဖၣ်-",
      "follow_cdc_guidelines": "ပိာ်ထွဲ CDC (တၢ်ဖီၣ်ဂၢၢ်ဒီသဒၢတၢ်ဆါ၀ဲၤကျိၤ)တၢ်ဟ့ၣ်ကူၣ်နဲၣ်ကျဲတဖၣ်"
    },
    "clarifying_results": {
      "title": "မၤဖျါဆှံထီၣ်နတၢ်အဆၢတဖၣ်",
      "which_did_your_result_most_look_like": "နတၢ်အဆၢလီၤဂာ်အါဖဲလဲၣ်တခါလဲၣ်?",
      "no_lines": "တထံၣ်ပနိကျိၤဘၣ်",
      "blue_control_line_only": "ထဲပနိကျိၤလါအဲး (Blue control line)",
      "pink_sample_line_only": "ထဲပနကျိၤဂီၤစၢ်အဒိ (Pink sample line)",
      "blue_control_line_and_pink_sample_line": "ပနိကျိၤလဲအဲး (Blue control line) ဒီးပနိကျိၤဂီၤစၢ်/ဂီၤလုး (pink/purple sample line)",
      "still_not_sure": "တလီၤတံၢ်ဒံးဘၣ်",
      "submit_results": "တီၣ်ထီၣ်တၢ်ဆၢတဖၣ်",
      "thank_you": "တၢ်ဘျုးႋႋ",
      "clarification_received_message": "တၢ်ဒိးန့ၢ်ဘၣ်နတၢ်ပာ်ဖျါဆှံထီၣ်တၢ်မၤကွၢ်အဆၢႋ",
      "only_pink_line": "ထဲကျိၤပနိဂီၤစၢ်",
      "no_lines_v2": "ကျိၤပနိတဖၣ်တအိၣ်ဘၣ်"
    },
    "return_to_confirmation": "က့ၤကဒါဆူ တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤ ကဘျံးပၤ"
  },
  "preferred_language": {
    "title": "ကျိာ်လၢလိၣ်ဘၣ်အီၤအါ",
    "subtitle": "နုလိၣ်ဘၣ်ကျိာ်ဖဲလဲၣ်တမံၤလဲၣ်ႋ"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "ဂံၢ်ခီၣ်ထံးကသံၣ်ဒီသဒၢကတြူာ်",
    "one_dose": "မၤလိာ်ဆိပာ်စၢၤတၢ်သ့ၣ်ဆၢဖးကတီၢ်လၢကသံၣ်လၢအဘၣ်လိာ်ဒီးတၢ်ဆါအကလုာ် လီၤဆီဒၣ်တၢ်, တၢ်အံၤယ",
    "title": "ကသံၣ်လၢဘၣ်တၢ်ဃုထၢအီၤ",
    "first_dose": "ကသံၣ်တၢတဖျၢၣ်",
    "second_dose": "ကသံၣ်ခံဖျၢၣ်တဖျၢၣ်",
    "single_dose_title": "ကသံၣ်တဖျၢၣ်လၢတဘျီဂီၢ်",
    "additional": "တၢ်ဂုၤဂၤ",
    "booster": "တၢ်ဆဲးမၤဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါ",
    "supplemental": "ကသံၣ်ဖျၢၣ်",
    "third_dose": "ထဲလၢသၢဖျၢၣ်တဖျၢၣ်",
    "no_vaccine": "တမ့ၢ်လၢထးနီတမံၤဘၣ်"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "ကြၢးအိၣ်ဒီးတၢ်ရဲၣ်တၢ်ကျဲၤ{{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "ကြၢးအိၣ်ဒီးတၢ်ရဲၣ်တၢ်ကျဲၤ{{datetime_format}}"
            },
            "uid": {
              "unknown_test": "တထံၣ်တၢ်မၤကွၢ်ဘၣ်"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "ပှၤစူးကါတၢ်အိၣ်၀ဲဒီးတဟ့ၣ်တၢ်ပျဲလၢတၢ်ကမၤသီထီၣ်တၢ်"
            },
            "date_of_birth": {
              "invalid_date": "ကြၢးအိၣ်ဒီးတၢ်ရဲၣ်တၢ်ကျဲၤ{{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{ethnicities}}",
                "other": "{{unknown_ethnicities}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "မုၣ်တအိၣ်ခွါတအိၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{genders}}"
            },
            "phone_number": {
              "invalid": "မ့ၢ်နီၣ်ဂံၢ်လၢတမ့ၢ်တတီ"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "မၤပှဲၤလီၤတသ့ဖဲတၢ်ကါမုာ်ခွါတအိၣ် \"prefer_to_self_describe\"\r\n"
            },
            "sex": {
              "unknown_sex": "သွံၣ်ထံးတအိၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ်အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ-{{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "တၢ်အိၣ်သးတမ့ၢ်တတီ, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ်\r\nအီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ-{{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{races}}",
                "other": "{{unknown_races}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ်\r\nအီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ-{{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "ခွဲးယာ်တအိၣ်လီၤဆီလၢတၢ်ပာ်လီၤမူဒါတၢ်ဖံးတၢ်မၤဂီၢ်"
            },
            "user_id": {
              "unknown_user": "တထံၣ်ပှၤစူးကါတၢ်ဘၣ်"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON တမ့ၢ်တတီ"
            },
            "user_id": {
              "unknown_user": "တထံၣ်ပှၤစူးကါတၢ်ဘၣ်"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "တထံၣ်တၢ်သ့ၣ်ဆၢဖးကတီၢ်တၢ်လီၢ်တၢ်ကျဲ"
            },
            "date_of_birth": {
              "invalid_date": "ကြၢးအိၣ်ဒီးတၢ်ရဲၣ်တၢ်ကျဲၤ{{date_format}}"
            },
            "email": {
              "duplicated": "တၢ်စူးကါလံအီၤ"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{ethnicities}}",
                "other": "{{unknown_ethnicities}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "မုၣ်တအိၣ်ခွါတအိၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{genders}}"
            },
            "phone_number": {
              "invalid": "မ့ၢ်နီၣ်ဂံၢ်လၢတမ့ၢ်တတီ"
            },
            "population": {
              "unknown_population": "{{unknown_population}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "မၤပှဲၤလီၤတသ့ဖဲတၢ်ကါမုာ်ခွါတအိၣ် \"prefer_to_self_describe\"\r\n"
            },
            "sex": {
              "unknown_sex": "သွံၣ်ထံးတအိၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ်အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ-{{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ် အီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ- {{races}}",
                "other": "{{unknown_races}} တအိၣ်ဘၣ်, ဃုထၢထီၣ်တၢ်ပာ်ပနီၣ်လၢတၢ်တူၢ်လိာ်\r\nအီၤသ့တဖၣ်ဒ်လၢလာ်အံၤ-{{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "ဒီးအိၣ်ဆိးလီၢ်ထံးဘၣ်တၢ်စူးကါလံအီၤဖဲတၢ်လီၢ်တၢ်ကျဲအဂၤတတီၤပူၤ"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Hispanic မ့တမ့ၢ် Latino/ပှၤ",
    "not_hispanic": "တမ့ၢ် Hispanic မ့တမ့ၢ် Latino/ပှၤ",
    "hispanic_expanded": {
      "other": "Hispanic, Latino/ပှၤ မ့တ့မၢ် Spanish အထူအထံးအဂၤ",
      "mexican": "Mexican, Mexican American, Chicano/ပှၤ",
      "puerto_rican": "Puerto Rican (ပှၤဖူတိ ရံကၤ)",
      "cuban": "Cuban (ခယူဘါ)"
    },
    "subtitle": "တၢ်ဖံးတၢ်မၤအတၢ်ဘျၢလိၣ်ဘၣ်ပထၢဖှိၣ်တၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤခဲလၢာ်",
    "help": "ကလုာ်ဒူၣ်အခီပညီမ့ၢ်၀ဲ လုလၢ်သကဲပ၀းအရူၢ်အသဲးလီၤဆီဒ်အ\r\nမ့ၢ်ကျိာ်, လုလၢ်တၢ်ဆဲးတၢ်လၤသနိ, တၢ်ဘူၣ်တၢ်ဘါ, ဒီးတၢ်ပ\r\nနီၣ်က့ၢ်ဂီၤအဂၤလၢအလီၤဆီလိာ်သးတဖၣ်လၢပှၤတကရူၢ်တ\r\nဖၣ်မၤ၀ဲဒၣ်န့ၣ်လီၤႋ တၢ်အံၤဘၣ်သ့ၣ်သ့ၣ်က မ့တမ့ၢ်တဘၣ်လိာ်ဃုာ်စ့ၢ်ကီးအသးဒီးက\r\nလုာ်ဒူၣ်အတၢ်ရ့လိာ်သးဘၣ်န့ၣ်လီၤႋ",
    "unknown": "တသ့ၣ်ညါဘၣ်",
    "title": "ကလုာ်ဒူၣ်"
  },
  "sex_at_birth": {
    "question": "မုၣ်ခွါသွံၣ်ထံး",
    "female": "ပိာ်မုၣ်",
    "male": "ပိာ်ခွါ",
    "help": "မုၣ်ခွါသွံၣ်ထံးအခီပညီမ့ၢ်တၢ်လၢနအိၣ်ဖျဲၣ်ထီၣ်ဃုာ်",
    "non_binary": "Non-binary (ပှၤအိၣ်ဒီးမုာ်ခွါက့ၢ်ဂီၤခံခါလိာ်)",
    "subtitle": "တၢ်မၤအတၢ်ဘျၢအိၣ်လၢပကဘၣ်ထၢဖှိၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤခဲလၢာ်",
    "decline": "ဂ့ၢ်လိာ်သမၢလၢကစံးဆၢ",
    "unknown": "တသ့ၣ်ညါဘၣ်"
  },
  "gender": {
    "help": "တၢ်ပာ်ပနီၣ်တၢ်ကဲမုၣ်ခွါအခီပညီမ့ၢ်၀ဲနီၢ်ကစၢ်ဒၣ်၀ဲအတၢ်ပာ်ပနီၣ်လီၤသးဒ်လဲၣ်ႋ တၢ်အံၤဘၣ်သ့ၣ်သ့ၣ်က မ့တမ့ၢ်တဘၣ်လိာ်ဃုာ်သးဒီးမုၣ်ခွါသွံၣ်\r\nထံးလၢနအိၣ်ဖျဲၣ်ထီၣ်ဃုာ်အီၤဘၣ်န့ၣ်လီၤႋ",
    "subtitle": "နမ့ၢ်အဲၣ်ဒိးဖၢနုာ်အါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤဘၣ်ဃးတၢ်ကဲမုၣ်ခွါအတၢ်အိၣ်သးပတီၢ်န့ၣ်၀ံသးစူၤမၤအီၤတက့ၢ်ႋ"
  },
  "sexual_orientation": {
    "title": "မုၣ်ခွါသွံၣ်ထံးတၢ်ရ့လိာ်သးတၢ်သးစဲ",
    "subtitle": "နမ့ၢ်အဲၣ်ဒိးဖၢနုာ်အါထီၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤဘၣ်ဃးနမုၣ်ခွါတၢ်ရ့လိာ်သးအတၢ်သးစဲန့ၣ်၀ံသးစူၤမၤအီၤတက့ၢ်ႋ",
    "gay": "Gay (ခွါအဲၣ်ခွါ), lesbian (မုၣ်အဲၣ်မုၣ်), မ့တမ့ၢ် homosexual (အဲၣ်ပကၢၤ)",
    "heterosexual": "Heterosexual (အဲၣ်ပကၢၤ) မ့တမ့ၢ် straight (မ့တမ့ၢ်အဲၣ်မုၣ်ခွါသွံၣ်ထံးပတီၢ်မုၢ်)",
    "bisexual": "Bisexual (ပှၤအဲၣ်ပိာ်မုၣဒီးခွါခံမံၤလိာ်)",
    "questioning": "အိၣ်ဒီးတၢ်သးဒ့ဒီ/တလီၤတံၢ်/တသ့ၣ်ညါဘၣ်",
    "prefer_not_to_disclose": "ဃုထၢလၢတပာ်အိၣ်ဖျါထီၣ်",
    "unknown": "တသ့ၣ်ညါဘၣ်",
    "orientation_not_listed": "တထၢနုာ်လီၤတၢ်သးစဲလၢစရီပူၤ",
    "not_applicable": "မသက်ဆိုင်ပါ",
    "pansexual": "ပန်းဆက်ချူဝဲ",
    "queer": "ခွီးယား"
  },
  "pronouns": {
    "title": "မံၤလံာ်ကျိၤဖုၣ်လၢတၢ်အဲၣ်ဒိး",
    "he": "ပိာ်ခွါ",
    "she": "ပိာ်မုၣ်",
    "they": "အ၀ဲသ့ၣ်",
    "choose_not_to_disclose": "ဃုထၢလၢတပာ်အိၣ်ဖျါထီၣ်",
    "prefer_to_self_describe": "အဲၣ်ဒိးလၢနီၢ်ကစၢ်တဲဖျါထီၣ်"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "လံာ်ဟ့ၣ်ခွဲးနီၣ်သိလ့ၣ် မ့တမ့ၢ်ထံကီၢ်ထံဖိကီၢ်ဖိနီၣ်ဂံၢ်",
    "driver_license": "လံာ်ဟ့ၣ်ခွဲးနီၣ်သိလ့ၣ်",
    "use_ssn_instead": "စူးကါလၢ SSN အလီၢ်",
    "social_security_number": "ပှၤဂ့ၢ်၀ီတၢ်ကွၢ်ထွဲနီၣ်ဂံၢ်",
    "state": "ကီၢ်စဲၣ်",
    "upload_front_of_driver_license": "တီၣ်ထီၣ်နတၢ်နီၣ်သိလ့ၣ်အလံာ်အုၣ်သးအမဲာ်သၣ်တပၤ မ့တမ့ၢ် ဒုးနဲၣ် ID နီၣ်ဂံၢ်",
    "choose_file": "ဃုထၢ file (လံာ်တြံာ်တၢ်မၤနီၣ်)",
    "no_file_chosen": "တလိၣ်ဃုထၢ file (လံာ်တြံာ်တၢ်မၤနီၣ်)",
    "no_identification": "ယနီၢ်ကစၢ်လံာ်အုၣ်သးတအိၣ်",
    "insurance_card_has_a_back": "ယတၢ်အုၣ်ကီၤအလံာ်ခးက့အိၣ်ဒီးအလီၢ်ခံတပၤ",
    "upload_front_of_insurance_card": "တီၣ်ထီၣ်နတၢ်အုၣ်ကီၤအလံာ်ခးက့အမဲာ်သၣ်တပၤတက့ၢ်.",
    "front_of_insurance_card_uploaded": "နတၢ်အုၣ်ကီၤအလံာ်ခးက့အမဲာ်သၣ်တပၤတီၣ်ထီၣ်သးဝံၤလံ.",
    "insurance_card": "တၢ်အုၣ်ကီၤအလံာ်ခးက့",
    "insurance_card_back": "တၢ်အုၣ်ကီၤအလံာ်ခးက့အလီၢ်ခံတပၤ",
    "back_of_insurance_card_uploaded": "နတၢ်အုၣ်ကီၤအလံာ်ခးက့အလီၢ်ခံတပၤတီၣ်ထီၣ်သးဝံၤလံ."
  },
  "quidel": {
    "certify_text": "ယအုၣ်သးလၢယကွဲးနုာ်လီၤထဲလၢတၢ်မၤကွၢ်အံၤအဆၢတဘျီ",
    "entering_results": "ကွဲးနုာ်လီၤနတၢ်အဆၢတဖၣ်",
    "review_instructions_1": "ကွၢ်ကဒါက့ၤတၢ်နဲၣ်ကျဲတဖၣ်လီၤတံၢ်လီၤဆဲး",
    "review_instructions_2": "နကွၢ်ကဒါက့ၤတၢ်နဲၣ်လီၤတဖၣ်လၢအပၣ်ဃုာ်ဖဲနတၢ်မၤကွၢ်လိၤ ဖးတၢ်နဲၣ်ကျဲတပတီၢ်ဘၣ်တပတီၢ် မ့တမ့ၢ်ကွၢ်တၢ်သိၣ်လိနဲၣ်လိလီၤဖဲတၢ်ဂီၤမူလၢလာ်အံၤ-",
    "read_instructions": "ဖးတၢ်နဲၣ်လီၤတဖၣ်",
    "watch_video": "ကွၢ်တၢ်ဂီၤမူ",
    "timer": "စးထီၣ်ပိးလီထိၣ်တၢ်ဆၢကတီၢ်ဖဲတၢ်ခွဲးခွးနီၣ်ဟံးန့ၢ်တၢ်အဒိ (swab) လၢတၢ်မၤကွၢ်ဂီၢ်ဖဲပီၤဘိပူၤ နီၣ်ထိၣ်တၢ်ဆၢကတီၢ်ကပတုာ်ဖဲ 1 မံးနံးအလီၢ်ခံ (လၢနကထုးထီၣ်ကွံာ်နီၣ်ဟံးန့ၣ်တၢ်အဒိ (swab) ဖဲပီၤဘိပူၤဂီၢ်) ၀ံသးစူၤကွဲးနီၣ် နကဘၣ်စးထီၣ်ကဒါက့ၤနီၣ်ထိၣ်တၢ်ဆၢကတီၢ်လၢကစးထီၣ်မၤနတၢ်မၤကွၢ်အဆၢဂီၢ် 10 မံးနံးႋ",
    "start_timer": "စးထီၣ်နီၣ်ထိၣ်တၢ်ဆၢကတီၢ် (အိၣ်ဒီးကျဲဃုထၢ)",
    "restart_timer": "ပတုာ်ကွံာ်ဒီးစးထီၣ်ကဒါက့ၤ",
    "resume": "စးထီၣ်မၤကဒါက့ၤ",
    "one_minute_countdown": "1 မံးနံးလဲၤပူၤကွံာ်- ထုးကွံာ်နနီၣ်ဟံးန့ၢ်တၢ်အဒိ (swab) လၢပီၤဘိပူၤဒီးစးထီၣ်မၤကဒါက့ၤနီၣ်ထိၣ်တၢ်ဆၢကတီၢ်",
    "take_photo": "ဒိနတၢ်မၤကွၢ်ပိးလီအကလုာ်အဂီၤ",
    "photo_warning": "ဟ့ၣ်လီၤတၢ်ဂီၤမ့ၢ်တၢ်အကါဒိၣ်လၢတၢ်မၤတၢ်ဆူညါအဂီၢ်",
    "enter_results": "ထၢနုာ်လီၤနတၢ်မၤကွၢ်အဆၢ",
    "choose_result": "ဃုထၢတၢ်မၤကွၢ်ပိးလီလၢအဂ့ၤကတၢၢ်လၢနတၢ်မၤကွၢ်အဂီၢ်န့ၣ်တက့ၢ်ႋ လိၣ်ဘၣ်တၢ်မၤစၢၤဧါ? စံၢ်လီၤဖဲအံၤလၢကကွၢ်တၢ်အဆၢတဖၣ်အဒိန့ၣ်တက့ၢ်ႋ",
    "positive": "အိၣ်ဒီးတၢ်ဆါအဃၢ်",
    "negative": "တၢ်ဆါတအိၣ်ဘၣ်",
    "unknown": "တသ့ၣ်ညါဘၣ်",
    "clarify": "မၤဖျါဆှံထီၣ်နတၢ်အဆၢတဖၣ်",
    "strip_question": "နတၢ်မၤကွၢ်အဖျ့ဖးထီဖဲလဲၣ်တဖၣ်လၢအလီၤဂာ်လိာ်သးအါကတၢၢ်န့ၣ်လဲၣ်?",
    "no_lines": "ကျိၤပနိတဖၣ်တအိၣ်ဘၣ်",
    "pink_line": "ထဲကျိၤပနိဂီၤစၢ်",
    "not_sure": "တလီၤတံၢ်ဒံးဘၣ်"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "တၢ်ဂီၤလၢဒိန့ၢ်",
    "retake_photo": "တၢ်ဂီၤဒိက့ၤအသီ",
    "capture_photo": "တၢ်ဂီၤလၢဖီၣ်န့ၢ်",
    "confirm_information_correct": "ယပာ်ဂၢၢ်ပာ်ကျၢၤလၢတၢ်ဂ့ၢ်တၢ်ကျိၤလၢထးမ့ၢ်တၢ်လၢအဘၣ်လီၤတံၢ်လီၤဆဲးန့ၣ်လီၤႋ",
    "submit": "တီၣ်ထီၣ်",
    "title": "ဒိလီၤတၢ်အစၢလၢ {{name}}",
    "subtitle": "အလီၢ်တအိၣ်လၢနဘၣ်ထုးလီၤပာ် မ့တမ့ၢ် စူးကါ iHealth COVID-19 အဲး(ပ) သ့န့ၣ်လီၤႋ",
    "instruction_title": "ကွၢ် iHealth တၢ်နဲၣ်လီၤ",
    "instruction_guide": "ဖးကွၢ်တၢ်နဲၣ်လီၤ",
    "video_guide": "ကွၢ်တၢ်ဂီၤမူ",
    "add_photo": "တီၣ်ထီၣ်တၢ်ဂီၤ",
    "confirm_result": "ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်အစၢ",
    "result_warning": "ဃုထၢတၢ်အစၢလၢကတီၣ်ထီၣ်အဂီၢ်",
    "confirm_warning": "ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်အစၢလၢကတီၣ်ထီၣ်အဂီၢ်",
    "administered_at_label": "နမၤကွၢ်တၢ်အံၤအခါဖဲလဲၣ်ႋ",
    "instruction_subtitle": "ကွၢ်ဖးဘၣ်တၢ်နဲၣ်လီၤလၢဟဲပၣ်ဃုာ်ဒီးနတၢ်မၤကွၢ်သး မ့တမ့ၢ် ကွၢ်ဖးဘၣ်တၢ်နဲၣ်လီၤဖဲအံၤတက့ၢ်ႋ"
  },
  "public_test_groups": {
    "title": "ပီးလီတၢ်မၤကွၢ် COVID-19",
    "appointment_recoveries_title": "ဆှၢက့ၤဧါႋ",
    "appointment_recoveries_button_text": "ဃုကွၢ်ယပှာ်ဘျးစဲ",
    "search_test_group_title": "မံၤခီၣ်ထံး",
    "search_test_group_button_text": "ဆဲးလီၤမံၤခဲအံၤ",
    "title_2": "တၢ်ဆဲးလီၤမံၤဒီးတၢ်ပာ်ဖျါအညီ",
    "step_one": "တၢ်ဆဲးလီၤမံၤဒုးအိၣ်ထီၣ်စရီ",
    "step_two": "မၤန့ၢ်ထးခိၣ်ထံအဒိလၢကဆှၢက့ၤအဂီၢ်",
    "step_three": "ကွၢ်တၢ်အစၢလၢပှာ်ယဲၤဖီခိၣ်",
    "title_3": "ဆဲးလီၤမံၤလၢကမၤမူထီၣ်နတၢ်ပီးလီအဂီၢ်",
    "population_title": "တၢ်ဆဲးလီၤမံၤ",
    "population_button": "မၤမူထီၣ်",
    "faq_subtitle": "ကွၢ်အါထီၣ်အဂ့ၢ်",
    "faq_title": "တၢ်သံကွၢ်တဖၣ်လၢဘၣ်တၢ်သံကွၢ်အီၤခဲအံၤခဲအံၤ",
    "faq_1": "ယဘၣ်ဆှၢက့ၤယတၢ်မၤကွၢ်အပီးလီဒ်လဲၣ်ႋ",
    "faq_1_footer": "ကွၢ်ဘၣ်တၢ်နဲၣ်လီၤလၢ ပီးလီဒၢထးခိၣ်ထံပျိာ်ဘိ",
    "faq_2": "ယဆဲးလီၤမံၤ၀ံၤလံ ဘၣ်ဆၣ်ယသးပ့ၤနီၣ်ယစရီႋ",
    "faq_3": "ယဘၣ်မၤမူထီၣ်ယတၢ်ပီးလီဒ်လဲၣ်ႋ",
    "faq_2_subtitle": "ဒ်ပှၤစူးကါတၢ်ဖိတဂၤလၢဆှၢက့ၤတၢ်အသိး နပာ်ဂၢၢ်ပာ်ကျၢၤဘၣ်နတၢ်ပာ်ဖျါသ့ခီဖျိ တၢ်စူးကါ'**Find my link**'ပနီၣ်လၢထးန့ၣ်လီၤႋ",
    "faq_3_subtitle": "၀ံသးစူၤ လဲၤဘၣ်လၢ Primary.Health စရီကျိၤကျဲ ခီဖျိတၢ်စူးကါကျဲလၢလာ်အံၤ-",
    "footer_text_1": "ပနံာ်တၢ်ဖိတၢ်လံၤအံၤ တဘၣ် FDA ပာ်ဂၢၢ်ပာ်ကျၢၤ မ့တမ့ၢ် အၢၣ်လီၤတူၢ်လိာ်အီၤဒံးဘၣ် သနာ်က့ ဘၣ်တၢ်ဟ့ၣ်ခွဲးစိကမီၤအီၤလၢတၢ်စူးကါသ့လၢဂ့ၢ်ဂီၢ်အူအဂီၢ်ခီဖျိ FDA ဒ်လၢ EUA ဖီလာ်အသိး-",
    "footer_text_2": "ပနံာ်တၢ်ဖိတၢ်လံၤအံၤ ဘၣ်တၢ်ဟ့ၣ်ခွဲးစိကမီၤအီၤ ထဲလၢတၢ်ထၢန့ၢ်ဒီးဟံးပာ်ဃာ် ထးခိၣ်ထံအဒိတဖၣ် ဒ်အမ့ၢ် တၢ်မၤစၢၤတမံၤလၢ တၢ်ဃုသ့ၣ်ညါဘၣ် နယူချံယါအဲးစ့းထံ လၢ SARS-CoV-2 အဂီၢ် တမ့ၢ်လၢဘဲရၢး မ့တမ့ၢ် တၢ်ဆါဃၢ်အဂုၤဂၤတဖၣ်အဂီၢ်ဘၣ်န့ၣ်လီၤ-",
    "footer_text_3": "ဂ့ၢ်ဂီၢ်အူတၢ်စူးကါပနံာ်တၢ်ဖိတၢ်လံၤအံၤ ဘၣ်တၢ်ဟ့ၣ်ခွဲးစိကမီၤအီၤထဲလၢ တၢ်ထုးထီၣ်ရၤလီၤတၢ်အိၣ်သးလၢကဲထီၣ်သးအဂ့ၢ်အဆၢကတီၢ် လၢတၢ်ဆီတလဲဘှီဘၣ်ကွံာ် တၢ်ဟ့ၣ်ခွဲးစိကမီၤ ဂ့ၢ်ဂီၢ်အူတၢ်စူးကါဆူၣ်ချ့ဂ့ၢ်၀ီပီးလီတဖၣ်အဂီၢ် ဒ်လၢ Federal Food, Drug and Cos- metic Act (ကီၢ်စၢဖှိၣ်တၢ်အီၣ်တၢ်အီဒီးကသံၣ်ကသီဒီးတၢ်ကယၢကယဲသဲစးတၢ်ဘျၢ) အဆၢနီၤဖး 564(b)(1), 21 U.S.C. § 360bbb-3(b)(1) မ့တမ့ၢ်ဘၣ်လၢတၢ်ဘိးဘၣ်ရၤလီၤန့ၣ် ဘၣ်တၢ်ထုးကွံာ်အီၤ မ့တမ့ၢ် တၢ်ဟ့ၣ်ခွဲးစိကမီၤန့ၣ်ဘၣ်တၢ်ဆိကတီၢ်ကွံာ်အီၤလၢတယံာ်ဘၣ်တီၢ်ပူၤန့ၣ်လီၤႋ",
    "description": "လၢကမ့ၢ်တၢ်ဘီၣ်တၢ်ညီလၢနဂီၢ်အဂီၢ် နမၤမူထီၣ်နပီးလီမၤကွၢ်တၢ် ခီဖျိတၢ်စူးကါနီၣ်စံၢ်ပနီၣ်လၢလာ်သ့-",
    "subtitle": "နုာ်လီၤဘၣ် Primary ခီၣ်ထံးတၢတဘျီဧါႋ",
    "subtitle_1": "တၢ်ဂ့ၢ်ဘၣ်ဃးန ပီးလီတၢ်မၤကွၢ် COVID-19",
    "faq_1_1": "ပာ်လီၤနီၣ်ထၢထးခိၣ်ထံဒိပျိာ်ဘိလၢအိၣ်ဒီးအခိၣ်ပံးဆူထးခိၣ်ထံဒိအဒၢပူၤ",
    "faq_1_2": "ပာ်လီၤထးခိၣ်ထံဒိအဒၢ ဆူတလါဖိလၢကျးသးဒီး SalivaDirect လံာ်မံၤပနီၣ်လၢဟဲပာ်ဃုာ်တက့ၢ်ႋ ပာ်လီၤ တလါဖိလၢကျးသးဒီး SalivaDirect လံာ်မံၤပနီၣ် ဆူ FedEx UN 3373 Pak တက့ၢ်ႋ",
    "faq_1_3": "ထုးကွံာ် FedEx UN 3373 Pak နီၣ်ကျးခိၣ်ပံး ဒီးဆီၣ်လီၤကျၢၤကျၢၤလၢကပံးကွံာ် Pak ဃံးဃံးတက့ၢ်ႋ",
    "faq_1_4": "၀ံသးစူၤ စီၤလီၤနထးခိၣ်ထံအဒိဖဲမၤန့ၢ်အီၤတနံၤဃီ ဒီးတချုးတၢ်ဟံးန့ၢ်က့ၤအီၤအကတၢၢ်တဘျီအဆၢကတီၢ်တက့ၢ်ႋ တဘၣ်ဆှၢနထးခိၣ်ထံအဒိ လၢလံာ်ပရၢစိၤထီထူၣ်ဖဲနွံကတၢၢ်န့ၣ်တဂ့ၤႋ",
    "faq_1_5": "ကွၢ်ဘၣ် FedEx ပှာ်ယဲၤသန့ဖဲ **[fedex.com/labreturns](https://www.fedex.com/labreturns)** ဒ်သိးကကွၢ်ဘၣ်တၢ်ဆှၢလီၤတၢ်ဖိတၢ်လံၤအလီၢ်ဒီးတၢ်ဟံးန့ၢ်အီၤအဆၢကတီၢ်တက့ၢ်ႋ",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "ပာ်ဖျါထီၣ်တၢ်အစၢ",
    "report_result_subtitle": "နတၢ်ကရၢကရိမ့ၢ်ဟ့ၣ်လီၤမၤစၢၤနၤတၢ်မၤကွၢ်လီၤနီၢ်ကစၢ်သး, ဒီးနမ့ၢ်အိၣ်ကတီၤသးလၢတၢ်ပာ်ဖျါထီၣ်တၢ်အစၢအဂီၢ်န့ၣ်, ဝံသးစူၤစံၢ်လီၤဖဲ button လၢလာ်အံၤအလိၤတက့ၢ်.",
    "need_help": "လိၣ်ဘၣ်တၢ်မၤစၢၤဧါ.",
    "assistance": "နမ့ၢ်လိၣ်ဘၣ်တၢ်တိစၢၤမၤစၢၤ, မ့တမ့ၢ် အဲၣ်ဒိးဆီတလဲနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤန့ၣ်, ဝံသးစူၤဆဲးကျိးဘၣ် Support Center (တၢ်ဆီၣ်ထွဲမၤစၢၤအလီၢ်) တက့ၢ်.",
    "contact_support": "ဆဲးကျိး support (တၢ်ဆီၣ်ထွဲမၤစၢၤအလီၢ်)",
    "save_link": "ပာ်ကီၤဃာ် ပှာ်ယဲၤသန့အတၢ်ဘျးစဲ (link) အံၤလၢနကပာ်ဖျါထီၣ်တၢ်အစၢလၢခံလၢလာ်အဂီၢ်.",
    "send_via_email": "ဆှၢ link ခီဖျိအံမ့(လ)",
    "send_via_text": "ဆှၢ link ခီဖျိ SMS text",
    "copy_link": "ကွဲးဒိဃာ် link",
    "resend_email": "ဆှၢဒါက့ၤအံမ့(လ)လၢတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအသီတဘျီအဂီၢ်",
    "phone_verified": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလီတဲစိနီၣ်ဂံၢ်",
    "add_vaccination_status": "မၤအါထီၣ်/တီၣ်ထီၣ် COVID-19 ကသံၣ်ဆဲးဒီသဒၢအတၢ်အိၣ်သးတဖၣ်",
    "terms_of_service": "တၢ်ဖံးစၢၤမၤစၢၤအတၢ်ဘျၢတဖၣ်",
    "verify_contact_information": "သမံထံပာ်ဂၢၢ်ပာ်ကျၢၤနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤဒ်သိးနဒိးန့ၢ်ဝဲဒၣ်တၢ်အစၢဒီးတၢ်ဆဲးကျိးဆဲးကျၢတဖၣ်ကသ့အဂီၢ်န့ၣ်လီၤ. နမ့ၢ်အဲၣ်ဒိးဆီတလဲနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤန့ၣ်, ဝံသးစူၤဆဲးကျိးဘၣ်ပ Support Center တက့ၢ်.",
    "resend_text": "ဆှၢဒါက့ၤ SMS text လၢတၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအသီတဘျီအဂီၢ်",
    "loading": "Loading (ထၢနုာ်လီၤသးအဖၢမုၢ်)",
    "add_to_account": "ထၢနုာ်အါထီၣ်ဖဲစရီပူၤ",
    "hi": "ဆၢဂ့ၤဆၢဝါ",
    "email_verified": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤအံမ့(လ)",
    "go_to_my_account": "နုာ်လီၤဖဲယစရီပူၤ",
    "activate_kit": "မၤမူထီၣ်ပီးလီမၤကွၢ်တၢ်",
    "report_custom_result": "တီၣ်ဖျါတၢ်အစၢ- {{test}}",
    "activate_custom_test": "မၤမူထီၣ်တၢ်မၤကွၢ် {{test}}",
    "update_vaccination_status": "မၤသီထီၣ်တၢ်ဆဲးကသံၣ်ဒီသဒၢအဂ့ၢ်",
    "order_a_test_kit": "မၤလိာ်တၢ်မၤကွၢ်အစူၣ်"
  },
  "enter_information": {
    "contact_info": "ဒိလီၤနတၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "label": "လီတဲစိ မ့တမ့ၢ် အံမ့(လ)",
    "invalid": "တၢ်အဝဲအံၤမ့ၢ်အံမ့(လ) မ့တမ့ၢ် လီတဲစိနီၣ်ဂံၢ်လၢတၢ်စူးကါအီၤတသ့.",
    "send": "ပကဆှၢ တၢ်ခူသူၣ်နီၣ်ဂံၢ် (code) ဆူနလီတဲစိ မ့တမ့ၢ် အံမ့(လ)အပူၤ",
    "legal": "ခီဖျိနတၢ်ထၢနုာ်လီၤလီတဲစိနီၣ်ဂံၢ် မ့တမ့ၢ် အံမ့(လ)အဃိ, နတူၢ်လိာ်ဝဲဒၣ် ဆူၣ်ချ့အဂံၢ်ထံးခီၣ်ဘိ (Primary Health) အ **[တၢ်ဖံးစၢၤမၤစၢၤအတၢ်ဘျၢ](https://primary.health/terms-of-service/)** ဒီး **[တၢ်ခူသူၣ်အဖီလစံၣ်](https://primary.health/privacy-policy/)** န့ၣ်လီၤ.",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "Registered? Find your link",
    "not_registered": "တဆဲးလီၤမံၤဘၣ်ဧါ. ဃုကွၢ်နပှာ်ဘျးစဲ",
    "search_by_keyword": "ဃုကွၢ်ခီဖျိမံၤ, လံာ်ဖျၢၣ်အရ့ဒိၣ်, ၀့ၢ်, စံး(ပ)ဟီၣ်က၀ီၤနီၣ်ဂံၢ်, မ့တမ့ၢ် တၢ်ကရၢကရိအနီၣ်ဂံၢ်.",
    "search": "ဃုုကွၢ်",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "တၢ်ဂ့ၢ်တၢ်ကျိၤလၢတၢ်စူးကါအီၤတသ့",
    "invalid_explanation": "နဃ့ထီၣ်ဝဲဒၣ်ပှာ်ယဲၤသန့အ link ဖဲတယံာ်ဒံးဘၣ်အံၤလၢနကနုာ်လီၤဖဲ Primary.Health ပူၤအဂီၢ်န့ၣ်လီၤ. ဒ်လဲၣ်ဂ့ၤ,",
    "never_registered": "တဆဲဲးလီၤမံၤလၢညါနီတဘျီဘၣ်ဧါ. ၀ံသးစူၤဆဲးကျၢနတၢ်သမံသမိးပှၤဟ့ၣ်တၢ်မၤစၢၤ လၢတၢ်ဆဲးလီၤမံၤ ပှာ်ဘျးစဲ မ့တမ့ၢ် ဃုကွၢ်နတၢ်လီၢ်တၢ်ကျဲလၢလာ်န့ၣ်တက့ၢ်.",
    "get_help": "နဒုးဘၣ်ထွဲဝဲဒၣ်နတၢ်မၤနီၣ်မၤဃါဒီးဆူနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤသ့ဝဲ",
    "get_help_calling": "မ့တမ့ၢ် ကိးလီတဲစိ",
    "invalid_explanation_bold": "တၢ်ဂ့ၢ်တၢ်ကျိၤလၢနဟ့ၣ်လီၤတၢ်စူးကါအီၤတသ့ဘၣ်န့ၣ်လီၤ.",
    "get_help_label": "ဃ့တၢ်မၤစၢၤ",
    "mobile": {
      "invalid_explanation": "တထံၣ်န့ၢ်ပှၤစူးကါတၢ်လၢတီၣ်ထီၣ်သးဆူနစရီအပူၤဘၣ်ႋ",
      "get_help": "နတၢ်သံကွၢ်အဂုၤဂၤမ့ၢ်အိၣ် ၀ံသးစူၤဆဲးကျၢ",
      "reason": "တမ့ၢ် ပှၤစူးကါ ဂံၢ်ခီၣ်ထံးဆူၣ်ချ့ဂ့ၢ်၀ီ လၢအခွဲးကအိၣ်လၢ စရီအသီအဂီၢ်ဘၣ်န့ၣ်လီၤႋ တုၤလၢနခွဲးယာ်အိၣ်၀ဲန့ၣ် နကဒိးန့ၢ်ဘၣ် တၢ်ကွဲမုာ်တဘ့ၣ် လၢ ဂံၢ်ခီၣ်ထံးဆူၣ်ချ့ဂ့ၢ်၀ီ မ့တမ့ၢ် ဟဲခီဖျိလၢနကရူၢ်ပှၤပၢဆှၢရဲၣ်ကျဲၤတၢ် န့ၣ်လီၤႋ"
    },
    "zen_name": "တၢ်ဆဲးကျၢအမံၤ",
    "ticket_type": "တၢ်ဂ့ၢ်ဘၣ်ထွဲဒီးမနုၤလဲၣ်",
    "appointment_access_code": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤနီၣ်ဂံၢ်ခူသူၣ် (မ့ၢ်သ့ၣ်ညါ)",
    "zen_desc": "တၢ်ကစီၣ်",
    "contact_us": "ဆဲးကျၢပှၤ",
    "need_assistance": "နမ့ၢ်လိၣ်ဘၣ်တၢ်တိစၢၤမၤစၢၤ, မ့တမ့ၢ် အဲၣ်ဒိးဆီတလဲနတၢ်ဆဲးကျိးအဂ့ၢ်အကျိၤ, ဝံသးစူၤဆဲးကျိးဘၣ်တၢ်ဆီၣ်ထွဲမၤစၢၤအလီၢ်တက့ၢ်ႋ"
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "တီၣ်ထီၣ်ဆူစရီ",
    "error": "ပှၤပၣ်ဃုာ်မၤသကိးတၢ်အံၤဆဲးလီၤတ့ၢ်လံအမံၤလၢစရီလံ",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "တၢ်ခူသူၣ်နီၣ်ဂံၢ် (code) မ့ၢ်မနုၤလဲၣ်",
    "enter_code": "ထၢနုာ်လီၤ တၢ်ခူသူၣ်နီၣ်ဂံၢ် (code) လၢတၢ်ဆှၢဝဲဆူ",
    "incorrect_code": "နထၢနုာ်လီၤ တၢ်ခူသူၣ်နီၣ်ဂံၢ် (code) ကမၣ်.",
    "verify": "တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤစရီ",
    "verified": "ဘၣ်တၢ်ပာ်ဂၢၢ်ပာ်ကျၢၤလံ",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "နဟံးန့ၢ်မၤဝဲဒၣ်တၢ်မၤကွၢ်မနုၤလဲၣ်.",
    "photo_required": "တၢ်လိၣ်ဘၣ်တၢ်ဂီၤအစၢ",
    "find_your_test": "ဃုထံၣ်န့ၢ်နတၢ်မၤကွၢ်"
  },
  "login": {
    "create_account": "ဒုးအိၣ်ထီၣ်နစရီ",
    "create_account_help": "တၢ်ဒုးအိၣ်ထီၣ်မံၤစရီန့ၣ်တဒ်သိးသိးဒီးတၢ်ဆဲးလီၤမံၤလၢတၢ်မၤကွၢ်အဂီၢ် မ့တမ့ၢ် တၢ်ဆဲးကသံၣ်ဒီသဒၢအဂီၢ်ဘၣ်. ဖဲနဆဲးလီၤမံၤ၀ံၤအခါ",
    "create_account_help_schedule": "တၢ်ရဲၣ်ကျဲၤပာ်လီၤတၢ်သ့ၣ်နံၤဖးသီအဆၢကတီၢ်",
    "create_account_help_record": "မၤနီၣ်မၤဃါဃာ်တၢ်မၤကွၢ်လၢဟံၣ်",
    "create_account_help_match": "ရဲၣ်ကျဲၤကသံၣ်ဆဲးဒီသဒၢဒီးတၢ်မၤကွၢ်အတၢ်မၤနီၣ်မၤဃါတဖၣ်.",
    "continue": "ဆဲးနုာ်လီၤဒ်ပှၤတမှံၤအသိး",
    "already": "နအိၣ်တ့ၢ်လံဒီးနစရီလံဧါ. စံၢ်လီၤဖဲ",
    "login": "လၢ တၢ်နုာ်လီၤ (login) အဂီၢ်",
    "or_sign_in": "မ့တမ့ၢ် တၢ်နုာ်လီၤ (sign in) ဃုာ်ဒီး-",
    "no_account": "နစရီတအိၣ်ဘၣ်မ့ၢ်ဧါ. စံၢ်လီၤဖဲ",
    "signup": "လၢ တၢ်ဟးထီၣ် (signup) အဂီၢ်.",
    "here": "ဖဲအံၤ",
    "email_address": "အံမ့(လ)အမံၤ (Email address)-",
    "password": "တၢ်ခူသူၣ်နီၣ်ဂံၢ် (Password)-",
    "complete_registration": "တၢ်မၤနီၣ်မၤဃါသးဝံၤဝဲဒၣ်",
    "last_covid_test": "တၢ်မၤကွၢ် COVID-19 အစၢအကတၢၢ်တဘျီ-",
    "no_record": "တၢ်မၤနီၣ်မၤဃါတအိၣ်",
    "viewing_information": "ကွၢ်ဘၣ် {{first_name}} အဂ့ၢ်အကျိၤ",
    "download": "ထၢနုာ်လီၤ (Download)",
    "not_registered": "ဖျါလီၤဂာ်လၢနတမၤနီၣ်မၤဃါသးဖဲကရူၢ်နီတဖုအပူၤ",
    "please_follow_link": "ဝံသးစူၤလူၤထွဲတၢ်ကွဲမုာ်အ link ဖဲနအံမ့(လ)ပူၤလၢနကရဲၣ်ကျဲၤလီၤဒီးနုာ်လီၤမၤန့ၢ်တၢ်ဂ့ၢ်တၢ်ကျိၤဒီး Primary.Health ကသ့အဂီၢ်.",
    "log_results": "မၤနီၣ်ယတၢ်မၤကွၢ်သးအစၢတဖၣ်",
    "book_appointment": "မၤဆိပာ်တၢ်သ့ၣ်နံၤဖးသီ",
    "no_new_appointments": "ကတီၢ်အံၤတၢ်တတူၢ်လိာ်ဝဲဒၣ်တၢ်သ့ၣ်နံၤဖးသီအသီတဖၣ်ဘၣ်",
    "upload_vaccinations": "တၢ်တီၣ်ထီၣ်ကသံၣ်ဆဲးဒီသဒၢအဂ့ၢ်",
    "vaccination": "ကသံၣ်ဆဲးဒီသဒၢ",
    "new_appointment": "တၢ်သ့ၣ်နံၤဖးသီအသီ",
    "log_otc_results": "တၢ်မၤနီၣ်မၤဃါတၢ်အစၢတဖၣ်လၢတၢ်မၤကွၢ် OTC အဂီၢ်",
    "no_household_members": "နတအိၣ်ဒီးဟံၣ်ဖိဃီဖိတဖၣ်ဖဲနဟံၣ်ပူၤဒံးဘၣ်.",
    "no_upcoming": "တအိၣ်ဒီးတၢ်သ့ၣ်နံၤဖးသီအဆၢကတီၢ်လၢအကတုၤဃီၤ",
    "update_vaccination": "တၢ်မၤသီထီၣ်ကသံၣ်ဆဲးဒီသဒၢ",
    "upload_vaccination": "တၢ်တီၣ်ထီၣ်ကသံၣ်ဆဲးဒီသဒၢအဂ့ၢ်",
    "title": "တူၢ်လိာ်မုာ်နၤဖဲ Primary.Health",
    "sub_title": "Before registering please create an account.",
    "p1": "တၢ်ဒုးအိၣ်ထီၣ်နစရီမၤစၢၤနၤသ့ဝဲလၢ-",
    "p2": "တၢ်ရဲၣ်ကျဲၤပာ်လီၤတၢ်သ့ၣ်နံၤဖးသီအဆၢကတီၢ်ဆူညါ",
    "p3": "တၢ်မၤနီၣ်မၤဃါတၢ်မၤကွၢ် COVID-19 အဂ့ၢ်ဖဲဟံၣ်",
    "p4": "တၢ်သုးကျဲၤပှၤလၢအဒိးသန့ၤထီၣ်သးဒီးနၤအတၢ်ဆဲးကသံၣ်ဒီသဒၢဒီးအတၢ်မၤကွၢ်သးအဂ့ၢ်",
    "p5": "တၢ်နုာ်လီၤဖဲ my.primary.health အကျိၤအကျဲအပူၤလၢအယိယိဖိ",
    "no_vaccination_record": "ကသံၣ်ဒီသဒၢအဂ့ၢ်တအိၣ်ဒံးဘၣ်",
    "vaccination_title_card": "Covid-19 ကသံၣ်ဒီသဒၢတၢ်အိၣ်သး",
    "account_and_settings": "နီၣ်ဂံၢ် ဒီး တၢ်ဆီလီၤပာ်လီၤ",
    "delete_account": "ထူးသံစရီ",
    "language": "ကျိာ်",
    "language_current": "ခဲအံၤကျိာ်- {{language}}",
    "log_out": "ဟးထီၣ်",
    "delete_account_title": "နပာ်သးလီၤတံၢ်လၢကထူးသံကွံာ်နစရီဧါႋ",
    "new_encounter": "{{first_name}} အတၢ်ကွၢ်ဆၢၣ်မဲာ်အသီ",
    "new_encounter_subtitle": "တၢ်ကွၢ်ဆၢၣ်မဲာ်အသီအိၣ်လၢကရူၢ်ဖဲလဲၣ်တဖုအဂီၢ်လဲၣ်ႋ",
    "no_programs": "ဖျါလၢနတဆဲးလီၤဘၣ်မံၤဒံးဘၣ်လၢတၢ်တိာ်ကျဲၤနီတမံၤဘၣ်",
    "edit_members": "ဘှီဘၣ်ကရၢဖိတဖၣ်",
    "edit_members_subtitle": "ဃုထၢလီၢ်ခၢၣ်သနၢၣ်လၢနဘၣ်သးလဲၤ မ့တမ့ၢ် တီၣ်အါထီၣ်ကရၢဖိဆူနစရီ",
    "add_members": "တီၣ်အါထီၣ်ကရၢဖိ",
    "delete_member": "ထူးသံ {{full_name}} ပှၤစူးကါစရီဧါႋ",
    "delete_member_subtitle": "တၢ်ထုးကွံာ်ပှၤစူးကါတၢ်န့ၣ် ကထူးသံကွံာ်တၢ်ဂ့ၢ်တၢ်ကျိၤအိၣ်လၢစရီပူၤခဲလၢာ်",
    "select_member": "ကရၢဖိအလီၢ်",
    "edit_appointment": "ဘှီဘၣ်တၢ်သ့ၣ်ဆၢကတီၢ်",
    "route": {
      "me": "လီၢ်ခၢၣ်သနၢၣ်",
      "history": "ဂ့ၢ်စံၣ်စိၤ",
      "programs": "ပာ်ထီၣ်တၢ်မၤကွၢ်",
      "contact_us": "မၤစၢၤ",
      "choose_member": "ကရၢဖိ",
      "settings": "တၢ်ဆီလီၤပာ်လီၤ",
      "help": "မၤစၢၤ"
    },
    "user_dashboard": "{{first_name}} အလီၢ်ခၢၣ်သနၢၣ်",
    "view_full_results": "ကွၢ်ဘၣ်လၢတၢ်အစၢအဖီးထၢၣ်ပူၤ",
    "required": "နမ့ၢ်တစူးကါ Primary App န့ၣ်တၢ်လိၣ်ဘၣ်အီၤ",
    "no_email": "ယအံမ့(လ) တအိၣ်ဘၣ်"
  },
  "vaccination_status": {
    "not_vaccinated": "တဆဲးဘၣ်ဒံးကသံၣ်ဆဲးဒီသဒၢ",
    "fully_vaccinated": "ဆဲးဘၣ်ကသံၣ်ဆဲးဒီသဒၢလၢလၢပှဲၤပှဲၤ",
    "partially_vaccinated": "ဆဲးဘၣ်ကသံၣ်ဆဲးဒီသဒၢတနီၤ",
    "vaccination_records": "တၢ်မၤနီၣ်မၤဃါတၢ်ဆဲးကသံၣ်ဒီသဒၢ",
    "title": "န COVID-19 တၢ်ဆဲးကသံၣ်ဒီသဒၢအပတီၢ်န့ၣ်မ့ၢ်တၢ်မနုၤလဲၣ်.",
    "definition_title": "တၢ်ဆဲးကသံၣ်ဒီသဒၢအခီပညီ",
    "definition_cdc": "CDC တၢ်ဆဲးကသံၣ်ဒီသဒၢအခီပညီ",
    "definition_osha": "OSHA တၢ်ဆဲးကသံၣ်ဒီသဒၢအခီပညီ",
    "definition": {
      "fully_vaccinated": "2 နွံဖဲတၢ်ဆဲးကသံၣ်ဒီသဒၢလၢခံကတၢၢ်လၢအိၣ်ဒီး တၢ်ဆဲးကသံၣ်ဒီသဒၢ Pfizer-BioNTech မ့တမ့ၢ်Moderna 2 ဘျီစံရံမ့ၢ်ဂ့ၤ, မ့တမ့ၢ် ကသံၣ်ဒီသဒၢ Johnson & Johnson's Janssen 1 ဘျီ၀ံၤအလီၢ်ခံန့ၣ်လီၤ.",
      "partially_vaccinated": "ဆဲးဘၣ်ထဲဂံၢ်ခီၣ်ထံးကသံၣ်ဒီသဒၢ 2 ဘျီအကျါ 1 ဘျီလၢဂံၢ်ခီၣ်ထံးစံရံအပူၤ, မ့တမ့ၢ် စှၤန့ၢ် 2 နွံဖဲ ဆဲးဘၣ်ကသံၣ်ဒီသဒၢလၢခံကတၢၢ်တဘျီလၢ ကသံၣ်ဒီသဒၢ 2 ဘျီစံရံအပူၤ မ့တမ့ၢ် Johnson & Johnson's Janssen 1 ဘျီ၀ံၤအလီၢ်ခံန့ၣ်လီၤ.",
      "not_vaccinated": "တၢ်တရဲၣ်ကျဲၤဆဲး COVID-19 ကသံၣ်ဒီသဒၢနီတမံၤဘၣ်.",
      "fully_vaccinated_with_one_booster": "ဆဲးဘၣ် တၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ် Pfizer-BioNTech မ့တမ့ၢ် Moderna ဖဲဆဲးကသံၣ်ဒီသဒၢစံရံပှဲၤပှဲၤ, မ့တမ့ၢ် Johnson & Johnson's Janssen ကသံၣ်ဒီသဒၢ 1 ဘျီ၀ံၤအလီၢ်ခံန့ၣ်လီၤ.",
      "fully_vaccinated_with_two_boosters": "ဆဲးဘၣ် တၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ် Pfizer-BioNTech မ့တမ့ၢ် Moderna ဖဲဆဲးကသံၣ်ဒီသဒၢစံရံပှဲၤပှဲၤ, မ့တမ့ၢ် Johnson & Johnson's Janssen ကသံၣ်ဒီသဒၢဒီးတၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဘူးစထၢၣ်၀ံၤအလီၢ်ခံန့ၣ်လီၤ."
    },
    "label": {
      "fully_vaccinated": "ဆဲးကသံၣ်ဒီသဒၢပှဲၤပှဲၤ",
      "partially_vaccinated": "ဆဲးကသံံၣ်ဒီသဒၢတဖှံၣ်တ၀ာ်",
      "not_vaccinated": "တဆဲးကသံၣ်ဒီသဒၢဘၣ်",
      "fully_vaccinated_with_one_booster": "ဆဲးကသံၣ်ဒီသဒၢပှဲၤပှဲၤ+ ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်",
      "fully_vaccinated_with_two_boosters": "ဆဲးကသံၣ်ဒီသဒၢပှဲၤပှဲၤ+ ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ် 2 ဘျီ",
      "prefer_not_to_answer": "တဘၣ်သးစံးဆၢဘၣ်"
    },
    "progress_bar_title": "COVID-19 တၢ်ဆဲးကသံၣ်ဒီသဒၢတၢ်စံၣ်စိၤ",
    "upload_record_card": "မၤသီထီၣ် COVID-19 တၢ်ဆဲးကသံၣ်ဒီသဒၢတၢ်မၤနီၣ်မၤဃါခးက့",
    "show_example": "ဒုးနဲၣ်တၢ်အဒိ",
    "hide_example": "ပာ််ခူသူၣ်တၢ်အဒိ",
    "take_photo": "စံၢ်လီၤလၢကဒိတၢ်ဂီၤ",
    "or": "မ့တမ့ၢ်",
    "upload_image_or_pdf_instead": "ပာ်ထီၣ်တၢ်ဂီၤ/PDF လၢအလီၢ်",
    "select_doses_received": "ဃုထၢ COVID-19 တၢ်ဆဲးကသံၣ်ဒီသဒၢအဘျီခဲလၢာ်လၢနဒိးန့ၢ်ဘၣ်အီၤတက့ၢ်.",
    "first_dose": "ကသံၣ်ဒီသဒၢအခီၣ်ထံးတဘျီ",
    "second_dose": "ကသံံၣ်ဒီသဒၢခံဘျီတဘျီ",
    "first_booster_dose": "တၢ်ဆဲဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်အခီၣ်ထံးတဘျီ",
    "second_booster_dose": "တၢ်ဆဲဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်ခံဘျီတဘျီ",
    "additional_dose": "ကသံၣ်ဒီသဒၢအဘျီအဂၤ",
    "first_dose_manufacturer": "မ့ၢ်မတၤထုးထီၣ် COVID-19 ကသံၣ်ဒီသဒၢလၢနကဆဲးအခီၣ်ထံးတဘျီအဂီၢ်လဲၣ်.",
    "first_dose_date": "နဆဲးကသံၣ်ဒီသဒၢအခီၣ်ထံးတဘျီအမုၢ်နံၤမုၢ်သီမ့ၢ်မနုၤလဲၣ်.",
    "second_dose_manufacturer": "မ့ၢ်မတၤထုးထီၣ် COVID-19 ကသံၣ်ဒီသဒၢလၢနကဆဲးခံဘျီတဘျီအဂီၢ်လဲၣ်.",
    "second_dose_date": "နဆဲးကသံၣ်ဒီသဒၢခံဘျီတဘျီအမုၢ်နံၤမုၢ်သီမ့ၢ်မနုၤလဲၣ်.",
    "first_booster_dose_manufacturer": "မ့ၢ်မတၤထုးထီၣ် တၢ်ဆဲးဆူၣ်ထီၣ် COVID-19 ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်လၢနကဆဲးအခီၣ်ထံးတဘျီအဂီၢ်လဲၣ်.",
    "first_booster_dose_date": "နတၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်အခီၣ်ထံးတဘျီအမုၢ်နံၤမုၢ်သီမ့ၢ်မနုၤလဲၣ်.",
    "second_booster_dose_manufacturer": "မ့ၢ်မတၤထုးထီၣ် တၢ်ဆဲးဆူၣ်ထီၣ် COVID-19 ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်လၢနကဆဲးခံဘျီတဘျီအဂီၢ်လဲၣ်",
    "second_booster_dose_date": "နတၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်ခံဘျီတဘျီအမုၢ်နံၤမုၢ်သီမ့ၢ်မနုၤလဲၣ်.",
    "additional_dose_manufacturer": "မ့ၢ်မတၤထုးထီၣ် COVID-19 ကသံၣ်ဒီသဒၢလၢနကဆဲးအါထီၣ်ကဒီးတဘျီအဂီၢ်လဲၣ်.",
    "additional_dose_date": "နဆဲးအါထီၣ်ကသံၣ်ဒီသဒၢကဒီးတဘျီအမုၢ်နံၤမုၢ်သီမ့ၢ်မနုၤလဲၣ်.",
    "completed_one": "၀ံၤလံ",
    "completed_two": "တၢ်ဘျုးလၢနမၤသီထီၣ်နတၢ်ဆဲးကသံၣ်ဒီသဒၢအပတီၢ်အဂီၢ်လီၤ.",
    "progress_bar_complete": "၀ံံၤလံ",
    "upload_image": "ပာ်ထီီၣ်တၢ်ဂီၤ",
    "retake_photo": "ဒိကဒါက့ၤတၢ်ဂီၤ",
    "other": "အဂၤ",
    "remove_first_dose": "တြူာ်သံကွံာ်ကသံၣ်ဒီသဒၢအခီၣ်ထံးတဘျီတၢ်စံၣ်စိၤ",
    "remove_second_dose": "တြူာ်သံကွံာ်ကသံၣ်ဒီသဒၢခံဘျီတဘျီတၢ်စံၣ်စိၤ",
    "remove_first_booster_dose": "တြူာ်သံကွံာ်တၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်အခီၣ်ထံးတဘျီတၢ်စံၣ်စိၤ",
    "remove_second_booster_dose": "တြူာ်သံကွံာ်တၢ်ဆဲးဆူၣ်ထီၣ်ကသံၣ်ဒီသဒၢဂံၢ်ဘါဘူးစထၢၣ်ခံဘျီတဘျီတၢ်စံၣ်စိၤ",
    "remove_additional_dose": "တြူာ်သံကွံာ်တၢ်ဆဲးအါထီၣ်ကသံၣ်ဒီသဒၢကဒီးတဘျီတၢ်စံၣ်စိၤ",
    "exemption": "နအိၣ်ဒီးတၢ်ပျဲပူၤဖျဲးဧါ.",
    "exempt": "ယဘၣ်တၢ်ပျဲပူၤဖျဲးယၤလီၤ",
    "not_exempt": "ယတဘၣ်တၢ်ပျဲပူၤဖျဲးယၤဘၣ်",
    "enter_exemption": "ထၢနုာ်လီၤတၢ်ပျဲပူၤဖျဲး",
    "upload_exemption_documentation": "ပာ်ထီၣ််လံာ်တီလံာ်မီလၢနတၢ်ပျဲပူၤဖျဲးအဂီၢ်",
    "remove_dose": "တြူာ်သံကွံာ်ကသံၣ်ဒီသဒၢအဘျီ",
    "continue": "ဆဲးဆူညါ",
    "enter_credentials": "၀ံသးစူၤထၢနုာ်လီၤနတၢ်အုၣ်ကီၤသး",
    "incorrect_credentials": "တၢ်အုၣ်ကီၤသးတဘၣ်ဘၣ်, ၀ံသးစူၤမၤကွၢ်ကဒီးတဘျီ.",
    "add_photo": "ထၢနုာ််လီၤတၢ်ဂီၤ"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "ဟါလိၣ် {{name}}, ပသ့ၣ်ညါဘၣ်လၢ နရဲၣ်ကျဲၤပာ်လီၤန {{group_name}} တၢ်သ့ၣ်ဆၢကတီၢ်ဒီး {{org_name}} တ၀ံၤဒံးဘၣ်န့ၣ်လီၤႋ ဒ်သိးကလဲၤဘၣ်နတၢ်သ့ၣ်ဆၢကတီၢ်အဂီၢ် နဘၣ်မၤပှဲၤကွံာ်တၢ်ဟ့ၣ်တၢ်ဘူးတၢ်လဲအပတီၢ်န့ၣ်လီၤႋ ၀ံသးစူၤစံၢ်လီၤဖဲပှာ်ဘျးစဲအံၤလၢကမၤ၀ံၤလၢပှဲၤဘၣ် တၢ်ဖံးတၢ်မၤကျိၤကျဲ {{complete_url}} တက့ၢ်ႋ နမ့ၢ်အဲၣ်ဒိးဆိကတီၢ်ကွံာ်နတၢ်သ့ၣ်ဆၢကတီၢ် ၀ံသးစူၤစံၢ်လီၤဖဲပှာ်ဘျးစဲအံၤ {{cancel_url}} တက့ၢ်ႋ တၢ်ဘျုး"
    }
  },
  "otc": {
    "record_results_for_abbott": "မၤကွၢ်ဒီးမၤနီၣ်နတၢ်အစၢလၢ BinaxNOW တၢ်မၤကွၢ်အဂီၢ်",
    "record_results": "မၤကွၢ်ဒီးမၤနီၣ်နတၢ်အစၢ",
    "activate_a_kit": "မၤမူထီၣ်ပီးလီ",
    "activate_a_kit_for_test": "မၤမူထီၣ်ပီးလီလၢ {{test}}",
    "todays_test_process": "တနံၤအံၤအတၢ်မၤကွၢ်ကျိၤကျဲ-",
    "date_of_birth_question": "{{first_name}} အတၢ်အိၣ်ဖျဲၣ်နံၤသီမ့ၢ်ဒ်လဲၣ်ႋ",
    "confirm_dob_to_continue": "၀ံသးစူၤပာ်ဂၢၢ်ပာ်ကျၢၤပှၤနုာ်လီၤပၣ်ဃုာ်သကိးတၢ်အအိၣ်ဖျဲၣ်နံၤသီလၢကလဲၤဆူညါအဂီၢ်-",
    "incorrect_dob": "အိၣ်ဖျဲၣ်နံၤသီကမၣ်",
    "file_too_large": "၀ံသးစူၤလံာ်တြူာ်လၢတီၣ်ထီၣ်သးအတၢ်ဒိၣ်တၢ်ဆံးလၢကဆံးန့ၢ် {{size}}",
    "enter_valid_date_in_last_x_days": "ဒိလီၤနံၤသီလၢအပူၤကွံာ် {{n}} သီ",
    "barcode_format_not_valid": "ဘးခိးသနိနုာ်လီၤတန့ၢ်",
    "complete_button": "၀ံၤလၢပှဲၤ",
    "next_button": "ဆူညါ",
    "back_button": "ဆူလီၢ်ခံ",
    "report_a_test": "တီၣ်ဖျါတၢ်မၤကွၢ်-",
    "x_%_completed": "လၢပှဲၤထီၣ် {{n}}%",
    "answer_survey": "စံးဆၢတၢ်ဃုသ့ၣ်ညါအတၢ်သံကွၢ်တဖၣ်",
    "steps": {
      "answer_survey": "စံးဆၢတၢ်ဃုသ့ၣ်ညါ",
      "review_instructions": "ကွၢ်သမံသမိးတၢ်နဲၣ်လီၤ",
      "scan_code": "ဒိလီၤတၢ်ခူသူၣ်နီၣ်ဂံၢ်",
      "enter_time": "ဒိလီၤတၢ်ဆၢကတီၢ်",
      "enter_result": "ဒိလီၤတၢ်အစၢ",
      "take_photo": "ဒိတၢ်ဂီၤ",
      "confirm_result": "ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်အစၢ",
      "selest_test_kit": "ဃုထၢတၢ်မၤကွၢ်အကလုာ်",
      "enter_date": "ဒိလီၤနံၤသီ",
      "enter_date_and_time": "ဒိလီၤနံၤသီဒီးတၢ်ဆၢကတီၢ်"
    },
    "review_instructions": "ကွၢ်သမံသမိးတၢ်မၤကွၢ်အတၢ်နဲၣ်လီၤ",
    "follow_instructions_or_below": "မၤပိာ်ထွဲတၢ်နဲၣ်လီၤလၢဟဲပၣ်ဃုာ်ဒီးနတၢ်မၤကွၢ် မ့တမ့ၢ် ကွၢ်သမံသမိးဘၣ်တၢ်နဲၣ်လီၤလၢလာ်တက့ၢ်ႋ",
    "watch_video": "ကွၢ်ဘံၢ်ဒံၢ်အိၣ်",
    "view_instructions": "ကွၢ်သမံသမိးတၢ်နဲၣ်လီၤ",
    "follow_instructions": "မၤပိာ်ထွဲတၢ်နဲၣ်လီၤလၢဟဲပၣ်ဃုာ်ဒီးနတၢ်မၤကွၢ်တက့ၢ်ႋ",
    "scan_code": "ဒိလီၤတၢ်မၤကွၢ် QR ပနီၣ်",
    "qr_code_description": "QR ပနီၣ်မ့ၢ်တၢ်အိၣ်လီၤဆီလၢနတၢ်မၤကွၢ်ဒီးထံၣ်ဘၣ်အီၤသ့ဖဲနတၢ်မၤကွၢ်အဒီခိၣ်စုထွဲတကပၤ",
    "show_example": "ပာ်ဖျါတၢ်အဒိ",
    "hide_example": "ပာ်သဒၢကွံာ်တၢ်အဒိ",
    "barcode_scanned": "ဒိလီၤဘးခိးပနီၣ်န့ၢ်လၢပှဲၤ",
    "enter_barcode": "ဒိလီၤဘးခိး",
    "scan_qr_code": "ဒိလီၤ QR ပနီၣ်",
    "which_test_did_you_take": "နမၤကွၢ်သးဒီးတၢ်မၤကွၢ်ဖဲလဲၣ်တခါလဲၣ်ႋ",
    "when_did_you_take_test": "နမၤကွၢ်သးအခါဖဲလဲၣ်ႋ",
    "what_day_did_you_take_test": "နမၤကွၢ်သးလၢမုၢ်နံၤမနုၤလဲၣ်ႋ",
    "what_time_did_you_take_test": "နမၤကွၢ်သးလၢပှဲၤနၣ်ရံၣ်လဲၣ်ႋ",
    "time": {
      "today": "တနံၤအံၤ",
      "yesterday": "မဟါတနံၤ",
      "two_days_ago": "အပူၤကွံာ် 2 သီ"
    },
    "enter_current_time": "ဒိလီၤကတီၢ်အံၤတၢ်ဆၢကတီၢ်",
    "enter_result": "ဒိလီၤတၢ်မၤကွၢ်အစၢ",
    "choose_result_option": "ဃုထၢတၢ်ဃုထၢလၢပာ်ဖျါဒုးနၢ်ပၢၢ်နတၢ်မၤကွၢ်ခးအဂ့ၢ်ပိာ်ထွဲထီၣ်-",
    "clarify_results": "မၤလီၤတံၢ်နတၢ်အစၢတဖၣ်",
    "take_result_photo": "ဒိတၢ်အစၢအဂီၤတဘ့ၣ်",
    "take_result_photo_description": "ဒိန့ၢ်ခးအစၢလၢမၤန့ၢ်သးလၢပှဲၤဒ်သိးကမၤနီၣ်နတၢ်မၤအစၢတဖၣ်တက့ၢ်ႋ",
    "my_result": "ယတၢ်ဂ့ၢ်အစၢ",
    "switch_camera": "ဆီတလဲခဲပရၢ်",
    "confirm_result": "ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်အစၢ",
    "name": "မံၤ-",
    "date": "နံၤသီ-",
    "result": "အစၢ-",
    "results": "တၢ်အစၢ",
    "test_submitted": "တၢ်ဘျုး, {{first_name}} န {{test}} တီၣ်ထီၣ်သး၀ံၤလၢလၢပှဲၤပှဲၤလံန့ၣ်လီၤႋ",
    "dob": "အိၣ်ဖျဲၣ်နံၤသီ-",
    "administered": "ဘၣ်တၢ်ပၢဆှၢရဲၣ်ကျဲၤ-",
    "or": "မ့တမ့ၢ်",
    "upload": "တီၣ်ထီၣ်",
    "change_uploaded_file": "ဆီတလဲလံာ်တြူာ်လၢတီၣ်ထီၣ်အီၤ",
    "take_photo": "ဒိတၢ်ဂီၤ",
    "confirm_information": "ပာ်ဂၢၢ်ပာ်ကျၢၤတၢ်ဂ့ၢ်တၢ်ကျိၤ",
    "barcode": "ဘးခိး-",
    "scan_test_barcode": "ဒိလီၤတၢ်မၤကွၢ်ဘးခိး",
    "barcode_description": "ဘးခိးမ့ၢ်တၢ်အိၣ်လီၤဆီလၢနတၢ်မၤကွၢ်ဒီးထံၣ်ဘၣ်အီၤသ့ဖဲနတၢ်မၤကွၢ်အလိၤ",
    "enter_date_in_last_30_days": "ဒိလီၤနံၤသီအပူၤကွံာ် 30 သီဒီးတၢ်ဆၢကတီၢ်အဘူးကတၢၢ်လၢမၤဘၣ်တၢ်မၤကွၢ်",
    "add_image": "တီၣ်ထီၣ်တၢ်ဂီၤ",
    "change_image": "ဆီတလဲတၢ်ဂီၤ",
    "skip_barcode_reader": "ဒိလီၤတၢ်ခူသူၣ်နီၣ်ဂံၢ်တန့ၢ်ဘၣ်ဧါႋ စံၢ်လီၤဆူညါလၢကတီၣ်ထီၣ်တၢ်မၤကွၢ်အစၢ",
    "enter_date_and_time": "ဒိလီၤတၢ်မၤကွၢ်အနံၤသီဒီးတၢ်ဆၢကတီၢ်",
    "enter_date": "ဒိလီၤတၢ်မၤကွၢ်အနံၤသီ",
    "did_you_take_test_today": "တနံၤအံၤနမၤဘၣ်တၢ်မၤကွၢ်လံဧါႋ",
    "record_results_for_generic": "မၤကွၢ်ဒီးမၤနီၣ်မၤဃါနတၢ်အစၢတဖၣ်လၢတၢ်မၤကွၢ်အ {{name}} အဂီၢ်",
    "choose_result_option_custom": "Choose the option that describes your {{test_name}} outcome:"
  },
  "yes": "မ့ၢ်",
  "no": "တအိၣ်",
  "event_token": {
    "title": "၀ံသးစူၤဒိလီၤတၢ်ခူသူၣ်နီၣ်ဂံၢ်လၢလာ်ဆူစဲးဆါတၢ်အပူၤဒ်သိးကထုးထီၣ်နတၢ်မၤကွၢ်မၤကွၢ်အပီးလီအဂီၢ်",
    "loading_text": "ထုးလီၤတၢ်ဂ့ၢ် ၀ံသးစူၤသမံထံကွၢ်က့ၤလၢတမံးနံး"
  },
  "appointment_recovery": {
    "complete_title": "နအိၣ်ဘၣ်လီၢ်ဘၣ်ကျဲလံ",
    "subtitle_1": "နမ့ၢ်စူးကါ iPhone န့ၣ် ၀ံသးစူၤဆီၣ်လီၤ **Done** ဖဲလီတဲစိမဲာ်သၣ်စုစ့ၣ်တၢ်ဖီခိၣ်နၢၣ်ထံးကပၤ လၢကက့ၤဘၣ်ဆူနလီၢ်ခၢၣ်သနၢၣ်တက့ၢ်ႋ",
    "subtitle_2": "နမ့ၢ်စူးကါ Android န့ၣ် ဆီၣ်လီၤ **X** ဖဲလီတဲစိမဲာ်သၣ်စုစ့ၣ်တၢ်ဖီခိၣ်နၢၣ်ထံးကပၤတက့ၢ်ႋ",
    "subtitle_0": "၀ံသးစူၤဟးထီၣ်ကွံာ်လၢပှာ်ယဲၤသန့ကျိၤလၢကလဲၤဆူညါအဂီၢ်တက့ၢ်ႋ"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "အၢၣ်လီၤဟ့ၣ်ခွဲးတၢ်ရ့လိာ်မုာ်လိာ်",
  "finish_registration": {
    "title": "မၤပှဲၤနတၢ်ဆဲးလီၤမံၤ",
    "subtitle": "၀ံသးစူၤမၤပှဲၤတၢ်ဂ့ၢ်တၢ်ကျိၤပိာ်ထွဲလၢလာ် တချုးလၢနတၢ်သ့ၣ်နံၤဖးသီ-",
    "button": "Finish registration"
  },
  "arab_ethnicity": {
    "arab": "အၣ်ရး(ဘ)",
    "non_arab": "တမ့ၢ််- အၣ်ရး(ဘ)",
    "prefer_not_to_answer": "တဘၣ်သးစံံးဆၢ",
    "question": "အၣ်ရး(ဘ) ကလုာ်ဒူၣ်",
    "subtitle": "တၢ်သိၣ်တၢ်သီတၢ်ဘျၢလၢတၢ်လိၣ်ဘၣ်အီၤလၢပထၢဖှိၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤပိာ်ထွဲလၢလာ်တဖၣ်.",
    "help": "ကလုာ်ဒူၣ်ဘၣ်ထွဲဒီးလုၢ်လၢ်သကဲာ်ပ၀းအါတက့ၢ်ဒ်အမ့ၢ်ကျိာ်, တၢ်ဆဲးတၢ်လၤထူသနူ, တၢ်ဘူၣ်တၢ်ဘါ, ဒီးတၢ်အရူၢ်အသသဲးအဂၤလၢညီနုၢ်မၤလီၤဆီပှၤအကရူၢ်တဖၣ်လီၤ. တၢ်အံၤကဘၣ် မ့တမ့ၢ် တဘၣ်လိာ်ဖိးမံဒီးစၢၤသွဲၣ်ကလုာ်ဒူၣ်တၢ်အုၣ်သးစ့ၢ်ကီးဘၣ်လီၤ."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "ဒိးသန့ၤထီၣ်သးဖဲနတၢ်ပိးတၢ်လီအဖီခိၣ်, စံၢ်လီၤဖဲ link လၢလာ်အံၤအလိၤလၢတၢ်ထုးထီၣ်ဟံးန့ၢ် Primary Health Pass (တၢ်ဖျိဝဲဒၣ်ဆူၣ်ချ့အခီၣ်ထံးခီၣ်ဘိ) န့ၣ်တက့ၢ်ႋ"
    }
  },
  "stepper": {
    "next": "လဲၤဆူညါ",
    "back": "က့ၤဆူအလီၢ်ခံ",
    "complete": "၀ံၤလံံ"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "အိးထီၣ်ခဲမရၢ်အဲး(ပ)လၢနလီတဲစိပီးလီအဖီခိၣ်",
    "point_to_qr_code": "နဲၣ်ဖဲ QR နီၣ်ဂံၢ် ဒီးစံၢ်လီၤဖဲလ့း(ခ)",
    "complete_registration": "မၤပှဲၤတၢ်ဆဲးလီၤမံၤ",
    "need_help": "လိၣ်ဘၣ်တၢ်မၤစၢၤဧါ. ဆဲးကျိးပှၤဖဲ"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "တၢ်အုၣ်သးပာ်ဖျါကသံၣ်ဆဲးဒီသဒၢတၢ်ဆါအဂ့ၢ်အကျိၤ",
    "select_checkbox": "ဝံသးစူၤဃုထၢဒီးကွၢ်ကဒါက့ၤတၢ်မၤနီၣ်လီၤတၢ်အတလါဒၢလာ်အံၤတချုးဒံးဖဲတၢ်မၤဝဲဒၣ်တၢ်မၤကျိၤကျဲကြၢးဝဲဘၣ်ဝဲအခါတက့ၢ်ႋ",
    "opportunity_to_read": "ယအိၣ် မ့တမ့ၢ် အိၣ်တ့ၢ်လံဒီးတၢ်ဟ့ၣ်လီၤခွဲးယာ်လၢတၢ်ဖး မ့တမ့ၢ် တၢ်ဖးန့ၢ်ယၤ, Vaccine Information Statement(s) (“VIS”) (ကသံၣ်ဆဲးဒီသဒၢတၢ်ဆါအဂ့ၢ်အကျိၤအလံာ်ပာ်ဖျါ) မ့တမ့ၢ် Emergency Use Authorization (“EUA”) (တၢ်ဟ့ၣ်စိဟ့ၣ်ကမီၤဂ့ၢ်ဂီၢ်အူတၢ်စူးကါ) တၢ်မၤသးနီၢ်နီၢ်အလံာ်က့လၢတၢ်ဟ့ၣ်လီၤဝဲလၢတၢ်ကပၢဆှၢရဲၣ်ကျဲၤကသံၣ်ဆဲးဒီသဒၢ(တဖၣ်)အဂီၢ်-",
    "vaccine_info_sheet": "ကသံၣ်ဆဲးဒီသဒၢတၢ်ဆါအဂ့ၢ်အကျိၤအလံာ်က့",
    "vaccine_fact_sheet": "ကသံၣ်ဆဲးဒီသဒၢတၢ်ဆါနီၢ်နီၢ်အလံာ်က့",
    "vaccine_info_statement": "ကာကွယ်ဆေး အချက်အလက်ထုတ်ပြန်ချက်"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio (တၢ်ဆါလီၤဘျ့)",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella (တၢ်ဆါလၢၢ်ထံ)",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "Kit (တၢ်ပီးတၢ်လီအစူၣ်) အံၤပၣ်ဃုာ်ဒီးတၢ်မၤကွၢ်ဝဲဒၣ် chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး gonorrhea (ဃဲသဲတၢ်ဆါ) အဂ့ၢ်န့ၣ်လီၤႋ",
    "B": "Kit (တၢ်ပီးတၢ်လီအစူၣ်) အံၤပၣ်ဃုာ်ဒီးတၢ်မၤကွၢ်ဝဲဒၣ် chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး gonorrhea (ဃဲသဲတၢ်ဆါ) အဂ့ၢ်န့ၣ်လီၤႋ",
    "C": "Kit (တၢ်ပီးတၢ်လီအစူၣ်) အံၤပၣ်ဃုာ်ဒီးတၢ်မၤကွၢ်ဝဲဒၣ် syphilis (တၢ်အိၣ်ဃုာ်လိာ်သးအတၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်တကလုာ်) အဂ့ၢ်န့ၣ်လီၤႋ",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "Kit (တၢ်ပီးတၢ်လီအစူၣ်) အံၤပၣ်ဃုာ်ဒီးတၢ်မၤကွၢ်ဝဲဒၣ် HIV ဒီး syphilis (တၢ်အိၣ်ဃုာ်လိာ်သးအတၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်တကလုာ်) အဂ့ၢ်န့ၣ်လီၤႋ",
    "box_type": "တလါဒ {{type}}",
    "administered_text": "ပကဆဲးကျိးနၤဖဲန kit (တၢ်ပီးတၢ်လီအစူၣ်) တုၤဃီၤဖဲတၢ်မၤကွၢ်ဒၢးအခါဒီးဖဲတၢ်ဒိးန့ၢ်တၢ်အစၢအခါန့ၣ်လီၤႋ",
    "activate_kit": "အိးထီၣ်စူးကါယ test kit (တၢ်မၤကွၢ်အပီးအလီအစူၣ်)",
    "register_another_test": "တၢ်ဆဲးလီၤမံၤလၢတၢ်မၤကွၢ်အဂၤတဘျီအဂီၢ်",
    "credentials": {
      "title": "ပကစးထီၣ်မၤ",
      "label": "တၢ်ဆဲးကျိး",
      "p1": "ပကစးထီၣ်မၤတၢ်ဂ့ၢ်တၢ်ကျိၤတနီၤနီၤလၢအဘၣ်ဃးဒီးနၤအဂ့ၢ်န့ၣ်လီၤႋ",
      "location_step": {
        "p1": "မတၤဟ့ၣ်လီၤနၤ test kit လဲၣ်ႋ",
        "load": "ထုးထီၣ်ဟံးန့ၢ်အါထီၣ်"
      }
    },
    "checklist": {
      "not_urinated": "နၣ်ရံၣ်တၢ်ဆၢကတီၢ်ဖဲအပူၤကွံာ်နတဆံၣ်ဆါဒံးဘၣ်န့ၣ်လီၤႋ",
      "bathroom": "နနုာ်လီၤဖဲတၢ်ဟးလီၢ်လၢတၢ်ဟံးန့ၢ်ပာ်နဆံၣ်လၢတၢ်မၤကွၢ်အဂီၢ်န့ၣ်လီၤႋ",
      "anal_swab": "နနုာ်လီၤဖဲနနီၢ်ကစၢ်တၢ်သဒၢအလီၢ်တတီၤတီၤလၢ anal swab (ဘဲပျံာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲခံပူၤ) လၢတၢ်မၤကွၢ်အဂီၢ်သ့ဝဲန့ၣ်လီၤႋ",
      "blood_extraction": "နနုာ်လီၤဖဲတၢ်ကဆှဲကဆှီအလီၢ်တတီၤတီၤလၢ ထုးထီၣ်ဟံးန့ၢ်သွံၣ် (blood extraction) လၢတၢ်မၤကွၢ်အဂီၢ်သ့ဝဲန့ၣ်လီၤႋ",
      "washed_hands": "နကဘၣ်သ့ကဆှီနစုဒီးဆးပၠၣ်ဒီးထံကလၢၤလၢအယံာ်ဝဲ 30 စဲးကးန့ၣ်လီၤႋ",
      "title": "မ့ၢ်နအိၣ်ကတီၤသးလၢတၢ်စးထီၣ်ဟံးန့ၢ်ပာ်တၢ်မၤကွၢ်အဒိတဖၣ်အဂီၢ်လံဧါႋ",
      "no_alcohol": "ဖဲနဟံးန့ၢ်ပာ်တၢ်မၤကွၢ်အဒိအနံၤတဘၣ်အီသံးတဂ့ၤႋ",
      "menstruation": "တဘၣ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲ vaginal swab specimen (ပိာ်မုၣ်က့ၢ်ဂီၤအပူၤ) ဖဲလုၢ်လၢ်ဟဲအကတီၢ် မ့တမ့ၢ် ဖဲတၢ်အိၣ်ဃုာ်လိာ်သး 24 နၣ်အကတီၢ်ပူၤအခါတဂ့ၤႋ"
    },
    "confirm": {
      "title": "သမံထံပာ်ဂၢၢ်ပာ်ကျၢၤနတၢ်ဂ့ၢ်တၢ်ကျိၤ",
      "p1": "မ့ၢ်တၢ်ဂ့ၢ်တၢ်ကျိၤအံၤဘၣ်ဧါႋ"
    },
    "display_name": {
      "A": "Chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး Gonorrhea (ဃဲသဲတၢ်ဆါ) (တၢ်လီၢ်တတီၤ)",
      "B": "Chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး Gonorrhea (ဃဲသဲတၢ်ဆါ) (တၢ်လီၢ် 3 တီၤ)",
      "C": "Syphilis (တၢ်အိၣ်ဃုာ်လိာ်သးအတၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်တကလုာ်)",
      "shortened": {
        "A": "Chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး Gonorrhea (ဃဲသဲတၢ်ဆါ)",
        "B": "Chlamydia (တၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်) ဒီး Gonorrhea (ဃဲသဲတၢ်ဆါ)",
        "C": "Syphilis (တၢ်အိၣ်ဃုာ်လိာ်သးအတၢ်ဘၣ်ဂာ်တၢ်ဆါဃၢ်တကလုာ်)"
      },
      "CC": "Colorectal Cancer Screening (FIT)",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "တၢ်သမံထံကွၢ်ကဒါက့ၤ",
    "scanned": "နသမံထံကွၢ်ဝဲဒၣ်တလါအဒၢ {{type}}",
    "not_yet": "တမၤဘၣ်ဒံးဘၣ်",
    "success_registration": "ဆဲးလီၤမံၤဝံၤလံႋ",
    "no_box": "နတလါဒၢတအိၣ်ဒံးဘၣ်ဧါႋ",
    "faq_link": "ကွၢ်ဘၣ်တၢ်သံကွၢ်စံးဆၢတဖၣ်အဂ့ၢ်",
    "welcome": "တၢ်တူၢ်လိာ်မုာ်ႋ",
    "scan": {
      "title": "နဘူးထီၣ်ဝဲဖဲနတၢ်အိၣ်ဆူၣ်အိၣ်ချ့ဂ့ၤထီၣ်အခီၣ်ထံးတပတီၢ်န့ၣ်လီၤႋ",
      "p1": "ဒိလီၤ မ့တမ့ၢ် ထၢနုာ်လီၤ **kit bar code** (**တၢ်ပီးတၢ်လီအစူၣ်အနီၣ်ဂံၢ်ခူသူၣ်**) ဖဲနတလါဒၢအကပၤန့ၣ်တက့ၢ်ႋ"
    },
    "appointments": {
      "title": "ဃုထၢပှၤဆူးပှၤဆါဒီးပှၤရဲၣ်ကျဲၤမၤစၢၤတၢ်ဖိ",
      "p1": "ပထံၣ်န့ၢ်အါထီၣ်နီၢ်ကစၢ်တၢ်ဂ့ၢ်ဖုၣ်ကိာ်တခါလၢအဘၣ်ထွဲဒီးနတၢ်ဆဲးကျိးဆဲးကျၢအဂ့ၢ်အကျိၤန့ၣ်လီၤႋ ဃုထၢနီၢ်ကစၢ်တၢ်ဂ့ၢ်ဖုၣ်ကိာ်လၢအဘၣ်တခါ-",
      "new": "ပှၤဆူးပှၤဆါ မ့တမ့ၢ် ပှၤရဲၣ်ကျဲၤမၤစၢၤတၢ်ဖိအသီ"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "ပထံၣ်န့ၢ်ဝဲဒၣ်နပှၤဆူးပှၤဆါအတၢ်မၤနီၣ်မၤဃါန့ၣ်လီၤႋ"
    },
    "section_1": "သမံထံပာ်ဂၢၢ်ပာ်ကျၢၤ kit အဂ့ၢ်အကျိၤတဖၣ်",
    "section_2": "တၢ်ထၢဖှိၣ်တၢ်အဒိလၢတၢ်မၤကွၢ်အဂီၢ်",
    "section_3": "တၢ်ဘိၣ်",
    "section_4": "တၢ်ဆှၢဟးထီၣ်တၢ်",
    "box_a": "တလါဒၢ A",
    "box_b": "တလါဒၢ B",
    "box_c": "တလါဒၢ C",
    "kit_flow": {
      "button_1": "ဆဲးမၤလၢတၢ်ဘိၣ်တၢ်စှၢၤအဂီၢ်",
      "button_2": "ယအိၣ်ကတီၤသးလံ",
      "button_3": "တၢ်မၤဝံၤဒီးတၢ်ဘိၣ်ထီၣ်ပာ်ယ kit လံ",
      "button_4": "ယမၤဝံၤလံ",
      "button_5": "တၢ်မၤကွၢ်ဆူညါ",
      "button_6": "လဲၤကပၤတၢ်နဲၣ်ကျဲဆူညါတခါ"
    },
    "contents": {
      "header": "ဂ့ၤလံႋ ပကမၤလီၤတံၢ်ဝဲဒၣ်တၢ်မၤဝံၤန kit အဂ့ၢ်",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "တၢ်ထၢဖှိၣ်ပာ်သွံၣ်အဒိအလံာ်ခးက့",
      "blood_collection_card_subtitle": "(1 မ့တမ့ၢ် 2 ဒိးသန့ၤထီၣ်သးဖဲ test kit အဖီခိၣ်)",
      "alchohol_pads": "Alcohol pads (ဘဲအဲးကဟီထွါစီတၢ်) 2 ကဘျံး",
      "lancets": "lancet (ဒီကွဲးတၢ်) လၢတၢ်စူးကါတဘျီဂီၢ်",
      "up_to_other": "(တုၤလၢ 4 ဘျီဒိးသန့ၤထီၣ်သးဖဲ test kit အဖီခိၣ်)",
      "adhesive_bandages": "Adhesive bandages (ဖျးစထၢၣ်ပံာ်ဘၢတၢ်ပူၤလီၢ်)",
      "pipette": "Pipette (တၢ်မၤကွၢ်အပီၤဘိဖိတကလုာ်)",
      "urine_tube": "Urine collection tube (ပီၤဘိလၢအထုးထီၣ်ဟံးန့ၢ်ဆံၣ်အဒိ)",
      "urine_cup": "Urine collection cup (ပီၤဘိလၢအဟံးန့ၢ်ပာ်ဖှိၣ်ဆံၣ်အဒိ)",
      "oral_swab": "Oral swab (ဘဲပျံာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲကိာ်ပူၤ)",
      "oral_tube": "Oral collection tube (ပီၤဘိလၢအထုးထီၣ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲကိာ်ပူၤ)",
      "anal_swab": "Anal swab (ဘဲပျံာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲခံပူၤ)",
      "anal_tube": "Anal collection tube (ပီၤဘိဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲခံပူၤ)",
      "vaginal_tube": "vaginal collection tube (ပီၤဘိလၢအထုးထီၣ်ဟံးတၢ်မၤကွၢ်အဒိဖဲပိာ်မုၣ်က့ၢ်ဂီၤအပူၤ) 1 ခါ",
      "vaginal_swab": "vaginal swab (ဘဲပျံာ်နုာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲပိာ်မုၣ်က့ၢ်ဂီၤအပူၤ) 1 ခါ",
      "biodegradable_paper": "Biodegradable collection paper (စးခိဟံးန့ၢ်တၢ်မၤကွၢ်အဒိလၢအလီၤကလဲညီ)",
      "bottle_and_probe": "Sampling bottle and probe (ပလီဒီးနီၣ်ထးမၤကွၢ်တၢ်အဒိ)",
      "prepaid_envelope": "တၢ်ဆှၢတၢ်အလံာ်ပရၢဒၢလၢတၢ်ဟ့ၣ်ပာ်အပှ့ၤဝံၤ",
      "biohazard_subtitle": "(ဃုာ်ဒီးဘဲကဘျံးလၢအဆူးသဝံးထီၣ်တၢ်)",
      "biohazard_bag": "specimen bag (အတၢ်အဒိအထၢၣ်) 1 ဖျၢၣ်",
      "biohazard_bag_plural": "({{count}}) Biohazard bags (ထၢၣ်ဒၢလၢတၢ်မၤသံအဃၢ်ဝံၤ)",
      "alcohol_pads_plural": "{{count}} alcohol pads (ဘဲအဲးကဟီထွါစီတၢ်)",
      "sterile_gauze_pad": "sterile gauze pad (ဘဲတၢ်ကံးညာ်လၢတၢ်မၤသံအဃၢ်) 1 ဘ့ၣ်"
    },
    "packaging": {
      "title": "တၢ်သမံသမိးတၢ်ဘိၣ်အမံၤစရီ",
      "subtitle": "တချုးနဆှၢဟးထီၣ်န kit ဒံးဘၣ်အခါ, မၤလီၤတံၢ်လၢ-"
    },
    "packaging_box_a": {
      "step_1": "နဘိၣ်ထီၣ်ဝဲဒၣ် test kit အပိးလီတဖၣ်ခဲလၢာ်",
      "step_2": "နစူးကါဝဲဒၣ်တၢ်ထၢဖှိၣ်တၢ်အဒိပိးလီတဖၣ်လၢအပၣ်ဃုာ်ဖဲ kit အပူၤလၢတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိလၢတၢ်လိၣ်ဘၣ်ဝဲတဖၣ်",
      "step_3": "န **နအိၣ်ဖျဲၣ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_4": "**တၢ်ထၢဖှိၣ်ပာ်တၢ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_5": "တၢ်ထၢနုာ်လီၤတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိဖဲအ biohazard bag (ထၢၣ်ဒၢလၢတၢ်မၤသံအဃၢ်ဝံၤ) အပူၤလၢတၢ်ပံာ်ဃာ်ခိၣ်ဃံးဃံး",
      "step_6": "တၢ်ထၢနုာ်လီၤတၢ်အဒိခဲလၢာ်ဖဲတလါဒၢတဖျၢၣ်အပူၤ",
      "step_7": "တလါဒၢအဝဲန့ၣ်တၢ်ထၢနုာ်လီၤကဒီးဖဲတၢ်ဆှၢဟးထီၣ်တၢ်အတၢ်ပရၢဒၢလၢတၢ်ဟ့ၣ်ပာ်အပှ့ၤဝံၤအပူၤ",
      "step_8": "တၢ်ဆှၢကဒါက့ၤလံာ်ပရၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်"
    },
    "packaging_box_b": {
      "step_1": "နဘိၣ်ဃုာ်ဝဲဒၣ် test kit အပိးလီတဖၣ်ခဲလၢာ်, ဖဲနမ့ၢ်တမၤတၢ်မၤကွၢ်(တဖၣ်)ဒၣ်လဲာ်",
      "step_2": "နစူးကါဝဲဒၣ်တၢ်ထၢဖှိၣ်တၢ်အဒိပိးလီတဖၣ်လၢအပၣ်ဃုာ်ဖဲ kit အပူၤလၢတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိလၢတၢ်လိၣ်ဘၣ်ဝဲတဖၣ်",
      "step_3": "န **နအိၣ်ဖျဲၣ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_4": "**တၢ်ထၢဖှိၣ်ပာ်တၢ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_5": "တၢ်ထၢနုာ်လီၤတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတခါစုာ်စုာ်ဖဲအ biohazard bag (ထၢၣ်ဒၢလၢတၢ်မၤသံအဃၢ်ဝံၤ) အပူၤလၢတၢ်ပံာ်ဃာ်ခိၣ်ဃံးဃံး (ထဲဒၣ်တၢ်အဒိ**တခါ**လၢထၢၣ်တဖျၢၣ်ဘၣ်တဖျၢၣ်အပူၤဧိၤ)",
      "step_6": "တၢ်ထၢနုာ်လီၤတၢ်အဒိခဲလၢာ်ဖဲတလါဒၢတဖျၢၣ်အပူၤ",
      "step_7": "တလါဒၢအဝဲန့ၣ်တၢ်ထၢနုာ်လီၤကဒီးဖဲတၢ်ဆှၢဟးထီၣ်တၢ်အတၢ်ပရၢဒၢလၢတၢ်ဟ့ၣ်ပာ်အပှ့ၤဝံၤအပူၤ",
      "step_8": "တၢ်ဆှၢကဒါက့ၤလံာ်ပရၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်"
    },
    "packaging_box_c": {
      "step_1": "နဘိၣ်ဃုာ်ဝဲဒၣ် test kit အပိးလီတဖၣ်ခဲလၢာ်, ပၣ်ဃုာ်ဒီးဒီကူးကွဲးတၢ်လၢတၢ်စူးကါအီၤဝံၤတဖၣ်န့ၣ်လီၤႋ",
      "step_2": "န **နအိၣ်ဖျဲၣ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_3": "**တၢ်ထၢဖှိၣ်ပာ်တၢ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_4": "တၢ်ထၢဖှိၣ်ပာ်သွံၣ်အခးက့ဒီးဒီကူးကွဲးတၢ်လၢတၢ်စူးကါအီၤဝံၤတဖၣ်တၢ်ထၢနုာ်လီၤဝဲဖဲ biohazard bag (ထၢၣ်ဒၢလၢတၢ်မၤသံအဃၢ်ဝံၤ) အပူၤ",
      "step_5": "တၢ်ထၢနုာ်လီၤ biohazard bag အဝဲန့ၣ်ဖဲတလါဒၢအပူၤ",
      "step_6": "တလါဒၢအဝဲန့ၣ်တၢ်ထၢနုာ်လီၤကဒီးဖဲတၢ်ဆှၢဟးထီၣ်တၢ်အတၢ်ပရၢဒၢလၢတၢ်ဟ့ၣ်ပာ်အပှ့ၤဝံၤအပူၤ",
      "step_7": "တၢ်ဆှၢကဒါက့ၤလံာ်ပရၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်"
    },
    "success": {
      "header_1": "ဂ့ၤလံႋ",
      "text_1": "နကတီၤသးလၢတၢ်ထၢဖှိၣ်ပာ်နတၢ်မၤကွၢ်အဒိအခီၣ်ထံးတဘျီန့ၣ်လီၤႋ",
      "header_2": "နမၤအီၤဝံၤလံႋ",
      "text_2": "**ဆူညါတပတီၢ်-** ဘိၣ်ထီၣ်န kit လၢတၢ်ဆှၢကဒါက့ၤအဂီၢ်တက့ၢ်ႋ",
      "header_3": "နမၤဂ့ၤဒိၣ်မးႋ",
      "text_3": "You’re one step closer to better health.",
      "header_4": "နမၤဂ့ၤဒိၣ်မးႋ",
      "text_4": "ထဲဒၣ်ဆူညါတစဲးဖိဒီးကဝံၤဝဲလီၤႋ",
      "text_5": "နကတီၤသးလၢတၢ်ထၢဖှိၣ်ပာ်နတၢ်မၤကွၢ်အဒိအခီၣ်ထံးတဘျီ"
    },
    "instructions": {
      "title": "ဃုထၢတၢ်မၤကွၢ်လၢတၢ်ကွၢ်ကဒါက့ၤတၢ်နဲၣ်ကျဲတဖၣ်-",
      "subtitle": "တၢ်မၤကွၢ်",
      "button": "တၢ်နဲၣ်ကျဲတဖၣ်",
      "pdf": "PDF တၢ်နဲၣ်ကျဲတဖၣ်"
    },
    "instructions_box_a": {
      "title": "တၢ်ဟံးန့ၢ်ပာ်ဆံၣ်အဒိ",
      "subtitle": "လၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံလၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤ-",
      "step_1": "တဘၣ်ဆံၣ်ဆါအစှၤကတၢၢ် 1 နၣ်ရံၣ်တချုးဖဲတၢ်ဟံးန့ၢ်ပာ်ဝဲအခါႋ စူးကါဆံၣ်ဖဲတၢ်မံပၢၢ်ထီၣ်သီသီတၢ်ဆံၣ်လီၤအခီၣ်ထံးတဘျီမ့ၢ်တၢ်ဂ့ၤကတၢၢ်န့ၣ်လီၤႋ",
      "step_2": "ဆံၣ်ဆါလီၤလိၤလိၤဖဲ PeeCanter အပူၤ မ့တမ့ၢ် တၢ်ဟံးပာ်ဆံၣ်အခွးပူၤ, လၢတၢ်ထၢနုာ်ပှဲၤ 1/3 ဒီး 1/2 အဘၢၣ်စၢၤႋ",
      "step_3": "လူလီၤဆံၣ်လၢအအိၣ်ဖဲ PeeCanter အပူၤ မ့တမ့ၢ် စူးကါ pipette လၢတၢ်ဘံးထုးထီၣ်ဆံၣ်ဖဲတၢ်ဟံးပာ်ဆံၣ်အခွးပူၤလၢတၢ်သွီနုာ်လီၤဖဲတၢ်ဟံးပာ်ဆံၣ်အပီၤဘိ(တဖၣ်)ပူၤအဂီၢ်ဒီးမၤနီၣ်လီၤဃာ်တၢ်ပနီၣ်အလွဲၢ်ဂီၤဘီ <span style=\"color: #FF5000\">**\"URINE\"**</span> လံာ်မံၤပနီၣ်တက့ၢ်ႋ",
      "step_4": "ထၢနုာ်ပှဲၤတၢ်ဟံးန့ၢ်ပာ်ဆံၣ်အပီၤဘိ(တဖၣ်)တုၤလီၤလၢတၢ်ထံတၢ်နိတဖၣ်ကျဲၣ်ကျီဃုာ်သးလၢအမၤပှဲၤထီၣ်တၢ်ပာ်ပနီၣ်လီၤတၢ်အကျိၤတဖၣ်န့ၣ်တက့ၢ်ႋ",
      "step_4_sublabel": "တဘၣ်ထၢနုာ်ပှဲၤအါတလၢကွံာ်န့ၢ်ဒံးတၢ်ပာ်ပနီၣ်အကျိၤတဂ့ၤ မ့တမ့ၢ် တၢ်ကသမၢကွံာ်တၢ်အဒိန့ၣ်လီၤႋ",
      "step_5": "ပာ်လီၤအခိၣ်ပံာ်ဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိလိၤဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အီၤလီၤတံၢ်လီၤဆဲးဒီးဃံးဃံးန့ၣ်တက့ၢ်ႋ",
      "step_6": "ကွဲးလီၤနအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီဒီးတၢ်ထၢဖှိၣ်တၢ်အဒိအမုၢ်နံၤ (တနံၤအံၤအမုၢ်နံၤ) ဖဲ MM/DD/YYYY အက့ၢ်ဂီၤအသိးဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလိၤန့ၣ်တက့ၢ်ႋ",
      "step_7": "ပာ်လီၤတၢ်အဒိဖဲ biohazard bag အကလီတဖျၢၣ်အပူၤဃုာ်ဒီး absorbent pad (ဘဲဆူးသဝံးတၢ်) ဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်န့ၣ်တက့ၢ်ႋ"
    },
    "instructions_box_b": {
      "test_1": {
        "title": "တၢ်ဟံးန့ၢ်ပာ်ဆံၣ်အဒိ",
        "subtitle": "လၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံလၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤ-",
        "step_1": "တဘၣ်ဆံၣ်ဆါအစှၤကတၢၢ် 1 နၣ်ရံၣ်တချုးဖဲတၢ်ဟံးန့ၢ်ပာ်ဝဲအခါႋ စူးကါဆံၣ်ဖဲတၢ်မံပၢၢ်ထီၣ်သီသီတၢ်ဆံၣ်လီၤအခီၣ်ထံးတဘျီမ့ၢ်တၢ်ဂ့ၤကတၢၢ်န့ၣ်လီၤႋ",
        "step_2": "ဆံၣ်ဆါလီၤလိၤလိၤဖဲ PeeCanter အပူၤ မ့တမ့ၢ် တၢ်ဟံးပာ်ဆံၣ်အခွးပူၤ, လၢတၢ်ထၢနုာ်ပှဲၤ 1/3 ဒီး 1/2 အဘၢၣ်စၢၤႋ",
        "step_3": "လူလီၤဆံၣ်လၢအအိၣ်ဖဲ PeeCanter အပူၤ မ့တမ့ၢ် စူးကါ pipette လၢတၢ်ဘံးထုးထီၣ်ဆံၣ်ဖဲတၢ်ဟံးပာ်ဆံၣ်အခွးပူၤလၢတၢ်သွီနုာ်လီၤဖဲတၢ်ဟံးပာ်ဆံၣ်အပီၤဘိ(တဖၣ်)ပူၤအဂီၢ်ဒီးမၤနီၣ်လီၤဃာ်တၢ်ပနီၣ်အလွဲၢ်ဂီၤဘီ <span style=\"color: #FF5000\">**\"URINE\"**</span> လံာ်မံၤပနီၣ်တက့ၢ်ႋ",
        "step_4": "ထၢနုာ်ပှဲၤတၢ်ဟံးန့ၢ်ပာ်ဆံၣ်အပီၤဘိ(တဖၣ်)တုၤလီၤလၢတၢ်ထံတၢ်နိတဖၣ်ကျဲၣ်ကျီဃုာ်သးလၢအမၤပှဲၤထီၣ်တၢ်ပာ်ပနီၣ်လီၤတၢ်အကျိၤတဖၣ်န့ၣ်တက့ၢ်ႋ",
        "step_4_subtitle": "တဘၣ်ထၢနုာ်ပှဲၤအါတလၢကွံာ်န့ၢ်ဒံးတၢ်ပာ်ပနီၣ်အကျိၤတဂ့ၤ မ့တမ့ၢ် တၢ်ကသမၢကွံာ်တၢ်အဒိန့ၣ်လီၤႋ",
        "step_5": "ပာ်လီၤအခိၣ်ပံာ်ဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိလိၤဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အီၤလီၤတံၢ်လီၤဆဲးဒီးဃံးဃံးန့ၣ်တက့ၢ်ႋ",
        "step_6": "ကွဲးလီၤနအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီဒီးတၢ်ထၢဖှိၣ်တၢ်အဒိအမုၢ်နံၤ (တနံၤအံၤအမုၢ်နံၤ) ဖဲ MM/DD/YYYY အက့ၢ်ဂီၤအသိးဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလိၤန့ၣ်တက့ၢ်ႋ",
        "step_7": "ပာ်လီၤတၢ်အဒိဖဲ biohazard bag အကလီတဖျၢၣ်အပူၤဃုာ်ဒီး absorbent pad (ဘဲဆူးသဝံးတၢ်) ဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်န့ၣ်တက့ၢ်ႋ"
      },
      "test_2": {
        "title": "Oral swab (ဘဲပျံာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲကိာ်ပူၤ)",
        "subtitle": "လၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံလၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤ-",
        "step_1": "ဖီၣ်ဃာ်ဘဲပျံာ်တၢ်ဖဲတၢ်ထွါလီၤတၢ်အလွဲၢ်ဖျါကဒုအကျိၤ (မ့ၢ်အိၣ်ဖျါ) ဒီးဘဲပျံာ်တၢ်ဖဲအဖီခိၣ်တပၤတက့ၢ်ႋ",
        "step_2": "ထၢနုာ်လီၤဘဲပျံာ်တၢ်ဆူနကိာ်ပူၤဒီးထွါလီၤနဘဲပျံာ်တၢ်အခိၣ်ကတၢၢ်ဖဲကိာ်ယူၢ်ဒိပူၤ 10 စဲးကးတက့ၢ်ႋ",
        "step_3": "ထုးထီၣ်နဘဲပျံာ်တၢ်လၢတၢ်ပလီၢ်ပဒီအပူၤဒီးထၢနုာ်လီၤဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိပူၤဒီးမၤနီၣ်လီၤတၢ်ပနီၣ်အလွဲၢ်လါ <span style=\"color: #001F70\">**\"ORAL\"**</span> လံာ်မံၤပနီၣ်တက့ၢ်ႋ မၤကၢ်ကွံာ်ဘဲပျံာ်အဝဲန့ၣ်ဖဲတၢ်ပာ်ပာ်နီၣ်လီၤတၢ်အကျိၤအလီၢ်ဒီးမၤသကွံၤလီၤတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိတက့ၢ်ႋ",
        "step_4": "ပာ်လီၤအခိၣ်ပံာ်ဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိလိၤဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အီၤလီၤတံၢ်လီၤဆဲးဒီးဃံးဃံးန့ၣ်တက့ၢ်ႋ",
        "step_5": "ကွဲးလီၤနအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီဒီးတၢ်ထၢဖှိၣ်တၢ်အဒိအမုၢ်နံၤ (တနံၤအံၤအမုၢ်နံၤ) ဖဲ MM/DD/YYYY အက့ၢ်ဂီၤအသိးဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလိၤန့ၣ်တက့ၢ်ႋ",
        "step_6": "ပာ်လီၤတၢ်အဒိဖဲ biohazard bag အကလီတဖျၢၣ်အပူၤဃုာ်ဒီး absorbent pad (ဘဲဆူးသဝံးတၢ်) ဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်န့ၣ်တက့ၢ်ႋ"
      },
      "test_3": {
        "title": "Anal swab (ဘဲပျံာ်ဟံးန့ၢ်တၢ်မၤကွၢ်အဒိဖဲခံပူၤ)",
        "subtitle": "လၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံလၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤ-",
        "step_1": "ဖီၣ်ဃာ်ဘဲပျံာ်တၢ်ဖဲတၢ်ထွါလီၤတၢ်အလွဲၢ်ဖျါကဒုအကျိၤ (မ့ၢ်အိၣ်ဖျါ) ဒီးဘဲပျံာ်တၢ်ဖဲအဖီခိၣ်တပၤတက့ၢ်ႋ",
        "step_2": "ထၢနုာ်လီၤဘဲပျံာ်တၢ် 3 - 5 စဲၣ်တံၣ်မံထၢၣ် (1 - 2”) ဆူခံအကျိၤပူၤ, ဒီးထုးထီၣ်က့ၤဘဲပျံာ်တၢ်ကယီကယီ 5 - 10 စဲးကးလၢတၢ်ဟံးန့ၢ်ပာ်တၢ်လၢအကဲထီၣ်တၢ်သးသမူအိၣ်သ့တမံၤမံၤတက့ၢ်ႋ",
        "step_3": "ထုးထီၣ်နဘဲပျံာ်တၢ်လၢတၢ်ပလီၢ်ပဒီအပူၤဒီးထၢနုာ်လီၤဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိပူၤဒီးမၤနီၣ်လီၤတၢ်ပနီၣ်အလွဲၢ်လါဟ့ <span style=\"color: #00C08C\">**“ANAL”**</span> လံာ်မံၤပနီၣ်တက့ၢ်ႋ မၤကၢ်ကွံာ်ဘဲပျံာ်အဝဲန့ၣ်ဖဲတၢ်ပာ်ပာ်နီၣ်လီၤတၢ်အကျိၤအလီၢ်ဒီးမၤသကွံၤလီၤတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိတက့ၢ်ႋ",
        "step_4": "ပာ်လီၤအခိၣ်ပံာ်ဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိလိၤဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အီၤလီၤတံၢ်လီၤဆဲးဒီးဃံးဃံးန့ၣ်တက့ၢ်ႋ",
        "step_5": "ကွဲးလီၤနအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီဒီးတၢ်ထၢဖှိၣ်တၢ်အဒိအမုၢ်နံၤ (တနံၤအံၤအမုၢ်နံၤ) ဖဲ MM/DD/YYYY အက့ၢ်ဂီၤအသိးဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလိၤန့ၣ်တက့ၢ်ႋ",
        "step_6": "ပာ်လီၤတၢ်အဒိဖဲ biohazard bag အကလီတဖျၢၣ်အပူၤဃုာ်ဒီး absorbent pad (ဘဲဆူးသဝံးတၢ်) ဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်န့ၣ်တက့ၢ်ႋ"
      }
    },
    "instructions_box_c": {
      "title": "တၢ်ဟံးပာ်သွံၣ်အဒိ",
      "subtitle": "လၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံလၢတၢ်မၤကွၢ်အံၤအဂီၢ်နကဘၣ်မၤဝဲဒၣ်တၢ်ဂ့ၢ်တၢ်ကျိၤလၢလာ်တဖၣ်အံၤ-",
      "step_1": "ကွဲးလီၤနမံၤ, နအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီ, ဒီးတၢ်ထၢဖှိၣ်တၢ်အဒိအမုၢ်နံၤဖဲတၢ်ပာ်ပနီၣ်လီၤဝဲအလီၢ်န့ၣ်တက့ၢ်ႋ စူးကါ MM/DD/YYYY အက့ၢ်ဂီၤတက့ၢ်ႋ",
      "step_2": "အိးထီၣ်သွံၣ်ခးက့အကဘျံးလၢတၢ်ထံၣ်ဘၣ်တၢ်ကဝီၤကွီၤတဖၣ်ဖဲတၢ်ဟံးပာ်သွံၣ်အဒိအစးခိလိၤတက့ၢ်ႋ တဘၣ်ဖီၣ်ဘူးထိးဘိးတၢ်ဟံးဃာ်သွံၣ်အဒိအစးခိတဂ့ၤႋ",
      "step_3": "သ့ကဆှီနစုဒီးထံကလၢၤအစှၤကတၢၢ် 30 စဲးကး, ဝံတဆ့နစုဆူၣ်ဆူၣ် 15 စဲးကးဒ်သိးနသွံၣ်ကလီၤဝဲလၢနစုနၢခိၣ်အဂီၢ်န့ၣ်တက့ၢ်ႋ",
      "step_4": "မၤကဆှီနစုနၢခိၣ်ထိးဒီးဘဲအဲးကဟီတက့ၢ်ႋ အဂ့ၤကတၢၢ်မ့ၢ်တၢ်စူးကါစုနၢခၢၣ်သး မ့တမ့ၢ် စုနၢသိးပသံးဖဲစုလၢတနစူးကါညီနုၢ်အီၤတပၤန့ၣ်တက့ၢ်ႋ",
      "step_5": "စူးကါဒီကူးကွဲးတၢ်ဒီးဝံာ်အိးထီၣ်တၢ်အခိၣ်ပံာ်တက့ၢ်ႋ စံၢ်လီၤတံၢ်ဃာ်နစုနၢခိၣ်ထဲဒၣ်တစဲးဖိဃံးဃံး, ဒီးဆဲးလီၤဒီးထးဖိအခိၣ်တက့ၢ်ႋ ပာ်နစုဖဲနသးအိၣ်လီၢ်အဖီလာ်ဖဲနဟံးန့ၢ်ပာ်သွံၣ်အဒိအခါ, စံၢ်လီၤနစုနၢအခီၣ်ထံးတုၤလၢအခိၣ်ထိးဒ်သိးသွံၣ်ကလဲၤသးဂ့ၤဂ့ၤအဂီၢ်န့ၣ်လီၤႋ",
      "step_5_subtitle": "တၢ်စူးကါဒီကူးကွဲးတၢ်သ့တဘျီန့ၣ်လီၤႋ တၢ်ကဘၣ်ဆှၢကဒါက့ၤဒီကူးကွဲးတၢ်ခဲလၢာ်ဃုာ်ဒီးတၢ်မၤကွၢ်အဒိတဖၣ်ဆူတၢ်မၤကွၢ်ဒၢးလၢတၢ်စူးကွံာ်ဝဲအဂီၢ်န့ၣ်လီၤႋ",
      "step_6": "စးထီၣ်မၤဖဲအခၢၣ်, မၤလီၤအီၤ 3-6 လီၤစီၤလၢတၢ်မၤပှဲၤတၢ်ကဝီၤကွီၤဒီးစုၣ်လီၤဖဲတၢ်ဟံးပာ်တၢ်အဒိအစးခိလိၤန့ၣ်တက့ၢ်ႋ တဘၣ်ဖီၣ်ဘူးစးခိဝဲန့ၣ်ဒီးနစုနၢတဂ့ၤမ့ၢ်လၢအမၤပတုာ်သွံၣ်တၢ်လဲၤတရံးသးသ့အဃိန့ၣ်လီၤႋ ဖဲတၢ်ကဝီၤကွီၤအလီၢ်မ့ၢ်ပှဲၤလံန့ၣ်, သုးကဒီးဆူတၢ်ကဝီၤကွီၤအလီၢ်ဆူညါတတီၤတက့ၢ်ႋ သွံၣ်မ့ၢ်တလၢထီၣ်ကွံာ်တၢ်အကျိၤတဘၣ်နီတမံၤဘၣ်, ဘၣ်ဆၣ်တဘၣ်ပျဲသွံၣ်တဖၣ်ရၤလီၤဘၣ်အသးတခါစုာ်စုာ်တဂ့ၤႋ",
      "step_6_subtitle": "တဘၣ်မၤအါထီၣ်သွံၣ်ဖဲတၢ်ကဝီၤကွီၤအပူၤတုၤလၢအဝံၤ မ့တမ့ၢ် ဃ့ထီတဂ့ၤႋ သွံၣ်မ့ၢ် “ဒီကထၢအသး” န့ၣ်တၢ်စူးကါအီၤတသ့လၢၤဘၣ်ႋ",
      "step_7": "တဘၣ်ပံာ်ဃာ်သွံၣ်အခးက့တဂ့ၤ, ပာ်လီၤဖဲတၢ်ပၢၤတမံၤမံၤအဖီခိၣ်ဒီးပာ်ဃ့ထီၣ်တၢ်ဟံးပာ်သွံၣ်စးခိလၢကလံၤကျါဖဲဒၢတကိၢ်တခုၣ်အပူၤ, အစှၤကတၢၢ် 30 မံးနံးတက့ၢ်ႋ တဘၣ်မၤကိၢ်, အူဃ့ထီ, မ့တမ့ၢ် မၤဘၣ်တၢ်ဟံးပာ်သွံၣ်အစးခိလိၤလိၤဒီးမုၢ်တၢ်ကပီၤတဂ့ၤႋ တၢ်ကိၢ်ကမၤဟးဂီၤကွံာ်တၢ်အဒိန့ၣ်လီၤႋ",
      "step_7_subtitle": "သမံသမိးကွၢ်တၢ်ဟံးပာ်သွံၣ်အစးခိအချၢတပၤတက့ၢ်ႋ သွံၣ်ကဘၣ်စှံၢ်လီၤဝဲဖဲစးခိအတၢ်ကဝီၤကွီၤတခါစုာ်စုာ်အပူၤန့ၣ်လီၤႋ",
      "step_8": "ဖဲတၢ်ဟံးပာ်သွံၣ်အစးခိဃ့ထီအခါ, ပံာ်ဃာ်သွံၣ်ခးက့အဝဲန့ၣ်ခီဖျိတၢ်ကျးတံၢ်လီၤလၢတၢ်ကဘျံးအလိၤတက့ၢ်ႋ ထၢနုာ်လီၤသွံၣ်ခးက့ဒီးဒီကူးကွဲးတၢ်တဖၣ်ဆူ biohazard bag အပူၤဃုာ်ဒီးတၢ်လၢအဆူးသဝံးတၢ်သဝံအဘိၣ်တက့ၢ်ႋ မၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ် biohazard bag အခိၣ်ဂ့ၤဂ့ၤန့ၣ်တက့ၢ်ႋ"
    },
    "test_tips": {
      "title": "တၢ်ဟ့ၣ်ကူၣ်လီၤဆီလၢတၢ်ဟံးပာ်သွံၣ်ဒိကြၢးဝဲဘၣ်ဝဲအဂ့ၢ်",
      "subtitle": "လၢတၢ်ဒိးန့ၢ်တၢ်အစၢဂ့ၤကတၢၢ်အဂီၢ်-",
      "step_1": "မၤလီၤတံၢ်လၢတအိၣ်ဒီးတၢ်ထံသံးနိသံးဖဲတၢ်မၤဝဲဒၣ်တၢ်ဟံးပာ်တၢ်အဒိအခါတဂ့ၤႋ တၢ်ထံတၢ်နိဂ့ၤဝဲမၤဂ့ၤထီၣ်စၢၤသွံၣ်တၢ်လဲၤတရံးသးန့ၣ်လီၤႋ",
      "step_2": "ဖဲနအီမိာ်ဝံၤအလီၢ်ခံတဘၣ်ဟံးန့ၢ်ပာ်တၢ်မၤကွၢ်အဒိတဘျီဃီတဂ့ၤႋ",
      "step_3": "သ့ကဆှီနစုဖဲထံကလၢၤယွၤလီၤအဖီလာ်တၢ်န့ၣ်ကမၤဂ့ၤထီၣ်စၢၤသွံၣ်တၢ်လဲၤတရံးသးဖဲနစုလိၤန့ၣ်လီၤႋ",
      "step_4": "တဆ့လီၤနစုဆူၣ်ဆူၣ်ဒ်သိးကမၤဂ့ၤထီၣ်စၢၤသွံၣ်တၢ်လဲၤတရံးသးဖဲနစုနၢတဖၣ်အလိၤန့ၣ်လီၤႋ",
      "step_5": "ပာ်လီၤတၢ်ပိးတၢ်လီလၢအဟံးန့ၢ်ပာ်တၢ်အဒိဒီးနစုဖဲနသးအိၣ်အလီၢ်အဖီလာ်ဖဲနဟံးန့ၢ်တၢ်အဒိအခါဒ်သိးသွံၣ်လဲၤတရံးသးဂ့ၤကတၢၢ်အဂီၢ်န့ၣ်လီၤႋ",
      "step_6": "ဘၣ်သ့ၣ်သ့ၣ်နကဘၣ်ဆဲးလီၤစုနၢခိၣ်ထိးအါန့ၢ်တဘိန့ၣ်လီၤႋ မၤထွဲတၢ်သ့ၣ်တဖၣ်အံၤတဘျီဝံၤတဘျီဖဲနဆဲးလီၤစုနၢခိၣ်ထိးတဘိစုစုအကတီၢ်တက့ၢ်ႋ"
    },
    "shipping": {
      "header": "န kit အိၣ်ကတီၤပာ်လၢတၢ်ကဆှၢထီၣ်ဝဲန့ၣ်လီၤႋ",
      "text": "**သးခုဃုာ်ဒီးနၤႋ** တၢ်သမံထံဘၣ်ဃးနမုၣ်ခွါသွံၣ်ထံးတၢ်အိၣ်ဆူၣ်အိၣ်ချ့အဂ့ၢ်ဝံၤဝဲန့ၣ်လီၤႋ"
    },
    "pick_up": "ဟံးန့ၢ် test kit လၢပှၤဘၣ်မူဘၣ်ဒါဟ့ၣ်ကူၣ်နၤဒီးဆဲးလီၤမံၤဖဲလာ်အံၤတက့ၢ်ႋ",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit (တၢ်မၤကွၢ်အပီးလီအစူၣ်)",
    "view_results": "ကွၢ်ဘၣ်တၢ်အစၢတဖၣ်",
    "recommended_kit_title": "Test Kit လၢတၢ်ဟ့ၣ်ကူၣ်ဝဲတဖၣ်",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection (ဘဲပျံာ်ဟံးန့ၢ်ပာ်တၢ်အဒိဖဲပိာ်မုၣ်က့ၢ်ဂီၤအပူၤ)",
      "subtitle": "တၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိတဖၣ်ကပၣ်ဃုာ်ဒီးတၢ်ထံတၢ်နိလၢအပၢၤဃာ်တၢ်တဟးဂီၤန့ၣ်လီၤႋ <span class=\"text-danger\">**မ့ၢ်အိၣ်တဘၣ်ပာ်အိၣ်ကလီတၢ်ထံတၢ်နိလၢအပၢၤဃာ်တၢ်တဟးဂီၤဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိတဖၣ်ပူၤ.**</span>",
      "step_1": "ဖီၣ်ဃာ်ဘဲပျံာ်တၢ်ဖဲတၢ်ထွါလီၤတၢ်အလွဲၢ်ဖျါကဒုအကျိၤ (မ့ၢ်အိၣ်ဖျါ) ဒီးဘဲပျံာ်တၢ်ဖဲအဖီခိၣ်တပၤလၢစုတခီပၤဒီးမၤလီၤဆီ ပိာ်မုၣ်က့ၢ်ဂီၤခိၣ်ထိး (labia) (ချံးဃာ်ဖံးဘ့ၣ်လၢအိၣ်ဝးတရံးအအိးထီၣ်သးအလီၢ်) တက့ၢ်ႋ",
      "step_2": "ထၢနုာ်လီၤဘဲပျံာ်တၢ် 5 စဲၣ်တံၣ်မံထၢၣ် (2in) ဖဲပိာ်မုၣ်က့ၢ်ဂီၤအိးထီၣ်သးအလီၢ်တက့ၢ်ႋ ထၢနုာ်လီၤဘဲပျံာ်တၢ်ကယီကယီ 30 စဲးကးဖဲတၢ်ထွါနုာ်လီၤဘဲပျံာ်တၢ်ဖဲပိာ်မုၣ်က့ၢ်ဂီၤအကပိာ်ကပၤအခါန့ၣ်တက့ၢ်ႋ",
      "step_3": "ထုးထီၣ်နဘဲပျံာ်တၢ်လၢတၢ်ပလီၢ်ပဒီအပူၤဒီးထၢနုာ်လီၤဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိပူၤဒီးမၤနီၣ်လီၤတၢ်ပနီၣ် <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div> တက့ၢ်ႋ မၤကၢ်ကွံာ်ဘဲပျံာ်အဝဲန့ၣ်ဖဲတၢ်ပာ်ပာ်နီၣ်လီၤတၢ်အကျိၤအလီၢ်ဒီးမၤသကွံၤလီၤတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိတက့ၢ်ႋ",
      "step_4": "ပာ်လီၤအခိၣ်ပံာ်ဖဲတၢ်ဟံးပာ်တၢ်အဒိအပီၤဘိလိၤဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အီၤလီၤတံၢ်လီၤဆဲးဒီးဃံးဃံးန့ၣ်တက့ၢ်ႋ",
      "step_5": "ကွဲးလီၤနအိၣ်ဖျဲၣ်အမုၢ်နံၤမုၢ်သီဒ် MM/DD/YYYY အက့ၢ်ဂီၤဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလီၢ် (DOB) တက့ၢ်ႋ",
      "step_6": "ကွဲးလီၤတၢ်ဟံးပာ်တၢ်အဒိအမုၢ်နံၤမုၢ်သီ (တနံၤအံၤအမုၢ်နံၤမုၢ်သီ) ဒ် MM/DD/YYYY အက့ၢ်ဂီၤဖဲပီၤဘိလၢတၢ်ပာ်ပနီၣ်လီၤအလီၢ် (Coll. Date) တက့ၢ်ႋ",
      "step_7": "ပာ်လီၤတၢ်အဒိဖဲ biohazard bag အကလီတဖျၢၣ်အပူၤဃုာ်ဒီး absorbent pad (ဘဲဆူးသဝံးတၢ်) ဒီးမၤလီၤတံၢ်လၢတၢ်ပံာ်ဃာ်အခိၣ်ဂ့ၤဂ့ၤဘၣ်ဘၣ်န့ၣ်တက့ၢ်ႋ"
    },
    "packaging_box_h": {
      "step_3": "န **နအိၣ်ဖျဲၣ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_4": "**တၢ်ထၢဖှိၣ်ပာ်တၢ်အနံၤအသီ** ဘၣ်တၢ်ကွဲးလီၤဝဲဖဲတၢ်ထၢဖှိၣ်ပာ်တၢ်အဒိအပီၤဘိတဖၣ်အလိၤလၢတၢ်စူးကါတၢ်ကွဲးလီၤနံၤသီလါနံၣ်အက့ၢ်ဂီၤဒ်အမ့ၢ် MM/DD/YYYY (လါ/သီ/နံၣ်) အသိး",
      "step_6": "တၢ်ထၢနုာ်လီၤ biohazard bag အဝဲန့ၣ်ဖဲတလါဒၢအပူၤ"
    },
    "instructions_box_cc": {
      "title": "တၢ်ဟံးပာ်အ့ၣ်အဒိ",
      "step_1": "ကွဲးလီၤနအိၣ်ဖျဲၣ်နံၤသီဒီးတၢ်ဟံးပာ်တၢ်အဒိအနံၤသီ (တနံၤအံၤအမုၢ်နံၤမုၢ်သီ) ဒ် MM/DD/YYYY အက့ၢ်ဂီၤအသိးဖဲတၢ်ပာ်ပနီၣ်လီၤပီဘိအလီၢ်န့ၣ်တက့ၢ်ႋ အိးထီၣ်အခိၣ်ပံာ်အလွဲၢ်လါဟ့လၢတၢ်ဝံာ်တရံးဒီးစိာ်ကဖီထီၣ်န့ၣ်တက့ၢ်ႋ",
      "step_2": "ပာ်လီၤတၢ်ဟံးပာ်တၢ်အဒိအစးခိဖဲတၢ်ဟးလီၢ်အလီၢ်ဆ့ၣ်နီၤထံဖီခိၣ်န့ၣ်တက့ၢ်ႋ",
      "step_3": "ပာ်လီၤအ့ၣ်အဒိဖဲတၢ်ဟံးပာ်အ့ၣ်ဒိအစးခိလိၤန့ၣ်တက့ၢ်ႋ",
      "step_4": "ဟံးပာ်အ့ၣ်အဒိတချုးဖဲစးခိလီၤဘျၢဒီးအ့ၣ်အဒိဘၣ်ဒီးထံအခါန့ၣ်တက့ၢ်ႋ",
      "step_5": "ကွးလီၤအ့ၣ်ဒီး sample probe (နီၣ်ဆဲးဂိာ်တၢ်အဒိ) အခိၣ်ထိးတက့ၢ်ႋ",
      "step_6": "ကျၢၢ်ဘၢလီၤ sample probe (နီၣ်ဆဲးဂိာ်တၢ်အဒိ) အကနူာ်ဒီးအ့ၣ်အဒိဂ့ၤဂ့ၤတက့ၢ်ႋ",
      "step_7": "ကျၢၢ်ဘၢတၢ်အဒိအပလီခီဖျိထၢနုာ်လီၤ sample probe ဒီးပံာ်လီၤတၢ်အခိၣ်ပံာ်အလွဲၢ်လါဟ့ဃံးဃံးတက့ၢ်ႋ တဘၣ်အိးထီၣ်ကဒါက့ၤတဂ့ၤႋ",
      "step_8": "ဒုးယွၤလီၤဆူၣ်ဆူၣ်တဘျီဃီႋ တၢ်ဟံးပာ်တၢ်အဒိအစးခိမ့ၢ်တၢ်လၢအလီၤကလဲညီဒီးတမၤဟးဂီၤထံဘၣ်အၢအကျိၤကျဲတဖၣ်ဘၣ်န့ၣ်လီၤႋ",
      "step_9": "ဘိၣ်ဃာ်တၢ်အဒိအပလီဖဲ absorbent pad အပူၤဒီးထၢနုာ်လီၤဖဲ biohazard bag အပူၤတက့ၢ်ႋ"
    },
    "contact_support": {
      "title": "စးထီၣ်ဘှီဘၣ်တၢ်အံၤ",
      "text_1": "ပဃ့ဝံသးစူၤလၢပနၢ်ဟူဘၣ်တၢ်လဲၤကမၣ်သးတမံၤမံၤအဃိလီၤႋ",
      "text_2": "ဝံသးစူၤဆဲးကျိးပှၤလၢနကဒုးသ့ၣ်ညါပှၤတၢ်လဲၤကမၣ်သးမနုၤအဂ့ၢ်ဒီးပကမၤစၢၤနၤလၢတၢ်ဆီတလဲန့ၢ်န kit အသီတခါန့ၣ်လီၤႋ"
    },
    "contact_support_success": {
      "title": "ပဒိးန့ၢ်ဘၣ်နတၢ်ဂ့ၢ်တၢ်ကျိၤ",
      "text_1": "စံးဘျုးနၤလၢနဆဲးကျိးပှၤအဃိလီၤႋ",
      "text_2": "ပတၢ်ဆီၣ်ထွဲမၤစၢၤကရူၢ်အကရၢဖိကဆဲးကျိးနၤလၢအဆိအချ့န့ၣ်လီၤႋ",
      "button_text": "က့ၤကဒါဆူပှာ်ယဲၤသန့ကဘျံးပၤမိၢ်ပှၢ်"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for colorectal cancer.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for hemoglobin.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D."
  },
  "copy_link": "ကွဲးဒိန့ၢ်ကဘျံးပၤအ link",
  "copied_link": "တၢ်ကွဲးဒိ link",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Protection against the common flu viruses",
      "vaccine_covid": "Protection against COVID-19 viruses",
      "tdap": "Tdap vaccine can prevent tetanus",
      "polio": "Protection against the polio virus. Required for children before starting school.",
      "varicella": "Vaccine that protects against chickenpox",
      "shingles": "Vaccine that protects against shingles. Minimum age is 18.",
      "human_papillomavirus": "Vaccine that protects against HPV. Recommended for 11 year old children.",
      "meningococcal": "Vaccine protects against four types of meningococcal bacteria."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Find a clinic",
    "select_pin": "Select a pin to see details",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Next available",
    "appointment_type": "Appointment type",
    "individual_appointment": "Individual appointment",
    "family_appointment": "Family appointment",
    "vaccines": "Vaccines",
    "what_kind_appointment": "What kind of appointment do you want to book?",
    "select_family_or_individual": "Select family appointment if you are scheduling for multiple family members at once.",
    "vaccines_selected": "Vaccines selected",
    "where_care": "Where do you want to receive care?",
    "select_vaccines_for_individual": "Select vaccines for your appointment (optional).",
    "select_vaccines_for_family": "Select vaccines for your family appointment (optional).",
    "schedule_services": "Schedule services",
    "add_family": "Add family members and select services below.",
    "family_member": "Family member {{number}}",
    "morning": "Morning",
    "afternoon": "Afternoon",
    "slot_available": "{{number}} available",
    "within_x_miles": "Within {{miles}} miles of",
    "any_distance": "Any distance from",
    "partial_results": "Partial results",
    "partial_matches": "The following clinics offer some but not all of the vaccines you're looking for",
    "no_matches": "That search didn’t find any matches. Try changing your filters for better results.",
    "no_clinics_found": "There aren't any clinics that match your search.",
    "broaden_filters": "Try broadening your filters for better results.",
    "unavailable_vaccines": "Unavailable vaccines:",
    "available_vaccines": "Available vaccines:",
    "select_date": "Select a date",
    "available_appointments": "AVAILABLE APPOINTMENTS",
    "appointment_scheduling_info": "You’ll be able to assign appointment times to specific family members in the next step. You can schedule appointments on different days if you choose.",
    "hold_selected_appointments": "We will hold your selected appointments for 15 minutes.",
    "appointments_selected": "APPOINTMENTS SELECTED",
    "no_appointments_selected": "No appointment selected",
    "vaccines_needed": "Vaccines needed",
    "select_x_appointments": "Select {{number}} appointments.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Let's verify your email!",
    "hi_full_name": "Hi {{full_name}},",
    "verify_email_button": "Verify email",
    "please_verify_email": "Please use the button below to verify your email."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Hepatitis A",
    "hepatitis_b": "Hepatitis B",
    "hepatitis_a_b": "Hepatitis A & B",
    "hib": "Hib",
    "mmr": "Measles, Mumps, Rubella",
    "meningococcal": "Meningococcal",
    "mpox": "Mpox",
    "pneumococcal": "Pneumococcal",
    "polio": "Polio",
    "rsv": "Respiratory syncytial virus",
    "rotovirus": "Rotavirus",
    "zoster": "Zoster (Shingles)",
    "tetanus_diptheria": "Tetanus & Diphtheria",
    "tdap": "Tdap",
    "typhoid": "Typhoid",
    "varicella": "Varicella (Chickenpox)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Adult)",
    "covid-19_adult_description": "For people 12 years or older.",
    "covid-19_children": "COVID-19 (Child)",
    "covid-19_children_description": "For children aged 4 - 11 years old.",
    "covid-19_infants": "COVID-19 (Infant)",
    "covid-19_infants_description": "For children 6 months - 3 years old.",
    "influenza": "Influenza",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}