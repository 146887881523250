import React, { useContext, useState, useRef } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import CreatableSelect from 'react-select/creatable';
import ErrorMessage from '../../../common/components/ErrorMessage';
import InsuranceContext from '../../../Insurance/contexts/InsuranceContext';
import PolicyHolderFields from './PolicyHolderFields';
import { buildCustomizedLabel } from '../../../common/locales/langs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { Row, Col, Image, Dropdown } from 'react-bootstrap';
import Select from 'react-select';
import Webcam from '../../../common/components/Webcam';
import { Camera, Upload } from '../../../common/components/Icons';
import { dataURItoBlob } from '../../../common/utils';

const PolicyInformationFields = ({
  setFieldValue,
  values,
  errors,
  handleChange,
  secondary,
  policyHolderNames,
  names={
    insuranceCardFront: "insurance_card_front",
    insuranceCardBack: "insurance_card_back",
    insuranceCompany: "insurance_company",
    insuranceId: "insurance_id",
    insuranceGroupNumber: "insurance_group_number",
    insurancePolicyHolder: "insurance_policy_holder",
  }
}) => {
  const { t, i18n } = useTranslation();
  const { formAction, setFormAction, testGroup } = useContext(InsuranceContext);
  const {
    insuranceCardFront,
    insuranceCardBack,
    insuranceCompany,
    insuranceId,
    insuranceGroupNumber,
    insurancePolicyHolder,
  } = names
  const insuranceCompanyOptions = testGroup.insurance_companies.map(v => ({ label: v, value: v }))
  const announceFileUpload = (file, nodeId) => {
    const announceFileUploadRoot = document.getElementById(nodeId);
    if (file === undefined) {
      announceFileUploadRoot.style.visibility = 'hidden';
    } else {
      announceFileUploadRoot.style.visibility = 'visible';
      announceFileUploadRoot.focus();
    }
  }
  const [insuranceFrontName, setInsuranceFrontName] = useState(null);
  const [insuranceBackName, setInsuranceBackName] = useState(null);
  const [insuranceImageFront, setInsuranceImageFront] = useState(null);
  const [insuranceImageBack, setInsuranceImageBack] = useState(null);
  const [insuranceImageSide, setInsuranceImageSide] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const fileFrontRef = useRef(null);
  const fileBackRef = useRef(null);

  const updateWebcamImage = (img) => {
    let insuranceCardSide;
    let container = new DataTransfer();
    container.items.add(dataURItoBlob(img, true));

    if (insuranceImageSide == "front"){
      setInsuranceImageFront(img);
      insuranceCardSide = insuranceCardFront;
      setInsuranceFrontName(container.files[0]["name"]);
    } else {
      setInsuranceImageBack(img);
      insuranceCardSide = insuranceCardBack;
      setInsuranceBackName(container.files[0]["name"]);
    }

    document.getElementById(`${insuranceImageSide}-of-card${insuranceCardSide}`).files = container.files;
    announceFileUpload(container.files[0], `announce-card-${insuranceImageSide}-upload${insuranceCardSide}`);
    setShowWebcam(false);
  }

  const errorMessage = (error) => (
    errors[error] && <ErrorMessage message={t(errors[error])} />
  );

  const policyHolderValue = values.user[insurancePolicyHolder] ? {label: t(`registration.insurance_policy_holder.${values.user[insurancePolicyHolder]}`), value: values.user[insurancePolicyHolder]} : " ";
  const insuranceCompanyValue = values.user[insuranceCompany] ? {label: values.user[insuranceCompany], value: values.user[insuranceCompany]} : " ";

  return (
    <div>
      <Row className='mb-4'>
        <Col sm={12} md={6} lg={3}>
          <label htmlFor={insuranceCompany}>{t('registration.insurance_information.company_name')}</label>
          <CreatableSelect
            id={insuranceCompany}
            name={`user[${insuranceCompany}]`}
            value={insuranceCompanyValue}
            onChange={(s) => setFieldValue(`user[${insuranceCompany}]`, s.value)}
            options={insuranceCompanyOptions}
            formatCreateLabel={(value) => `Other - click here to add: ${value}`}
            aria-label={t('registration.insurance_information.company_name')}
            className={errors[insuranceCompany] && 'is-invalid'}
          />
          {errorMessage(insuranceCompany)}
        </Col>
        <Col sm={12} md={6} lg={3}>
          <label htmlFor={insuranceId}>Member ID #</label>
          <input
            id={insuranceId}
            className="form-control"
            name={`user[${insuranceId}]`}
            value={values.user[insuranceId] || ""}
            onChange={handleChange}
            aria-label={t('registration.insurance_information.id_number')}
            aria-invalid={!!errorMessage(insuranceId)}
          />
          {errorMessage(insuranceId)}
        </Col>
        <Col sm={12} md={6} lg={3}>
          <label htmlFor="group_number">Member group #</label>
          <input
            id="group_number"
            className="form-control"
            name={`user[${insuranceGroupNumber}]`}
            value={values.user[insuranceGroupNumber] || ""}
            onChange={handleChange}
            aria-label={t('registration.insurance_information.group_number')}
          />
        </Col>
        <Col sm={12} md={6} lg={3}>
          <label htmlFor={insurancePolicyHolder}>{t('registration.insurance_policy_holder.question')}</label>
          <Select
            id={insurancePolicyHolder}
            name={`user[${insurancePolicyHolder}]`}
            value={policyHolderValue}
            onChange={(s) => {
              setFieldValue(`user[${insurancePolicyHolder}]`, s.value);
              if (secondary) {
                setFormAction({...formAction, secondaryPolicyHolder: s.value});
              } else {
                setFormAction({...formAction, policyHolder: s.value});
              }
            }}
            options={testGroup.policy_holder_options.map((policyHolder) => {
              return { value: policyHolder, label: t(buildCustomizedLabel(testGroup.population, `registration.insurance_policy_holder.${policyHolder}`)) }
            })}
            aria-label={t('registration.insurance_policy_holder.question')}
          />
        </Col>
      </Row>
      <Row>
        <PolicyHolderFields
          setFieldValue={setFieldValue}
          values={values}
          errors={errors}
          handleChange={handleChange}
          names={policyHolderNames}
        />
      </Row>

      <Row className='mb-3'>
        <Row style={{alignItems: "center"}}>
          {showWebcam &&
            <Col xs={"auto"} className="ms-3 mb-2">
              <div className="position-relative">
                <div
                  className="position-absolute popover fade shadow show bs-popover-end overflow-hidden"
                  style={{ top: -205, left: 174 }}
                >
                  <Webcam
                    setImageData={(data) => updateWebcamImage(data)}
                    setShow={(e) => setShowWebcam(e)}
                    style={{width: 350, height: 300, backgroundColor: "black"}}
                  />
                </div>
              </div>
            </Col>
          }
        </Row>
        <Col xs={12} lg={'auto'}>
          <Dropdown>
            <Dropdown.Toggle variant="link" className="chevron" id={`dropdown-${insuranceCardFront}`}>
              Add Insurance Card Front
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {setShowWebcam(true);  setInsuranceImageSide('front')}} >
                <Camera className='me-2' color='inherit' style={{ width: 14 }} />Take photo
              </Dropdown.Item>
              <Dropdown.Item onClick={() => fileFrontRef.current.click()}>
                  <Upload className="me-1" color='inherit' style={{ width: 14 }} /> Upload Insurance Card Front
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <input
            id={"front-of-card" + insuranceCardFront}
            style={{visibility: "hidden", width: "0px"}}
            type="file"
            ref={fileFrontRef}
            onChange={(e) => {
              setFieldValue(`user[${insuranceCardFront}]`, e.currentTarget.files[0]);
              setInsuranceFrontName(e.currentTarget.files[0]["name"]);
              setInsuranceImageFront(null);
              announceFileUpload(e.currentTarget.files[0], 'announce-card-front-upload' + insuranceCardFront);
            }}
            name={`user[${insuranceCardFront}]`}
            accept="image/*;capture=camera"
          />
          {insuranceImageFront && <div><Image src={insuranceImageFront} className="my-3 ms-4 rounded" height="150" /></div>}
          <label className="ms-4 me-2">{insuranceFrontName}</label>
          <span id={"announce-card-front-upload" + insuranceCardFront} aria-live="polite" style={{ visibility: "hidden", color: "var(--bs-green)" }}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
          {errorMessage(insuranceCardFront)}
        </Col>
        <Col xs={12} lg={'auto'}>
          <Dropdown>
            <Dropdown.Toggle variant="link" className="chevron" id={`dropdown-${insuranceCardBack}`}>
              Add Insurance Card Back
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => {setShowWebcam(true); setInsuranceImageSide('back')}} >
                <Camera className='me-2' color='inherit' style={{ width: 14 }} />Take photo
              </Dropdown.Item>
              <Dropdown.Item onClick={() => fileBackRef.current.click()}>
                  <Upload className="me-1" color='inherit' style={{ width: 14 }} /> Upload Insurance Card Back
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <input
            id={"back-of-card" + insuranceCardBack}
            style={{visibility: "hidden", width: "0px"}}
            type="file"
            ref={fileBackRef}
            name={`user[${insuranceCardBack}]`}
            onChange={(e) => {
              setFieldValue(`user[${insuranceCardBack}]`, e.currentTarget.files[0]);
              setInsuranceBackName(e.currentTarget.files[0]["name"]);
              setInsuranceImageBack(null);
              announceFileUpload(e.currentTarget.files[0], 'announce-card-back-upload' + insuranceCardBack);
            }}
            accept="image/*;capture=camera"
          />
          {insuranceImageBack && <div><Image src={insuranceImageBack} className="my-3 ms-4 rounded" height="150" /></div>}
          <label className="ms-4 me-2">{insuranceBackName}</label>
          <span id={"announce-card-back-upload" + insuranceCardBack} aria-live="polite" style={{ visibility: "hidden", color: "var(--bs-green)" }}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default PolicyInformationFields;
