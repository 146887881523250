import React, { useState } from 'react';
import {
  Modal,
  Button,
  Row,
  Col,
  Accordion,
  Collapse,
  Badge,
  Image,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import printJS from 'print-js';
import axios from 'axios';
import SurveyPreview from '../../../common/components/SurveyPreview';
import '../../../common/locales/i18n';
import { ChevronDropDown } from '../../../common/components/Chevron';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faEllipsis, faCheck, faArrowRight, faEnvelope, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { today, dataURItoBlob } from '../../../common/utils';
import AsyncMultiSelect from '../../../common/components/AsyncMultiSelect';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import { Camera, Digital, Manage, Shield, Upload } from '../../../common/components/Icons';
import { useRef } from 'react';
import { buildGuardianConsent, ConsentContainer } from '../../../Consents/ConsentFormsUsers';
import { faEye, faRotate } from '@fortawesome/pro-solid-svg-icons';
import Insurance from '../insurance/Insurance';
import DemographicSection from './CheckinComponents/DemographicSection';
import VaccineProgramSection from './CheckinComponents/VaccineProgramSection';

export const pushReminderToSign = ({ id }) => {
  axios
    .post(`/api/send_consent_reminder_appointment/${id}`)
    .then(() => {
      toastr.success(`Reminder sent!`);
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;
      if (status === 401) {
        toastr.error('Not authorized to send reminder.');
      } else {
        toastr.error('Failed to send reminder.');
      }
    });
};

const pushReminderToUpdateVaccinationStatus = (testGroupId, userId) => {
  axios
    .post(`/api/send_vaccination_status_reminder/${testGroupId}/${userId}`)
    .then(() => {
      toastr.success(`Reminder sent!`);
    })
    .catch((error) => {
      const {
        response: { status },
      } = error;
      if (status === 401) {
        toastr.error('Not authorized to send reminder.');
      } else {
        toastr.error('Failed to send reminder.');
      }
    });
};

const TestLaneSection = ({ appointment }) => {
  return (
    <React.Fragment>
      {appointment.available_test_lanes?.map((testLane) => (
        <div
          style={{ float: 'left' }}
          key={testLane.name}
          data-test-hook="test_lane"
        >
          <input
            type="radio"
            className="btn-check"
            name="appointment[test_lane_id]"
            value={testLane.id}
            id={`test-lane-${testLane.id}`}
            autoComplete="off"
            required
            defaultChecked={appointment.test_lane_id === testLane.id}
          />
          <label
            className="btn btn-outline-primary me-2"
            htmlFor={`test-lane-${testLane.id}`}
          >
            {testLane.name}
          </label>
        </div>
      ))}
      <div className="clearfix"></div>
      <hr/>
    </React.Fragment>
  );
};

const SurveySection = ({ value, survey, title, inputName, newSurvey=false }) => {
  const [answers, setAnswers] = useState(value);
  const [show, setShow] = useState(true);

  return (
    <React.Fragment>
      <div onClick={()=> setShow(!show)} className="h5-vivid-blue">
        <hr/>
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        {title &&
          <h5>
            {title} {' '}
            { Object.keys(answers).length == 0 &&
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-danger"
              />
            }
          </h5>
      }
      </div>
      <Collapse in={show}>
        <div>
          <input type="hidden" name={inputName} value={JSON.stringify(answers)} />
          <SurveyPreview json={survey} data={answers} newSurvey={newSurvey} onValueChanged={(e) => setAnswers(e.data)} />
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const Covid19VaccinationStatusSection = ({ covid19Vaccination, user, testGroup, showHeader=true }) => {
  const [show, setShow] = useState(true);
  const vaccinationStatusSet = covid19Vaccination.status_set;
  const vaccinationStatusUpdatedAt = covid19Vaccination.status_updated_at;
  const vaccinationViewStatusUrl = covid19Vaccination.view_status_url;
  const vaccinationEditStatusUrl = covid19Vaccination.edit_status_url;

  return (
    <React.Fragment>
      {showHeader &&
        <div onClick={()=> setShow(!show)} className="h5-vivid-blue">
          <hr/>
          <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
          <h5>
            COVID-19 Vaccination Status{' '}
            { !vaccinationStatusSet &&
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-danger"
              />
            }
          </h5>
        </div>
      }
      <Collapse in={show} >
        <div>
          {vaccinationStatusSet && <p className="my-1">Last updated: {vaccinationStatusUpdatedAt}</p>}
          <Row className="mt-2 mb-2" style={{alignItems: "center"}}>
            {vaccinationStatusSet ? (
              <React.Fragment>
                <Col xs={12} lg={"auto"}>
                  <a
                    className="btn btn-outline-primary font-weight-bold"
                    href={vaccinationViewStatusUrl}
                    target="_blank"
                  >
                    <FontAwesomeIcon className='me-2' icon={faEye} /> View vaccination status
                  </a>
                </Col>
                <Col xs={"auto"} className="ms-3 mt-1">
                  <a href={vaccinationEditStatusUrl} target="_blank">
                    <label htmlFor="vaccinationStatus" className="btn btn-link">
                      <FontAwesomeIcon icon={faRotate} className="me-2" /> Update
                    </label>
                  </a>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col xs={12} lg={"auto"} className="mb-2">
                  <Dropdown>
                    <Dropdown.Toggle id="dropdown-vaccination-status">
                      <Shield className='me-2' color='inherit' style={{ width: 14 }} /> Add vaccination status
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item href={vaccinationEditStatusUrl} target="_blank">
                      <Digital className='me-2' style={{ width: 14 }} /> Capture status digitally
                      </Dropdown.Item>
                      <Dropdown.Item  href="javascript:;" onClick={() => pushReminderToUpdateVaccinationStatus(testGroup.id, user.id)}>
                        <FontAwesomeIcon className='me-2' icon={faEnvelope} /> Send participant questionnaire
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
              </React.Fragment>
            )}
          </Row>
          {!vaccinationStatusSet && (
            <span className='text-danger font-weight-bold'>
              <FontAwesomeIcon icon={faCircleExclamation} />{' '}
              Missing vaccination status
            </span>
          )}
        </div>
      </Collapse>
    </React.Fragment>
  );
};


export const additionalConsents = (testConfigurations) => (
  testConfigurations.map(tc => tc.test_group_test_configuration.active_consent_form.found &&
    ({id: tc.test_group_test_configuration.active_consent_form.id, testConfiguration: tc })
  ).filter(x => x)
)

const ConsentSection = ({
  appointment: { consent_capture_method, ...appointment },
  user,
  tests=[],
  testGroup,
  testConfigurations=[],
}) => {
  const consented = appointment['consents_signed?'];
  const [consentImage, setConsentImage] = useState(null);
  const [fileText, setFileText] = useState(null);
  const [showWebcam, setShowWebcam] = useState(false);
  const [show, setShow] = useState(true);
  const fileRef = useRef(null)

  const updateWebcamImage = (img) => {
    setConsentImage(img)
    let container = new DataTransfer();
    container.items.add(dataURItoBlob(img, true))
    document.getElementById("consentFile").files = container.files;
    setShowWebcam(false);
  }

  const additionalUserConsents = additionalConsents(testConfigurations).
    filter(x => {
      return user.consent_forms_users.map(cfu => cfu.consent_form_id).includes(x.id) ||
        tests.map(t => t.test_configuration_id).includes(x.testConfiguration.id)
    });

  const additionalConsentState = (consentFormUser) => {
    const consent = consentFormUser || { consent: null, consented: false }
    const declinedConsent = consent.consent == "no";

    const renderTooltip = (props) => (
      <Tooltip {...props}>
        { consent.consented
          ? "Consented"
          : declinedConsent
            ? "Declined consent"
            : "Consent for this service has not been provided by the participant"
        }
      </Tooltip>
    );

    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        {consent.consented ? (
          <span style={{color: "var(--bs-green)"}}>
            <FontAwesomeIcon icon={faCheck} />
          </span>
        ) : (
          <span style={{color: "var(--bs-danger)"}}>
            <FontAwesomeIcon icon={declinedConsent ? faCircleXmark : faCircleExclamation} />
          </span>
        )}
      </OverlayTrigger>
    )
  }

  return (
    <React.Fragment>
      <div onClick={()=> setShow(!show)} className="h5-vivid-blue">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5>
          Consent{' '}
          { !consented &&
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="text-danger"
            />
          }
        </h5>
      </div>
      <Collapse in={show}>
        <div>
        <ConsentContainer
          consented={consented}
          consentOption={consented ? (
            <a
              className="btn btn-link"
              href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users`}
              target="_blank"
            >
               View
            </a>
          ) : (
            <Dropdown>
              <Dropdown.Toggle variant="link" className="chevron" id="dropdown-consent">
                Sign
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={`${user.confirmation_link}?confirmation_bypass=show_consent`} target="_blank">
                  <Digital className='me-2' style={{ width: 14 }} /> Capture consent digitally
                </Dropdown.Item>
                <Dropdown.Item  href="javascript:;" onClick={() => pushReminderToSign(appointment)}>
                  <FontAwesomeIcon className='me-2' icon={faEnvelope} /> Send consent to participant
                </Dropdown.Item>
                <Dropdown.Item
                  href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users`}
                  target="_blank"
                >
                  <Manage className='me-2' style={{ width: 14 }} /> Manage consents
                </Dropdown.Item>
                <Dropdown.Item onClick={() => setShowWebcam(true)} >
                  <Camera className='me-2' color='inherit' style={{ width: 14 }} />Take photo
                </Dropdown.Item>
                <Dropdown.Item onClick={() => fileRef.current.click()}>
                    <Upload className="me-1" color='inherit' style={{ width: 14 }} /> Upload waiver
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
          showWebcam={showWebcam}
          webcamProps={{
            setImageData: (data) => updateWebcamImage(data),
            setShow: setShowWebcam,
          }}
          fileText={fileText}
          fileProps={{
            ref: fileRef,
            id: "consentFile",
            name: "appointment[consent_waiver_image]",
            onChange: (e) => {
              setFileText(e.target.files[0].name);
              setConsentImage(null);
            },
          }}
          consentImage={consentImage}
          badge={consented
            ? <span style={{color: "var(--bs-green)"}}><FontAwesomeIcon icon={faCheck} /></span>
            : <span style={{color: "var(--bs-danger)"}}><FontAwesomeIcon icon={faCircleExclamation} /></span>
          }
          />
          {!consented && (
            <span className='text-danger font-weight-bold'>
              <FontAwesomeIcon icon={faCircleExclamation} />{' '}
              Missing consent
            </span>
          )}
          {consentImage && <div><Image src={consentImage} className="my-3 rounded" height="150" /></div>}
          {buildGuardianConsent(user, appointment, testGroup)}
          { additionalConsents(testConfigurations).map((additionalConsent, idx) => {
            const consentFormUser = user.consent_forms_users.find(consentFormUser => consentFormUser.consent_form_id == additionalConsent.id);
            const requiredConsent = additionalUserConsents.find(a => a.id == additionalConsent.id)
            return (
              <Row key={idx} className='my-3'>
                <Col xl={9} xs={11}>
                  <div className={`p-4 border rounded ${requiredConsent && !consentFormUser?.consented && "border-danger"}`}>
                    <div className='d-flex'>
                      <div><b>{additionalConsent.testConfiguration.checkout_name || additionalConsent.testConfiguration.display_name} consent</b></div>
                      <div className='ms-auto'>
                        {consentFormUser
                          ? <a
                              href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users?consent_type=${additionalConsent.testConfiguration.test_group_test_configuration.id}`}
                              target="_blank"
                            >
                              View
                            </a>
                          : <a
                              href={`/test_groups/${testGroup.slug}/participants/${user.id}/consent_forms_users/${appointment.id}/bulk_edit?redirect_to=${location.href}`}
                              target="_blank"
                            >
                              Sign <FontAwesomeIcon icon={faArrowRight} />
                            </a>
                        }
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xl={3} xs={1} style={{alignSelf: "center"}}>
                  {additionalConsentState(consentFormUser)}
                </Col>
              </Row>
          )})}
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const DependentsSection = ({ appointment, user, testGroup, symptoms }) => {
  return user.dependent_appointments?.map((dep_appointment, idx) => (
    <React.Fragment key={idx + 'consent'}>
      <div className="h4 my-2">
        {dep_appointment.first_name} {dep_appointment.last_name}
      </div>
      <input
        type="hidden"
        name={`dependent_appointments[${idx}][user_id]`}
        value={dep_appointment.id}
      />
      <div className="btn-group-toggle" data-bs-toggle="buttons">
        {appointment?.available_test_lanes?.map((testLane) => (
           <div
            key={testLane.name}
            data-test-hook="test_lane"
          >
            <input
              type="radio"
              className="btn-check"
              name={`dependent_appointments[${idx}][test_lane_id]`}
              value={testLane.id}
              id={`test-lane-${testLane.id}-${idx}`}
              autoComplete="off"
              required
            />
            <label
              className="btn btn-outline-primary me-2"
              htmlFor={`test-lane-${testLane.id}-${idx}`}
            >
              {testLane.name}
            </label>
          </div>
        ))}
      </div>
      <div className="mt-3 mb-3">
        <div className="form-check">
          <input
            type="checkbox"
            name={`dependent_appointments[${idx}][consent]`}
            value="1"
            checked="checked"
            className="form-check-input"
          />
          <label className="form-check-label">Signed Consent?</label>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <SymptomsSection
          symptoms={symptoms}
          appointment={dep_appointment}
          name={`dependent_appointments[${idx}]`}
          showHeader={false}
        />
      </div>
    </React.Fragment>
  ));
};

const SymptomsSection = ({ user={}, symptoms, appointment, name="appointment", showHeader=true }) => {
  const [symptomValues, setSymptomValues] = useState(appointment.symptoms);
  const [show, setShow] = useState(true);

  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      {showHeader &&
        <div onClick={()=> setShow(!show)} className="h5-vivid-blue">
          <hr/>
          <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
          <h5>Symptoms</h5>
          <div className="mb-4">
            Indicate if any participants have the following symptoms today
          </div>
        </div>
      }
      <Collapse in={show} >
        <div>
          {user.dependent_appointments &&
            <h5 className="mb-2">
              {user.first_name} {user.last_name}
            </h5>
          }
          <div className="row">
            {symptoms.map((symptom, idx) => {
              const active =
                symptomValues[symptom.key] === '1' ||
                symptomValues[symptom.key] === 'true';
              return (
                <div className="col-md-6" key={`symptom-${idx}`}>
                  <FabrxCheckbox
                    ariaLabel={t(`symptoms.${symptom.key}`)}
                    value="1"
                    name={`${name}[symptoms_blob][${symptom.key}]`}
                    defaultChecked={active}
                    label={t(`symptoms.${symptom.key}`)}
                  />
                </div>
            )})}
          </div>
        </div>
      </Collapse>
    </React.Fragment>
  );
};

const InsuranceSection = ({ user, appointment, testGroup }) => {
  let missingState;
  if(testGroup.insurance_fields_required.includes('insurance')) {
    missingState = !user.insurance_policy_holder;
  } else {
    missingState = !(user.insurance_policy_holder || user.drivers_license_number || user.social_security_number);
  }

  const [show, setShow] = useState(true);
  const [missing, setMissing] = useState(missingState)

  return (
    <div className="my-3">
      <hr/>
      <div className="h5-vivid-blue">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0" />
        <h5>
          Insurance {' '}
          { missing &&
              <FontAwesomeIcon
                icon={faCircleExclamation}
                className="text-danger"
              />
            }
        </h5>
      </div>
      <Collapse in={show}>
        <div>
          <Insurance test_group={testGroup} user={user} appointment={appointment} />
        </div>
      </Collapse>
    </div>
  );
}

const TagSection = ({user, testGroup}) => {
  const [edit, setEdit] = useState(false);
  const [show, setShow] = useState(true);
  const tags = user.user_tags?.map(userTag => (
    {id: userTag.tag_id, friendly_name: userTag.friendly_name}
  )) || []
  const [activeTags, setActiveTags] = useState(tags);
  const originalTagIds = tags.map(t => t.id);
  const inActiveTags = originalTagIds.filter(t => !activeTags.includes(t))
  const totalTags = activeTags.concat(inActiveTags);

  const isOriginalTag = (tagId) => originalTagIds.find(id => id == tagId);

  return (
    <div className="my-3">
      <hr/>
      <div className="h5-vivid-blue">
        <ChevronDropDown open={show} setOpen={setShow} btnClass="p-0 ps-3" />
        { !edit &&
          <FontAwesomeIcon
            icon={faEllipsis}
            className="text-muted float-end me-2 mt-1 pointer"
            onClick={() => setEdit(!edit)}
          />
        }
        <h5>
          Tags{' '}
        </h5>
      </div>
      <Collapse in={show}>
        {edit ? (
          <div className="w-50">
            <AsyncMultiSelect
              name="Tags"
              selected={activeTags}
              optionsUrl={`/test_groups/${testGroup.slug}/tags`}
              onSelect={(selected) => {
                const newValues = selected ? selected.map(t => t.value) : [];
                setActiveTags(newValues);
              }}
            />
          </div>
        ) : (
          <div>
            {activeTags.map((tag, idx) => (
              <Badge key={idx} variant="primary" className={idx == 0 ? "me-1" : "mx-1"}>{tag.friendly_name}</Badge>
            ))}
          </div>
        )}
      </Collapse>
      {totalTags.map((tagId, idx) => (
          inActiveTags.includes(tagId) ? (
            isOriginalTag(tagId) &&
              <React.Fragment key={idx}>
                <input
                  type="hidden"
                  value="1"
                  name={`user[user_tags_attributes][${idx}][_destroy]`}
                />
                <input
                  type="hidden"
                  value={user.user_tags.find(x => x.tag_id == tagId).id}
                  name={`user[user_tags_attributes][${idx}][id]`}
                />
              </React.Fragment>
          ) : !isOriginalTag(tagId) &&
            <input key={idx} type="hidden" value={tagId} name={`user[user_tags_attributes][${idx}][tag_id]`} />
        ))}
    </div>
)};

export const CheckInInputs = ({
  appointment,
  user,
  tests,
  symptoms,
  testGroup,
  covid19Vaccination,
  activeSections = [],
  testConfigurations = [],
  disableAdvanced=false,
  checkOut=false,
}) => {
  const [checkIn, setCheckIn] = useState(true);

  const guardianInformation = appointment['has_guardian_information?'] && (
    <div>
      Guardian Info: {appointment.consented_guardian_name}
      {appointment.consented_guardian_phone_number &&
        `/ ${appointment.consented_guardian_phone_number}`}
      {appointment.consented_guardian_email &&
        `/ ${appointment.consented_guardian_email}`}
    </div>
  );

  const hasInsuranceSurvey = Object.keys(testGroup.insurance_survey || {}).length > 0;
  const showInsuranceSurvery = (testGroup.show_only_insurance_survey  || testGroup.show_insurance_survey) &&
        hasInsuranceSurvey;
  const showInsuranceSection = testGroup.insurance_fields_requested.includes('insurance') || testGroup.insurance_fields_required.includes('insurance');

  const sections = {
    guardian_information: guardianInformation,
    test_lane: appointment.available_test_lanes?.length > 0 && <TestLaneSection appointment={appointment} />,
    consent: <ConsentSection
      appointment={appointment}
      user={user}
      testGroup={testGroup}
      tests={tests || appointment.tests}
      testConfigurations={testConfigurations} />,
    vaccination_status: <Covid19VaccinationStatusSection covid19Vaccination={covid19Vaccination} user={user} testGroup={testGroup} />,
    medical_screening_survey: testGroup.medical_screening_survey && (
      <SurveySection
        value={() => {
          try {
            return JSON.parse(appointment.medical_screening_survey_answers.answers)
          } catch {
            return {}
          }
        }}
        survey={testGroup.medical_screening_survey}
        title="Medical Screening"
        inputName="appointment[medical_screening][answers]"
      />
    ),
    appointment_survey: testGroup.show_custom_survey && (
      <SurveySection
        value={appointment.questionnaire}
        survey={testGroup.custom_survey}
        title="Appointment Questionnaire"
        inputName="appointment[questionnaire]"
      />
    ),
    test_group_user_survey: Object.keys(testGroup.test_group_user_survey).length >
      0 && (
      <SurveySection
        value={user.test_group_user.survey}
        survey={testGroup.test_group_user_survey}
        title="Participant Questionnaire"
        inputName="test_group_user[survey]"
      />
    ),
    dependents: testGroup.allow_dependents && (
      <DependentsSection
        appointment={appointment}
        user={user}
        testGroup={testGroup}
        symptoms={symptoms}
      />
    ),
    symptoms: (
      <SymptomsSection
        user={user}
        symptoms={symptoms}
        appointment={appointment}
      />
    ),
    checkout_survey: !!testGroup.checkout_survey && Object.keys(testGroup.checkout_survey).length > 0 && (
      <SurveySection
        value={appointment.checkout_survey || {}}
        survey={testGroup.checkout_survey}
        title="Checkout Questionnaire"
        inputName="appointment[checkout_survey]"
      />
    ),
    insurance: showInsuranceSection && (
      <InsuranceSection user={user} appointment={appointment} testGroup={testGroup} />
    ),
    insurance_survey: showInsuranceSurvery && (
      <SurveySection
        value={typeof(appointment.insurance_answers) == 'string' ? JSON.parse(appointment.insurance_answers) : appointment.insurance_answers}
        survey={testGroup.insurance_survey}
        title="Insurance Questionnaire"
        inputName="appointment[insurance_answers]"
        newSurvey={testGroup.flipper_flags.upgraded_surveyjs}
      />
    ),
    tags: <TagSection user={user} testGroup={testGroup} />,
    vaccine_programs: <VaccineProgramSection appointment={appointment} />,
    demographic_information: <DemographicSection appointment={appointment} testGroup={testGroup} user={user} />,
  };

  return (
    <div className="h5-vivid-blue">
      <input type="hidden" name="_method" value="put" />
      <input
        type="hidden"
        name="authenticity_token"
        value={appointment.form_authenticity_token}
      />
      <input type="hidden" value={checkIn} name="appointment[check_in]" />
      <input type="hidden" value={checkOut} name="appointment[check_out]" />
      {activeSections.map((section, idx) =>
        <React.Fragment key={idx}>
          {sections[section]}
        </React.Fragment>
      )}
      {!disableAdvanced &&
        <Accordion>
          <Accordion.Toggle
            as={Button}
            variant="link"
            className="mb-2 p-0 mt-4"
            eventKey="0"
          >
            Advanced
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <div
            className="h6 custom-control custom-switch custom-switch-lg"
              onClick={() => setCheckIn(!checkIn)}
            >
              <input
                type="radio"
                defaultChecked={checkIn}
                checked={checkIn}
                className="custom-control-input"
              />
              <label className="custom-control-label">Confirm Check In</label>
            </div>
          </Accordion.Collapse>
        </Accordion>
      }
    </div>
  );
};

const CheckInModal = ({
  setShow,
  show,
  appointment,
  user,
  symptoms,
  testGroup,
  testConfigurations=[],
  covid19Vaccination,
  redirect = true,
  overrideCheckin = () => {},
}) => {
  const handleClose = () => setShow(false);

  const appointmentIsToday = appointment.on_demand
    ? true
    : today(appointment.appointment_slot.localized_day);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = document.querySelector('#checkInModal')
    const formData = new FormData(form)

    axios({
        url: `/test_groups/${testGroup.id}/appointments/${appointment.id}`,
        method: "post",
        data: formData,
        headers: { 'content-type': 'multipart/form-data' },
      })
      .then((response) => {
        (response.status == 200) && toastr.success('Checked In');
        overrideCheckin(response.status == 200);
        window.location.reload()
      });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="xl"
      data-test-hook="check_in_modal"
    >
      <Modal.Header>
        <Modal.Title as="h2">
          Check In for {user.first_name} {user.last_name}{' '}
          <div className="lead-20-medium">{user.date_of_birth} (Age: {user.age})</div>
          {!appointmentIsToday && (
            <div className="alert-danger h5 mt-2">
              This appointment is not scheduled for today.
            </div>
          )}
          {appointment.follow_up_time && (
            <div className="h5 mt-2">Follow Up: {appointment.follow_up_time}</div>
          )}
        </Modal.Title>
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
          aria-label="Close"
        ></button>
      </Modal.Header>
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        action={`/test_groups/${testGroup.id}/appointments/${appointment.id}`}
        acceptCharset="UTF-8"
        method="post"
        name="checkInModal"
        id="checkInModal"
        className="h5-vivid-blue"
      >
        <Modal.Body>
          <CheckInInputs
            appointment={appointment}
            user={user}
            symptoms={symptoms}
            covid19Vaccination={covid19Vaccination}
            testGroup={testGroup}
            testConfigurations={testConfigurations}
            activeSections={[
              'guardian_information',
              'test_lane',
              'consent',
              'vaccination_status',
              'medical_screening_survey',
              'appointment_survey',
              'test_group_user_survey',
              'dependents',
              'symptoms',
              'insurance',
              'checkout_survey',
              'insurance_survey',
              'tags',
              'vaccine_programs',
              'demographic_information',
            ].filter(
              (section) =>
                !testGroup.disabled_checkin_options.includes(section),
            )}
          />
        </Modal.Body>

        <Modal.Footer>
          {appointment.links?.vaccine_consent_form && (
            <a
              onClick={() =>
                printJS({
                  printable: appointment.links.vaccine_consent_form,
                  onError: (error) => toastr.error('Could not print: ' + error),
                })
              }
              className="btn btn-link me-auto ms-0xs p-0"
            >
              Print Vaccine Consent Form
              <FontAwesomeIcon icon={faPrint} className="ms-2" />
            </a>
          )}
          <Button variant="outline-tertiary" onClick={handleClose} className="mx-2">
            Cancel
          </Button>
          <Button
            variant="primary"
            className="mx-2"
            type="submit"
            data-test-hook="save"
            onClick={redirect ? null : handleSubmit}
          >
            Save changes
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CheckInModal;
