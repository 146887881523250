import React, { useContext } from 'react';
import { Button, Tooltip } from 'react-bootstrap';

import LocationContext from '../../LocationSelector/context/LocationContext';
import Wrapped from '../../common/components/Wrapped';
import TooltipWrapper from '../../common/components/TooltipWrapper';

export const assembleUrl = (url, param) =>
  url
    ? url.indexOf('?') !== -1
      ? `${url}&${param}`
      : `${url}?${param}`
    : `?${param}`;

const CheckoutButton = ({
  variant = 'primary',
  onDemand,
  testGroup: { request_site_user_location },
  links: {
    create_on_demand_appointment_url,
  },
  readyToCheckout,
  onClick: propOnClick,
}) => {
  const { selectedLocation } = useContext(LocationContext);
  const urlWithLocationParam = (url) =>
    request_site_user_location && selectedLocation
      ? assembleUrl(
          url,
          `assigned_appointment_slot_group_id=${selectedLocation}`,
        )
      : url;

  const buttonProps = (href) =>
    !request_site_user_location && propOnClick
      ? { onClick: () => propOnClick(href) }
      : { href: urlWithLocationParam(href), 'data-method': 'post' };

  return onDemand ? (
    <Wrapped
      WrapperComponent={TooltipWrapper}
      wrapIf={!readyToCheckout}
      componentProps={{
        tooltipContent:
          'This participant cannot be checked out until their required information is filled in.',
      }}
    >
      <span>
        <Button
          style={{ minWidth: '100%' }}
          variant={variant}
          disabled={!readyToCheckout}
          size="sm"
          {...buttonProps(create_on_demand_appointment_url)}
        >
          Checkout
        </Button>
      </span>
    </Wrapped>
  ) : null;
};

export default CheckoutButton;
