import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { today } from '../../../common/utils';

const CheckoutButtons = ({
  appointment,
  links,
  onCheckIn,
  onRescheduleFollowUp,
}) => {
  const checkedInClass = appointment.check_in_at
    ? 'btn-primary'
    : 'btn-primary not-allowed';
  const enforceStrictCheckIn =
    appointment.test_group.require_strict_check_in &&
    !today(appointment.appointment_slot.localized_day) &&
    !appointment.on_demand;

  const notOnDemandCheckout = !appointment.on_demand;

  return notOnDemandCheckout && (appointment.archived_status || enforceStrictCheckIn) ? (
    <td colSpan="2">
      {appointment.archived_status ? (
        <Button
          variant="outline-primary"
          href={links.create_appointment}
        >
          Schedule another appointment
        </Button>
      ) : (
        <Button
          variant="outline-primary"
          href={
            appointment.follow_up_props ? null : links.appointment + '?tab=edit'
          }
          onClick={
            appointment.follow_up_props
              ? () => onRescheduleFollowUp(appointment)
              : null
          }
        >
          Reschedule appointment
        </Button>
      )}
    </td>
  ) : (
    <React.Fragment>
      <td className="text-center">
        <Button
          onClick={() => onCheckIn(appointment)}
          variant={appointment.check_in_at ? 'outline-primary' : 'primary'}
          data-test-hook="check_in"
        >
          {appointment.check_in_at ? 'Checked in' : 'Check in'}
        </Button>
      </td>
      <td>
        <a
          className={`${
            appointment['checked_out?']
              ? 'btn btn-outline-primary'
              : `btn ${checkedInClass}`
          }`}
          style={{ color: !appointment['checked_out?'] && 'white' }}
          href={links.checkout_link}
          data-test-hook="check_out"
        >
          {appointment['checked_out?'] ? 'Checked out' : 'Check out'}
        </a>
      </td>
    </React.Fragment>
  );
};

export default CheckoutButtons;
