export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "ရက်",
    "day": "နှစ်",
    "year": "ဇန်နဝါရီလ",
    "january": "ဖေဖော်ဝါရီလ",
    "february": "မတ်လ",
    "march": "ဧပြီလ",
    "april": "မေလ",
    "may": "ဇွန်လ",
    "june": "ဇူလိုင်လ",
    "july": "ဩဂုတ်လ",
    "august": "စက်တင်ဘာလ",
    "september": "အောက်တိုဘာလ",
    "october": "နိုဝင်ဘာလ",
    "november": "ဒီဇင်ဘာလ",
    "december": "{{name}} ၏ရက်ချိန်းကို {{date}} ရက်၊ {{address}} တွင်အတည်ပြုပြီးပါပြီ။"
  },
  "user": {
    "send_appointment_confirmation_message": "Your appointment is confirmed for {{name}}.",
    "landline_appointment_reminder_message": "{{date}} ရက်၊  {{time}} ပြီး %{address၊ ၌ {{name}} ၏ရက်ချိန်းအတည်ပြုပြီးပါပြီ။",
    "send_at_home_visit_confirmation_message": "Your appointment is confirmed on {{date}} after {{time}}.",
    "send_mail_order_confirmation_message": "Your mail order is confirmed",
    "send_waitlist_message": "ဖော်ပြပါလင့်ခ်{{confirmation_link}}အားနှိပ်ပြီး သင်၏ဖုန်းနံပါတ်အား အတည်ပြုပါ။",
    "verify_contact": {
      "phone": "{{confirmation_link}}",
      "email": "ယခုလင့်ခ်{{url}}တွင် ရက်ချိန်းယူရန်စာရင်းသွင်းခြင်း ဆက်လက်ပြုလုပ်ပါ။"
    },
    "duplicate_user_continue_registering": "{{full_name}}  {{name}}သည် အမည်ဖြင့်စာရင်းသွင်းခြင်းအောင်မြင်ပါသည်။ ",
    "on_demand_confirmation_message": "You have successfully registered for {{name}}.",
    "appointment_reminder": "{{text}} နှိပ်ပါ: {{link}}",
    "appointment_reminder_base": "{{first_name_with_last_initial}}၏ ရလဒ်အဖြေများရရှိပါပြီ။ {{url}}ကိုသွား၍ {{access_code}}ကုဒ်နံပါတ် ကိုအသုံးပြုပါ။",
    "test_result_notifier": {
      "text_message": "{{first_name_with_last_initial}} အတွက် လူနာပေါ်တယ်ကို အပ်ဒိတ်လုပ်ပြီးပါပြီ။ {{url}} သို့သွားပြီး ကုဒ်ကို သုံးပါ- {{access_code}}",
      "email_subject": "သင့်လူနာပေါ်တယ်တွင် အပ်ဒိတ်အသစ်များ",
      "email_intro": "မင်္ဂလာပါ {{first_name_with_last_initial}}",
      "email_p1": "မက်ဆေ့ဂျ်အသစ် သို့မဟုတ် လူနာအပ်ဒိတ်ကို သင့်အတွက် ရနိုင်ပါသည်။",
      "email_p2": "အောက်ပါခလုတ်ကို နှိပ်ပါ သို့မဟုတ် သင့်လူနာပေါ်တယ်ကိုကြည့်ရှုရန်နှင့် ကုဒ်ကိုအသုံးပြုရန် လင့်ခ်ကိုအသုံးပြုပါ။",
      "email_p3": "လူနာပေါ်တယ်ကိုကြည့်ပါ-'",
      "email_button": "ပေါ်တယ်ကိုကြည့်ပါ။"
    },
    "landline_appointment_reminder_message_without_time": "ရက်ချိန်းယူထားသော {{date}}ရက်မတိုင်မီ လုပ်ဆောင်ချက်များပြုလုပ်လိုခြင်းနှင့် ရက်ချိန်းအတည်ပြုချက်ကို ကြည့်ရှုစစ်ဆေးလိုပါက လင့်ခ်ကိုနှိပ်ပါ။ ",
    "appointment_reminder_on_demand": "တစ်စုံတစ်ခုမှားယွင်းနေပါသည်။ ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပါ။",
    "new_test_result_notifier": {
      "email_intro": "သင့်လူနာပေါ်တယ်ကို အပ်ဒိတ်လုပ်ပြီးပါပြီ။",
      "email_text_1": "လူနာမှတ်တမ်းအသစ်များ သို့မဟုတ် ဓာတ်ခွဲခန်းအစီရင်ခံစာများကို ကြည့်ရှုရန် အောက်ပါ သင်၏ဆေးဘက်ဆိုင်ရာမှတ်တမ်းပေါ်တယ်ကို ဝင်ရောက်ကြည့်ရှုပါ။",
      "email_button": "Medical History",
      "email_text_2": "You can also copy and paste this URL into your browser:"
    },
    "mailers": {
      "email_contact_us": "Have a question? Contact us at"
    }
  },
  "errors": {
    "generic": {
      "message": "ကွက်လပ်ချန်ထား၍မရပါ။"
    },
    "messages": {
      "blank": "စာရိုက်ပါ။"
    },
    "incorrect_credentials": "Your credentials were incorrect, please try again.",
    "error_activating_test_kit": "There was an error activating the test kit."
  },
  "type": "ငွေပေးချေမှုကို ဆက်လက်လုပ်ဆောင်ပါ။",
  "payment": {
    "continue_label": "သင်၏ရက်ချိန်းအား ပယ်ဖျက်ခြင်း သို့မဟုတ် မလာရောက်ဖြစ်ပါက ပေးချေထားသောငွေပမာဏ၏ ၈၀ ရာခိုင်နှုန်းအား ရက်ချိန်းရက်မှ ၇ ရက်အကြာတွင် ပြန်လည်ပေးအပ်မည်ဖြစ်ပါသည်။ ဝန်ဆောင်မှုစည်းကမ်းချက်အပြည့်အစုံအား [Terms of Service]({{link}}) တွင်ကြည့်ရှုနိုင်ပါသည်။",
    "policy_text": "ကတ်နံပါတ်",
    "card_number": "သက်တမ်းကုန်ဆုံးရက်",
    "expiry_date": "ပေးချေပါ။",
    "pay_button": "ငွေသားဖြင့်ပေးချေရန် သဘောတူပါသည်။",
    "no_credit_card": "နေရာတွင်ပေးချေပါမည်",
    "cash_payment_helper": "ပြေစာ",
    "invoice": "ကတ်ဖြင့်ပေးချေရန်။",
    "pay_by_card": "ကျသင့်ငွေ",
    "cost": "စုစုပေါင်း",
    "total": "{{appointment}} သို့ ရက်ချိန်းလာရောက်သောရက်တွင် ငွေသားဖြင့်ပေးချေပါမည်။",
    "pay_cash_at_appointment": "ပြေစာကိုကြည့်ရှုရန်/ပရင့်ထုတ်ရန်",
    "view_invoice": "သဘောတူညီကြောင်းစာကိုတင်ပါ သို့မဟုတ် အောက်ပါအကွက်တွင် လက်မှတ်ရေးထိုးပါ။",
    "refund_policy": "သင့်ရက်ချိန်းကိုပယ်ဖျက်ပါက ချိန်းဆိုထားသည့်ရက်စွဲမှ ၇ ရက်အကြာတွင် သင့်ပေးချေထားသော {{amount}} ကိုပြန်အမ်းပါမည်။ [ဝန်ဆောင်မှုစည်းမျဉ်းများ]({{link}}) အပြည့်အစုံကို ကြည့်ရှုနိုင်ပါသည်။",
    "refund_window_passed": "ချိန်းဆိုထားသည့်အချိန်ကျော်လွန်သွားသောကြောင့် သင့်ချိန်းဆိုမှုအတွက် ပြန်အမ်းငွေမရှိနိုင်ပါ။ မည်သည့်ဆုံးဖြတ်ချက်များအတွက်မဆို ဆိုက်ညှိနှိုင်းရေးမှူးထံသို့တိုက်ရိုက်ဆက်သွယ်ပါ။",
    "amount_paid": "ပေးချေပြီး ပမာဏ",
    "balance": "လက်ကျန်ငွေ"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "စာရင်းသွင်းခြင်းအောင်မြင်စွာလုပ်ဆောင်ရန် သဘောတူညီချက်လိုအပ်ပါသည်။",
    "stop": "စာရင်းသွင်းခြင်းနောက်ဆုံးအဆင့်ကို လုပ်ဆောင်ရန် သင်၏မိဘ၊ အုပ်ထိန်းသူ၊ သို့မဟုတ် တရားဝင်ဆုံးဖြတ်ချက်ချမည့်သူ၏ သဘောတူညီကြောင်းစာကို အောက်ပါဖောင်တွင်ဖြည့်စွက်ပြီးနောက် ၎င်း၏လက်မှတ်ထိုးရန်လိုအပ်ပါသည်။ သဘောတူညီကြောင်းစာကို ကာယကံရှင်ကိုယ်တိုင်ပြုလုပ်ခွင့်မရှိပါ။",
    "parent_or_guardian_required_html": "သင်၏မိဘ၊ အုပ်ထိန်းသူထံ ယခုလင့်ခ်ကိုပို့၍သော်လည်းကောင်း၊ ယခုစာမျက်နှာတွင် လက်မှတ်ရေးထိုးခြင်းကိုသော်လည်းကောင်း ပြုလုပ်ပါ။",
    "send_parent_or_guardian": "အမည်",
    "consent_guardian_name_signature": "အရွယ်မရောက်သေးသူကိုယ်စား သဘောတူညီကြောင်းလက်မှတ်ရေးထိုးပါ။",
    "consent_for_minor": "မိဘ၊ အုပ်ထိန်းသူ၊ သို့မဟုတ် တရားဝင်ကျန်းမာရေးဆိုင်ရာဆုံးဖြတ်ချက်ချမည့်သူ၏လက်မှတ်",
    "guardian_signature": "ကာယကံရှင်သည် အသက်  {{age}}နှစ်အောက်ဖြစ်ပါက မိဘ၊အုပ်ထိန်းသူမှ ကာယကံရှင်ကိုယ်စား သဘောတူညီကြောင်းလက်မှတ်ရေးထိုးပေးရမည်။ စာရင်းသွင်းခြင်းပြီးမြောက်ရန် မိမိ၏အုပ်ထိန်းသူထံပို့ခြင်း သို့မဟုတ် ကာယကံရှင်ကိုယ်စားလက်မှတ်ရေးထိုးခြင်းပြုလုပ်ပါ။",
    "after_you_schedule": "မျိုးရိုးအမည်",
    "consent_guardian_last_name_signature": "အမည်အပြည့်အစုံ",
    "consent_first_and_last_name": "ဆက်သွယ်ရန် လိုအပ်သည့်အချက်အလက်",
    "phone_number": "အုပ်ထိန်းသူ၏ဖုန်းနံပါတ်",
    "email": "အုပ်ထိန်းသူ၏အီးမေးလ်",
    "consent_registrar_with_guardian_name_signature": "အုပ်ထိန်းသူအမည်၏ရှေ့ဆုံးစာလုံး",
    "consent_registrar_with_guardian_last_name_signature": "အုပ်ထိန်းသူအမည်၏နောက်ဆုံးစာလုံး",
    "consent_registrar_first_name_signature": "စာရင်းသွင်းသူအမည်၏ရှေ့ဆုံးစာလုံး",
    "consent_registrar_last_name_signature": "စာရင်းသွင်းသူအမည်၏နောက်ဆုံးစာလုံး",
    "consent_on_behalf": "ကျွန်ုပ်သည် {{first_name}} {{last_name}} ၏ကိုယ်စား လက်မှတ်ရေးထိုးပါသည်။",
    "consent_on_behalf_registrar": "ကျွန်ုပ်သည် {{first_name}} {{last_name}} ၏ကိုယ်စား လက်မှတ်ရေးထိုးရန် နှုတ်အားဖြင့် လုပ်ပိုင်ခွင့် လွှဲအပ်ခံထားရပါသည်။",
    "consent_on_behalf_registrar_with_guardian": "ကျွန်ုပ်သည် {{first_name}} {{last_name}} ၏အုပ်ထိန်းသူကိုယ်စား လက်မှတ်ရေးထိုးရန် နှုတ်အားဖြင့် လုပ်ပိုင်ခွင့် လွှဲအပ်ခံထားရပါသည်။",
    "registrar_phone": "Registrar's phone number",
    "registrar_email": "Registrar's email",
    "consented_by_parent_guardian": "Parent / Guardian",
    "consented_by_decision_maker": "Authorized Medical Decision-maker",
    "consented_by_guardian_verbal": "Registrar with Guardian (verbal consent obtained)",
    "consented_by_registrar": "Registrar (verbal consent obtained)",
    "full_name": "Full name",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Name & Address",
    "contact_information_additional": "ကိုယ်ရေးအချက်အလက်",
    "personal_information": "Demographic information",
    "address_placeholder": "အမည်",
    "first_name_label": "မျိုးရိုးအမည်",
    "last_name_label": "ပြည်နယ်သည်အက္ခရာနှစ်လုံးပါပြည်နယ်ကုဒ်ဖြစ်ရမည်။",
    "errors": {
      "state": "ဖုန်းနံပါတ်မှားယွင်းနေပါသည်။",
      "phone_number_invalid": "ဤအကွက်ကိုဖြည့်စွက်ရန်လိုအပ်ပါသည်။",
      "required": "သင်၏ဝင်ရောက်မှု မမှန်ကန်ပါ။ ကျေးဇူးပြု၍ ထပ်မံစစ်ဆေးကြည့်ပါ။ ထောက်ပံ့ပေးသောအဖွဲ့အစည်းမှ ရွေးချယ်ထားသောသူများသာ စာရင်းသွင်းခြင်းကို လုပ်ဆောင်နိုင်ပါသည်။ ကျေးဇူးပြု၍ သင်၏အလုပ် (သို့) ကွန်မြူနတီအီးမေးလ်ကိုအသုံးပြုကြည့်ပါ။ အချက်အလက်များအားလုံးမှန်ကန်ကိုက်ညီမှုရှိပြီး ပြသာနာတစ်စုံတစ်ရာရှိသည်ဟု ယူဆပါက ကျေးဇူးပြု၍အကူအညီကို ဆက်သွယ်ပါ။",
      "email_domain": "မွေးနေ့သည်အသက်အရွယ်လိုအပ်ချက်နှင့်ကိုက်ညီမှုမရှိပါ။",
      "age_requirement": "လက်မှတ်လိုအပ်ပါသည်။",
      "signature_required": "အချက်အလက်မှန်ကန်မှုမရှိပါ။",
      "regex_invalid": "ဖော်ပြပါရက်စွဲမရှိပါ။",
      "date_invalid": "စာရင်းသွင်းခြင်းဆက်လက်မပြုလုပ်ပါနှင့်။ ဖော်ပြပါညွှန်ကြားချက်များကို လိုက်နာခြင်း သို့မဟုတ် လူကြီးမင်း၏သက်ဆိုင်ရာထံ ဆက်သွယ်ပါ။",
      "invalid_entry": "မှန်ကန်သောမြို့ဖြစ်ရပါမည်။",
      "city": "* အက္ခရာဖြင့်ရေးထားသော လိုအပ်သောမေးခွန်းအားလုံးကို ဖြေပြီးကြောင်းသေချာရန် ကျေးဇူးပြု၍ စစ်ဆေးပါ။",
      "survey_unanswered": "မှန်ကန်သောဂဏန်း ၅ လုံးပါစာပို့သင်္ကေတဖြစ်ရမည်။",
      "postal_code": "ကျန်းမာရေးအာမခံရှိပါသလား။",
      "field_required": "{{field}} တွင် တန်ဖိုးတစ်ခုရှိရမည်။",
      "option_required": "ရွေးချယ်စရာများထဲမှ တစ်ခုကိုရွေးချယ်ပါ။",
      "year_length_invalid": "ဂဏန်းလေးလုံးပါသော ခုနှစ်ဖြင့် မွေးသက္ကရာဇ် ရိုက်ထည့်ပါ",
      "invalid_day": "ရက်သည် 1 မှ 31 ကြား ဖြစ်ရမည်",
      "reached_max_chars": "သင်သည် ထူးခြားသော စရိုက်လက္ခဏာများ၏ အမြင့်ဆုံးနံပတ်ကို ရောက်ရှိနေသည်။",
      "chars_max": "အများဆုံး စရိုက်လက္ခဏာ",
      "minimum_length": "Input should be at least {{length}} characters long."
    },
    "insurance_status": {
      "question": "ရှိ",
      "have_health_insurance": "မရှိ",
      "do_not_have_health_insurance": "အာမခံထားရှိသူကိုဖော်ပြပါ။"
    },
    "insurance_policy_holder": {
      "question": "ကိုယ်တိုင်",
      "i_am": "ခင်ပွန်း သို့မဟုတ် ဇနီး",
      "my_spouse": "မိဘ",
      "my_parents": "အခြားသူ",
      "someone_else": "အာမခံထားရှိသူအမည်",
      "policy_first_name": "အာမခံထားရှိသူ မျိုးရိုးအမည်",
      "policy_last_name": "အာမခံထားရှိသူ မွေးသက္ကရာဇ်",
      "policy_dob": "အာမခံအချက်အလက်များ",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "အာမခံကုမ္ပဏီအမည်",
      "company_name": "အဖွဲ့ဝင်သက်သေခံနံပါတ်",
      "id_number": "အဖွဲ့ဝင်အုပ်စုနံပါတ်",
      "group_number": "ကျွန်ုပ်တွင် ဒုတိယအာမခံရှိသည်။",
      "secondary_insurance_label": "အလုပ်အကိုင်",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "ကုမ္ပဏီလိပ်စာ",
      "address_1": "ဥပမာ: တိုက်အမှတ် ၂၀၀",
      "address_2": "ကုမ္ပဏီဖုန်း",
      "phone_number": "ကုမ္ပဏီအမည်",
      "company": "အလုပ်ရှင်၏စာပို့သင်္ကေတ",
      "postal_code": "အလုပ်ရှိ",
      "employed": "ကိုယ်ပိုင်စီးပွားရေးလုပ်ကိုင်သူ",
      "sole_prorietor": "အလုပ်မရှိ",
      "not_employed": "စမ်းသပ်ခြင်းကို လက်ရှိတွင် ဤဒေသများတွင်နေထိုင်သော ရပ်ရွာ၀င်များအတွက် \nဦးစားပေးထားပါသည်။",
      "reporting_supervisor": "အစီရင်ခံကြီးကြပ်ရေးမှူး",
      "reporting_department": "အစီရင်ခံရေးဌာန",
      "supervisor_name": "ကြီးကြပ်သူ အမည်",
      "supervisor_email": "ကြီးကြပ်သူ အီးမေးလ်"
    },
    "location_availability": "မေးခွန်းလွှာ",
    "custom_survey": "ပြန်လည်စစ်ဆေးခြင်း",
    "confirmation": "စောင့်ဆိုင်းစာရင်း",
    "waitlisted": "ရက်ချိန်းရယူပါ။",
    "schedule_your_appointment": "အချက်အလက်",
    "information": "သဘောတူညီမှု",
    "consent": "တည်နေရာ",
    "location": "ရောဂါလက္ခဏာများ",
    "symptoms": "သင်၏လိပ်စာကိုထည့်ပါ။",
    "address_required": "ရှေ့ဆက်ရန် သဘောတူပါ။",
    "consent_required": "လိုအပ်သောနယ်ပယ်ကိုဖော်ပြထားသည်။",
    "required_field": "ဖုန်းနံပါတ်",
    "phone_number": "အီးမေးလ်",
    "email": "မွေးသက္ကရာဇ်",
    "date_of_birth": "အနိမ့်ဆုံးအသက်မှာ {{year}}နှစ်ဖြစ်သည်။",
    "minimum_age": "စမ်းသပ်မှုပြုလုပ်ရန် အနည်းဆုံးအသက်ကန့်သက်ချက်မရှိပါ။",
    "no_minimum_age": "ဆက်ရန်",
    "continue_button": "မှန်ကန်သော အီးမေးလိပ်စာကိုဖော်ပြပါ။",
    "email_required": "ပြီးမြောက်သည်။",
    "done": "လက်မှတ်",
    "signature": "ရှင်းလင်းရန်",
    "clear_button": "နောက်သို့",
    "back_button": "တည်နေရာ ရွေးချယ်ပါ။",
    "choose_location": "{{location}} တွင် လုံလောက်သောဇယားများမရှိပါ။",
    "no_slots": "{{location}}၌ ရက်ချိန်းရယူလိုသောအချိန်ကို ရွေးချယ်ပါ။",
    "choose_appointment": "ရက်ချိန်းအချိန်ကို ရွေးပါ။",
    "appointment_required": "ဖုန်းနံပါတ်မမှန်ကန်ပါ။",
    "phone_number_required": "ပိုမိုမြန်ဆန်သော စမ်းသပ်မှုရလဒ်များရရှိနိုင်ရန် စာတိုများလက်ခံနိုင်သော \nဖုန်းနံပါတ်တစ်ခုကိုထည့်ပါ။",
    "phone_number_label": "သင်လက်ရှိခံစားနေရသော ရောဂါလက္ခဏာများကို ရွေးပါ။ \nရောဂါလက္ခဏာမရှိပါက ဆက်ပါ။",
    "symptoms_experiencing": "အိမ်ထောင်စုဝင်များ",
    "household": "ဆေးစစ်ရန်လိုအပ်သော အိမ်ထောင်စု၀င်များအတွက် ရက်ချိန်းပြုလုပ်နိုင်ပါသည်။",
    "household_p1": "အိမ်ထောင်စုဝင်ထည့်ပါ။",
    "add_dependent": "မှီခိုသူကိုဖယ်ရှားပါ။",
    "remove_dependent": "သဘောတူညီမှုများ",
    "dependent_consents": "ပြီးမြောက်သည်။",
    "submit": "စောင့်ဆိုင်းစာရင်းသို့ထည့်ပါ။",
    "add_waitlist": "နေရပ်လိပ်စာ",
    "address": "လိပ်စာ လိုင်း ၁",
    "address_1": "တိုက်ခန်း",
    "address_2": "မြို့",
    "address_city": "ပြည်နယ်",
    "address_state": "စာတိုက်သင်္ကေတ",
    "postal_code": "လူမျိုး",
    "race_ethnicity": "ကျား၊မ ဖြစ်တည်မှု",
    "gender": "ကိုယ်တိုင်ဖော်ပြထားသောလိင်",
    "self_described_gender": "စကားပြန်လိုအပ်ပါသလား။ မည်သည့်ဘာသာစကားဖြင့်လိုအပ်ပါသလဲ။",
    "interpreter": "ဤစည်းကမ်းချက်များကို ကျွန်ုပ်သဘောတူပါသည်။",
    "consent_to_terms": "စာရင်းသွင်းခြင်း မဖွင့်သေးပါ။",
    "reg_not_open": "သင်ရွေးချယ်ထားသောအချိန်ကို မရနိုင်တော့ပါ။ ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပါ။",
    "no_more_avail_spots": "လက်မှတ်ထိုးရန် အောက်ပါအကွက်ကိုဖြတ်၍ ကွန်ပျုတာမောက်စ် သို့မဟုတ် \nလက်ချောင်းဖြင့်ဆွဲပါ။",
    "consent_helper": "ကြိုးဖုန်း",
    "phone_number_unreachable_label": "ရွေးချယ်ပါ။",
    "select": "ရက်ချိန်းမေးခွန်းများ",
    "test_surveys": "ပြင်ဆင်ရန်",
    "edit": "စာရင်းသွင်းခြင်းသို့ ဆက်သွားရန်",
    "continue_to_registration": "အကောင့်ရှိပြီးသားလား။",
    "accounts": {
      "already_have_an_account": "အကောင့်ဝင်ပါ။",
      "log_in": "‌အကောင့်ဝင်ရန်",
      "sign_up": "သင်၏အကောင့်ကိုဖန်တီးရန်နှင့် စာရင်းထပ်မံသွင်းချိန်တွင် \nအချိန်ကုန်သက်သာစေရန် သင်၏အချက်အလက်များကိုထည့်ပါ။",
      "sign_up_description": "သင်၏အီးမေးလ်နှင့်စကားဝှက်ကိုရိုက်ထည့်ပါ၊ သို့မဟုတ်အောက်ပါ \nGoogle သို့မဟုတ် Outlook မှတဆင့်ဝင်ပါ။",
      "log_in_description": "{{provider}}နှင့် အကောင့်သို့ဝင်ပါ။",
      "sign_in_with_label": "စကားဝှက်များ ကိုက်ညီမှုရှိရပါမည်။",
      "password_must_match": "သင်၏စကားဝှက်တွင် စာလုံးကြီးအနည်းဆုံး (%s) လုံးပါရှိရမည်။",
      "password_uppercase": "သင်၏စကားဝှက်တွင် စာလုံးသေးအနည်းဆုံး (%s) လုံးပါရှိရမည်။",
      "password_lowercase": "သင်၏စကားဝှက်တွင် ဂဏန်းအနည်းဆုံး (%s) လုံးပါရှိရမည်။",
      "password_number": "သင်၏စကားဝှက်တွင် အထူးအက္ခရာအနည်းဆုံး (%s) လုံးပါရှိရမည်။",
      "password_special": "စကားဝှက်"
    },
    "password": "စကားဝှက် ထပ်မံရိုက်ထည့် အတည်ပြုရန်",
    "password_confirmation": " {{name}}အတွက် သဘောတူညီချက်",
    "consent_for": "ရက်ချိန်းတစ်ကြိမ်စာရယူပါ။",
    "book_one_time_appointment": "သင်စာရင်းသွင်းပြီးပါပြီ။",
    "duplicate_users": {
      "exists": "သင်၏ဖုန်းနံပါတ်နှင့်အီးမေးလ်လိပ်စာသို့ မက်ဆေ့ပေးပို့ပြီးပါပြီ။",
      "overlapping_email_and_phone_p1": "လက်ခံရရှိသောမက်ဆေ့ကိုသုံး၍ သင်၏ရက်ချိန်းကိုပြောင်းနိုင်သည် (သို့) \nဆေးစစ်ချက်အဖြေကိုထည့်နိုင်သည်။",
      "p2": "အသေးစိတ်အတွက် သင်၏အီးမေးလ် သို့မဟုတ် ဖုန်းကိုစစ်ဆေးပါ။",
      "overlapping_phone_and_email_p3": "အသေးစိတ်အတွက် သင်၏အီးမေးလ်ကိုစစ်ဆေးပါ။",
      "overlapping_email_p3": "အသေးစိတ်အတွက် သင်၏ဖုန်းကိုစစ်ဆေးပါ။",
      "overlapping_phone_p3": "လူကြီးမင်း၏ဖုန်းနံပါတ်သို့ မက်ဆေ့ခ်ျပို့ပြီးပါပြီ။",
      "overlapping_phone_p1": "လူကြီးမင်း၏အီးမေးလ်သို့ မက်ဆေ့ခ်ျပို့ပြီးပါပြီ။",
      "overlapping_email_p1": "မှားယွင်းနေသည်ဟု ယူဆပါက support@primary.health တွင်\nကျွန်ုပ်တို့ကိုဆက်သွယ်ပါ။",
      "p4": "အသေးစိတ်အတွက် သင်၏အီးမေးလ် သို့မဟုတ် ဖုန်းကိုစစ်ဆေးပါ။",
      "overlapping_email_and_phone_p3": "သင် စောင့်ဆိုင်းစာရင်းသွင်းပြီးဖြစ်သည်။"
    },
    "duplicate_waitlist": {
      "exists": "သင့်ဖုန်းနံပါတ်နဲ့အီးမေးလ်လိပ်စာသို့ အတည်ပြုချက်တစ်ခု ထပ်မံပို့ပြီးပါပြီ။",
      "overlapping_email_and_phone_p1": "အတည်ပြုချက်ကိုအသုံးပြု၍ အချက်အလက်ထပ်မံပေးပို့ခြင်း၊ သို့မဟုတ် \nစောင့်ဆိုင်းစာရင်းမှဖယ်ရှားခြင်းများ ပြုလုပ်နိုင်ပါသည်။",
      "p2": "အသေးစိတ်အတွက် သင်၏အီးမေးလ် သို့မဟုတ် ဖုန်းကိုစစ်ဆေးပါ။",
      "overlapping_phone_and_email_p3": "အသေးစိတ်အတွက် သင်၏အီးမေးလ်ကိုစစ်ဆေးပါ။",
      "overlapping_email_p3": "အသေးစိတ်အတွက် သင်၏ဖုန်းကိုစစ်ဆေးပါ။",
      "overlapping_phone_p3": "လူကြီးမင်း၏ ဖုန်းနံပါတ်နဲ့အီးမေးလ်လိပ်စာသို့ နောက်ထပ်အတည်ပြုချက်တစ်ခု\nပို့ပြီးပါပြီ။",
      "overlapping_email_p1": "ကိုယ်စားသဘောတူညီပါသည်။"
    },
    "insist_guardian_consent": "ပြန်လည်စစ်ဆေး၍ အတည်ပြုပါ။",
    "confirmation_section": {
      "title": "စာရင်းသွင်းခြင်းအဆုံးမသတ်မီ သင်၏အချက်အလက်များအား ပြန်လည်စစ်ဆေးပါ။",
      "p1": "ပြန်သွား၍ပြင်ဆင်ပါ။",
      "edit_information": "အမည်မသိ browser "
    },
    "iemodal": {
      "title": "ဤ browser များတွင် စာရင်းသွင်းခြင်းကိုလောလောဆယ်ပြုလုပ်နိုင်သည်",
      "body": "အခြားတည်နေရာများကြည့်ရှုရန် နှိပ်ပါ။"
    },
    "show_other_locations": "ဤသည် နိုင်ငံတကာလိပ်စာဖြစ်ပါသည်။",
    "non_us_address": "လျှောက်ထားသူအားစိစစ်ခြင်း",
    "test_group_user_survey": "ကိုယ်တိုင်သဘောတူညီပါသည်။",
    "self_consent": "နိုင်ငံ",
    "address_country": "ကျား",
    "appointment": "ရက်ချိန်း",
    "employee_id": "အလုပ်သမားမှတ်ပုံတင်",
    "appointment_time": "ရက်ချိန်းပြသမည့်အချိန်",
    "appointment_location": "နေရာ",
    "phone_or_email": "ဖုန်းနံပတ် (သို့မဟုတ်) အီးမေးလ်",
    "no_self_consent": "ကိုယ်တိုင်သဘောတူညီရန်ငြင်းပယ်သည်",
    "no_insist_guardian_consent": "{{full_name}} ကိုယ်စား သဘောတူညီရန်ငြင်းပယ်သည်",
    "additional_consents_helpertext": "အပိုဆောင်းခွင့်ပြုချက်များကို နောင်တွင်ထပ်မံတောင်းခံနိုင်ပါသည်",
    "minimum_age_with_months": "ပါဝင်ရန် အနည်းဆုံးအသက်မှာ {{year}} နှစ်နှင့် {{month}} လဖြစ်သည်။",
    "assistive_technology": "အထောက်အကူပြုနည်းပညာ",
    "covid_vaccine_y_n": "ယခုစာရင်းသွင်းခြင်းဖြင့် ကိုဗစ် ၁၉ ရောဂါကာကွယ်ဆေးထိုးနှံလိုပါသလား။",
    "received_covid_vaccine_y_n": "ကိုဗစ်-၁၉ ကာကွယ်ဆေးထိုးနှံဖူးပါသလား",
    "covid_vaccinations": "ကိုဗစ်-၁၉ ကာကွယ်ဆေးများ",
    "select_vaccine_dose": "သင်ထိုးနှံလိုသော ကာကွယ်ဆေးအမျိုးအစားကို ကျေးဇူးပြု၍ ရွေးချယ်ပါ။",
    "demographic_info": "လူဦးရေစာရင်း အချက်အလက်",
    "additional_info": "နောက်ထပ် အချက်အလက်",
    "self_described_race": "မိမိကိုယ်တိုင် သရုပ်ဖော်နိုင်သော လူမျိုး",
    "verify_contact_information": "အတည်ပြုခြင်း",
    "verify_contact": {
      "we_sent_you_a_code": "ေကျးဇူးပြု၍ {{contact}} ထံ ကျွနု်ပ်တို့ ေပးပို့သည့် ကုဒ်ကုိ ထည့်ပါ",
      "confirmation_code": "အတည်ပြုကုဒ်",
      "code_resent": {
        "email": "သင့်အီးေမးလ်ထံ ေနာက်ထပ်ကုဒ်တစ်ခု ေပးပို့ခဲ့သည်",
        "phone_number": "သင့်ဖုန်းထံ ေနာက်ထပ်ကုဒ်တစ်ခု ေပးပို့ခဲ့သည်"
      },
      "did_not_receive_code": "အတည်ပြုကုဒ်် မရဘူးလား။",
      "verify_by": {
        "email": "၎င်းအစား အီးေမးလ်ဖြင့် အတည်ပြုပါ",
        "phone_number": "၎င်းအစား ဖုန်းနံပါတ်ဖြင့် အတည်ပြုပါ"
      }
    },
    "preferred_method_of_communication": {
      "question": "Preferred method of communication",
      "helper_text": "We will contact you with appointment updates",
      "phone_number": "Phone (SMS messaging only and data rates may apply)",
      "landline": "We cannot support landlines at this time. Please provide an email for important communications.",
      "verification_method": "How do you want to receive your authentication code?",
      "verification_helper_text": "You will be sent a code to verify your identity before logging in."
    },
    "skip_for_now": "Skip for now",
    "default_information": {
      "title": "Okay, we'll use this address",
      "title_v2": "အိုကေ၊ ငါတို့ဤဆက်သွယ်ရန်အချက်အလက်ကိုအသုံးပြုမည်"
    },
    "middle_name_label": "Middle name",
    "confirm_appointment": "Confirm appointment",
    "dob": "DOB",
    "different_appointment": "Different appointment",
    "select_appointment_time": "Select appointment time",
    "decline_address": "I decline to provide an address. Please use the program's address instead.",
    "patient_information": "Patient information",
    "scan_license": "Scan driver's license",
    "how_to_contact": "How should we contact you?",
    "decline_email_or_phone": "I decline to provide an email or phone number. I authorize program administrators to receive my results.",
    "medical_screening": "Medical screening",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "optional",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "မ",
    "female": "အခြား",
    "cisgender": "အခြား",
    "non_binary": "အခြား",
    "other": "စာရင်းတွင်ဖော်ပြထားခြင်းမရှိပါ။",
    "prefer_to_self_describe": "ဖြေရန်ငြင်းပယ်သည်။",
    "prefer_not_to_disclose": "ကျား (လိင်ပြောင်း)",
    "transgender_male": "မ (လိင်ပြောင်း)",
    "transgender_female": "အမည်မသိ",
    "unknown": "အမေရိကန် အင်ဒီယန်း သို့ အလာစကာနွယ်ဖွား",
    "non_binary_only": "ဒွိလိင်မဟုတ်သူ",
    "intersex": "လိင်ထင်ထင်ရှားရှား မကွဲသူ",
    "transgender": "လိင်ပြောင်း",
    "gender_fluid": "လူမျိုးရေးကျပ်",
    "not_applicable": "မသက်ဆိုင်ပါ"
  },
  "ethnicity": {
    "american_indian_alaska_native": "တောင် သို့မဟုတ် အလယ်ပိုင်းအမေရိကမှ အမေရိကန် အင်ဒီယန်းလူမျိုး",
    "american_indian_central_america": "အာရှတိုက်သား",
    "asian": "လူမည်း သို့မဟုတ် အာဖရိကန်-အမေရိကန်လူမျိုး",
    "black": "တောင်အမေရိကတိုက်သား သို့မဟုတ် စပိန်ဘာသာပြောလက်တင်အမေရိကတိုက်သား",
    "latinx": "အရှေ့အလယ်ပိုင်း သို့မဟုတ် မြောက်အာဖရိကတိုက်သား",
    "middle_eastern_north_african": "ဟာဝိုင်အီနွယ်ဖွား သို့မဟုတ် ပစိဖိတ်ကျွန်းသား",
    "native_hawaiian_pacific_islander": "လူဖြူ",
    "white_european": "မသိ",
    "unknown": "ထုတ်ဖော်မပြောရန်ရွေးချယ်ပါသည်။",
    "prefer_not_to_disclose": "အာရှအိန္ဒိယတိုက်သား",
    "asian_indian": "ဖိလစ်ပိုင်လူမျိုး",
    "filipino": "ဂျပန်လူမျိုး",
    "japanese": "ကိုရီးယားလူမျိုး",
    "korean": "ဗီယက်နမ်လူမျိုး",
    "vietnamese": "အခြားအာရှလူမျိုး",
    "other_asian": "ဟာဝိုင်အီနွယ်ဖွား",
    "native_hawaiian": "ဂွာမာနီယမ် သို့မဟုတ် ချာမိုရိုလူမျိုး",
    "guamanian_or_chamorro": "စမုန်းလူမျိုး",
    "samoan": "အခြားသော ပစိဖိတ်ကျွန်းသား",
    "other_pacific_islander": "တရုတ်လူမျိုး",
    "chinese": "လူမျိုးဆိုသည်မှာလူအုပ်စုများကိုခွဲခြားရန်သုံးသောရုပ်ပိုင်းဆိုင်ရာလက္ခဏာများကို\nရည်ညွှန်းသည်။ လူမျိုး၊ နိုင်ငံသား (သို့) သင်နိုင်ငံသားဖြစ်သည့်နိုင်ငံနှင့်\nမကိုက်ညီပါ။",
    "help": "စည်းမျဉ်းများအရ အောက်ပါအချက်အလက်အားလုံးကို ကျွန်ုပ်တို့ရယူရန်\nလိုအပ်ပါသည်။",
    "subtitle": "လာအိုနိုင်ငံသား",
    "laotian": "ကမ္ဘောဒီးယားနိုင်ငံသား",
    "cambodian": "အခြားလူမျိုး",
    "other": "အင်္ဂလိပ်ဘာသာ",
    "bangladeshi": "ဘင်္ဂလားဒေရှီ",
    "hmong": "ဟမုန်း",
    "indonesian": "အင်ဒိုနီးရှား",
    "malaysian": "မလေးရှား",
    "pakistani": "ပါကစ္စတန်",
    "sri_lankan": "သီရိလင်္ကာ",
    "thai": "ထိုင်း",
    "taiwanese": "ထိုင်ဝမ်",
    "fijian": "ဖီဂျီ",
    "guamanian": "ဂူအမ်",
    "tongan": "တွန်ဂါ"
  },
  "languages": {
    "en": "ပြင်သစ်ဘာသာ",
    "fr": "စပိန်ဘာသာ",
    "es": "ဆိုမာလီဘာသာ",
    "so": "မောင်းဘာသာ",
    "hmn": "အမေရိကန် လက်ဟန်ဘာသာစကား",
    "asl": "ကရင်ဘာသာ",
    "kar": "အမ်ဟာရစ်ဘာသာ",
    "am": "ရုရှားဘာသာ",
    "ru": "အရိုမိုဘာသာ",
    "om": "ကိုယ်ပူအဖျားရှိခြင်း"
  },
  "symptoms": {
    "fever": "ချောင်းဆိုးခြင်း",
    "cough": "ကြွက်သားများနာကျင်ကိုက်ခဲခြင်း",
    "muscle_aches": "ပင်ပန်းနွမ်းလျခြင်း",
    "severe_fatigue": "အသက်ရှုခက်ခဲခြင်း",
    "trouble_breathing": "ဝမ်းပျက်ဝမ်းလျှောခြင်း",
    "diarrhea": "အနံ့ပျောက်ခြင်း",
    "loss_of_smell": "အရသာပျောက်ခြင်း",
    "loss_of_taste": "မောဟိုက်ခြင်း သို့မဟုတ် အသက်ရှုရခက်ခဲခြင်း",
    "shortness_of_breath": "ခေါင်းကိုက်ခြင်း",
    "headache": "လည်ချောင်းနာခြင်း",
    "sore_throat": "နှာစေး၊ နှာပိတ်ခြင်း",
    "congestion": "ပျို့အန်ခြင်း",
    "nausea": "ရောဂါပိုးကူးစက်ခံရသူနှင့် အနီးကပ်ထိတွေ့မှုရှိခြင်း",
    "close_contact": "*ကိုဗစ်-၁၉ ရောဂါကူးစက်ခံထားရကြောင်း အတည်ပြုထားသောသူတစ်ဦးနှင့် (အနည်းဆုံး ၆ ပေအကွာ၌ ၁၅ မိနစ်) သင်အနီးကပ်ထိတွေ့ဖူးပါသလား။",
    "helper_text": {
      "close_contact": "သံသယဖြစ်ဖွယ်ထိတွေ့မှု"
    },
    "suspected_exposure": "ရောဂါလက္ခဏာများမရှိပါ။",
    "none": "သင်စာရင်းသွင်းပြီးဖြစ်သော်လည်း မသွားခင် ..."
  },
  "instructions": {
    "title": "ဤစာမျက်နှာတွင် သင်၏ရက်ချိန်းနှင့် ရက်ချိန်းဘားကုဒ်တို့ပါ၀င်သည်။",
    "p1": "ရက်ချိန်းတင်သွင်းပြီးပါပြီ။",
    "appointment_scheduled": "သင်၏အချက်အလက်များ မှန်ကန်ကြောင်းအတည်ပြုပါ။",
    "verify_info": {
      "title": "သင်၏ လူနာမှတ်တမ်းကို လျင်မြန်စွာ၀င်ရောက်ကြည့်ရှုနိုင်ရန် \nသင်၏ဆက်သွယ်ရန်အချက်အလက်များကို အတည်ပြုပေးရန် အရေးကြီးပါသည်။",
      "p1": "သင်၏အီးမေးလ်လိပ်စာသို့ အတည်ပြုချက်ပို့ပြီးပါပြီ။ ဖော်ပြပါ လင့်ခ်အားနှိပ်ပါ။",
      "p2": "သင်၏ဆက်သွယ်ရန်အချက်အလက်များကို ပြောင်းလဲပြင်ဆင်လိုပါက \nအကူအညီခလုတ်ကိုနှိပ်ပါ။",
      "p3": "မှန်ကန်ကြောင်းအတည်ပြုပြီးပါပြီ။"
    },
    "verified": "မှန်ကန်ကြောင်းအတည်မပြုရသေးပါ။",
    "not_verified": "လင့်ခ်မရသေးပါ။",
    "resend_text": "ပြန်ပို့ရန်။",
    "resend": "အချက်အလက်များအတည်ပြုခြင်းကိုကျော်၍ အတည်ပြုခြင်းကိုပြပါ။",
    "skip": "အမည်",
    "name": "ရက်ချိန်း",
    "appointment_details": "နေ့စွဲ နှင့် အချိန်",
    "date_and_time": "ပြက္ခဒိန်သို့ထည့်ပါ။",
    "add_to_calendar": "အထွေထွေညွှန်ကြားချက်များ",
    "instructions": "ရက်ချိန်းဆိုမှု အောင်မြင်ပါသည်။",
    "successfully_flash": "အီးမေးလ်ကိုအတည်ပြုခြင်း အောင်မြင်ပါသည်။",
    "success_flash_email": "ဖုန်းနံပါတ်ကိုအတည်ပြုခြင်း အောင်မြင်ပါသည်။",
    "success_flash_phone": "သင်၏စမ်းသပ်ကိရိယာအစုံကို စာတိုက်မှတဆင့် တစ်ပတ်အတွင်းလက်ခံရရှိပါမည်။",
    "mail_order": "ဤအချိန်၌ ကျန်းမာရေးဝန်ထမ်းသည် သင်၏အိမ်သို့လာရောက်မည်။",
    "at_home": "အိမ်တွင်းလမ်းညွှန်ချက်များ",
    "at_home_instructions": "စာတိုက်ဝန်ဆောင်မှု လမ်းညွှန်ချက်များ",
    "mail_order_instructions": "တည်နေရာညွှန်ကြားချက်များ",
    "request_additional_appointment": "နောက်ထပ်ရက်ချိန်းယူရန်။",
    "book_additional_appointment": "အတည်ပြုကုဒ်",
    "confirmation_code": "ပြီးမြောက်သည်။",
    "completed": "ရက်ချိန်းဆိုမှု ဘားကုဒ်",
    "appointment_barcode": "အိမ်ထောင်စုလိုက် ရက်ချိန်းဆိုမှုလင့်ခ်များ",
    "dependent_links": "သင်စာရင်းသွင်းပြီးဖြစ်သော်လည်း မသွားခင် ...",
    "on_demand_title": "ဤလင့်ခ်အားသိမ်းဆည်းမည်။",
    "save_link": "သင်၏ဖုန်းနံပါတ်ကို အတည်ပြုပါ။",
    "verify_phone": "သင်၏မှတ်တမ်းများကို လျင်မြန်စွာရယူနိုင်ရန် သင်၏ဆက်သွယ်ရန်အချက်အလက်များကို အတည်ပြုပေးရန် အရေးကြီးပါသည်။ အချက်အလက်များမှန်ကန်ကြောင်း အတည်ပြုမက်ဆေ့ချ်ကို သင်၏ဖုန်းနံပါတ်သို့ပေးပို့ပြီးပါပြီ။",
    "verify_phone_description": "သင်၏မှတ်တမ်းများကို လျင်မြန်စွာရယူနိုင်ရန် သင်၏ဆက်သွယ်ရန်အချက်အလက်များကို အတည်ပြုပေးရန် အရေးကြီးပါသည်။ အချက်အလက်များမှန်ကန်ကြောင်း အတည်ပြုမက်ဆေ့ချ်ကို သင်၏အီးမေးလ်သို့ပေးပို့ပြီးပါပြီ။",
    "verify_email_description": "စာရင်းသွင်းခြင်းအချက်အလက်",
    "information": "နောက်ဆက်တွဲရက်ချိန်း",
    "follow_up_appointment": "လမ်းညွှန်ချက်များရယူရန်။",
    "get_directions": "ရက်ချိန်းဆိုမှုပယ်ဖျက်ရန်။",
    "cancel": "ရက်ချိန်းထပ်မံရယူရန်။",
    "reschedule_appointment": "ရက်ချိန်းထပ်မံရယူရန်။",
    "reschedule_linked_appointments": "အချိန်မရရှိတော့ပါ။",
    "no_slots": "လူနာမှတ်တမ်းကိုစစ်ဆေးပါ။",
    "check_results": "ဒုတိယအကြိမ်ကာကွယ်ဆေးထိုးရန်ရက်ချိန်းရယူပါ။",
    "follow_up_modal_header": "ရက်ချိန်းဆိုမှုပယ်ဖျက်ရန် သေချာပါသလား။",
    "are_you_sure_you_want_to_cancel": "အောက်ပါရက်ချိန်းဆိုမှုပယ်ဖျက်ရခြင်း အကြောင်းအရင်းတစ်ခုကို ရွေးချယ်ပါ။",
    "please_choose_cancellation_reason": "အသေးစိတ်အကြောင်းအရာကို အောက်တွင်ထပ်မံဖော်ပြပါ။",
    "additional_details": "ရက်ချိန်းဆိုမှုပယ်ဖျက်ရခြင်း အကြောင်းအရင်းတစ်ခုကို ရွေးချယ်ပါ။",
    "errors": {
      "missing_cancellation_reason": "သင့်အီးမေးလ်ကိုအတည်ပြုပါ။"
    },
    "verify_email": "သင်၏စစ်တမ်းကိုပြီးမြောက်ရန်နှိပ်ပါ။",
    "redcap_url": "သင့်ဆက်သွယ်ရန်အချက်အလက်များကိုအတည်ပြုပါ။",
    "verify_contact_information": "အချက်အလက်ကုဒ်မှားယွင်းနေပါသည်။",
    "please_also": "ကျေးဇူးပြု၍",
    "new_title": "Confirmation for {{name}}",
    "contact_method": "Contact method",
    "next_steps": {
      "title": "Next steps",
      "p1": "You have a test kit to activate. When you’re ready to take your test, follow the link sent to your email to return to this page and activate your kit below.",
      "administered": "We will contact you when your kit reaches the lab and when your results are ready. Results typically take 3-5 days after they reach the lab. You can view results from your [Medical history]({{link}}) page when they are ready.",
      "resulted": "Results are available in your [Medical history]({{link}}) page.",
      "shipping_information": "Shipping information"
    },
    "save_this_page": {
      "title": "Save this page",
      "p1": "Use this page to report a self test or view results via the medical history.",
      "p2": "To access this page in the future click on the link in your confirmation email or SMS text.",
      "p3": "You can save this page by bookmarking it, adding it to the home screen or copying the link to a safe place."
    },
    "show_my_barcode": "Show my barcode",
    "my_account": "My account",
    "register_another": "Register another participant",
    "update_vaccine": "Update vaccine information",
    "medical_history": "Medical history",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "ရလဒ်အဖြေကြည့်ရှုရန် ဝင်ပါ။"
    },
    "landing": {
      "p1": "အချက်အလက်ကုဒ်",
      "access_code_label": "တင်ပြမည်။",
      "submit_button": "သင်၏လတ်တလောရလဒ်အဖြေများ",
      "recent_results": "{{name}}အတွက် ဆေးစစ်ချက်အဖြေများ"
    },
    "view_results": {
      "headline": "ပြန်လည်စာရင်းသွင်းမည်။",
      "reregister": "ဆေးစစ်ချက်ရလဒ်များသေချာမှုမရှိပါ။",
      "status": {
        "likely_positive": "ထပ်မံစမ်းသပ်ရန်လိုအပ်သည်။",
        "test_not_performed": "ဆေးစစ်ချက်အဖြေများရရှိပါပြီ။",
        "results_ready": "စစ်ဆေးနေဆဲ",
        "processing": "ဆေးစစ်ချက်အခြေအနေ",
        "test_status": "ဆေးစစ်ချက်အဖြေ",
        "test_result": "ရလဒ်အဖြေ",
        "administered": "စီမံခန့်ခွဲထားသည်",
        "results_pending": "Results pending",
        "test_results": "Test results"
      },
      "at": "{{address}} တွင်"
    },
    "result_label": "ပုံမှန်",
    "result": {
      "flu_a": {
        "result_label": "တုပ်ကွေး (က) အဖြေ"
      },
      "flu_b": {
        "result_label": "တုပ်ကွေး (ခ) အဖြေ"
      },
      "covid19": {
        "result_label": "ကိုဗစ်-၁၉ အဖြေ"
      },
      "covid": {
        "result_label": "ကိုဗစ်-၁၉ အဖြေ"
      },
      "sars": {
        "result_label": "SARS ရလဒ်"
      },
      "control": {
        "result_label": "ထိန်းချုပ်မှု"
      },
      "rsv": {
        "result_label": "RSV ရလဒ်"
      },
      "result": {
        "result_label": "ရလဒ်"
      },
      "hba1c": {
        "normal": "Normal",
        "warning": "Prediabetes",
        "danger": "Diabetes"
      },
      "lead_venous": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "tc": {
        "result_label": "TC Result"
      },
      "hdl": {
        "result_label": "HDL Result"
      },
      "trg": {
        "result_label": "TRG Result"
      },
      "ldl": {
        "result_label": "LDL Result"
      },
      "non_hdl": {
        "result_label": "Non-HDL Result"
      },
      "tc_hdl_ratio": {
        "result_label": "TC/HDL Ratio"
      },
      "glu": {
        "result_label": "GLU Result"
      },
      "alere_cholestech_ldx": {
        "danger": "At Risk",
        "warning": "Abnormal",
        "normal": "Normal"
      },
      "lead": {
        "result_label": "Lead"
      },
      "zinc": {
        "result_label": "Zinc"
      },
      "lead_standard_profile": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "creatinine": {
        "danger": "Abnormal",
        "normal": "Normal"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "အပြောမူကြောင်း",
        "warning": "မြင့်မား",
        "danger": "မြင့်မား"
      },
      "total_cholesterol_fasted": {
        "normal": "အပြောမူကြောင်း",
        "elevated": "မြင့်မား",
        "high": "မြင့်မား",
        "low": "နိမ့်ကျဆင်း"
      },
      "total_cholesterol_unfasted": {
        "normal": "အပြောမူကြောင်း",
        "elevated": "မြင့်မား",
        "high": "မြင့်မား"
      },
      "a1c_now": {
        "normal": "အပြောမူကြောင်း",
        "warning": "ဆီးခဲအချက်ကပင်",
        "danger": "ဆီးခဲရောဂါ"
      },
      "blood_glucose": {
        "warning": "နိမ့်",
        "normal": "ပုံမှန်",
        "prediabetes": "ဆီးချိုမဖြစ်မီအခြေအနေ",
        "danger": "ဆီးချိုရောဂါ"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "စာရွက်စာတမ်းများ",
    "self_administered": "မိမိကိုယ်တိုင် စမ်းသပ်သည့် ပစ္စည်းကို {{location}} တွင် ရယူပါ",
    "patient": "Patient",
    "medical_history": "Medical History",
    "overview_title": "Select a test or service to view more details and any additional action items that are needed. Note that some test results may not yet be available or will only become available after speaking with a provider.",
    "insurance_information": "Fill out insurance information",
    "contact_support": "If you need assistance, or to change your contact information, please contact support.",
    "show": "Show",
    "hide": "Hide",
    "lab_report": "Lab report",
    "contact_provider": {
      "header": "You have positive results",
      "description": "Do you want to talk to a provider to discuss your results and treatment?",
      "yes_option_text": "Yes, I want to talk to a provider",
      "no_option_text": "No, I have read and understand my results, and do not want to talk to a provider",
      "confirm_phone_number_header": "Confirm your phone number",
      "confirm_phone_number_description": "Please confirm the best phone number for your consultation.",
      "confirm_button_text": "Confirm",
      "consultation_confirmed_header": "Consultation confirmed",
      "consultation_confirmed_description": "A provider will call you at {{phone_number}} within 2-3 business days.",
      "acknowledgement_option_helper_text": "Something that explains the importance of treatment and lets the user know how to schedule a call if they change their mind.",
      "acknowledgement_confirmed_header": "Acknowledgement confirmed",
      "acknowledgement_confirmed_description": "Something about the importance of getting treatment with linked resources. Reminder that the consultation is completely free and the provider can prescribe or whatever else to help them resolve the infection.",
      "acknowledgement_confirmed_change_mind_text": "If you change your mind, simply click “I’d like a consultation” below.",
      "request_consultation_button_text": "I’d like a consultation"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "ရက်ချိန်းအသစ်များ"
    }
  },
  "member": {
    "medical_history": "ရာဇဝင်",
    "view_instructions": "လာမည့်ရက်ချိန်း",
    "next_appointment": "မှီခိုသူ မှတ်ပုံတင်စာရင်းသွင်းရန်။",
    "over_18": "ဤချိန်းဆိုမှုအတွက် သင်အလိုရှိသော၀န်ဆောင်မှု(များ)ကိုရွေးပါ။",
    "choose_test_configurations_title": "အသုံးပြုသူတွင်အကောင့်တစ်ခုရှိပြီးဖြစ်သည်။",
    "member_taken_error": "အိမ်ထောင်စု၀င်အားလုံးအတွက် ၀န်ဆောင်မှု(များ)သက်ဆိုင်သည်။",
    "choose_test_configurations_subtitle": "သို့မဟုတ်",
    "service": "ဝန်ဆောင်မှု",
    "group_or_location": "အုပ်စု/တည်နေရာ",
    "details": "အသေးစိတ်အချက်အလက်များ",
    "see_all": "အားလုံးကို ကြည့်ရှုပါ",
    "no_history": "ယခုအချိန်တွင် ရရှိနိုင်သော မှတ်တမ်း မရှိပါ။",
    "medical_history_title_with_name": "ရာခိုင်နှုန်း{{name}}၏ ရာဇဝင်",
    "no_dashboard": "လက်ရှိတွင် ရရှိနိုင်သောအရာ မရှိပါ",
    "product": "ထုတ်ကုန်",
    "price": "ဈေးနှုန်း",
    "quantity": "အရေအတွက်",
    "total_services_selected": "ရွေးချယ်ထားသော စုစုပေါင်းဝန်ဆောင်မှုများ",
    "total_price": "စုစုပေါင်းတန်ဖိုး"
  },
  "or": "အကောင့်တစ်ခုမှတ်ပုံတင်ရန်အသက် ၁၃ နှစ်ဖြစ်ရမည်။",
  "account": {
    "errors": {
      "must_be_13": "ကိုယ်တိုင်စီမံခြင်း"
    }
  },
  "self_administration": {
    "title": "ကိုယ်တိုင်စီမံသူ",
    "self_administer_action": "ကိုယ်တိုင်စီမံခြင်းကို ကျော်ပါ။",
    "skip_to_link": "လုပ်ဆောင်မည့်သူကိုရွေးပါ။",
    "select_person": "လူကြီး",
    "adult": "ကလေး",
    "child": "ရွေးချယ်ပြီးပါပြီ။",
    "checked_out": "ဆက်လက်လုပ်ဆောင်ရန် ကာယကံရှင်၏မွေးသက္ကရာဇ်ကိုအတည်ပြုပါ။",
    "go_back": "ပြန်သွားရန်",
    "switch_to_scanner": "စကင်နာသို့ပြောင်းပါ",
    "enter_barcode": "ဘားကုဒ်ကိုနှိပ်ပါ",
    "scan_barcode": "ဘားကုဒ်ကိုစကင်ဖတ်ပါ",
    "cancel": "ပယ်ဖျက်သည်",
    "barcode_for": "အတွက် ဘားကုဒ်",
    "enter_barcode_manually": "ဘားကုဒ်ကို ကိုယ်တိုင်ရိုက်ထည့်ပါ။",
    "instructions": "သင့်စမ်းသပ်ပြွန်အောက်တွင်ပါရှိသော ဘားကုဒ်ကိုစကင်ဖတ်ပါ သို့မဟုတ် ရိုက်ထည့်ပါ။",
    "regex_prefix": "ဘားကုဒ်တွင် ပါဝင်ရမည်။",
    "all_completed": "ပြီးမြောက်သည်: အိမ်ထောင်စုဝင်အားလုံး ယခုအဆင့်ကိုပြီးမြောက်သည်။",
    "errors": {
      "no_appointment": "ရက်ချိန်းနေရာတွင် ပြဿနာရှိသည်။",
      "barcode_in_use": "ဤဘားကုဒ်ကို ယခင်ကအသုံးပြီးပြုပြီးဖြစ်သည်။ ဤကိရိယာကိုသင်ကိုယ်တိုင် \nကိုင်တွယ်အသုံးမပြုခဲ့ပါက သင့်အားကိရိယာပေးခဲ့သော သက်ဆိုင်ရာ၀န်ထမ်းများ\nနှင့်ဆက်သွယ်ပါ။ သို့မဟုတ် အောက်ပါအကူအညီခလုတ်ကိုနှိပ်ပါ။",
      "test_error_general": "စမ်းသပ်မှုပြုလုပ်ရာတွင် ပြသာနာရှိသည်။",
      "different_barcodes": "သင်ထည့်သောဘားကုဒ်များ ကိုက်ညီမှုမရှိပါ။"
    },
    "confirm_barcode_input": "အတည်ပြုရန် ဘားကုဒ်ကိုထပ်မံရိုက်ထည့်ပါ။",
    "click_to_self_test": "ကိုယ်တိုင်စမ်းသပ်မှုပြုလုပ်ရန် ဤနေရာကိုနှိပ်ပါ။"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "ရက်ချိန်းများမရှိပါ။",
      "unavailable": "နေရပ်လိပ်စာ"
    },
    "labels": {
      "address": "ရရှိနိုင်သောရက်ချိန်းများ",
      "next_appointment": "ရရှိနိုင်သောဝန်ဆောင်မှုများ",
      "services": "စောင့်ဆိုင်းအခန်းထဲသို့ သင်ရောက်ရှိနေပါသည်။ သင့်အား အချိန်ဇယားဆွဲစနစ်\nနှင့်ချိတ်ဆက်ပေးနေစဉ် ကျေးဇူးပြု၍စောင့်ဆိုင်းပါ။"
    }
  },
  "waiting_room": {
    "header": "သည်းခံပေးပါ။ ဒီကပ်ရောဂါကို ကျွန်ုပ်တို့အတူကျော်ဖြတ်နိုင်မှာပါ။",
    "p1": "ရိုးသားစွာဖြင့်",
    "signature": "ကိုဗစ်-၁၉ ရောဂါခံစားရခြင်းသည် စိတ်ပျက်စရာကောင်းသည်ကို နားလည်ပါသည်။ ရက်ချိန်းရယူရန်နေရာသို့ အမြန်ဆုံးပို့ဆောင်ပေးနိုင်ရန် ကြိုးစားဆောင်ရွက်ပေးနေစဉ် ကျေးဇူးပြု၍စောင့်ဆိုင်းပေးပါ။",
    "p2": "မင်္ဂလာပါ။ ၎င်းသည်  {{full_name}} တွင် သင်၏ရက်ချိန်းအတွက် သတိပေးချက်ဖြစ်ပါသည်။ \nသင့်ရက်ချိန်းမှာ {{date}} ရက်၊ {{time}}၊ {{address}} တွင်ဖြစ်ပါသည်။"
  },
  "users": {
    "send_appointment_confirmation_message": "မင်္ဂလာပါ {{full_name}}။ သင်၏ {{name}} အတွက်မှတ်ပုံတင်ရန် \nဤလင့်ခ်{{registration_link}}ကိုသွားပါ။",
    "send_registration_link": "ပထမအကြိမ်ကာကွယ်ဆေး"
  },
  "follow_up": {
    "first_dose": "ယခင်ရက်ချိန်း",
    "previous_appointment": "ယခုရက်ချိန်း",
    "booked_appointment": "ဒုတိယအကြိမ်ကာကွယ်ဆေး",
    "second_dose": "ဒုတိယအကြိမ်ရက်ချိန်းအတွက် နေရာကိုရွေးပါ။",
    "choose_second_location": "ဤနေရာကိုရောက်ရှိရန် နည်းလမ်းမရှိပါ။"
  },
  "cancellation_types": {
    "cant_get_to_location": "ကိုဗစ်-၁၉ ရောဂါခံစားခဲ့ရသည်။",
    "timing_conflict": "ဤ၀န်ဆောင်မှုကို အခြားနေရာတွင်ရခဲ့သည်။",
    "became_ill_with_covid19": "အခြား",
    "received_service_elsewhere": "အမည်ကိုအစားထိုးရိုက်ထည့်ပါ။",
    "other": "အမည်ကိုရိုက်ထည့်ပါ။",
    "duplicate_appointment": "ထပ်နေသော ချိန်းဆိုမှုတစ်ခု ရှိခဲ့သည်"
  },
  "translation": {
    "consent": {
      "type_name_instead": "သင့်ကုဒ်နံပါတ်သည်",
      "type_name": "Type signature instead"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "အောက်ပါခလုတ်ကိုနှိပ်၍ သင့်အီးမေးလ်ကိုအတည်ပြုပါ။",
      "please_confirm_your_email": "မင်္ဂလာပါ။",
      "hello": "ညွှန်ကြားချက်များ/အချိန်ဇယားကိုပြရန်နှိပ်ပါ။",
      "click_to_reschedule": "ညွှန်ကြားချက်များ/အချိန်ဇယားကိုပြရန်နှိပ်ပါ",
      "click_to_reschedule_on_demand": "မင်္ဂလာပါ {{name}}၊ {{time}} {{location}} တွင်သင်၏ရက်ချိန်းကို ပယ်ဖျက်လိုက်သည်။ ဤအရာသည်အမှားတစ်ခုဖြစ်ပါက support@primary.health သို့အီးမေးလ်ပို့ပါ။"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Your appointment at {{time}} at {{location}} has been canceled. If this is a mistake, please email support@primary.health"
    }
  },
  "signature_lines": {
    "name": "ရက်စွဲ",
    "date": "ကာယကံရှင်လက်မှတ်",
    "signature": "နှင့်/သို့မဟုတ်",
    "and_or": "မိဘ/အုပ်ထိန်းသူအမည်",
    "guardian_name": "မိဘ/အုပ်ထိန်းသူလက်မှတ်",
    "guardian_signature": "မင်္ဂလာပါ {{name}}"
  },
  "employer_testing": {
    "hello_name": "ရောဂါပိုးမရှိပါ။",
    "results": {
      "negative": "ရောဂါကူးစက်ခံထားရသည်။",
      "positive": "အမည်မသိ",
      "did_not_result": "သင်၏စမ်းသပ်မှုကတ်ပြားပေါ်တွင် ကုဒ်ကိုစကင်ဖတ်ပါ။",
      "unknown": "အမည်မသိ",
      "invalid": "မခိုင်လုံပါ",
      "pending": "ခွင့်တောင်းနေဆဲ"
    },
    "code_reader": {
      "scan_your_code": "စကင်ဖတ်စစ်ဆေးမှုကုဒ်သည် သင်၏စမ်းသပ်မှုတွင်တစ်မူထူးခြားပြီး သင့်စမ်းသပ်မှု၏ညာဘက်အပေါ်ထောင့်တွင်တွေ့နိုင်သည်။",
      "find_in_top_right_hand_corner": "ကုဒ်ကို အလယ်တည့်တည့်ထားပါ။",
      "center_code": "ဤစမ်းသပ်မှုဘားကုဒ်ကိုအသုံးပြုပြီးပါပြီ။ အကူအညီအတွက် သင်၏စမ်းသပ်ကိရိယာပံ့ပိုးသူကို ဆက်သွယ်ပါ။",
      "already_used_error": "QR ကုဒ်ကိုစကင်ဖတ်ရန်နှိပ်ပါ။",
      "click_to_scan": "စမ်းသပ်မှုကဒ်အသစ်ကိုစကင်ဖတ်ရန် ဤနေရာကိုနှိပ်ပါ။",
      "scan_new_test_card": "လုပ်ဆောင်မှုမအောင်မြင်ပါ။",
      "not_working": "ထပ်မံကြိုးစားရန်ဒီနေရာကိုနှိပ်ပါ။",
      "try_again": "ဆက်လက်လုပ်ဆောင်ပါ။"
    },
    "continue": "ဆက်လက်လုပ်ဆောင်ရန် ကာယကံရှင်၏မွေးသက္ကရာဇ်ကိုအတည်ပြုပါ။",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "စမ်းသပ်မှုအမျိုးအစား: {{test_type}}",
      "contact_administrator": "ထည့်သွင်းထားသော မွေးသက္ကရာဇ်မှားယွင်းနေပါသည်။ ကျေးဇူးပြု၍ ထပ်မံကြိုးစားပါ (သို့) ပြောင်းလဲရန်သင်၏နယ်မြေရှိစီမံခန့်ခွဲသူကိုဆက်သွယ်ပါ။",
      "error": "သင်၏ကုဒ်နံပါတ်ကိုမှတ်ပုံတင်ပြီးပြီ။"
    },
    "result_entry": {
      "code_is_registered": "စမ်းသပ်မှုရယူပါ။",
      "take_the_test": "စမ်းသပ်မှုပါညွှန်ကြားချက်များကိုလိုက်နာပါ။ စမ်းသပ်မှုကတ်ပေါ်ရှိပြွန်အတွင်းသို့ နှာခေါင်းတို့ဖတ်တံကိုထည့်ပြီးသည်နှင့် ၁၅ မိနစ်အချိန်မှတ်ပါ။",
      "follow_the_instructions": "ညွှန်ကြားချက်များကို ရှာမတွေ့ဖြစ်နေပါသလား။",
      "cant_find_instructions": "Binax Now ညွှန်ကြားချက်များကိုကြည့်ရှုပါ။",
      "view_instructions": "၁၅ မိနစ် အချိန်မှတ်ပါ။ (ရွေးချယ်နိုင်သည်)",
      "start_timer": "အကြံပြုချက် - သင်၏ရလဒ်များကို ၁၅ မိနစ်အတွင်းတင်ပြပါ။",
      "submit_within_15": "စမ်းသပ်မှုရလဒ်အဖြေများ ရိုက်ထည့်ပါ။",
      "enter_test_results": "သင်၏စစ်ဆေးမှုကဒ်ရလဒ်ကိုဖော်ပြသော ရွေးစရာကိုရွေးပါ။",
      "choose_a_result": "တင်ပြပါ။",
      "submit": "သင်ရလဒ်တစ်ခုအားမှတ်တမ်းတင်ပြီး ဆက်လက်လုပ်ဆောင်ရန် \nသင့်ရလဒ်အဖြေအား ဓာတ်ပုံရိုက်ပါ။",
      "must_log_result_and_photo": "သိကောင်းစရာ: စမ်းသပ်မှု ၁၅ မိနစ်ကြာပြီးမှ သင်၏ရလဒ်များကိုတင်ပြပါ။",
      "submit_within_15_v2": "ဓာတ်ပုံရိုက်ရန်နှိပ်ပါ။",
      "click_to_take_photo": "စမ်းသပ်မှုရလဒ်များမမှန်ကန်နိုင်ပါ။",
      "results_may_be_invalid": "ရလဒ်အကျဉ်းချုပ်"
    },
    "summary": {
      "title": "ကိုဗစ်-၁၉ ရောဂါပိုးကူးစက်ခံထားရခြင်းမရှိပါ။",
      "negative_subtitle": "သင်သည် ရောဂါကင်းစင်ပြီး လုပ်ငန်းခွင်သို့ပြန်လည်ဝင်ရောက်နိုင်ပြီဖြစ်ကြောင်း သင့်အလုပ်ရှင်အား အကြောင်းကြားပြီးပါပြီ။",
      "negative_message": "ဝမ်းနည်းပါတယ်။ သင် ကိုဗစ်-၁၉ ရောဂါကူးစက်ခံထားရပါသည်။",
      "positive_subtitle": "သင် ကိုဗစ်-၁၉ ရောဂါကူးစက်ခံထားရကြောင့် သင့်အလုပ်ရှင်အား အကြောင်းကြားပြီးပါပြီ။",
      "positive_message": "သင်ဘာတွေလုပ်ဆောင်သင့်လဲ။",
      "what_should_you_do": "အလုပ်ရှင်အမည်များ",
      "employer_name_resources": "CDC စည်းမျဉ်းစည်းကမ်းများကိုလိုက်နာပါ။",
      "follow_cdc_guidelines": "သင်၏ရလဒ်များကိုရှင်းလင်းခြင်း"
    },
    "clarifying_results": {
      "title": "သင့်ရလဒ်အဖြေက ဘယ်ပုံစံနဲ့တူပါသလဲ။",
      "which_did_your_result_most_look_like": "မည်သည့်မျဉ်းကြောင်းမျှမမြင်ရပါ။",
      "no_lines": "အပြာရောင်ထိန်းချုပ်မှုမျဉ်းသာ",
      "blue_control_line_only": "ပန်းရောင်နမူနာမျဉ်းသာ",
      "pink_sample_line_only": "အပြာရောင်ထိန်းချုပ်မှုမျဉ်းနှင့်ပန်းရောင်/ခရမ်းရောင်နမူနာမျဉ်း",
      "blue_control_line_and_pink_sample_line": "ခပ်ရေးရေးမျဉ်းကြောင်း",
      "still_not_sure": "ရလဒ်အဖြေများ တင်ပြသည်။",
      "submit_results": "ကျေးဇူးတင်ပါသည်။",
      "thank_you": "သင်၏ရလဒ်အဖြေရှင်းလင်းချက်ကိုလက်ခံရရှိပါသည်။",
      "clarification_received_message": "အတည်ပြုစာမျက်နှာသို့ပြန်သွားရန်",
      "only_pink_line": "ပန်းရောင်မျဉ်းသာ",
      "no_lines_v2": "မည်သည့်မျဉ်းမျှမရှိပါ"
    },
    "return_to_confirmation": "ဦးစားပေးဘာသာစကား"
  },
  "preferred_language": {
    "title": "ကနဦးကာကွယ်ဆေးရက်ချိန်း(များ)ယူရန်စာအုပ်",
    "subtitle": "မည်သည့် ဘာသာစကားကို ပိုမိုကြိုက်နှစ်သက်သနည်း။"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "ကနဦး ကာကွယ်ဆေးထိုးနှံမှု စီးရီးများ",
    "one_dose": "ပထမအကြိမ်ကာကွယ်ဆေး",
    "title": "ပုံစံ {{datetime_format}} ရှိသင့်သည်",
    "first_dose": "ဒုတိယအကြိမ်ကာကွယ်ဆေး",
    "second_dose": "ကာကွယ်ဆေးတစ်ကြိမ်တည်းသာ",
    "single_dose_title": "ကာကွယ်ဆေးပမာဏရွေးချယ်ခြင်း",
    "additional": "ဖြည့်စွက်",
    "booster": "တိုးမြှင့်ကာကွယ်ဆေး",
    "supplemental": "နောက်ဆက်တွဲ",
    "third_dose": "တတိယအကြိမ် ထိုးဆေးသာ",
    "no_vaccine": "တစ်ခုမှ မဟုတ်ပါ"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "ပုံစံ {{datetime_format}} ရှိသင့်သည်"
            },
            "results_at": {
              "invalid_datetime": "စမ်းသပ်မှုမတွေ့ရှိပါ။"
            },
            "uid": {
              "unknown_test": "အသုံးပြုသူရှိနေပြီး ၎င်းကိုပြင်ဆင်ခြင်းခွင့်မပြုပါ။"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "ပုံစံ {{datetime_format}} ရှိသင့်သည်"
            },
            "date_of_birth": {
              "invalid_date": "အသုံးပြုနေပြီဖြစ်သည်။"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။\n {{ethnicities}}",
                "other": "ဖော်ပြပါလိင် မရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{genders}}"
              }
            },
            "gender": {
              "unknown_gender": "မမှန်ကန်သည့်နံပါတ်ဖြစ်သည်။"
            },
            "phone_number": {
              "invalid": "{{unknown_population}} မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{population_names}}"
            },
            "population_name": {
              "unknown_population": "မည်သည့်လိင်ဖြစ်ကြောင်းကိုယ်တိုင်ဖော်ပြခြင်းကို ဦးစားပေးမဟုတ်ပါက ဖြည့်စွက်၍မရနိုင်ပါ။"
            },
            "self_described_gender": {
              "not_allowed": "ဖော်ပြပါလိင်အမျိုးအစားမရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{sexes}}"
            },
            "sex": {
              "unknown_sex": "လိင်တိမ်းညွှတ်မှု မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{sexual_orientations}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "အခြေအနေမှန်ကန်မှုမရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{statuses}}"
            },
            "status": {
              "unknown_status": "{unknown_races} မရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{races}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} မရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{races}}",
                "other": "ကဏ္ဍသတ်မှတ်ရန် လုံလောက်သောအထူးအခွင့်အရေးများမရှိပါ။"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "အသုံးပြုသူမတွေ့ပါ။"
            },
            "user_id": {
              "unknown_user": "မမှန်ကန်သည့် JSON"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "အသုံးပြုသူမတွေ့ပါ။"
            },
            "user_id": {
              "unknown_user": "ရက်ချိန်းတည်နေရာမတွေ့ပါ။"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "ပုံစံ {{date_format}} ရှိသင့်သည်"
            },
            "date_of_birth": {
              "invalid_date": "အသုံးပြုနေပြီဖြစ်သည်။"
            },
            "email": {
              "duplicated": "{{unknown_ethnicities}} မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။\n {{ethnicities}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။\n {{ethnicities}}",
                "other": "ကျား၊မ ဆိုင်ရာဖြစ်တည်မှုမရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။\n {{genders}}"
              }
            },
            "gender": {
              "unknown_gender": "မမှန်ကန်သည့်နံပါတ်ဖြစ်သည်။"
            },
            "phone_number": {
              "invalid": "{{unknown_population}} မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{population_names}}"
            },
            "population": {
              "unknown_population": "\"မည်သည့်လိင်ဖြစ်ကြောင်းကိုယ်တိုင်ဖော်ပြခြင်း\"ကို ဦးစားပေးမဟုတ်ပါက ဖြည့်စွက်၍မရနိုင်ပါ။"
            },
            "self_described_gender": {
              "not_allowed": "ဖော်ပြပါလိင်အမျိုးအစားမရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ \n{{sexes}}"
            },
            "sex": {
              "unknown_sex": "လိင်တိမ်းညွှတ်မှု မရှိပါ၊ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{sexual_orientations}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "{unknown_races} မရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{races}}"
            },
            "races": {
              "unknown_races": {
                "one": "{unknown_races} မရှိပါ။ အောက်ပါလက်ခံနိုင်သောတန်ဖိုးများမှရွေးပါ။ {{races}}",
                "other": "၎င်းလိပ်စာကို အခြားရှိပြီးသားတည်နေရာတွင် အသုံးပြုနေပြီဖြစ်သည်။"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "စပိန်ဘာသာစကားပြော တောင်အမေရိကတိုက်သား သို့မဟုတ် လက်တင်အမေရိကတိုက်သား"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "စပိန်ဘာသာစကားပြော တောင်အမေရိကတိုက်သားလည်းမဟုတ်၊ လက်တင်အမေရိကတိုက်သားလည်းမဟုတ်သော",
    "not_hispanic": "အခြား စပိန်ဘာသာစကားပြော တောင်အမေရိကတိုက်သား သို့မဟုတ် လက်တင်အမေရိကတိုက်သား",
    "hispanic_expanded": {
      "other": "မက္ကဆီကန်လူမျိုး၊ မက္ကဆီကန်-အမေရိကန်လူမျိုး၊ ချီကာနိုလူမျိုး",
      "mexican": "ပေါ်တိုရီကိုလူမျိုး",
      "puerto_rican": "ကျူးဘားလူမျိုး",
      "cuban": "စည်းမျဉ်းများအရ အောက်ပါအချက်အလက်များကို ကျွန်ုပ်တို့ရယူရန်လိုအပ်သည်။"
    },
    "subtitle": "လူမျိုးရေးဟူသည်ဘာသာစကား၊ ယဉ်ကျေးမှုဓလေ့ထုံးတမ်းများ၊ ဘာသာရေးနှင့် လူအုပ်စုများကိုကွဲပြားစေရန်သုံးသော အခြားစရိုက်လက္ခဏာများကို ရည်ညွှန်းသည်။ ၎င်းသည် လူမျိုးရေးလက္ခဏာတစ်ခုနှင့်လည်းမကိုက်ညီနိုင် သို့မဟုတ် မညှိနိုင်ပါ။",
    "help": "မသိ",
    "unknown": "လူမျိုးစု",
    "title": "ကျား၊မ"
  },
  "sex_at_birth": {
    "question": "မ",
    "female": "ကျား",
    "male": "မွေးစာရင်းတွင်ဖော်ပြပါလိင်",
    "help": "လိင်အမျိုးအစားသည်သင်၏မွေးစာရင်းတွင် ဖော်ပြထားသည်ကိုရည်ညွှန်းသည်။",
    "non_binary": "စည်းမျဉ်းများအရ အောက်ပါအချက်အလက်အားလုံးကို ကျွန်ုပ်တို့ရယူရန်\nလိုအပ်ပါသည်။",
    "subtitle": "ဖြေဆိုရန်ငြင်းပယ်သည်။",
    "decline": "ကျား၊မ ဖြစ်တည်မှု ဆိုသည်မှာ သင်ကိုယ်တိုင်ကိုယ်ကျ ခွဲခြားသတ်မှတ်ပုံကိုရည်ညွှန်းသည်။ ၎င်းသည် သင်မွေးဖွားစဉ်က သတ်မှတ်ထားသောလိင်နှင့်မကိုက်ညီ သို့မဟုတ် မညီဖြစ်နိုင်သည်။",
    "unknown": "မသိ"
  },
  "gender": {
    "help": "သင့်ကျားမ ဖြစ်တည်မှုနှင့် သက်ဆိုင်သောအချက်အလက်များ ထပ်မံထည့်သွင်းလိုပါက လုပ်ဆောင်နိုင်ပါသည်။",
    "subtitle": "လိင်တိမ်းညွှတ်မှု"
  },
  "sexual_orientation": {
    "title": "သင့်လိင်တိမ်းညွှတ်မှုနှင့် သက်ဆိုင်သောအချက်အလက်များ ထပ်မံထည့်သွင်းလိုပါက လုပ်ဆောင်နိုင်ပါသည်။",
    "subtitle": "လိင်တူတိမ်းညွှတ်သူအမျိုးသား၊ လိင်တူတိမ်းညွှတ်သူအမျိုးသမီး၊ သို့မဟုတ် လိင်တူဆက်ဆံသူ",
    "gay": "ဆန့်ကျင်ဘက်လိင်တိမ်းညွှတ်သူ",
    "heterosexual": "လိင်တူလိင်ကွဲ နှစ်မျိုးလုံးကိုတိမ်းညွှတ်သူ",
    "bisexual": "မေးမြန်းခြင်း/မသေချာ/မသိ",
    "questioning": "ထုတ်ဖော်မပြောရန်ရွေးချယ်ပါ။",
    "prefer_not_to_disclose": "မသိ",
    "unknown": "ဦးတည်ချက်ကိုဖော်ပြမထားပါ။",
    "orientation_not_listed": "ဦးစားပေးအခေါ်အ‌‌‌ဝေါ်များ",
    "not_applicable": "မသက်ဆိုင်ပါ",
    "pansexual": "ပန်းဆက်ချူဝယ်",
    "queer": "ကွီယာ"
  },
  "pronouns": {
    "title": "အမျိုးသားဟုဖော်ပြသူ",
    "he": "အမျိုးသမီးဟုဖော်ပြသူ",
    "she": "အမျိုးသားဖြစ်သည် အမျိုးသမီးဖြစ်သည်ဟု တိကျစွာဖော်ပြခြင်းမရှိသူ",
    "they": "ထုတ်ဖော်မပြောရန်ရွေးချယ်ပါ။",
    "choose_not_to_disclose": "ကိုယ်တိုင်ဖော်ပြခြင်းကိုပိုနှစ်သက်သည်။",
    "prefer_to_self_describe": "ယာဉ်မောင်းလိုင်စင် (သို့) ပြည်နယ်သက်သေခံကတ်ပြားနံပါတ်"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "ယာဉ်မောင်းလိုင်စင်",
    "driver_license": "၎င်းအစား SSN ကိုသုံးပါ",
    "use_ssn_instead": "လူမှုဖူလုံရေးကိုယ်ပိုင်နံပါတ်",
    "social_security_number": "ပြည်နယ်",
    "state": "ကျွန်ုပ်သည် ဤဆေးစစ်ချက်အတွက် အဖြေများကိုတစ်ကြိမ်သာ မှတ်တမ်းတင်ကြောင်းအတည်ပြုသည်။",
    "upload_front_of_driver_license": "သင့်ယာဉ်မောင်းလိုင်စင်၏ ရှေ့မျက်နှာစာ သို့မဟုတ် သက်သေခံနံပတ်ကို တင်ပါ",
    "choose_file": "ဖိုင်ရွေးချယ်ပါ",
    "no_file_chosen": "ဖိုင်ရွေးချယ်မထားပါ",
    "no_identification": "ကျွန်ုပ်၌ သက်သေခံကတ်ပြားမရှိပါ",
    "insurance_card_has_a_back": "ကျွန်ုပ်၏အာမခံကတ်ပြားတွင် ကျောဘက်ပါရှိသည်",
    "upload_front_of_insurance_card": "သင့်အာမခံကတ်ပြား၏ ရှေ့မျက်နှာစာကို တင်ပါ",
    "front_of_insurance_card_uploaded": "သင့်အာမခံကတ်ပြား၏ ရှေ့မျက်နှာစာကို တင်ပြီးပါပြီ။",
    "insurance_card": "အာမခံကတ်ပြား",
    "insurance_card_back": "အာမခံကတ်ပြား၏ ကျောဘက်",
    "back_of_insurance_card_uploaded": "သင့်အာမခံကတ်ပြား၏ ကျောဘက်ကို တင်ပြီးပါပြီ။ "
  },
  "quidel": {
    "certify_text": "သင်၏ရလဒ်များဆီသို့ဝင်ရောက်နေသည်။",
    "entering_results": "ညွှန်ကြားချက်များကို သေချာစွာပြန်သုံးသပ်ပါ။",
    "review_instructions_1": "သင်၏ဆေးစစ်ချက်နှင့်ပါလာသော ညွှန်ကြားချက်များကို ပြန်လည်သုံးသပ်နိုင်သည်။ လမ်းညွှန်ချက်ကိုဖတ်ပါ၊ သို့မဟုတ် အောက်ပါလမ်းညွှန်ဗီဒီယိုကိုကြည့်နိုင်သည်။",
    "review_instructions_2": "လမ်းညွှန်ချက်များကိုဖတ်ပါ။",
    "read_instructions": "ဗီဒီယိုကြည့်ပါ။",
    "watch_video": "နှာခေါင်းတို့ဖတ်တံကို ပြွန်ထဲတွင်ထည့်မွှေပြီးနောက် အချိန်စမှတ်ပါ။ ၁ မိနစ်ကြာလျှင် နှာခေါင်းတို့ဖတ်တံကိုပြွန်မှဖယ်ရှားရန်အတွက် အချိန်မှတ်ခြင်းကိုခေတ္တရပ်ထားပါ။ သတိပြုရန်မှာ စမ်းသပ်မှုအဖြေထွက်ရန် ၁၀ မိနစ် အချိန်ဆက်မှတ်ရပါမည်။",
    "timer": "အချိန်စမှတ်ပါ (ရွေးချယ်နိုင်ပါသည်)",
    "start_timer": "ရပ်ပြီးပြန်စပါ။",
    "restart_timer": "ဆက်လက်လုပ်ဆောင်ပါ။",
    "resume": "အချိန် ၁ မိနစ်ပြည့်သွားပါပြီ။ သင်၏ နှာခေါင်းတို့ဖတ်တံကိုပြွန်မှဖယ်ရှားပြီး အချိန်ဆက်မှတ်ပါ။",
    "one_minute_countdown": "သင့်ဆေးစစ်တံကိုဓာတ်ပုံရိုက်ပါ။",
    "take_photo": "ဆက်လက်လုပ်ဆောင်ရန် ဓာတ်ပုံတင်ရန်လိုအပ်ပါသည်။",
    "photo_warning": "ဆေးစစ်ချက်အဖြေများကိုနှိပ်ပါ။",
    "enter_results": "သင့်ဆေးစစ်တံနှင့်အကိုက်ညီဆုံးတစ်ခုကိုရွေးချယ်ပါ။ အကူအညီလိုအပ်ပါသလား။ နမူနာဆေးစစ်ချက်ရလဒ်များကြည့်ရန် ဒီနေရာကိုနှိပ်ပါ။",
    "choose_result": "ကူးစက်ခံထားရသည်။",
    "positive": "ကူးစက်မခံထားရပါ။",
    "negative": "မသိ",
    "unknown": "သင်၏ရလဒ်များကိုရှင်းလင်းခြင်း",
    "clarify": "သင့်ဆေးစစ်တံက ဘယ်တစ်ခုနှင့်ကိုက်ညီမှုအရှိဆုံးဖြစ်ပါသလဲ။",
    "strip_question": "မျဉ်းများမပေါ်ပါ။",
    "no_lines": "ပန်းရောင်မျဉ်းတစ်ကြောင်းသာ",
    "pink_line": "မသေချာသေးပါ။",
    "not_sure": "Not sure"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "ရလဒ်ဓာတ်ပုံ",
    "retake_photo": "ဓာတ်ပုံပြန်ရိုက်ပါ",
    "capture_photo": "ဓာတ်ပုံရိုက်ပါ",
    "confirm_information_correct": "အထက်ပါ အချက်အလက်များ မှန်ကန်ကြောင်း ကျွန်ုပ် အတည်ပြုပါသည်။",
    "submit": "ပို့လွှတ်ပါ",
    "title": "ရာခိုင်နှုန်း(အမည်)အတွက် ရလဒ်များထဲသို့ ဝင်ရောက်ပါ",
    "subtitle": "အိုင်ကျန်းမာရေး ကိုဗစ်-၁၉ အက်ပလီကေးရှင်းကို ဒေါင်းလုပ်ဆွဲရန် (သို့မဟုတ်) အသုံးပြုရန် မလိုအပ်ပါ။",
    "instruction_title": "အိုင်ကျန်းမားရေး ညွှန်ကြားချက်များကို ကြည့်ရှုလေ့လာပါ",
    "instruction_guide": "ညွှန်ကြားချက်များကို ဖတ်ရှုလေ့လာပါ",
    "video_guide": "ဗီဒီယို ကြည့်ရှုပါ",
    "add_photo": "ဓာတ်ပုံထည့်ပါ",
    "confirm_result": "ရလဒ်ကို အတည်ပြုပါ",
    "result_warning": "ပို့လွှတ်ရန် ရလဒ်ကို ရွေးချယ်ပါ",
    "confirm_warning": "ပို့လွှတ်ရန် ရလဒ်ကို အတည်ပြုပါ",
    "administered_at_label": "ဤစစ်ဆေးမှုကို မည်သည့်အချိန်က ခံယူခဲ့ပါသလဲ။",
    "instruction_subtitle": "သင့်စစ်ဆေးမှု၏ ညွှန်ကြားချက်များကို ကြည့်ရှုပါ (သို့မဟုတ်) ဤနေရာမှ ညွှန်ကြားချက်များကို ပြန်လည်ကြည့်ရှုပါ။"
  },
  "public_test_groups": {
    "title": "ကိုဗစ်-၁၉  စစ်ဆေးကိရိယာ",
    "appointment_recoveries_title": "ပြန်လည်အသုံးပြုခြင်းလား",
    "appointment_recoveries_button_text": "ကျွန်ုပ်၏ လင့်ခ်ကို ရှာဖွေပါ",
    "search_test_group_title": "ပထမအကြိမ်လား",
    "search_test_group_button_text": "ယခုစာရင်းသွင်းပါ",
    "title_2": "ရိုးရှင်းလွယ်ကူသော စာရင်းသွင်းခြင်းနှင့် အစီရင်ခံခြင်း",
    "step_one": "အကောင့်စာရင်းသွင်းခြင်း",
    "step_two": "ပြန်ပို့ရန် တံတွေးနမူနာ စုဆောင်းပါ",
    "step_three": "ရလဒ်များကို အင်တာနက်ပေါ်တွင် ကြည့်ရှုပါ",
    "title_3": "သင်၏ စစ်ဆေးကိရိယာကို အသက်သွင်းရန် စာရင်းသွင်းပါ",
    "population_title": "စာရင်းသွင်းခြင်း",
    "population_button": "အသက်သွင်းပါ",
    "faq_subtitle": "ဆက်လက်လေ့လာပါ",
    "faq_title": "ကြိမ်ဖန်များစွာ မေးလေ့ရှိကြသော မေးခွန်းများ",
    "faq_1": "ကျွန်ုပ်၏ စစ်ဆေးကိရိယာကို မည်ကဲ့သို့ ပြန်လည်ပေးအပ်ရမည်လဲ",
    "faq_1_footer": "Saliva Direct Funnel (တံတွေးတိုက်ရိုက်ပြွန်- ကိုဗစ်-၁၉ စစ်ဆေးမှုပုံစံတစ်မျိုး)၏ ညွှန်ကြားချက်များကို ကြည့်ရှုပါ",
    "faq_2": "ကျွန်ုပ် စာရင်းသွင်းခဲ့ပြီးပါပြီ။ သို့သော် ကျွန်ုပ်၏ အကောင့်ကို မေ့နေပါပြီ။ ",
    "faq_3": "ကျွန်ုပ်၏ ကိရိယာကို မည်ကဲ့သို့ အသက်သွင်းရမည်လဲ။",
    "faq_2_subtitle": "ပြန်လည်အသုံးပြုသူတစ်ယောက်အနေဖြင့် အထက်တွင်ပါရှိသော '**ကျွန်ုပ်၏ လင့်ခ်ကို ရှာဖွေပါ**' ခလုတ်ကို အသုံးပြု၍ သင်၏အစီရင်ခံစာကို အတည်ပြုနိုင်ပါသည်။",
    "faq_3_subtitle": "ကျေးဇူးပြု၍ အောက်တွင်ပါရှိသော ရွေးချယ်စရာများကို အသုံးပြကာ အခြေခံကျန်းမာရေးအကောင့်အဆင့်များအတိုင်း သွားပါ။",
    "footer_text_1": "ဤထုတ်ကုန်ကို အက်ဖ်ဒီအေ(အစားအသောက်နှင့်ဆေးဝါးကွပ်ကဲရေးဌာန)မှ ရှင်းလင်းခြင်း (သို့မဟုတ်) အတည်ပြုထားခြင်း မရှိသေးသော်လည်း အီးယူအေ(အရေးပေါ်အသုံးပြုမှုတရားဝင်ခွင့်ပြုချက်)လက်အောက်ရှိ အက်ဖ်ဒီအေမှ အရေးပေါ်အသုံးပြုရန်အတွက် ခွင့်ပြုချက်ရရှိထားပြီးဖြစ်သည်။ ",
    "footer_text_2": "ဤထုတ်ကုန်ကို အခြားဗိုင်းရပ်စ် (သို့မဟုတ်) ရောဂါပိုးမွှားများအတွက်မဟုတ်ဘဲ SARS-CoV-2 မှ နယူကလီရစ်အက်စစ်ကို ရှာဖွေရာတွင် အထောက်အကူအဖြစ် တံတွေးနမူနာများကို စုဆောင်းထိန်းသိမ်းခြင်းအတွက်သာ ခွင့်ပြုထားသည်။ ",
    "footer_text_3": "ဤထုတ်ကုန်၏အရေးပေါ်အသုံးပြုမှုကို ဖက်ဒရယ်အစားအသောက်၊ဆေးဝါးနှင့်အလှကုန် အက်ဥပဒေပုဒ်မ ၂၁ U.S.C. § ၃၆၀ခခခ-၃(ခ)(၁)၏ အခန်း ၅၆၄(ခ)(၁)အောက်ရှိ ဆေးပစ္စည်းကိရိယာများ အရေးပေါ်အသုံးပြုမှုခွင့်ပြုချက်နှင့်ကိုက်ညီသော အခြေအနေများ ဖြစ်ပွားနေကြောင်း ကြေငြာထားသည့်ကာလအတွင်း၌သာ (ကြေငြာချက်အား  ရပ်စဲခြင်း သို့မဟုတ် စောလျင်စွာ ရုတ်သိမ်းခြင်းမရှိပါက) ခွင့်ပြုထားပါသည်။",
    "description": "သင်အဆင်ပြေစေရန်အတွက် အောက်ပါခလုတ်များကို အသုံးပြု၍ သင်၏စစ်ဆေးကိရိယာကို အသက်သွင်းနိုင်ပါသည်။",
    "subtitle": "အခြေခံအဆင့်တွင် ပထမဆုံးအကြိမ်လား။",
    "subtitle_1": "သင်၏ ကိုဗစ်-၁၉ စစ်ဆေးကိရိယာများအကြောင်း",
    "faq_1_1": "အဖုံးပိတ်ထားသော နမူနာပြွန်ကို ဇီဝနမူနာအိတ်ထဲသို့ ထည့်ပါ။",
    "faq_1_2": "ဇီဝနမူနာအိတ်ကို SalivaDirect တံဆိပ်ကပ်ထားသော သေတ္တာထဲသို့ ပြန်ထည့်ပါ။ SalivaDirect တံဆိပ်ကပ်ထားသော သေတ္တာကို FedEX UN 3373 Pak ထဲ သို့ ထည့်ပါ။",
    "faq_1_3": "FedEX UN 3373 Pak ၏ ကပ်ခွာအလွှာကို ဖယ်ရှားပြီး Pak ကို ခိုင်ခံ့လုံခြုံအောင် ပိတ်ရန် အောက်သို့ သေချာစွာ ဖိသိပ်ပေးပါ။",
    "faq_1_4": "သင်၏နမူနာကို သင်စုဆောင်းသည့်နေ့နှင့် သက်ဆိုင်ရာဌာနမှ နောက်ဆုံးစုဆောင်းချိန်မတိုင်မီတွင် ပို့ပေးပါ။ သင့်နမူနာကို စနေ၊ တနင်္ဂနွေနေ့များတွင် drop box သို့ မပို့ပါနှင့်။",
    "faq_1_5": "drop box တည်နေရာများနှင့် စုဆောင်းမည့် အချိန်ဇယားများကို ကြည့်ရှုရန် FedEx ဝက်ဆိုက်သို့ ဖော်ပြပါလင့်ခ်  **[fedex.com/labreturns](https://www.fedex.com/labreturns)** မှ ဝင်ရောက်လေ့လာပါ။",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "ရလဒ်ကို တင်ပြပါ",
    "report_result_subtitle": "သင့်အဖွဲ့အစည်းမှ ကိုယ်တိုင်စစ်ဆေးခြင်းကို ခွင့်ပြုထားပြီး သင်ကိုယ်တိုင်ကလည်း စစ်ဆေးမှုရလဒ်အဖြေကို တင်ပြရန် အဆင်သင့်ဖြစ်လျှင် ကျေးဇူးပြု၍ အောက်ပါခလုတ်ကို နှိပ်ပါ။ ",
    "need_help": "အကူအညီ လိုအပ်ပါသလား။",
    "assistance": "အကူအညီလိုအပ်လျှင် သို့မဟုတ် သင်၏ ဆက်သွယ်ရမည့် အချက်အလက်ကို ပြောင်းလဲလိုလျှင် ကျေးဇူးပြု၍ ထောက်ပံ့ရေးစင်တာသို့ ဆက်သွယ်ပါ။ ",
    "contact_support": "ထောက်ပံ့ရေးစင်တာသို့ ဆက်သွယ်ပါ",
    "save_link": "နောင်တွင် ရလဒ်အဖြေများ တင်ပြရန် ဤလင့်ခ်ကို သိမ်းထားပါ။",
    "send_via_email": "အီးမေးလ်မှတစ်ဆင့် လင့်ခ်ပို့ပါ",
    "send_via_text": "စာတိုဖြင့် လင့်ခ်ပို့ပါ",
    "copy_link": "လင့်ခ်ကို ကူးယူပါ",
    "resend_email": "အီးမေးလ်အတည်ပြုချက် ပြန်လည်ပေးပို့ပါ",
    "phone_verified": "ဖုန်းအတည်ပြုပြီး",
    "add_vaccination_status": "ကိုဗစ်-၁၉ ကာကွယ်ဆေးထိုးနှံမှုအခြေအနေကို ထပ်ထည့်ပါ/အဆင့်မြှင့်ပါ ",
    "terms_of_service": "ဝန်ဆောင်မှုစည်းမျဉ်းများ",
    "verify_contact_information": "စစ်ဆေးမှုရလဒ်များနှင့် ပြောဆိုဆက်သွယ်မှုများကို လက်ခံရရှိနိုင်စေရန် သင်၏ ဆက်သွယ်ရမည့် အချက်အလက်များကို အတည်ပြုပါ။",
    "resend_text": "စာတိုဖြင့် အတည်ပြုချက် ပြန်လည်ပေးပို့ပါ",
    "loading": "ချိတ်ဆက် ဆောင်ရွက်နေသည်",
    "add_to_account": "အကောင့်တွင် ထပ်ထည့်ပါ",
    "hi": "မင်္ဂလာပါ",
    "email_verified": "အီးမေးလ် အတည်ပြုပြီး ",
    "go_to_my_account": "ကျွန်ုပ်၏ အကောင့်သို့ သွားပါ",
    "activate_kit": "စမ်းသပ်ကိရိယာအိတ်ကိုဖွင့်ပါ",
    "report_custom_result": "{{test}}စစ်ဆေးချက်ရလဒ်ကိုတင်ပြပါ",
    "activate_custom_test": "{{test}} စမ်းသပ်မှုစတင်ပါ",
    "update_vaccination_status": "ကာကွယ်ဆေးထိုးခြင်း အခြေအနေကို အပ်ဒိတ်လုပ်ပါ",
    "order_a_test_kit": "စမ်းသပ်ကိရိယာတစ်ခု မှာယူခြင်း"
  },
  "enter_information": {
    "contact_info": "သင့်အား ဆက်သွယ်ရန်အချက်အလက်များကို ဖြည့်ပါ",
    "label": "ဖုန်း သို့မဟုတ် အီးမေးလ်",
    "invalid": "ဤဟာသည် ခိုင်လုံသော အီးမေးလ် သို့မဟုတ် ဖုန်းနံပတ် မဟုတ်ပါ",
    "send": "ကျွန်ုပ်တို့ဘက်မှ သင်၏ ဖုန်း သို့မဟုတ် အီးမေးလ်သို့ ကုဒ်တစ်ခု ပို့ပေးပါမည်",
    "legal": "သင့်ဖုန်းနံပတ် သို့မဟုတ် အီးမေးလ်ကို ရိုက်ထည့်ခြင်းဖြင့် အခြေခံကျန်းမာရေး၏ [ဝန်ဆောင်မှုစည်းမျဉ်းများ](https://primary.health/terms-of-service/) နှင့် [ကိုယ်ရေးအချက်အလက်မူဝါဒ](https://primary.health/privacy-policy/)ကို သဘောတူပါမည်။",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "စာရင်းသွင်းထားပါသလား။ သင်၏လင့်ကို ရှာဖွေပါ",
    "not_registered": "Not Registered?",
    "search_by_keyword": "နာမည်၊ အဓိကစာလုံး၊ မြို့၊ ဇစ်ကုတ် သို့မဟုတ် အဖွဲ့အစည်းကုတ် များဖြင့် ရှာဖွေပါ။",
    "search": "ရှာဖွေပါ",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "မခိုင်လုံသော အချက်အလက်",
    "invalid_explanation": "အခြေခံကျန်းမာရေး သို့ အကောင့်ဖွင့်ရန် လင့်ခ်ကို မကြာသေးမီက သင် တောင်းဆိုထားသည်။ သို့သော်......",
    "never_registered": "ယခင်ကတည်းက စာရင်းမသွင်းရသေးပါသလား။ ကျေးဇူးပြု၍ စာရင်းသွင်းသည့်လင့်အတွက် သင်၏စမ်းသပ်ပရိုဗိုက်တာကို ထောက်ပံ့ပေးပါ သို့မဟုတ် သင့်ဝက်ဘ်ဆိုဒ်ကို အောက်တွင် ရှာဖွေပါ။",
    "get_help": "သင့်မှတ်တမ်းနှင့် ဆက်သွယ်ရမည့်အချက်အလက်ကို တိုက်ကြည့်ပြီး ကိုက်ညီသော အကူအညီကို ရယူနိုင်သည်။ ",
    "get_help_calling": "သို့မဟုတ် ဖုန်းဆက်၍ အကူအညီ ရယူနိုင်သည်။",
    "invalid_explanation_bold": "သင်ပေးထားသော အချက်အလက်မှာ မခိုင်လုံပါ။",
    "get_help_label": "အကူအညီရယူရန်",
    "mobile": {
      "invalid_explanation": "သင့်အကောင့်သို့ ထည့်သွင်းနိုင်သည့် အသုံးပြုသူများကို ရှာမတွေ့ပါ။",
      "get_help": "သင့်တွင် နောက်ထပ်မေးခွန်းများရှိပါက ကျေးဇူးပြု၍ ဆက်သွယ်ပါ",
      "reason": "Primary.Health အသုံးပြုသူများအားလုံးသည် ကျွန်ုပ်တို့၏ အကောင့်အသစ်အင်္ဂါရပ်အတွက် အရည်အချင်းပြည့်မီခြင်း မရှိပါ။ သင်သည် အရည်အချင်းပြည့်မီသည်နှင့်တပြိုင်နက်၊ Primary.Health မှ ဖိတ်ကြားချက် သို့မဟုတ် သင့်အဖွဲ့စီမံခန့်ခွဲသူထံမှ ဖိတ်ကြားချက်ကို လက်ခံရရှိမည်ဖြစ်သည်။"
    },
    "zen_name": "အဆက်အသွယ်အမည်",
    "ticket_type": "မည်သည့်အရာကို ရည်ညွှန်းပြောဆိုပါသနည်း",
    "appointment_access_code": "အတည်ပြုကုဒ် (သိပါက)",
    "zen_desc": "မက်ဆေ့ချ်",
    "contact_us": "ကျွန်ုပ်တို့ထံ ဆက်သွယ်ရန်",
    "need_assistance": "If you need assistance, or to change your contact information, please contact support."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "အကောင့်ထဲသို့ ထည့်ထားသည်",
    "error": "ဤပါဝင်သူတွင် အကောင့်တစ်ခု ရှိနှင့်ပြီးဖြစ်သည်",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "ကုဒ်က ဘာလဲ။",
    "enter_code": "သင့်ဆီ ပို့ထားသော ကုဒ်ကို ရိုက်ထည့်ပါ",
    "incorrect_code": "မှားယွင်းသော ကုဒ်ကို သင်ရိုက်ထည့်ထားသည်",
    "verify": "အကောင့်အတည်ပြုပါ",
    "verified": "အတည်ပြုပြီး",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "မည်သည့်စမ်းသပ်စစ်ဆေးမှုကို သင်ရယူခဲ့သနည်း။",
    "photo_required": "စစ်ဆေးမှုရလဒ်အဖြေဓာတ်ပုံ ထည့်သွင်းရန် လိုအပ်သည်",
    "find_your_test": "သင့်စမ်းသပ်စစ်ဆေးမှုကို ရှာဖွေပါ"
  },
  "login": {
    "create_account": "အကောင့်ဖွင့်မည်",
    "create_account_help": "အကောင့်တစ်ခု ဖွင့်ခြင်းသည် စမ်းသပ်ခြင်း သို့မဟုတ် ကာကွယ်ဆေးထိုးခြင်းတို့အတွက် စာရင်းသွင်းထားမှုနှင့် မတူညီပါ။ သင်သည် တစ်ကြိမ်မျှသာ စာရင်းသွင်းနိုင်ပါသည်",
    "create_account_help_schedule": "ချိန်းဆိုမှုအချိန်ဇယားများ",
    "create_account_help_record": "အိမ်တွင်း စမ်းသပ်စစ်ဆေးမှုမှတ်တမ်းများ",
    "create_account_help_match": "ကာကွယ်ဆေးစီမံခန့်ခွဲခြင်းနှင့် စမ်းသပ်စစ်ဆေးမှုမှတ်တမ်းများ",
    "continue": "ဧည့်သည်အဖြစ် ရှေ့ဆက်သွားမည်",
    "already": "အကောင့်ရှိပြီးသားလား။ နှိပ်ပါ။ ",
    "login": "လော့အင်ဝင်ရန်",
    "or_sign_in": "သို့မဟုတ် လက်မှတ်ထိုးဝင်ပါ",
    "no_account": "အကောင့်မရှိဘူးလား။ နှိပ်ပါ။ ",
    "signup": "စာရင်းသွင်းရန်",
    "here": "ဤနေရာတွင်",
    "email_address": "အီးမေးလ်လိပ်စာ",
    "password": "လျှို့ဝှက်နံပတ်",
    "complete_registration": "စာရင်းသွင်းခြင်း ပြီးမြောက်ပါပြီ",
    "last_covid_test": "နောက်ဆုံး ကိုဗစ်-၁၉ စစ်ဆေးမှု ရလဒ်အဖြေ",
    "no_record": "မှတ်တမ်းမရှိပါ",
    "viewing_information": "ကြည့်ရှုခြင်း {{first_name}}၏ အချက်အလက်",
    "download": "ဒေါင်းလုတ်ဆွဲပါ",
    "not_registered": "မည်သည့်အဖွဲ့တွင်မှ သင် စာရင်းသွင်းထားဟန် မရှိပါ။",
    "please_follow_link": "အခြေခံကျန်းမာရေးရှိ သင့်အချက်အလက်များကို ဝင်ရောက်ရန်နှင့် အစီအစဉ်ဆွဲရန် သင့်အီးမေးလ်တွင် ပေးပို့ထားသော ဖိတ်ခေါ်လင့်ခ်အတိုင်း လိုက်ပါဆောင်ရွက်ပါ။",
    "log_results": "ကျွန်ုပ်၏ စစ်ဆေးမှုရလဒ်များကို မှတ်တမ်းတင်ပါ",
    "book_appointment": "ရက်ချိန်း ကြိုတင်ရယူပါ",
    "no_new_appointments": "လတ်တလောတွင် ချိန်းဆိုမှုအသစ်များကို လက်မခံသေးပါ",
    "upload_vaccinations": "ကာကွယ်ဆေးများ တင်ပါ",
    "vaccination": "ကာကွယ်ဆေး",
    "new_appointment": "ချိန်းဆိုမှုအသစ်",
    "log_otc_results": "ဆရာဝန်၏ဆေးထောက်ခံစာပါသော စစ်ဆေးမှုတစ်ခုအတွက် ရလဒ်များ မှတ်တမ်းတင်ပါ",
    "no_household_members": "သင့်၌ ယခုအချိန်ထိ အိမ်ထောင်စုဝင်များ မရှိသေးပါ",
    "no_upcoming": "လာမည့်ရက်ချိန်း မရှိပါ",
    "update_vaccination": "ကာကွယ်ဆေးထိုးခြင်းကို အဆင့်မြှင့်တင်ပါ(အပ်ဒိတ်လုပ်ပါ)",
    "upload_vaccination": "ကာကွယ်ဆေး တင်ပါ",
    "title": "အခြေခံကျန်းမာရေးမှ ကြိုဆိုပါသည်",
    "sub_title": "Before registering please create an account.",
    "p1": "အကောင့်ဖွင့်ခြင်းက သင့်ကို ခွင့်ပြုသည်-",
    "p2": "နောင်လာမည့် ချိန်းဆိုမှုများကို အစီအစဉ်ဆွဲပါ",
    "p3": "အိမ်တွင်း ကိုဗစ်-၁၉ စမ်းသပ်စစ်ဆေးမှုများကို မှတ်တမ်းတင်ပါ",
    "p4": "မှီခိုကာကွယ်ဆေးထိုးခြင်းနှင့် စမ်းသပ်မှုမှတ်တမ်းများကို စီမံခန့်ခွဲပါ",
    "p5": "ကျွန်ုပ်၏ အခြေခံကျန်းမာရေးမှ လွယ်ကူရိုးရှင်းသော အကောင့်ဝင်ခြင်းလုပ်ငန်းစဉ်",
    "no_vaccination_record": "ကာကွယ်ဆေးထိုးနှံပြီးကြောင်း မထည့်ရသေးပါ",
    "vaccination_title_card": "ကိုဗစ်-၁၉ ကာကွယ်ဆေးထိုးနှံမှု အခြေအနေ",
    "account_and_settings": "အကောင့်နှင့် ဆက်တင်များ",
    "delete_account": "အကောင့်ဖျက်ရန်",
    "language": "ဘာသာစကား",
    "language_current": "လက်ရှိဘာသာစကား- {{language}}",
    "log_out": "‌အကောင့်ထွက်ရန်",
    "delete_account_title": "သင့်အကောင့်အား ဖျက်လိုသည်မှာ သေချာပါသလား။",
    "new_encounter": "{{first_name}}၏ တွေ့ဆုံမှုအသစ်",
    "new_encounter_subtitle": "ဤတွေ့ဆုံမှုအသစ်သည် မည်သည့်အုပ်စုအတွက် ဖြစ်သနည်း။",
    "no_programs": "သင့်အား မည်သည့်အစီအစဉ်တွင်မျှ စာရင်းသွင်းထားပုံ မပေါ်ပါ",
    "edit_members": "အဖွဲ့ဝင်များအား တည်းဖြတ်ရန်",
    "edit_members_subtitle": "သင်သွားရောက်လိုသည့် ဒက်ရှ်ဘုတ်ကို ရွေးချယ်ပါ သို့မဟုတ် သင့်အကောင့်တွင် အခြားအဖွဲ့ဝင်ကို ထည့်ပါ",
    "add_members": "အဖွဲ့ဝင် ထည့်ရန်",
    "delete_member": "{{full_name}}၏ အသုံးပြုသူအကောင့်ကို ဖျက်မလား။",
    "delete_member_subtitle": "အသုံးပြုသူအား ဖယ်ရှားခြင်းက အကောင့်နှင့်ဆက်စပ်နေသော အချက်အလက်အားလုံးကို ဖျက်ပစ်ပါမည်။",
    "select_member": "အဖွဲ့ဝင်ရွေးချယ်ခြင်း",
    "edit_appointment": "ချိန်းဆိုမှုကို တည်းဖြတ်ရန်",
    "route": {
      "me": "ဒက်ရှ်ဘုတ်",
      "history": "မှတ်တမ်း",
      "programs": "စစ်ဆေးမှုတစ်ခုကို ထည့်ရန်",
      "contact_us": "အကူအညီ",
      "choose_member": "အဖွဲ့ဝင်",
      "settings": "ဆက်တင်များ",
      "help": "အကူအညီ"
    },
    "user_dashboard": "{{first_name}}၏ ဒက်ရှ်ဘုတ်",
    "view_full_results": "ရလဒ်ပေါ်တယ်တွင် ကြည့်ပါ",
    "required": "သင်သည် Primary အက်ပ်ကို အသုံးပြုပါက လိုအပ်ပါသည်။",
    "no_email": "ကျွန်ုပ်တွင် အီးမေးလ်လိပ်စာ မရှိပါ။"
  },
  "vaccination_status": {
    "not_vaccinated": "ကာကွယ်ဆေး မထိုးရသေးပါ",
    "fully_vaccinated": "ကာကွယ်ဆေး အပြည့်အဝ ထိုးပြီးပါပြီ",
    "partially_vaccinated": "ကာကွယ်ဆေး တစ်စိတ်တစ်ပိုင်း ထိုးပြီးပါပြီ",
    "vaccination_records": "ကာကွယ်ဆေးထိုး မှတ်တမ်းများ",
    "title": "သင်၏COVID-19 ကာကွယ်ဆေးထိုးထားသည့် အခြေအနေကို ဖော်ပြပါ။",
    "definition_title": "ကာကွယ်ဆေးအခြေအနေ ဖွင့်ဆိုချက်များ",
    "definition_cdc": "CDC ကာကွယ်ဆေးအခြေအနေ ဖွင့်ဆိုချက်",
    "definition_osha": "OSHA ကာကွယ်ဆေးအခြေအနေ ဖွင့်ဆိုချက်",
    "definition": {
      "fully_vaccinated": "Pfizer-BioNTech သို့မဟုတ် Moderna ၏ ထိုးဆေး၂ကြိမ်စီးရီးရှိ နောက်ဆုံးအကြိမ်ထိုးဆေး ထိုးရာတွင် ဖြစ်စေ သို့မဟုတ် Johnson & Johnson's Janssen ကာကယ်ဆေး၏ထိုးဆေးတစ်ကြိမ် ထိုးရာတွင် ဖြစ်စေ နှစ်ပတ်အကြာ ကွာရပါမည်။",
      "partially_vaccinated": "ကာကွယ်ဆေး နှစ်ကြိမ်စီးရီးရှိ နောက်ဆုံးအကြိမ်ထိုးဆေး သို့မဟုတ် Johnson & Johnson's Janssen ကာကွယ်ဆေး တစ်ကြိမ်ကို လက်ခံရှိပြီးနောက် နှစ်ပတ်အကြာတွင် မူလစီးရီးရှိ ထိုးဆေးနှစ်ကြိမ်အနက် တစ်ကြိမ်ကို လက်ခံရရှိခဲ့ပြီး ဖြစ်ပါသည်။",
      "not_vaccinated": "COVID-19 ကာကွယ်ဆေး ပေးထားခြင်း မရှိပါ။",
      "fully_vaccinated_with_one_booster": "ကာကွယ်ဆေးစီးရီးအပြည့် သို့မဟုတ် Johnson & Johnson's Janssen ကာကွယ်ဆေး တစ်ကြိမ် ထိုးပြီးနောက် Pfizer-BioNTech သို့မဟုတ် Moderna ကာကွယ်ဆေးများ၏ ဘူစတာထိုးဆေး တစ်ကြိမ် လက်ခံရရှိခဲ့ပြီးဖြစ်သည်။",
      "fully_vaccinated_with_two_boosters": "ထိုးဆေးစီးရီးအပြည့် သို့မဟုတ် ohnson & Johnson's Janssen ကာကွယ်ဆေး နှင့် ဘူစတာထိုးဆေး ထိုးပြီးနောက် Pfizer-BioNTech သို့မဟုတ် Moderna ကာကွယ်ဆေးများ၏ဒုတိယအကြိမ်ဘူစတာထိုးဆေး လက်ခံရရှိခဲ့ပြီး ဖြစ်ပါသည်။"
    },
    "label": {
      "fully_vaccinated": "ကာကွယ်ဆေးအပြည့်ထိုးထားပြီး",
      "partially_vaccinated": "ကာကွယ်ဆေး တစ်စိတ်တစ်ဒေသ ထိုးထားပြီး",
      "not_vaccinated": "ကာကွယ်ဆေးထိုးထားခြင်းမရှိပါ",
      "fully_vaccinated_with_one_booster": "ကာကွယ်ဆေး + ဘူစတာထိုးဆေး အပြည့်အဝ ထိုးထားပြီး",
      "fully_vaccinated_with_two_boosters": "ကာကွယ်ဆေး + ဘူစတာထိုးဆေး နှစ်ကြိမ် အပြည့်ထိုးထားပြီး",
      "prefer_not_to_answer": "ဖြေဆိုရန် ဆန္ဒမရှိပါ"
    },
    "progress_bar_title": "COVID-19 ကာကွယ်ဆေးထိုးခြင်း ဖြစ်စဉ်",
    "upload_record_card": "သင့် COVID-19 ကာကွယ်ဆေးထိုးခြင်းမှတ်တမ်းကို အက်ပ်လုပ် ပြုလုပ်ရန်",
    "show_example": "နမူနာ ပြပါ",
    "hide_example": "နမူနာ ထိန်ချန်ထားပါ",
    "take_photo": "ဓာတ်ပုံရိုက်ရန် Click ကို နှိပ်ပါ",
    "or": "သို့မဟုတ်",
    "upload_image_or_pdf_instead": "PDF/ ဓာတ်ပုံ အက်ပ်လုပ် ပြုလုပ်ရန်",
    "select_doses_received": "သင်လက်ခံရရှိခဲ့ပြီးသော COVID-19 ကာကွယ်ရေးထိုးဆေးများအားလုံးကို ရွေးချယ်ပါ။",
    "first_dose": "ပထမအကြိမ်ထိုးဆေး",
    "second_dose": "ဒုတိယအကြိမ်ထိုးဆေး",
    "first_booster_dose": "ပထမအကြိမ်ဘူစတာထိုးဆေး",
    "second_booster_dose": "ဒုတိယအကြိမ်ဘူစတာထိုးဆေး",
    "additional_dose": "အပိုထိုးဆေး",
    "first_dose_manufacturer": "သင်၏COVID-19 ပထမအကြိမ် ထိုးဆေးအတွက် ထုတ်လုပ်သူမှာ မည်သူနည်း။",
    "first_dose_date": "သင့်ပထမအကြိမ် ထိုးဆေး၏ရက်စွဲကို ဖော်ပြပါ။",
    "second_dose_manufacturer": "သင်၏COVID-19 ဒုတိယအကြိမ် ထိုးဆေးအတွက် ထုတ်လုပ်သူမှာ မည်သူနည်း။",
    "second_dose_date": "သင့်ဒုတိယအကြိမ် ထိုးဆေး၏ရက်စွဲကို ဖော်ပြပါ။",
    "first_booster_dose_manufacturer": "သင်၏COVID-19 ပထမအကြိမ် ဘူစတာထိုးဆေးအတွက် ထုတ်လုပ်သူမှာ မည်သူနည်း။",
    "first_booster_dose_date": "သင့်ပထမအကြိမ် ထိုးဆေး၏ရက်စွဲကို ဖော်ပြပါ။",
    "second_booster_dose_manufacturer": "သင်၏COVID-19 ဒုတိယအကြိမ် ဘူစတာထိုးဆေးအတွက် ထုတ်လုပ်သူမှာ မည်သူနည်း။",
    "second_booster_dose_date": "သင့်ဒုတိယအကြိမ် ဘူစတာထိုးဆေး၏ရက်စွဲကို ဖော်ပြပါ။",
    "additional_dose_manufacturer": "သင်၏COVID-19 ပထမအကြိမ် ထိုးဆေးအတွက် ထုတ်လုပ်သူမှာ မည်သူနည်း။",
    "additional_dose_date": "သင့်အပိုထိုးဆေး၏ရက်စွဲကို ဖော်ပြပါ။",
    "completed_one": "ပြီးဆုံးပါပြီ",
    "completed_two": "သင့်၏ကာကွယ်ဆေးထိုးနှံခြင်း အခြေအနေကို အက်ပ်ဒိတ်ပြုလုပ်ပေးခြင်းအတွက် ကျေးဇူးတင်ပါသည်။",
    "progress_bar_complete": "ပြီးဆုံးပါပြီ",
    "upload_image": "ဓာတ်ပုံ တင်ပါ",
    "retake_photo": "ဓာတ်ပုံ ပြန်ရိုက်ပါ",
    "other": "အခြား",
    "remove_first_dose": "ပထမအကြိမ်ထိုးဆေးဖြစ်စဉ်ကို ဖယ်ရှားပါ",
    "remove_second_dose": "ဒုတိယအကြိမ်ထိုးဆေးဖြစ်စဉ်ကို ဖယ်ရှားပါ",
    "remove_first_booster_dose": "ပထမအကြိမ် ဘူစတာထိုးဆေးဖြစ်စဉ်ကို ဖယ်ရှားပါ",
    "remove_second_booster_dose": "ဒုတိယအကြိမ် ဘူစတာထိုးဆေးဖြစ်စဉ်ကို ဖယ်ရှားပါ",
    "remove_additional_dose": "နောက်ထပ် အပိုထိုးဆေးဖြစ်စဉ်ကို ဖယ်ရှားပါ",
    "exemption": "သင့်တွင် ကင်းလွတ်ခွင့် ရှိပါသလား။",
    "exempt": "ကျွန်ုပ် ကင်းလွတ်ခွင့် ရပါသည်",
    "not_exempt": "ကျွန်ုပ် ကင်းလွတ်ခွင့် မရပါ",
    "enter_exemption": "ကင်းလွတ်ခွင့်သို့ ဝင်ပါ",
    "upload_exemption_documentation": "သင့်၏ကင်းလွတ်ခွင့် စာရွက်စာတမ်းကို အက်ပ်လုပ် ပြုလုပ်ပါ",
    "remove_dose": "ထိုးဆေးကို ဖယ်ရှားပါ",
    "continue": "ဆက်လက်လုပ်ဆောင်ပါ",
    "enter_credentials": "သင်မှန်ကန်ကြောင်း အထောက်အထားပြုလုပ်ရန် ဝင်ရောက်ပါ",
    "incorrect_credentials": "အထောက်အထား မမှန်ကန်ပါ။ ကျေးဇူးပြု၍ တဖန်ပြန်လည် ကြိုးစားပါ။",
    "add_photo": "ဓာတ်ပုံထည့်ပါ"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "မင်္ဂလာပါ {{name}}၊ {{org_name}} ဖြင့် သင်၏ {{group_name}} ရက်ချိန်းကို ရက်ချိန်းရယူခြင်းမပြီးမြောက်သည်ကို သတိပြုမိပါသည်။ သင်၏ချိန်းဆိုမှုကို ဆက်လက်ထားရှိရန်အတွက် ငွေပေးချေမှုအဆင့်ကို အပြီးသတ်ရန်လိုအပ်ပါသည်။ လုပ်ငန်းစဉ် {{complete_url}} ကိုအပြီးသတ်ရန် ဤလင့်ခ်ကိုနှိပ်ပါ။ သင့်ရက်ချိန်းကို ပယ်ဖျက်လိုပါက ဤလင့်ခ် {{cancel_url}} ကို နှိပ်ပါ။ ကျေးဇူးတင်ပါသည်။"
    }
  },
  "otc": {
    "record_results_for_abbott": "BinaxNOW စမ်းသပ်မှုပြုလုပ်ပြီး သင်၏ရလဒ်များကိုမှတ်တမ်းတင်ပါ",
    "record_results": "စမ်းသပ်မှုပြုလုပ်ပြီး သင်၏ရလဒ်များကိုမှတ်တမ်းတင်ပါ",
    "activate_a_kit": "စမ်းသပ်ကိရိယာအိတ်ကိုဖွင့်ပါ",
    "activate_a_kit_for_test": "{{test}}အတွက်စမ်းသပ်ကိရိယာအိတ်ကိုဖွင့်ပါ",
    "todays_test_process": "ယနေ့စမ်းသပ်မှုလုပ်ငန်းစဉ်",
    "date_of_birth_question": "{{first_name}} ၏ မွေးသက္ကရာဇ်ကိုဖော်ပြပါ",
    "confirm_dob_to_continue": "ဆက်လက်ဆောင်ရွက်ရန် ကျေးဇူးပြု၍ ပါဝင်သူ၏မွေးသက္ကရာဇ်ကို အတည်ပြုပါ",
    "incorrect_dob": "မွေးသက္ကရာဇ်မှန်ကန်မှုမရှိပါ",
    "file_too_large": "အသုံးပြုထားသောဖိုင်၏အရွယ်အစားသည် {{size}} ထက်သေးငယ်ရပါမည်",
    "enter_valid_date_in_last_x_days": "နောက်ဆုံး {{n}} ရက်အတွင်း မှန်ကန်သောရက်စွဲကို ထည့်ပါ။",
    "barcode_format_not_valid": "ဘားကုဒ်ပုံစံမှန်ကန်မှုမရှိပါ",
    "complete_button": "ပြီးမြောက်သည်",
    "next_button": "ရှေ့သို့",
    "back_button": "နောက်သို့",
    "report_a_test": "စမ်းသပ်မှုကိုတင်ပြပါ",
    "x_%_completed": "ပြီးမြောက်သည် {{n}}%",
    "answer_survey": "စစ်တမ်းမေးခွန်းများအားဖြေဆိုပါ",
    "steps": {
      "answer_survey": "စစ်တမ်းဖြေဆိုပါ",
      "review_instructions": "ညွှန်ကြားချက်များကိုပြန်လည့်ကြည့်ရှုပါ",
      "scan_code": "ကုဒ်ကိုစကင်ဖတ်ပါ",
      "enter_time": "အချိန်ကိုထည့်သွင်းပါ",
      "enter_result": "ရလဒ်ကိုထည့်သွင်းပါ",
      "take_photo": "ဓာတ်ပုံရိုက်ပါ",
      "confirm_result": "ရလဒ်အတည်ပြုသည်",
      "selest_test_kit": "စမ်းသပ်မှုအမျိုးအစားရွေးချယ်ပါ",
      "enter_date": "နေ့စွဲကိုထည့်သွင်းပါ",
      "enter_date_and_time": "နေ့စွဲနှင့်အချိန်ကိုထည့်သွင်းပါ"
    },
    "review_instructions": "စမ်းသပ်မှုညွှန်ကြားချက်များကို ပြန်လည်ကြည့်ရှုပါ",
    "follow_instructions_or_below": "စမ်းသပ်မှုပါညွှန်ကြားချက်များကိုလိုက်နာပါ သို့မဟုတ် အောက်ပါညွှန်ကြားချက်များကို ပြန်လည်သုံးသပ်ပါ။",
    "watch_video": "ဗွီဒီယိုကိုကြည့်ရှုပါ",
    "view_instructions": "လမ်းညွှန်ချက်များကိုကြည့်ရှုပါ",
    "follow_instructions": "စမ်းသပ်မှုပါညွှန်ကြားချက်များကိုလိုက်နာပါ",
    "scan_code": "စမ်းသပ်ချက်ကျုအာကုဒ်ကိုစကင်ဖတ်ပါ",
    "qr_code_description": "QR ကုဒ်သည် သင့်စမ်းသပ်မှုအတွက်သာဖြစ်ပြီး သင့်စမ်းသပ်မှု၏ညာဘက်အပေါ်ထောင့်တွင် တွေ့ရှိနိုင်သည်။",
    "show_example": "နမူနာကြည့်ရှုပါ",
    "hide_example": "ဥပမာကိုဖျောက်ပါ",
    "barcode_scanned": "ဘားကုဒ်စကင်န်ဖတ်ခြင်းအောင်မြင်ပါသည်",
    "enter_barcode": "ဘားကုဒ်ထည့်သွင်းပါ",
    "scan_qr_code": "ကျုအာကုဒ်ကိုစကင်ဖတ်ပါ",
    "which_test_did_you_take": "မည်သည့်စမ်းသပ်မှုကိုပြုလုပ်ခဲ့ပါသလဲ",
    "when_did_you_take_test": "စမ်းသပ်မှုကိုမည်သည့်အချိန်တွင်ပြုလုပ်ခဲ့ပါသလဲ",
    "what_day_did_you_take_test": "စမ်းသပ်မှုကိုမည်သည့်နေ့တွင်ပြုလုပ်ခဲ့ပါသလဲ",
    "what_time_did_you_take_test": "စမ်းသပ်မှုကိုမည်သည့်အချိန်တွင်ပြုလုပ်ခဲ့ပါသလဲ",
    "time": {
      "today": "ယနေ့",
      "yesterday": "မနေ့က",
      "two_days_ago": "လွန်ခဲ့သည့်နှစ်ရက်က"
    },
    "enter_current_time": "လက်ရှိအချိန်ကိုထည့်သွင်းပါ",
    "enter_result": "ဆေးစစ်ချက်ရလဒ်ကိုထည့်သွင်းပါ",
    "choose_result_option": "သင့်စမ်းသပ်ကတ်ရလဒ်ကို ဖော်ပြသည့်အဖြေကိုရွေးချယ်ပါ",
    "clarify_results": "သင်၏ရလဒ်များကိုရှင်းလင်းပါ",
    "take_result_photo": "ရလဒ်များကိုဓာတ်ပုံရိုက်ပါ",
    "take_result_photo_description": "သင့်ရလဒ်များကိုမှတ်တမ်းတင်ရန် ပြီးမြောက်ထားသောရလဒ်ကတ်ပြားကို ဓာတ်ပုံရိုက်ပါ",
    "my_result": "ကျွန်ုပ်၏ရလဒ်",
    "switch_camera": "ကင်မရာပြောင်းပါ",
    "confirm_result": "ရလဒ်အတည်ပြုသည်",
    "name": "အမည်",
    "date": "နေ့စွဲ",
    "result": "ရလဒ်",
    "results": "ရလဒ်များ",
    "test_submitted": "ကျေးဇူးတင်ပါတယ်၊ {{first_name}}။ သင်၏ {{test}} ကို အောင်မြင်စွာ တင်သွင်းပြီးပါပြီ။",
    "dob": "မွေးသက္ကရာဇ်",
    "administered": "လုပ်ဆောင်သည်",
    "or": "သို့မဟုတ်",
    "upload": "တင်ပြပါ",
    "change_uploaded_file": "တင်သွင်းသောဖိုင်ကိုပြောင်းပါ",
    "take_photo": "ဓာတ်ပုံရိုက်ပါ",
    "confirm_information": "အချက်အလက်ကိုအတည်ပြုသည်",
    "barcode": "ဘားကုဒ် ",
    "scan_test_barcode": "စမ်းသပ်ချက်ဘားကုဒ်ကိုစကင်ဖတ်ပါ",
    "barcode_description": "ဘားကုဒ်သည် သင့်စမ်းသပ်မှုအတွက်သာဖြစ်ပြီး သင်၏စမ်းသပ်မှုတွင်တွေ့ရှိနိုင်သည်။",
    "enter_date_in_last_30_days": "ပြီးခဲ့သည့်ရက်ပေါင်း ၃၀ အတွင်း စမ်းသပ်မှုပြုလုပ်ခဲ့သည့်နေ့စွဲနှင့် ခန့်မှန်းအချိန်ကိုထည့်သွင်းပါ",
    "add_image": "ပုံထည့်သွင်းပါ",
    "change_image": "ပုံပြောင်းလဲပါ",
    "skip_barcode_reader": "ကုဒ်ကိုစကင်န်ဖတ်ခြင်းမအောင်မြင်ဘူးလား? သို့သော် စမ်းသပ်မှုရလဒ်ကိုသတင်းပို့ရန် Next ကိုနှိပ်ပါ။",
    "enter_date_and_time": "စမ်းသပ်မှပြုလုပ်သည့်နေ့စွဲနှင့်အချိန်ကိုထည့်သွင်းပါ",
    "enter_date": "စမ်းသပ်မှုပြုလုပ်သည့်နေ့စွဲကိုထည့်သွင်းပါ",
    "did_you_take_test_today": "ဒီနေ့ စမ်းသပ်မှု ပြုလုပ်ပြီးပြီလား။",
    "record_results_for_generic": "Test & record your results for the {{name}} test",
    "choose_result_option_custom": "Choose the option that describes your {{test_name}} outcome:"
  },
  "yes": "ထိုးနှံလိုပါသည်",
  "no": "ငြင်းပယ်သည်",
  "event_token": {
    "title": "သင့်စမ်းသပ်ကိရိယာကို ထုတ်ယူရန်အတွက် အောက်ပါကုဒ်ကို အရောင်းစက်တွင် ထည့်သွင်းပါ။",
    "loading_text": "လုပ်ဆောင်နေပါသည်။ မိနစ်အနည်းငယ်အတွင်း ထပ်မံစစ်ဆေးပါ"
  },
  "appointment_recovery": {
    "complete_title": "အားလုံးပြင်ဆင်ပြီးပါပြီ။",
    "subtitle_1": "သင် iPhone ကို အသုံးပြုနေပါက သင့်ဒက်ရှ်ဘုတ်သို့ ပြန်သွားရန် ဤစခရင်၏ ဘယ်ဘက်ထောင့်ထိပ်ရှိ **ပြုလုပ်ပြီးပါပြီ** ကို နှိပ်ပါ။",
    "subtitle_2": "သင်က Android ကို အသုံးပြုနေပါက ဤစခရင်၏ ဘယ်ဘက်ထောင့်ထိပ်ရှိ **X** ကို နှိပ်ပါ။",
    "subtitle_0": "ဆက်လုပ်ရန် ဘရောက်ဇာမှ ထွက်ပါ။"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "ဆက်နွယ်မှု ခွင့်ပြုချက်",
  "finish_registration": {
    "title": "သင်၏ မှတ်ပုံတင်ခြင်းကို ပြည့်စုံအောင် လုပ်ပါ",
    "subtitle": "သင်၏ချိန်းဆိုရက်မတိုင်မီ အောက်ပါအချက်အလက်များကို ကျေးဇူးပြု၍ ဖြည့်စွက်ပါ။",
    "button": "Finish registration"
  },
  "arab_ethnicity": {
    "arab": "အာရပ်လူမျိုး",
    "non_arab": "အာရပ်လူမျိုး မဟုတ်ပါ",
    "prefer_not_to_answer": "ဖြေဆိုရန် ဆန္ဒမရှိပါ",
    "question": "အာရပ်လူမျိုးစု",
    "subtitle": "စည်းမျဉ်းများအတွက်‌ အောက်ဖော်ပြပါအချက်အလက်များကို ကျွန်ုပ်တို့စုဆောင်းရန်",
    "help": "လူမျိုးစုဆိုသည်မှာ ဘာသာစကား၊ ထုံးတမ်းအစဉ်အလာ၊ ကိုးကွယ်သည့်ဘာသာ နှင့် အခြားထင်ရှားသော စရိုက်လက္ခဏာများ တူညီသည့် သီးခြားအစုအဖွဲ့တခုကို ရည်ညွှန်းသည်။"
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Depending on your device, click the link below to download the Primary Health Pass."
    }
  },
  "stepper": {
    "next": "ရှေ့သို့",
    "back": "နောက်သို့",
    "complete": "ပြီးဆုံးပါပြီ"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "သင့်စက်ရှိ ကင်မရာအက်ပ်ကို ဖွင့်ပါ",
    "point_to_qr_code": "QR ကုဒ်ကို ချိန်ပြီး လင့်ခ်ကို နှိပ်ပါ",
    "complete_registration": "စာရင်းသွင်းခြင်း ပြီးဆုံးအောင်လုပ်ပါ",
    "need_help": "အကူအညီ လိုအပ်ပါသလား။ ကျွန်ုပ်တို့ထံ ဆက်သွယ်ပါ"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Vaccine information attestation",
    "select_checkbox": "Please review the following and select the appropriate checkbox below before proceeding.",
    "opportunity_to_read": "I have or have been given the opportunity to read, or had read to me, the Vaccine Information Statement(s) (“VIS”) or Emergency Use Authorization (“EUA”) fact sheet provided for the vaccine(s) to be administered:",
    "vaccine_info_sheet": "vaccine information sheet",
    "vaccine_fact_sheet": "vaccine fact sheet",
    "vaccine_info_statement": "vaccine information statement"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "this kit includes tests for chlamydia and gonorrhea.",
    "B": "this kit includes tests for chlamydia and gonorrhea.",
    "C": "this kit includes tests for syphilis.",
    "D": "this kit includes tests for HIV, syphilis, chlamydia and gonorrhea.",
    "E": "this kit includes tests for HIV and syphilis.",
    "box_type": "Box {{type}}",
    "administered_text": "We will contact you when your kit reaches the lab and when your results are ready.",
    "activate_kit": "Activate my test kit",
    "register_another_test": "Register another test",
    "credentials": {
      "title": "Let's get started",
      "label": "Contact",
      "p1": "We'll start with some info about you.",
      "location_step": {
        "p1": "Who provided your test kit?",
        "load": "Load more"
      }
    },
    "checklist": {
      "not_urinated": "You have not urinated in the last hour.",
      "bathroom": "You have access to a bathroom for urine collection.",
      "anal_swab": "You have access to a private place to perform an anal swab.",
      "blood_extraction": "You have access to a clean environment for blood extraction.",
      "washed_hands": "You have washed your hands with soap and warm water for 30 seconds.",
      "title": "Are you ready to begin sample collection?",
      "no_alcohol": "Do not drink alcohol on the day of collection.",
      "menstruation": "Do not collect vaginal swab specimen during menstruation or within 24 hours of intercourse."
    },
    "confirm": {
      "title": "Confirm your information",
      "p1": "Is this information correct?"
    },
    "display_name": {
      "A": "Chlamydia & Gonorrhea (Single Site)",
      "B": "Chlamydia & Gonorrhea (3 Site)",
      "C": "Syphilis",
      "shortened": {
        "A": "Chlamydia & Gonorrhea",
        "B": "Chlamydia & Gonorrhea",
        "C": "Syphilis"
      },
      "CC": "Colorectal Cancer Screening (FIT)",
      "CE": "Celiac Disease Screening",
      "CR": "Creatinine Test",
      "D": "4 Panel Test - HIV, Syphilis, Chlamydia & Gonorrhea (Single Site)",
      "DD": "Diabetes Screening (HbA1c)",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone Level",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Rescan",
    "scanned": "You've scanned Box {{type}}",
    "not_yet": "Not Yet",
    "success_registration": "Registration success!",
    "no_box": "Don't have a box?",
    "faq_link": "Visit FAQs",
    "welcome": "Welcome!",
    "scan": {
      "title": "You're one step closer to a healthier you.",
      "p1": "Scan or enter the **kit bar code** on the side of your box."
    },
    "appointments": {
      "title": "Select the patient & provider",
      "p1": "We found more than one profile associated with your contact information. Select the correct profile:",
      "new": "New patient or provider"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "We found your patient record."
    },
    "section_1": "Confirm kit contents",
    "section_2": "Test collection",
    "section_3": "Packaging",
    "section_4": "Shipping",
    "box_a": "Box A",
    "box_b": "Box B",
    "box_c": "Box C",
    "kit_flow": {
      "button_1": "Continue to packaging",
      "button_2": "I'm ready",
      "button_3": "My kit is complete and packed",
      "button_4": "I'm finished",
      "button_5": "Next test",
      "button_6": "Skip instructions"
    },
    "contents": {
      "header": "Great! Let's make sure your kit is complete",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Blood collection card",
      "blood_collection_card_subtitle": "(1 or 2 depending on test kit)",
      "alchohol_pads": "2 alcohol pads",
      "lancets": "Single use lancets",
      "up_to_other": "(Up to 4 depending on test kit)",
      "adhesive_bandages": "Adhesive bandages",
      "pipette": "Pipette",
      "urine_tube": "Urine collection tube",
      "urine_cup": "Urine collection cup",
      "oral_swab": "Oral swab",
      "oral_tube": "Oral collection tube",
      "anal_swab": "Anal swab",
      "anal_tube": "Anal collection tube",
      "vaginal_tube": "1 vaginal collection tube",
      "vaginal_swab": "1 vaginal swab",
      "biodegradable_paper": "Biodegradable collection paper",
      "bottle_and_probe": "Sampling bottle and probe",
      "prepaid_envelope": "Pre-paid shipping envelope",
      "biohazard_subtitle": "(with absorbent pad)",
      "biohazard_bag": "1 specimen bag",
      "biohazard_bag_plural": "({{count}}) Biohazard bags",
      "alcohol_pads_plural": "{{count}} alcohol pads",
      "sterile_gauze_pad": "1 sterile gauze pad"
    },
    "packaging": {
      "title": "Packaging checklist",
      "subtitle": "Before you ship your kit, make sure that:"
    },
    "packaging_box_a": {
      "step_1": "You have packed all test kit contents",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "The specimen collection tube is sealed in its biohazard bag",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_b": {
      "step_1": "You have packed all test kit contents, even if you skipped the test(s)",
      "step_2": "You have used all collection materials contained in the kit to collect the required specimens",
      "step_3": "Your **date-of-birth** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_4": "The **collection date** is written on all specimen collection tubes using MM/DD/YYYY format",
      "step_5": "Each specimen collection tube is sealed in its own biohazard bag (only **one** specimen per bag)",
      "step_6": "All specimens are placed into the box",
      "step_7": "The box is placed into the pre-paid shipping envelope",
      "step_8": "The return shipping envelope is completely sealed"
    },
    "packaging_box_c": {
      "step_1": "You have packed all test kit contents, including used lancets.",
      "step_2": "Your **date-of-birth** is written on the blood collection card using MM/DD/YYYY format",
      "step_3": "The **collection date** is written on the blood collection card using MM/DD/YYYY format",
      "step_4": "The blood collection card and all used lancets are placed in the biohazard bag",
      "step_5": "The biohazard bag is placed into the box",
      "step_6": "The box is placed into the pre-paid shipping envelope",
      "step_7": "The return shipping envelope is completely sealed"
    },
    "success": {
      "header_1": "Great!",
      "text_1": "You're ready to collect your first sample.",
      "header_2": "You did it!",
      "text_2": "**Next up:** package your kit to send back.",
      "header_3": "Great job!",
      "text_3": "You’re one step closer to better health.",
      "header_4": "Nice job!",
      "text_4": "Just one more and you’re done!",
      "text_5": "You're ready to collect your first sample"
    },
    "instructions": {
      "title": "Select a test to view instructions:",
      "subtitle": "Test",
      "button": "Instructions",
      "pdf": "PDF instructions"
    },
    "instructions_box_a": {
      "title": "Urine collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
      "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
      "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
      "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
      "step_4_sublabel": "Do not fill tube past the maximum fill line or specimen will be rejected.",
      "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
      "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Urine collection",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Do not urinate for at least 1 hour prior to collection. Using first morning urine is best.",
        "step_2": "Urinate directly into the PeeCanter or collection cup, filling between 1/3 and 1/2.",
        "step_3": "Pour from the PeeCanter or use the pipette to transfer urine from the collection cup into the collection tube(s) marked with an orange <span style=\"color: #FF5000\">**\"URINE\"**</span> label.",
        "step_4": "Fill the collection tube(s) until the combined liquid is between the designated fill lines.",
        "step_4_subtitle": "Do not fill tube past the maximum fill line or specimen will be rejected.",
        "step_5": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_6": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_7": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_2": {
        "title": "Oral swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab into your mouth and rub the swab tip against the back of your throat for 10 seconds.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a blue <span style=\"color: #001F70\">**\"ORAL\"**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      },
      "test_3": {
        "title": "Anal swab",
        "subtitle": "For this test you will need the following materials:",
        "step_1": "Hold the swab between the faint score line (if present) and the cotton-topped portion of the swab.",
        "step_2": "Insert the swab 3 - 5 cm (1 - 2”) into the anal canal. Gently turn the swab for 5 - 10 seconds to collect any potential organisms.",
        "step_3": "Carefully withdraw the swab and place into the collection tube marked with a green <span style=\"color: #00C08C\">**“ANAL”**</span> label. Break the swab at the score line by bending against the collection tube.",
        "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
        "step_5": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area.",
        "step_6": "Place specimen into an empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
      }
    },
    "instructions_box_c": {
      "title": "Blood collection",
      "subtitle": "For this test you will need the following materials:",
      "step_1": "Write your name, date of birth, and the date of collection in the designated fields. Use MM/DD/YYYY format.",
      "step_2": "Open blood card flap to expose the circles on the blood collection paper. Do not touch the blood collection paper.",
      "step_3": "Wash hands with warm water for at least 30 seconds, then shake hands vigorously for 15 seconds to encourage blood flow to your fingers.",
      "step_4": "Clean fingertip with alcohol pad. It is best to use the middle or ring finger of your non-dominant hand.",
      "step_5": "Take the lancet and twist off the cap. Press the small tip firmly into the tip of your finger, until the needle ejects with a click. Keeping your hand below your heart during collection, massage finger from base to tip to encourage blood flow.",
      "step_5_subtitle": "Lancets are single use. All lancets need to be returned with your sample to the laboratory for disposal.",
      "step_6": "Starting at the center, apply 3-6 drops to fill the circle and soak through the collection paper. Do not touch the paper with your finger as this will restrict blood flow. Once circle is full, move on to next circle. It is okay for blood to extend beyond lines, but do not let blood spots spread into each other.",
      "step_6_subtitle": "Do not add additional blood to a circle once completed or dry. The “layering” of blood will invalidate the collection.",
      "step_7": "Without closing the blood card, lay it on a flat surface and allow the blood collection paper to air dry at room temperature, for at least 30 minutes. Do not heat, blow dry, or expose the blood collection paper to direct sunlight. Heat will damage the specimen.",
      "step_7_subtitle": "Check the back side of blood collection paper. Blood should saturate all the way through and fill each circle of the collection paper.",
      "step_8": "When blood collection paper is dry, close blood card by tucking flap. Place the blood card and lancets into biohazard bag with the desiccant pack. Ensure biohazard bag is properly sealed."
    },
    "test_tips": {
      "title": "Tips for Proper Blood Collection",
      "subtitle": "For best results:",
      "step_1": "Be sure you are not dehydrated while performing collection. Hydration promotes blood flow.",
      "step_2": "Do not perform collection immediately after smoking.",
      "step_3": "Washing and warming your hands under warm water will help promote blood flow in your hands.",
      "step_4": "Shake hands vigorously towards the floor to encourage blood flow to your fingers.",
      "step_5": "Keep collection device and hands below your heart during collection for best blood flow.",
      "step_6": "You may need more than one finger prick. Repeat these tips between each finger prick."
    },
    "shipping": {
      "header": "Your kit is ready to ship!",
      "text": "**Congrats!** You finished your sexual health screening."
    },
    "pick_up": "Pick up your recommended test kit from the site staff and register below",
    "short_display_name": {
      "A": "Chlamydia & Gonorrhea",
      "B": "Chlamydia & Gonorrhea",
      "C": "Syphilis",
      "CC": "Colorectal Cancer",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, Syphilis, Chlamydia & Gonorrhea",
      "DD": "Diabetes",
      "E": "HIV & Syphilis",
      "H": "HPV",
      "P": "Hepatitis C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Viral Surveillance"
    },
    "test_kit": "Test Kit",
    "view_results": "View results",
    "recommended_kit_title": "Recommended Test Kits",
    "instructions_box_h": {
      "title": "Vaginal Swab Collection",
      "subtitle": "Collection tubes may contain a liquid preservative. <span class=\"text-danger\">**Do not empty liquid preservative from the collection tubes if present.**</span>",
      "step_1": "Hold the swab between the faint score line (if present) and the cotton-tipped portion of the swab in one hand and separate the labia (folds of skin around the vaginal opening).",
      "step_2": "Insert the swab 5cm (2in) into the vaginal opening. Gently turn the swab for 30 seconds while rubbing the swab against the walls of the vagina.",
      "step_3": "Carefully withdraw the swab and place into the collection tube marked with a <span class=\"text-danger\">**RED “VAGINAL” LABEL**</div>. Break the swab by bending against the collection tube.",
      "step_4": "Place lid onto the collection tube and ensure it is closed evenly and tightly.",
      "step_5": "Write your date of birth in MM/DD/YYYY format onto the tube in the designated area (DOB).",
      "step_6": "Write the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area (Coll. Date).",
      "step_7": "Place specimen into the empty biohazard bag with the absorbent pad and ensure the seal is closed completely."
    },
    "packaging_box_h": {
      "step_3": "Your **date-of-birth** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_4": "Your **collection date** is written on the specimen collection tube using MM/DD/YYYY format",
      "step_6": "The biohazard bag is placed into the box"
    },
    "instructions_box_cc": {
      "title": "Stool collection",
      "step_1": "Write your date of birth and the specimen collection date (today’s date) in MM/DD/YYYY format onto the tube in the designated area. Open green cap by twisting and lifting.",
      "step_2": "Place supplied collection paper into toilet bowl on top of water.",
      "step_3": "Deposit stool sample on top of collection paper.",
      "step_4": "Collect sample from stool before paper sinks and stool sample touches water.",
      "step_5": "Scrape the surface of the fecal sample with the sample probe.",
      "step_6": "Cover the grooved portion of the sample probe completely with stool sample.",
      "step_7": "Close sampling bottle by inserting sample probe and snapping green cap on tightly. Do not reopen.",
      "step_8": "Flush. Collection paper is biodegradable and will not harm septic systems.",
      "step_9": "Wrap sampling bottle in absorbent pad and insert in to  the biohazard bag."
    },
    "contact_support": {
      "title": "Let's fix this",
      "text_1": "We are sorry to hear that something is wrong!",
      "text_2": "Please contact us to let us know what’s wrong and we will help to replace your kit."
    },
    "contact_support_success": {
      "title": "We received your message",
      "text_1": "Thanks for contacting us.",
      "text_2": "A member of our support team will be in touch soon.",
      "button_text": "Return home"
    },
    "kit_in_transit_to_patient": "Your order is on its way! Most orders are delivered within 2-5 business days. Once you receive your kit, we will send you more information on how to activate it and send it back.\n\nTracking Link: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "The lab received your kit! You'll receive a notification to check your patient portal when results are ready",
    "registration_confirmation": "your registration is now complete! Visit your registration confirmation page to activate your test kit:",
    "kit_ordered_online": "We've received your kit order and we will send an update once it ships! \n\nOrder #: {{order_number}} \nOrder date: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "We received your order!",
      "p1": "We've received your order! Once your order ships, we'll send you another update.",
      "order_number": "Order #",
      "order_date": "Order date"
    },
    "completed": "Completed",
    "I": "this kit includes tests for HIV.",
    "CC": "this kit includes tests for colorectal cancer.",
    "CE": "this kit includes tests for celiac disease.",
    "CR": "this kit includes tests for creatinine.",
    "DD": "this kit includes tests for diabetes.",
    "H": "this kit includes tests for HPV.",
    "P": "this kit includes tests for hepatitis C.",
    "HH": "this kit includes tests for hemoglobin.",
    "HS": "this kit includes tests for HSV-2.",
    "TT": "this kit includes tests for testosterone.",
    "VD": "this kit includes tests for vitamin D."
  },
  "copy_link": "Copy page link",
  "copied_link": "Copied link!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Protection against the common flu viruses",
      "vaccine_covid": "Protection against COVID-19 viruses",
      "tdap": "Tdap vaccine can prevent tetanus",
      "polio": "Protection against the polio virus. Required for children before starting school.",
      "varicella": "Vaccine that protects against chickenpox",
      "shingles": "Vaccine that protects against shingles. Minimum age is 18.",
      "human_papillomavirus": "Vaccine that protects against HPV. Recommended for 11 year old children.",
      "meningococcal": "Vaccine protects against four types of meningococcal bacteria."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Find a clinic",
    "select_pin": "Select a pin to see details",
    "miles_shorten": "{{miles}} mi",
    "next_available": "Next available",
    "appointment_type": "Appointment type",
    "individual_appointment": "Individual appointment",
    "family_appointment": "Family appointment",
    "vaccines": "Vaccines",
    "what_kind_appointment": "What kind of appointment do you want to book?",
    "select_family_or_individual": "Select family appointment if you are scheduling for multiple family members at once.",
    "vaccines_selected": "Vaccines selected",
    "where_care": "Where do you want to receive care?",
    "select_vaccines_for_individual": "Select vaccines for your appointment (optional).",
    "select_vaccines_for_family": "Select vaccines for your family appointment (optional).",
    "schedule_services": "Schedule services",
    "add_family": "Add family members and select services below.",
    "family_member": "Family member {{number}}",
    "morning": "Morning",
    "afternoon": "Afternoon",
    "slot_available": "{{number}} available",
    "within_x_miles": "Within {{miles}} miles of",
    "any_distance": "Any distance from",
    "partial_results": "Partial results",
    "partial_matches": "The following clinics offer some but not all of the vaccines you're looking for",
    "no_matches": "That search didn’t find any matches. Try changing your filters for better results.",
    "no_clinics_found": "There aren't any clinics that match your search.",
    "broaden_filters": "Try broadening your filters for better results.",
    "unavailable_vaccines": "Unavailable vaccines:",
    "available_vaccines": "Available vaccines:",
    "select_date": "Select a date",
    "available_appointments": "AVAILABLE APPOINTMENTS",
    "appointment_scheduling_info": "You’ll be able to assign appointment times to specific family members in the next step. You can schedule appointments on different days if you choose.",
    "hold_selected_appointments": "We will hold your selected appointments for 15 minutes.",
    "appointments_selected": "APPOINTMENTS SELECTED",
    "no_appointments_selected": "No appointment selected",
    "vaccines_needed": "Vaccines needed",
    "select_x_appointments": "Select {{number}} appointments.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Let's verify your email!",
    "hi_full_name": "Hi {{full_name}},",
    "verify_email_button": "Verify email",
    "please_verify_email": "Please use the button below to verify your email."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Hepatitis A",
    "hepatitis_b": "Hepatitis B",
    "hepatitis_a_b": "Hepatitis A & B",
    "hib": "Hib",
    "mmr": "Measles, Mumps, Rubella",
    "meningococcal": "Meningococcal",
    "mpox": "Mpox",
    "pneumococcal": "Pneumococcal",
    "polio": "Polio",
    "rsv": "Respiratory syncytial virus",
    "rotovirus": "Rotavirus",
    "zoster": "Zoster (Shingles)",
    "tetanus_diptheria": "Tetanus & Diphtheria",
    "tdap": "Tdap",
    "typhoid": "Typhoid",
    "varicella": "Varicella (Chickenpox)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (Adult)",
    "covid-19_adult_description": "For people 12 years or older.",
    "covid-19_children": "COVID-19 (Child)",
    "covid-19_children_description": "For children aged 4 - 11 years old.",
    "covid-19_infants": "COVID-19 (Infant)",
    "covid-19_infants_description": "For children 6 months - 3 years old.",
    "influenza": "Influenza",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}