import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';

import { buildLocalizedString } from '../../common/locales/langs';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { faSquareMinus, faSquarePlus } from '@fortawesome/pro-regular-svg-icons';

const ResultRow = ({ test, renderThirdRow, negativeHighlight }) => {
  const { t, i18n } = useTranslation();
  const [showTest, toggleTest] = useState(test.test_on_appointment);

  const customResultText = buildLocalizedString(
    test.test_configuration,
    `${test.result || 'processing'}_result_string`,
  )
    ? buildLocalizedString(
        test.test_configuration,
        `${test.result || 'processing'}_result_string`,
        i18n.language,
      )
    : test.result;

  const resultText = test.result_complete ? customResultText : 'Processing';

  return (
    <Row
      className={`mx-1 border-end border-start ${
        !renderThirdRow && 'border-bottom'
      }`}
    >
      <Col sm={6} md={4} className="mb-0 text-secondary fw-bold py-3 my-1 ps-2">
        {t('result_page.result_label')}
      </Col>
      <Col
        sm={6}
        md={8}
        className="fw-bold py-3 my-1 pe-2"
        style={negativeHighlight}
      >
        {resultText}
        {buildLocalizedString(
          test.test_configuration,
          `${test.result || 'processing'}_result_text`,
        ) && (
          <Button
            variant="link"
            size="sm"
            className="pb-1 pe-0 ps-1 pt-0 d-print-none"
            onClick={() => toggleTest(!showTest)}
          >
            <FontAwesomeIcon
              icon={showTest ? faSquareMinus : faSquarePlus}
            />
          </Button>
        )}
        <br />
        {test.lab_report_attached && (
          <a href={test.lab_report_url}>
            <FontAwesomeIcon icon={faPrint} /> Lab Report
          </a>
        )}
      </Col>
      {showTest && (
        <Col className="mx-1 my-3">
          <LocalizedMarkdown
            container={test.test_configuration}
            stringKey={`${test.result || 'processing'}_result_text`}
          />
        </Col>
      )}
    </Row>
  );
};

export default ResultRow;
