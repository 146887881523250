import React from 'react';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const { t } = useTranslation();

  if(props.returnToPath){
    window.location.href = props.returnToPath;
  }
  return (
    <div className="text-center">
      <FontAwesomeIcon icon={faCircleCheck} className="text-success h1"/>
      <h3>{t('vaccination_status.completed_one')}</h3>
      <p>{props.subtext}</p>
    </div>
  );
};
