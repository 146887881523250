import React, { useContext, useEffect } from "react";
import axios from "axios";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FabrxCheckbox from "../../Registration/primary/FabrxCheckbox";
import PaymentFields from "./PaymentFields";
import Switch from "../../common/components/Switch";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

export const fetchVaccineInfoSheetVersions = async (dispatch, testConfiguration) => {
  if (!testConfiguration) return;
  // all vaccine_info_sheet_versions are fetched for the existing test_group_test_configurations, but it would be too expensive to fetch
  // them for the available test_configurations, so we fetch them on demand when adding a selecting a test_group_test_configuration
  dispatch({ type: "setLoading", values: true });
  if (testConfiguration.service_specification_type == "immunization") {
    const response = await axios.get(`/test_configurations/${testConfiguration.id}/latest_vaccine_info_sheet_versions`);
    dispatch({ type: "setVaccineInfoSheetVersions", values: response.data.vaccine_info_sheet_versions });
  } else {
    dispatch({ type: "setVaccineInfoSheetVersions", values: [] });
  }
  dispatch({ type: "setLoading", values: false });
};

export const VaccineInfoSheetVersions = ({ vaccineInfoSheetVersions }) => {
  if (!vaccineInfoSheetVersions?.length) return <span />;
  return (
    <div style={{ marginLeft: "3rem" }}>
      {vaccineInfoSheetVersions?.map((visv) => {
        const fileName = `${visv.name}_${visv.language}_${visv.version_date}.pdf`
        return (
          <div>
            <a href={visv.vaccine_info_sheet_version_file_url} download>
              <FontAwesomeIcon
                className="me-2"
                icon="fa-regular fa-file"
                style={{ height: "16px" }}
              />
              {fileName}
            </a>
          </div>
        )
      })}
    </div>
  );
};

const VaccineTestGroupTestConfigurationInputFields = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  useEffect(() => {
    fetchVaccineInfoSheetVersions(dispatch, state.selectedTestConfiguration);
  }, [state.selectedTestConfiguration]);

  return (
    <>
      {!!state.vaccineInfoSheetVersions?.length && (
        <Row>
          <Col xs={12} lg={8}>
            <h6>Vaccine information statement (VIS):</h6>
            <Switch
              checked={!!state.testGroupTestConfiguration.show_vis_on_registration}
              containerClassName="my-3"
              id="show_vis_on_registration"
              label="Include electronic VIS in registration"
              onChange={() => {
                dispatch({
                  type: "setTestGroupTestConfiguration",
                  values: {
                    ...state.testGroupTestConfiguration,
                    show_vis_on_registration: !state.testGroupTestConfiguration.show_vis_on_registration
                  }
                });
              }}
              subLabel="The latest VIS for this service will be provided to participants during registration.
                When turned off, staff will be required to report the VIS version date at checkout."
            />
            <VaccineInfoSheetVersions vaccineInfoSheetVersions={state.vaccineInfoSheetVersions} />
          </Col>
        </Row>
      )}

      <div className="py-2"><hr/></div>
      <h5 className="my-4">Availability</h5>
      <div className="my-4">
        <Switch
          checked={!!state.testGroupTestConfiguration.auto_generate_for_appointments}
          containerClassName="my-3"
          id="auto_generate_for_appointments"
          label="Auto-generate for appointments"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                auto_generate_for_appointments: !state.testGroupTestConfiguration.auto_generate_for_appointments
              }
            });
          }}
          subLabel="Automatically add this service to every appointment."
        />
        <Switch
          checked={!!state.testGroupTestConfiguration.hide_service}
          containerClassName="my-3"
          id="hide_service"
          label="Hide from participants"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                hide_service: !state.testGroupTestConfiguration.hide_service
              }
            });
          }}
          subLabel="Exclude this service from the service directory and participant registration."
        />
        <Switch
          checked={!!state.testGroupTestConfiguration.shown_on_checkout}
          containerClassName="my-3"
          id="shown_on_checkout"
          label="Available at checkout"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                shown_on_checkout: !state.testGroupTestConfiguration.shown_on_checkout
              }
            });
          }}
          subLabel="Allow clinicians to add this service at checkout."
        />
      </div>

      <div className="py-2"><hr/></div>
      <h5 className="my-4">Funding</h5>
      <div className="my-4">
        <Switch
          checked={!!state.testGroupTestConfiguration.vfc_eligible}
          containerClassName="my-3"
          id="vfc_eligible"
          label="Vaccines for Children (VFC)"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                vfc_eligible: !state.testGroupTestConfiguration.vfc_eligible
              }
            });
          }}
          subLabel="Turn on to allow VFC for eligible participants under 18 years old."
        />
        {!!state.testGroupTestConfiguration.vfc_eligible && (
          <div style={{ marginLeft: "3rem" }}>
            <div>
              <FabrxCheckbox
                checked={!!state.testGroupTestConfiguration.request_vfc_on_registration}
                radio
                onChange={() => {
                  dispatch({
                    type: "setTestGroupTestConfiguration",
                    values: {
                      ...state.testGroupTestConfiguration,
                      request_vfc_on_registration: true,
                    }
                  });
                }}
                label={"Include eligibility questionnaire in patient registration"}
              />
              <FabrxCheckbox
                checked={!state.testGroupTestConfiguration.request_vfc_on_registration}
                radio
                onChange={() => {
                  dispatch({
                    type: "setTestGroupTestConfiguration",
                    values: {
                      ...state.testGroupTestConfiguration,
                      request_vfc_on_registration: false,
                    }
                  });
                }}
                label={"Eligibility questionnaire administered by clinician at checkout only"}
              />
            </div>
          </div>
        )}
        <Switch
          checked={!!state.testGroupTestConfiguration.request_funding_for_vfc}
          containerClassName="my-3"
          id="request_funding_for_vfc"
          label="Request funding for Vaccines for Children"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                request_funding_for_vfc: !state.testGroupTestConfiguration.request_funding_for_vfc
              }
            });
          }}
          subLabel="Turn on to request VFC funding on checkout for eligible participants under 18 years old."
        />

        <Switch
          checked={!!state.testGroupTestConfiguration.vfa_eligible}
          containerClassName="my-3"
          id="vfa_eligible"
          label="Vaccines for Adults"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                vfa_eligible: !state.testGroupTestConfiguration.vfa_eligible
              }
            });
          }}
          subLabel="Turn on to allow vaccines for adults for eligible participants over 18 years old."
        />
        <Switch
          checked={!!state.testGroupTestConfiguration.request_funding_for_vfa}
          containerClassName="my-3"
          id="request_funding_for_vfa"
          label="Request funding for adult financial aid program"
          onChange={() => {
            dispatch({
              type: "setTestGroupTestConfiguration",
              values: {
                ...state.testGroupTestConfiguration,
                request_funding_for_vfa: !state.testGroupTestConfiguration.request_funding_for_vfa
              }
            });
          }}
          subLabel="Turn on to request funding on checkout for eligible participants over 18 years old."
        />
      </div>
      <PaymentFields />
    </>
  );
};

export default VaccineTestGroupTestConfigurationInputFields;