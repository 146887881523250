import React from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { buildLocalizedString } from '../../../common/locales/langs';
import LocalizedMarkdown from '../../../Registration/components/registration/fields/LocalizedMarkdown';

const FollowUpExplaination = ({followUpTestConfiguration}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="form-section">
      <LocalizedMarkdown container={followUpTestConfiguration} stringKey='follow_up_explaination_text' />
    </div>
  );
};

export default FollowUpExplaination;