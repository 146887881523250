import React, { useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { buildLocalizedString } from '../../common/locales/langs';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { faSquareMinus, faSquarePlus } from '@fortawesome/pro-regular-svg-icons';

const MultiplexSubTestRow = ({
  testName,
  testConfiguration,
  resultLabel,
  resultTextKey,
  negativeHighlight,
  type,
  groupedResults = false,
  notes = null,
}) => {
  const [showResultText, toggleResultText] = useState(false);
  const hasResultText = buildLocalizedString(testConfiguration, resultTextKey);
  const rowClass = groupedResults ? 'border-top' : 'border-bottom border-start border-end';

  return (
    <Row key={type} className={`mx-1 ${rowClass}`}>
      <Col sm={6} md={4} className="text-secondary fw-bold py-3 ps-2 mb-0 my-1">
        {testName}
      </Col>
      <Col sm={6} md={8} className="py-3 pe-2 my-1" style={negativeHighlight}>
        <div className="fw-bold">
          {resultLabel}
          {hasResultText && (
            <Button
              variant="link"
              size="sm"
              className="pb-1 pe-0 ps-1 pt-0 d-print-none"
              onClick={() => toggleResultText(!showResultText)}
              data-testid="descriptionExpandButton"
            >
              <FontAwesomeIcon
                icon={
                  showResultText
                    ? faSquareMinus
                    : faSquarePlus
                }
              />
            </Button>
          )}
        </div>
        {notes && <div>{notes}</div>}
      </Col>
      {showResultText && hasResultText && (
        <Col className="mx-1 my-3">
          <LocalizedMarkdown
            container={testConfiguration}
            stringKey={resultTextKey}
          />
        </Col>
      )}
    </Row>
  );
};

export default MultiplexSubTestRow;
