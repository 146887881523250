import React, { useEffect, useState } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import TestStripsDisplay, { NeedHelp, StatusBadge } from './TestStripsDisplay';
import { TestsDisplay } from './TestsDisplay';
import { useMediaQuery } from 'react-responsive';

import FixInsuranceModal from './FixInsuranceModal';
import axios from 'axios';
import VaccinesDisplay from './VaccinesDisplay';
import WellnessDisplay, { TestHeader } from './WellnessDisplay';

const PageHeader = ({ title, message, displayTime, barcode }) => {
  const { t, i18n } = useTranslation();

  return (
    <div style={{ padding: '24px 0' }}>
      <h4 className="mb-0">{title}</h4>
      <div>{message}</div>
      {(barcode || displayTime) && (
        <div style={{ color: '#717D96', padding: '16px 0 0 0' }}>
          {barcode && (
            <>
              <span>
                <b>{t('otc.barcode')}</b> {barcode.toUpperCase()}
              </span>
              <br />
            </>
          )}
          {displayTime}
        </div>
      )}
    </div>
  );
};

const MedicalHistory = ({ data }) => {
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const {
    appointment: _appointment,
    user,
    test_group_user,
    test_group,
    flipper_flags,
    vaccine_service_specifications,
  } = data;
  const [showInsuranceModal, setShowInsuranceModal] = useState(
    user.admin_requests_change_on_insurance,
  );

  const [appointment, setAppointment] = useState(_appointment);

  const params = {
    last_name: user.last_name,
    date_of_birth: user.date_of_birth,
    access_code: test_group_user.access_code || '',
    locale: i18n.language,
    key: appointment.registration_access_key || '',
    registration_type: appointment.population_name || '',
  };
  Object.keys(params).forEach(
    (key) => (params[key] === null || params[key] === '') && delete params[key],
  );

  const accessCodeRegistrationUrl = `/a/${test_group.slug}/new?${jQuery.param(
    params,
  )}`;

  const isVaccine = (specification) => {
    return vaccine_service_specifications.includes(specification);
  };

  const wellnessTests = appointment.tests.filter((t) => ["vitals_screening", "hypertension_screening"].includes(t.test_configuration.service_specification));
  const vaccines = appointment.tests.filter((t) => isVaccine(t.test_configuration.service_specification));
  const tests = appointment.tests.filter((t) => !wellnessTests.includes(t) && !isVaccine(t.test_configuration.service_specification));

  const [selectedTestStrip, setSelectedTestStrip] = useState(null);
  const [selectedTest, setSelectedTest] = useState(null);
  const [selectedVaccine, setSelectedVaccine] = useState(null);
  const [selectedWellnessTest, setSelectedWellnessTest] = useState(null);

  const selections = {selectedTestStrip, selectedTest, selectedVaccine, selectedWellnessTest};
  const noTestSelected = Object.values(selections).every(v => !v);

  const noOtherTestTypeSelected = (key) => {
    const otherSelections = Object.keys(selections)
      .filter(selectionKey => selectionKey !== key)
      .map(selectionKey => selections[selectionKey]);
    return otherSelections.every(v => !v);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selectedTestStrip, selectedTest, selectedVaccine]);

  const overviewMessage = `${t('result_page.overview_title')}`;
  const overviewTitle = `${t('result_page.medical_history')}`;

  const recordAcknowledgement = async (testStrip, status, user = undefined) =>
    axios
      .post(appointment.record_result_acknowledgement_path, {
        test_strip_id: testStrip.id,
        acknowledgement_status: status,
        user: { phone_number: user?.phone_number },
      })
      .then((_) => {
        const idx = appointment.test_strips.findIndex(
          (ts) => ts.id === testStrip.id,
        );
        const testStrips = [...appointment.test_strips];
        testStrips[idx] = {
          ...testStrips[idx],
          acknowledgement_status: status,
        };

        setAppointment({
          ...appointment,
          test_strips: testStrips,
        });
      });

  const onBackClick = () => {
    const acknowledgementContainer = document.getElementsByClassName(
      'has-pending-acknowledgement',
    );
    if (acknowledgementContainer.length > 0) {
      acknowledgementContainer[0].classList.add('focus');
      acknowledgementContainer[0].scrollIntoView();
    } else {
      setSelectedTestStrip(null);
    }
  };

  return (
    <Row className="mb-2">
      <Col sm={3} className="d-none d-sm-none d-md-block p-3 d-print-block">
        <h4>{t('result_page.patient')}</h4>
        <p className="text-muted mb-0 body-medium-normal">
          {t('instructions.name')}
        </p>
        <h5 className="body-large-16-regular font-weight-bold">
          {user.full_name}
        </h5>
        <p className="text-muted body-medium-normal mb-0 mt-3">
          {t('registration.date_of_birth')}
        </p>
        <b className="body-large-16-regular">{user.date_of_birth}</b>
        {test_group.request_additional_appointment && (
          <React.Fragment>
            <hr />
            <a
              href={accessCodeRegistrationUrl}
              className="btn btn-outline-secondary d-print-none"
            >
              {t('login.book_appointment')}
            </a>
          </React.Fragment>
        )}
        {!user['belongs_to_account?'] && (
          <Button
            variant="outline-secondary"
            className="mt-3 d-print-none"
            href={`/members/sign_up?invite_code=${user.invite_code}`}
          >
            {t('self_resulting.add_to_account')}
          </Button>
        )}
        {user.admin_requests_change_on_insurance && (
          <React.Fragment>
            <Button
              onClick={() => setShowInsuranceModal(true)}
              variant="outline-secondary"
              className="d-print-none mt-3"
            >
              Complete insurance
            </Button>
            {showInsuranceModal && (
              <FixInsuranceModal
                test_group={test_group}
                user={user}
                setShow={setShowInsuranceModal}
              />
            )}
          </React.Fragment>
        )}
      </Col>
      <Col className="px-3">
        {noTestSelected && (
          <PageHeader title={overviewTitle} message={overviewMessage} />
        )}
        {selectedTestStrip && (
          <>
            <Button
              variant="link"
              className="kit-flow header-btn"
              style={{ padding: '24px 0 0 0' }}
              onClick={onBackClick}
            >
              {t('otc.back_button')}
            </Button>
            <Row>
              <Col>
                <PageHeader
                  title={
                    isMobile ? (
                      <>
                        {t('test_strip.box_type', {
                          type: selectedTestStrip.kit_type,
                        })}
                        <br />
                        {t(
                          `test_strip.display_name.${selectedTestStrip.kit_type}`,
                        )}
                      </>
                    ) : (
                      `${t('test_strip.box_type', {
                        type: selectedTestStrip.kit_type,
                      })} - ${t(
                        `test_strip.display_name.${selectedTestStrip.kit_type}`,
                      )}`
                    )
                  }
                  barcode={selectedTestStrip.barcode}
                  displayTime={selectedTestStrip.display_time}
                />
              </Col>
              <Col className="text-end col-12 col-sm-auto order-first order-sm-last">
                <StatusBadge status={selectedTestStrip.status} />
              </Col>
            </Row>
          </>
        )}
        {selectedWellnessTest && (
          <Button
            variant="link"
            className="kit-flow header-btn"
            style={{ padding: '24px 0 0 0' }}
            onClick={() => setSelectedWellnessTest(null)}
          >
            {t('otc.back_button')}
          </Button>
        )}
        {noOtherTestTypeSelected("selectedWellnessTest") && !!wellnessTests.length && (
          <WellnessDisplay
            tests={wellnessTests}
            selectedWellnessTest={selectedWellnessTest}
            setSelectedWellnessTest={setSelectedWellnessTest}
          />
        )}
        {noOtherTestTypeSelected("selectedVaccine") && !!vaccines.length && (
          <VaccinesDisplay
            tests={vaccines}
            selectedVaccine={selectedVaccine}
            setSelectedVaccine={setSelectedVaccine}
            isVaccine={isVaccine}
          />
        )}
        {selectedTest && (
          <>
            <Button
              variant="link"
              className="kit-flow header-btn"
              style={{ padding: '24px 0 0 0' }}
              onClick={() => setSelectedTest(null)}
            >
              {t('otc.back_button')}
            </Button>
            <Row>
              <Col>
                <TestHeader test={selectedTest} />
              </Col>
              <Col className="text-end col-12 col-sm-auto order-first order-sm-last">
                <StatusBadge status={selectedTest.result_complete} />
              </Col>
            </Row>
          </>
        )}
        {noOtherTestTypeSelected("selectedTestStrip") && !!appointment.test_strips.length && (
          <TestStripsDisplay
            user={user}
            testStrips={appointment.test_strips}
            selectedTestStrip={selectedTestStrip}
            setSelectedTestStrip={setSelectedTestStrip}
            tests={appointment.tests}
            recordAcknowledgement={recordAcknowledgement}
            flipperFlags={flipper_flags}
          />
        )}
        {noOtherTestTypeSelected("selectedTest") && !!tests.length && (
          <TestsDisplay
            tests={tests}
            selectedTest={selectedTest}
            setSelectedTest={setSelectedTest}
            isVaccine={isVaccine}
          />
        )}
        <div className="my-4">
          <LocalizedMarkdown container={test_group} stringKey="result_text" />
        </div>
        <NeedHelp t={t} />
        {test_group.request_additional_appointment && (
          <a
            href={accessCodeRegistrationUrl}
            className="btn btn-outline-secondary d-print-none d-md-none"
          >
            {t('login.book_appointment')}
          </a>
        )}
        <br />
        {!user['belongs_to_account?'] && (
          <Button
            variant="outline-secondary"
            className="d-md-none mt-3 d-print-none"
            href={`/members/sign_up?invite_code=${user.invite_code}`}
          >
            {t('self_resulting.add_to_account')}
          </Button>
        )}
        <br />
        {user.admin_requests_change_on_insurance && (
          <React.Fragment>
            <Button
              onClick={() => setShowInsuranceModal(true)}
              variant="outline-secondary"
              className="d-md-none mt-3"
            >
              Complete insurance
            </Button>
            {showInsuranceModal && (
              <FixInsuranceModal
                test_group={test_group}
                user={user}
                setShow={setShowInsuranceModal}
              />
            )}
          </React.Fragment>
        )}
      </Col>
    </Row>
  );
};

export default MedicalHistory;
