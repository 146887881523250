import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { verifiedCheck } from '../../Participants/components/ParticipantCard';
import CheckoutButton from './CheckoutButton';

const NewTestGroupUserCard = ({
  is_global_search,
  onDemandOnly,
  test_group_user,
  assignAppointmentSlotGroup,
  toggleSelection,
  selectedTestGroupUsers,
  targetTestGroupUsers,
  setUserTagsModalOpen,
  setSelectedSingleTestGroupUser,
}) => {
  const { test_group, user, links } = test_group_user;

  const createBadge = (type, message, link) => {
    return (
      <a href={link}>
        <span className={'m-1 py-1 px-2 badge badge-pill bg-' + type}>{message}</span>
      </a>
    );
  };

  const createHoverBadge = (type, message, link, position) => {
    return (
      <OverlayTrigger
        placement={position}
        overlay={
          <Tooltip className="primary-tooltip">
            {message}: {user.missing_required_info.join(", ")}
          </Tooltip>
        }
      >
        <a href={link}><span className={"m-1 py-1 px-2 badge badge-pill bg-" + type}>{message}</span></a>
      </OverlayTrigger>
    )
  };

  let appointment_link = links.participant_url;
  let waitlist_link = links.waitlist_url;
  if (is_global_search) {
    appointment_link = links.global_search.global_search_participant_url;
    waitlist_link = links.global_search.global_search_waitlist_url;
  }

  const hasMissingRequiredInfo = user.missing_required_info.length > 0;
  const readyToCheckout = user.ready_to_checkout;

  const isOnDemand =
    !['in_development', 'archived'].includes(test_group.status) &&
    test_group.has_on_demand_location;

  return (
    <React.Fragment>
      <td>
        <input
          type="checkbox"
          className="form-check-input"
          checked={
            !!selectedTestGroupUsers.find((tguId) => tguId == test_group_user.id)
          }
          onChange={(e) =>
            toggleSelection(test_group_user.id, e.target.checked)
          }
        />
      </td>
      <td>
        <a href={links.test_group_user_link}><b>{user.full_name}</b></a>
        <div>{user.date_of_birth} ({user.age})</div>
      </td>
      {is_global_search && <td>{test_group.name}</td>}
      <td>
        {user.phone_number && (
          <div>
            {verifiedCheck(true)} <span className="ms-1">{user.phone_formatted}</span>
          </div>
        )}
        {user.email && (
          <div>
            {verifiedCheck(user.email_verified)} <span className="ms-1">{user.email}</span>
          </div>
        )}
      </td>
      <td>
        <div>Population: {test_group_user.population_name}</div>
        <div>Tags: {user.tags.map((tag) => tag.friendly_name).join(', ')}</div>
      </td>
      <td>
        {hasMissingRequiredInfo &&
          <div>
            {createHoverBadge("danger", "Missing information", links.edit_test_group_user_url, "top")}
          </div>
        }
        {!onDemandOnly && (
          <div>
            {test_group_user.has_appointment &&
              createBadge('primary', 'Has appointment', links.test_group_participant_url)}
          </div>
        )}
        <div>
          {test_group_user.is_waitlisted &&
            createBadge('warning', 'Waitlisted', waitlist_link)}
        </div>
        <div>
          {test_group_user.archived &&
            createBadge('danger', 'Archived', appointment_link)}
        </div>
        <div>
          {!is_global_search &&
            test_group.has_active_consent &&
            !test_group_user.consented &&
            createBadge(
              'danger',
              test_group_user.consent_gone_status,
              links.consent_url,
            )}
        </div>
      </td>
      <td>
        <CheckoutButton
          testGroup={test_group}
          onDemand={isOnDemand}
          links={links}
          readyToCheckout={readyToCheckout}
          onClick={
            test_group.assign_appointment_slot_groups_to_users &&
            assignAppointmentSlotGroup
          }
        />
      </td>
      <td>
        <div
          className="pointer"
          data-bs-toggle="modal"
          data-bs-target="#custom-message-modal"
          onClick={() => {
            targetTestGroupUsers.current.value = [test_group_user.id];
            document.getElementsByClassName('message-count')[0].innerHTML = 1;
          }}
          style={{
            backgroundColor: "var(--bs-secondary)",
            mask: `url(/images/icons/icon-mail.svg) no-repeat center`,
            "-webkit-mask": `url(/images/icons/icon-mail.svg) no-repeat center`,
            "-webkit-mask-size": 20,
            height: 20,
            width: 20,
          }}
        ></div>
        
      </td>
      <td>
        <div class="dropdown">
          <button
            class="btn btn-link"
            type="button"
            id={`dropdownMenuButton${test_group_user.id}`}
            data-bs-toggle="dropdown"
            aria-expanded="false"
            style={{border: "1px solid transparent"}}
          >
            <img src="/images/icons/icon-more-horizontal.svg" style={{minWidth: 20}} />
          </button>
          <ul class="dropdown-menu" aria-labelledby={`dropdownMenuButton${test_group_user.id}`}>
            <li>
              <a href={links.edit_test_group_user_url} className="dropdown-item">
                Edit
              </a>
            </li>
            <li>
              <a href={links.medical_history_url} className="dropdown-item">
                History
              </a>
            </li>
            <li>
              <a
                onClick={() => {
                  setSelectedSingleTestGroupUser(test_group_user)
                  setUserTagsModalOpen(true)
                }}
                className="dropdown-item inter bold pointer underline-link"
              >
                Tag
              </a>
            </li>
          </ul>
        </div>
      </td>
    </React.Fragment>
  );
};

export default NewTestGroupUserCard;
