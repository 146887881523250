import { format, formatDistanceStrict, isAfter, isBefore, add } from 'date-fns';

import IISReferences from './IisReferences';
import { isCovidVaccine, vaccineCode, manufacturerName } from '../../common/utils/vaccine';

class CovidWarningBuilder {
  static LEVEL_DANGER = 'danger';
  static LEVEL_WARNING = 'warning';
  static LEVEL_OK = 'success';

  static CDC_SUGGESTED_MAXIMUM_FOLLOW_UP_DAYS = 45;

  constructor({histories, tests, iisReferences}) {
    this.histories = histories;
    this.tests = tests;
    this.firstDose = this.getCovidVaccines()[0];
    this.mostRecentReference = new IISReferences(iisReferences).mostRecent();
  }

  getAvailableVaccines() {
    if (this.availableVaccines) return this.availableVaccines;

    const uniqueCvxCodes = new Set();
    this.tests.forEach(test => {
      test.test_configuration.vaccine_lots.forEach(lot => {
        uniqueCvxCodes.add(lot.vaccine_route.cvx_code?.code)
      });
    });
    return this.availableVaccines = uniqueCvxCodes;
  }

  sameVaccineUnavailable() {
    return !this.getAvailableVaccines().has(vaccineCode(this.firstDose.vaccine));
  }

  getAllowableSecondDose() {
    if (this.allowableSecondDose) return this.allowableSecondDose;

    let sameVaccineTest = this.tests.find(test => {
      return test.test_configuration.vaccine_lots.find(lot => {
        return lot.vaccine_route.cvx_code.code == vaccineCode(this.firstDose.vaccine);
      });
    });
    return this.allowableSecondose = sameVaccineTest && sameVaccineTest.test_configuration;
  }

  multipleManufacturersAvailable() {
    return this.getAvailableVaccines().size > 1;
  }

  tooSoon() {
    return isBefore(new Date(), this.minimumFollowUpDate(this.tests));
  }

  minimumFollowUpDate() {
    return add(new Date(this.firstDose.vaccinated_on), {days: this.followUpMinimumDays()})
  }

  followUpMinimumDays() {
    return this.getAllowableSecondDose().follow_up_minimum_days;
  }

  tooLate() {
    return isAfter(new Date(), this.maximumFollowUpDate());
  }

  maximumFollowUpDate() {
    return add(new Date(this.firstDose.vaccinated_on), {days: this.followUpMaximumDays()})
  }

  followUpMaximumDays() {
    // FIXME: Temporary measure until we add configurable minimum and maximum warning values
    // for the TC's
    //return this.getAllowableSecondDose().follow_up_maximum_days;
    return CovidWarningBuilder.CDC_SUGGESTED_MAXIMUM_FOLLOW_UP_DAYS;
  }

  getCovidVaccines() {
    if(this.covidVaccines) return this.covidVaccines;

    return this.covidVaccines = this.histories.filter(({vaccine}) => isCovidVaccine(vaccine));
  }

  hasHadAdverseReactions() {
    return this.histories.find(({notes}) => notes);
  }

  hasStaleIISReferences() {
    return this.hasNeverBeenChecked() || this.wasLastCheckedWithErrors() || this.isStale();
  }

  hasNeverBeenChecked() {
    return !this.mostRecentReference;
  }

  wasLastCheckedWithErrors() {
    return IISReferences.hasErrors(this.mostRecentReference);
  }

  isStale() {
    return IISReferences.isStale(this.mostRecentReference);
  }

  warnings() {
    let vaccineCount = this.getCovidVaccines().length
    const warnings = [];
    if (vaccineCount) {
      const manufacturerForFirstDose = manufacturerName(this.firstDose.manufacturer);
      const daysSinceFirstDose = formatDistanceStrict(
        new Date(this.firstDose.vaccinated_on),
        new Date(),
        {unit: 'day'}
      );
      if (this.getAvailableVaccines().size == 0) {
        warnings.push({
          level: CovidWarningBuilder.LEVEL_WARNING,
          message: `Second dose manufacturer cannot be determined (no active Vaccine Lots)`
        });
      } else if (this.sameVaccineUnavailable()) {
        warnings.push({
          level: CovidWarningBuilder.LEVEL_DANGER,
          message: `First dose from unavailable manufacturer (${manufacturerForFirstDose})`
        });
      } else {
        if (this.multipleManufacturersAvailable()) {
          warnings.push({
            level: CovidWarningBuilder.LEVEL_WARNING,
            message: `If administering second COVID dose, ensure same manufacturer: ${manufacturerForFirstDose}`
          });
        }

        if (this.tooSoon()) {
          warnings.push({
            level: CovidWarningBuilder.LEVEL_DANGER,
            message: `First dose administered ${daysSinceFirstDose} days ago, minimum follow up date is: ${format(this.minimumFollowUpDate(), 'yyyy-MM-dd')}`
          });
        } else if (this.tooLate()) {
          warnings.push({
            level: CovidWarningBuilder.LEVEL_WARNING,
            message: `First dose administered ${daysSinceFirstDose} days ago, maximum follow up date was: ${format(this.maximumFollowUpDate(), 'yyyy-MM-dd')}`
          });
        }
      }
    }
    if (this.hasHadAdverseReactions()) {
      warnings.push({
        level: CovidWarningBuilder.LEVEL_WARNING,
        message: "Possible adverse reactions in previous immunization history"
      });
    }

    if (this.hasStaleIISReferences()) {
      warnings.push({
        level: CovidWarningBuilder.LEVEL_WARNING,
        message: "Immunization history is not fresh"
      });
    }
    if (warnings.length === 0) {
      const message = this.firstDose
        ? `${vaccineCount} dose${vaccineCount > 1 ? 's' : ''} received. Please check date of last COVID-19 vaccine for additional dose availability`
        : 'Eligible for first dose';
      warnings.push({
        level: CovidWarningBuilder.LEVEL_OK,
        message: message
      });
    }
    return warnings;
  }
}

export default CovidWarningBuilder;
