import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import RegistrationContext from '../contexts/RegistrationContext';
import RegistrationCore from './RegistrationCore';
import AppointmentSlot, { validateAppointmentSlot } from './registration/AppointmentSlot';
import FollowUpExplaination from './followups/FollowUpExplaination';

const FollowUpRegistration = (props) => {
  // State initialization
  const { t, i18n } = useTranslation();
  const testGroup = props.test_group;
  const initialValues = {appointment: {}};
  const firstAppointment = props.first_appointment;
  const followUpTestConfiguration = props.follow_up_test_configuration;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState(0);
  const [localFormState, setLocalFormState] = useState({
    accessCode: location.pathname.split("/").pop(),
    key: firstAppointment.registration_access_key,
  });

  let sections = [
    {
      header: 'registration.information',
      view: () => <FollowUpExplaination followUpTestConfiguration={followUpTestConfiguration} />,
      validate: () => {},
      name: 'registration_text',
    },
    {
      header: 'registration.location',
      view: ({values, errors, setFieldValue}) => (
        <AppointmentSlot
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          followUp={true}
        />
      ),
      validate: validateAppointmentSlot,
      name: 'appointment_time',
    },
  ].filter(section => section);

  const onSubmit = (values, bag) => {
    if(currentRegistrationStep === sections.length - 1){
      setIsSubmitting(true);
      axios.post(`/follow_up_appointments`, {
        access_code: props.access_code,
        appointment: {
          appointment_slot_id: values.appointment.appointment_slot_id,
        }
      }).then((response) => {
        window.location.reload();
      }).catch((error => {
        console.log(error.response.message);
      }));
    } else {
      setCurrentRegistrationStep(currentRegistrationStep + 1);
    }
  };

  return (
    <div className="mb-5">
      <RegistrationContext.Provider value={{
        testGroup,
        isSubmitting,
        setIsSubmitting,
        localFormState,
        setLocalFormState,
        currentRegistrationStep,
        setCurrentRegistrationStep,
        sectionsLength: sections.length,
        currentSectionHeader: sections[currentRegistrationStep].header,
        sections: sections,
      }}>
        <RegistrationCore
          initialValues={initialValues}
          onSubmit={onSubmit}
          progress={(currentRegistrationStep + 1) / sections.length * 100}
          sections={sections}
          currentRegistrationStep={currentRegistrationStep}
          onSubmitText={t('registration.submit')}
          showProgress={true}
          headerText={"Schedule Appointment for Second Dose"}
        />
      </RegistrationContext.Provider>
    </div>
  );
};

FollowUpRegistration.propTypes = {
  test_group: PropTypes.object.isRequired, // this is passed from the Rails view
  first_appointment: PropTypes.object.isRequired,
  follow_up_test_configuration: PropTypes.object.isRequired,
};

export default FollowUpRegistration;
