import React, { useState } from 'react';
import { Button, Image, Card, Col, Row, FormControl } from 'react-bootstrap';
import Webcam from '../common/components/Webcam';
import { dataURItoBlob } from '../common/utils';
import { verifyIfSpecificDevice } from '../Otc/components/OtcFlow';
import PhotoUploader from '../Otc/components/PhotoUploader';
import {
  faCircleMinus,
  faCaretRight,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import '../../../../vendor/assets/css/animate.css';
import {
  CDCIcon,
  OSHAIcon,
  FullyVaccinatedIcon,
  FullyVaccinatedWithBoosterIcon,
  NotVaccinatedIcon,
  PartiallyVaccinatedIcon,
} from './common/Icons';
import FloatingLabelInput from '../common/components/FloatingLabelInput';
import ErrorMessage from '../common/components/ErrorMessage';
import CheckboxCard from '../common/components/CheckBoxCard';
import styled, { css } from 'styled-components';
import { Stepper } from '../common/components/Wizard';
import { useTranslation } from 'react-i18next';

const VACCINEMANUFACTURER = {
  pfizer: 'Pfizer-BioNTech',
  moderna: 'Moderna',
  johnson: 'Johnson & Johnson/Janssen',
  other: 'vaccination_status.other',
};

const Error = ({ name }) => {
  return name ? <ErrorMessage message={name} /> : null;
};

const STATUS = {
  fully_vaccinated: {
    label: 'vaccination_status.label.fully_vaccinated',
    icon: FullyVaccinatedIcon,
    description:"vaccination_status.definition.fully_vaccinated",
  },
  partially_vaccinated: {
    label: 'vaccination_status.label.partially_vaccinated',
    icon: PartiallyVaccinatedIcon,
    alterValue: 'exempt_with_partial_vaccination',
    description: "vaccination_status.definition.partially_vaccinated",
  },
  not_vaccinated: {
    label: 'vaccination_status.label.not_vaccinated',
    icon: NotVaccinatedIcon,
    alterValue: 'exempt_with_no_vaccination',
    description: "vaccination_status.definition.not_vaccinated",
  },
  fully_vaccinated_with_booster: {
    label: 'vaccination_status.label.fully_vaccinated_with_one_booster',
    icon: FullyVaccinatedWithBoosterIcon,
    description: "vaccination_status.definition.fully_vaccinated_with_one_booster",
  },
  fully_vaccinated_with_second_booster: {
    label: 'vaccination_status.label.fully_vaccinated_with_two_boosters',
    icon: FullyVaccinatedWithBoosterIcon,
    description: "vaccination_status.definition.fully_vaccinated_with_two_boosters",
  },
  prefer_not_to_answer: { label: 'vaccination_status.label.prefer_not_to_answer' },
};

const StatusIcon = styled.div`
  display: flex;

  svg {
    height: 40px !important;
    width: 40px !important;
    max-height: unset !important;
    max-width: unset !important;
    margin-left: 0 !important;
  }
`;

const CenteredContent = styled.div`
  ${({ fixed }) =>
    fixed
      ? css`
          @media (min-width: 768px) {
            width: 600px;
          }
        `
      : css`
          @media (min-width: 768px) {
            max-width: 600px;
          }
        `}

  margin-left: auto;
  margin-right: auto;
`;

const Status = ({
  values,
  setValues,
  setStatusAndClearValues,
  showExempt,
  errors,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const { t, i18n } = useTranslation();

  return (
    <React.Fragment>
      <CenteredContent fixed className="my-4">
        <h5 className="mb-2">{t("vaccination_status.title")}*</h5>
        <a href="javascript:;" onClick={() => setShowInfo(!showInfo)}>
          <FontAwesomeIcon icon={showInfo ? faAngleDown : faCaretRight} />{' '}
          {t("vaccination_status.definition_title")}
        </a>
        <div className={!showInfo && 'd-none'}>
          <a
            target="_blank"
            href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/fully-vaccinated.html"
          >
            {CDCIcon}
            <span className="me-1" />{t("vaccination_status.definition_cdc")}
          </a>
          <br />
          <a target="_blank" href="https://www.osha.gov/coronavirus/ets2/faqs">
            {OSHAIcon} <span className="me-1" />{t("vaccination_status.definition_osha")}
          </a>
        </div>
      </CenteredContent>
      <CenteredContent>
        <Col style={{ maxWidth: 600 }}>
          {Object.keys(STATUS).map((stat, idx) => {
            const active =
              values.status === stat ||
              values.status === STATUS[stat].alterValue;
            return (
              <CheckboxCard
                key={idx}
                radio
                checked={active}
                onChange={() =>
                  stat === 'prefer_not_to_answer'
                    ? setStatusAndClearValues(stat)
                    : setValues({ ...values, status: stat })
                }
                name={stat}
                reducedMargin
              >
                <Row className="w-100">
                  <div
                    className="d-grid align-items-center"
                    style={{ gridTemplateColumns: '1fr 40px', gap: 8 }}
                  >
                    <span className={active ? 'fw-normal' : 'fw-light'}>
                      <span className={active ? 'fw-bold' : 'fw-bolder'}>
                      {t(STATUS[stat].label)}
                        {STATUS[stat].description && ':'}
                      </span>
                      {STATUS[stat].description && (
                        <span> {t(STATUS[stat].description)}</span>
                      )}
                    </span>
                    {STATUS[stat].icon && (
                      <StatusIcon>{STATUS[stat].icon}</StatusIcon>
                    )}
                  </div>
                </Row>
              </CheckboxCard>
            );
          })}
        </Col>
      </CenteredContent>
      <Error name={errors.status} />
      {showExempt && <Exempt {...{ values, setValues, errors }} />}
    </React.Fragment>
  );
};

const Exempt = ({ values, setValues, errors }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CenteredContent fixed>
        <h5 className="mt-4">{t('vaccination_status.exemption')}</h5>
      </CenteredContent>
      <CenteredContent>
        <Col>
          <Row>
            <Col lg={5}>
              <CheckboxCard
                name="exempt"
                radio
                checked={[
                  'exempt_with_no_vaccination',
                  'exempt_with_partial_vaccination',
                ].includes(values.status)}
                onChange={() => {
                  if (values.status === 'partially_vaccinated')
                    setValues({
                      ...values,
                      status: 'exempt_with_partial_vaccination',
                    });
                  if (values.status === 'not_vaccinated')
                    setValues({
                      ...values,
                      status: 'exempt_with_no_vaccination',
                    });
                }}
                reducedMargin
              >
                {t('vaccination_status.exempt')}
              </CheckboxCard>
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <CheckboxCard
                name="not_exempt"
                radio
                checked={['partially_vaccinated', 'not_vaccinated'].includes(
                  values.status,
                )}
                onChange={() => {
                  if (values.status === 'exempt_with_partial_vaccination')
                    setValues({ ...values, status: 'partially_vaccinated' });
                  if (values.status === 'exempt_with_no_vaccination')
                    setValues({ ...values, status: 'not_vaccinated' });
                }}
                reducedMargin
              >
                {t('vaccination_status.not_exempt')}
              </CheckboxCard>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={5}>
              <FloatingLabelInput
                disabled={
                  ![
                    'exempt_with_no_vaccination',
                    'exempt_with_partial_vaccination',
                  ].includes(values.status)
                }
                onChange={(e) =>
                  setValues({ ...values, exempt_notes: e.target.value })
                }
                value={values.exempt_notes}
                label={t('vaccination_status.enter_exemption')}
                dataTestHook="exempt_notes"
              />
              <Error name={errors.exempt_notes} />
            </Col>
          </Row>
        </Col>
      </CenteredContent>
      {['exempt_with_no_vaccination', 'exempt_with_partial_vaccination'].includes(
        values.status,
      ) && (
        <React.Fragment>
          <CenteredContent fixed>
            <h5 className="mt-4">{t('vaccination_status.upload_exemption_documentation')}</h5>
          </CenteredContent>
          <CenteredContent>
            <ImageCapture
              values={values}
              setValues={setValues}
              exemptImage
              errors={errors}
            />
          </CenteredContent>
        </React.Fragment>
      )}
    </React.Fragment>
  )
};

const ImageCapture = ({ values, setValues, errors, exemptImage = false, setErrors }) => {
  const usePhonesUploadOptions = verifyIfSpecificDevice(["iPhone", "iPad", "Android"]);
  const [capture, setCapture] = useState(false);
  const [fileText, setFileText] = useState(null);

  const imageName = exemptImage ? 'exempt_images' : 'images';
  const imageData = values[imageName] && values[imageName][0];
  const setImageData = (e) =>
    setValues({ ...values, [imageName]: values[imageName].concat(e) });
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card
        className="overflow-hidden justify-content-center"
        style={{
          minHeight: 200,
          maxWidth: 500,
          backgroundColor: '#FFF',
          ...(capture || imageData ? { height: 'unset' } : { maxHeight: 200 }),
        }}
      >
        {capture ? (
          <Webcam
            setImageData={setImageData}
            setShow={setCapture}
            style={{ width: '100%', height: 'auto' }}
          />
        ) : imageData ? (
          <div>
            <img src={imageData} className="shared-labs-webcam-image" />
          </div>
        ) : (
          <React.Fragment>
            <div
              className="d-flex flex-column flex-grow-1 h-100 align-items-center justify-content-center click-to-take-photo"
              style={{ border: 'none', maxWidth: 'unset' }}
              onClick={() => { 
                usePhonesUploadOptions 
                  ? document.getElementById("file-upload-trigger").click() 
                  : setCapture(true)                
              }}
            >
              <FontAwesomeIcon icon={'camera'} style={{ fontSize: '3em' }} />
              <span>{t('vaccination_status.take_photo')}</span>
            </div>
          </React.Fragment>
        )}
      </Card>
      {usePhonesUploadOptions &&
        <PhotoUploader
          primaryTitle={"Upload"}
          secondaryTitle={"Change image"}
          imageData={imageData}
          setImageData={setImageData}
          setError={setErrors}
          setFile={(file) => {
            setValues({ ...values, [imageName + '_upload']: [file]});
            setFileText(file.name);
            setCapture(false);
          }}
          hidePrimaryButton={!imageData}
        />
      }
      {imageData ? (
        <div
          className="mt-1 d-flex flex-row justify-content-between"
          style={{ maxWidth: 500 }}
        >
          <div>
            <label className="btn-link" htmlFor="vaccineFile">
            {t('vaccination_status.upload_image')}
            </label>
            {fileText && <span className="text-muted ms-2">{fileText}</span>}
          </div>
          <a
            className="btn-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setCapture(true);
              setValues({ ...values, [imageName]: [] });
            }}
          >
            {t('vaccination_status.retake_photo')}
          </a>
        </div>
      ) : (
        <div
          className="d-flex flex-column mt-3 align-items-center"
          style={{ maxWidth: 500, gap: 12 }}
        >
          <span>{t('vaccination_status.or')}</span>
          <label htmlFor="vaccineFile" className="btn btn-outline-primary">
            {t('vaccination_status.upload_image_or_pdf_instead')}
          </label>
          {fileText && <span className="text-muted ms-2">{fileText}</span>}
        </div>
      )}
      <input
        id="vaccineFile"
        className="d-none"
        type="file"
        name="appointment[consent_waiver_image]"
        accept="image/*;capture=camera"
        onChange={(e) => {
          setValues({ ...values, [imageName + '_upload']: e.target.files });
          setFileText(e.target.files[0].name);
          setCapture(false);
        }}
      />
      <Error name={errors[imageName]} />
    </React.Fragment>
  );
};

const VACCINE_TYPES = [
  {
    name: 'first_dose',
    label: 'vaccination_status.first_dose',
    manufacturer_label: 'vaccination_status.first_dose_manufacturer',
    date_label: 'vaccination_status.first_dose_date',
    remove_dose_label: 'vaccination_status.remove_first_dose',
  },
  {
    name: 'second_dose',
    label: 'vaccination_status.second_dose',
    manufacturer_label: 'vaccination_status.second_dose_manufacturer',
    date_label: 'vaccination_status.second_dose_date',
    remove_dose_label: 'vaccination_status.remove_second_dose',
  },
  {
    name: 'booster_dose',
    label: 'vaccination_status.first_booster_dose',
    manufacturer_label: 'vaccination_status.first_booster_dose_manufacturer',
    date_label: 'vaccination_status.first_booster_dose_date',
    remove_dose_label: 'vaccination_status.remove_first_booster_dose',
  },
  {
    name: 'second_booster_dose',
    label: 'vaccination_status.second_booster_dose',
    manufacturer_label: 'vaccination_status.second_booster_dose_manufacturer',
    date_label: 'vaccination_status.second_booster_dose_date',
    remove_dose_label: 'vaccination_status.remove_second_booster_dose',
  },
  {
    name: 'additional_dose',
    label: 'vaccination_status.additional_dose',
    manufacturer_label: 'vaccination_status.additional_dose_manufacturer',
    date_label: 'vaccination_status.additional_dose_date',
    remove_dose_label: 'vaccination_status.remove_additional_dose',
  },
];

const VaccineDoses = ({ values, errors, toggleDose }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CenteredContent fixed>
        <h5 className="my-4">
          {t('vaccination_status.select_doses_received')}
        </h5>
      </CenteredContent>
      <CenteredContent>
        <Col>
          {VACCINE_TYPES.map(({ name, label }, idx) => (
            <Row key={idx}>
              <CheckboxCard
                name={name}
                reducedMargin
                checked={
                  !!values.participant_vaccine_histories_attributes.find(
                    (h) => h.dose === name,
                  )
                }
                onChange={() => toggleDose(name)}
              >
                {t(label)}
              </CheckboxCard>
            </Row>
          ))}
        </Col>
        <Error name={errors.dose} />
      </CenteredContent>
    </React.Fragment>
  );
};

const VaccineDetails = ({
  history: { dose, ...history },
  updateHistory,
  updateHistoryDate,
  removeHistory,
  newRecord,
  errors,
}) => {
  const doseManufacturerLabel = VACCINE_TYPES.find((vt) => vt.name === dose)?.manufacturer_label;
  const doseDateLabel = VACCINE_TYPES.find((vt) => vt.name === dose)?.date_label;
  const removeDoseLabel = VACCINE_TYPES.find((vt) => vt.name === dose)?.remove_dose_label;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CenteredContent fixed>
        <Row>
          <Col
            className="d-flex align-items-center"
            md={!newRecord ? 9 : undefined}
          >
            <h5>{t(doseManufacturerLabel)}</h5>
          </Col>
          {!newRecord && (
            <Col className="my-3" md={3}>
              <Button
                className="d-none d-md-block"
                onClick={removeHistory}
                variant="outline-danger"
              >
                <FontAwesomeIcon icon={faCircleMinus} className="me-1" />
                  {t('vaccination_status.remove_dose')}
              </Button>
            </Col>
          )}
        </Row>
      </CenteredContent>
      <CenteredContent>
        <Col>
          {Object.keys(VACCINEMANUFACTURER).map((manufacturer, idx) => (
            <Row key={idx}>
              <CheckboxCard
                name={manufacturer}
                reducedMargin
                checked={history.manufacturer === manufacturer}
                onChange={() => updateHistory('manufacturer', manufacturer)}
              >
                {t(VACCINEMANUFACTURER[manufacturer])}
              </CheckboxCard>
            </Row>
          ))}
        </Col>
        <Error name={errors['manufacturer_' + history.id]} />
      </CenteredContent>
      <CenteredContent fixed>
        <h5 className="my-4">{t(doseDateLabel)}</h5>
      </CenteredContent>
      <CenteredContent>
        <Row>
          <Col xs="5" sm="auto">
            <Image src="/images/cdc_card.png" style={{ maxHeight: 100 }} />
          </Col>
          <Col xs="7">
            <FormControl
              type="date"
              style={{ maxWidth: '400px' }}
              value={`${history.year}-${history.month}-${history.day}`}
              data-test-hook="date"
              onChange={(e) => updateHistoryDate(e.target.value)}
            />
          </Col>
        </Row>
        <Error name={errors['date_' + history.id]} />
        {!newRecord && (
          <Button
            className="d-md-none w-100 mt-4"
            onClick={removeHistory}
            variant="outline-danger"
          >
            <FontAwesomeIcon icon={faCircleMinus} className="me-1" />
            {t(removeDoseLabel)}
          </Button>
        )}
      </CenteredContent>
    </React.Fragment>
  );
};

const UploadVaccinationRecord = ({
  values,
  setValues,
  errors,
  requireVaccineCardImage,
}) => {
  const [showInfo, setShowInfo] = useState(false);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <CenteredContent fixed className="mb-3">
        <h5 className="mt-4">
          {t('vaccination_status.upload_record_card')}{' '}
          {requireVaccineCardImage && '*'}
        </h5>
        <a href="javascript:;" onClick={() => setShowInfo(!showInfo)}>
          {showInfo
            ? t('vaccination_status.hide_example')
            : t('vaccination_status.show_example')}{' '}
          <FontAwesomeIcon icon={showInfo ? faAngleUp : faAngleDown} />
        </a>
        <div className={!showInfo && 'd-none'}>
          <Image src="/images/cdc_card.png" style={{ maxWidth: 450 }} />
        </div>
      </CenteredContent>
      <CenteredContent>
        <ImageCapture {...{ values, setValues, errors }} />
      </CenteredContent>
    </React.Fragment>
  );
};

const availableSteps = ({
  hasVaccinationRecord,
  values,
  setValues,
  requireVaccineCardImage,
  requireVaccinationHistory,
  skipVaccineCardImage,
  skipVaccinationHistory,
  setErrors,
}) => {
  const toggleDose = (dose) => {
    const selectedDoses = values.participant_vaccine_histories_attributes.map(
      (vh) => vh?.dose,
    );
    const existingDose = selectedDoses.find((sd) => sd === dose);
    const newSelectedDoses = existingDose
      ? selectedDoses.filter((sd) => !(sd === dose))
      : [...selectedDoses, dose];

    const newVaccineHistory = VACCINE_TYPES.filter((vt) =>
      newSelectedDoses.includes(vt.name),
    ).map((vt) => ({
      dose: vt.name,
      ...values.participant_vaccine_histories_attributes.find(
        (vh) => vh.dose === vt,
      ),
    }));

    setValues({
      ...values,
      participant_vaccine_histories_attributes: newVaccineHistory,
    });
  };

  const updateHistory = (idx) => (name, value) => {
    const newVaccinehistory = [
      ...values.participant_vaccine_histories_attributes,
    ];
    newVaccinehistory[idx] = { ...newVaccinehistory[idx], [name]: value };
    setValues({
      ...values,
      participant_vaccine_histories_attributes: newVaccinehistory,
    });
  };

  const updateHistoryDate = (idx) => (date) => {
    const newVaccinehistory = [
      ...values.participant_vaccine_histories_attributes,
    ];
    newVaccinehistory[idx] = {
      ...newVaccinehistory[idx],
      year: date.split('-')[0],
      month: date.split('-')[1],
      day: date.split('-')[2],
    };
    setValues({
      ...values,
      participant_vaccine_histories_attributes: newVaccinehistory,
    });
  };

  const validate = (errors) => {
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  let stepList = [
    {
      Component: Status,
      filterProps: (props) => props,
      validate: () => {
        const errors = {};
        if (!values.status) {
          errors.status = 'Please select your status';
        } else if (
          [
            'exempt_with_no_vaccination',
            'exempt_with_partial_vaccination',
          ].includes(values.status)
        ) {
          if (!values.exempt_images_upload && values.exempt_images.length === 0)
            errors.exempt_images = 'Please upload documentation';
          if (!values.exempt_notes)
            errors.exempt_notes = 'Please provide exemption reason';
        }

        return validate(errors);
      },
    },
  ];

  if (hasVaccinationRecord) {
    if (!skipVaccineCardImage) {
      stepList.push({
        Component: UploadVaccinationRecord,
        filterProps: (props) => props,
        validate: () => {
          const errors = {};
          if (
            requireVaccineCardImage &&
            !values.images_upload &&
            values.images.length === 0
          )
            errors.images = 'Please upload an image';
  
          return validate(errors);
        },
      });
    }

    if (!skipVaccinationHistory) {
      stepList.push({
        Component: VaccineDoses,
        filterProps: (props) => ({ toggleDose, ...props }),
        validate: () => {
          const errors = {};
          if (
            requireVaccinationHistory &&
            !values.participant_vaccine_histories_attributes.find((h) => !!h.dose)
          )
            errors.dose = 'Please select a dose';
  
          return validate(errors);
        },
      });
  
      const sortedPVHA = values.participant_vaccine_histories_attributes.sort((a,b) => {
        const orderOfVaccines = VACCINE_TYPES.map(vt => vt.name);
        return orderOfVaccines.indexOf(a.dose) - orderOfVaccines.indexOf(b.dose);
      })

      const vaccineDetailSteps =
        sortedPVHA.map((history, idx) => ({
          Component: VaccineDetails,
          filterProps: (props) => ({
            history,
            updateHistory: updateHistory(idx),
            updateHistoryDate: updateHistoryDate(idx),
            removeHistory: () => toggleDose(history.dose),
            ...props,
          }),
          validate: () => {
            const errors = {};
            if (requireVaccinationHistory && !history.manufacturer)
              errors['manufacturer_' + history.id] =
                'Please select a manufacturer';
            if (requireVaccinationHistory && (!history.day || !history.month || !history.year))
              errors['date_' + history.id] =
                'Please fill in the date of your vaccination';
  
            return validate(errors);
          },
        }));
  
        if (
            requireVaccinationHistory ||
            (values.participant_vaccine_histories_attributes.length > 0 && 
            !Object.values(values.participant_vaccine_histories_attributes[0]).every(v => v === null))
        )
          stepList = stepList.concat(vaccineDetailSteps);
    }
  }

  return stepList;
};

const Uploader = ({
  onComplete,
  data,
  testGroupId,
  submitText,
  vaccinationStatusFieldsRequested,
  vaccinationStatusFieldsRequired,
}) => {
  const newRecord = !data.vaccineData;
  const formParams = new URLSearchParams(data.authParams);
  const [values, setValues] = useState(
    data.vaccineData
      ? data.vaccineData
      : {
          images: [],
          exempt_images: [],
          status: data.vaccinationStatus,
          exempt_notes: null,
          participant_vaccine_histories_attributes: [
            { dose: null, day: null, month: null, year: null },
          ],
        },
  );

  const setStatusAndClearValues = (status) => {
    setValues({
      images: [],
      exempt_images: [],
      status: status,
      exempt_notes: null,
      participant_vaccine_histories_attributes: [
        { dose: null, day: null, month: null, year: null },
      ],
    });
  };

  const requireVaccineCardImage =
    vaccinationStatusFieldsRequired.includes('vaccine_card_image');
  const requestVaccineCardImage =
    vaccinationStatusFieldsRequested.includes('vaccine_card_image');
  const requireVaccinationHistory = 
    vaccinationStatusFieldsRequired.includes('vaccination_history');
  const requestVaccinationHistory =
    vaccinationStatusFieldsRequested.includes('vaccination_history');
  const requestExemption =
    vaccinationStatusFieldsRequested.includes('exemption');

  const showExempt =
    requestExemption &&
    [
      'not_vaccinated',
      'partially_vaccinated',
      'exempt_with_no_vaccination',
      'exempt_with_partial_vaccination',
    ].includes(values.status);
  const hasVaccinationRecord = ![
    'not_vaccinated',
    null,
    'exempt_with_no_vaccination',
    'prefer_not_to_answer',
  ].includes(values.status);
  const [errors, setErrors] = useState({});

  const submit = () => {
    let formData = new FormData();

    if (values.images_upload) {
      var ins = values.images_upload.length;
      for (var x = 0; x < ins; x++) {
        formData.append(
          'vaccine_upload[images][]',
          values.images_upload[x],
          values.images_upload[x].name,
        );
      }
    }

    if (values.exempt_images_upload) {
      var ins = values.exempt_images_upload.length;
      for (var x = 0; x < ins; x++) {
        formData.append(
          'vaccine_upload[exempt_images][]',
          values.exempt_images_upload[x],
          values.exempt_images_upload[x].name,
        );
      }
    }
    values.images.map((img) => {
      if (img.startsWith('data'))
        formData.append('vaccine_upload[images][]', dataURItoBlob(img));
    });

    values.exempt_images.map((img) => {
      if (img.startsWith('data'))
        formData.append('vaccine_upload[exempt_images][]', dataURItoBlob(img));
    });

    formData.append('vaccine_upload[status]', values.status);
    formData.append('vaccine_upload[exempt_notes]', values.exempt_notes);
    if (
      !['exempt_with_no_vaccination', 'not_vaccinated'].includes(values.status)
    ) {
      values.participant_vaccine_histories_attributes.map((attr, idx) => {
        if (attr.id)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][id]`,
            attr.id,
          );
        if (attr.dose)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][dose]`,
            attr.dose,
          );
        if (attr.manufacturer)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][manufacturer]`,
            attr.manufacturer,
          );
        if (attr.year)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][year]`,
            attr.year,
          );
        if (attr.month)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][month]`,
            attr.month,
          );
        if (attr.day)
          formData.append(
            `vaccine_upload[participant_vaccine_histories_attributes][${idx}][day]`,
            attr.day,
          );
      });
    }

    const url = data.id
      ? `/test_groups/${testGroupId}/vaccine_uploads/${
          data.id
        }/?${formParams.toString()}`
      : `/test_groups/${testGroupId}/vaccine_uploads?${formParams.toString()}`;

    if (data.id) {
      axios
        .put(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => onComplete());
    } else {
      axios
        .post(url, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((response) => onComplete());
    }
  };

  return (
    <Stepper
      expandedView={!newRecord}
      newRecord={newRecord}
      getStepList={availableSteps}
      values={values}
      setValues={setValues}
      setErrors={setErrors}
      showExempt={showExempt}
      hasVaccinationRecord={hasVaccinationRecord}
      requireVaccineCardImage={requireVaccineCardImage}
      requireVaccinationHistory={requireVaccinationHistory}
      skipVaccineCardImage={!requireVaccineCardImage && !requestVaccineCardImage}
      skipVaccinationHistory={!requireVaccinationHistory && !requestVaccinationHistory}
      setStatusAndClearValues={setStatusAndClearValues}
      completeText={submitText}
      onComplete={submit}
      errors={errors}
    />
  );
};

export default Uploader;
