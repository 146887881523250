import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import startCase from 'lodash/startCase';

import SelectableTable from '../../common/components/Table/components/SelectableTable';
import { AssignableUser, Test } from './Index';
import AssigneeDropdown from './AssigneeDropdown';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import Wrapped from '../../common/components/Wrapped';
import { determineBloodPressureStatus } from '../../CheckoutPage/components/utils';

const STATUS_LABEL = {
  attempted_contact: 'Attempted',
  attempted_voicemail: 'Attempted',
  attempted_no_voicemail: 'Attempted',
  not_notified: 'Not notified',
  contacted_complete: 'Contacted',
  contacted_final: 'Final Attempt',
  contacted_phone: 'Contacted',
  contacted_video: 'Contacted',
  sms_email_sent: 'SMS & email',
  contacted_follow_up_required: 'Follow up',
};

const ParticipantCell: FC<Test> = ({ user }) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">{user.fullName}</span>
    <span>
      {user.dateOfBirth} ({user.age})
    </span>
  </div>
);

const StatusCell: FC<Test> = ({ caseReportStatus, attemptsCount }) => {
  const statusString =
    (caseReportStatus &&
      (STATUS_LABEL[caseReportStatus] || startCase(caseReportStatus))) ??
    'New';
  const isResolved =
    [
      'contacted_complete',
      'contact_employer_follow_up_required',
      'contacted_follow_up_required',
      'contacted_final',
      'contacted_video',
      'contacted_phone',
      'normal_result',
    ].includes(caseReportStatus) || attemptsCount > 2;

  return (
    <div className="d-flex" {...(isResolved ? { 'data-resolved': true } : {})}>
      <div
        className="status-badge text-nowrap"
        data-status={caseReportStatus || 'new'}
      >
        {statusString}
        {caseReportStatus && ` (${attemptsCount ? Math.max(0, attemptsCount) : '0'})`}
      </div>
    </div>
  );
};

const OrderDetailsCell: FC<Test> = ({
  uid,
  testConfiguration: { displayName, serviceSpecification },
  testStrip,
  results,
}) => {
  let positiveResultCount = results.filter((r) =>
    [
      'positive',
      'high',
      'warning',
      'danger',
      'elevated',
      'overweight',
      'underweight',
      'abnormal',
      'obese',
      'prediabetes',
    ].includes(r.value),
  ).length;

  if (serviceSpecification === 'hypertension_screening') {
    const firstReadingHypertensionMeasurement = results.filter((r) =>
      ['Systolic', 'Diastolic'].includes(r.label),
    );
    const secondReadingHypertensionMeasurement = results.filter((r) =>
      ['Systolic - repeated', 'Diastolic - repeated'].includes(r.label),
    );

    const firstReadingHypertensionStatus = determineBloodPressureStatus(
      ...firstReadingHypertensionMeasurement.map((r) => r.value),
    );
    const secondReadingHypertensionStatus = determineBloodPressureStatus(
      ...secondReadingHypertensionMeasurement.map((r) => r.value),
    );

    if (!['normal', null].includes(firstReadingHypertensionStatus)) {
      positiveResultCount++;
    }

    if (!['normal', null].includes(secondReadingHypertensionStatus)) {
      positiveResultCount++;
    }
  }

  return (
    <div className="d-flex flex-column">
      <TooltipWrapper
        tooltipContent={`${testStrip?.barcode || uid} - ${displayName}`}
        placement="right"
      >
        <span className="test-name font-weight-bold" style={{ cursor: 'help' }}>
          {displayName}
        </span>
      </TooltipWrapper>
      <Wrapped
        WrapperComponent={TooltipWrapper}
        componentProps={{
          tooltipContent: results
            .map((r) =>
              results.length > 1
                ? `${r.label}: ${r.labelledValue || r.value || ''}`
                : r.labelledValue || r.value || '',
            )
            .join('\n'),
        }}
      >
        <span style={{ cursor: 'help' }}>
          {positiveResultCount} positive result(s)
        </span>
      </Wrapped>
    </div>
  );
};

const ResultedAtCell: FC<Test> = ({
  localizedResultsAtDate,
  localizedResultsAtTime,
}) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold ">{localizedResultsAtDate}</span>
    <span>{localizedResultsAtTime}</span>
  </div>
);

const LastAttemptCell: FC<Test> = ({ lastAttempt }) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">{lastAttempt?.date}</span>
    <span>{lastAttempt?.time}</span>
  </div>
);

const AttemptedByCell: FC<Test> = ({ lastAttempt }) => (
  <span>{lastAttempt?.adminFullName}</span>
);

const TimeZoneCell: FC<Test> = ({ timeZone }) => (
  <span>{timeZone.replace(' Time (US & Canada)', '')}</span>
);

const GoToCaseCell: FC<Test> = ({ caseReportPath }) => (
  <a href={caseReportPath} target="_blank">
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
  </a>
);

interface IndexTableProps {
  tests: Test[];
  assignableUsers: AssignableUser[];
  onSelectedChange: (selectedTests: number[]) => void;
}

const IndexTable: FC<IndexTableProps> = ({
  tests,
  assignableUsers,
  onSelectedChange,
}) => (
  <SelectableTable
    data={tests}
    headers={[
      { name: 'Participant', CellComponent: ParticipantCell },
      { name: 'Status', CellComponent: StatusCell },
      { name: 'Order details', CellComponent: OrderDetailsCell },
      { name: 'Resulted at', CellComponent: ResultedAtCell },
      { name: 'Last attempt', CellComponent: LastAttemptCell },
      { name: 'Attempted by', CellComponent: AttemptedByCell },
      { name: 'Time zone', CellComponent: TimeZoneCell },
      {
        name: 'Assigned to',
        CellComponent: (test) => (
          <AssigneeDropdown
            flush
            selectedTests={[test]}
            assignableUsers={assignableUsers}
            {...test}
          />
        ),
      },
      { name: '', CellComponent: GoToCaseCell },
    ]}
    onSelectedChange={onSelectedChange}
  />
);

export default IndexTable;
