import React from "react";
import GoogleAutocomplete from "./GoogleAutocompleteV2";
import HereTechAutocomplete from "./HereTechAutoComplete";

export default function AutocompleteLocation({
  useHereTech = true,
  ...props
}) {
  return useHereTech ? (
    <HereTechAutocomplete {...props} />
  ) : (
    <GoogleAutocomplete {...props} />
  );
}
