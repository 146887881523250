import React from 'react';
import TestSection from './TestSection';
import VaccineSection from './VaccineSection';

const ResultTable = ({ test, printSelectedTest }) => {
  if (
    test.test_configuration.service_specification === 'vaccine_covid' ||
    test.test_configuration.service_specification === 'vaccine_flu'
  ) {
    return <VaccineSection test={test} printSelectedTest={printSelectedTest} />;
  } else {
    return <TestSection test={test} printSelectedTest={printSelectedTest} />;
  }
};

export default ResultTable;
