import React, { useState, useEffect } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';
import NewTestGroupUserCard from './NewTestGroupUserCard';
import { LocationContextProvider } from '../../LocationSelector/components/LocationContext';
import AssignAppointmentSlotGroupModal from './AssignAppointmentSlotGroupModal';
import AsyncMultiSelect from '../../../bundles/common/components/AsyncMultiSelect';
import axios from 'axios';

const SelectableTable = ({
  isGlobalSearch,
  onDemandOnly,
  testGroupUsers,
  selectedTestGroupUsers,
  setSelectedTestGroupUsers,
  targetTestGroupUsers,
  testGroup,
  selectedSingleTestGroupUser,
  setSelectedSingleTestGroupUser,
  createGroupTagPath
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [testGroupUserForModal, setTestGroupUserForModal] = useState({});
  const [customUrl, setCustomUrl] = useState('');
  const [visibleSelected, setVisibleSelected] = useState(false);
  const [userTagsModalOpen, setUserTagsModalOpen] = useState(false);

  const openModal = (href) => {
    setCustomUrl(href);
    setModalOpen(true);
  };

  const closeModal = () => {
    setCustomUrl('');
    setModalOpen(false);
  };

  useEffect(() => {
    setVisibleSelected(
      testGroupUsers
        .map((tg) => selectedTestGroupUsers.includes(tg.id))
        .reduce((a, b) => a && b, true),
    );
  }, [testGroupUsers, selectedTestGroupUsers]);

  const toggleSelection = (id, checked) => {
    const newSelection = checked
      ? [...selectedTestGroupUsers, id]
      : selectedTestGroupUsers.filter((tgId) => tgId !== id);
    setSelectedTestGroupUsers(newSelection);
  };

  const toggleVisible = (checked) => {
    if (checked) {
      const additionalSelections = testGroupUsers
        .filter((tgu) => !selectedTestGroupUsers.includes(tgu.id))
        .map((tgu) => tgu.id);
      setSelectedTestGroupUsers([...selectedTestGroupUsers, ...additionalSelections]);
    } else {
      const newSelection = selectedTestGroupUsers.filter(
        (tguId) => !testGroupUsers.map((tgu) => tgu.id).includes(tguId),
      );
      setSelectedTestGroupUsers(newSelection);
    }
    setVisibleSelected(checked);
  };

  const UserTagsModal = () => {
    const [selectedUserTags, setSelectedUserTags] = useState(selectedSingleTestGroupUser.user.tags);
    const testGroupUserToUpdate = testGroupUsers.find(tgu => tgu.user_id === selectedSingleTestGroupUser.user_id);

    const updateUserTags = async () => {
      return axios.put(`/test_groups/${testGroup.id}/participants/${selectedSingleTestGroupUser.user.id}/update_user_tags`, {
        id: selectedSingleTestGroupUser.user.id,
        user: {
          user_tags: selectedUserTags.join(),
          tag_ids: selectedSingleTestGroupUser.user.tags.map(t => t.id)
        }
      })
    }

    const createTag = (newTag) =>
      new Promise((resolve, reject) => {
        axios
          .post(createGroupTagPath, {
            tag: {
              friendly_name: newTag,
            },
          })
          .then(({ data: { tag } }) => resolve(tag))
          .catch((error) => reject(error));
      });

    return (
      <div className="p-4">
        <h4>Tags</h4>
        <p className="my-3">
          Select or update all relevent tags for this participant.
        </p>
        <div className="my-4">
          <AsyncMultiSelect
            name="Tags"
            selected={selectedUserTags}
            optionsUrl={`/test_groups/${testGroup.id}/tags`}
            onSelect={(selected) => {
              const newValues = selected ? selected.map((t) => t.value) : [];
              setSelectedUserTags(newValues);
            }}
            createOption={createTag}
            formatCreateLabel={(_) => "Save as a new tag"}
            creatable={true}
          />
        </div>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-primary"
            className="mx-2"
            onClick={() => setUserTagsModalOpen(false)}
          >
            No, go back
          </Button>
          <Button
            className="mx-2"
            onClick={async () => {
              try {
                const response = await updateUserTags();
                testGroupUserToUpdate.user.tags = response.data.new_user_tags;

                setUserTagsModalOpen(false);
                toastr.success(`User tags updated`);
              } catch (e) {
                console.log(e);
                toastr.error('Something went wrong.');
              }
            }}
          >
            {testGroupUserToUpdate.user.tags.length > 0
              ? 'Yes, update tags'
              : 'Yes, add tags'}
          </Button>
        </div>
      </div>
    );
  }

  return (
    <LocationContextProvider>
      <section className="fabrx-tables-light">
        <Table
          responsive
          data-test-hook="test_group_users_table"
          className="table-lg"
        >
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="select-all-checkbox"
                  checked={visibleSelected}
                  onChange={(e) => toggleVisible(e.target.checked)}
                  data-selected-ids={selectedTestGroupUsers.join(',')}
                />
              </th>
              <th>Name</th>
              {isGlobalSearch && <th>Test group</th>}
              <th className="d-none d-xl-table-cell">Contact info</th>
              <th className="d-none d-xl-table-cell">Population/Tags</th>
              <th className="d-none d-xl-table-cell">Status</th>
              <th colSpan="8"></th>
            </tr>
          </thead>
          <tbody>
            {testGroupUsers.map((test_group_user) => (
              <tr
                key={test_group_user.id}
                className="test_group_user__page"
                data-test-hook={`test_group_user_${test_group_user.id}`}
              >
                <NewTestGroupUserCard
                  test_group_user={test_group_user}
                  key={test_group_user.id}
                  isGlobalSearch={isGlobalSearch}
                  onDemandOnly={onDemandOnly}
                  assignAppointmentSlotGroup={(href) => {
                    setTestGroupUserForModal(test_group_user);
                    openModal(href);
                  }}
                  toggleSelection={toggleSelection}
                  selectedTestGroupUsers={selectedTestGroupUsers}
                  targetTestGroupUsers={targetTestGroupUsers}
                  setUserTagsModalOpen={setUserTagsModalOpen}
                  setSelectedSingleTestGroupUser={setSelectedSingleTestGroupUser}
                />
              </tr>
            ))}
          </tbody>
        </Table>
      </section>
      {modalOpen && (
        <AssignAppointmentSlotGroupModal
          show={modalOpen}
          onHide={closeModal}
          customUrl={customUrl}
          testGroupUser={testGroupUserForModal}
        />
      )}
      <Modal show={userTagsModalOpen} onHide={() => setUserTagsModalOpen(false)} size="md">
        <UserTagsModal />
      </Modal>
    </LocationContextProvider>
  );
};

export default SelectableTable;
