import React, { useEffect, useState } from 'react';
import FloatingLabelInput from '../common/components/FloatingLabelInput';
import { loadScript } from './GoogleAutocomplete';
import SearchBox from './autocomplete/SearchBox';
import Item from './autocomplete/Item';

let service;
let placeService;

const handleScriptLoad = () => {
  service = new google.maps.places.AutocompleteService();
  placeService = new google.maps.places.PlacesService(document.createElement('div'));
}

const GoogleItem = ({onClick, ...props}) => {
  const mainText = props.structured_formatting.main_text,
    matchedSubstring = props.structured_formatting.main_text_matched_substrings[0];
  let matchedText = '',
    restOfText = '';
  if (matchedSubstring) {
    matchedText = mainText.slice(matchedSubstring.offset, matchedSubstring.length),
    restOfText = mainText.slice(matchedSubstring.length, mainText.length);
  } else {
    restOfText = mainText;
  }
  return (
    <Item 
      onClick={onClick}
      mainText={`<b>${matchedText}</b>${restOfText}`}
      secondaryText={props.structured_formatting.secondary_text}
      {...props}
    />
  )
} 

const GoogleAutocomplete = ({
  className,
  onChange,
  name,
  invalid,
  value,
  ...props
}) => {
  const [items, setItems] = useState([]);
  const [showOptions, setShowOptions] = useState(false);

  const handleChange = (event) => {
    onChange(event)
    let request = {
      input: event.target.value,
      componentRestrictions: { country: "us" }
    }
    service.getPlacePredictions(request, (e) => setItems(e));
    setShowOptions(true);	
  };

  const getPlace = ({place_id, ...props}) => {
    placeService.getDetails(
      {placeId: place_id, fields: ["address_components", "formatted_address"]},
      (addressObject) => {
         var googlePlace = new window.Primary.GoogleAddress(addressObject);

        var address1 = Primary.normalizeString(googlePlace.composeAddress(['street_number', 'route'], 'long').trim());
        var city = Primary.normalizeString(googlePlace.composeAddress(['locality', 'postal_town'], 'long').trim());
        var state = Primary.normalizeString(googlePlace.composeAddress(['administrative_area_level_1'], 'short').trim());
        var postalCode = Primary.normalizeString(googlePlace.composeAddress(['postal_code'], 'long').trim());
        var county = Primary.normalizeString(googlePlace.composeAddress(['administrative_area_level_2'], 'long').trim());
        onChange({target: {value: addressObject.formatted_address, name, address: {address1, city, state, postalCode, county},...addressObject, ...props}}); 
        setShowOptions(false);
      }
    );
  }
  useEffect(() => {
    if (google != undefined) {
      handleScriptLoad()
    } else {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}&libraries=places`,
        () => handleScriptLoad()
      );
    }
  }, []);

  return (
    <div className={className || undefined} data-testid="googleAutocomplete">
      <div style={{position: 'relative'}}>
        <FloatingLabelInput
          onChange={handleChange}
          prependIcon="map-marker-alt"
          onBlur={() => setShowOptions(false)}
          autoComplete="new-password"
          type="text"
          name={name}
          value={value || ''}
          validation={invalid && 'is-invalid'}
          ariaInvalid={!!invalid}
          {...props}
        />
        {showOptions && items?.length > 0 && (
          <SearchBox
            items={items}
            onClickItem={(value) => getPlace(value)}
            ItemComponent={GoogleItem}
          />
        )}
      </div>
    </div>
  );
}

export default GoogleAutocomplete;
