import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, ProgressBar } from "react-bootstrap";
import axios from 'axios';
import Header from '../../Registration/components/Header';
import OtcContext from '../contexts/OtcContext';
import OtcCodeReader from "./OtcCodeReader";
import OtcResultsEntry from "./OtcResultsEntry";
import OtcSummary from "./OtcSummary";
import OtcInstructions from "./OtcInstructions";
import OtcDateOfTest from "./OtcDateOfTest";
import OtcPhoto from "./OtcPhoto";
import OtcConfirmation from "./OtcConfirmation";
import OtcClarifyResult from "./OtcClarifyResult";
import OtcVerifyDateOfBirth from "./OtcVerifyDateOfBirth";
import OtcTestKitSelector from "./OtcTestKitSelector";
import OtcSurveillance from "./OtcSurveillance";
import OtcSurvey from "./OtcSurvey";
import { useTranslation } from 'react-i18next';
import * as SurveyOriginal from "survey-react";
import { Model, StylesManager } from "survey-core";
import RegistrationContext from '../../../Registration/contexts/RegistrationContext';
import SwitchUsers from "../../MembersPortal/components/partials/SwitchUsers";
import { setHousehold } from "../../MembersPortal/components/utils";
import AlertHeader from "../../common/components/AlertHeader";
import { otcInstructionSubSteps } from "../../PublicTestGroups/Demo/Instructions/Instructions";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import "survey-core/plugins/bootstrap-integration";

export const verifyIfPhone = () => {
  return /Android|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
};

export const verifyIfSpecificDevice = (deviceArray) => {
  const devices = new RegExp(deviceArray.join("|"));
  return devices.test(navigator.userAgent);
};

export const widthOfScreen = () => {
  return window.innerWidth;
}

const OtcFlow = ({
  test_group,
  flippers,
  appointment,
  test_configuration,
  user,
  test_group_test_configuration,
  controller,
  flow,
  all_test_kits,
  pretty_test_kit_names,
}) => {

  const today = new Date();
  const params = new URLSearchParams(window.location.search);
  const [currentStep, setCurrentStep] = useState(0);
  const [test, setTest] = useState(null);
  const [result, setResult] = useState(null);
  const [multiplexResult, setMultiplexResult] = useState(null);
  const [didNotResultReason, setDidNotResultReason] = useState(null);
  const [barcode, setBarcode] = useState("");
  const [barcodeConfirmation, setBarcodeConfirmation] = useState(null);
  const [barcodeScanned, setBarcodeScanned] = useState(false);
  const [numberOfScanTries, setNumberOfScanTries] = useState(0);
  const [barcodeReaderSkipped, setBarcodeReaderSkipped] = useState(false);
  const [showManualInput, setShowManualInput] = useState(false);
  const [showWebCam, setShowWebCam] = useState(false);
  const [testImageData, setTestImageData] = useState(null);
  const [certifyCheckboxChecked, setCertifyCheckboxChecked] = useState(false);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [displayFooterButtons, setDisplayFooterButtons] = useState(true);
  const [dateOfBirth, setDateOfBirth] = useState({day: null, month: null, year: null});
  const [progress, setProgress] = useState(10);
  const [resultDate, setResultDate] = useState("");
  const [resultTime, setResultTime] = useState("");
  const [testTakenToday, setTestTakenToday] = useState(null);
  const [resultInLastThirtyDays, setResultInLastThirtyDays] = useState(true);
  const hasSurvey = Object.keys(test_configuration.survey).length > 0;
  const allowSelfResulting = test_group_test_configuration.allow_self_resulting;
  const needsToScanQRCode = flow === "self_checkouts" || test_configuration.generate_record_configuration === "scan_only"
  const [selectedKit, setSelectedKit] = useState(null);
  const [selectedTestType, setSelectedTestType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loggedIn, setLoggedIn] = useState(null);
  const isTestLucira = test_configuration.service_specification === "naat_lucira_flu_ab_covid19";
  const isTestMultiplex = flow === "self_admin" && allowSelfResulting && isTestLucira;
  const [testStripCreated, setTestStripCreated] = useState(false);
  const isArchived = user.archived;
  const instructionFlowAvailable = flow === "quidel_self_checkouts";
  const showMultiStepInstructions = instructionFlowAvailable && flippers.quidel_detailed_otc_instructions;
  const showPhotoStep = allowSelfResulting && (test_group_test_configuration.request_otc_photo || test_group_test_configuration.require_otc_photo);

  const { t, i18n } = useTranslation();
  let model;
  if (flippers.upgraded_surveyjs) {
    StylesManager.applyTheme("bootstrap");
    model = new Model(test_configuration.survey)
  } else {
    SurveyOriginal.StylesManager.applyTheme("bootstrap");
    model = new SurveyOriginal.Model(test_configuration.survey)
  }
  model.render();
  model.locale = i18n.language;

  let multiplexSubTests = [];
  if (isTestLucira) {
    multiplexSubTests = test_configuration.results_schema.results.map(
      ({ type, label, possible_values }) => {
        const resultOptions = possible_values.map(({ label, value, icon }) => {
          return {
            name: label,
            value: value,
            icon: ['fa', icon],
          };
        });
        return {
          key: type,
          label: label,
          resultOptions: resultOptions,
        };
      },
    );
  };

  const [activeErrors, setActiveErrors] = useState({});
  const errorOptions = {
    "incorrect_date_of_birth": t("otc.incorrect_dob"),
    "unanswered_survey": t("registration.errors.survey_unanswered"),
    "generic_error": t("errors.generic.message"),
    "already_scanned": t("employer_testing.code_reader.already_used_error"),
    "file_too_large": t("otc.file_too_large", {size: "20MB"}),
    "test_not_in_last_thirty_days": t("otc.enter_valid_date_in_last_x_days", {n: "30"}),
    "incorrect_barcode_format": t("otc.barcode_format_not_valid"),
    "barcodes_dont_match": t("self_administration.errors.different_barcodes"),
  };

  useEffect(() => {
    let token = params.get('token') || params.get('access_token');
    setHousehold(token, setLoggedIn, user);
  }, []);

  useEffect(() => {
    // hacky way to update the title of otcInstructionSubSteps
    if (!instructionFlowAvailable) return;
    const currentSubStepTitle = document.getElementsByClassName('instruction-sub-step-title')[0];
    if (!currentSubStepTitle) return;
    const currentSubStepTitleText = currentSubStepTitle.innerHTML;
    const newSubStepTitle = document.createElement('h4');
    const newSubStepTitleText = currentSubStepTitleText.split(": ")[1] || "Wash your hands";
    newSubStepTitle.innerHTML = `${currentStep + 1}. ${newSubStepTitleText}`;
    currentSubStepTitle.parentNode.replaceChild(newSubStepTitle, currentSubStepTitle);
  }, [currentStep]);

  const setError = (error) => {
    const newError = {};
    newError[error] = errorOptions[error];
    setActiveErrors({...activeErrors, ...newError});
  }

  const clearError = (error) => {
    delete activeErrors[error]
  }

  const clearErrors = () => {
    setActiveErrors({});
  }


  const sections = [
    !user.date_of_birth
      && { name: "verify_date_of_birth", render: <OtcVerifyDateOfBirth /> },
    hasSurvey
      && {
        name: "survey",
        render: <OtcSurvey />,
        description: t("otc.steps.answer_survey")
      },
    {
      name: "instructions",
      render: <OtcInstructions />,
      description: t("otc.steps.review_instructions")
    },
    showMultiStepInstructions && (
      otcInstructionSubSteps.map((component, idx) => {
        return {
          name: `sub_instruction_${idx}`,
          render: component,
          description: idx === 0 && t("otc.steps.review_instructions")
        }
      })
    ),
    needsToScanQRCode
      && {
        name: "code_reader",
        render: <OtcCodeReader />,
        description: t("otc.steps.scan_code")
      },
    flow === "general_self_checkouts"
      && {
        name: "test_kit_selector",
        render: <OtcTestKitSelector />,
        description: t("otc.steps.selest_test_kit")
      },
    allowSelfResulting
      && {
        name: "date_of_test",
        render: <OtcDateOfTest />,
        description: test_group_test_configuration.require_otc_time ? t("otc.steps.enter_date_and_time") : t("otc.steps.enter_date"),
      },
    allowSelfResulting
      && {
        name: "results_entry",
        render: <OtcResultsEntry />,
        description: t("otc.steps.enter_result"),
      },
    allowSelfResulting
      && result === "did_not_result"
      && test_configuration.service_specification === "rapid_antigen_abbott_covid19"
      && { name: "clarify_result", render: <OtcClarifyResult /> },
    showPhotoStep
      && {
        name: "photo",
        render: <OtcPhoto />,
        description: t("otc.steps.take_photo"),
      },
    {
      name: "confirmation",
      render: <OtcConfirmation />,
      description: allowSelfResulting ? t("otc.steps.confirm_result") : t("otc.confirm_information"),
    },
    flippers.viral_surveillance
      && {
        name: "surveillance",
        render: <OtcSurveillance />,
      },
    {
      name: "summary",
      render: <OtcSummary />,
    },
  ].flat().filter(section => section);

  const lastStep = currentStep === sections.length - 1;
  const showBackButton = !lastStep && !(currentStep == 0 && loggedIn);

  const verify = (uid, appointment, testConfiguration) => {
    clearErrors();

    let url;
    if (controller === "otc" && flow === "self_checkouts") {
      url = "otc"
    } else if (controller === "self_checkouts") {
      url = "self_checkouts"
    } else if (controller === "self_admin") {
      url = `/test_groups/${test_group.slug}/validate_test`
    } else {
      url = "otc/validate_test"
    }

    return axios.post(url, {
      access_code: appointment.access_code,
      flow: flow,
      test: {
        uid: uid,
        test_configuration_id: testConfiguration.id,
      }
    });
  }

  const skipBarcodeReader = () => {
    setNextButtonDisabled(false);
    setBarcodeReaderSkipped(true);
  }

  const verifyBarcode = async (uid) => {
    const response = await verify(uid, appointment, test_configuration);
    if (response.data.resulted_test) {
      if (flow === "self_checkouts") {
        numberOfScanTries === 2 && allowSelfResulting ? skipBarcodeReader() : setError("already_scanned");
        setNumberOfScanTries(numberOfScanTries + 1);
      } else {
        setError("already_scanned");
      }
    } else {
      setBarcode(uid);
      setTest(response.data.test);
      setBarcodeScanned(true);
      setNextButtonDisabled(false);
    }
  }

  const isDateInPastThirtyDays = (newDate) => {
    today.setHours(0,0,0,0);
    today.toUTCString();
    const date = new Date(newDate);
    date.toUTCString();
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = today.getTime() - date.getTime();
    const diffInDays = Math.round(diffInTime / oneDay);
    return diffInDays <= 30 && diffInDays >= 0 ? true : false;
  }

  const verifyAndSetResultDate = (newDate) => {
    if (isDateInPastThirtyDays(newDate)) {
      setResultInLastThirtyDays(true);
    } else {
      setResultInLastThirtyDays(false);
    }
    setResultDate(newDate);
  }

  const verifyDateOfBirth = async (appointment, dateOfBirth) => {
    return axios.post(`/otc/verify_date_of_birth`, {
      date_of_birth: dateOfBirth,
      access_code: appointment.access_code
    })
  }

  const saveSurvey = async () => {
    return axios.post(`/otc/save_survey`, {
      access_code: appointment.access_code,
      test_survey: { survey: model.data, test_configuration_id: test_configuration.id }
    })
  }

  const validateSurvey = (savedSurvey) => {
    if (flippers.use_new_surveyjs_validation) {
      if (model.startedPage?.hasErrors()) return false;
    } else {
      const requiredQuestions = [];
      model.pages.forEach(page => {
        Object.keys(page.elements).forEach(idx => {
          if (page.elements[idx]['isRequired']) requiredQuestions.push(page.elements[idx]['name']);
        })
      });
      for (let i = 0; i < requiredQuestions.length; i++) {
        const question = requiredQuestions[i];
        if (savedSurvey["survey"][question] === undefined) return false;
      }
    }
    return true;
  }

  const crateTestStrip = async () => {
    return axios.post(`/otc/create_test_strip`, {
      access_code: appointment.access_code,
    })
  }

  const handleSurveillance = async (userWantsToParticipate = true) => {
    if (userWantsToParticipate) {
      try {
        const response = await crateTestStrip();
        if (response.status === 200) setTestStripCreated(true);
      } catch(e) {
        toastr.error(e.message)
      }
    } else {
      stepForward();
    }
  }

  const updateResult = async () => {
    const administeredAt = resultDate
      ? new Date(`${resultDate.split("-").join("/")} ${resultTime}`)?.toUTCString()
      : new Date().toUTCString();
    return axios.put(`/${controller}/${test.id}`, {
      access_code: appointment.access_code,
      flow: flow,
      test: {
        id: test.id,
        test_configuration_id: test_configuration.id,
        result: result,
        test_image_base64: testImageData,
        administered_at: administeredAt,
        abbott_test_result: {
          sample_result: result,
          did_not_result_reason: didNotResultReason,
        }
      },
    })
  }

  const submitResult = async (createAbbottTestWithoutBarcode = false) => {
    const multiplexSummarizedResult = multiplexResult && Object.values(multiplexResult).some(r => r === "positive") ? "positive" : "custom_result";
    const administeredAt = resultDate
      ? new Date(`${resultDate.split("-").join("/")} ${resultTime}`)?.toUTCString()
      : new Date().toUTCString();
    if (controller === "self_admin") {
      return axios.post(`/test_groups/${test_group.slug}/self_administrations`, {
        test: {
          uid: barcode,
          result_test_type: test_configuration.test_type,
          test_configuration_id: test_configuration.id,
          appointment_id: appointment.id,
          administered_at: administeredAt,
          result: result,
          self_admin_test_result_type: selectedTestType
        },
        flow: flow,
        access_code: appointment.access_code,
        test_file_base64: testImageData,
        generate_barcode: needsToScanQRCode ? "false" : "true",
        test_group_test_configuration_id: test_group_test_configuration.id,
      })
    } else {
      return axios.post(`/${controller}`, {
        access_code: appointment.access_code,
        flow: flow,
        multiplex_result: multiplexResult,
        test: {
          uid: barcode,
          test_configuration_id: test_configuration.id,
          result: isTestMultiplex ? multiplexSummarizedResult : result,
          test_image_base64: testImageData,
          administered_at: administeredAt,
        },
        general_test_result: {
          test_kit: selectedKit,
          sample_result: result
        },
        abbott_test_result: {
          sample_result: result,
          did_not_result_reason: didNotResultReason,
        },
        create_abbott_test_without_barcode: createAbbottTestWithoutBarcode,
      })
    }
  }

  const handleSubmitResult = async () => {
    if (loading) return;
    setLoading(true);
    const response = flow === "self_checkouts"
      ? barcodeReaderSkipped
        ? await submitResult({createAbbottTestWithoutBarcode: true})
        : await updateResult()
      : await submitResult();
    if (response.status === 200 || response.status === 204) {
      if (response.data.test) setBarcode(response.data.test.uid)
      setCurrentStep(currentStep + 1)
    } else {
      setError("generic_error");
    }
    setLoading(false);
    setNextButtonDisabled(false);
  }

  const stepForward = async () => {
    const disableButtonForSelfResulting = allowSelfResulting &&
      (resultDate === "" || resultTime === "");

    if (sections[currentStep].name === "verify_date_of_birth") {
      const response = await verifyDateOfBirth(appointment, `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`);
      if (response.data.match) {
        setCurrentStep(currentStep + 1)
        clearErrors();
      } else {
        setError('incorrect_date_of_birth');
      }
    } else if (sections[currentStep].name === "survey") {
      const response = await saveSurvey();
      if (response.data.saved) {
        if (validateSurvey(response.data.test_survey)) {
          setCurrentStep(currentStep + 1);
          clearErrors();
        } else {
          setError("unanswered_survey");
        }
      } else {
        setError("generic_error");
      }
    } else if ((sections[currentStep].name === "instructions" && !showMultiStepInstructions) ||
                sections[currentStep].name === "sub_instruction_10") {
      if (needsToScanQRCode && !barcodeScanned) {
        setNextButtonDisabled(true);
      } else if (flow === "general_self_checkouts" && !selectedKit) {
        setNextButtonDisabled(true);
      } else if (disableButtonForSelfResulting) {
        setNextButtonDisabled(true);
      }
      setCurrentStep(currentStep + 1);
    } else if (sections[currentStep].name === "code_reader") {
      if (showManualInput) {
        try {
          await verify(barcode, appointment, test_configuration);
        } catch(e) {
          setError("already_scanned");
          return;
        }
      }
      if (disableButtonForSelfResulting) setNextButtonDisabled(true);
      setCurrentStep(currentStep + 1);
    } else if (sections[currentStep].name === "test_kit_selector") {
      if (disableButtonForSelfResulting) setNextButtonDisabled(true);
      setCurrentStep(currentStep + 1);
    } else if (sections[currentStep].name === "date_of_test") {
      if (!result) setNextButtonDisabled(true);
      setCurrentStep(currentStep + 1);
    } else if (sections[currentStep].name === "results_entry") {
      if (result === "did_not_result" && flow === "self_checkouts" && !didNotResultReason) {
        setNextButtonDisabled(true);
      } else if (!testImageData && test_group_test_configuration.require_otc_photo) {
        setNextButtonDisabled(true);
      }
      setCurrentStep(currentStep + 1)
      clearErrors();
    } else if (sections[currentStep].name === "clarify_result") {
      setCurrentStep(currentStep + 1);
      if (!testImageData && test_group_test_configuration.require_otc_photo) {
        setNextButtonDisabled(true);
      }
      clearErrors();
    } else if (sections[currentStep].name === "photo") {
      if (!certifyCheckboxChecked) setNextButtonDisabled(true)
      setCurrentStep(currentStep + 1)
    } else if (sections[currentStep].name === "confirmation") {
      setNextButtonDisabled(true);
      handleSubmitResult();
    } else if (sections[currentStep].name === "summary") {
      if (loggedIn) {
        window.location.href = `/members/sign_in?current_user=${loggedIn.currentUser}`;
      } else {
        window.location.href = `/t/${test_group.slug}/u/${appointment.access_code}`;
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  }

  const stepBackwards = () => {
    if (sections[currentStep].name === "verify_date_of_birth") {
      window.location.href = `/t/${test_group.slug}/u/${appointment.access_code}`;
    } else if (["code_reader", "test_kit_selector", "date_of_test", "results_entry"].includes(sections[currentStep].name)) {
      setNextButtonDisabled(false);
    } else if (sections[currentStep].name === "photo") {
      setNextButtonDisabled(false);
      clearErrors();
    } else if (sections[currentStep].name === "confirmation") {
      if (allowSelfResulting) {
        setNextButtonDisabled(!testImageData && test_group_test_configuration.require_otc_photo);
      }
    }

    setCurrentStep(currentStep - 1);
  }

  const adjustFooterButtons = () => {
    if (sections[currentStep].name === "surveillance") {
      setDisplayFooterButtons(false);
    } else {
      setDisplayFooterButtons(true);
    }
  }

  const adjustProgress = () => {
    const currentProgress = Math.floor((currentStep / (sections.length - 1)) * 100)
    setProgress(currentProgress)
  }

  const switchFooter = () => {
    document.getElementById("default-footer").classList.add("d-none");
    document.getElementById("otc-footer").classList.remove("d-none");
  }
  switchFooter();

  useEffect(() => {
    adjustFooterButtons();
    adjustProgress();
    window.scrollTo(0, 0);
  }, [currentStep]);

  const context = {
    t,
    i18n,
    flippers,
    activeErrors,
    dateOfBirth,
    setDateOfBirth,
    firstName: user.first_name,
    testConfiguration: test_configuration,
    testGroup: test_group,
    appointment,
    model,
    verifyBarcode,
    clearError,
    clearErrors,
    result,
    setResult,
    barcodeScanned,
    certifyCheckboxChecked,
    setCertifyCheckboxChecked,
    user,
    setNextButtonDisabled,
    didNotResultReason,
    setDidNotResultReason,
    showWebCam,
    setShowWebCam,
    testImageData,
    setTestImageData,
    testGroupTestConfiguration: test_group_test_configuration,
    flow: flow,
    verifyAndSetResultDate,
    resultDate,
    setResultDate,
    setResultTime,
    resultTime,
    needsToScanQRCode,
    setError,
    isDateInPastThirtyDays,
    allTestKits: all_test_kits,
    prettyTestKitNames: pretty_test_kit_names,
    selectedKit,
    setSelectedKit,
    barcode,
    setBarcode,
    barcodeConfirmation,
    setBarcodeConfirmation,
    stepNumber: currentStep + 1,
    sections,
    allowSelfResulting,
    hideConfirmationPageLink: true,
    barcodeReaderSkipped,
    showManualInput,
    setShowManualInput,
    testTakenToday,
    setTestTakenToday,
    isTestLucira,
    isTestMultiplex,
    multiplexSubTests,
    multiplexResult,
    setMultiplexResult,
    handleSurveillance,
    testStripCreated,
    stepForward,
    isArchived,
  }

  return (
    <div>
      <Header testGroup={test_group} borderBottom={false} loggedInComponent={loggedIn && !loggedIn.fromMobile && <SwitchUsers />}/>
      <ProgressBar now={progress} variant="primary" />
      <div style={{ minHeight: verifyIfPhone() ? "calc(100vh - 188px)" : "calc(100vh - 188px)", backgroundColor: "#FBFBFB" }}>
        <div className="d-flex justify-content-between mx-3 pt-1" style={{backgroundColor: "#FBFBFB"}}>
          <div>{t("otc.report_a_test")}{` ${widthOfScreen() > 600 ? user.full_name : user.first_name}`}</div>
          <div>{t("otc.x_%_completed", {n: progress})}</div>
        </div>
        <div>
          {isArchived && (
            <AlertHeader
              title={"Your profile has been disabled"}
              message={<div>Please contact your school or organization for more information, or find your site <a href={`/test_groups/${test_group.slug}`} style={{textDecoration: "none"}}>here</a>.</div>}
              icon={faTriangleExclamation}
              className="m-4"
            />
          )}
          <OtcContext.Provider value={context}>
            {
              // use plain div for surveillance since it handles its layout differently
              sections[currentStep].name !== 'surveillance' ? (
                <Container
                  style={{ maxWidth: '560px' }}
                  className="pt-4 px-4"
                  id="self-checkout"
                >
                  {sections[currentStep].render}
                </Container>
              ) : (
                <div id="self-checkout">
                  {sections[currentStep].render}
                </div>
              )
            }
          </OtcContext.Provider>
          {
            displayFooterButtons && (
              <div>
                <Row
                  style={{ maxWidth: "560px", padding: "12px", margin: "auto", backgroundColor: "#FBFBFB" }}
                      className="d-flex flex-col justify-content-between"
                    >
                  <Col xl={3} md={4} xs={6} className="text-start">
                    {showBackButton && (
                          <Button
                            onClick={stepBackwards}
                            variant="link"
                            style={{ padding: "12px" }}
                            data-test-hook="backbutton"
                            className="otc-button"
                            disabled={isArchived}
                      >
                        {t("otc.back_button")}
                      </Button>
                    )}
                  </Col>
                  <Col xl={3} md={4} xs={lastStep ? 12 : 6} className="text-end">
                    {loading ? (
                      <FontAwesomeIcon icon="spinner" className="fa-spin" />
                    ) : (
                      <Button
                        onClick={stepForward}
                        disabled={nextButtonDisabled || isArchived}
                        data-test-hook="nextbutton"
                        className="otc-button"
                        style={{minWidth: "100px"}}
                          >
                        {lastStep ? t("otc.complete_button") : t("otc.next_button")}
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default OtcFlow;
