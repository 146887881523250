import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Header from '../common/components/Header';
import Authenticate from './Authenticate';
import Uploader from './Uploader';
import Completed from '../common/components/Completed';
import { useTranslation } from 'react-i18next';

export default (props) => {
  const {
    user_id,
    test_group_id,
    return_to_path,
    manually_authenticate,
    vaccine_data,
    submit_text,
    vaccination_status,
    vaccination_status_fields_requested,
    vaccination_status_fields_required,
  } = props;
  const [data, setData] = useState({
    authParams: {
      access_code: props.access_code,
      last_name: props.last_name,
      date_of_birth: props.date_of_birth,
    },
    vaccinationStatus: vaccination_status,
    vaccineData: vaccine_data,
    id: props.id,
  });
  const [currentStep, setCurrentStep] = useState(user_id ? 1 : 0);
  const { t } = useTranslation();

  function updateData(type, newData) {
    setData((data) => {
      return { ...data, [type]: newData };
    });
  }

  return (
    <Container fluid="lg">
      <div className="vaccine-upload">
        {manually_authenticate && (
          <Header
            currentStep={currentStep}
            steps={['Authenticate', 'Upload Vaccine Cards', 'Complete']}
          />
        )}
        <div className="px-xs-2 px-md-4">
          {currentStep === 0 && (
            <Authenticate
              onComplete={() => setCurrentStep(1)}
              data={data.authParams}
              update={updateData}
              testGroupId={test_group_id}
            />
          )}
          {currentStep === 1 && (
            <Uploader
              onComplete={() => setCurrentStep(2)}
              data={data}
              testGroupId={test_group_id}
              submitText={submit_text}
              vaccinationStatusFieldsRequested={
                vaccination_status_fields_requested
              }
              vaccinationStatusFieldsRequired={
                vaccination_status_fields_required
              }
            />
          )}
          {currentStep === 2 && (
            <Completed
              returnToPath={return_to_path}
              subtext={t('vaccination_status.completed_two')}
            />
          )}
        </div>
      </div>
    </Container>
  );
};
