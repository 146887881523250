import React from 'react';
import { ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faCircleXmark,
  faHouseUser,
} from '@fortawesome/pro-regular-svg-icons';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

import Badges from './participants/Badges';
import AppointmentInfo from './participants/AppointmentInfo';

export const verifiedCheck = (isVerified) => (
  <span>
    <FontAwesomeIcon
      icon={isVerified ? faCircleCheck : faCircleXmark}
      color={`var(--bs-${isVerified ? 'primary' : 'secondary'})`}
    />
  </span>
);

const ParticipantCard = ({ appointment }) => {
  const {
    appointment_slot,
    appointment_slot_group,
    user,
    links,
    global_search,
  } = appointment;

  const notCheckoutEligible =
    !user.is_checkout_eligible && !user.primary_user_id;

  var householdButtonLink = appointment.is_global_search
    ? global_search.global_search_participant
    : links.primary_user_id;

  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('include_archived') === 'true') {
    const householdButtonLinkParams = new URLSearchParams(
      householdButtonLink.split('?')[1],
    );
    householdButtonLinkParams.set('include_archived', true);
    householdButtonLink =
      householdButtonLink.split('?')[0] + '?' + householdButtonLinkParams;
  }

  return (
    <React.Fragment>
      <td>
        <div className="h6">
          <b>{user.last_name}</b>, {user.first_name}
        </div>
        {appointment.is_global_search && (
          <a href={global_search.test_group_search_participant}>
            {global_search.test_group_name}
          </a>
        )}
      </td>
      <td>
        <a
          className="btn btn-outline-secondary btn-has-one rounded-circle"
          href={householdButtonLink}
        >
          <FontAwesomeIcon icon={user.primary_user_id ? faHouseUser : faUser} />
        </a>
      </td>
      <td>
        <b>
          {user.date_of_birth} (age {user.age})
        </b>
        <br />
        {user.phone_number && (
          <div>
            {user.phone_formatted} {verifiedCheck(user.phone_verified)}
          </div>
        )}
        {user.email && (
          <div>
            {user.email} {verifiedCheck(user.email_verified)}
          </div>
        )}
      </td>
      <td>
        <AppointmentInfo
          appointment={appointment}
          links={links}
          appointmentSlotGroup={appointment_slot_group}
          appointmentSlot={appointment_slot}
        />
        <Badges
          testGroupId={appointment.test_group.id}
          appointment={appointment}
          user={user}
        />
      </td>
      <td>
        {appointment.test_lane_name && (
          <span>
            In: <i>{appointment.test_lane_name}</i>
          </span>
        )}
      </td>
      <td>
        <ButtonGroup className="d-flex">
          <a
            className={`btn ${
              notCheckoutEligible
                ? 'btn-warning text-dark'
                : 'btn-outline-secondary'
            }`}
            href={user.links.participant_path}
          >
            {user.first_name} {user.last_name}
          </a>
        </ButtonGroup>
      </td>
    </React.Fragment>
  );
};

export default ParticipantCard;
