import React, { useState } from 'react';
import { Alert, Button, Modal, Row, Col } from 'react-bootstrap';
import {
  faTriangleExclamation,
  faCircleExclamation,
  faDollarSign,
} from '@fortawesome/pro-regular-svg-icons';
import ModalHeader from '../../common/components/ModalHeader';
import AlertHeader from '../../common/components/AlertHeader';

export const errorRequiresConfirmation = (errors) =>
  Object.keys(errors)
    .filter((errorKey) => errors[errorKey].errored)
    .find((errorKey) =>
      [
        'not_consented',
        'guardian_consented',
        'Does Not Meet Age Requirement',
      ].includes(errorKey),
    );

export const insuranceInfoErrored = (errors) =>
  errors['insurance_info'].errored;

export const getError = (
  errorKey,
  errors,
  test_group_id,
  user,
  appointment,
) => {
  const humanizeErrorMessage = (error) => error.split('_').join(' ');

  let errorMessage;
  let errorUrl = `/test_groups/${test_group_id}/participants/${user.id}/edit`;
  let subtext;
  let icon = faTriangleExclamation;
  let showAsButton = true;
  let missingInformation = true;
  let standardErrorKeys = [
    'date_of_birth',
    'name',
    'address',
    'employment_info',
    'insurance_info',
    'language_preference',
    'phone_number',
    'email',
    'ethnicity',
    'race',
    'gender_identity',
    'sexual_orientation',
    'pronoun',
  ];

  if (errorKey === 'contact_information') {
    errorMessage = 'Missing phone or email';
  } else if (errorKey === 'not_consented') {
    icon = faCircleExclamation;
    errorMessage = 'Missing consent';
    errorUrl = `/test_groups/${test_group_id}/participants/${user.id}/consent_forms_users`;
  } else if (errorKey === 'unpaid') {
    errorMessage = 'Unpaid';
    errorUrl = `/test_groups/${test_group_id}/appointments/${appointment.id}/edit?tab=payment`;
    icon = faDollarSign;
  } else if (errorKey === 'first_dose_not_complete') {
    errorMessage = 'First dose not administered';
    errorUrl = user.errors[errorKey].url;
    subtext = user.errors[errorKey].subtext;
    missingInformation = false;
  } else if (errorKey === 'under_18') {
    errorMessage = 'Minor: Participant is under 18 years old';
    missingInformation = false;
    showAsButton = false;
  } else if (errorKey === 'Does Not Meet Age Requirement') {
    let ageRequirementTypes = errors[errorKey].errors.join(',');
    errorMessage = `Age restriction: Participant does not meet age requirement (${ageRequirementTypes})`;
    missingInformation = false;
    showAsButton = false;
  } else if (errorKey === 'guardian_consented') {
    errorMessage = 'Missing guardian consent';
    showAsButton = true;
    errorUrl = `/test_groups/${test_group_id}/participants/${user.id}/consent_forms_users`;
    icon = faCircleExclamation;
  } else if (errorKey === 'sex_at_birth') {
    errorMessage = 'Missing sex';
    errorUrl = user.errors[errorKey].url;
  } else if (standardErrorKeys.includes(errorKey)) {
    errorMessage = `Missing ${humanizeErrorMessage(errorKey)}`;
    if (user.errors[errorKey].url) errorUrl = user.errors[errorKey].url;
  } else {
    errorMessage = errorKey;
    showAsButton = false;
    errorUrl = false;
    missingInformation = false;
  }
  return {
    errorMessage,
    errorUrl,
    subtext,
    icon,
    showAsButton,
    missingInformation,
  };
};

const getModalContent = (error) => {
  let header = '';
  let body = '';

  if (error === 'not_consented' || error === 'guardian_consented' || error === 'test_group_test_configuration_error') {
    header = `Participant missing ${
      error === 'guardian_consented' ? 'guardian' : ''
    } consent`;
    body =
      'The participant does not have consent signed for this administration. Please go back and manage consent.';
  } else if (error === 'Does Not Meet Age Requirement') {
    header = 'Age requirement not met';
    body =
      'The participant does not meet the age requirement to be administered one or more service. Please go back and ensure the correct service is selected or consult your clinical lead to proceed.';
  } else if (error === 'insurance_info') {
    header = 'Missing insurance information';
    body =
      "The participant's insurance information is missing or incomplete. Please go back and ensure the information is complete or consult your clinical lead to proceed.";
  }

  return { header, body };
};

const getErrorDetails = (userErrors) => {
  const ageError = userErrors['Does Not Meet Age Requirement'];
  return ageError.errored
    ? `Age requirement not met for ${ageError.errors.join(', ')}`
    : '';
};

// NOTE: ConfirmationModal will be refactored and separated
// in two pieces in a future update
export const ConfirmationModal = ({
  error,
  userErrors,
  show,
  onHide,
  onSave = null,
}) => {
  const modalContent = getModalContent(error);
  const errorDetails = getErrorDetails(userErrors);
  const [initials, setInitials] = useState('');

  return (
    <Modal show={show} onHide={onHide}>
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2">{modalContent['header']}</Modal.Title>
      </ModalHeader>

      <Modal.Body>
        <p>{modalContent['body']}</p>
        {onSave && (
          <React.Fragment>
            {errorDetails && <p>{errorDetails}</p>}
            <p>
              To disregard this message and administer the service please type
              your initials in the box and select Save anyway.
            </p>
            <input
              style={{ maxWidth: '25%' }}
              type="initials"
              className="form-control"
              id="initialsInput"
              placeholder="Initials"
              value={initials}
              onChange={(e) => setInitials(e.target.value)}
            ></input>
          </React.Fragment>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={onHide} className="mx-2">
          {onSave ? 'Close' : 'Proceed'} & fix
        </Button>
        {onSave && (
          <Button
            variant="danger"
            onClick={() => onSave(initials)}
            disabled={initials.length < 2}
            data-test-hook="save-anyways"
            className="mx-2"
          >
            Save anyway
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

const getCheckoutErrors = (
  errorKey,
  test_group_id,
  user,
  appointment,
) => {

  const humanizeString = (string) => string.split('_').join(' ');

  let getErrorMessage = (infoType = humanizeString(errorKey)) => `Participant has not completed ${infoType}`;
  let getBtnTxt = (infoType = humanizeString(errorKey)) => `Add ${infoType}`;
  let errorUrl = `/test_groups/${test_group_id}/participants/${user.id}/edit`;

  let btnTxt = getBtnTxt();
  let btnAction =  () => window.open(errorUrl, "_blank");
  let errorMessage = getErrorMessage();

  if (errorKey === "demographic_information") {
    btnTxt = getBtnTxt("demographics");
  } else if (errorKey === "not_consented") {
    errorMessage = getErrorMessage("consent");
    btnTxt = getBtnTxt("consent");
    errorUrl = `/test_groups/${test_group_id}/participants/${user.id}/consent_forms_users`;
  } else if (errorKey === "payment_information") {
    errorMessage = "Participant has not paid yet";
    btnTxt = "Pay now";
    errorUrl = `/test_groups/${test_group_id}/appointments/${appointment.id}/edit?tab=payment`;
  } else if (errorKey === "missingSurveyAnswer") {
    const firstUncompletedSurvey = document.getElementsByClassName("missing-survey-answer")[0];
    const surveyLocation = firstUncompletedSurvey.getBoundingClientRect();
    btnAction = () => window.scrollTo({ top: surveyLocation.top, behavior: 'smooth'});
    errorMessage = "Some of the required survey answers are missing";
    btnTxt = "Answer required survey question"
  } else if (errorKey === "insurance_information") {
    const insuranceSection = document.getElementById("insurance-section")?.getBoundingClientRect();
    btnAction = () => window.scrollTo({ top: insuranceSection.top, behavior: 'smooth'});
  }

  return {
    errorMessage,
    errorUrl,
    btnTxt,
    btnAction,
  };
}

export const CheckoutErrors = ({ appointment, test_group_id, user }) => {

  const errorData = user.errors;
  if (errorData['phone_number'] || errorData['email']) delete errorData['contact_information'];
  const errors = Object.keys(errorData).filter((errorKey) => errorData[errorKey].errored);
  const groupedErrors = [];

  const missingSurveyAnswer = document.getElementsByClassName("missing-survey-answer").length > 0;
  if (missingSurveyAnswer) {
    if (!groupedErrors.includes('missingSurveyAnswer')) groupedErrors.push('missingSurveyAnswer');
  }

  errors.forEach(e => {
    if (['sex_at_birth', 'ethnicity', 'race', 'gender_identity', 'sexual_orientation', 'pronoun'].includes(e)) {
      if (!groupedErrors.includes('demographic_information')) groupedErrors.push('demographic_information');
    }
    if (['not_consented', 'guardian_consented'].includes(e)) {
      if (!groupedErrors.includes('not_consented')) groupedErrors.push('not_consented');
    }
    if (['contact_information', 'phone_number', 'email', 'address'].includes(e)) {
      if (!groupedErrors.includes('contact_information')) groupedErrors.push('contact_information');
    }
    if (['insurance_info'].includes(e)) {
      if (!groupedErrors.includes('insurance_information')) groupedErrors.push('insurance_information');
    }
    if (['employment_info'].includes(e)) {
      if (!groupedErrors.includes('employment_information')) groupedErrors.push('employment_information');
    }
    if (['unpaid'].includes(e)) {
      if (!groupedErrors.includes('payment_information')) groupedErrors.push('payment_information');
    }
    if (['date_of_birth', 'name'].includes(e)) {
      if (!groupedErrors.includes('personal_information')) groupedErrors.push('personal_information');
    }
  })

  return (
    <div>
      {groupedErrors.length > 0 && (
        <div>
          {groupedErrors.map((errorKey) => {
            const { errorMessage, errorUrl, btnTxt, btnAction } = getCheckoutErrors(
              errorKey,
              test_group_id,
              user,
              appointment,
            );

            return (
              <AlertHeader
                message={errorMessage}
                btnTxt={btnTxt}
                btnAction={btnAction}
                className={errorKey + "-error-header"}
              />
            );
          })}
        </div>
      )}
    </div>
  )
}

const UserErrors = ({ test_group_id, appointment, user }) => {
  const errorData = user.errors;

  if (errorData['phone_number'] || errorData['email'])
    delete errorData['contact_information'];

  const errors = Object.keys(errorData).filter(
    (errorKey) => errorData[errorKey].errored,
  );

  const hasMissingInformation = errors.some(
    (errorKey) =>
      getError(errorKey, errorData, test_group_id, user, appointment)
        .missingInformation,
  );

  const alertClass = errorRequiresConfirmation(user.errors)
    ? 'danger'
    : 'warning';

  return (
    <Alert variant={alertClass} className="d-block">
      {hasMissingInformation && (
        <React.Fragment>
          <h4 className="alert-heading mb-4">
            This person is missing critical information.
          </h4>
          <div>Please fix by clicking the links below before proceeding.</div>
          <hr />
        </React.Fragment>
      )}
      <Row>
        {errors.map((errorKey) => {
          const { errorMessage, errorUrl, subtext, showAsButton } = getError(
            errorKey,
            errorData,
            test_group_id,
            user,
            appointment,
          );

          return (
            <Col md={4} key={errorKey}>
              {showAsButton ? (
                <Button
                  target="_blank"
                  className="mb-2 w-100"
                  variant="outline-light"
                  href={errorUrl}
                >
                  {errorMessage}
                </Button>
              ) : (
                <div className="center" style={{ marginBottom: '14px' }}>
                  {errorMessage}
                </div>
              )}
              <div className="center" style={{ marginBottom: '8px' }}>
                <i>{subtext}</i>
              </div>
            </Col>
          );
        })}
      </Row>
    </Alert>
  );
};
export default UserErrors;
