import React, { useContext } from "react";
import { Col, Row } from "react-bootstrap";

import FloatingLabelInput from "../../common/components/FloatingLabelInput";
import FloatingLabelSelect from "../../common/components/FloatingLabelSelect";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";

const PaymentFields = () => {

  const {
    dispatch,
    state,
  } = useContext(TestGroupTestConfigurationsContext);

  const cancellationFeePercentageOptions = Array.from({ length: 101 }, (_, i) => i).map((n) => {
    return { value: n, label: `${n} %` }
  });
  const selectedCancellationFeePercentage = cancellationFeePercentageOptions.find((option) => {
    return option.value == state.testGroupTestConfiguration.cancellation_fee_percentage
  });

  return (
    <>
      <div className="py-2"><hr/></div>
      <h5 className="my-4">Payment</h5>
      <Row className="my-4">
        <Col xs={6} lg={3}>
          <h6>Cost:</h6>
          <FloatingLabelInput
            type="text"
            value={`$${state.testGroupTestConfiguration.payment_amount}`}
            onBlur={(e) => {
              const parsedInteger = e.target.value.replace(/[^0-9.]/g, '');
              const parsedFloat = parseFloat(parsedInteger || 0).toFixed(2);
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  payment_amount: parsedFloat
                }
              });
            }}
            onChange={(e) => {
              console.log(e.target.value)
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  payment_amount: e.target.value.replace(/[^0-9.]/g, '')
                }
              });
            }}
          />
        </Col>
        <Col xs={6} lg={3}>
          <h6>Cancellation fee:</h6>
          <FloatingLabelSelect
            filledValue={!!state.testGroupTestConfiguration.cancellation_fee_percentage}
            onChange={(target) => {
              dispatch({
                type: "setTestGroupTestConfiguration",
                values: {
                  ...state.testGroupTestConfiguration,
                  cancellation_fee_percentage: target.value
                }
              });
            }}
            options={cancellationFeePercentageOptions}
            value={selectedCancellationFeePercentage}
          />
        </Col>
      </Row>
    </>
  );
};

export default PaymentFields;