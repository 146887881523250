import React, { useEffect, useState } from 'react';

import Table from '../../common/components/Table';
import Wrapped from '../../common/components/Wrapped';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import LoadingOverlay from '../../common/components/LoadingOverlay';
import GroupStatus from './GroupStatus';

const InputCellComponent = ({ id, selectedTestGroupIds, toggleSelection }) => (
  <input
    type="checkbox"
    className="form-check-input"
    checked={!!selectedTestGroupIds.find((tgId) => tgId == id)}
    onChange={(e) => toggleSelection(id, e.target.checked)}
  />
);

const NameCellComponent = ({
  slug,
  name,
  organizationUser: { role },
}) =>
  <a href={`/test_groups/${slug}`}>{name}</a>;

const ServicesCellComponent = ({ testConfigurations }) => {
  const joinedTestConfigurations = testConfigurations
    .map((tc) => tc.displayName)
    .join(', ');

  return (
    <Wrapped
      WrapperComponent={TooltipWrapper}
      wrapIf={joinedTestConfigurations.length > 50}
      componentProps={{
        tooltipContent: joinedTestConfigurations,
        placement: 'right',
      }}
    >
      <span>
        {joinedTestConfigurations.slice(0, 50)}
        {joinedTestConfigurations.length > 50 && '...'}
      </span>
    </Wrapped>
  );
};

const OwningOrganizationCellComponent = ({ organization: { id, name } }) => (
  <a href={`/organizations/${id}/organization_users`}>{name}</a>
);

const FirstQuickLinkCellComponent = ({
  extensions: { studentProfiles },
  onDemandOnly,
  slug,
}) =>
  studentProfiles ? (
    <a
      className="btn btn-sm btn-outline-secondary"
      href={`/test_groups/${slug}/schools/students`}
      style={{ minWidth: 'max-content' }}
    >
      Students
    </a>
  ) : !onDemandOnly ? (
    <a
      className="btn btn-sm btn-outline-secondary"
      href={`/test_groups/${slug}/participants`}
    >
      Appointments
    </a>
  ) : (
    <a
      className="btn btn-sm btn-outline-secondary"
      href={`/test_groups/${slug}/test_group_users`}
      style={{ minWidth: 'max-content' }}
    >
      Participants
    </a>
  );

const SecondQuickLinkCellComponent = ({
  extensions: { studentProfiles },
  slug,
}) =>
  studentProfiles ? (
    <a
      className="btn btn-sm btn-outline-secondary"
      href={`/test_groups/${slug}/schools/student_profiles`}
      style={{ whiteSpace: 'nowrap' }}
    >
      Immunizations
    </a>
  ) : (
    <a
      className="btn btn-sm btn-outline-secondary"
      href={`/test_groups/${slug}/case_reports`}
      style={{ whiteSpace: 'nowrap' }}
    >
      Case reports
    </a>
  );

const ThirdQuickLinkCellComponent = ({ slug }) => (
  <a
    className="btn btn-sm btn-outline-secondary"
    href={`/test_groups/${slug}/edit`}
    style={{ minWidth: 'max-content' }}
  >
    Edit
  </a>
);

const PinnedCellComponent = ({ pinned, slug }) =>
  pinned ? (
    <a
      rel="nofollow"
      data-method="delete"
      href={`/test_groups/${slug}/pinned_test_groups`}
    >
      <i className="fa-solid fa-star icon-yellow"></i>
    </a>
  ) : (
    <a
      rel="nofollow"
      data-method="post"
      href={`/test_groups/${slug}/pinned_test_groups`}
    >
      <i className="fa-regular fa-star text-secondary"></i>
    </a>
  );

const PendingTrainingLink = ({ organizationUser: { organizationId, id } }) => (
  <a
    href={`/organizations/${organizationId}/organization_users/${id}/training`}
    className="text-warning"
  >
    You must complete training before accessing this group.
  </a>
);

const RowComponent = ({
  bulkEditEnabled,
  selectedTestGroupIds,
  toggleSelection,
  ...props
}) => {
  const {
    organizationUser: { role, trainingCompleted, securityDelegate },
    demo,
  } = props;
  const hasPendingTraining = !demo && !trainingCompleted && !securityDelegate;

  return (
    <tr>
      {bulkEditEnabled && (
        <td style={{ width: '1%' }}>
          <InputCellComponent
            {...props}
            selectedTestGroupIds={selectedTestGroupIds}
            toggleSelection={toggleSelection}
          />
        </td>
      )}
      <td style={{ width: '20%' }}>
        <NameCellComponent {...props} />
      </td>
      <td style={{ width: '20%' }}>
        <ServicesCellComponent {...props} />
      </td>
      <td style={{ width: '20%' }}>
        <OwningOrganizationCellComponent {...props} />
      </td>
      <td>
        <GroupStatus {...props} />
      </td>
      {hasPendingTraining ? (
        <td className="align-middle" colSpan={4}>
          <PendingTrainingLink {...props} />
        </td>
      ) : (
        <React.Fragment>
          <td className="align-middle small-width">
            <FirstQuickLinkCellComponent {...props} />
          </td>
          <td className="align-middle small-width">
            <SecondQuickLinkCellComponent {...props} />
          </td>
          <td className="align-middle small-width">
            <ThirdQuickLinkCellComponent {...props} />
          </td>
          <td className="align-middle small-width">
            <PinnedCellComponent {...props} />
          </td>
        </React.Fragment>
      )}
    </tr>
  );
};

const SelectableTable = ({
  testGroups,
  loading,
  bulkEditEnabled,
  selectedTestGroupIds,
  setSelectedTestGroupIds,
}) => {
  const [visibleSelected, setVisibleSelected] = useState(false);

  useEffect(() => {
    setVisibleSelected(
      testGroups
        .map((tg) => selectedTestGroupIds.includes(tg.id))
        .reduce((a, b) => a && b, true),
    );
  }, [testGroups, selectedTestGroupIds]);

  const toggleSelection = (id, checked) => {
    const newSelection = checked
      ? [...selectedTestGroupIds, id]
      : selectedTestGroupIds.filter((tgId) => tgId !== id);
    setSelectedTestGroupIds(newSelection);
  };

  const toggleVisible = (checked) => {
    if (checked) {
      const additionalSelections = testGroups
        .filter((tg) => !selectedTestGroupIds.includes(tg.id))
        .map((tg) => tg.id);
      setSelectedTestGroupIds([
        ...selectedTestGroupIds,
        ...additionalSelections,
      ]);
    } else {
      const newSelection = selectedTestGroupIds.filter(
        (tgId) => !testGroups.map((tg) => tg.id).includes(tgId),
      );
      setSelectedTestGroupIds(newSelection);
    }
    setVisibleSelected(checked);
  };

  return (
    <section className="fabrx-tables-light table-responsive">
      <LoadingOverlay loading={loading}>
        <Table
          id="students-index-table"
          headers={[
            ...(bulkEditEnabled
              ? [
                  {
                    name: 'mark all',
                    HeaderComponent: () => (
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="select-all-checkbox"
                        checked={visibleSelected}
                        onChange={(e) => toggleVisible(e.target.checked)}
                        data-selected-ids={selectedTestGroupIds.join(',')}
                      />
                    ),
                  },
                ]
              : []),
            { name: 'Name' },
            { name: 'Services' },
            { name: 'Owning organization' },
            { name: 'Status' },
            { name: '', colSpan: 4 },
          ]}
          RowComponent={(props) => (
            <RowComponent
              bulkEditEnabled={bulkEditEnabled}
              selectedTestGroupIds={selectedTestGroupIds}
              toggleSelection={toggleSelection}
              {...props}
            />
          )}
          data={testGroups}
        />
      </LoadingOverlay>
    </section>
  );
};

export default SelectableTable;
