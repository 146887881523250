import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import styled from 'styled-components';
import { Individual } from '../common/components/Icons/Individual';
import { List } from '../common/components/Icons/List';
import { PaperPlane } from '../common/components/Icons/PaperPlane';
import { Report } from '../common/components/Icons/Report';
import { TestTube } from '../common/components/Icons/TestTube';
import { Shield } from '../common/components/Icons';
import { ShieldVirus } from '../common/components/Icons/ShieldVirus';

const IconCircleWrapper = styled.div`
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid black;
  overflow: hidden;
  background: #f7fbfd;
  border: 1.5px solid var(--bs-primary);
`;

const Pill = ({ display_name, ...props }) => (
  <span
    className="d-inline-block text-primary px-2 py-1 mt-1 border border-primary"
    style={{ borderRadius: 18 }}
    {...props}
  >
    {display_name}
  </span>
);

const TestConfigurationList = ({ testConfigurations }) => {
  const [open, setOpen] = useState(false);
  const partitionAt = 5;
  const shouldCollapse = testConfigurations.length > partitionAt;

  return (
    <React.Fragment>
      {testConfigurations
        .slice(0, partitionAt)
        .map((test_configuration, idx) => (
          <Pill key={idx} {...test_configuration} />
        ))}
      {shouldCollapse && (
        <React.Fragment>
          <Collapse in={open}>
            <div>
              {testConfigurations
                .slice(partitionAt)
                .map((test_configuration, idx) => (
                  <Pill key={idx} {...test_configuration} />
                ))}
            </div>
          </Collapse>
          <Pill
            display_name={open ? 'Less...' : 'More...'}
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const ActionCardContainer = styled.div`
  border-width: 2px;

  &:active,
  &:hover,
  &:focus {
    background-color: #e9f1fb;
  }

  &:focus {
    box-shadow: #8fadf8 0 0 0 3px;
  }
`;

const ActionCard = ({
  Icon: IconComponent,
  iconStyles = {},
  header,
  children,
  onClick,
}) => (
  <ActionCardContainer
    className={`card border border-primary ${onClick ? 'pointer' : ''}`}
    onClick={onClick}
    tabIndex={0}
  >
    <div className="card-body">
      {IconComponent && (
        <IconCircleWrapper>
          <IconComponent
            className="text-primary"
            color="var(--bs-primary)"
            style={{ minWidth: 25, maxHeight: 25, ...iconStyles }}
          />
        </IconCircleWrapper>
      )}
      <div className="mt-2 mb-1 h5">{header}</div>
      <div>{children}</div>
    </div>
  </ActionCardContainer>
);

const redirectTo = (url) => () => {
  window.location = url;
};

const DailyOperations = ({
  test_group: {
    on_demand_only,
    active_test_configurations_count,
    extensions,
    appointments_path,
    participant_list_path,
    send_communication_path,
    test_configurations,
    abbott_results_path,
    case_reports_path,
    errored_case_reports_path,
    statistics_path,
    immunizations_path,
    show_lots,
    show_immunization_manager,
    more_than_five_groups,
    appointment_slot_links_first,
    appointment_slot_group_links_last,
  },
}) => (
  <div className="row mb-2 justify-content-center">
    <div className="col-lg-4 col-md-6 col-sm mt-4">
      {extensions.student_profiles ? (
        <ActionCard
          Icon={Individual}
          header="View student roster"
          onClick={redirectTo(
            on_demand_only ? participant_list_path : appointments_path,
          )}
        >
          <p className="card-text">
            View your school's student roster here
            {active_test_configurations_count === 0
              ? '.'
              : ', and checkout students as well.'}
          </p>
        </ActionCard>
      ) : (
        <ActionCard
          Icon={Individual}
          header="Manage and checkout participants"
          onClick={redirectTo(
            on_demand_only ? participant_list_path : appointments_path,
          )}
        >
          <p className="card-text">
            View participants list, including full Checkout ability
          </p>
        </ActionCard>
      )}
    </div>
    {test_configurations.length !== 0 && (
      <div className="col-lg-4 col-md-6 col-sm mt-4">
        <ActionCard
          Icon={TestTube}
          header="Record test results"
          onClick={redirectTo('/abbott')}
        >
          <p className="card-text">Input test results for POC services</p>
          <TestConfigurationList testConfigurations={test_configurations} />
        </ActionCard>
      </div>
    )}
    <div className="col-lg-4 col-md-6 col-sm mt-4">
      <ActionCard
        Icon={Report}
        header="View case reports"
        onClick={redirectTo(case_reports_path)}
      >
        <p className="card-text">View tests results and vaccination records</p>
        {errored_case_reports_path && (
          <span class="badge rounded-pill bg-danger">Errors found</span>
        )}
      </ActionCard>
    </div>
    <div className="col-lg-4 col-md-6 col-sm mt-4">
      <ActionCard
        Icon={PaperPlane}
        iconStyles={{ marginRight: 2 }}
        header="Send messages to participants"
        onClick={redirectTo(send_communication_path)}
      >
        <p className="card-text">
          Send custom email/SMS to participants to register, record a test,
          reminders, and more
        </p>
      </ActionCard>
    </div>
    <div className="col-lg-4 col-md-6 col-sm mt-4">
      <ActionCard
        Icon={List}
        iconStyles={{ padding: 3 }}
        header="View dashboards"
        onClick={redirectTo(statistics_path)}
      >
        View data visualizations on daily operations
      </ActionCard>
    </div>
    {show_lots && (
      <div className="col-lg-4 col-md-6 col-sm mt-4">
        <ActionCard Icon={Shield} header={"Today's vaccine lots"}>
          <p className="card-text">
            Activate and deactivate vaccine lots for your locations <br />
            {appointment_slot_links_first.map((link) => (
              <div>
                <a href={link.link}>{link.title}</a>
              </div>
            ))}
            {more_than_five_groups && (
              <>
                <div id="show-more-lots" className="collapse mt-2">
                  {appointment_slot_group_links_last.map((link) => (
                    <div>
                      <a href={link.link}>{link.title}</a>
                    </div>
                  ))}
                </div>
                <a
                  id="show-more-lots-button"
                  className="button default ok mt-2 font-weight-bold"
                  href="#show-more-lots"
                  data-bs-toggle="collapse"
                  aria-expanded="false"
                >
                  Show more +
                </a>
              </>
            )}
          </p>
        </ActionCard>
      </div>
    )}
    {show_immunization_manager && (
      <div className="col-lg-4 col-md-6 col-sm mt-4">
        <ActionCard
          Icon={ShieldVirus}
          header="Immunization Management"
          onClick={redirectTo(immunizations_path)}
        >
          View your students immunization records and manage student gaps
        </ActionCard>
      </div>
    )}
  </div>
);

export default DailyOperations;
