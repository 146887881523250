import React from 'react';

export const Report = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 17.992411 23.42721"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_157_10228)" transform="translate(-11.33569,-8)">
      <path
        d="M 28.5783,14.6063 23.2367,8.74933 m 5.3416,5.85697 v 15.3219 c 0,0.4141 -0.3357,0.7497 -0.7497,0.7497 H 12.8347 c -0.4141,0 -0.7497,-0.3356 -0.7497,-0.7497 V 9.49902 c 0,-0.41404 0.3356,-0.74969 0.7497,-0.74969 h 10.402 z m 0,0 h -4.5919 c -0.414,0 -0.7497,-0.3356 -0.7497,-0.7497 V 8.74933 Z"
        stroke={color}
        strokeWidth="1.49862"
      />
      <path
        d="M 14.4282,20.5574 H 24.4554"
        stroke={color}
        strokeWidth="1.49862"
        strokeLinecap="round"
      />
      <path
        d="M 14.4282,23.931 H 24.4554"
        stroke={color}
        strokeWidth="1.49862"
        strokeLinecap="round"
      />
      <path
        d="M 14.4282,27.3047 H 24.4554"
        stroke={color}
        strokeWidth="1.49862"
        strokeLinecap="round"
      />
    </g>
    <defs id="defs24">
      <clipPath id="clip0_157_10228">
        <rect
          width="17.992701"
          height="23.427999"
          fill="#ffffff"
          transform="translate(11.3354,8)"
          x="0"
          y="0"
        />
      </clipPath>
    </defs>
  </svg>
);
