import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Card, Alert, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

import '../../../common/locales/i18n';
import GoogleMapsWidget from '../../../common/components/GoogleMapsWidget';
import Slotter from './scheduling/calculator';
import AppointmentSlotSelector from './scheduling/AppointmentSlotSelector';
import { buildFirstAppointmentSlots } from './AppointmentSlot'
import CollapsableAppointmentSlotGroupSelector from './scheduling/CollapsableAppointmentSlotGroupSelector';
import { buildValidAppointmentSlots, buildValidAppointmentSlotGroups } from './scheduling/FollowUpAppointmentSlotSelector';
import { SectionTitle } from '../SectionComponents';

export const DoubleAppointmentSlotComponent = ({
  firstAppointment,
  secondAppointment,
  setFirstAppointment,
  setSecondAppointment,
  testGroup,
  originalData,
  showForceReschedule
}) => {
  const { t, i18n } = useTranslation();
  const [appointmentSlotGroupsLoaded, setAppointmentSlotGroupsLoaded] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const availabilitiesParams = {
      key: firstAppointment.registration_access_key || urlParams.get("key"),
    };
    axios.post(
      `/test_groups/${testGroup.slug}/appointment_slot_availabilities`,
      availabilitiesParams,
    ).then(({ data }) => {
      testGroup.appointment_slot_groups = data.appointment_slot_groups;
      setAppointmentSlotGroupsLoaded(true);
    });
  }, []);

  let firstAppointmentSlotGroup = testGroup.appointment_slot_groups.find((asg) => (
    asg.id === firstAppointment.appointment_slot_group_id
  ));
  const secondAppointmentSlotGroup = testGroup.appointment_slot_groups.find((asg) => (
    asg.id === secondAppointment.appointment_slot_group_id
  ));
  const activeAppointmentSlotGroups = testGroup.appointment_slot_groups.filter(a => a.active);

  const slotter = new Slotter({...testGroup, appointment_slot_groups: activeAppointmentSlotGroups});

  let secondAppointmentSlotGroups = [];
  if (firstAppointmentSlotGroup) {
    secondAppointmentSlotGroups = slotter.followUpAppointmentSlotGroups(firstAppointmentSlotGroup);
  }

  let firstAppointmentSlots = [];
  let secondAppointmentSlots = [];

  if (originalData?.appointment_1.appointment_slot_group_id === firstAppointmentSlotGroup?.id) {
    firstAppointmentSlots = [{
      id: null,
      localized_day: originalData.appointment_1.localized_day,
      localized_starts_at: `${originalData.appointment_1.original_time} (Your Booked Appointment)`,
      starts_at: originalData.appointment_1.original_starts_at
    }];
  }
  if (originalData?.appointment_2.appointment_slot_group_id === secondAppointmentSlotGroup?.id &&
      secondAppointmentSlotGroups.some(x => x.id === secondAppointmentSlotGroup?.id)) {
    secondAppointmentSlots = [{
      id: null,
      localized_day: originalData.appointment_2.localized_day,
      localized_starts_at: `${originalData.appointment_2.original_time} (Your Booked Appointment)`,
      starts_at: originalData.appointment_2.original_starts_at
    }];
  }

  if (firstAppointmentSlotGroup) {
    firstAppointmentSlots = firstAppointmentSlots.concat(slotter.availableSlotsForAppointmentSlotGroup(firstAppointmentSlotGroup));

    const firstAppointmentSlot = firstAppointmentSlots?.find(
      appointmentSlot => appointmentSlot.id === firstAppointment.appointment_slot_id
    );

    if (firstAppointmentSlot && secondAppointmentSlotGroup) {
      secondAppointmentSlots = secondAppointmentSlots.concat(slotter.followUpAppointmentSlots(firstAppointmentSlot, secondAppointmentSlotGroup));
    }
  }

  const appointmentTitle = (appointment, originalAppointment) => (
    <div>
      <b>
        {appointment.resulted ? t('follow_up.previous_appointment'): t('follow_up.booked_appointment')}:
      </b> {' '}
      {originalAppointment.original_appointment_slot_group_title} - {originalAppointment.original_time}
    </div>
  )

  return (
    <div className="form-section">
      <Card body className="my-3">
        <SectionTitle>{t('follow_up.first_dose')}</SectionTitle>
        { originalData.appointment_1.original_time &&
          appointmentTitle(firstAppointment, originalData.appointment_1)
        }
        { !firstAppointment.resulted &&
          <Row>
            <Col xs={12} lg={6} className="mb-3">
              <div className="h6">{ t('registration.choose_location') }</div>
              <CollapsableAppointmentSlotGroupSelector
                selectedAppointmentSlotGroupId={firstAppointmentSlotGroup?.id}
                appointmentSlotGroups={activeAppointmentSlotGroups}
                onSelect={(s) => {
                  setFirstAppointment({
                    ...firstAppointment,
                    appointment_slot_group_id: s.value,
                    appointment_slot_id: null
                  })

                  if (s.value !== originalData.appointment_1.appointment_slot_group_id){
                    setSecondAppointment({
                      ...secondAppointment,
                      appointment_slot_group_id: null,
                      appointment_slot_id: null,
                    })
                  }
                }}
                collapsed={true}
              />
              { firstAppointmentSlotGroup &&
                <div className="my-3">
                  {appointmentSlotGroupsLoaded
                    ?
                      <AppointmentSlotSelector
                        onSelect={(s) => {
                          setFirstAppointment({
                            ...firstAppointment,
                            appointment_slot_id: s.value,
                          })
                        }}
                        appointmentSlotId={firstAppointment.appointment_slot_id}
                        appointmentSlotGroup={firstAppointmentSlotGroup}
                        appointmentSlots={firstAppointmentSlots}
                        allowWaitlist={false}
                      />
                    :
                      <Spinner animation="border" size="sm" />
                  }
                </div>
              }
            </Col>
            <Col xs={12} lg={6} className="tab-pane fade show text-center">
              { firstAppointmentSlotGroup?.location_type === "location" &&
                firstAppointmentSlotGroup?.show_map_on_registration &&
                <GoogleMapsWidget
                  title={firstAppointmentSlotGroup.title}
                  address={firstAppointmentSlotGroup.address}
                  marker={firstAppointmentSlotGroup}
                />
              }
            </Col>
          </Row>
        }
        {showForceReschedule && (
          <a
            className="text-danger"
            href={originalData.appointment_1.force_reschedule_path}
          >
            Click Here to force reschedule
          </a>
        )}
      </Card>
      <Card body className="my-3">
        <SectionTitle>{t('follow_up.second_dose')}</SectionTitle>
        { originalData.appointment_2.original_time &&
          appointmentTitle(secondAppointment, originalData.appointment_2)
        }
        { !secondAppointment.resulted &&
          <Row>
            <Col xs={12} lg={6} className="mb-3">
              <div className="h6">{ t('registration.choose_location') }</div>
              <CollapsableAppointmentSlotGroupSelector
                selectedAppointmentSlotGroupId={secondAppointmentSlotGroup?.id}
                appointmentSlotGroups={secondAppointmentSlotGroups}
                onSelect={(s) => {
                  setSecondAppointment({
                    ...secondAppointment,
                    appointment_slot_group_id: s.value,
                    appointment_slot_id: null
                  })
                }}
                collapsed={true}
              />
              <div className="my-3">
                { appointmentSlotGroupsLoaded
                  ?
                    secondAppointmentSlots &&
                      <AppointmentSlotSelector
                        onSelect={(s) => {
                          setSecondAppointment({
                            ...secondAppointment,
                            appointment_slot_id: s.value,
                          })
                        }}
                        appointmentSlotId={secondAppointment.appointment_slot_id}
                        appointmentSlotGroup={secondAppointmentSlotGroup}
                        appointmentSlots={secondAppointmentSlots}
                        allowWaitlist={false}
                      />
                  :
                    <Spinner animation="border" size="sm" />
                }
              </div>
            </Col>
            <Col xs={12} lg={6} className="tab-pane fade show text-center">
              { secondAppointmentSlotGroup?.location_type === "location" &&
                secondAppointmentSlotGroup?.show_map_on_registration &&
                <GoogleMapsWidget
                  title={secondAppointmentSlotGroup.title}
                  address={secondAppointmentSlotGroup.address}
                  marker={secondAppointmentSlotGroup}
                />
              }
            </Col>
          </Row>
        }
        {showForceReschedule && (
          <a
            className="text-danger"
            href={originalData.appointment_2.force_reschedule_path}
          >
            Click Here to force reschedule
          </a>
        )}
      </Card>
    </div>
  );
};

const DoubleAppointmentSlotForm = ({
  redirectTo = null,
  showForceReschedule = false,
  ...props
}) => {
  const [firstAppointment, setFirstAppointment] = useState({...props.appointments.appointment_1, appointment_slot_id: null});
  const [secondAppointment, setSecondAppointment] = useState({...props.appointments.appointment_2, appointment_slot_id: null});

  const [error, setError] = useState("");
  const errorRef = useRef(null);

  const onSubmit = (values, url) => {
    axios.put(url, values)
      .then(response => {
        location.href = redirectTo || response.data.appointment_1_url;
      })
      .catch(err => {
        if (err.response.data && err.response.data.error_message) {
          const errorMessage = err.response.data.error_message;
          setError(errorMessage);
          errorRef.current.scrollIntoView()
        } else {
          setError("Something went wrong");
        }
        console.info(err + " url: " + err)
      });
  }

  return (
    <Card body className="mb-6">
      { error && <Alert variant="danger" ref={errorRef}>{error}</Alert>}
      <DoubleAppointmentSlotComponent
        firstAppointment={firstAppointment}
        secondAppointment={secondAppointment}
        setFirstAppointment={setFirstAppointment}
        setSecondAppointment={setSecondAppointment}
        testGroup ={props.test_group}
        originalData={props.appointments}
        showForceReschedule={showForceReschedule}
      />
      <Button onClick={() => onSubmit({appointment_1: firstAppointment, appointment_2: secondAppointment}, props.test_group.submit_url)}>Submit</Button>
    </Card>
  )
};

export default DoubleAppointmentSlotForm;
