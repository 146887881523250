import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import ParticipantCard from './ParticipantCard';

import CheckoutButtons from './participants/CheckoutButtons';
import CheckInModal from './participants/CheckInModal';
import { FollowUpRescheduleModal } from '../../Instruction/components/instructions/NewShow';

const Participants = (props) => {
  const [appointment, setAppointment] = useState(null);
  const [showCheckInModal, setShowCheckInModal] = useState(false);
  const [showRescheduleFollowUpModal, setShowRescheduleFollowUpModal] =
    useState(false);

  const closeRescheduleFollowUpModal = () =>
    setShowRescheduleFollowUpModal(false);
  const openRescheduleFollowUpModal = () =>
    setShowRescheduleFollowUpModal(true);

  const onCheckIn = (appointment) => {
    setAppointment(appointment);
    setShowCheckInModal(true);
  };

  const onRescheduleFollowUp = (appointment) => {
    setAppointment(appointment);
    openRescheduleFollowUpModal();
  };

  return (
    <React.Fragment>
      <section className="fabrx-tables-light">
        <Table responsive className="table-lg">
          <tbody>
            {props.appointments.map((appointment) => {
              const notCheckoutEligible =
                !appointment.user.is_checkout_eligible &&
                !appointment.user.primary_user_id;
              return (
                <tr
                  key={appointment.id}
                  className="participant__page"
                  data-test-hook={`appointment-${appointment.id}`}
                >
                  <ParticipantCard
                    appointment={appointment}
                    key={appointment.id}
                  />
                  {!['archived', 'in_development'].includes(
                      appointment.test_group.status,
                    ) && (
                      <CheckoutButtons
                        appointment={appointment}
                        links={appointment.links}
                        notCheckoutEligible={notCheckoutEligible}
                        onCheckIn={onCheckIn}
                        onRescheduleFollowUp={onRescheduleFollowUp}
                      />
                    )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </section>
      {appointment && showCheckInModal &&
        <CheckInModal
          setShow={setShowCheckInModal}
          show={showCheckInModal}
          appointment={appointment}
          user={appointment.user}
          testConfigurations={appointment.test_configurations || []}
          symptoms={props.covid_symptoms}
          testGroup={appointment.test_group}
          covid19Vaccination={appointment.covid_19_vaccination}
        />
      }
      {appointment && appointment.follow_up_props && (
        <FollowUpRescheduleModal
          show={showRescheduleFollowUpModal}
          onHide={closeRescheduleFollowUpModal}
          appointment={appointment}
          follow_up_props={JSON.parse(appointment.follow_up_props)}
          redirectTo={appointment.on_reschedule_redirect_path}
          showForceReschedule
        />
      )}
    </React.Fragment>
  );
};

export default Participants;
