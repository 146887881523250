import React from 'react';
import { Badge } from 'react-bootstrap';

const AppointmentInfo = ({
  appointment,
  links,
  appointmentSlotGroup,
  appointmentSlot,
}) => {

  const archiveBadge = (archivedStatus) => {
    if (!!archivedStatus) {
      switch (archivedStatus) {
        case "canceled":
          return "CANCELED"
        case "completed":
          return "COMPLETED"
        case "no_show":
          return "NO SHOW"
        case "expired":
          return "EXPIRED"
        case "manual":
          return "ADMIN ARCHIVED"
        case "processing":
          return "PROCESSING"
        default:
          return "ARCHIVED"
      }
    }
  }

  const onDemandAndNotAdministeredOrPreviousNotAdministered =
    appointmentSlot.on_demand && !(appointment.administered || appointment.previous_dose_administered);

  const shouldShowTime = !onDemandAndNotAdministeredOrPreviousNotAdministered;

  return (
    <a href={ links.appointment }>
      { shouldShowTime && appointmentSlot.localized_starts_at }
      <div>{ appointmentSlotGroup.title }</div>
      { !!appointment.archived_status &&
        <Badge className="bg-warning text-dark">{ archiveBadge(appointment.archived_status) }</Badge>
      }
      { " " }
      { appointment.payment_state &&
        <Badge
          className={`bg-${appointment.payment_state == "Paid" ? "success" : "warning"}`}
        >
          {appointment.payment_state}
        </Badge>
      }
    </a>
  )
}

export default AppointmentInfo;
