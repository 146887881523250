import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';

import MultiplexSubTests from './MultiplexSubTests';
import { MultiplexResults } from '../../shared_labs/multiplex_results';

const MultiplexResultRow = ({ test, negativeHighlight }) => {
  const resultRenderer = (results) => {
    return <MultiplexSubTests {...{results, negativeHighlight}}></MultiplexSubTests>
  };
  const multiplexResults = new MultiplexResults(test, {
    renderers: {
      ungrouped: resultRenderer,
      grouped: ({groupId, groupLabel, results}) => {
        return (
          <Row
            key={groupId}
            className="mx-1 border-end border-start border-bottom py-2"
            data-testid={`group-${groupId}`}
          >
            <Col>
              <h5>{groupLabel}</h5>
              {resultRenderer(results)}
            </Col>
          </Row>
        );
      }
    }
  });

  return (
    <React.Fragment>
      {multiplexResults.fixedResults() || multiplexResults.dynamicResults()}
      {test.lab_report_attached && (
        <Row
          key="lab_report"
          className="mx-1 py-3 border-end border-start border-bottom"
        >
          <Col>
            <a href={test.lab_report_url}>
              <FontAwesomeIcon icon={faPrint} /> Lab Report
            </a>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default MultiplexResultRow;
