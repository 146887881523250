import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import OldDateOfBirthInput from "../common/components/OldDateOfBirthInput";
import DateOfBirthWithDropdown from "../common/components/DateOfBirthWithDropdown";
import { useTranslation } from 'react-i18next';
import FloatingLabelInput from '../common/components/FloatingLabelInput';
import axios from "axios";

export default (props) => {
  const [dateOfBirth, setDateOfBirth] = useState({day: null, month: null, year: null});
  const [values, setValues] = useState({
    access_code: props.data.access_code,
  });
  const { t, i18n } = useTranslation();
  const vaccineUploadEndpointUrl = window.location.origin + "/v/" + props.testGroupId
  const [authError, setAuthError] = useState(false)

  const submit = () => {
    const authentication = {
      date_of_birth: new Date(dateOfBirth.year, dateOfBirth.month - 1, dateOfBirth.day),
      last_name: values.last_name,
      access_code: values.access_code,
    }
    const formParams = new URLSearchParams(authentication);
    axios
      .get(
        vaccineUploadEndpointUrl + "?" + formParams.toString(),
        { headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' } },
      )
      .then(response => {
        if(response.data.user_id){
          // SET VERIFIED-GOOD AUTH PARAMS IN DATA ON PARENT COMPONENT
          props.update('authParams', authentication)
          props.update('vaccineData', JSON.parse(response.data.vaccine_uploader_props).vaccine_data)
          props.update('id', JSON.parse(response.data.vaccine_uploader_props).id)
          setAuthError(false)
          props.onComplete()
        } else {
          // DISPLAY ERROR FOR USER
          setAuthError(true)
        }
      })
  }

  return (
    <div className="text-center">
      <h3 className="my-5">{t('vaccination_status.enter_credentials')}</h3>
      <FloatingLabelInput
        name="access_code"
        className="my-3"
        value={values.access_code || ""}
        onChange={(e) => setValues({...values, access_code: e.target.value}) }
        ariaLabel="access_code"
        ariaRequired={true}
        ariaInvalid={!!values.access_code}
        label={t('result_page.landing.access_code_label') + " *"}
      />

      <FloatingLabelInput
        name="last_name"
        className="my-3"
        value={values.last_name || ""}
        onChange={(e) => setValues({...values, last_name: e.target.value}) }
        ariaLabel="last_name"
        ariaRequired={true}
        ariaInvalid={!!values.last_name}
        label={t('registration.last_name_label') + " *"}
      />

      <div className="mt-4">
        <OldDateOfBirthInput currentDate={dateOfBirth} setCurrentDate={setDateOfBirth} />
      </div>
      {authError && <div className="text-danger">{t('vaccination_status.incorrect_credentials')}</div>}

      <div className="my-5">
        <Button
          block
          data-test-hook="submit"
          onClick={submit}
        >
          {t('vaccination_status.continue')}
        </Button>
      </div>
    </div>
  )
}
