import React from 'react';

export const Individual = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 39.316002 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g transform="matrix(5.999299,0,0,5.999299,-502.53427,-507.31044)">
      <path
        d="m 88.263377,88.601568 c 0.57241,-0.39433 0.9413,-1.04307 0.9413,-1.78085 0,-1.19571 -0.96674,-2.16246 -2.16246,-2.16246 -1.19571,0 -2.16246,0.96675 -2.16246,2.16246 0,0.73778 0.36889,1.38652 0.94131,1.78085 -1.13211,0.35617 -1.95893,1.42468 -1.95893,2.67127 v 1.52645 h 6.36017 v -1.52645 c 0,-1.24659 -0.82682,-2.3151 -1.95893,-2.67127 z m -2.8748,-1.78085 c 0,-0.91587 0.73778,-1.65365 1.65364,-1.65365 0.91587,0 1.65365,0.73778 1.65365,1.65365 0,0.91587 -0.73778,1.65365 -1.65365,1.65365 -0.91586,0 -1.65364,-0.73778 -1.65364,-1.65365 z m 4.32492,5.46975 h -5.34255 v -1.01763 c 0,-1.25931 1.03035,-2.28966 2.28966,-2.28966 h 0.76322 c 1.25932,0 2.28967,1.03035 2.28967,2.28966 z"
        style={{
          fill: color,
          fillOpacity: 1,
          fillRule: 'evenodd',
        }}
      />
    </g>
  </svg>
);
