import { DateObject } from 'react-multi-date-picker';
import { differenceInDays, differenceInMonths, differenceInYears } from 'date-fns';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import axios from 'axios';

export const waitFor = (delay) =>
  new Promise((resolve) => setTimeout(resolve, delay));

export function createUuid() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16);
    },
  );
  return uuid;
}

export const sumProperty = (array, prop) => {
  return array.map((obj) => obj[prop]).reduce((a, b) => a + (b || 0), 0);
};

export const sum = (l) => l.reduce((partialSum, a) => partialSum + a, 0);

export const formatNumberWithCommas = (n) =>
  n?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const calculateAge = (dateOfBirth) => {
  const today = new Date();
  return differenceInYears(today, dateOfBirth);
};

export const calculateAgeInDays = (dateOfBirth) =>
  differenceInDays(new Date(), dateOfBirth);

export const calculateAgeInMonths = (dateOfBirth) =>
  differenceInMonths(new Date(), dateOfBirth);

export const dateStringToDate = (dateString) => {
  const dateObject = new DateObject(dateString);
  return (
    new Date(
      dateObject.year,
      dateObject.month.index,
      dateObject.day
    )
  );
};

export const ageInMonthsYear = (ageInMonths) =>
  ageInMonths && Math.floor(ageInMonths / 12);

export const ageInMonthsRemainder = (ageInMonths) =>
  ageInMonths && ageInMonths % 12;

export const today = (day) => isToday(parseISO(day));

export const humanize = (str) =>
  str &&
  str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });

export const loadAppointmentSlots = ({
  accessKey,
  testGroupSlug,
  onLoad,
  accessCode = null,
  testLocationId = null,
}) => {
  const availabilitiesParams = { test_location_id: testLocationId };
  if (accessKey) availabilitiesParams.key = accessKey;
  if (accessCode) availabilitiesParams.access_code = accessCode;
  axios
    .post(
      `/test_groups/${testGroupSlug}/appointment_slot_availabilities`,
      availabilitiesParams,
    )
    .then(({ data }) => {
      onLoad(data);
    });
};

export const validateEmail = (email) =>
  RegExp(
    /^[a-z0-9._-]+@[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/i,
  ).test(email);

export const withinAgeRange = (age, minimumAge, maximumAge) => {
  if (age) {
    if (minimumAge && age < minimumAge) return false;
    if (maximumAge && age > maximumAge) return false;
  }
  return true;
};

export const dataURItoBlob = (dataURI, makeToFile = false) => {
  // convert base64 to raw binary data held in a string
  var byteString = atob(dataURI.split(',')[1]);

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  if (makeToFile) {
    return new File([ab], 'webcam_photo.jpg', {
      type: 'image/jpeg',
      lastModified: new Date().getTime(),
    });
  }
  return new Blob([ab], { type: mimeString });
};

export const languageObject = {
  am: 'አማርኛ',
  ar: 'العربية',
  bn: 'বাঙালি',
  en: 'English',
  es: 'Español',
  fa: 'فارسی',
  fr: 'Français',
  hat: 'Kreyòl ayisyen',
  hi: 'हिन्दी, हिंदी',
  hmn: 'Hmong',
  hy: 'հայերէն',
  ja: '日本',
  kar: 'Karen',
  ko: '한국어',
  lo: 'ລາວ',
  my: 'မြန်မာဘာသာ',
  ne: 'नेपाली',
  om: 'Afaan Oromoo',
  ru: 'русский',
  so: 'Soomaali',
  tl: 'Tagalog',
  uk: 'українська',
  ur: 'اردو',
  vi: 'Tiếng Việt',
  zh: '中文',
};

export const countryCodeObject = {
  AD: 'ca-AD',
  AE: 'ar-AE',
  AF: 'fa-AF',
  AG: 'en-AG',
  AI: 'en-AI',
  AL: 'sq-AL',
  AM: 'am-Am',
  AN: 'any',
  AO: 'pt-AO',
  AR: 'es-AR',
  AS: 'any',
  AT: 'de-AT',
  AU: 'en-AU',
  AW: 'nl-AW',
  AX: 'any',
  AZ: 'az-AZ',
  BA: 'bs-BA',
  BB: 'any',
  BD: 'bn-BD',
  BE: 'fr-BE',
  BF: 'fr-BF',
  BG: 'bg-BG',
  BH: 'ar-BH',
  BI: 'any',
  BJ: 'fr-BJ',
  BM: 'en-BM',
  BN: 'any',
  BO: 'es-BO',
  BR: 'pt-BR',
  BS: 'en-BS',
  BT: 'dz-BT',
  BW: 'en-BW',
  BY: 'be-BY',
  BZ: 'any',
  CA: 'en-CA',
  CC: 'any',
  CD: 'any',
  CF: 'any',
  CG: 'any',
  CH: 'de-CH',
  CI: 'any',
  CK: 'any',
  CL: 'es-CL',
  CM: 'any',
  CN: 'zh-CN',
  CO: 'es-CO',
  CR: 'es-CR',
  CU: 'es-CU',
  CV: 'any',
  CX: 'any',
  CY: 'el-CY',
  CZ: 'cs-CZ',
  DE: 'de-DE',
  DJ: 'any',
  DK: 'da-DK',
  DM: 'any',
  DO: 'es-DO',
  DZ: 'ar-DZ',
  EC: 'es-EC',
  EE: 'et-EE',
  EG: 'ar-EG',
  ER: 'any',
  ES: 'es-ES',
  ET: 'any',
  FI: 'fi-FI',
  FJ: 'fj-FJ',
  FK: 'any',
  FM: 'any',
  FO: 'fo-FO',
  FR: 'fr-FR',
  GA: 'any',
  GB: 'en-GB',
  GD: 'any',
  GE: 'ka-GE',
  GF: 'fr-GF',
  GG: 'en-GG',
  GH: 'en-GH',
  GI: 'any',
  GL: 'kl-GL',
  GM: 'any',
  GN: 'any',
  GP: 'fr-GP',
  GQ: 'any',
  GR: 'el-GR',
  GT: 'any',
  GU: 'any',
  GW: 'any',
  GY: 'en-GY',
  HK: 'en-HK',
  HN: 'es-HN',
  HR: 'any',
  HT: 'any',
  HU: 'hu-HU',
  ID: 'id-ID',
  IE: 'en-IE',
  IL: 'he-IL',
  IM: 'any',
  IN: 'en-IN',
  IO: 'any',
  IQ: 'ar-IQ',
  IR: 'ir-IR',
  IS: 'any',
  IT: 'it-IT',
  JE: 'any',
  JM: 'en-JM',
  JO: 'ar-JO',
  JP: 'ja-JP',
  KE: 'en-KE',
  KG: 'ky-KG',
  KH: 'any',
  KI: 'en-KI',
  KM: 'any',
  KN: 'any',
  KP: 'any',
  KR: 'ko-KR',
  KW: 'ar-KW',
  KY: 'any',
  KZ: 'kk-KZ',
  LA: 'any',
  LB: 'az-LB',
  LC: 'any',
  LI: 'any',
  LK: 'si-LK',
  LR: 'any',
  LS: 'en-LS',
  LT: 'lt-LT',
  LU: 'de-LU',
  LV: 'any',
  LY: 'az-LY',
  MA: 'any',
  MC: 'any',
  MD: 'any',
  ME: 'any',
  MF: 'any',
  MG: 'mg-MG',
  MH: 'any',
  MK: 'any',
  ML: 'any',
  MM: 'my-MM',
  MN: 'mn-MN',
  MO: 'en-MO',
  MP: 'any',
  MQ: 'fr-MQ',
  MR: 'any',
  MS: 'any',
  MT: 'en-MT',
  MU: 'en-MU',
  MV: 'dv-MV',
  MW: 'any',
  MX: 'es-MX',
  MY: 'ms-MY',
  MZ: 'mz-MZ',
  NA: 'any',
  NC: 'any',
  NE: 'any',
  NF: 'any',
  NG: 'en-NG',
  NI: 'es-NI',
  NL: 'nl-NL',
  NO: 'nb-NO',
  NP: 'ne-NP',
  NR: 'any',
  NU: 'any',
  NZ: 'en-NZ',
  OM: 'any',
  PA: 'es-PA',
  PE: 'es-PE',
  PF: 'fr-PF',
  PG: 'en-PG',
  PH: 'en-PH',
  PK: 'en-PK',
  PL: 'pl-PL',
  PM: 'any',
  PN: 'any',
  PR: 'any',
  PS: 'ar-PS',
  PT: 'pt-PT',
  PW: 'any',
  PY: 'es-PY',
  QA: 'any',
  RE: 'fr-RE',
  RO: 'ro-RO',
  RS: 'sr-RS',
  RU: 'ru-RU',
  RW: 'en-RW',
  SA: 'ar-SA',
  SB: 'any',
  SC: 'any',
  SD: 'any',
  SE: 'sv-SE',
  SG: 'en-SG',
  SH: 'any',
  SI: 'sl-SI',
  SJ: 'any',
  SK: 'sk-SK',
  SL: 'en-SL',
  SM: 'it-SM',
  SN: 'any',
  SO: 'any',
  SR: 'any',
  ST: 'any',
  SV: 'es-SV',
  SY: 'ar-SY',
  SZ: 'any',
  TC: 'any',
  TD: 'any',
  TG: 'any',
  TH: 'th-TH',
  TJ: 'tg-TJ',
  TK: 'any',
  TL: 'any',
  TM: 'tk-TM',
  TN: 'ar-TN',
  TO: 'any',
  TR: 'tr-TR',
  TT: 'any',
  TV: 'any',
  TW: 'zh-TW',
  TZ: 'en-TZ',
  UA: 'uk-UA',
  UG: 'en-UG',
  US: 'en-US',
  UY: 'es-UY',
  UZ: 'uz-UZ',
  VA: 'any',
  VC: 'any',
  VE: 'es-VE',
  VG: 'any',
  VI: 'any',
  VN: 'vi-VN',
  VU: 'any',
  WF: 'any',
  WS: 'any',
  YE: 'ar-YE',
  YT: 'any',
  ZA: 'en-ZA',
  ZM: 'en-ZM',
  ZW: 'en-ZW',
};

export const US_TIME_ZONES = {
  'America/Puerto_Rico': 'Atlantic',
  'America/New_York': 'Eastern',
  'America/Chicago': 'Central',
  'America/Denver': 'Mountain',
  'America/Los_Angeles': 'Pacific',
  'Pacific/Honolulu': 'Hawaii-Aleutian Islands',
  'America/Adak': 'Hawaii-Aleutian Islands',
  'Pacific/Pago_Pago': 'American Samoa',
  'Pacific/Majuro': 'Marshall Islands',
  'Pacific/Guam': 'Guam',
  'Pacific/Palau': 'Palau',
  'Pacific/Pohnpei': 'Micronesia',
};

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export const formatBytes = (bytes, decimals = 1) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getClientTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const conversationMainIssues = [
  { label: 'Test', value: 'test' },
  { label: 'Vaccine', value: 'vaccine' },
  { label: 'Other', value: 'other' },
];

// https://stackoverflow.com/a/54246501
export const camelToSnakeCase = (str) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const stringToId = (label) => label?.toLowerCase()?.replace(/\s+/g, '-');
