import React from 'react';

export const List = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 15.70751 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m 2.9282,4.0574 h 8.03"
      stroke={color}
      strokeWidth="1.49862"
      strokeLinecap="round"
    />
    <path
      d="m 2.9282,1.0574 h 12.03"
      stroke={color}
      strokeWidth="1.49862"
      strokeLinecap="round"
    />
    <path
      d="M 2.9282,7.431 8.5,7.5"
      stroke={color}
      strokeWidth="1.49862"
      strokeLinecap="round"
    />
    <path
      d="M 2.9282,10.8047 H 12.9554"
      stroke={color}
      strokeWidth="1.49862"
      strokeLinecap="round"
    />
    <path d="M 0.5,11.5 V 0.5" stroke={color} strokeLinecap="round" />
  </svg>
);
