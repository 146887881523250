import React, { useReducer } from "react";
import axios from "axios";

import NewTestGroupTestConfigurationSelector from "./NewTestGroupTestConfigurationSelector";
import TestGroupTestConfigurationForm from "./TestGroupTestConfigurationForm";
import TestGroupTestConfigurationFormHeader from "./TestGroupTestConfigurationsHeader";
import TestGroupTestConfigurationsContext from "./contexts/TestGroupTestConfigurationsContext";
import TestGroupTestConfigurationsIndex from "./TestGroupTestConfigurationsIndex";

const STEPS = {
  newTestGroupTestConfigurationSelector: <NewTestGroupTestConfigurationSelector />,
  testGroupTestConfigurationsIndex: <TestGroupTestConfigurationsIndex />,
  testGroupTestConfigurationForm: <TestGroupTestConfigurationForm />,
};

const TestGroupTestConfigurationsContainer = ({
  current_user,
  flippers,
  test_group,
  test_group_test_configurations,
}) => {

  const initialTestGroupTestConfiguration = {
    active: true,
    auto_generate_for_appointments: false,
    barcode_type: 0,
    calredi_reporting_school_name: "",
    cancellation_fee_percentage: null,
    iis_username: "",
    notification_settings: {
      canceled: true,
      custom_result: true,
      did_not_result: true,
      negative: true,
      positive: true,
      test_not_performed: true,
    },
    ordering_facility_address_type_code: "",
    ordering_facility_city: "",
    ordering_facility_clia: "",
    ordering_facility_country: "",
    ordering_facility_mailing_address: "",
    ordering_facility_name: "",
    ordering_facility_other_designation: "",
    ordering_facility_phone_number: "",
    ordering_facility_postal_code: "",
    ordering_facility_state_abbv: "",
    payment_amount: "0.00",
    publish_results_settings: {
      canceled: true,
      custom_result: true,
      did_not_result: true,
      negative: true,
      positive: true,
      test_not_performed: true,
    },
    service_code: "",
    test_configuration_id: null,
    test_group_id: test_group.id,
  };

  const compareDisplayNames = (a, b) => {
    return a.test_configuration.checkout_name.localeCompare(b.test_configuration.checkout_name);
  };

  const reducer = (state, action) => {
    const newState = { ...state }
    switch(action.type) {
      case "setCurrentStep":
        return {
          ...state,
          currentStep: action.values,
          testGroupTestConfiguration: action.values == "testGroupTestConfigurationsIndex"
            ? initialTestGroupTestConfiguration
            : state.testGroupTestConfiguration,
          selectedTestConfiguration: action.values == "testGroupTestConfigurationsIndex"
            ? null
            : state.selectedTestConfiguration,
          vaccineInfoSheetVersions: action.values == "testGroupTestConfigurationsIndex"
            ? []
            : state.vaccineInfoSheetVersions,
        }
      case "setLoading":
        return { ...state, loading: action.values };
      case "setSelectedTestGroupTestConfigurationIds":
        return { ...state, selectedTestGroupTestConfigurationIds: action.values }
      case "setTestConfigurationOptions":
        newState.testConfigurationOptions = action.values;
        if (!newState.testGroupTestConfiguration.id) { // if creating a new test_group_test_configuration
          newState.testGroupTestConfiguration = {
            ...state.testGroupTestConfiguration,
            requisitioning_physician_id: action.values.requisitioning_physician_candidates.length === 1 // if only one option, select it
              ? action.values.requisitioning_physician_candidates[0].id
              : null,
          }
        }
        return newState;
      case "setTestGroupTestConfigurations":
        return { ...state, testGroupTestConfigurations: action.values.sort(compareDisplayNames) }
      case "setActiveTab":
        return { ...state, activeTab: action.values }
      case "setTestGroupTestConfiguration":
        if (!!action.values.test_configuration_id) { // selecting a test_configuration when creating a new test_group_test_configuration
          newState.testGroupTestConfiguration = action.values;
          newState.selectedTestConfiguration = state.testConfigurationOptions?.allowed_test_configurations?.find((test_configuration) => {
            return test_configuration.id == action.values.test_configuration_id
          });
        } else { // editing an existing test_group_test_configuration
          newState.testGroupTestConfiguration = action.values.test_group_test_configuration;
          newState.selectedTestConfiguration = action.values.test_configuration;
          newState.currentStep = "testGroupTestConfigurationForm";
        }
        return newState;
      case "setTestGroupTestConfigurationSelectedFilter":
        if (state.testGroupTestConfigurationSelectedFilter == action.values) { // if user selected the current value
          newState.testGroupTestConfigurationSelectedFilter = null;
          newState.filteredTestConfigurationOptions = null;
        } else {
          newState.testGroupTestConfigurationSelectedFilter = action.values;
          newState.filteredTestConfigurationOptions = state.testConfigurationOptions?.allowed_test_configurations.filter((test_configuration) => {
            return test_configuration.service_specification_type == action.values
          })
        }
        return newState;
      case "setVaccineInfoSheetVersions":
        return { ...state,
          testGroupTestConfiguration: {
            ...state.testGroupTestConfiguration,
            show_vis_on_registration: state.testGroupTestConfiguration.id
              ? state.testGroupTestConfiguration.show_vis_on_registration
              : !!action.values?.length
          },
          vaccineInfoSheetVersions: action.values,
        };
      default:
        throw new Error("Unknown action");
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    testGroupTestConfiguration: {
      ...initialTestGroupTestConfiguration
    },
    currentStep: "testGroupTestConfigurationsIndex",
    loading: false,
    selectedTestGroupTestConfigurationIds: [],
    selectedTestConfiguration: null,
    activeTab: "active",
    testConfigurationOptions: null,
    testGroupTestConfigurations: test_group_test_configurations.sort(compareDisplayNames),
    testGroupTestConfigurationSelectedFilter: null,
    vaccineInfoSheetVersions: [],
  });

  const bulkUpdateActiveStatus = async () => {
    const response = await axios.put(`/test_groups/${test_group.id}/bulk_update_active_status`, {
      active: state.activeTab != "active",
      test_group_test_configuration_ids: state.selectedTestGroupTestConfigurationIds,
    });

    if (response.status == 200) {
      dispatch({ type: "setTestGroupTestConfigurations", values: JSON.parse(response.data.test_group_test_configurations).test_group_test_configurations });
      dispatch({ type: "setSelectedTestGroupTestConfigurationIds", values: [] });
      toastr.success(`The selected service(s) were successfully ${state.activeTab == "active" ? "inactivated" : "activated"}`);
    } else {
      toastr.error("Something went wrong")
    }
  };

  const save = async () => {
    let request = axios.post;
    let url = `/test_groups/${test_group.id}/test_group_test_configurations`;

    const testGroupTestConfiguration = state.testGroupTestConfiguration;

    if (!!testGroupTestConfiguration.id) {
      request = axios.put;
      url = `/test_groups/${test_group.id}/test_group_test_configurations/${testGroupTestConfiguration.id}`
    }
    if (state.selectedTestConfiguration?.service_specification_type == "otc") {
      // there are no input fields for these so we set them true if 'otc'
      testGroupTestConfiguration["allow_self_resulting"] = true;
      testGroupTestConfiguration["request_otc_photo"] = true;
    }

    try {
      const response = await request(url, {
        test_group_test_configuration: testGroupTestConfiguration,
      });
      toastr.success("Service successfully saved");
      window.location.href = `/test_groups/${test_group.slug}/edit_tests`;
    } catch(e) {
      toastr.error("Something went wrong");
    }
  };

  const context = {
    bulkUpdateActiveStatus,
    current_user,
    dispatch,
    flippers,
    save,
    state,
    test_group,
  };

  return (
    <div>
      <TestGroupTestConfigurationsContext.Provider value={context}>
        <TestGroupTestConfigurationFormHeader />
        {STEPS[state.currentStep]}
      </TestGroupTestConfigurationsContext.Provider>
    </div>
  );
};

export default TestGroupTestConfigurationsContainer;