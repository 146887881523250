import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import axios from 'axios';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Modal, Button, FormControl, Alert } from 'react-bootstrap';
import ModalHeader from '../../../common/components/ModalHeader';
import { buildLocalizedString } from '../../../common/locales/langs';

const CancelAppointmentModal = ({ closeModal, appointment, testGroup }) => {
  const { t } = useTranslation();
  const options = [
    'cant_get_to_location',
    'timing_conflict',
    'became_ill_with_covid19',
    'received_service_elsewhere',
    'duplicate_appointment',
    'other',
  ].map((o) => {
    return {
      value: o,
      label: t(`cancellation_types.${o}`),
    };
  });

  const [selectedCancellationOption, setSelectedCancellationOption] =
    useState(null);
  const [archivedReason, setArchivedReason] = useState('');
  const [error, setError] = useState(null);
  const refundMessage = `${buildLocalizedString(testGroup, 'payment_refund_text')} ${t('payment.refund_policy', {
    amount: appointment?.payment_configuration?.refund_configuration?.formatted_amount,
    link: `/terms-of-service`
  })}`
  const windowPassedMessage = t('payment.refund_window_passed');
  const submit = () => {
    if (!selectedCancellationOption) {
      setError(t('instructions.errors.missing_cancellation_reason'));
      return;
    }
    axios
      .delete(`/registrations/${appointment.access_code}`, {
        data: {
          appointment: {
            archived_reason: archivedReason,
            cancellation_type: selectedCancellationOption.value,
          },
        },
      })
      .then(() => {
        location.reload();
      })
      .catch(() => {
        location.reload();
      });
  };
  return (
    <Modal show={true} onHide={closeModal} size="lg">
      <ModalHeader closeButton onHide={closeModal}>
        <div>
          <Modal.Title as="h2">
            Cancel appointment
          </Modal.Title>
          <p className="text-muted mb-0">
            {t('instructions.are_you_sure_you_want_to_cancel')}
          </p>
        </div>
      </ModalHeader>
      <Modal.Body>
        {
          appointment?.refunds_enabled && (
            <Alert className={appointment.refundable ? "text-warning border-warning" : "text-danger border-danger"}>
              <ReactMarkdown children={appointment.refundable ? refundMessage : windowPassedMessage} />
            </Alert>
          )
        }
        <label className="form-label">
          {t('instructions.please_choose_cancellation_reason')}: *
        </label>
        <Select
          aria-label={t('instructions.please_choose_cancellation_reason')}
          value={selectedCancellationOption}
          onChange={(selectedCancellationOption) =>
            setSelectedCancellationOption(selectedCancellationOption)
          }
          options={options}
        />
        {error && <div className="text-danger">{error}</div>}
        <br />
        <label className="form-label">
          {t('instructions.additional_details')}:
        </label>
        <FormControl
          onChange={(e) => setArchivedReason(e.target.value)}
          value={archivedReason}
          as="textarea"
          aria-label="Please add any additional details"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={submit}>
          {t('instructions.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CancelAppointmentModal;
