import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';

import MultiplexResultRow from './MultiplexResultRow';
import ResultRow from './ResultRow';

const TestSection = ({ test, printSelectedTest }) => {
  const { t, i18n } = useTranslation();
  const testDocumentationUrl = test.test_group_test_configuration?.documentation_url;
  const renderThirdRow =
    test.custom_templates.length > 0 || testDocumentationUrl;
  const negativeHighlight = {
    backgroundColor: test.result === 'negative' && 'rgba(131,197,98,0.1)',
  };
  const {
    test_configuration: { service_specification },
  } = test;

  const ResultRowComponent =
    test.test_configuration.results_schema && test.generic_test_results.length
      ? MultiplexResultRow
      : ResultRow;

  return (
    <React.Fragment>
      <div className="mb-3 mt-3 test-card" id={test.id}>
        <Row className="results__table-top-row pt-3 pb-3 mx-1 border-bottom-0 rounded-top">
          <Col sm={6} md={4}>
            <h5 className="mb-0 fw-bold">
              {service_specification === 'rapid_general'
                ? test.general_test_result
                  ? test.general_test_result.pretty_test_kit_names[
                      test.general_test_result.test_kit
                    ]
                  : test.test_configuration.display_name
                : test.test_configuration.display_name}
            </h5>
            <div className="flex-center-vertical">
              <div
                className={`results__status-circle me-1 ${
                  test.result_complete ? 'green' : 'orange'
                }`}
              ></div>
              <p className="mb-0 text-muted">
                {test.result_complete
                  ? t('result_page.view_results.status.results_ready')
                  : t('result_page.view_results.status.processing')}
              </p>
            </div>
          </Col>
          <Col className="d-flex align-items-center">
            {test.result_complete ? (
              <div>
                <span>
                  {t('result_page.view_results.status.administered')}{' '}
                  {t(
                    `date.${
                      test.administered_at_month &&
                      test.administered_at_month.toLowerCase()
                    }`,
                  )}{' '}
                  {test.administered_at_day}, {test.administered_at_year}{' '}
                  {test.administered_at_hour}:{test.administered_at_minute}{' '}
                  {test.administered_at_am_pm}
                </span>
                <br />
                {test.self_administered && (
                  <span className="text-muted">
                    {t('result_page.self_administered', {
                      location: test.location_name,
                    })}
                  </span>
                )}
                {!test.self_administered && (
                  <span className="text-muted">
                    {' '}
                    {t('result_page.view_results.at', {
                      address: test.location,
                    })}
                  </span>
                )}
              </div>
            ) : (
              ''
            )}
          </Col>
          <Col sm={1}>
            <Button variant="link" onClick={() => printSelectedTest(test.id)}>
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          </Col>
        </Row>
        <ResultRowComponent
          test={test}
          renderThirdRow={renderThirdRow}
          negativeHighlight={negativeHighlight}
        />
        {renderThirdRow && (
          <Row className="results__table-bottom-row pt-3 pb-3 px-2 mx-1 border-top-0 rounded-bottom">
            <Col xs={12} sm={6} md={4} className="mb-0 fw-bold text-secondary">
              {t('result_page.documents')}
            </Col>
            <Col>
              {test.custom_templates.map((custom_template, index) => (
                <div key={index}>
                  <a href={custom_template.url} target="_blank">
                    {custom_template.name}
                  </a>
                </div>
              ))}
              {testDocumentationUrl && (
                <div>
                  <a
                    href={testDocumentationUrl}
                    target="_blank"
                  >
                    Test Configuration Documentation
                  </a>
                </div>
              )}
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default TestSection;
