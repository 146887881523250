import React, { useState } from 'react';
import AppointmentSlotSelector from './AppointmentSlotSelector';
import CollapsableAppointmentSlotGroupSelector from './CollapsableAppointmentSlotGroupSelector';
import { Col, Card, Row } from 'react-bootstrap';
import GoogleMapsWidget from '../../../../common/components/GoogleMapsWidget';
import '../../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';

export const buildValidAppointmentSlots = (
  slotter,
  selectedAppointmentSlot,
  selectedFollowUpAppointmentSlotGroup,
) => {
  return slotter.followUpAppointmentSlots(selectedAppointmentSlot, selectedFollowUpAppointmentSlotGroup)
}

export const buildValidAppointmentSlotGroups = (appointmentSlotGroups, selectedAppointmentSlotGroup, onlyOneLocationForFollowUp=false) => {
  if (onlyOneLocationForFollowUp) {
    return [selectedAppointmentSlotGroup];
  }
  return appointmentSlotGroups.filter(appointmentSlotGroup => {
    return selectedAppointmentSlotGroup.follow_up_test_configuration_id == appointmentSlotGroup.follow_up_test_configuration_id;
  })
}

const appointmentSlotSelectorWrapper = ({
  slotter,
  selectedAppointmentSlot,
  selectedFollowUpAppointmentSlotGroup,
  selectedFollowUpAppointmentSlotId,
  onSelectFollowUpAppointmentSlot,
}) => {
  if (!selectedFollowUpAppointmentSlotGroup) {
    return null;
  }

  const validAppointmentSlots = buildValidAppointmentSlots(
    slotter,
    selectedAppointmentSlot,
    selectedFollowUpAppointmentSlotGroup,
  );

  return (
    <div className="my-3">
      <AppointmentSlotSelector
        onSelect={onSelectFollowUpAppointmentSlot}
        appointmentSlotGroup={selectedFollowUpAppointmentSlotGroup}
        appointmentSlots={validAppointmentSlots}
        appointmentSlotId={selectedFollowUpAppointmentSlotId}
        allowWaitlist={false}
      />
    </div>
  )
}

const FollowUpAppointmentSlotSelector = ({
  slotter,
  selectedAppointmentSlot,
  validAppointmentSlotGroups,
  onSelectFollowUpAppointmentSlot,
  selectedFollowUpAppointmentSlotId,
  onSelectFollowUpAppointmentSlotGroup,
}) => {
  const { t, i18n } = useTranslation();
  const [selectedFollowUpAppointmentSlotGroup, setSelectedFollowUpAppointmentSlotGroup] = useState(null);

  return (
    <Card body className="my-3">
      <h2 className="h3 font-weight-bold">{t('follow_up.second_dose')}</h2>
      <Row>
        <Col xs={12} lg={6}>
          <label className="select form-label">{t('follow_up.choose_second_location')}</label>
          <CollapsableAppointmentSlotGroupSelector
            selectedAppointmentSlotGroupId={selectedFollowUpAppointmentSlotGroup?.id}
            appointmentSlotGroups={validAppointmentSlotGroups}
            onSelect={(s) => {
              setSelectedFollowUpAppointmentSlotGroup(validAppointmentSlotGroups.find(asg => asg.id === s.value));
              onSelectFollowUpAppointmentSlot({value: null});
              onSelectFollowUpAppointmentSlotGroup();
            }}
            collapsed={true}
          />
          { appointmentSlotSelectorWrapper({
            slotter,
            selectedAppointmentSlot,
            selectedFollowUpAppointmentSlotGroup,
            selectedFollowUpAppointmentSlotId,
            onSelectFollowUpAppointmentSlot,
          })}
        </Col>
        <Col xs={12} lg={6} className="tab-pane fade show text-center">
          { selectedFollowUpAppointmentSlotGroup?.location_type === "location" &&
            selectedFollowUpAppointmentSlotGroup?.show_map_on_registration &&
            <GoogleMapsWidget
              title={selectedFollowUpAppointmentSlotGroup.title}
              address={selectedFollowUpAppointmentSlotGroup.address}
              marker={selectedFollowUpAppointmentSlotGroup}
            />
          }
        </Col>
      </Row>
    </Card>
  )
}

export default FollowUpAppointmentSlotSelector;
