import React, { useContext } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import RegistrationContext from '../../../Registration/contexts/RegistrationContext';
import TestSurvey, { validateSurvey } from './TestSurvey';
import LocalizedMarkdown from '../../../Registration/components/registration/fields/LocalizedMarkdown';
import SignConsentsForTestConfigurations, {
  validateConsent,
} from './SignConsentsForTestConfigurations';

export const validateChooseTestConfiguration = (testGroup) => (values) => {
  const errors = {};
  if (values.chosen_test_configurations.length === 0) {
    errors.chosen_test_configurations = 'registration.errors.required';
  }
  return errors;
};

const formatPayment = (payment) =>
  (payment / 100).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

const ThreeColumnGrid = styled.div`
  display: grid;

  grid-template-areas:
    'icon a a a a'
    'icon b b b b'
    'icon c c c c';
  grid-template-columns: 75px 1fr 1fr 1fr 1fr;

  @media (min-width: 470px) {
    grid-template-areas:
      'icon a a a a'
      'icon b b c c';
    grid-template-columns: 75px 2fr 2fr 2fr 3fr;
  }

  @media (min-width: 768px) {
    grid-template-areas: 'icon a a a a b c';
    grid-template-columns: 90px 1fr 1fr 1fr 1fr 90px 120px;
  }

  p {
    margin-bottom: 0;
  }
`;

const TestConfigurationCard = ({
  testConfiguration,
  onClick,
  updateQuantity,
  values,
  totalRecords,
  testGroup,
}) => {
  const { t, i18n } = useTranslation();
  const active = values.chosen_test_configurations.find(
    (x) => x.test_configuration_id === testConfiguration.id,
  );
  const paymentAmount =
    testConfiguration.test_group_test_configuration.payment_amount;
  const localizedDisplayNameAvailable = testConfiguration.custom_text_snippets?.find((s) => s.language == i18n.language && s.phrase_key == "display_name");

  return (
    <Card
      className={`my-3 shadow-sm border border-2 icon_card icon_card-sm-none ${
        active && 'border-primary active'
      }`}
      style={{
        borderRadius: '17px',
        cursor: 'pointer',
        overflow: 'hidden',
      }}
      onClick={() => onClick(!active)}
    >
      <ThreeColumnGrid>
        <div
          className="d-flex flex-row align-items-center"
          style={{ gridArea: 'icon' }}
        >
          <div
            className="d-flex justify-content-center pt-4 text-white flex-shrink-0 me-1"
            style={{
              width: '100%',
              height: '100%',
              minHeight: 90,
              backgroundColor: testConfiguration.color_code + 'BB',
            }}
          >
            <FontAwesomeIcon
              icon={['fa', testConfiguration.font_awesome_icon]}
              className="h1 mb-0"
            />
          </div>
        </div>
        <div
          className="d-flex flex-row p-3 align-self-center"
          style={{ gridArea: 'a' }}
        >
          <input
            type="checkbox"
            style={{ verticalAlign: 'middle' }}
            className="form-check-input flex-shrink-0"
            onClick={() => onClick(!active)}
            checked={active}
          />
          <div className="d-flex flex-column ms-2">            
            {localizedDisplayNameAvailable ? (
              <LocalizedMarkdown
                container={testConfiguration}
                stringKey="display_name"
              />
            ) : (
              <h6>
                {testConfiguration.display_name}
              </h6>
            )}
            <LocalizedMarkdown
              container={testConfiguration}
              stringKey="description"
            />
          </div>
        </div>
        {testGroup.payment_required && (
          <React.Fragment>
            <div
              className="d-flex align-self-center mx-3 me-lg-4 pb-3 pb-md-0"
              style={{ gridArea: 'b' }}
            >
              <strong className="d-block d-md-none">
                {t('member.price')}:
              </strong>
              <span className="text-muted ms-auto">
                {formatPayment(paymentAmount)}
              </span>
            </div>
            <div
              className="d-flex align-self-center align-items-center justify-content-between mx-3 pb-3 pb-md-0"
              style={{ gridArea: 'c' }}
            >
              <strong className="d-block d-md-none me-1 flex-grow-1">
                {t('member.quantity')}:
              </strong>
              {totalRecords > 1 || !testGroup.service_quantity_selector ? (
                <span className="text-muted mx-auto">{totalRecords}</span>
              ) : (
                <select
                  className="form-select form-select-lg select required mx-auto"
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    !active
                      ? onClick(true, e.target.value)
                      : updateQuantity(testConfiguration, e.target.value);
                  }}
                  value={active?.quantity || 1}
                  style={{ width: 78 }}
                >
                  {[...Array(10)].map((_, idx) => (
                    <option key={idx} value={idx + 1}>
                      {idx + 1}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </React.Fragment>
        )}
      </ThreeColumnGrid>
    </Card>
  );
};

const ChooseTestConfiguration = ({ values, errors, setFieldValue, user }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, sections, setSections } = useContext(RegistrationContext);

  const chosenTestConfigurationShape = (testConfiguration, quantity = 1) => ({
    test_configuration_id: testConfiguration.id,
    id: null,
    appointment_id: values.id,
    quantity,
  });

  const consentTemplate = {
    view: (props) => <SignConsentsForTestConfigurations {...props} />,
    validate: validateConsent,
    name: 'consent_forms_test_group_test_configuration',
  };

  const hasConsent = (testConfiguration) =>
    !!testConfiguration.test_group_test_configuration.active_consent;

  const testSurveySectionTemplate = (testSurvey, testConfiguration) => {
    const surveyIndex = values.test_surveys.findIndex((x) => {
      return x.test_configuration_id === testSurvey.test_configuration_id;
    });

    return {
      view: ({ values, errors, setFieldValue }) => (
        <TestSurvey
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          userTestSurvey={testSurvey}
        />
      ),
      validate: validateSurvey.bind(
        null,
        testSurvey.test_configuration_survey,
        surveyIndex,
      ),
      name: `test_surveys_${testConfiguration.id}`,
    };
  };

  let totalPrice = testGroup.test_configurations
    .map((tc) => {
      const testConfigInChosen = values.chosen_test_configurations.find(
        (ctc) => ctc.test_configuration_id === tc.id,
      );

      return testConfigInChosen
        ? tc.test_group_test_configuration.payment_amount *
            (testConfigInChosen?.quantity || 1)
        : 0;
    })
    .reduce((a, b) => a + b, 0);

  const totalRecordsEach = user.dependents.length + 1;

  const updateChosenTestConfigurationQuantity = (
    testConfiguration,
    quantity,
  ) => {
    const chosenIndex = values.chosen_test_configurations.findIndex(
      (ctc) => ctc.test_configuration_id === testConfiguration.id,
    );
    if (chosenIndex > -1) {
      const newChosenTestConfigurations = [
        ...values.chosen_test_configurations,
      ];
      newChosenTestConfigurations[chosenIndex] = {
        ...newChosenTestConfigurations[chosenIndex],
        quantity,
      };
      setFieldValue('chosen_test_configurations', newChosenTestConfigurations);
    }
  };

  return (
    <div className="form-section m-3">
      <h5>{t('member.choose_test_configurations_title')}</h5>
      {testGroup.allow_dependents && (
        <div className="text-muted">
          {t('member.choose_test_configurations_subtitle')}
        </div>
      )}
      <LocalizedMarkdown
        container={testGroup}
        stringKey="test_configuration_text"
      />
      <ThreeColumnGrid className="d-none d-md-grid mt-2 font-weight-bold">
        <span>{t('member.product')}</span>
        <span className="mx-auto" style={{ gridArea: 'b' }}>
          {t('member.price')}
        </span>
        <span className="mx-auto" style={{ gridArea: 'c' }}>
          {t('member.quantity')}
        </span>
      </ThreeColumnGrid>
      {testGroup.test_configurations.map((testConfiguration) => {
        const testSurvey = values.test_surveys.find(
          (survey) => survey.test_configuration_id === testConfiguration.id,
        );
        return (
          <TestConfigurationCard
            key={testConfiguration.id}
            testConfiguration={testConfiguration}
            totalRecords={totalRecordsEach}
            testGroup={testGroup}
            onClick={(selected, quantity = 1) => {
              if (selected) {
                setFieldValue(
                  'chosen_test_configurations',
                  values.chosen_test_configurations.concat(
                    chosenTestConfigurationShape(testConfiguration, quantity),
                  ),
                );
                let testConfigSection = [];
                if (testSurvey) {
                  testConfigSection = testConfigSection.concat(
                    testSurveySectionTemplate(testSurvey, testConfiguration),
                  );
                }
                if (
                  hasConsent(testConfiguration) &&
                  !sections.find(
                    (x) =>
                      x.name == 'consent_forms_test_group_test_configuration',
                  )
                ) {
                  testConfigSection = testConfigSection.concat(consentTemplate);
                }

                setSections(sections.concat(testConfigSection));
              } else {
                setFieldValue(
                  'chosen_test_configurations',
                  values.chosen_test_configurations.filter(
                    (x) => x.test_configuration_id !== testConfiguration.id,
                  ),
                );
                let newSection = sections.filter(
                  (section) =>
                    section.name !== `test_surveys_${testConfiguration.id}`,
                );
                const tempValues = values.chosen_test_configurations.filter(
                  (x) => x.test_configuration_id !== testConfiguration.id,
                );
                const chosenTestConfigurations =
                  tempValues.length > 0
                    ? tempValues.map((x) => x.test_configuration_id)
                    : [];
                const consents =
                  tempValues.length > 0
                    ? testGroup.test_configurations.filter(
                        (tc) =>
                          tc.test_group_test_configuration.active_consent &&
                          chosenTestConfigurations.includes(tc.id),
                      )
                    : testGroup.test_configurations.filter(
                        (tc) => tc.test_group_test_configuration.active_consent,
                      );
                if (consents.length == 0)
                  newSection = newSection.filter(
                    (x) =>
                      x.name != 'consent_forms_test_group_test_configuration',
                  );
                setSections(newSection);
              }
            }}
            updateQuantity={updateChosenTestConfigurationQuantity}
            values={values}
          />
        );
      })}
      {testGroup.payment_required && (
        <div className="text-end my-2">
          {t('member.total_services_selected')}:{' '}
          <strong className="me-5">
            {values.chosen_test_configurations
              .map((ctc) => parseInt(ctc.quantity))
              .reduce((a, b) => a + b, 0)}
          </strong>
          {t('member.total_price')}:{' '}
          <strong>
            {totalPrice > 10
              ? formatPayment(totalPrice * totalRecordsEach)
              : formatPayment(0)}
          </strong>
        </div>
      )}
      {errors.chosen_test_configurations && (
        <div className="text-danger">
          {t(errors.chosen_test_configurations)}
        </div>
      )}
    </div>
  );
};

export default ChooseTestConfiguration;
