export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Tháng",
    "day": "Ngày",
    "year": "Năm",
    "january": "Tháng Một",
    "february": "Tháng Hai",
    "march": "Tháng Ba",
    "april": "Tháng Tư",
    "may": "Tháng Năm",
    "june": "Tháng Sáu",
    "july": "Tháng Bảy",
    "august": "Tháng Tám",
    "september": "Tháng Chín",
    "october": "Tháng Mười",
    "november": "Tháng Mười Một",
    "december": "Tháng Mười Hai"
  },
  "user": {
    "send_appointment_confirmation_message": "Cuộc hẹn của bạn đã được xác nhận với {{name}}.",
    "landline_appointment_reminder_message": "Xin chào, {{full_name}}. Đây là lời nhắc nhở cho lịch hẹn của quý vị tại {{name}}. Lịch hẹn của quý vị là vào {{date}} lúc {{time}} tại {{address}}.",
    "send_at_home_visit_confirmation_message": "Cuộc hẹn của bạn đã được xác nhận sau {{time}}, ngày {{date}}.",
    "send_mail_order_confirmation_message": "Đặt hàng qua thư của bạn đã được xác nhận",
    "send_waitlist_message": "Quý vị đã được đưa vào danh sách chờ cho {{name}}. Làm ơn không tới hẹn đến khi chúng tôi xác nhận thời gian hẹn",
    "verify_contact": {
      "phone": "Làm ơn xác nhận số điện thoại của quý vì bằng cách nhấn vào: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Tiếp tục đăng ký cho lịch hẹn tại đây: {{url}}",
    "on_demand_confirmation_message": "Bạn đã đăng ký thành công đối với {{name}}.",
    "appointment_reminder": "Trước lịch hẹn của quý vị tại {{start_time}}, làm ơn nhấn vào để hoàn thành bất kỳ hành động nào còn tồn đọng và xem xác nhận của mình. Làm ơn không đến sớm hơn 15 phút trước lịch hẹn của mình.",
    "appointment_reminder_base": "{{text}} NHẤN VÀO ĐÂY: {{link}}",
    "test_result_notifier": {
      "text_message": "Cổng thông tin bệnh nhân cho {{first_name_with_last_initial}} đã được cập nhật. Truy cập {{url}} và sử dụng mã: {{access_code}}",
      "email_subject": "Cập nhật mới trong cổng thông tin bệnh nhân của bạn",
      "email_intro": "Xin chào {{first_name_with_last_initial}}",
      "email_p1": "Một tin nhắn mới hoặc cập nhật bệnh nhân có sẵn cho bạn.",
      "email_p2": "Nhấp vào nút bên dưới hoặc sử dụng liên kết để xem cổng thông tin bệnh nhân của bạn và sử dụng mã",
      "email_p3": "Xem cổng thông tin bệnh nhân tại:'",
      "email_button": "Xem cổng thông tin"
    },
    "landline_appointment_reminder_message_without_time": "Xin chào, {{full_name}}. Đây là lời nhắc nhở cho lịch hẹn của quý vị tại {{name}}. Lịch hẹn của quý vị vào {{date}} tại {{address}}.",
    "appointment_reminder_on_demand": "Trước lịch hẹn của quý vị vào {{date}}, làm ơn nhấn vào để hoàn thành bất kỳ hành động nào còn tồn đọng và xem xác nhận của mình.",
    "new_test_result_notifier": {
      "email_intro": "Cổng thông tin bệnh nhân của bạn đã được cập nhật.",
      "email_text_1": "Hãy truy cập cổng thông tin Lịch sử Y tế của bạn bên dưới để xem mọi hồ sơ bệnh nhân hoặc báo cáo xét nghiệm mới.",
      "email_button": "Lịch sử y tế",
      "email_text_2": "Bạn cũng có thể sao chép và dán URL này vào trình duyệt:"
    },
    "mailers": {
      "email_contact_us": "Bạn có thắc mắc? Liên lạc với chúng tôi ở"
    }
  },
  "errors": {
    "generic": {
      "message": "Đã xảy ra lỗi. Vui lòng thử lại"
    },
    "messages": {
      "blank": "không thể để trống"
    },
    "incorrect_credentials": "Bạn nhập sai thông tin đăng nhập, vui lòng thử lại.",
    "error_activating_test_kit": "Có lỗi xảy ra khi kích hoạt bộ xét nghiệm."
  },
  "type": "Thể loại (đánh máy)",
  "payment": {
    "continue_label": "Tiếp tục Thanh toán",
    "policy_text": "Nếu quý vị hủy hoặc không có mặt tại lịch hẹn của mình, chúng tôi sẽ hoàn trả 80% số tiền thanh toán của quý vị, 7 ngày sau ngày hẹn. Quý vị có thể xem toàn bộ",
    "card_number": "Số thẻ",
    "expiry_date": "Ngày hết hạn",
    "pay_button": "Thanh toán",
    "no_credit_card": "Tôi sẽ trả tại chỗ",
    "cash_payment_helper": "Làm ơn ghi nhớ mang theo **{{payment}}** tới co",
    "invoice": "Hoá đơn",
    "pay_by_card": "Thanh toán bằng thẻ",
    "cost": "Giá",
    "total": "Tổng cộng",
    "pay_cash_at_appointment": "Tôi sẽ thanh toán bằng tiền mặt trong cuộc hẹn của mình tại {{appointment}}",
    "view_invoice": "Xem/In hoá đơn",
    "refund_policy": "Nếu bạn hủy hẹn, chúng tôi sẽ hoàn tiền {{amount}}, sau lịch hẹn 7 ngày. Bạn có thể xem đầy đủ bản [Terms of Service]({{link}})",
    "refund_window_passed": "Do lịch hẹn đã qua nên bạn không thể nhận được tiền hoàn trả. Xin hãy hỏi trực tiếp nhân viên giám sát tại chỗ để giải quyết vấn đề.",
    "amount_paid": "Số tiền đã thanh toán",
    "balance": "Cân bằng"
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Tải lên bản chấp nhận từ chối hoặc ký tên vào hộp chữ ký bên dưới",
    "stop": "Cần có sự đồng ý để hoàn thành đăng ký",
    "parent_or_guardian_required_html": "Để hoàn tất đăng ký của mình, cha mẹ, người giám hộ hoặc người ra quyết định được ủy quyền của quý vị phải cung cấp sự đồng ý vào biểu mẫu bên dưới và ký tên của họ. Quý vị <b> CANNOT </b> tự mình đồng ý với dịch vụ này.",
    "send_parent_or_guardian": "Quý vị có thể gửi cho cha mẹ hoặc người giám hộ của mình liên kết này hoặc yêu cầu họ ký tên bên dưới trên trang này.",
    "consent_guardian_name_signature": "Tên",
    "consent_for_minor": "Ký tên đồng ý thay mặt cho trẻ vị thành niên",
    "guardian_signature": "Chữ ký của cha mẹ, người giám hộ hoặc người được ủy quyền ra quyết định y tế ",
    "after_you_schedule": "Những người tham gia dưới {{age}} phải có sự đồng ý của cha mẹ hoặc người giám hộ thay mặt họ ký tên. Vui lòng tiếp tục đến cuối bản đăng ký để gửi cho người giám hộ của quý vị hoặc ký tên cho người thân uỷ quyền của quý vị.",
    "consent_guardian_last_name_signature": "Họ",
    "consent_first_and_last_name": "Tên vào họ",
    "phone_number": "Số điện thoại của người giám hộ",
    "email": "Thư điện tử của người giám hộ",
    "consent_registrar_with_guardian_name_signature": "Tên người giám hộ",
    "consent_registrar_with_guardian_last_name_signature": "Họ người giám hộ",
    "consent_registrar_first_name_signature": "Tên người đăng ký",
    "consent_registrar_last_name_signature": "Họ người đăng ký",
    "consent_on_behalf": "Tôi ký tên thay mặt cho {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "Tôi đã được ủy quyền bằng lời nói từ {{first_name}} {{last_name}} để thay mặt ký tên cho người này.",
    "consent_on_behalf_registrar_with_guardian": "Tôi đã được ủy quyền bằng lời nói từ người giám hộ của {{first_name}} {{last_name}} để thay mặt ký tên cho người này.",
    "registrar_phone": "Số điện thoại người lưu trữ",
    "registrar_email": "E-mail người lưu trữ",
    "consented_by_parent_guardian": "Phụ huynh/người giám hộ",
    "consented_by_decision_maker": "Người ra quyết định y tế cho phép",
    "consented_by_guardian_verbal": "Người lưu trữ với người giám hộ (có chấp thuận bằng lời nói)",
    "consented_by_registrar": "Người lưu trữ (có chấp thuận bằng lời nói)",
    "full_name": "Họ tên",
    "print": "Print"
  },
  "registration": {
    "contact_information": "Tên & địa chỉ",
    "contact_information_additional": "Thông tin liên hệ của cha mẹ hoặc người giám hộ để liên hệ về lịch hẹn và kết quả ",
    "personal_information": "Thông tin nhân khẩu học",
    "address_placeholder": "Làm ơn nhập địa chỉ của quý vị",
    "first_name_label": "Tên",
    "last_name_label": "Họ",
    "errors": {
      "state": "Tiểu bang phải là mã tiểu bang với 2 ký tự hợp lệ",
      "phone_number_invalid": "Số điện thoại không hợp lệ",
      "required": "Phần giá trị này là bắt buộc",
      "email_domain": "Mục nhập của quý vị không hợp lệ; xin vui lòng kiểm tra lại. Sự đăng ký này được giới hạn cho những người tham gia được lựa chọn bởi tổ chức tài trợ. Hãy thử sử dụng email nơi làm việc hoặc cộng đồng của quý vị. Nếu quý vị cảm thấy mình đủ điều kiện và hệ thống có lôik, vui lòng liên hệ với bộ phận hỗ trợ.",
      "age_requirement": "Ngày sinh không đáp ứng yêu cầu về độ tuổi",
      "signature_required": "Chữ ký là bắt buộc",
      "regex_invalid": "Phần giá trị này dcó vẻ như không hợp lệ.",
      "date_invalid": "Ngày này không tồn tại",
      "invalid_entry": "Vui lòng không tiếp tục đăng ký. Làm theo hướng dẫn trên màn hình hoặc liên hệ với người liên lạc của quý vị.",
      "city": "Phải là một thành phố hợp lệ",
      "survey_unanswered": "Vui lòng kiểm tra xem tất cả các câu hỏi bắt buộc được đánh dấu * đã được trả lời hay chưa.",
      "postal_code": "Phải là mã bưu điện với 5 chữ số hợp lệ",
      "field_required": "{{field}} Phải có một giá trị",
      "option_required": "Bạn phải chọn một trong các tùy chọn",
      "year_length_invalid": "Nhập ngày tháng năm sinh với năm sinh gồm 4 chữ số (yyyy)",
      "invalid_day": "Ngày phải từ 1 - 31",
      "reached_max_chars": "Bạn đã tới số ký tự tối đa.",
      "chars_max": "Ký tự tối đa",
      "minimum_length": "Nội dung phải dài tối thiểu {{length}} ký tự."
    },
    "insurance_status": {
      "question": "Quý vị có bảo hiểm y tế hay không",
      "have_health_insurance": "Có, tôi có bảo hiểm y tế",
      "do_not_have_health_insurance": "Không, tôi không có bảo hiểm y tế"
    },
    "insurance_policy_holder": {
      "question": "Ai là người nắm giữ chính sách bảo hiểm?",
      "i_am": "Tôi",
      "my_spouse": "Vợ hoặc chồng hoặc bạn đời của tôi ",
      "my_parents": "Bố mẹ tôi",
      "someone_else": "Người khác",
      "policy_first_name": "Tên của người nắm giữ chính sách bảo hiểm",
      "policy_last_name": "Họ của người nắm giữ chính sách bảo hiểm",
      "policy_dob": "Ngày tháng năm sinh của người nắm giữ chính sách bảo hiểm",
      "name": "Policy holder's name"
    },
    "insurance_information": {
      "title": "Thông tin bảo hiểm",
      "company_name": "Tên công ty bảo hiểm",
      "id_number": "Số nhận dạng thành viên",
      "group_number": "Số Nhóm thành viên",
      "secondary_insurance_label": "Tôi có bảo hiểm thứ hai",
      "take_photo": "Please take a photo of your insurance card. Taking a photo will auto fill some of the information.",
      "front_of_card": "Front of card",
      "card_information": "Insurance card information",
      "back_of_card": "Back of card"
    },
    "employment_information": {
      "label": "Nơi làm việc",
      "address_1": "Địa chỉ công ty",
      "address_2": "Ví dụ: Phòng 2000",
      "phone_number": "Số điện thoại công ty",
      "company": "Tên công ty",
      "postal_code": "Mã bưu điện Công ty",
      "employed": "Có việc làm",
      "sole_prorietor": "Công ty Tư nhân",
      "not_employed": "Không có việc làm",
      "reporting_supervisor": "Người giám sát báo cáo",
      "reporting_department": "Phòng báo cáo",
      "supervisor_name": "Tên của người giám sát",
      "supervisor_email": "Địa chỉ e-mail của người giám sát"
    },
    "location_availability": "xét nghiệm hiện đang được ưu tiên cho các thành viên cộng đồng sống ở những khu vực này",
    "custom_survey": "Bản câu hỏi",
    "confirmation": "Xem xét",
    "waitlisted": "Danh sách chờ đợi",
    "schedule_your_appointment": "Lên lịch hẹn",
    "information": "Thông tin",
    "consent": "Sự đồng ý",
    "location": "Địa điểm",
    "symptoms": "Triệu chứng",
    "address_required": "Làm ơn nhập địa chỉ của quý vị",
    "consent_required": "Làm ơn đồng ý để tiếp tục",
    "required_field": "Chỉ ra các phần bắt buộc",
    "phone_number": "Số điện thoại di động",
    "email": "E-mail",
    "date_of_birth": "Ngày tháng năm sinh",
    "minimum_age": "Độ tuổi tối thiểu để tham gia là {{year}} tuổi",
    "no_minimum_age": "Không có độ tuổi tối thiểu để xét nghiệm",
    "continue_button": "Tiếp tục",
    "email_required": "Phần Giá trị này phải là một email hợp lệ",
    "done": "Hoàn thành",
    "signature": "Chữ ký",
    "clear_button": "Làm sạch",
    "back_button": "Quay lại",
    "choose_location": "Lựa chọn một địa điểm",
    "no_slots": "Không còn chỗ trống nào tại {{location}}",
    "choose_appointment": "Chọn thời gian hẹn tại {{location}}",
    "appointment_required": "Vui lòng lựa chọn thời gian hẹn",
    "phone_number_required": "Số điện thoại không hợp lệ",
    "phone_number_label": "Vui lòng nhập một số điện thoại có thể nhận tin nhắn văn bản để có kết quả kiểm tra nhanh hơn",
    "symptoms_experiencing": "Vui lòng nhấp vào các triệu chứng quý vị đang có. Nếu quý vị không có triệu chứng nào, làm ơn tiếp tục.",
    "household": "Thành viên trong gia đình",
    "household_p1": "Theo tùy chọn, hãy tạo một cuộc hẹn cho các thành viên trong gia đình, những người cũng cần đi xét nghiệm kiểm tra.",
    "add_dependent": "Thêm thành viên trong gia đình",
    "remove_dependent": "Gỡ bỏ một người phụ thuộc",
    "dependent_consents": "Sự đồng ý",
    "submit": "Hoàn thành",
    "add_waitlist": "Thêm vào danh sách chờ đợi",
    "address": "Địa chỉ gia đình",
    "address_1": "Địa chỉ hàng 1 ",
    "address_2": "Số Căn hộ/Phòng",
    "address_city": "Thành phố",
    "address_state": "Tiểu bang",
    "postal_code": "Mã bưu điện",
    "race_ethnicity": "Chủng tộc",
    "gender": "Giới tính",
    "self_described_gender": "Giới tính tự mô tả",
    "interpreter": "Cần phiên dịch viên? Nếu có, ngôn ngữ nào?",
    "consent_to_terms": "Tôi đồng ý với các điều khoản này",
    "reg_not_open": "Đăng ký chưa được mở",
    "no_more_avail_spots": "Khoảng thời gian quý vị đã chọn không còn nữa. Vui lòng thử lại.",
    "consent_helper": "Nhấp và kéo chuột hoặc ngón tay của quý vị trên hộp bên dưới để ký",
    "phone_number_unreachable_label": "Số điện thoại nhà?",
    "select": "Lựa chọn",
    "test_surveys": "Các câu hỏi Lịch hẹn",
    "edit": "Chỉnh sửa",
    "continue_to_registration": "Tiếp tục Đăng ký",
    "accounts": {
      "already_have_an_account": "Quý vị đã có một tài khoản?",
      "log_in": "Đăng nhập",
      "sign_up": "Đăng ký",
      "sign_up_description": "Vui lòng nhập thông tin của quý vị để tạo tài khoản và tiết kiệm thời gian đăng ký trong tương lai",
      "log_in_description": "Vui lòng nhập e-mail và mật khẩu của quý vị hoặc đăng nhập qua Google hoặc Outlook bên dưới",
      "sign_in_with_label": "Đăng nhập với {{provider}}",
      "password_must_match": "mật khẩu phải trùng khớp",
      "password_uppercase": "Mật khẩu của quý vị phải có ít nhất (%s) chữ hoa.",
      "password_lowercase": "Mật khẩu của quý vị phải có ít nhất (%s) chữ thường.",
      "password_number": "Mật khẩu của quý vị phải có ít nhất (%s) chữ số.",
      "password_special": "Mật khẩu của quý vị phải có ít nhất (%s) ký tự đặc biệt."
    },
    "password": "Mật khẩu",
    "password_confirmation": "Xác nhận mật khẩu",
    "consent_for": "Đồng ý cho",
    "book_one_time_appointment": "Lên lịch hẹn một lần",
    "duplicate_users": {
      "exists": "Quý vị đã đăng ký",
      "overlapping_email_and_phone_p1": "Chúng tôi đã gửi một tin nhắn đến số điện thoại và địa chỉ email của quý vị.",
      "p2": "Sử dụng tin nhắn của mình, quý vị có thể thay đổi cuộc hẹn của mình hoặc nhập kết quả kiểm tra.",
      "overlapping_phone_and_email_p3": "Làm ơn kiểm tra điện thoại và email của quý vị để có thêm thông tin chi tiết",
      "overlapping_email_p3": "Làm ơn kiểm tra email của quý vị để có thêm thông tin chi tiết",
      "overlapping_phone_p3": "Làm ơn kiểm tra điện thoại của quý vị để có thêm thông tin chi tiết",
      "overlapping_phone_p1": "Chúng tôi đã gửi một tin nhắn đến số điện thoại của quý vị.",
      "overlapping_email_p1": "Chúng tôi đã gửi một tin nhắn đến địa chỉ email của quý vị.",
      "p4": "Nếu quý vị cho rằng đây là lỗi hệ thống, vui lòng liên hệ với chúng tôi theo địa chỉ support@primary.health",
      "overlapping_email_and_phone_p3": "Làm ơn kiểm tra điện thoại và email của quý vị để có thêm thông tin chi tiết"
    },
    "duplicate_waitlist": {
      "exists": "Quý vị đã ở trong danh sách chờ",
      "overlapping_email_and_phone_p1": "Chúng tôi đã gửi một xác nhận khác đến số điện thoại và địa chỉ email của quý vị",
      "p2": "Quý vị có thể sử dụng xác nhận của mình để cung cấp thông tin bổ sung hoặc xóa quý vị ra khỏi danh sách chờ.",
      "overlapping_phone_and_email_p3": "Làm ơn kiểm tra điện thoại và email của quý vị để có thêm thông tin chi tiết",
      "overlapping_email_p3": "Làm ơn kiểm tra email của quý vị để có thêm thông tin chi tiết",
      "overlapping_phone_p3": "Làm ơn kiểm tra điện thoại của quý vị để có thêm thông tin chi tiết",
      "overlapping_email_p1": "Chúng tôi đã gửi một xác nhận khác đến địa chỉ email của quý vị"
    },
    "insist_guardian_consent": "Tôi đang cung cấp sự đồng ý thay mặt cho",
    "confirmation_section": {
      "title": "Xem xét và xác nhận",
      "p1": "Vui lòng xem lại thông tin của quý vị trước khi hoàn thành đăng ký.",
      "edit_information": "Quay lại và chỉnh sửa"
    },
    "iemodal": {
      "title": "trình duyệt không được hỗ trợ",
      "body": "Hiện có thể đăng ký trên các trình duyệt này"
    },
    "show_other_locations": "Nhấp để hiển thị các địa điểm khác",
    "non_us_address": "Đây là một địa chỉ quốc tế",
    "test_group_user_survey": "Kiểm tra sàng lọc người tham gia",
    "self_consent": "Tôi đồng ý cho bản thân",
    "address_country": "Quốc gia",
    "appointment": "Cuộc hẹn",
    "employee_id": "Mã hiệu công nhân",
    "appointment_time": "Giờ hẹn",
    "appointment_location": "Vị trí",
    "phone_or_email": "Số điện thoại hoặc địa chỉ e-mail",
    "no_self_consent": "Tôi từ chối Đồng Ý cho mình",
    "no_insist_guardian_consent": "Tôi từ chối Đồng Ý cho {{full_name}}",
    "additional_consents_helpertext": "Có thể yêu cầu thêm Đồng Ý Bổ Sung sau",
    "minimum_age_with_months": "Độ tuổi tối thiểu để tham gia là {{year}} tuổi {{month}} tháng.",
    "assistive_technology": "Công nghệ hỗ trợ",
    "covid_vaccine_y_n": "Bạn có muốn tiêm vắc xin cùng với lần đăng ký này không?",
    "received_covid_vaccine_y_n": "Bạn đã từng chích vắc xin Covid-19 chưa?",
    "covid_vaccinations": "Vắc xin Covid-19",
    "select_vaccine_dose": "Xin hãy chọn liều vắc xin bạn muốn tiêm",
    "demographic_info": "Thông tin nhân khẩu",
    "additional_info": "Thông tin bổ sung",
    "self_described_race": "Chủng tộc (tự mô tả)",
    "verify_contact_information": "Xác minh",
    "verify_contact": {
      "we_sent_you_a_code": "Vui lòng nhập mã code chúng tôi vừa gửi đến {{contact}}",
      "confirmation_code": "Mã xác nhận",
      "code_resent": {
        "email": "Mã code khác vừa được gửi tới email của bạn",
        "phone_number": "Mã code khác vừa được gửi tới điện thoại của bạn"
      },
      "did_not_receive_code": "Bạn không nhận được mã xác nhận?",
      "verify_by": {
        "email": "Thay vào đó hãy xác minh bằng email",
        "phone_number": "Thay vào đó hãy xác minh bằng số điện thoại"
      }
    },
    "preferred_method_of_communication": {
      "question": "Phương thức giao tiếp mong muốn",
      "helper_text": "Chúng tôi sẽ liên lạc với bạn về cập nhật buổi hẹn",
      "phone_number": "Điện thoại (chỉ nhắn tin SMS và có thể áp dụng phí dữ liệu)",
      "landline": "Chúng tôi hiện không hỗ trợ điện thoại bàn. Vui lòng cung cấp e-mail để gửi các thông tin quan trọng.",
      "verification_method": "Bạn muốn nhận mã xác thực như thế nào?",
      "verification_helper_text": "Bạn sẽ được gửi mã để xác minh danh tính trước khi đăng nhập."
    },
    "skip_for_now": "Hiện tại bỏ qua",
    "default_information": {
      "title": "OK, chúng tôi sẽ dùng địa chỉ này",
      "title_v2": "OK, chúng tôi sẽ sử dụng thông tin liên hệ này"
    },
    "middle_name_label": "Tên lót",
    "confirm_appointment": "Xác nhận cuộc hẹn",
    "dob": "Ngày sinh",
    "different_appointment": "Cuộc hẹn khác",
    "select_appointment_time": "Chọn giờ hẹn",
    "decline_address": "Tôi từ chối cung cấp địa chỉ. Vui lòng dùng địa chỉ của chương trình thay vào đó.",
    "patient_information": "Thông tin bệnh nhân",
    "scan_license": "Quét giấy phép lái xe",
    "how_to_contact": "Chúng tôi nên liên lạc bạn như thế nào?",
    "decline_email_or_phone": "Tôi từ chối cung cấp e-mail hoặc số điện thoại. Tôi cho phép quản trị viên chương trình nhận kết quả của tôi.",
    "medical_screening": "Tầm soát y tế",
    "show_service_at_this_location": "Show service at this location",
    "verbal_consent_provided": "In accordance with the Americans with Disabilities Act, participant or their legally authorized decision maker provided verbal consent. Written consent shall be obtained from participant or their legally authorized decision maker within 24 hours.",
    "unexpired_written_consent_present": "Testing site has unexpired written consent on file.",
    "select_to_proceed": "Please select an option above to proceed.",
    "guardians_information": "Guardians information",
    "registrars_information": "Registrar's information",
    "optional": "TÙY LỰA CHỌN",
    "vfc_eligibility": "Vaccine for Children (VFC) eligibility",
    "vfc_eligibility_subtext": "Your child may be able to get no-cost vaccines through CDC’s Vaccines for Children (VFC) Program at healthcare providers offices, pharmacies and health clinics that are enrolled in the VFC Program."
  },
  "gender_key": {
    "male": "Nam",
    "female": "Nữ",
    "cisgender": "Người hợp giới",
    "non_binary": "Đa dạng giới hoặc phi nhị giới",
    "other": "Khác",
    "prefer_to_self_describe": "Danh tính không được liệt kê",
    "prefer_not_to_disclose": "Từ chối trả lời",
    "transgender_male": "Chuyển đổi giới tính Nam",
    "transgender_female": "Chuyển đổi giới tính Nữ",
    "unknown": "Không xác định",
    "non_binary_only": "Phi nhị giới",
    "intersex": "Liên giới tính",
    "transgender": "Chuyển giới",
    "gender_fluid": "Giới tính linh hoạt",
    "not_applicable": "Không áp dụng"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Người Mỹ da đỏ hoặc thổ dân Alaska",
    "american_indian_central_america": "Người Mỹ da đỏ từ Nam hoặc Trung Mỹ",
    "asian": "Người Châu Á",
    "black": "Người Mỹ da đen hoặc người Mỹ gốc Phi",
    "latinx": "Người châu Mỹ La-tinh hoặc người Tây Ban Nha",
    "middle_eastern_north_african": "Người Trung Đông hoặc Bắc Phi",
    "native_hawaiian_pacific_islander": "Người Hawaii bản địa hoặc người Đảo Thái Bình Dương",
    "white_european": "Người da trắng",
    "unknown": "Không xác định",
    "prefer_not_to_disclose": "Không muốn tiết lộ",
    "asian_indian": "Người Châu Á Ấn Độ",
    "filipino": "Người Philippine",
    "japanese": "Người Nhật Bản",
    "korean": "Người Hàn Quốc",
    "vietnamese": "Người Việt Nam",
    "other_asian": "Người Châu Á khác",
    "native_hawaiian": "Người Hawaii bản địa",
    "guamanian_or_chamorro": "Người Guam hoặc Chamorro",
    "samoan": "Người Samoa",
    "other_pacific_islander": "Người Đảo Thái Bình Dương khác",
    "chinese": "Người Trung Quốc",
    "help": "Chủng tộc đề cập đến một tập hợp các đặc điểm thể chất được sử dụng để phân biệt giữa các nhóm người. Chủng tộc có thể có hoặc có thể không phù hợp với quốc tịch, hoặc quốc gia mà quý vị có quốc tịch.",
    "subtitle": "Các quy định yêu cầu chúng tôi thu thập tất cả các thông tin sau.",
    "laotian": "Người Lào",
    "cambodian": "Người Campuchia",
    "other": "Khác",
    "bangladeshi": "Người Bangladesh",
    "hmong": "H'Mông",
    "indonesian": "Người Indonesia",
    "malaysian": "Người Malaysia",
    "pakistani": "Người Pakistan",
    "sri_lankan": "Người Sri Lanka",
    "thai": "Người Thái",
    "taiwanese": "Người Đài Loan",
    "fijian": "Người Fiji",
    "guamanian": "Người Guam",
    "tongan": "Người Tonga"
  },
  "languages": {
    "en": "Tiếng Anh",
    "fr": "Tiếng Pháp",
    "es": "Tiếng Tây Ban Nha",
    "so": "Tiếng Somali",
    "hmn": "Tiếng Hmong",
    "asl": "Ngôn ngữ ký hiệu Mỹ (ASL)",
    "kar": "Tiếng Karen (Tiếng Hán - Tạng) ",
    "am": "Tiếng Amhara",
    "ru": "Tiếng Nga",
    "om": "Tiếng Oromo"
  },
  "symptoms": {
    "fever": "Sốt hoặc ớn lạnh",
    "cough": "Ho",
    "muscle_aches": "Đau cơ",
    "severe_fatigue": "Mệt mỏi (hơn bình thường)",
    "trouble_breathing": "Khó thở",
    "diarrhea": "Tiêu chảy",
    "loss_of_smell": "Mất khứu giác",
    "loss_of_taste": "Mất vị giác",
    "shortness_of_breath": "Thở ngắn, hụt hơi hoặc khó thở",
    "headache": "Đau đầu",
    "sore_throat": "Đau cổ họng",
    "congestion": "Ngạt mũi hoặc chảy nước mũi",
    "nausea": "Buồn nôn hoặc nôn mửa",
    "close_contact": "Tiếp xúc gần gũi với người nhiễm bệnh",
    "helper_text": {
      "close_contact": "*Quý vị đã tiếp xúc gần (trong vòng 6 feet trong ít nhất 15 phút) với người đã xác nhận là nhiễm COVID-19 chưa?"
    },
    "suspected_exposure": "Nghi ngờ có phơi nhiễm",
    "none": "Không có triệu chứng"
  },
  "instructions": {
    "title": "Quý vị đã đăng ký nhưng trước khi đi ...",
    "p1": "Trang này chứa thông tin về cuộc hẹn cũng như mã vạch cuộc hẹn của quý vị.",
    "appointment_scheduled": "Cuộc hẹn đã được lên lịch",
    "verify_info": {
      "title": "Xác nhận thông tin của quý vị",
      "p1": "Xác nhận thông tin liên lạc của quý vị là rất quan trọng, để quý có thể truy cập cổng thông tin bệnh nhân của mình một cách nhanh chóng.",
      "p2": "Một xác nhận đã được gửi đến email của quý vị. Vui lòng nhấp vào liên kết được cung cấp.",
      "p3": "Nếu quý vị cần thay đổi thông tin liên hệ của mình, vui lòng nhấn nút trợ giúp."
    },
    "verified": "Đã xác nhận",
    "not_verified": "Chưa xác nhận",
    "resend_text": "Chưa nhận được liên kết?",
    "resend": "Gửi lại",
    "skip": "Bỏ qua xác thực và hiển thị xác nhận",
    "name": "Tên",
    "appointment_details": "Lịch hẹn",
    "date_and_time": "Ngày và thời gian",
    "add_to_calendar": "Thêm vào lịch",
    "instructions": "Hướng dẫn chung",
    "successfully_flash": "Đã tạo cuộc hẹn thành công!",
    "success_flash_email": "Email đã được xác nhận thành công",
    "success_flash_phone": "Số điện thoại đã được xác nhận thành công",
    "mail_order": "Quý vị sẽ nhận được bộ dụng cụ kiểm tra của mình qua đường bưu điện trong tuần.",
    "at_home": "Một nhân viên y tế sẽ đến nhà quý vị vào khoảng thời gian này",
    "at_home_instructions": "Hướng dẫn ở nhà",
    "mail_order_instructions": "Hướng dẫn đặt hàng qua thư",
    "request_additional_appointment": "Đặt lịch hẹn tái khám",
    "book_additional_appointment": "Đặt lịch hẹn khác",
    "confirmation_code": "Mã xác nhận",
    "completed": "Hoàn thành",
    "appointment_barcode": "Mã vạch lịch hẹn",
    "dependent_links": "Các liên kết lịch hẹn gia đình",
    "on_demand_title": "Quý vị đã đăng ký nhưng trước khi đi ...",
    "save_link": "Ghi chú liên kết này",
    "verify_phone": "Xác nhận số điện thoại của quý vị",
    "verify_phone_description": "Xác nhận thông tin liên lạc của quý vị là rất quan trọng, để quý có thể truy cập các hồ sơ của mình một cách nhanh chóng. Một tin nhắn đã được gửi tới số điện thoại của quý vị",
    "verify_email_description": "Xác nhận thông tin liên lạc của quý vị là rất quan trọng, để quý có thể truy cập các hồ sơ của mình một cách nhanh chóng. Một email đã được gửi tới email của quý vị",
    "information": "Thông tin đăng ký",
    "follow_up_appointment": "Lịch hẹn tái khám",
    "get_directions": "Nhận chỉ đường",
    "cancel": "Huỷ lịch hẹn",
    "reschedule_appointment": "Lên lịch lại cuộc hẹn",
    "reschedule_linked_appointments": "Lên lịch lại các cuộc hẹn",
    "no_slots": "Không còn thời gian trống",
    "check_results": "Kiểm tra cổng thông tin bệnh nhân",
    "follow_up_modal_header": "Làm ơn lên lịch hẹn cho mũi tiêm thứ 2 của quý vị",
    "are_you_sure_you_want_to_cancel": "Quý vị có chắc chắn muốn huỷ lịch hẹn này",
    "please_choose_cancellation_reason": "làm ơn lựa chọn một lý do huỷ hẹn dưới đây",
    "additional_details": "làm ơn thêm các chi tiết vào dưới đây",
    "errors": {
      "missing_cancellation_reason": "làm ơn lựa chọn một lý do huỷ hẹn "
    },
    "verify_email": "Xác nhận email",
    "redcap_url": "Nhấp để hoàn thành khảo sát của quý vị",
    "verify_contact_information": "Xác nhận thông tin liên lạc",
    "please_also": "Xin vui lòng",
    "new_title": "Xác nhận cho {{name}}",
    "contact_method": "Phương thức liên lạc",
    "next_steps": {
      "title": "Bước tiếp theo",
      "p1": "Bạn có một bộ xét nghiệm để kích hoạt. Khi bạn sẵn sàng làm xét nghiệm, hãy truy cập đường dẫn được gửi đến e-mail của bạn để quay về trang này và kích hoạt bộ xét nghiệm bên dưới.",
      "administered": "Chúng tôi sẽ liên lạc với bạn khi bộ xét nghiệm của bạn tới phòng xét nghiệm và khi có kết quả. Kết quả thông thường có trong vòng 3 – 5 ngày sau khi tới phòng xét nghiệm. Bạn có thể xem kết quả từ trang [Lịch sử y tế]({{link}}) khi có kết quả.",
      "resulted": "Kết quả có ở trang [Lịch sử y tế]({{link}}).",
      "shipping_information": "Thông tin giao nhận"
    },
    "save_this_page": {
      "title": "Lưu trang này",
      "p1": "Dùng trang này để báo cáo tự xét nghiệm hoặc xem kết quả qua lịch sử y tế.",
      "p2": "Sau này để truy cập trang này, nhấp vào đường trong e-mail hoặc tin nhắn SMS xác nhận của bạn.",
      "p3": "Bạn có thể lưu trang này lại bằng cách đánh dấu, thêm trang vào màn hình chính hoặc sao chép đường dẫn đến chỗ an toàn."
    },
    "show_my_barcode": "Hiện mã vạch",
    "my_account": "Tài khoản",
    "register_another": "Đăng ký người tham gia khác",
    "update_vaccine": "Cập nhật thông tin tiêm chủng",
    "medical_history": "Lịch sử y tế",
    "upcoming_appointments": "Upcoming appointments",
    "reschedule": "Reschedule",
    "resend_confirmation": "Resend confirmation",
    "appointment_details_v2": "Appointment details",
    "confirm_cancellation": "Confirm cancellation",
    "confirm_cancellation_question": "Are you sure you want to cancel this appointment?",
    "select_new_appointment_time_below": "Select a new appointment time below. If you need to modify the services or appointment location, please cancel this appointment and schedule a new one.",
    "no_take_me_back": "No, take me back",
    "yes_cancel_appointment": "Yes, cancel appointment",
    "update_appointment": "Update appointment",
    "select_new_appointment_time": "Select new appointment time",
    "clinic": "Clinic",
    "services": "Services",
    "appointment_missed": "Appointment missed",
    "appointment_canceled": "Appointment canceled"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Mã truy cập không hợp lệ"
    },
    "landing": {
      "p1": "Để xem kết quả của quý vị, làm ơn nhập:",
      "access_code_label": "Mã truy cập",
      "submit_button": "Gửi",
      "recent_results": "Kết quả gần đây của quý vị"
    },
    "view_results": {
      "headline": "Các kết quả thử nghiệm cho {{name}}",
      "reregister": "Đăng ký lại",
      "status": {
        "likely_positive": "Kết quả không xác định",
        "test_not_performed": "Cần thử nghiệm lại",
        "results_ready": "Kết quả đã sẵn sàng",
        "processing": "Đang xử lý",
        "test_status": "Trạng thái thử nghiệm",
        "test_result": "Kết quả thử nghiệm",
        "administered": "Được quản lý",
        "results_pending": "Đang chờ kết quả",
        "test_results": "Kết quả xét nghiệm"
      },
      "at": "Tại {{address}}"
    },
    "result_label": "Kết quả",
    "result": {
      "flu_a": {
        "result_label": "Kết quả Cúm A"
      },
      "flu_b": {
        "result_label": "Kết quả Cúm B"
      },
      "covid19": {
        "result_label": "Kết quả COVID-19"
      },
      "covid": {
        "result_label": "Kết quả COVID-19"
      },
      "sars": {
        "result_label": "Kết quả SARS"
      },
      "control": {
        "result_label": "Kiểm soát"
      },
      "rsv": {
        "result_label": "Kết quả RSV"
      },
      "result": {
        "result_label": "Kết quả"
      },
      "hba1c": {
        "normal": "Bình thường",
        "warning": "Tiền tiểu đường",
        "danger": "Tiểu đường"
      },
      "lead_venous": {
        "danger": "Bất thường",
        "normal": "Bình thường"
      },
      "tc": {
        "result_label": "Kết quả TC"
      },
      "hdl": {
        "result_label": "Kết quả HDL"
      },
      "trg": {
        "result_label": "Kết quả TRG"
      },
      "ldl": {
        "result_label": "Kết quả LDL"
      },
      "non_hdl": {
        "result_label": "Kết quả Non-HDL"
      },
      "tc_hdl_ratio": {
        "result_label": "Tỷ lệ TC/HDL"
      },
      "glu": {
        "result_label": "Kết quả GLU"
      },
      "alere_cholestech_ldx": {
        "danger": "Có nguy cơ",
        "warning": "Bất thường",
        "normal": "Bình thường"
      },
      "lead": {
        "result_label": "Chì"
      },
      "zinc": {
        "result_label": "Kẽm"
      },
      "lead_standard_profile": {
        "danger": "Bất thường",
        "normal": "Bình thường"
      },
      "creatinine": {
        "danger": "Bất thường",
        "normal": "Bình thường"
      },
      "igg": {
        "result_label": "IgG result"
      },
      "igm": {
        "result_label": "IgM result"
      },
      "blood_glucose_fasted": {
        "normal": "Bình thường",
        "warning": "Cao",
        "danger": "Cao"
      },
      "total_cholesterol_fasted": {
        "normal": "Bình thường",
        "elevated": "Cao",
        "high": "Cao",
        "low": "Thấp"
      },
      "total_cholesterol_unfasted": {
        "normal": "Bình thường",
        "elevated": "Cao",
        "high": "Cao"
      },
      "a1c_now": {
        "normal": "Bình thường",
        "warning": "Tiền đái tháo đường",
        "danger": "Đái tháo đường"
      },
      "blood_glucose": {
        "warning": "Thấp",
        "normal": "Bình thường",
        "prediabetes": "Tiền tiểu đường",
        "danger": "Bệnh tiểu đường"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "Những tài liệu",
    "self_administered": "Kiểm tra tự quản lý được chọn tại {{location}}",
    "patient": "Bệnh nhân",
    "medical_history": "Lịch sử y tế",
    "overview_title": "Chọn xét nghiệm hoặc dịch vụ để xem thêm chi tiết và những mục hành động bổ sung nào cần thiết. Lưu ý rằng một kết quả xét nghiệm có thể chưa hoặc chỉ có sau khi thảo luận với nhà cung cấp.",
    "insurance_information": "Điền thông tin bảo hiểm",
    "contact_support": "Nếu bạn cần hỗ trợ hoặc đổi thông tin liên lạc, hãy liên lạc với bộ phận hỗ trợ.",
    "show": "Hiện",
    "hide": "Ẩn",
    "lab_report": "Báo cáo xét nghiệm",
    "contact_provider": {
      "header": "Bạn có kết quả dương tính",
      "description": "Bạn có muốn nói chuyện với nhà cung cấp để thảo luận về kết quả và điều trị?",
      "yes_option_text": "Có, tôi muốn nói chuyện với nhà cung cấp",
      "no_option_text": "Không, tôi đã đọc và hiểu kết quả, tôi không muốn nói chuyện với nhà cung cấp",
      "confirm_phone_number_header": "Xác nhận số điện thoại",
      "confirm_phone_number_description": "Vui lòng xác nhận số điện thoại phù hợp nhất để tư vấn.",
      "confirm_button_text": "Xác nhận",
      "consultation_confirmed_header": "Đã xác nhận tư vấn",
      "consultation_confirmed_description": "Nhà cung cấp sẽ gọi bạn vào số {{phone_number}} trong vòng 2 – 3 ngày làm việc.",
      "acknowledgement_option_helper_text": "Giải thích tầm quan trọng của việc điều trị và cho người dùng biết cách lên lịch cuộc gọi nếu người dùng đổi ý.",
      "acknowledgement_confirmed_header": "Đã xác nhận nhận được",
      "acknowledgement_confirmed_description": "Nói về tầm quan trọng của việc được điều trị với đường dẫn đến nguồn tài liệu. Nhắc nhở rằng việc tư vấn hoàn toàn miễn phí, nhà cung cấp có thể kê toa thuốc hoặc bất kỳ thứ gì khác để giúp họ điều trị nhiễm trùng.",
      "acknowledgement_confirmed_change_mind_text": "Nếu bạn đổi ý, chỉ cần nhấn nút \"Tôi cần tư vấn\" bên dưới.",
      "request_consultation_button_text": "Tôi cần tư vấn"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Địa chỉ của quý vị không phù hợp với các yêu cầu về tính đủ điều kiện cho các địa điểm trong chương trình này."
    }
  },
  "member": {
    "medical_history": "Lịch sử tìm kiếm",
    "view_instructions": "Xem Xác nhận",
    "next_appointment": "Lịch hẹn tiếp theo",
    "over_18": "Tôi xác nhận rằng tôi trên 18 tuổi",
    "choose_test_configurations_title": "Vui lòng chọn (các) dịch vụ quý vị muốn cho cuộc hẹn này",
    "member_taken_error": "Người dùng đã có tài khoản",
    "choose_test_configurations_subtitle": "(Các) dịch vụ sẽ được áp dụng cho tất cả các thành viên trong gia đình",
    "service": "Dịch vụ",
    "group_or_location": "Nhóm/ Địa điểm",
    "details": "Chi tiết",
    "see_all": "Xem tất cả",
    "no_history": "Không có lịch sử tại thời điểm hiện tại.",
    "medical_history_title_with_name": "Tên lịch sử tìm kiếm",
    "no_dashboard": "Hiện tại không có gì có sẵn",
    "product": "Sản phẩm",
    "price": "Giá",
    "quantity": "Số lượng",
    "total_services_selected": "Tổng số dịch vụ được chọn",
    "total_price": "Thành tiền"
  },
  "or": "hoặc",
  "account": {
    "errors": {
      "must_be_13": "Phải đủ 13 tuổi để đăng ký một tài khoản"
    }
  },
  "self_administration": {
    "title": "Tự Quản lý",
    "self_administer_action": "Tự điều hành/ Tự tiêm",
    "skip_to_link": "Chuyển đến Tự quản lý",
    "select_person": "Lựa chọn một người để thanh toán",
    "adult": "người lớn",
    "child": "trẻ em",
    "checked_out": "ĐÃ THANH TOÁN",
    "go_back": "Quay lại",
    "switch_to_scanner": "Chuyển sang máy quét",
    "enter_barcode": "Nhập mã vạch",
    "scan_barcode": "Quét mã vạch",
    "cancel": "Hủy bỏ",
    "barcode_for": "Mã vạch cho",
    "enter_barcode_manually": "Nhập mã vạch theo cách thủ công",
    "instructions": "Quét hoặc nhập mã vạch nằm trên ống nghiệm của bạn bên dưới.",
    "regex_prefix": "Mã vạch phải bao gồm",
    "all_completed": "Đã hoàn thành: Tất cả các thành viên trong gia đình đã hoàn thành bước này",
    "errors": {
      "no_appointment": "Sự cố khi tìm cuộc hẹn",
      "barcode_in_use": "Mã vạch này đã được sử dụng trước đây. Nếu trước đây bạn không tự quản lý bộ tài liệu này, vui lòng trao đổi với nhân viên tại địa điểm đã cung cấp bộ công cụ cho bạn và yêu cầu bộ công cụ mới. Nếu không, hãy nhấp vào nút trợ giúp bên dưới.",
      "test_error_general": "Sự cố khi tạo thử nghiệm",
      "different_barcodes": "Mã vạch bạn đã nhập không khớp."
    },
    "confirm_barcode_input": "Nhập lại mã vạch để xác nhận",
    "click_to_self_test": "Bấm vào đây để tự kiểm tra"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Danh sách chờ",
      "unavailable": "Không có cuộc hẹn"
    },
    "labels": {
      "address": "Địa chỉ",
      "next_appointment": "Cuộc hẹn có sẵn tiếp theo",
      "services": "Các dịch vụ có sẵn"
    }
  },
  "waiting_room": {
    "header": "Quý vị đang ở trong phòng chờ; vui lòng đợi trong khi chúng tôi kết nối quý vị với hệ thống lên lịch hẹn.",
    "p1": "Xin hãy kiên nhẫn. Chúng ta sẽ cùng nhau kết thúc đại dịch này.",
    "signature": "Trân trọng",
    "p2": "Chúng tôi biết trải nghiệm COVID-19 có thể gây khó chịu. Vui lòng đợi ở đây trong khi chúng tôi làm việc nhanh nhất có thể để đưa quý vị đến trang để đặt lịch hẹn."
  },
  "users": {
    "send_appointment_confirmation_message": "Xin chào {{full_name}}. Đây là lời nhắc cho cuộc hẹn của quý vị tại {{name}}. Cuộc hẹn của bạn vào {{date}} lúc {{time}} tại {{address}}.",
    "send_registration_link": "Xin chào, {{full_name}}. Nhấp vào liên kết này để đăng ký {{name}} ${register_link} của quý vị"
  },
  "follow_up": {
    "first_dose": "Mũi tiêm liều đầu tiên",
    "previous_appointment": "Lịch hẹn trong quá khứ",
    "booked_appointment": "Lịch hẹn của quý vị",
    "second_dose": "Mũi tiêm liều thứ hai",
    "choose_second_location": "Chọn một địa điểm cuộc hẹn thứ hai"
  },
  "cancellation_types": {
    "cant_get_to_location": "Tôi đã có một sự cố đối lập về thời gian",
    "timing_conflict": "Tôi đã bị bệnh với COVID-19",
    "became_ill_with_covid19": "Tôi đã nhận dịch vụ này ở nơi khác",
    "received_service_elsewhere": "Khác",
    "other": "Nhập tên thay thế",
    "duplicate_appointment": "Cuộc hẹn bị trùng lặp"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Nhập tên",
      "type_name": "Nhập chữ ký thay vào đó"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Mã truy cập của quý vị là",
      "please_confirm_your_email": "Vui lòng xác nhận email của quý vị bằng cách nhấp vào nút bên dưới",
      "hello": "Xin chào",
      "click_to_reschedule": "Nhấp để Hiển thị Hướng dẫn / Lên lịch lại",
      "click_to_reschedule_on_demand": "Nhấp để Hiển thị Hướng dẫn"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Cuộc hẹn của bạn vào lúc {{time}} ở {{location}} đã bị hủy. Nếu đây là nhầm lẫn, vui lòng gửi e-mail đến support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Tên của người tham gia",
    "date": "Ngày",
    "signature": "Chữ ký của người tham gia",
    "and_or": "VÀ/HOẶC",
    "guardian_name": "Tên của phụ huynh/người giám hộ",
    "guardian_signature": "Chữ ký của phụ huynh/người giám họ"
  },
  "employer_testing": {
    "hello_name": "Xin chào {{name}}",
    "results": {
      "negative": "Âm tính",
      "positive": "Dương tính",
      "did_not_result": "Không xác nhận",
      "unknown": "Không thể xác định",
      "invalid": "Không hợp lệ",
      "pending": "Đang chờ"
    },
    "code_reader": {
      "scan_your_code": "Quét mã trên thẻ kiểm tra của quý vị",
      "find_in_top_right_hand_corner": "Mã quét là duy nhất cho thử nghiệm kiểm tra của quý vị và có thể được tìm thấy ở góc trên cùng bên phải của thử nghiệm bài kiểm tra",
      "center_code": "Căn mã ở giữa đây.",
      "already_used_error": "Mã vạch thử nghiệm này đã được sử dụng. Vui lòng liên hệ với nhà cung cấp bộ mẫu xét nghiệm của quý vị để được hỗ trợ.",
      "click_to_scan": "Nhấn để quét mã QR",
      "scan_new_test_card": "Nhấn vào đây để quét thẻ kiểm tra mới.",
      "not_working": "Không hoạt động?",
      "try_again": "Nhấn vào đây để thử lại"
    },
    "continue": "Tiếp tục",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Làm ơn xác nhận ngày tháng năm sinh của người tham gia để tiếp tục",
      "contact_administrator": "Nếu thông tin hiển thị không chính xác, vui lòng liên hệ với quản trị viên để được hỗ trợ",
      "error": "Ngày tháng năm sinh không chính xác - làm ơn thử lại hoặc vui lòng liên hệ với quản trị viên để cập nhật thông tin này"
    },
    "result_entry": {
      "code_is_registered": "Mã của quý vị đã được đăng ký",
      "take_the_test": "Hãy làm thử nghiệm kiểm tra",
      "follow_the_instructions": "Làm theo hướng dẫn đi kèm với bài kiểm tra của quý vị và lên lịch hẹn giờ 15 phút sau khi quý vị đã cho tăm bông vào lọ trên thẻ kiểm tra.",
      "cant_find_instructions": "Không tìm thấy hướng dẫn?",
      "view_instructions": "Xem các hướng dẫn cho Binax Now",
      "start_timer": "BẮT ĐẦU HẸN GIỜ 15 PHÚT (TÙY LỰA CHỌN)",
      "submit_within_15": "Mẹo: Gửi kết quả của quý vị trong vòng 15 phút",
      "enter_test_results": "Nhập kết quả thử nghiệm",
      "choose_a_result": "Lựa chọn kết quả thẻ thử nghiệm của quý vị",
      "submit": "Gửi",
      "must_log_result_and_photo": "Quý vị phải ghi lại kết quả và chụp hình kiểm tra thử nghiệm của mình để tiếp tục",
      "submit_within_15_v2": "Mẹo: Gửi kết quả của quý vị sau khi thử nghiệm đã kiểm tra sau 15 phút",
      "click_to_take_photo": "Nhấn để chụp hình",
      "results_may_be_invalid": "Kết quả kiểm tra có thể không hợp lệ"
    },
    "summary": {
      "title": "Tóm tắt kết quả",
      "negative_subtitle": "Quý vị không có COVID-19",
      "negative_message": "Chúng tôi đã gửi một thông báo cho nơi làm việc của quý vị rằng quý vị được phép quay lại làm việc.",
      "positive_subtitle": "Chúng tôi xin lỗi, quý vị đã nhiễm COVID-19",
      "positive_message": "Chúng tôi đã gửi một thông báo cho nơi làm việc của quý vị rằng quý vị đã nhiễm COVID-19",
      "what_should_you_do": "Quý vị nên làm gì:",
      "employer_name_resources": "Tài nguyên Tên nơi làm việc",
      "follow_cdc_guidelines": "Tuân theo các Nguyên tắc Khuyến nghị của CDC"
    },
    "clarifying_results": {
      "title": "Làm rõ kết quả của quý vị",
      "which_did_your_result_most_look_like": "Kết quả của quý vị giống nhất với?",
      "no_lines": "Không thấy vạch",
      "blue_control_line_only": "Chỉ có vạch xanh kiểm soát",
      "pink_sample_line_only": "Chỉ có vạch hồng mẫu",
      "blue_control_line_and_pink_sample_line": "Vạch xanh kiểm soát VÀ vạch hồng/tím mẫu",
      "still_not_sure": "Không chắc chắn",
      "submit_results": "Gửi kết quả",
      "thank_you": "Cảm ơn quý vị!",
      "clarification_received_message": "Kết quả làm rõ của bạn đã được nhận.",
      "only_pink_line": "Chỉ dòng màu hồng",
      "no_lines_v2": "Không có dòng"
    },
    "return_to_confirmation": "Quay lại trang Xác nhận"
  },
  "preferred_language": {
    "title": "Ngôn ngữ lựa chọn",
    "subtitle": "Bạn muốn ngôn ngữ nào?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Loạt vắc-xin ban đầu",
    "one_dose": "Đặt một cuộc hẹn cho một mũi tiêm liều cụ thể. Đặc biệt, đây là",
    "title": "Lựa chọn liều",
    "first_dose": "Mũi tiêm liều đầu tiên",
    "second_dose": "Mũi tiêm liều thứ hai",
    "single_dose_title": "Một liều duy nhất",
    "additional": "Thêm vào",
    "booster": "Tăng cường",
    "supplemental": "Bổ sung",
    "third_dose": "Chỉ có liều thứ ba",
    "no_vaccine": "Không có cái nào ở trên"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "nên có định dạng {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "nên có định dạng {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "Không tìm được thử nghiệm kiểm tra"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "người dùng tồn tại và không được phép cập nhật "
            },
            "date_of_birth": {
              "invalid_date": "nên có định dạng {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "giới tính không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{genders}}"
            },
            "phone_number": {
              "invalid": "là một số không hợp lệ"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "không thể điền vào nếu giới tính không phải là \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "giới tính không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "xu hướng tình dục không tồn tại, hãy chọn từ các giá trị có thể chấp nhận sau: {{sex_orientations}}"
            },
            "status": {
              "unknown_status": "trạng thái không hợp lệ, hãy chọn từ các giá trị được chấp nhận sau: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{races}}",
                "other": "{{unknown_races}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{races}}"
              }
            },
            "vaccination_status": {
              "unknown_vaccination_status": "vaccination status is invalid, pick from the following acceptable values: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "không có đặc quyền để đặt vai trò"
            },
            "user_id": {
              "unknown_user": "Người dùng không được tìm thấy"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "JSON không hợp lệ"
            },
            "user_id": {
              "unknown_user": "Người dùng không được tìm thấy"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "Địa điểm lịch hẹn không tìm thấy"
            },
            "date_of_birth": {
              "invalid_date": "nên có định dạng {{datetime_format}}"
            },
            "email": {
              "duplicated": "đã được sử dụng"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "giới tính không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{genders}}"
            },
            "phone_number": {
              "invalid": "là một số không hợp lệ"
            },
            "population": {
              "unknown_population": "{{unknown_population}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "không thể điền vào nếu giới tính không phải là \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "giới tính không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "xu hướng tình dục không tồn tại, hãy chọn từ các giá trị có thể chấp nhận sau: {{sex_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{races}}",
                "other": "{{unknown_races}} không tồn tại, hãy chọn từ các giá trị được chấp nhận sau: {{races}}"
              }
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "consent_form not found"
            },
            "access_code": {
              "unknown_user": "user not found"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "should have format {{date_format}}"
            },
            "phone_number": {
              "invalid": "is an invalid number"
            },
            "email": {
              "duplicated": "is already used"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} does not exist, pick from the following acceptable values: {{races}}",
                "other": "{{unknown_races}} do not exist, pick from the following acceptable values: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "gender does not exist, pick from the following acceptable values: {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "sexual_orientation does not exist, pick from the following acceptable values: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "cannot fill it if the gender is not \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "sex does not exist, pick from the following acceptable values: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} does not exist, pick from the following acceptable values: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} do not exist, pick from the following acceptable values: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} is an invalid value, pick from the following acceptable values: {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} does not exist, pick from the following acceptable values: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "appointment location not found"
            },
            "appointment_date": {
              "invalid_date": "should have format {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} is not a valid dose, pick from the following acceptable values: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} is not available within the vaccine_lot, pick from the following available values: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "should have format {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "should have format {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "is not a valid JSON"
            },
            "name": {
              "taken": "và địa chỉ đã được sử dụng ở một địa điểm khác"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "User",
          "other": "Users"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Location",
          "other": "Locations"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Người châu Mỹ La-tinh hoặc người Tây Ban Nha",
    "not_hispanic": "Không phải Người châu Mỹ La-tinh hoặc người Tây Ban Nha",
    "hispanic_expanded": {
      "other": "Người gốc châu Mỹ La-tinh hoặc người Tây Ban Nha ",
      "mexican": "Người Mexico, Người Mỹ gốc Mexico, Chicano",
      "puerto_rican": "Ngươid Puerto Rico",
      "cuban": "Người Cuba"
    },
    "subtitle": "Các quy định yêu cầu chúng tôi thu thập các thông tin sau",
    "help": "Dân tộc là một tập hợp các đặc điểm chung như ngôn ngữ, phong tục văn hóa, tôn giáo và các đặc điểm khác được sử dụng để phân biệt các nhóm người. Nó cũng có thể có hoặc có thể không phù hợp với bản sắc chủng tộc.",
    "unknown": "Không xác định",
    "title": "Dân tộc"
  },
  "sex_at_birth": {
    "question": "Giới tính",
    "female": "Nữ",
    "male": "Nam",
    "help": "Giới tính đề cập đến những gì trên giấy khai sinh của quý vị.",
    "non_binary": "Phi nhị giới",
    "subtitle": "Các quy định yêu cầu chúng tôi thu thập tất cả các thông tin sau",
    "decline": "Từ chối trả lời",
    "unknown": "Không xác định"
  },
  "gender": {
    "help": "Giới tính đề cập đến cách quý vị nhận dạng bản thân. Nó có thể phù hợp hoặc có thể không phù hợp với giới tính mà quý vị được chỉ định khi sinh.",
    "subtitle": "Nếu quý vị muốn thêm thông tin về giới tính của mình, vui lòng thêm."
  },
  "sexual_orientation": {
    "title": "Xu hướng tình dục",
    "subtitle": "Nếu quý vị muốn thêm thông tin về xu hướng tình dục của mình, vui lòng thêm.",
    "gay": "Đồng tính nam, đồng tính nữ hoặc đồng tính luyến ái",
    "heterosexual": "Dị tính",
    "bisexual": "Lưỡng tính",
    "questioning": "Phân vân/không chắc/không biết",
    "prefer_not_to_disclose": "Chọn không tiết lộ",
    "unknown": "Không xác định",
    "orientation_not_listed": "Xu hướng không được liệt kê",
    "not_applicable": "Không áp dụng",
    "pansexual": "Toàn tính luyến ái",
    "queer": "Queer"
  },
  "pronouns": {
    "title": "Đại từ ưu tiên",
    "he": "Anh ta",
    "she": "Cô ta",
    "they": "Họ",
    "choose_not_to_disclose": "Chọn không tiết lộ",
    "prefer_to_self_describe": "Không ưu tiên"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Giấy phép Lái xe hoặc Số ID Tiểu bang",
    "driver_license": "Giấy phép lái xe",
    "use_ssn_instead": "Sử dụng SSN thay thế",
    "social_security_number": "Số chứng minh nhân dân",
    "state": "Tiểu bang",
    "upload_front_of_driver_license": "Đăng tải mặt trước của bằng lái xe hoặc số chứng minh nhân dân",
    "choose_file": "Chọn thư mục",
    "no_file_chosen": "Không có thư mục được chọn",
    "no_identification": "Tôi không có giấy tờ tuỳ thân",
    "insurance_card_has_a_back": "Thẻ bảo hiểm có mặt sau",
    "upload_front_of_insurance_card": "Đăng tải mặt trước của thẻ bảo hiểm",
    "front_of_insurance_card_uploaded": "Mặt trước của thẻ bảo hiểm đã được đăng tải",
    "insurance_card": "Thẻ bảo hiểm",
    "insurance_card_back": "Mặt sau của thẻ bảo hiểm",
    "back_of_insurance_card_uploaded": "Mặt sau của thẻ bảo hiểm đã được đăng tải"
  },
  "quidel": {
    "certify_text": "Tôi xác nhận rằng tôi chỉ ghi lại kết quả cho bản thử nghiệm kiểm tra này một lần.",
    "entering_results": "Đang nhập kết quả thử nghiệm của quý vị",
    "review_instructions_1": "Xem xét kỹ các hướng dẫn",
    "review_instructions_2": "Quý vị có thể xem lại hướng dẫn đi kèm với bài kiểm tra của mình, đọc hướng dẫn từng bước hoặc xem video hướng dẫn bên dưới:",
    "read_instructions": "Đọc hướng dẫn ",
    "watch_video": "Xem video",
    "timer": "Bắt đầu hẹn giờ sau khi khuấy tăm bông trong ống. Bộ đếm thời gian sẽ dừng sau 1 phút (để quý vị lấy miếng gạc ra khỏi ống). Xin lưu ý: Quý vị phải hẹn giờ thêm 10 phút để có kết quả.",
    "start_timer": "BẮT ĐẦU HẸN GIỜ (TÙY LỰA CHỌN)",
    "restart_timer": "Dừng và bắt đầu",
    "resume": "Tiếp tục",
    "one_minute_countdown": "1 phút đã trôi qua! Lấy miếng gạc của quý vị ra khỏi ống và tiếp tục hẹn giờ.",
    "take_photo": "Chụp ảnh mẫu thử nghiệm của bạn",
    "photo_warning": "Quý vị phải chụp hình để tiếp tục",
    "enter_results": "Nhập kết quả thử nghiệm của quý vị",
    "choose_result": "Chọn tùy chọn phản ánh tốt nhất mẫu thử nghiệm của quý vị. Cần giúp đỡ? Bấm vào đây để xem kết quả ví dụ.",
    "positive": "Dương tính",
    "negative": "Âm tính",
    "unknown": "Không xác nhận",
    "clarify": "Làm rõ kết quả của quý vị",
    "strip_question": "Mẫu thử nghiệm của quý vị trông giống cái nào nhất?",
    "no_lines": "Không có vạc",
    "pink_line": "Chỉ có vạch hồng",
    "not_sure": "Không chắc chắn"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Hình ảnh kết quả ",
    "retake_photo": "Chụp lại hình ảnh ",
    "capture_photo": "Chụp hình ảnh",
    "confirm_information_correct": "Tôi xác nhận thông tin trên đây là chính xác",
    "submit": "Đệ trình ",
    "title": "Nhập kết quả cho {{name}}",
    "subtitle": "Quý vị không cần tải xuống hoặc sử dụng ứng dụng iHealth COVID-19.",
    "instruction_title": "Xem hướng dẫn iHealth",
    "instruction_guide": "Đọc hướng dẫn",
    "video_guide": "Xem video",
    "add_photo": "Thêm hình ảnh",
    "confirm_result": "Xác nhận kết quả",
    "result_warning": "Lựa chọn kết quả để đệ trình",
    "confirm_warning": "Xác nhận kết quả để đệ trình",
    "administered_at_label": "Quý vị đã làm thử nghiệm kiểm tra khi nào? ",
    "instruction_subtitle": "Xem hướng dẫn đi kèm với bài kiểm tra của quý vị hoặc xem lại hướng dẫn tại đây."
  },
  "public_test_groups": {
    "title": "Bộ dụng cụ xét nghiệm COVID-19",
    "appointment_recoveries_title": "Quay trở lại?",
    "appointment_recoveries_button_text": "Tìm liên kết của tôi",
    "search_test_group_title": "Lần đầu tiên?",
    "search_test_group_button_text": "Đăng ký",
    "title_2": "Đăng ký và báo cáo đơn giản",
    "step_one": "Đăng ký tài khoản",
    "step_two": "Thu thập mẫu nước bọt để gửi trở lại",
    "step_three": "Xem kết quả trên mạng",
    "title_3": "Đăng ký để kích hoạt bộ dụng cụ xét nghiệm của quý vị",
    "population_title": "Đăng ký",
    "population_button": "Kích hoạt",
    "faq_subtitle": "Tìm hiểu thêm",
    "faq_title": "Các câu hỏi thường gặp",
    "faq_1": "Làm cách nào để gửi lại bộ xét nghiệm của tôi?",
    "faq_1_footer": "Xem hướng dẫn Thu thập Nước Bọt từ Phễu SalivaDirect",
    "faq_2": "Tôi đã đăng ký rồi nhưng tôi quên tài khoản của mình.",
    "faq_3": "Làm cách nào để kích hoạt bộ dụng cụ xét nghiệm của tôi?",
    "faq_2_subtitle": "Với tư cách là người dùng cũ, quý vị có thể xác nhận báo cáo của mình bằng cách sử dụng nút '** Tìm liên kết của tôi **' ở trên.",
    "faq_3_subtitle": "Vui lòng thực hiện quy trình tài khoản Primary.Health bằng cách sử dụng các tùy chọn bên dưới:",
    "footer_text_1": "Sản phẩm này chưa được FDA cho phép hoặc phê duyệt, nhưng đã được FDA cho phép sử dụng khẩn cấp theo EUA;",
    "footer_text_2": "Sản phẩm này chỉ được phép thu thập và duy trì các mẫu nước bọt để hỗ trợ phát hiện axit nucleic từ SARS-CoV-2, không dùng cho bất kỳ vi rút hoặc mầm bệnh nào khác;",
    "footer_text_3": "Việc sử dụng khẩn cấp sản phẩm này chỉ được phép trong khoảng thời gian đã được tuyên bố rằng có các trường hợp chứng minh cho việc cấp phép sử dụng khẩn cấp các thiết bị y tế theo Mục 564 (b) (1) của Đạo luật Thực phẩm, Dược phẩm và Hoa Kỳ Liên bang, 21 USC § 360bbb-3 (b) (1), trừ khi tuyên bố bị chấm dứt hoặc giấy phép bị thu hồi sớm hơn.",
    "description": "Để thuận tiện, quý vị có thể kích hoạt bộ thử nghiệm của mình bằng các nút bên dưới:",
    "subtitle": "Lần đầu tiên trên Primary?",
    "subtitle_1": "Thông tin về bộ dụng cụ kiểm tra COVID-19 của quý vị",
    "faq_1_1": "Đặt ống mẫu đã đậy nắp vào túi đựng mẫu sinh phẩm",
    "faq_1_2": "Đặt túi mẫu sinh phẩmvào hộp có nhãn SalivaDirect mà nó đi kèm. Đặt hộp có nhãn SalivaDirect vào FedEx UN 3373 Pak.",
    "faq_1_3": "Tháo lớp lót kết dính của FedEx UN 3373 Pak và ấn mạnh xuống để niêm phong Pak chặt chẽ.",
    "faq_1_4": "Vui lòng gửi mẫu của quý vị vào cùng ngày lấy mẫu và trước thời gian nhận hàng cuối cùng. Không giao mẫu của quý vị vào hộp thả thư vào cuối tuần.",
    "faq_1_5": "Truy cập trang web FedEx tại **[fedex.com/labreturns](https://www.fedex.com/labreturns)** để xem các vị trí hộp thả và lịch trình lấy hàng.",
    "demo": {
      "title": "COVID-19 at-home test kit additional information & instructions",
      "online_instructions": "Online instructions",
      "download_instructions": "Download instruction sheet",
      "begin": "Begin",
      "instructions": {
        "welcome_title": "Welcome to the at-home COVID-19 testing portal.",
        "welcome_text_1": "Before you start testing, wash you hands or use hand sanitizer.",
        "welcome_text_2": "Make sure your hands are dry before starting.",
        "continue": "Continue",
        "back": "Back",
        "step_one_title": "Step 1: Check your test kit",
        "step_one_text_1": "Locate the kit components:",
        "step_one_text_2": "It is recommended gloves (not provided) also be used during testing.",
        "step_two_title": "Step 2: Place tube in the tube holder",
        "step_two_text_1": "Remove cap from one **TUBE** and place it in the **TUBE HOLDER.**",
        "step_two_text_2": "**NOTE:** Use of gloves is recommended.",
        "step_five_title": "Step 5: Remove swab from the tube",
        "step_five_text_1": "After **ONE MINUTE**, remove the swab from the **TUBE** by rubbing the swab head against the inside wall of the tube to squeeze out as much liquid as possible.",
        "step_five_text_2": "Dispose of swab in the trash.",
        "step_six_title": "Step 6: Open the test strip",
        "step_six_text_1": "Open the **TEST STRIP** pouch carefully at the slit and hold the **TEST STRIP** as indicated.",
        "step_seven_title": "Step 7: Place test strip in the tube",
        "step_seven_text_1": "Place the **TEST STRIP** into the **TUBE** with the arrows pointing down. Leave the strip in the **TUBE** for a **FULL TEN MINUTES**– do not handle or remove.",
        "step_eight_title": "Step 8: Remove test strip from the tube",
        "step_eight_text_1": "At **TEN MINUTES**, remove the **TEST STRIP** from the **TUBE**. Next, place the **TEST STRIP** on the outline in Steps 10-12. Ensure the **TEST STRIP** is on a flat surface in good lighting.",
        "step_eight_text_2": "**Note:** The test is intended to be read at 10 minutes. If the test is read before this or is read more than 5 minutes after the indicated read time, results may be inaccurate (false negative, false positive, or invalid) and the test should be repeated.",
        "step_nine_title": "Step 9: Check your results",
        "step_nine_text_1": "There are three type of results possible.",
        "step_nine_text_2": "Check for a Positive Result",
        "step_nine_text_3": "Check for a Negative Result",
        "step_nine_text_4": "Check for an Invalid Result",
        "step_ten_title": "Step 10: Remove test strip from the tube",
        "step_ten_text_1": "Place the **TEST STRIP** on the test strip outline below and compare with test result examples shown.",
        "step_three_title": "Step 3: Swab the nostrils",
        "step_three_text_1": "Remove the **SWAB** from its wrapper, being careful not to touch the **SWAB** head.",
        "step_three_text_2": "Gently insert the **SWAB** ½ to ¾ of an inch into the nostril, depending on the size of the person’s nose. Firmly rub the **SWAB** in a circular motion around the inside wall of EACH NOSTRIL at least 4 times.",
        "step_three_text_3": "Be sure to rub BOTH nostrils with the SAME SWAB.",
        "step_three_text_5": "**NOTE:** Failure to swab properly may cause false negative results.",
        "step_four_title": "Step 4: Place swab in the tube",
        "step_four_text_1": "Immediately place the **SWAB** into the liquid inside the **TUBE**, and ensure it is touching the bottom. Stir 3-4 times.",
        "step_four_text_2": "Leave the swab in the solution for **ONE MINUTE**.",
        "step_four_text_3": "**NOTE**: If the swab is in the solution for more than 10-minutes it should not be used.",
        "step_three_text_4": "**NOTE:** If you are swabbing others, please wear a face mask. With children, you may not need to insert the swab as far into the nostril. For very young children, you may need another person to steady the child’s head while swabbing."
      },
      "choose_language": "Choose your language"
    }
  },
  "self_resulting": {
    "report_result": "Báo cáo kết quả",
    "report_result_subtitle": "Nếu tổ chức của bạn cung cấp dịch vụ tự kiểm tra và bạn sẵn sàng báo cáo kết quả đó, vui lòng nhấp vào nút bên dưới.",
    "need_help": "Cần sự trợ giúp?",
    "assistance": "Nếu bạn cần hỗ trợ hoặc cần thay đổi thông tin liên lạc, vui lòng liên hệ với Trung tâm hỗ trợ.",
    "contact_support": "Liên hệ với bộ phận hỗ trợ",
    "save_link": "Lưu liên kết này để báo cáo kết quả sau này",
    "send_via_email": "Gửi liên kết qua e-mail",
    "send_via_text": "Gửi liên kết qua tin nhắn SMS",
    "copy_link": "Sao chép liên kết",
    "resend_email": "Gửi lại e-mail xác minh",
    "phone_verified": "Số điện thoại đã được xác minh",
    "add_vaccination_status": "Thêm / Cập nhật trạng thái tiêm chủng vắc xin COVID-19",
    "terms_of_service": "Điều khoản dịch vụ",
    "verify_contact_information": "Xác minh thông tin liên lạc để nhận được kết quả và cách thức trao đổi. Nếu bạn cần thay đổi thông tin liên lạc, vui lòng liên hệ với Trung tâm hỗ trợ.",
    "resend_text": "Gửi lại tin nhắn SMS xác minh",
    "loading": "Đang tải",
    "add_to_account": "Thêm vào tài khoản",
    "hi": "Xin chào",
    "email_verified": "E-mail đã được xác minh",
    "go_to_my_account": "Chuyển đến tài khoản của tôi",
    "activate_kit": "Kích hoạt bộ dụng cụ xét nghiệm",
    "report_custom_result": "Báo cáo kết quả: {{test}}",
    "activate_custom_test": "Kích hoạt xét nghiệm: {{test}}",
    "update_vaccination_status": "Cập nhật tình trạng tiêm chủng",
    "order_a_test_kit": "Đặt kit xét nghiệm"
  },
  "enter_information": {
    "contact_info": "Nhập thông tin liên lạc của bạn",
    "label": "Số điện thoại hoặc địa chỉ e-mail",
    "invalid": "Số điện thoại hoặc địa chỉ e-mail không hợp lệ",
    "send": "Chúng tôi sẽ gửi mã xác minh vào số điện thoại hoặc địa chỉ e-mail",
    "legal": "Bằng cách nhập số điện thoại hoặc địa chỉ e-mail, bạn đã đồng ý với các [Điều khoản dịch vụ](https://primary.health/terms-of-service/) và [Chính sách bảo mật](https://primary.health/privacy-policy/) của Primary Health",
    "subtitle": "We will send you a text or email to help you find your records or link to report a test result!",
    "find_link": "Registered? Find your link",
    "not_registered": "Chưa đăng ký? Tìm địa điểm của bạn",
    "search_by_keyword": "Tìm theo tên, từ khóa, thành phố, mã ZIP hoặc mã tổ chức!",
    "search": "Tìm",
    "register_here": "Register here"
  },
  "contact_support": {
    "invalid": "Thông tin không hợp lệ",
    "invalid_explanation": "Bạn đã yêu cầu một liên kết đăng nhập tới Primary Health gần đây. Tuy nhiên,",
    "never_registered": "Chưa từng đăng ký? Hãy liên lạc với nhà cung cấp xét nghiệm để nhận đường dẫn đăng ký hoặc tìm địa điểm của bạn ở dưới.",
    "get_help": "Bạn có thể nhận được trợ giúp so khớp hồ sơ với thông tin liên lạc của mình với",
    "get_help_calling": "Hoặc bằng cách gọi điện thoại",
    "invalid_explanation_bold": "Thông tin cung cấp không hợp lệ",
    "get_help_label": "Nhận trợ giúp",
    "mobile": {
      "invalid_explanation": "Không tìm thấy người dùng nào để có thể được thêm vào tài khoản của bạn.",
      "get_help": "Nếu bạn có thêm bất kỳ câu hỏi nào, vui lòng liên hệ",
      "reason": "Không phải tất cả người dùng của Primary.Health đều đủ điều kiện cho tính năng Tài khoản mới của chúng tôi. Khi bạn đạt đủ điều kiện, bạn sẽ nhận được lời mời từ Primary.Health hoặc từ nhóm quản trị viên của bạn."
    },
    "zen_name": "Tên liên lạc",
    "ticket_type": "Cái này liên quan đến cái gì",
    "appointment_access_code": "Mã Xác Nhận (nếu đã có)",
    "zen_desc": "Tin nhắn",
    "contact_us": "Liên lạc với chúng tôi",
    "need_assistance": "Nếu bạn cần hỗ trợ hoặc đổi thông tin liên lạc, hãy liên lạc với bộ phận hỗ trợ."
  },
  "show_appointments": {
    "welcome": "Find your profile",
    "welcome_subtitle_1": "Showing results below for",
    "added_to_account": "Đã được thêm vào tài khoản",
    "error": "Người tham gia này có thể đã có một tài khoản",
    "welcome_subtitle_2": "Not seeing the name below?",
    "welcome_not_logged_in": "Finding a site link",
    "welcome_logged_in": "Select your Group",
    "welcome_subtitle_1_logged_in": "To finish adding a member, select the user and group. Members are assigned to one account.",
    "welcome_subtitle_2_logged_in": "If you are not seeing your member listed above, you can",
    "welcome_subtitle_1_not_logged_in": "Select who needs to locate their site or add a new member. Member not found?",
    "welcome_subtitle_3_logged_in": "try a different contact method",
    "welcome_subtitle_4_logged_in": "or contact us at",
    "welcome_subtitle_2_not_logged_in": "Update search.",
    "welcome_subtitle_3": "Update your search"
  },
  "enter_code": {
    "code": "Mã xác minh là gì?",
    "enter_code": "Nhập mã xác minh đã được gửi tới",
    "incorrect_code": "Bạn đã nhập mã xác minh không chính xác",
    "verify": "Xác minh tài khoản",
    "verified": "Đã xác minh",
    "verify_account_information": "Verify your account"
  },
  "general_self_checkout": {
    "choose_test": "Bạn đã làm những xét nghiệm nào?",
    "photo_required": "Hình ảnh kết quả được yêu cầu",
    "find_your_test": "Tìm kiếm xét nghiệm của bạn"
  },
  "login": {
    "create_account": "Tạo một tài khoản",
    "create_account_help": "Tạo tài khoản không giống như đăng ký xét nghiệm hoặc chích ngừa. Sau khi bạn đăng ký",
    "create_account_help_schedule": "Lên lịch các cuộc hẹn",
    "create_account_help_record": "Ghi lại các xét nghiệm tại nhà",
    "create_account_help_match": "Quản lý hồ sơ tiêm chủng vắc xin & xét nghiệm.",
    "continue": "Tiếp tục với tư cách khách mời",
    "already": "Đã có tài khoản? Nhấp vào",
    "login": "để đăng nhập",
    "or_sign_in": "Hoặc đăng nhập với",
    "no_account": "Chưa có tài khoản? Nhấp vào",
    "signup": "để đăng ký",
    "here": "tại đây",
    "email_address": "Địa chỉ e-mail:",
    "password": "Mật khẩu:",
    "complete_registration": "Hoàn tất đăng ký",
    "last_covid_test": "Kết quả kiểm tra COVID-19 gần đây nhất",
    "no_record": "Không có bản ghi nhận",
    "viewing_information": "Xem thông tin của {{first_name}}",
    "download": "Tải xuống",
    "not_registered": "Có vẻ như bạn chưa đăng ký vào nhóm nào.",
    "please_follow_link": "Vui lòng nhấp vào liên kết được mời từ e-mail của bạn để lên lịch và truy cập thông tin của bạn với Primary Health.",
    "log_results": "Ghi lại kết quả xét nghiệm của tôi",
    "book_appointment": "Đặt lịch hẹn",
    "no_new_appointments": "Hiện tại không nhận các cuộc hẹn mới",
    "upload_vaccinations": "Đăng tải các mũi vắc xin",
    "vaccination": "tiêm chủng vắc xin",
    "new_appointment": "Cuộc hẹn mới",
    "log_otc_results": "Ghi lại kết quả từ xét nghiệm OTC",
    "no_household_members": "Bạn chưa có thành viên nào trong gia đình.",
    "no_upcoming": "Không có cuộc hẹn tiếp theo",
    "update_vaccination": "Cập nhật tiêm chủng vắc xin",
    "upload_vaccination": "Đăng tải mũi tiêm chủng vắc xin",
    "title": "Chào mừng đến với Primary Health",
    "sub_title": "Before registering please create an account.",
    "p1": "Tạo một tài khoản cho phép bạn:",
    "p2": "Lên lịch các cuộc hẹn sau này",
    "p3": "Ghi nhận xét nghiệm COVID-19 tại nhà",
    "p4": "Quản lý hồ sơ tiêm chủng & xét nghiệm của người phụ thuộc",
    "p5": "Quy trình đăng nhập đơn giản từ Primary Health của tôi",
    "no_vaccination_record": "Chưa bổ sung vắc-xin",
    "vaccination_title_card": "Tình trạng tiêm chủng vắc-xin Covid-19",
    "account_and_settings": "Tài khoảng và cài đặt",
    "delete_account": "Xóa tài khoản",
    "language": "Ngôn ngữ",
    "language_current": "Ngôn ngữ hiện tại: {{language}}",
    "log_out": "Đăng xuất",
    "delete_account_title": "Bạn có chắc bạn muốn xóa tài khoản không?",
    "new_encounter": "Gặp gỡ mới của {{first_name}}",
    "new_encounter_subtitle": "Cuộc gặp này dành cho nhóm nào?",
    "no_programs": "Có vẻ bạn chưa đăng kí chương trình nào",
    "edit_members": "Chỉnh sửa thành viên",
    "edit_members_subtitle": "Chọn trang muốn truy cập hoặc thêm thành viên khác vào tài khoản của bạn",
    "add_members": "Thêm thành viên",
    "delete_member": "Xóa tài khoản của người dùng tên {{full_name}}",
    "delete_member_subtitle": "Xóa người dùng sẽ xóa tất cả các thông tin được liên kết với tài khoản",
    "select_member": "Lựa chọn thành viên",
    "edit_appointment": "Chỉnh sửa cuộc hẹn",
    "route": {
      "me": "Bảng điều khiển",
      "history": "Lịch sử",
      "programs": "Thêm một bài kiểm tra",
      "contact_us": "Giúp đỡ",
      "choose_member": "Thành viên",
      "settings": "Cài đặt",
      "help": "Giúp đỡ"
    },
    "user_dashboard": "Bảng điều hiển của {{first_name}}",
    "view_full_results": "Xem trong cổng kết quả",
    "required": "Bắt buộc nếu bạn đang sử dụng Primary App.",
    "no_email": "Tôi không có địa chỉ e-mail"
  },
  "vaccination_status": {
    "not_vaccinated": "Chưa tiêm phòng",
    "fully_vaccinated": "Đã tiêm phòng đầy đủ",
    "partially_vaccinated": "Đã tiêm phòng một phần",
    "vaccination_records": "Hồ sơ tiêm chủng",
    "title": "Tình trạng chích ngừa COVID-19 của bạn là gì?",
    "definition_title": "Định nghĩa tình trạng chích ngừa",
    "definition_cdc": "Định nghĩa tình trạng chích ngừa của CDC",
    "definition_osha": "Định nghĩa tình trạng chích ngừa của OSHA",
    "definition": {
      "fully_vaccinated": "2 tuần sau khi chích liều cuối cùng trong liệu trình 2 liều Pfizer-BioNTech hoặc Moderna hoặc sau 1 liều Janssen của Johnson & Johnson.",
      "partially_vaccinated": "Chỉ chích 1 trong 2 liều chính hoặc chưa đầy 2 tuần sau khi chích liều cuối cùng trong liệu trình 2 liều hoặc 1 liều Janssen của Johnson & Johnson.",
      "not_vaccinated": "Không chích ngừa COVID-19.",
      "fully_vaccinated_with_one_booster": "Chích liều tăng cường của vaccine Pfizer-BioNTech hoặc Moderna sau khi chích liệu trình đầy đủ hoặc sau 1 liều Janssen của Johnson & Johnson.",
      "fully_vaccinated_with_two_boosters": "Chích liều tăng cường thứ hai của vaccine Pfizer-BioNTech hoặc Moderna sau khi chích liệu trình đầy đủ hoặc sau liều chính và liều tăng cường của Janssen của Johnson & Johnson."
    },
    "label": {
      "fully_vaccinated": "Đã chích ngừa đầy đủ",
      "partially_vaccinated": "Đã chích ngừa một phần",
      "not_vaccinated": "Chưa chích ngừa",
      "fully_vaccinated_with_one_booster": "Đã chích ngừa đầy đủ + tăng cường",
      "fully_vaccinated_with_two_boosters": "Đã chích ngừa đầy đủ + 2 liều tăng cường",
      "prefer_not_to_answer": "Không muốn trả lời"
    },
    "progress_bar_title": "Lịch sử chích ngừa COVID-19",
    "upload_record_card": "Tải lên thẻ chích ngừa COVID-19",
    "show_example": "Hiện ví dụ",
    "hide_example": "Ẩn ví dụ",
    "take_photo": "Nhấn để chụp hình",
    "or": "HOẶC",
    "upload_image_or_pdf_instead": "Tải lên hình/tệp .pdf",
    "select_doses_received": "Chọn tất cả liều chích ngừa COVID-19 mà bạn đã chích.",
    "first_dose": "Liều thứ nhất",
    "second_dose": "Liều thứ hai",
    "first_booster_dose": "Liều tăng cường thứ nhất",
    "second_booster_dose": "Liều tăng cường thứ hai",
    "additional_dose": "Liều bổ sung",
    "first_dose_manufacturer": "Bạn chích liều vaccine COVID-19 thứ nhất của nhà sản xuất nào?",
    "first_dose_date": "Bạn chích liều thứ nhất vào ngày nào?",
    "second_dose_manufacturer": "Bạn chích liều vaccine COVID-19 thứ hai của nhà sản xuất nào?",
    "second_dose_date": "Bạn chích liều thứ hai vào ngày nào?",
    "first_booster_dose_manufacturer": "Bạn chích liều vaccine COVID-19 tăng cường thứ nhất của nhà sản xuất nào?",
    "first_booster_dose_date": "Bạn chích liều tăng cường thứ nhất vào ngày nào?",
    "second_booster_dose_manufacturer": "Bạn chích liều vaccine COVID-19 tăng cường thứ hai của nhà sản xuất nào?",
    "second_booster_dose_date": "Bạn chích liều tăng cường thứ hai vào ngày nào?",
    "additional_dose_manufacturer": "Bạn chích liều vaccine COVID-19 bổ sung của nhà sản xuất nào?",
    "additional_dose_date": "Bạn chích liều bổ sung vào ngày nào?",
    "completed_one": "Đã hoàn tất",
    "completed_two": "Cảm ơn bạn đã cập nhật tình trạng chích ngừa!",
    "progress_bar_complete": "Hoàn tất",
    "upload_image": "Tải lên hình",
    "retake_photo": "Chụp lại hình",
    "other": "Khác",
    "remove_first_dose": "Xóa lịch sử liều thứ nhất",
    "remove_second_dose": "Xóa lịch sử liều thứ hai",
    "remove_first_booster_dose": "Xóa lịch sử liều tăng cường thứ nhất",
    "remove_second_booster_dose": "Xóa lịch sử liều tăng cường thứ hai",
    "remove_additional_dose": "Xóa lịch sử liều bổ sung",
    "exemption": "Bạn có ngoại lệ không?",
    "exempt": "Tôi có ngoại lệ",
    "not_exempt": "Tôi không có ngoại lệ",
    "enter_exemption": "Nhập ngoại lệ",
    "upload_exemption_documentation": "Tải lên tài liệu chứng minh ngoại lệ",
    "remove_dose": "Xóa liều",
    "continue": "Tiếp tục",
    "enter_credentials": "Hãy nhập thông tin của bạn",
    "incorrect_credentials": "Sai thông tin, hãy thử lại.",
    "add_photo": "Thêm hình"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Xin chào {{name}}, Chúng tôi thấy rằng bạn chưa hoàn tất đặt {{group_name}} cuộc hẹn với {{org_name}}. Để giữ cuộc hẹn, bạn cần hoàn thành bước trả phí. Hãy nhấn vào đường dẫn này để hoàn thành quy trình {{complete_url}}. Nếu bạn muốn hủy hẹn, hãy nhấn vào đường dẫn này {{cancel_url}}. Xin cám ơn"
    }
  },
  "otc": {
    "record_results_for_abbott": "Xét nghiệm và ghi chép kết quả cho Xét nghiệm BinaxNOW",
    "record_results": "Xét nghiệm và ghi chép kết quả của bạn",
    "activate_a_kit": "Kích hoạt bộ dụng cụ",
    "activate_a_kit_for_test": "Kích hoạt bộ dụng cụ cho {{test}}",
    "todays_test_process": "Tiến trình xét nghiệm hôm nay",
    "date_of_birth_question": "Ngày tháng năm sinh của {{first_name}}?",
    "confirm_dob_to_continue": "Xin hãy xác nhận ngày tháng năm sinh của người tham gia để tiếp tục",
    "incorrect_dob": "Sai ngày sinh",
    "file_too_large": "Xin hãy đảm bảo kích cỡ của tập tin tải lên nhỏ hơn {{size}}",
    "enter_valid_date_in_last_x_days": "Nhập ngày có hiệu lực trong vòng {{n}} ngày",
    "barcode_format_not_valid": "Định dạng mã không có giá trị",
    "complete_button": "Hoàn tất",
    "next_button": "Tiếp",
    "back_button": "Quay lại",
    "report_a_test": "Báo cáo xét nghiệm:",
    "x_%_completed": "{{n}}% hoàn tất",
    "answer_survey": "Trả lời các câu hỏi khảo sát",
    "steps": {
      "answer_survey": "Trả lời khảo sát",
      "review_instructions": "Xem lại hướng dẫn",
      "scan_code": "Quét mã",
      "enter_time": "Nhập thời gian hiện tại",
      "enter_result": "Nhập kết quả",
      "take_photo": "Chụp ảnh",
      "confirm_result": "Xác nhận kết quả",
      "selest_test_kit": "Chọn kiểu xét nghiệm",
      "enter_date": "Nhập ngày",
      "enter_date_and_time": "Nhập ngày giờ"
    },
    "review_instructions": "Xem lại hướng dẫn xét nghiệm",
    "follow_instructions_or_below": "Làm theo hướng dẫn khi xét nghiệm hoặc tham khảo các hướng dẫn dưới dây.",
    "watch_video": "Xem video",
    "view_instructions": "Xem hướng dẫn",
    "follow_instructions": "Làm theo hướng dẫn khi xét nghiệm.",
    "scan_code": "Quét mã QR xét nghiệm",
    "qr_code_description": "Mã QR xét nghiệm của bạn là duy nhất và bạn có thể tìm thấy ngay góc trên bên phải trong bản xét nghiệm",
    "show_example": "Xem mẫu",
    "hide_example": "Dấu mẫu",
    "barcode_scanned": "Mã vạch quét thành công",
    "enter_barcode": "Nhập mã vạch",
    "scan_qr_code": "Quét mã QR",
    "which_test_did_you_take": "Bạn làm xét nghiệm loại nào?",
    "when_did_you_take_test": "Bạn làm xét nghiệm khi nào?",
    "what_day_did_you_take_test": "Bạn xét nghiệm ngày nào?",
    "what_time_did_you_take_test": "Thời gian bạn làm xét nghiệm?",
    "time": {
      "today": "Hôm nay",
      "yesterday": "Ngày hôm qua",
      "two_days_ago": "2 ngày trước"
    },
    "enter_current_time": "Nhập thời gian hiện tại",
    "enter_result": "Nhập kết quả xét nghiệm",
    "choose_result_option": "Chọn phương án mô tả kết quả phiếu thử:",
    "clarify_results": "Giải thích kết quả của bạn",
    "take_result_photo": "Chụp kết quả",
    "take_result_photo_description": "Chụp ảnh bảng kết quả hoàn chỉnh để lưu lại",
    "my_result": "Kết quả của tôi",
    "switch_camera": "Bật camera",
    "confirm_result": "Xác nhận kết quả",
    "name": "Tên:",
    "date": "Ngày:",
    "result": "Kết quả:",
    "results": "Các kết quả",
    "test_submitted": "Xin cảm ơn, {{first_name}}! {{test}} của bạn đã đăng kí thành công.",
    "dob": "Ngày sinh:",
    "administered": "Quản lý:",
    "or": "HOẶC",
    "upload": "Tải lên",
    "change_uploaded_file": "Thay đổi tập tin tải lên",
    "take_photo": "Chụp ảnh bảng kết quả hoàn chỉnh để lưu lại",
    "confirm_information": "Xác nhận thông tin",
    "barcode": "Mã vạch:",
    "scan_test_barcode": "Quét mã vạch xét nghiệm",
    "barcode_description": "Mã vạch xét nghiệm của bạn là duy nhất và bạn có thể tìm thấy trong bản xét nghiệm",
    "enter_date_in_last_30_days": "Nhập ngày trong vòng 30 ngày trước và khoảng thời gian làm xét nghiệm",
    "add_image": "Chèn ảnh",
    "change_image": "Thay đổi hình ảnh",
    "skip_barcode_reader": "Không thể quét mã vạch? Nhấn Tiếp tục để gửi kết quả xét nghiệm luôn",
    "enter_date_and_time": "Nhập ngày giờ làm xét nghiệm",
    "enter_date": "Nhập ngày làm xét nghiệm",
    "did_you_take_test_today": "Bạn đã làm kiểm tra ngày hôm nay chưa?",
    "record_results_for_generic": "Xét nghiệm & lưu lại kết quả của xét nghiệm {{name}}",
    "choose_result_option_custom": "Chọn lựa chọn mô tả kết quả {{test_name}} của bạn:"
  },
  "yes": "Có",
  "no": "Không",
  "event_token": {
    "title": "Xin hãy nhập mã dưới vào máy bán hàng tự động để nhận bộ dụng cụ xét nghiệm của bạn",
    "loading_text": "đang tải, xin hãy quay lại sau một phút"
  },
  "appointment_recovery": {
    "complete_title": "Bạn đã sẵn sàng",
    "subtitle_1": "Nếu bạn đang sử dụng Iphone, hãy nhấn vào **Xong** ở góc trên cùng bên trái của màn hình này để quay lại trang điều khiển của bạn.",
    "subtitle_2": "Nếu bạn đang sử dụng Android, hãy nhấn vào **X** ở góc trên cùng bên trái của màn hình này.",
    "subtitle_0": "Vui lòng thoát khỏi trình duyệt để tiếp tục"
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Mối quan hệ được đồng ý",
  "finish_registration": {
    "title": "Hoàn tất đăng ký",
    "subtitle": "Hãy điền những thông tin bên dưới trước cuộc hẹn:",
    "button": "Hoàn tất đăng ký"
  },
  "arab_ethnicity": {
    "arab": "Người Ả Rập",
    "non_arab": "Không phải người Ả Rập",
    "prefer_not_to_answer": "Không muốn trả lời",
    "question": "Sắc tộc Ả Rập",
    "subtitle": "Quy định yêu cầu chúng tôi thu thập các thông tin sau.",
    "help": "Sắc tộc nghĩa là một tập hợp các đặc điểm chung như ngôn ngữ, phong tục, tôn giáo và các đặc điểm khác được dùng để phân biệt một nhóm người. Nó có thể hoặc không trùng hợp với chủng tộc."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Tùy theo thiết bị, hãy nhấn vào đường dẫn bên dưới để tải xuống Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Tiếp",
    "back": "Quay lại",
    "complete": "Hoàn tất"
  },
  "registration_direcory": {
    "keyword_search_label": "Keyword, city, zip code, organization name",
    "keyword_search_label_mobile": "City, zip code, organization name",
    "zero_results": {
      "title": "We didn’t find any providers with that search term.",
      "sub_title": "Try searching for your provider by:",
      "p1": "Zip code",
      "p2": "City or town name",
      "p3": "County name",
      "p4": "Provider or organization name"
    }
  },
  "registration_flyer": {
    "open_camera": "Mở ứng dụng camera trên điện thoại",
    "point_to_qr_code": "Quét mã QR và nhấn vào đường dẫn",
    "complete_registration": "Hoàn tất đăng ký",
    "need_help": "Bạn cần hỗ trợ? Liên hệ chúng tôi theo"
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Chứng nhận thông tin tiêm chủng",
    "select_checkbox": "Vui lòng xem xét điều sau đây và chọn hộp kiểm phù hợp bên dưới trước khi tiếp tục.",
    "opportunity_to_read": "Tôi đã có cơ hội đọc hoặc được đọc phiếu chi tiết Vaccine Information Statement (\"VIS\" – Tuyên bố Thông tin Tiêm chủng) hoặc Emergency Use Authorization (\"EUA\" – Cấp phép Sử dụng Khẩn cấp) dành cho loại thuốc tiêm chủng sắp được sử dụng:",
    "vaccine_info_sheet": "phiếu thông tin tiêm chủng",
    "vaccine_fact_sheet": "phiếu chi tiết tiêm chủng",
    "vaccine_info_statement": "tuyên bố thông tin vắc-xin"
  },
  "exemption": {
    "dtap_tdap": "DTaP/TDap",
    "mmr": "MMR",
    "ipv_opv": "Polio",
    "hib": "HIB",
    "hep_b": "HEPB",
    "var": "Varicella",
    "hep_a": "HEPA",
    "pcv": "PCV",
    "mcv": "MCV"
  },
  "test_strip": {
    "A": "bộ xét nghiệm này bao gồm xét nghiệm dành cho chlamydia và bệnh lậu.",
    "B": "bộ xét nghiệm này bao gồm xét nghiệm dành cho chlamydia và bệnh lậu.",
    "C": "bộ xét nghiệm này bao gồm xét nghiệm dành cho giang mai.",
    "D": "bộ xét nghiệm này bao gồm xét nghiệm dành cho HIV, giang mai, chlamydia và bệnh lậu.",
    "E": "bộ xét nghiệm này bao gồm xét nghiệm dành cho HIV và giang mai.",
    "box_type": "Hộp {{type}}",
    "administered_text": "Chúng tôi sẽ liên lạc với bạn khi bộ xét nghiệm của bạn tới phòng xét nghiệm và khi có kết quả.",
    "activate_kit": "Kích hoạt bộ xét nghiệm",
    "register_another_test": "Đăng ký xét nghiệm khác",
    "credentials": {
      "title": "Hãy bắt đầu",
      "label": "Liên lạc",
      "p1": "Bắt đầu là một số thông tin về bạn.",
      "location_step": {
        "p1": "Ai cung cấp bộ xét nghiệm cho bạn?",
        "load": "Tải thêm"
      }
    },
    "checklist": {
      "not_urinated": "Bạn chưa đi tiểu tiện trong một giờ qua.",
      "bathroom": "Bạn có một nhà tắm để lấy mẫu nước tiểu.",
      "anal_swab": "Bạn có một nơi riêng tư để thực hiện lấy mẫu que chọc hậu môn.",
      "blood_extraction": "Bạn có một môi trường vệ sinh để lấy mẫu máu.",
      "washed_hands": "Bạn đã rửa tay bằng xà bông và nước ấm trong 30 giây.",
      "title": "Bạn đã sẵn sàng bắt đầu lấy mẫu?",
      "no_alcohol": "Không uống rượu bia vào ngày lấy mẫu.",
      "menstruation": "Không lấy mẫu que chọc âm đạo trong kỳ kinh nguyệt hoặc trong vòng 24 giờ sau khi có quan hệ tình dục."
    },
    "confirm": {
      "title": "Xác nhận thông tin",
      "p1": "Thông tin này có chính xác không?"
    },
    "display_name": {
      "A": "Chlamydia & bệnh lậu (một vị trí)",
      "B": "Chlamydia & bệnh lậu (3 vị trí)",
      "C": "Giang mai",
      "shortened": {
        "A": "Chlamydia & bệnh lậu",
        "B": "Chlamydia & bệnh lậu",
        "C": "Giang mai"
      },
      "CC": "Tầm soát ung thư đại trực tràng (FIT)",
      "CE": "Tầm soát bệnh celiac",
      "CR": "Xét nghiệm creatinine",
      "D": "Xét nghiệm 4 chân – HIV, giang mai, chlamydia & bệnh lậu (một vị trí)",
      "DD": "Tầm soát tiểu đường (HbA1c)",
      "E": "HIV & giang mai",
      "H": "HPV",
      "P": "Viêm gan C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Mức testosterone",
      "VD": "Vitamin D",
      "VS": "Theo dõi virus",
      "AP": "Chlamydia & Gonorrhea"
    },
    "rescan": "Quét lại",
    "scanned": "Bạn đã quét hộp {{type}}",
    "not_yet": "Chưa có",
    "success_registration": "Đăng ký thành công!",
    "no_box": "Bạn không có hộp?",
    "faq_link": "Truy cập mục hỏi đáp",
    "welcome": "Chào mừng!",
    "scan": {
      "title": "Bạn sắp trở thành một phiên bản khỏe mạnh hơn.",
      "p1": "Quét hoặc nhập **mã vạch bộ xét nghiệm** ở bên hông của hộp."
    },
    "appointments": {
      "title": "Chọn bệnh nhân & nhà cung cấp",
      "p1": "Chúng tôi tìm thấy trên một hồ sơ gắn liền với thông tin liên lạc của bạn. Chọn đúng hồ sơ:",
      "new": "Bệnh nhân hoặc nhà cung cấp mới"
    },
    "verify": {
      "title": "Confirm your identity",
      "p1": "Chúng tôi đã tìm thấy bệnh án của bạn."
    },
    "section_1": "Xác nhận bên trong bộ xét nghiệm",
    "section_2": "Lấy mẫu xét nghiệm",
    "section_3": "Đóng gói",
    "section_4": "Giao hàng",
    "box_a": "Hộp A",
    "box_b": "Hộp B",
    "box_c": "Hộp C",
    "kit_flow": {
      "button_1": "Tiếp tục đóng gói",
      "button_2": "Tôi sẵn sàng",
      "button_3": "Bộ xét nghiệm của tôi đã đầy đủ và đóng gói",
      "button_4": "Tôi đã xong",
      "button_5": "Xét nghiệm tiếp theo",
      "button_6": "Bỏ qua hướng dẫn"
    },
    "contents": {
      "header": "Tuyệt vời! Đảm bảo bộ xét nghiệm của bạn đầy đủ",
      "text_1": "Does your test kit box contain the following?",
      "blood_collection_card": "Thẻ lấy mẫu máu",
      "blood_collection_card_subtitle": "(1 hoặc 2 cái tùy theo bộ xét nghiệm)",
      "alchohol_pads": "2 bông gạc tẩm cồn",
      "lancets": "Kim lấy máu dùng một lần",
      "up_to_other": "(Tối đa 4 cái tùy theo bộ xét nghiệm)",
      "adhesive_bandages": "Băng keo cá nhân",
      "pipette": "Ống nhỏ giọt",
      "urine_tube": "Ống lấy mẫu nước tiểu",
      "urine_cup": "Ly lấy mẫu nước tiểu",
      "oral_swab": "Que lấy nước bọt",
      "oral_tube": "Ống lấy mẫu nước bọt",
      "anal_swab": "Que chọc hậu môn",
      "anal_tube": "Ống lấy mẫu hậu môn",
      "vaginal_tube": "1 ống lấy mẫu âm đạo",
      "vaginal_swab": "1 Que Chọc Âm Đạo",
      "biodegradable_paper": "Giấy lấy mẫu phân hủy sinh học",
      "bottle_and_probe": "Lọ và que lấy mẫu",
      "prepaid_envelope": "Phong bì gửi thư trả trước",
      "biohazard_subtitle": "(với giấy thấm)",
      "biohazard_bag": "1 túi mẫu",
      "biohazard_bag_plural": "({{count}}) Túi đựng mẫu vật sinh học",
      "alcohol_pads_plural": "{{count}} bông gạc tẩm cồn",
      "sterile_gauze_pad": "1 băng gạc vô trùng"
    },
    "packaging": {
      "title": "Danh sách đóng gói",
      "subtitle": "Trước khi bạn gửi đi bộ xét nghiệm, hãy đảm bảo rằng:"
    },
    "packaging_box_a": {
      "step_1": "Bạn đã đóng gói tất cả dụng cụ bên trong bộ xét nghiệm",
      "step_2": "Bạn đã dùng tất cả vật liệu lấy mẫu chứa trong bộ xét nghiệm để lấy mẫu được yêu cầu",
      "step_3": "**Ngày sinh** của bạn được viết lên tất cả ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_4": "**Ngày lấy mẫu** được viết lên tất cả ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_5": "Ống lấy mẫu được niêm phong trong túi đựng mẫu vật sinh học",
      "step_6": "Tất cả mẫu được đặt vào hộp",
      "step_7": "Hộp được đặt vào phong bì gửi thư trả trước",
      "step_8": "Phong bì gửi thư trả lại được niêm phong kín"
    },
    "packaging_box_b": {
      "step_1": "Bạn đã đóng gói tất cả dụng cụ bên trong bộ xét nghiệm, kể cả khi bạn bỏ qua xét nghiệm",
      "step_2": "Bạn đã dùng tất cả vật liệu lấy mẫu chứa trong bộ xét nghiệm để lấy mẫu được yêu cầu",
      "step_3": "**Ngày sinh** của bạn được viết lên tất cả ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_4": "**Ngày lấy mẫu** được viết lên tất cả ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_5": "Mỗi ống lấy mẫu được niêm phong trong túi đựng mẫu vật sinh học riêng (mỗi túi **một** mẫu)",
      "step_6": "Tất cả mẫu được đặt vào hộp",
      "step_7": "Hộp được đặt vào phong bì gửi thư trả trước",
      "step_8": "Phong bì gửi thư trả lại được niêm phong kín"
    },
    "packaging_box_c": {
      "step_1": "Bạn đã đóng gói tất cả dụng cụ bên trong bộ xét nghiệm, bao gồm kim lấy máu đã sử dụng.",
      "step_2": "**Ngày sinh** của bạn được viết lên tất cả thẻ lấy mẫu máu theo thứ tự MM/DD/YYYY",
      "step_3": "**Ngày lấy mẫu** được viết lên tất cả thẻ lấy mẫu máu theo thứ tự MM/DD/YYYY",
      "step_4": "Thẻ lấy mẫu máu và tất cả kim lấy máu đã sử dụng được cho vào túi đựng mẫu vật sinh học",
      "step_5": "Túi đựng mẫu vật sinh học được đặt vào hộp",
      "step_6": "Hộp được đặt vào phong bì gửi thư trả trước",
      "step_7": "Phong bì gửi thư trả lại được niêm phong kín"
    },
    "success": {
      "header_1": "Tuyệt vời!",
      "text_1": "Bạn sẵn sàng lấy mẫu lần đầu tiên rồi đấy.",
      "header_2": "Bạn làm được rồi!",
      "text_2": "**Tiếp theo:** Đóng gói bộ xét nghiệm để gửi lại.",
      "header_3": "Bạn giỏi quá!",
      "text_3": "Sức khỏe của bạn sắp trở nên tốt hơn rồi.",
      "header_4": "Bạn hay lắm!",
      "text_4": "Chỉ còn một bước nữa là xong rồi!",
      "text_5": "Bạn sẵn sàng lấy mẫu lần đầu tiên rồi đấy"
    },
    "instructions": {
      "title": "Chọn xét nghiệm để xem hướng dẫn:",
      "subtitle": "Xét nghiệm",
      "button": "Hướng dẫn",
      "pdf": "Hướng dẫn PDF"
    },
    "instructions_box_a": {
      "title": "Lấy mẫu nước tiểu",
      "subtitle": "Đối với xét nghiệm này, bạn cần những vật liệu sau:",
      "step_1": "Không tiểu tiện tối thiểu 1 giờ trước khi lấy mẫu. Lấy nước tiểu khi đi tiểu đầu tiên vào buổi sáng là tốt nhất.",
      "step_2": "Tiểu trực tiếp vào PeeCanter hoặc cốc lấy mẫu, đầy từ 1/3 đến 1/2 cốc.",
      "step_3": "Đổ từ PeeCanter hoặc dùng ống nhỏ giọt để chuyển nước tiểu từ cốc lấy mẫu sang ống lấy mẫu có đánh dấu bằng nhãn <span style=\"color: #FF5000\">**\"URINE\"**</span> màu cam.",
      "step_4": "Làm đầy ống lấy mẫu cho đến khi hỗn hợp chất lỏng nằm giữa các vạch cho sẵn.",
      "step_4_sublabel": "Không làm đầy ống quá vạch tối đa, nếu không mẫu sẽ bị từ chối.",
      "step_5": "Đậy nắp ống lấy mẫu và đảm bảo nó đóng đều và khít.",
      "step_6": "Ghi ngày sinh và ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống.",
      "step_7": "Đặt vật mẫu vào túi đựng mẫu vật sinh học trống cùng với giấy thấm và đảm bảo niêm phong kín hoàn toàn."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Lấy mẫu nước tiểu",
        "subtitle": "Đối với xét nghiệm này, bạn cần những vật liệu sau:",
        "step_1": "Không tiểu tiện tối thiểu 1 giờ trước khi lấy mẫu. Lấy nước tiểu khi đi tiểu đầu tiên vào buổi sáng là tốt nhất.",
        "step_2": "Tiểu trực tiếp vào PeeCanter hoặc cốc lấy mẫu, đầy từ 1/3 đến 1/2 cốc.",
        "step_3": "Đổ từ PeeCanter hoặc dùng ống nhỏ giọt để chuyển nước tiểu từ cốc lấy mẫu sang ống lấy mẫu có đánh dấu bằng nhãn <span style=\"color: #FF5000\">**\"URINE\"**</span> màu cam.",
        "step_4": "Làm đầy ống lấy mẫu cho đến khi hỗn hợp chất lỏng nằm giữa các vạch cho sẵn.",
        "step_4_subtitle": "Không làm đầy ống quá vạch tối đa, nếu không mẫu sẽ bị từ chối.",
        "step_5": "Đậy nắp ống lấy mẫu và đảm bảo nó đóng đều và khít.",
        "step_6": "Ghi ngày sinh và ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống.",
        "step_7": "Đặt vật mẫu vào túi đựng mẫu vật sinh học trống cùng với giấy thấm và đảm bảo niêm phong kín hoàn toàn."
      },
      "test_2": {
        "title": "Que lấy nước bọt",
        "subtitle": "Đối với xét nghiệm này, bạn cần những vật liệu sau:",
        "step_1": "Cầm que giữa vạch mờ (nếu có) và đầu bông vải của que.",
        "step_2": "Cho que vào miệng và chà xát đầu que vào phía sau họng trong 10 giây.",
        "step_3": "Cẩn thận lấy que ra và đặt vào ống lấy mẫu có đánh dấu bằng nhãn <span style=\"color: #001F70\">**\"ORAL\"</span> màu xanh dương. Tỳ que vào ống lấy mẫu để bẻ đứt que ở vạch.",
        "step_4": "Đậy nắp ống lấy mẫu và đảm bảo nó đóng đều và khít.",
        "step_5": "Ghi ngày sinh và ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống.",
        "step_6": "Đặt vật mẫu vào túi đựng mẫu vật sinh học trống cùng với giấy thấm và đảm bảo niêm phong kín hoàn toàn."
      },
      "test_3": {
        "title": "Que chọc hậu môn",
        "subtitle": "Đối với xét nghiệm này, bạn cần những vật liệu sau:",
        "step_1": "Cầm que giữa vạch mờ (nếu có) và đầu bông vải của que.",
        "step_2": "Cho que vào đường hậu môn khoảng 3 – 5 cm (1 – 2\"). Nhẹ nhàng xoay que 5 đến 10 giây để lấy mẫu sinh vật hữu cơ.",
        "step_3": "Cẩn thận lấy que ra và đặt vào ống lấy mẫu có đánh dấu bằng nhãn <span style=\"color: #00C08C\">**\"ANAL\"</span> màu xanh lá. Tỳ que vào ống lấy mẫu để bẻ đứt que ở vạch.",
        "step_4": "Đậy nắp ống lấy mẫu và đảm bảo nó đóng đều và khít.",
        "step_5": "Ghi ngày sinh và ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống.",
        "step_6": "Đặt vật mẫu vào túi đựng mẫu vật sinh học trống cùng với giấy thấm và đảm bảo niêm phong kín hoàn toàn."
      }
    },
    "instructions_box_c": {
      "title": "Lấy mẫu máu",
      "subtitle": "Đối với xét nghiệm này, bạn cần những vật liệu sau:",
      "step_1": "Viết tên, ngày sinh và ngày lấy mẫu vào các mục cho sẵn. Sử dụng thứ tự MM/DD/YYYY.",
      "step_2": "Mở thẻ lấy mẫu máu ra để thấy các vòng tròn trên giấy lấy mẫu máu. Không chạm vào giấy lấy mẫu máu.",
      "step_3": "Rửa tay bằng nước ấm tối thiểu 30 giây, phẩy tay trong 15 giây để cho kích thích máu lưu thông đến các ngón tay.",
      "step_4": "Lau đầu ngón tay bằng bông gạc tẩm cồn. Tốt nhất là lấy máu ở ngón giữa hoặc ngón đeo nhẫn của tay không thuận.",
      "step_5": "Lấy kim lấy máu và tháo nắp ra. Đè đầu nhỏ vào đầu ngón tay của bạn cho đến khi cho đến khi kim bật ra với một tiếng tách. Để bàn tay ở độ cao thấp hơn tim trong khi lấy máu, mát xa ngón tay từ đầu đến gốc để kích thích máu lưu thông.",
      "step_5_subtitle": "Kim lấy máu dùng một lần. Tất cả kim lấy máu cần được trả lại cho phòng xét nghiệm kèm với mẫu để tiêu hủy.",
      "step_6": "Bắt đầu từ trung tâm, thoa 3 – 6 giọt làm đầy vòng tròn và cho máu thấm qua giấy lấy mẫu máu. Không chạm vào giấy bằng ngón tay, điều này sẽ làm hạn chế máu lưu thông. Sau khi đầy vòng tròn, qua vòng tròn tiếp theo. Bạn có thể cho máu ra khỏi đường tròn, nhưng không được để các đốm máu hòa lẫn vào nhau.",
      "step_6_subtitle": "Không thêm máu vào một vòng tròn đã xong hoặc đã khô. \"Lớp máu\" sẽ làm việc lấy máu mất tác dụng.",
      "step_7": "Không gấp thẻ lại mà để nó lên một bề mặt phẳng, cho giấy lấy mẫu máu khô ở nhiệt độ phòng trong ít nhất 30 phút. Không sấy, thổi hơi hay để giấy lấy mẫu máu trực tiếp dưới ánh nắng mặt trời. Nhiệt sẽ làm hư mẫu vật.",
      "step_7_subtitle": "Kiểm tra mặt sau của giấy lấy mẫu máu. Máu phải thấm ướt vào tận bên trong và lan ra từng vòng tròn trên giấy lấy mẫu máu.",
      "step_8": "Khi giấy lấy mẫu máu khô, đóng thẻ lấy mẫu máu bằng cách gấp lại. Để thẻ lấy mẫu máu và kim lấy máu vào túi đựng mẫu vật sinh học cùng gói hút ẩm. Đảm bảo túi đựng mẫu vật sinh học được bịt kín."
    },
    "test_tips": {
      "title": "Mẹo lấy mẫu máu đúng cách",
      "subtitle": "Để có kết quả tốt nhất:",
      "step_1": "Nhớ đừng để mất nước trong khi thực hiện lấy mẫu. Cơ thể đầy đủ nước khiến máu lưu thông.",
      "step_2": "Không thực hiện lấy mẫu ngay sau khi hút thuốc.",
      "step_3": "Rửa tay và làm ấm tay với nước ấm sẽ giúp kích thích lưu thông máu ở tay.",
      "step_4": "Phẩy tay mạnh xuống sàn để kích thích máu lưu thông đến các ngón tay.",
      "step_5": "Để thiết bị lấy mẫu và bàn tay ở độ cao thấp hơn tim để máu lưu thông tốt nhất.",
      "step_6": "Bạn có thể cần chích ngón tay hơn một lần. Lặp lại những mẹo này giữa những lần chích ngón tay."
    },
    "shipping": {
      "header": "Bộ xét nghiệm của bạn sẵn sàng gửi đi!",
      "text": "**Chúc mừng!** Bạn đã hoàn tất sàng lọc sức khỏe tình dục."
    },
    "pick_up": "Lấy bộ xét nghiệm được đề xuất của bạn từ nhân viên và đăng ký bên dưới",
    "short_display_name": {
      "A": "Chlamydia & bệnh lậu",
      "B": "Chlamydia & bệnh lậu",
      "C": "Giang mai",
      "CC": "Ung thư đại trực tràng",
      "CE": "Celiac",
      "CR": "Creatinine",
      "D": "HIV, giang mai, chlamydia & bệnh lậu",
      "DD": "Tiểu đường",
      "E": "HIV & giang mai",
      "H": "HPV",
      "P": "Viêm gan C",
      "HS": "Herpes Simplex",
      "I": "HIV",
      "TT": "Testosterone",
      "VD": "Vitamin D",
      "VS": "Theo dõi virus"
    },
    "test_kit": "Bộ xét nghiệm",
    "view_results": "Xem kết quả",
    "recommended_kit_title": "Bộ xét nghiệm được đề xuất",
    "instructions_box_h": {
      "title": "Lấy mẫu que âm đạo",
      "subtitle": "Ống lấy mẫu có thể chứa chất bảo quản lỏng. <span class=\"text-danger\">**Không bỏ dung dịch chất bảo quản khỏi ống lấy mẫu nếu có.**</span>",
      "step_1": "Cầm que giữa vạch mờ (nếu có) và đầu bông vải của que bằng một tay và tách môi lớn (lớp da quanh khe âm đạo).",
      "step_2": "Cho que vào âm đạo khoảng 5 cm (2\"). Nhẹ nhàng xoay que 30 giây trong khi chà xát que vào thành âm đạo.",
      "step_3": "Cẩn thận lấy que ra và đặt vào ống lấy mẫu có đánh dấu bằng <span class=\"text-danger\">**NHÃN \"VAGINAL\" MÀU ĐỎ**</div>. Tỳ que vào ống lấy mẫu để bẻ đứt que.",
      "step_4": "Đậy nắp ống lấy mẫu và đảm bảo nó đóng đều và khít.",
      "step_5": "Ghi ngày sinh theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống (DOB).",
      "step_6": "Ghi ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống (Coll. Date).",
      "step_7": "Đặt vật mẫu vào túi đựng mẫu vật sinh học trống cùng với giấy thấm và đảm bảo niêm phong kín hoàn toàn."
    },
    "packaging_box_h": {
      "step_3": "**Ngày sinh** của bạn được viết lên ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_4": "**Ngày lấy mẫu** của bạn được viết lên ống lấy mẫu theo thứ tự MM/DD/YYYY",
      "step_6": "Túi đựng mẫu vật sinh học được đặt vào hộp"
    },
    "instructions_box_cc": {
      "title": "Lấy mẫu phân",
      "step_1": "Ghi ngày sinh và ngày lấy mẫu (hôm nay) theo thứ tự MM/DD/YYYY lên chỗ cho sẵn trên ống. Vặn và nhấc nắp màu xanh để mở.",
      "step_2": "Đặt giấy lấy mẫu đi kèm lên chậu toilet ở trên mặt nước.",
      "step_3": "Để mẫu phân lên trên giấy lấy mẫu.",
      "step_4": "Lấy mẫu phân trước khi giấy chìm và mẫu phân chạm nước.",
      "step_5": "Bóc bề mặt phân bằng que lấy mẫu.",
      "step_6": "Lấy mẫu phân sao cho mẫu phân phủ hết phần xù xì của que lấy mẫu.",
      "step_7": "Đóng lọ lấy mẫu bằng cách cho que lấy mẫu vào và vặn chặt nắp màu xanh lá. Không mở ra lại.",
      "step_8": "Dội nước. Giấy lấy mẫu có thể phân hủy sinh học và sẽ không làm hại đến hệ thống tự hoại.",
      "step_9": "Lấy giấy thấm quấn lọ lấy mẫu lại rồi cho vào túi đựng mẫu vật sinh học."
    },
    "contact_support": {
      "title": "Hãy khắc phục",
      "text_1": "Chúng tôi rất tiếc khi biết có lỗi xảy ra!",
      "text_2": "Hãy liên lạc chúng tôi để cho chúng tôi biết mình có lỗi gì và để hỗ trợ thay thế bộ xét nghiệm của bạn."
    },
    "contact_support_success": {
      "title": "Chúng tôi đã nhận tin nhắn",
      "text_1": "Cảm ơn đã liên lạc với chúng tôi.",
      "text_2": "Một thành viên trong nhóm hỗ trợ của chúng tôi sắp liên lạc.",
      "button_text": "Quay về trang chủ"
    },
    "kit_in_transit_to_patient": "Đơn hàng của bạn đang tới! Đa số đơn hàng được giao tới trong vòng 2 – 5 ngày làm việc. Sau khi bạn nhận bộ xét nghiệm, chúng tôi sẽ gửi thêm thông tin cho bạn về cách kích hoạt và gửi trả lại. \n\nĐường dẫn theo dõi: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}\"'",
    "kit_received_at_lab": "Phòng xét nghiệm đã nhận bộ xét nghiệm của bạn! Bạn sẽ nhận thông báo để kiểm tra cổng thông tin bệnh nhân khi kết quả sẵn sàng",
    "registration_confirmation": "đăng ký hiện đã hoàn tất! Truy cập trang xác nhận đăng ký để kích hoạt bộ xét nghiệm của bạn:",
    "kit_ordered_online": "Chúng tôi đã nhận đơn hàng bộ xét nghiệm và chúng tôi sẽ cập nhật cho bạn sau khi gửi hàng đi! Đơn hàng #: {{order_number}} Ngày đặt: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Chúng tôi đã nhận đơn hàng!",
      "p1": "Chúng tôi đã nhận đơn hàng! Sau khi đơn hàng gửi đi, chúng tôi sẽ gửi cho bạn một cập nhật khác.",
      "order_number": "Đơn hàng #",
      "order_date": "Ngày đặt"
    },
    "completed": "Đã hoàn tất",
    "I": "bộ xét nghiệm này bao gồm xét nghiệm HIV.",
    "CC": "bộ xét nghiệm này bao gồm xét nghiệm ung thư đại trực tràng.",
    "CE": "bộ xét nghiệm này bao gồm xét nghiệm bệnh celiac.",
    "CR": "bộ xét nghiệm này bao gồm xét nghiệm creatinine.",
    "DD": "bộ xét nghiệm này bao gồm xét nghiệm tiểu đường.",
    "H": "bộ xét nghiệm này bao gồm xét nghiệm HPV.",
    "P": "bộ xét nghiệm này bao gồm xét nghiệm viêm gan C.",
    "HH": "bộ xét nghiệm này bao gồm xét nghiệm hemoglobin.",
    "HS": "bộ xét nghiệm này bao gồm xét nghiệm HSV-2.",
    "TT": "bộ xét nghiệm này bao gồm xét nghiệm testosterone.",
    "VD": "bộ xét nghiệm này bao gồm xét nghiệm vitamin D."
  },
  "copy_link": "Sao chép đường dẫn trang",
  "copied_link": "Đã sao chép đường dẫn!",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Bảo vệ chống lại virus cúm thông thường",
      "vaccine_covid": "Bảo vệ chống lại virus COVID-19",
      "tdap": "Vaccine Tdap có thể ngăn ngừa bệnh uốn ván",
      "polio": "Bảo vệ chống lại virus bại liệt. Bắt buộc đối với trẻ em trước khi bắt đầu đi học.",
      "varicella": "Vaccine bảo vệ chống lại bệnh thủy đậu",
      "shingles": "Vaccine bảo vệ chống lại bệnh zona. Tuổi tối thiểu là 18.",
      "human_papillomavirus": "Vaccine bảo vệ chống lại HPV. Đề xuất đối với trẻ em 11 tuổi.",
      "meningococcal": "Vaccine bảo vệ chống lại bốn loại vi khuẩn gây viêm màng não mô cầu."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Prepare to ship your sample:",
      "step_1": {
        "label": "Put the biohazard bag into box.",
        "substep_1": "Remove the return shipping mailer from your test kit.",
        "substep_2": "Place the biohazard bag into the original collection kit box and close the box."
      },
      "step_2": {
        "label": "Put box into return shipping mailer.",
        "substep_1": "Place the box into the return shipping mailer and seal the mailer.",
        "substep_2": "Do not fold or cover the UN3373 diamond symbol."
      },
      "step_3": {
        "label": "Return to Fedex location.",
        "substep_1": "Return kit on the same day of collection (before the last pickup) to a Fedex location.",
        "substep_2": "Call for a FedEx pickup at 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Welcome to the Viral Sequencing Program!",
      "subtitle": "Your participation helps provide valuable information on which respiratory pathogen variants are circulating in your area. Please follow the instructions that came with your kit to get started."
    },
    "instructions": {
      "title": "Collect your sample:",
      "step_1": "Wash your hands.",
      "step_2": "Remove the cap of the collection tube and open the swab package.",
      "step_2a": "Remove the swab from the package. Do not touch the soft end with your hands or anything else.",
      "step_3": "Collect a nasal swab.",
      "step_3a": "Insert the entire soft end of the swab into your nostril no more than 3/4 of an inch (1/5cm) into your nose.",
      "step_3b": "Slowly rotate the swab",
      "step_3c": "Gently remove the swab.",
      "step_3d": "Using the same swab, repeat in your other nostril with the same end of the swab.",
      "step_4": "Put swab into the collection tube and cap the tube securely.",
      "step_5": "Put the collection tube into the biohazard bag and seal.",
      "step_6": "Apply even pressure to the ziplock to seal the bag shut."
    },
    "click_to_get_started_button": "Click to get started",
    "get_started_button": "Get started",
    "thank_you": {
      "title": "Thank you for using Primary.Health!",
      "p_3": "You may now close the browser.",
      "p_2": "Please make sure to drop off the viral surveillance kit at your closest FedEx location.",
      "p_1": "Your participation actively helps Public Health officials fight the spread of disease. If you would like to see the impact you are making on Public Health, please take a moment to explore more via [this link]({{link}}). You will not be contacted about your results, as they are anonymous."
    },
    "verify_dob_title": "First, let's confirm your details",
    "verify_dob_subtitle": "This helps us ensure the correct person is using the test kit.",
    "activate_kit": "Activate a kit:",
    "steps_complete": "Steps complete",
    "opt_in": {
      "section_1_title": "How it works",
      "section_1_text_1": "Sign up below and receive a viral sequencing test kit in 1-3 business days.",
      "section_1_text_2": "Upon receiving the test kit",
      "section_1_text_3": "Send the sample back per the instructions in the kit using our prepaid label.",
      "sign_up": "Sign me up!",
      "no_to_participate": "No",
      "section_2_title": "What is the impact of your participation?",
      "section_2": {
        "card_1_title": "Rapid response to new variants",
        "card_1_text": "Genetic sequencing helps track the spread and emergence of new respiratory pathogen variants, allowing public health systems to quickly ramp up preparedness efforts.",
        "card_2_title": "Track how Respiratory Pathogens are changing",
        "card_2_text": "Respiratory pathogen sequencing analyzes the “genetic blueprint” of the virus. This helps scientists trace how the virus changes, enabling it to spread more easily, make people more sick",
        "card_3_title": "You have complete privacy",
        "card_3_text": "The sequencing will be processed anonymously. The lab will never contact you about your results"
      },
      "title": "You’re eligible for California’s Free Respiratory Pathogen Viral Sequencing Project!",
      "subtitle": "You can help public health officials protect communities against respiratory pathogens! If you opt in to participate you will receive a free viral sequencing kit that will test what respiratory pathogen strain you are infected with. Results will help officials track the spread of respiratory pathogen variants. See below for more information and to sign up.",
      "continue_to_result": "Continue to my result",
      "create_page_title": "Thank you!",
      "create_page_text_1": "Your kit should arrive in the next 1-3 business days.",
      "create_page_text_2": "Your participation actively helps Public Health officials fight the spread of diseases. You will be notified when your test kit order has been approved and when it has shipped."
    }
  },
  "family_registrations": {
    "find_clinic": "Tìm phòng khám",
    "select_pin": "Chọn ghim để xem chi tiết",
    "miles_shorten": "{{miles}} dặm",
    "next_available": "Hiện có tiếp theo",
    "appointment_type": "Loại cuộc hẹn",
    "individual_appointment": "Cuộc hẹn cá nhân",
    "family_appointment": "Cuộc hẹn gia đình",
    "vaccines": "Tiêm chủng",
    "what_kind_appointment": "Bạn muốn đặt loại cuộc hẹn nào?",
    "select_family_or_individual": "Chọn cuộc hẹn gia đình nếu bạn đang đặt lịch cho nhiều thành viên trong gia đình cùng lúc.",
    "vaccines_selected": "Vaccine được chọn",
    "where_care": "Bạn muốn được chăm sóc ở đâu?",
    "select_vaccines_for_individual": "Chọn vaccine cho cuộc hẹn của bạn (phụ).",
    "select_vaccines_for_family": "Chọn vaccine cho cuộc hẹn gia đình của bạn (phụ).",
    "schedule_services": "Lên lịch dịch vụ",
    "add_family": "Add family members and select services below.",
    "family_member": "Thành viên gia đình {{number}}",
    "morning": "Buổi sáng",
    "afternoon": "Buổi chiều",
    "slot_available": "Có {{number}}",
    "within_x_miles": "Trong vòng {{miles}} dặm tính từ",
    "any_distance": "Khoảng cách bất kỳ từ",
    "partial_results": "Kết quả một phần",
    "partial_matches": "Những phòng khám sau đây có một số vaccine mà bạn đang tìm",
    "no_matches": "Tìm kiếm đó không có kết quả nào. Thử thay đổi bộ lọc để có kết quả phù hợp hơn.",
    "no_clinics_found": "Không có phòng khám nào trùng khớp với tìm kiếm.",
    "broaden_filters": "Thử mở rộng bộ lọc để có kết quả phù hợp hơn.",
    "unavailable_vaccines": "Vaccine không có:",
    "available_vaccines": "Vaccine hiện có:",
    "select_date": "Chọn ngày",
    "available_appointments": "CUỘC HẸN HIỆN CÓ",
    "appointment_scheduling_info": "Bạn có thể bố trí giờ hẹn cho thành viên cụ thể trong gia đình ở bước tiếp theo. Bạn có thể lên lịch hẹn vào những ngày khác nhau nếu chọn.",
    "hold_selected_appointments": "Chúng tôi giữ cuộc hẹn được chọn trong 15 phút.",
    "appointments_selected": "CUỘC HẸN ĐƯỢC CHỌN",
    "no_appointments_selected": "Không có cuộc hẹn nào được chọn",
    "vaccines_needed": "Vaccine cần thiết",
    "select_x_appointments": "Chọn {{number}} cuộc hẹn.",
    "more": "More",
    "less": "Less",
    "register_next_family_member": "Register next family member",
    "successfully_registered_x_of_y_family_members": "You have successfully registered {{x}} of {{y}} family members",
    "next_family_member": "Next family member",
    "appointments_abbreviated": "appts",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Hãy xác minh e-mail!",
    "hi_full_name": "Xin chào {{full_name}},",
    "verify_email_button": "Xác minh e-mail",
    "please_verify_email": "Vui lòng nhấn nút bên dưới để xác minh e-mail."
  },
  "services": {
    "dptap_dt": "DTaP/Tdap/Td",
    "hepatitis_a": "Viêm gan A",
    "hepatitis_b": "Viêm gan B",
    "hepatitis_a_b": "Viêm gan A & B",
    "hib": "Hib",
    "mmr": "Sởi, quai bị, rubella",
    "meningococcal": "Viêm màng não mô cầu",
    "mpox": "Mpox",
    "pneumococcal": "Phế cầu khuẩn",
    "polio": "Bại liệt",
    "rsv": "Virus hợp bào hô hấp",
    "rotovirus": "Rotavirus",
    "zoster": "Zona",
    "tetanus_diptheria": "Uốn ván & bạch cầu",
    "tdap": "Tdap",
    "typhoid": "Thương hàn",
    "varicella": "Varicella (thủy đậu)",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (người lớn)",
    "covid-19_adult_description": "Dành cho người 12 tuổi trở lên.",
    "covid-19_children": "COVID-19 (trẻ em)",
    "covid-19_children_description": "Dành cho trẻ 4 – 11 tuổi.",
    "covid-19_infants": "COVID-19 (trẻ sơ sinh)",
    "covid-19_infants_description": "Dành cho trẻ 6 tháng – 3 tuổi.",
    "influenza": "Cúm",
    "mmrv": "Measles, Mumps, Rubella and Varicella"
  },
  "deep_archived": {
    "admin": "For data security purposes, the data in the group {{test_group_name}} ({{slug}}) is archived and is no longer viewable or changeable from this page. Please reach out to your account manager or support@primary.health if you need assistance.",
    "participant": "This page is no longer available. Please reach out to your contacts from the health campaign: {{test_group_name}}. If you are having trouble viewing an old record, please contact support@primary.health for assistance."
  }
}