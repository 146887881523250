import axios from 'axios';
import React, { useEffect, useReducer, useCallback } from 'react';
import '../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import VerificationCodeInput from '../common/components/VerificationCodeInput';

const emailLabel = (email, mask) =>
  mask
    ? email.replace(
        /^(.)(.*)(.@.*)$/,
        (_, a, b, c) => a + b.replace(/./g, '*') + c,
      )
    : email;

const phoneNumberLabel = (phoneNumber, mask) =>
  mask
    ? phoneNumber.replace(
        /[0-9]{3}\-([0-9]{2,3})\-([0-9]{3})/,
        function ($0, $1, $2) {
          return '***-' + $1.replace(/\d/g, '*') + '-' + $2;
        },
      )
    : phoneNumber;

const newContactVerification = (params) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get('token')) params.token = urlParams.get('token');

  return axios.post('/registrations/contact_verifications', params);
};

const resendConfirmationCode = (contactVerificationId, method) =>
  axios.put(`/registrations/contact_verifications/resend`, {
    id: contactVerificationId,
    preferred_method: method,
  });

const verifyConfirmationCode = (contactVerificationId, code) =>
  axios.put('/registrations/contact_verifications/validate', {
    id: contactVerificationId,
    otp_code: code,
  });

const verifyContactReducer = (state, action) => {
  switch (action.type) {
    case 'setOtpCode':
      return { ...state, otpCode: action.value, isInvalidCode: false };
    case 'toggleVerificationMethod':
      return {
        ...state,
        verificationMethod:
          state.verificationMethod === 'email' ? 'phone_number' : 'email',
      };
    case 'setLoading':
      return {
        ...state,
        loading: action.value,
      };
    case 'setContactVerification':
      return {
        ...state,
        contactVerification: action.value,
        loading: false,
      };
    case 'setMessageResent':
      return {
        ...state,
        messageResent: action.value,
      };
    case 'setInvalidCode':
      return {
        ...state,
        isInvalidCode: action.value,
      };
    default:
      throw new Error('Unknown action.');
  }
};

export const useVerifyContactInformation = (
  email,
  phoneNumber,
  onVerify,
  verified = false,
  maskContact = false,
  defaultVerificationMethod = undefined,
) => {
  const [state, dispatch] = useReducer(verifyContactReducer, {
    otpCode: '',
    verificationMethod: defaultVerificationMethod
      ? defaultVerificationMethod
      : !!email
      ? 'email'
      : !!phoneNumber
      ? 'phone_number'
      : null,
    loading: false,
    isInvalidCode: false,
    messageResent: false,
    contactVerification: null,
  });

  const sendContactVerification = useCallback(async () => {
    dispatch({ type: 'setLoading', value: true });
    const response = await newContactVerification({
      contact_verification: {
        email,
        phone_number: phoneNumber,
      },
      preferred_method: state.verificationMethod,
    });
    dispatch({ type: 'setContactVerification', value: response.data });
  }, [state.verificationMethod]);

  const resend = useCallback(
    async (method = state.verificationMethod) => {
      dispatch({ type: 'setLoading', value: true });
      const response = await resendConfirmationCode(
        state.contactVerification?.id,
        method,
      );
      dispatch({ type: 'setContactVerification', value: response.data });
      dispatch({ type: 'setMessageResent', value: true });
    },
    [state.contactVerification, state.verificationMethod],
  );

  const toggleVerificationMethod = () => {
    dispatch({ type: 'toggleVerificationMethod' });
    resend(state.verificationMethod === 'email' ? 'phone_number' : 'email');
  };

  const verify = useCallback(
    async (otpCode) => {
      verifyConfirmationCode(state.contactVerification?.id, otpCode)
        .then(
          (response) =>
            response.data.verification_status === 'verified' &&
            onVerify(response.data.id),
        )
        .catch((_e) => {
          dispatch({ type: 'setInvalidCode', value: true });
        });
    },
    [state.contactVerification],
  );

  const contactLabel =
    state.verificationMethod == 'email'
      ? emailLabel(email, maskContact)
      : phoneNumberLabel(phoneNumber, maskContact);

  useEffect(() => {
    !verified && sendContactVerification();
  }, []);

  useEffect(() => {
    state.contactVerification &&
      state.otpCode.length === 6 &&
      verify(state.otpCode);
  }, [state.otpCode, state.contactVerification?.id]);

  return {
    otpCode: state.otpCode,
    setOtpCode: (otpCode) => dispatch({ type: 'setOtpCode', value: otpCode }),
    canToggleVerificationMethod: phoneNumber && email,
    verificationMethod: state.verificationMethod,
    toggleVerificationMethod,
    loading: state.loading,
    isInvalidCode: state.isInvalidCode,
    messageResent: state.messageResent,
    resendConfirmationCode: resend,
    contactLabel: contactLabel,
  };
};

const VerifyContactInformation = ({
  email,
  phoneNumber,
  onVerify,
  verified = false,
  maskContact = false,
}) => {
  const { t, i18n } = useTranslation();
  const {
    setOtpCode,
    verificationMethod,
    canToggleVerificationMethod,
    toggleVerificationMethod,
    loading,
    isInvalidCode,
    resendConfirmationCode,
    messageResent,
    contactLabel,
  } = useVerifyContactInformation(
    email,
    phoneNumber,
    onVerify,
    verified,
    maskContact,
  );

  return (
    <div>
      <h3 className="kit-body-text">
        {t('registration.verify_contact.we_sent_you_a_code', {
          contact: contactLabel,
        })}
      </h3>
      {loading}
      <div className="my-5">
        <VerificationCodeInput
          onChange={setOtpCode}
          verified={verified}
          invalid={isInvalidCode}
        />
      </div>
      <div className="my-3 body big">
        {t('registration.verify_contact.did_not_receive_code')}{' '}
        <a
          className="font-weight-bold pointer body big"
          data-test-hook="resend"
          onClick={() => resendConfirmationCode()}
        >
          {t(
            `self_resulting.resend_${
              verificationMethod === 'email' ? 'email' : 'text'
            }`,
          )}
        </a>
      </div>
      {messageResent && (
        <div className="mb-4 text-success body big">
          {t(`registration.verify_contact.code_resent.${verificationMethod}`)}
        </div>
      )}
      {isInvalidCode && (
        <div className="mb-4 text-danger body big">
          {t('enter_code.incorrect_code')}
        </div>
      )}
      {canToggleVerificationMethod && (
        <div className="mb-4">
          <a
            className="p-0 font-weight-bold pointer body big"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              toggleVerificationMethod();
            }}
          >
            {t(
              `registration.verify_contact.verify_by.${
                verificationMethod === 'email' ? 'phone_number' : 'email'
              }`,
            )}
          </a>
        </div>
      )}
    </div>
  );
};

export default VerifyContactInformation;
