import React from 'react';

export const PaperPlane = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 18.652716 18.655907"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m 18.561586,0.09100735 c -0.0848,-0.0855 -0.2123,-0.1134 -0.3258,-0.0724 L 0.20494633,6.5482073 c -0.11751,0.0426 -0.19834,0.1517 -0.20455999846,0.2767 -0.006220002,0.125 0.0631099985,0.2416 0.17595999846,0.296 L 6.6692863,10.238007 12.534386,5.9021073 c 0.1436,-0.1061 0.3236,0.074 0.2173,0.2173 l -4.3348997,5.8660997 3.1164997,6.4941 c 0.0519,0.1079 0.1611,0.1763 0.2801,0.1763 0.0053,0 0.0103,0 0.0156,-3e-4 0.1249,-0.0066 0.2341,-0.0871 0.277,-0.2046 l 6.5284,-18.03409965 c 0.0407,-0.1135 0.0124,-0.2407 -0.0728,-0.3259 z"
      fill={color}
    />
  </svg>
);
