import React, { FC, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import DatePicker, { DateObject } from 'react-multi-date-picker';

import { weekDays } from '../../common/components/MultiDatePickerField';
import FilterArea, {
  ExpandedFilter,
} from '../../common/components/SearchFilters/FilterArea';
import FilterDropdown from '../../common/components/SearchFilters/FilterDropdown';
import SearchButton from '../../common/components/SearchFilters/SearchButton';
import EvenlySpacedRow, {
  AutoCol,
  Col,
} from '../../common/components/Layout/EvenlySpacedRow';
import SideBar, {
  Body,
  Footer,
} from '../../common/components/SearchFilters/SideBar';
import DateRangeField from '../../common/components/DateRangeField';
import { FilterOptions } from './Index';
import useFilters, {
  FilterOption,
  FilterMap,
  SetFilter,
  GetDisplayFilters,
  RemoveFilter,
} from '../../common/components/SearchFilters/hooks/useFilters';
import FilterValueProvider from '../../common/components/SearchFilters/FilterValueProvider';
import {
  dropdownValueLabel,
  valueAsLabel,
} from '../../common/components/SearchFilters/helpers/utils';

interface FilterUpdater {
  filters: FilterMap;
  setFilter: SetFilter;
  removeFilter: RemoveFilter;
}

const searchFilterOption = {
  paramKey: 'q',
  paramLabel: 'Search',
  multiValue: false,
};

const assigneeFilterOption = {
  paramKey: 'assignee_id',
  paramLabel: 'Assigned to',
  multiValue: true,
  numericValue: true,
};

const organizationFilterOption = {
  paramKey: 'organization_id',
  paramLabel: 'Organization',
  multiValue: true,
  numericValue: true,
};

const statusFilterOption = {
  paramKey: 'status',
  paramLabel: 'Status',
  multiValue: true,
};

const attemptsFilterOption = {
  paramKey: 'attempts',
  paramLabel: 'Attempts',
};

const serviceFilterOption = {
  paramKey: 'test_configuration_id',
  paramLabel: 'Service',
  multiValue: true,
  numericValue: true,
};

const resultFilterOption = {
  paramKey: 'result',
  paramLabel: 'Result',
  multiValue: true,
};

const startDayFilterOption = {
  paramKey: 'start_day',
  paramLabel: 'Start day',
  hide: true,
};

const endDayFilterOption = {
  paramKey: 'end_day',
  paramLabel: 'End day',
  hide: true,
};

const lastAttemptFilterOption = {
  paramKey: 'last_attempt',
  paramLabel: 'Last attempt',
};

const timeZoneFilterOption = {
  paramKey: 'time_zone',
  paramLabel: 'Time zone',
  multiValue: false,
};

const requisitioningPhysicianFilterOption = {
  paramKey: 'administered_requisitioning_physician_id',
  paramLabel: 'Requisitioning physician',
  multiValue: true,
  numericValue: true,
};

const MainFilters: FC<
  FilterOptions &
    FilterUpdater & {
      searchPath: string;
    }
> = ({ results, filters, setFilter, searchPath, statuses, assignees }) => (
  <EvenlySpacedRow>
    <Col>
      <FilterValueProvider
        filterOption={searchFilterOption}
        filters={filters}
        setFilter={setFilter}
      >
        {({ value, onChange }) => (
          <div className="string optional search_q">
            <input
              className="form-control string optional "
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              placeholder="Search by name or barcode"
              id="search_q"
            />
          </div>
        )}
      </FilterValueProvider>
    </Col>
    <Col>
      <FilterValueProvider
        filterOption={assigneeFilterOption}
        filters={filters}
        setFilter={setFilter}
      >
        {({ label, value, onChange }) => (
          <FilterDropdown
            label={label}
            value={value}
            onChange={onChange}
            options={assignees}
            isMulti
          />
        )}
      </FilterValueProvider>
    </Col>
    <Col>
      <FilterValueProvider
        filterOption={resultFilterOption}
        filters={filters}
        setFilter={setFilter}
      >
        {({ label, value, onChange }) => (
          <FilterDropdown
            label={label}
            value={value}
            onChange={onChange}
            options={results}
            isMulti
          />
        )}
      </FilterValueProvider>
    </Col>
    <Col>
      <FilterValueProvider
        filterOption={statusFilterOption}
        filters={filters}
        setFilter={setFilter}
      >
        {({ label, value, onChange }) => (
          <FilterDropdown
            label={label}
            value={value}
            onChange={onChange}
            options={statuses}
            isMulti
          />
        )}
      </FilterValueProvider>
    </Col>
    <Col>
      <DateRangeField
        values={[filters['start_day'], filters['end_day']]}
        startDateFieldName=""
        endDayFieldName=""
        onChange={(startDay: DateObject, endDay: DateObject) => {
          setFilter('start_day', startDay.format('YYYY-MM-DD'));
          setFilter('end_day', endDay.format('YYYY-MM-DD'));
        }}
        placeholder="Resulted at"
        includeToday={false}
      />
    </Col>
    <AutoCol>
      <SearchButton onClick={() => (window.location.href = searchPath)} />
    </AutoCol>
  </EvenlySpacedRow>
);

const AllFilters: FC<
  FilterOptions &
    FilterUpdater & {
      getDisplayFilters: GetDisplayFilters;
    }
> = ({
  testConfigurations,
  getDisplayFilters,
  results,
  requisitioningPhysicians,
  assignees,
  statuses,
  organizations,
  attempts,
  timeZones,
  filters,
  setFilter,
  removeFilter,
}) => (
  <React.Fragment>
    <FilterValueProvider
      filterOption={searchFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <div className="string optional search_q">
            <input
              className="form-control string optional "
              aria-label={label}
              type="text"
              value={value}
              onChange={(e) => onChange(e.target.value)}
              id="search_q"
            />
          </div>
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={assigneeFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={assignees}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={organizationFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={organizations}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={statusFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={statuses}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={attemptsFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={attempts}
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={serviceFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={testConfigurations}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={resultFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={results}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <ExpandedFilter
      label="Resulted at"
      displayFilters={
        filters['start_day'] && filters['end_day']
          ? [
              {
                paramKey: '',
                paramLabel: 'Resulted at',
                value: `${filters['start_day']} - ${filters['end_day']}`,
                label: `${filters['start_day']} - ${filters['end_day']}`,
                onRemove: () => {
                  removeFilter('start_day');
                  removeFilter('end_day');
                },
              },
            ]
          : []
      }
    >
      <DateRangeField
        values={[filters['start_day'], filters['end_day']]}
        startDateFieldName=""
        endDayFieldName=""
        onChange={(startDay: DateObject, endDay: DateObject) => {
          setFilter('start_day', startDay?.format('YYYY-MM-DD'));
          setFilter('end_day', endDay?.format('YYYY-MM-DD'));
        }}
        placeholder=""
        includeToday={false}
      />
    </ExpandedFilter>
    <ExpandedFilter
      label="Last attempt"
      displayFilters={getDisplayFilters('last_attempt')}
    >
      <DatePicker
        className="rmdp-primary"
        weekDays={weekDays}
        value={filters['last_attempt'] as string}
        onChange={(date?: DateObject) =>
          setFilter('last_attempt', date?.format('YYYY-MM-DD'))
        }
        format="YYYY/MM/DD"
        inputClass="form-control date-picker"
        containerStyle={{ flex: '1 0 3' }}
      />
    </ExpandedFilter>
    <FilterValueProvider
      filterOption={timeZoneFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={timeZones}
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
    <FilterValueProvider
      filterOption={requisitioningPhysicianFilterOption}
      filters={filters}
      setFilter={setFilter}
      getDisplayFilters={getDisplayFilters}
    >
      {({ label, value, onChange, displayFilters }) => (
        <ExpandedFilter label={label} displayFilters={displayFilters}>
          <FilterDropdown
            value={value}
            onChange={onChange}
            options={requisitioningPhysicians}
            isMulti
          />
        </ExpandedFilter>
      )}
    </FilterValueProvider>
  </React.Fragment>
);

const FilterSection: FC<FilterOptions> = (filterOptions) => {
  const availableFilters = useMemo<FilterOption[]>(() => {
    return [
      { ...searchFilterOption, valueToLabel: valueAsLabel } as FilterOption,
      {
        ...assigneeFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.assignees),
      } as FilterOption,
      {
        ...organizationFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.organizations),
      } as FilterOption,
      {
        ...statusFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.statuses),
      } as FilterOption,
      {
        ...attemptsFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.attempts),
      } as FilterOption,
      {
        ...serviceFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.testConfigurations),
      } as FilterOption,
      {
        ...resultFilterOption,
        valueToLabel: dropdownValueLabel(filterOptions.results),
      } as FilterOption,
      { ...startDayFilterOption, valueToLabel: valueAsLabel } as FilterOption,
      { ...endDayFilterOption, valueToLabel: valueAsLabel } as FilterOption,
      {
        ...lastAttemptFilterOption,
        valueToLabel: valueAsLabel,
      } as FilterOption,
      { ...timeZoneFilterOption, valueToLabel: valueAsLabel } as FilterOption,
      {
        ...requisitioningPhysicianFilterOption,
        valueToLabel: dropdownValueLabel(
          filterOptions.requisitioningPhysicians,
        ),
      } as FilterOption,
    ];
  }, [filterOptions]);

  const {
    filters,
    getDisplayFilters,
    getPathWithParams,
    setFilter,
    removeFilter,
    resetFilters,
  } = useFilters(availableFilters);

  return (
    <React.Fragment>
      <FilterArea
        displayFilters={
          filters['start_day'] && filters['end_day']
            ? [
                ...getDisplayFilters(),
                {
                  paramKey: '',
                  paramLabel: 'Resulted at',
                  value: '',
                  label: `${filters['start_day']} - ${filters['end_day']}`,
                  onRemove: () => {
                    removeFilter('start_day');
                    removeFilter('end_day');
                  },
                },
              ]
            : getDisplayFilters()
        }
        clearAll={resetFilters}
      >
        <MainFilters
          {...filterOptions}
          filters={filters}
          setFilter={setFilter}
          searchPath={getPathWithParams()}
          removeFilter={removeFilter}
        />
        <Button
          className="ms-auto mt-3"
          variant="link"
          style={{ height: 40, paddingInline: 16 }}
          data-bs-toggle="offcanvas"
          data-bs-target="#filterSideBar"
          aria-controls="filterSideBar"
          aria-label="Toggle navigation"
        >
          See all filters{' '}
          <FontAwesomeIcon className="ms-2" icon={faArrowRight as IconProp} />
        </Button>
      </FilterArea>
      <SideBar
        title="Filter cases"
        subTitle="Apply filters to your case manager view."
        id="filterSideBar"
      >
        <Body>
          <AllFilters
            {...filterOptions}
            filters={filters}
            setFilter={setFilter}
            removeFilter={removeFilter}
            getDisplayFilters={getDisplayFilters}
          />
        </Body>
        <Footer>
          <div
            className="d-flex flex-row justify-content-end"
            style={{ gap: 8 }}
          >
            <Button variant="link" className="me-2" onClick={resetFilters}>
              Clear filters
            </Button>
            <Button
              variant="primary"
              className="me-2"
              onClick={() => (window.location.href = getPathWithParams())}
            >
              Apply filters
            </Button>
          </div>
        </Footer>
      </SideBar>
    </React.Fragment>
  );
};

export default FilterSection;
