import Header from '../../Registration/components/Header';
import { ProgressBar, Container } from 'react-bootstrap';
import { FooterButtons } from './components/Buttons';
import { HeaderButton } from './components/Buttons';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  VerifyDateOfBirth,
  VerifyDateOfBirthContext,
} from '../../Otc/components/OtcVerifyDateOfBirth';
import { ShippingInformation } from './components/ShippingInformation';
import { useTranslation } from 'react-i18next';
import { TestKitOverview } from './components/TestKitOverview';
import { ContactUs } from './components/ContactUs';
import { Instructions } from './components/Instructions';
import { ThankYou } from './components/ThankYou';
import SHIPPING from './data/shipping';
import INSTRUCTIONS from './data/instructions';
import {
  ViralSurveillanceOptInContext,
  ViralSurveillanceOptIn,
} from '../../Otc/components/OtcSurveillance';

const TEST_KIT_OVERVIEW = 'TEST_KIT_OVERVIEW';
const VERIFY_DOB = 'VERIFY_DOB';
const INSTRUCTIONS_STEP = 'INSTRUCTIONS_STEP';
const SHIPPING_INFORMATION = 'SHIPPING_INFORMATION';
const THANK_YOU = 'THANK_YOU';
const params = new URLSearchParams(document.location.search);
const barcode = params.get('barcode') || params.get('id');
const skipVerifyDob = params.get('opt_in') || '';
const calculateSteps = () => {
  let allSteps = [];
  if (!skipVerifyDob) {
    allSteps = allSteps.concat(VERIFY_DOB);
  }
  allSteps = allSteps.concat(
    TEST_KIT_OVERVIEW,
    INSTRUCTIONS_STEP,
    SHIPPING_INFORMATION,
    THANK_YOU,
  );
  return allSteps;
};

const validateCredentials = async (
  appointment,
  dateOfBirth,
  lastName,
  setError,
) => {
  const response = await axios.post(`/kits/validate_credentials`, {
    date_of_birth: dateOfBirth,
    access_code: appointment.access_code,
    last_name: lastName,
  });

  if (response.data.error === 102) {
    setError(['incorrect_date_of_birth']);
  }

  return response.data.match;
};

const administerTestKit = async (appointment, barcode) => {
  const processedBarcode = barcode.trim().toUpperCase();
  const response = await axios.put(`/kits/${processedBarcode}`, {
    access_code: appointment.access_code,
  });

  return response.data.success;
};

export function ViralSurveillanceFlow({
  test_group,
  user,
  appointment,
  test_strip,
}) {
  const [currentStep, setCurrentStep] = useState(0);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
  const [activeErrors, setActiveErrors] = useState({});
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [lastName, setLastName] = useState('');
  const [viralSurveillanceOptIn, setViralSurveillanceOptIn] = useState(
    params.get('opt_in') == 'true',
  );
  const steps = calculateSteps();
  const progress = Math.floor(((currentStep + 1) * 100) / steps.length);
  const { t } = useTranslation();
  const stepName = steps[currentStep];
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;
  const kitImage =
    'https://primarybio-public.s3.us-east-2.amazonaws.com/STI At Home-1672962307.png';

  const stepForward = async () => {
    if (stepName === VERIFY_DOB) {
      const dobIsValid = await validateCredentials(
        appointment,
        `${dateOfBirth.year}-${dateOfBirth.month}-${dateOfBirth.day}`,
        lastName,
        setError,
      );
      if (dobIsValid) {
        setCurrentStep(currentStep + 1);
        clearErrors();
      }
    } else if (stepName === TEST_KIT_OVERVIEW) {
      const administerIsValid = await administerTestKit(appointment, barcode);
      if (administerIsValid) {
        setCurrentStep(currentStep + 1);
        clearErrors();
      } else {
        setError('error_activating_test_kit');
      }
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const stepBackward = () => {
    setCurrentStep(currentStep - 1);
  };

  const setError = (error) => {
    const newError = {};
    newError[error] = errorOptions[error];
    setActiveErrors({ ...activeErrors, ...newError });
  };

  const clearErrors = () => {
    setActiveErrors({});
  };

  const errorOptions = {
    incorrect_date_of_birth: t('errors.incorrect_credentials'),
    barcodes_must_match: t('self_administration.errors.different_barcodes'),
    error_activating_test_kit: t('errors.error_activating_test_kit'),
  };

  const handleSurveillance = (userWantsToParticipate = true) => {
    if (userWantsToParticipate) {
      setViralSurveillanceOptIn(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (
      stepName === VERIFY_DOB &&
      (!dateOfBirth.day || !dateOfBirth.month || !dateOfBirth.year)
    ) {
      setNextButtonDisabled(true);
    } else {
      setNextButtonDisabled(false);
    }
  }, [stepName, dateOfBirth]);

  const props = {
    stepForward,
    stepBackward,
    test_group,
    appointment,
    stepName,
    nextButtonDisabled,
    steps,
    isLastStep,
    isFirstStep,
    t,
    activeErrors,
  };

  if (test_strip?.administered) {
    return (
      <>
        <Header testGroup={test_group} />
        <Container
          style={{ maxWidth: '560px' }}
          className="pt-2 px-4"
          id="self-checkout"
        >
          <ThankYou />
        </Container>
      </>
    );
  }

  if (viralSurveillanceOptIn) {
    return (
      <>
        <Header testGroup={test_group} />
        <ViralSurveillanceOptInContext.Provider
          value={{
            handleSurveillance,
            showOptOut: false,
          }}
        >
          <ViralSurveillanceOptIn />
        </ViralSurveillanceOptInContext.Provider>
      </>
    );
  }

  return (
    <div>
      <Header testGroup={test_group} borderBottom={false} />
      <ProgressBar now={progress} variant="primary" />
      <div>
        <div style={{ minHeight: 'calc(100vh - 188px)' }}>
          <div className="d-flex justify-content-between mx-3 pt-1">
            <div>
              {t('viral_surveillance.activate_kit')} {user.first_name}
            </div>
            <div>{t('otc.x_%_completed', { n: progress })}</div>
          </div>
          <HeaderButton {...props} />
          <Container
            style={{ maxWidth: '560px' }}
            className="pt-2 px-4"
            id="self-checkout"
          >
            <div>
              {stepName === VERIFY_DOB && (
                <VerifyDateOfBirthContext.Provider
                  value={{
                    dateOfBirth,
                    setDateOfBirth,
                    lastName,
                    setLastName,
                    firstName: user.first_name,
                    activeErrors: activeErrors,
                    testGroup: test_group,
                    imageUrl: kitImage,
                    imageClassName: 'text-right',
                    imageStyle: { maxHeight: '128px' },
                    title: t('viral_surveillance.verify_dob_title'),
                    subtitle: t('viral_surveillance.verify_dob_subtitle'),
                    requestLastName: true,
                  }}
                >
                  <VerifyDateOfBirth />
                </VerifyDateOfBirthContext.Provider>
              )}
              {stepName === TEST_KIT_OVERVIEW && <TestKitOverview />}
              {stepName === INSTRUCTIONS_STEP && (
                <Instructions {...props} data={INSTRUCTIONS} />
              )}
              {stepName === SHIPPING_INFORMATION && (
                <ShippingInformation data={SHIPPING} {...props} />
              )}
              {stepName === THANK_YOU && <ThankYou />}
            </div>
          </Container>
          <FooterButtons {...props} />
        </div>
      </div>
    </div>
  );
}
