import React, { useState } from 'react';
import ResultsTable from './ResultsTable';
import { Button } from 'react-bootstrap';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { vaccinationStatus } from '../../VaccineCardUpload/common/utils';

import FixInsuranceModal from './FixInsuranceModal';

const Results = ({ data }) => {
  const { t, i18n } = useTranslation();
  const { appointment, user, test_group_user, test_group, current_member } =
    data;
  const [showInsuranceModal, setShowInsuranceModal] = useState(
    user.admin_requests_change_on_insurance,
  );

  const params = {
    last_name: user.last_name,
    date_of_birth: user.date_of_birth,
    access_code: test_group_user.access_code || '',
    locale: i18n.language,
    key: appointment.registration_access_key || '',
    registration_type: appointment.population_name || '',
  };
  Object.keys(params).forEach(
    (key) => (params[key] === null || params[key] === '') && delete params[key],
  );

  const accessCodeRegistrationUrl = `/a/${test_group.slug}/new?${jQuery.param(
    params,
  )}`;

  const printSelectedTest = (id) => {
    const testsToHide = Array.from(
      document.getElementsByClassName('test-card'),
    ).filter((el) => el.id !== id.toString());
    testsToHide.forEach((e) => e.classList.add('d-none'));
    window.addEventListener('afterprint', () => showAllTests());
    window.print();
  };

  const showAllTests = () => {
    document
      .getElementsByClassName('test-card')
      .forEach((e) => e.classList.remove('d-none'));
  };

  const vaccineStatus = vaccinationStatus(data.covid_vaccine.status);

  return (
    <div className="flex shadow mb-2" id="results_page">
      <div className="results__sidebar d-lg-block d-none d-print-block">
        <p className="text-muted">{t('member.medical_history')}</p>
        <hr />

        <p className="text-muted mb-0">{t('instructions.name')}</p>
        <h5>{user.full_name}</h5>

        <p className="text-muted mb-0 mt-3">
          {t('registration.date_of_birth')}
        </p>
        <h5>{user.date_of_birth}</h5>
        {test_group.request_additional_appointment && (
          <React.Fragment>
            <hr />
            <a
              href={accessCodeRegistrationUrl}
              className="btn btn-block btn-primary d-print-none"
            >
              {t('instructions.request_additional_appointment')}
            </a>
          </React.Fragment>
        )}
        {(data.covid_vaccine.found || test_group.collect_vaccine_card) && (
          <React.Fragment>
            <hr />
            <p className="text-muted mb-0 mt-3">
              {t('vaccination_status.vaccination_records')}
            </p>
            <h5 className={vaccineStatus.class}>{t(vaccineStatus.key)}</h5>
            <a
              className="btn btn-block btn-primary d-print-none"
              href={`/v/${test_group.slug}?access_code=${test_group_user.access_code}&last_name=${user.last_name}&date_of_birth=${user.date_of_birth}`}
            >
              {t('login.update_vaccination')}
            </a>
          </React.Fragment>
        )}
        {!user['belongs_to_account?'] && (
          <Button
            variant="info"
            block
            className="mt-2"
            href={`/members/sign_up?invite_code=${user.invite_code}`}
          >
            Add to Account
          </Button>
        )}
      </div>
      <div className="w-100 p-3">
        <h5>
          {t('member.medical_history_title_with_name', {
            name: user.full_name,
          })}{' '}
          {
            <Button variant="link" onClick={() => window.print()}>
              <FontAwesomeIcon icon={faPrint} />
            </Button>
          }
        </h5>
        {/Android|iPhone|iPad|BlackBerry/i.test(navigator.userAgent) && (
          <div>
            <p className="text-muted mb-0 mt-3">
              {t('registration.date_of_birth')}
            </p>
            <h5>{user.date_of_birth}</h5>
          </div>
        )}
        {user.admin_requests_change_on_insurance && (
          <React.Fragment>
            <Button
              onClick={() => setShowInsuranceModal(true)}
              variant="warning"
            >
              Fill out Insurance Information
            </Button>
            {showInsuranceModal && (
              <FixInsuranceModal
                test_group={test_group}
                user={user}
                setShow={setShowInsuranceModal}
              />
            )}
          </React.Fragment>
        )}
        {appointment.tests &&
          appointment.tests.map((test) => (
            <ResultsTable
              test={test}
              key={test.id}
              printSelectedTest={printSelectedTest}
            />
          ))}
        <LocalizedMarkdown container={test_group} stringKey="result_text" />
        {test_group.request_additional_appointment && (
          <a
            href={accessCodeRegistrationUrl}
            className="btn btn-primary d-lg-none d-print-none"
          >
            {t('instructions.request_additional_appointment')}
          </a>
        )}
      </div>
    </div>
  );
};

export default Results;
