import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { Printer } from '../../common/components/Icons/Printer';

const VaccineSection = ({ test, printSelectedTest }) => {
  const { t, i18n } = useTranslation();
  const renderThirdRow =
    test.custom_templates.length > 0 || test.test_group_test_configuration;

  return (
    <React.Fragment>
      <div className="mb-3 mt-3 test-card" id={test.id}>
        <Row className="results__table-top-row pt-3 pb-3 mx-1 border-bottom-0 rounded-top">
          <Col xs={12} md={4} lg={4} sm={6} xl={4}>
            <h5 className="mb-0 fw-bold">
              {test.test_configuration.display_name}
            </h5>
          </Col>
          <Col className="d-flex align-items-center">
            <div>
              <span>
                {t(
                  `date.${
                    test.administered_at_month &&
                    test.administered_at_month.toLowerCase()
                  }`,
                )}{' '}
                {test.administered_at_day}, {test.administered_at_year}{' '}
                {test.administered_at_hour}:{test.administered_at_minute}{' '}
                {test.administered_at_am_pm}
              </span>
              <br />
              <span className="text-muted"> at {test.location_name}</span>
            </div>
          </Col>
          <Col xs="auto">
            <Button
              className="btn-icon btn-transparent"
              onClick={() => printSelectedTest(test.id)}
            >
              <Printer />
            </Button>
          </Col>
        </Row>
        <Row className="mx-1 border-end border-start border-bottom">
          <Col xs={6} className="mb-0 text-secondary fw-bold py-3 my-1 ps-2">
            Administrator Name
          </Col>
          <Col xs={6} className="mb-0 fw-bold py-3 my-1 ps-2">
            {test.vaccine_result.administrator_name} (
            {test.vaccine_result.administrator_degree})
          </Col>
          <Col xs={6} className="mb-0 text-secondary fw-bold py-3 my-1 ps-2">
            Vaccine name
          </Col>
          <Col xs={6} className="mb-0 fw-bold py-3 my-1 ps-2">
            {test.vaccine_result.vaccine_route.trade_name_with_manufacturer}
          </Col>
          <Col xs={6} className="mb-0 text-secondary fw-bold py-3 my-1 ps-2">
            Vaccine Dose
          </Col>
          <Col xs={6} className="mb-0 fw-bold py-3 my-1 ps-2">
            {test.vaccine_result.dose} mL
          </Col>
          <Col xs={6} className="mb-0 text-secondary fw-bold py-3 my-1 ps-2">
            Lot Number
          </Col>
          <Col xs={6} className="mb-0 fw-bold py-3 my-1 ps-2">
            {test.vaccine_result.lot_number}
          </Col>
        </Row>
        {renderThirdRow && (
          <Row className="results__table-bottom-row pt-3 pb-3 px-2 mx-1 border-top-0 rounded-bottom">
            <Col
              xs={12}
              md={4}
              lg={4}
              sm={6}
              xl={4}
              className="mb-0 fw-bold text-secondary"
            >
              Documents
            </Col>
            <Col>
              {test.custom_templates.map((custom_template) => (
                <div>
                  <a href={custom_template.url} target="_blank">
                    {custom_template.name}
                  </a>
                </div>
              ))}
              {test.test_group_test_configuration?.documentation_url && (
                <a
                  href={test.test_group_test_configuration.documentation_url}
                  target="_blank"
                >
                  Test Documentation
                </a>
              )}
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

export default VaccineSection;
