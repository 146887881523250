import React, { useContext, Component } from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import PropTypes from 'prop-types';
import { buildLocalizedString } from '../../../../common/locales/langs';
import { useTranslation } from 'react-i18next';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ReactMarkdown source={this.props.rawMarkdown } />
    }

    return this.props.children;
  }
}


const LocalizedMarkdown = ({container, stringKey, user}) => {
  const { t, i18n } = useTranslation();
  let accessCodeList = ["instructions_text", "appointment_slot_group_location_instruction_text"]
  let source = buildLocalizedString(container, stringKey, i18n.language);
  accessCodeList.includes(stringKey) && (source = source.replace(/%{user.access_code}/g, user.access_code).replace(/%{test_group_user.access_code}/g, user.test_group_user_access_code))
  const converter = new showdown.Converter();
  const convertedSource = converter.makeHtml(source)

  return (
    <ErrorBoundary rawMarkdown={convertedSource}>
      <ReactMarkdown source={convertedSource} escapeHtml={false} />
    </ErrorBoundary>
  )
};

LocalizedMarkdown.propTypes = {
  container: PropTypes.object.isRequired,
  stringKey: PropTypes.string.isRequired,
};

export default LocalizedMarkdown;
