import React, { FC } from 'react';

const BADGE_COLOR = {
  active: 'success-dark',
  archived: 'danger-dark',
  demo: 'primary',
  in_development: 'secondary',
  deep_archived: 'warning text-dark',
};

interface GroupStatusProps {
  status: keyof typeof BADGE_COLOR;
}

const GroupStatus: FC<GroupStatusProps> = ({ status }) => (
  <span
    className={`badge bg-${BADGE_COLOR[status]}`}
    style={{ textTransform: 'capitalize' }}
  >
    {status.replace(/\_/gi, ' ')}
  </span>
);

export default GroupStatus;
