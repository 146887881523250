import React from 'react';
import { Badge } from 'react-bootstrap';
import { getError } from '../../../CheckoutPage/components/UserErrors'
import { today } from '../../../common/utils';
import { sentenceCase } from "sentence-case";

const linkedAppointmentBadge = (appointment) => {
  let variant = "info text-dark"
  let message, warning;
  let requiresBadge = true


  const type = {
    first_dose: appointment["insist_first_dose_only?"] || appointment.appointment_type == "first_dose",
    second_dose: appointment["insist_second_dose_only?"] || appointment.appointment_type == "second_dose",
    third_dose: appointment["insist_third_dose_only?"] || appointment.appointment_type == "third_dose",
    booster: appointment.appointment_type == "booster",
    additional: appointment.appointment_type == "additional",
    supplemental: appointment.appointment_type == "supplemental",
    other: appointment.appointment_type != null && appointment.appointment_type != "default",
  }

  const overRideMessage = () => {
    variant = "info text-dark"
    requiresBadge = true;

    if (type.first_dose) {
      message = "First dose"
    } else if (type.second_dose) {
      message = "Second dose"
      variant = "pink text-dark"
    } else if (type.third_dose) {
      message = "Third dose"
      variant = "brand-5 text-dark"
    } else if (type.booster) {
      message = "Booster"
      variant = "success-shade-600"
    } else if (type.additional) {
      message = "Additional"
      variant = "secondary-shade-500"
    } else if (type.supplemental) {
      message = "Supplemental"
      variant = "warning-shade-600"
    } else {
      const capitalizedString = appointment.appointment_type.charAt(0).toUpperCase() + appointment.appointment_type.slice(1);
      message = capitalizedString;
    }
  }

  if (appointment["has_follow_up?"]) {
    message = "First dose"
  } else if (appointment["has_previous?"]) {
    message = "Second dose"
    variant = "pink text-dark"
  } else {
    requiresBadge = false;
  }

  if (type.other) overRideMessage();

  return ({requiresBadge, variant, message, warning})
}

const Badges = ({testGroupId, appointment, user}) => {
  const errors = user.primary_user_id ? (
    Object.keys(user.errors).filter(errorKey => (
      user.errors[errorKey].errored &&
        ["name", "date_of_birth", "guardian_consented", "unpaid"].includes(errorKey)
    ))
  ) : Object.keys(user.errors).filter(errorKey => user.errors[errorKey].errored)

  const guardianConsented = (
    !errors.find(errorKey => errorKey === "guardian_consented") &&
    appointment.test_group.minimum_age_of_consent &&
    user.age < appointment.test_group.minimum_age_of_consent &&
    appointment.test_group.show_guardian_consented_badge
  )

  const linkedAppointmentBadgeVariables = linkedAppointmentBadge(appointment)
  const appointmentIsToday = appointment.on_demand ? true : today(appointment.appointment_slot.localized_day);

  return (
    <React.Fragment>
      {errors.map((errorKey) => {
        const {
          errorMessage,
          errorUrl,
          icon
        } = getError(errorKey,user.errors,testGroupId, user, appointment);

        return (
          <div key={errorKey}>
          { errorUrl ? (
              <a target="_blank" className="badge bg-danger text-dark" href={errorUrl}>
                {sentenceCase(errorMessage)}
              </a>
            ) : (
              <span className="badge bg-danger text-dark">
                {sentenceCase(errorMessage)}
              </span>
            )
          }
          </div>
        )
      })}
      { linkedAppointmentBadgeVariables.requiresBadge &&
        <React.Fragment>
          { linkedAppointmentBadgeVariables.warning &&
            <Badge className="bg-warning text-dark">{linkedAppointmentBadgeVariables.warning}</Badge>
          }
          <Badge className={"bg-" + linkedAppointmentBadgeVariables.variant}>
            {linkedAppointmentBadgeVariables.message}
          </Badge>
        </React.Fragment>
      }
      { appointment.appointment_slot.on_demand &&
        <Badge className="bg-primary">On demand</Badge>
      }
      { appointment.user.age < 18 &&
        <Badge style={{background: "slateblue", color: "white"}}>Minor</Badge>
      }
      { guardianConsented &&
        <Badge className="bg-success">Guardian consented</Badge>
      }
      { !appointmentIsToday &&
        <Badge className="bg-warning text-dark">Appointment not today</Badge>
      }
    </React.Fragment>
  )
}

export default Badges;
