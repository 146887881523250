import React from 'react';
import FloatingLabelSelect from '../../common/components/FloatingLabelSelect';

const StatusDropdown = ({ godUser, status, updateFilter }) => {
  const STATUS_OPTIONS = [
    { value: '', label: 'All Statuses' },
    { value: '2', label: 'Active' },
    { value: '0', label: 'Demo' },
    { value: '1', label: 'In development' },
    { value: '3', label: 'Archived' },
    ...(godUser ? [{ value: '4', label: 'Template' }] : []),
  ];

  const selectedStatusOption = STATUS_OPTIONS.find(
    (option) => option.value === status,
  );

  return (
    <div className="dropdown">
      <FloatingLabelSelect
        name="test_group_status"
        ariaLabel="test_group_status select"
        value={!!status && selectedStatusOption}
        onChange={(s) => updateFilter('status', s.value)}
        options={STATUS_OPTIONS}
        filledValue={status}
        label="Group Status"
        isSearchable={true}
      />
    </div>
  );
};

export default StatusDropdown;
