import React, { useEffect, useState, useRef } from 'react';
import SelectableTable from './SelectableTable';
import TablePagination from '../../common/components/TablePagination';
import queryString from 'querystring';

const NewTestGroupUsers = ({
  is_global_search,
  on_demand_only,
  test_group_users,
  test_group,
  filtered_test_group_users_count,
  create_group_tag_path
}) => {

  const [selectedTestGroupUsers, setSelectedTestGroupUsers] = useState([]);
  const [filteredTestGroupUsers, setFilteredTestGroupUsers] = useState(test_group_users);
  const [selectedSingleTestGroupUser, setSelectedSingleTestGroupUser] = useState(test_group_users.first);

  const [page, setPage] = useState(1);
  const [pageMetadata, setPageMetadata] = useState({});

  const targetTestGroupUsers = useRef(document.getElementById('selected_test_group_user_ids'));

  const updapeTestGroupUserExportUrl = () => {
    const exportParticipantsLink = document.getElementById('export_participants_link')
    const exportParams = new URLSearchParams(exportParticipantsLink.href);
    exportParams.append('export_params[selected_test_group_user_ids]', selectedTestGroupUsers);
    const exportUrlParams = {}
    for (const [key, value] of exportParams.entries()) exportUrlParams[key] = value;
    exportParticipantsLink.href = `/organizations/${test_group.organization_id}/exports?${queryString.stringify(exportUrlParams)}`
  }

  const updateSelectedTestGroupUserValues = () => {
    if (!targetTestGroupUsers.current) return;
    targetTestGroupUsers.current.value = selectedTestGroupUsers;
    const modalTestGroupUserCount = document.getElementsByClassName('message-count')[0];
    const selectedTestGroupUserCount = document.getElementById('selected_participants_count');
    const testGroupUserCheckboxBadge = document.getElementById('tgu-checkbox-badge');
    if (selectedTestGroupUsers.length > 0) {
      if (!!modalTestGroupUserCount) modalTestGroupUserCount.innerHTML = selectedTestGroupUsers.length.toString();
      if (!!selectedTestGroupUserCount) selectedTestGroupUserCount.innerHTML = `(selected: ${selectedTestGroupUsers.length.toString()})`;
      if (!!testGroupUserCheckboxBadge) testGroupUserCheckboxBadge.classList.remove('d-none');
    } else {
      if (!!modalTestGroupUserCount) modalTestGroupUserCount.innerHTML = filtered_test_group_users_count.toString();
      if (!!selectedTestGroupUserCount) selectedTestGroupUserCount.innerHTML = '';
      if (!!testGroupUserCheckboxBadge) testGroupUserCheckboxBadge.classList.add('d-none');
    }
  }

  useEffect(() => {
    updapeTestGroupUserExportUrl();
    updateSelectedTestGroupUserValues();
  
    const filterParams = new URLSearchParams(window.location.search);
    const params = {page: page};
    for (const [key, value] of filterParams.entries()) params[key] = value;

    fetch(`/test_groups/${test_group.id}/test_group_users.json?${queryString.stringify(params)}`, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((resp) => resp.json())
      .then((json) => {
        setFilteredTestGroupUsers(json.test_group_users);
        setPageMetadata(json.meta);
      });
  }, [page, selectedTestGroupUsers]);

  return (
    <React.Fragment>
      <SelectableTable
        isGlobalSearch={is_global_search}
        onDemandOnly={on_demand_only}
        testGroupUsers={filteredTestGroupUsers}
        selectedTestGroupUsers={selectedTestGroupUsers}
        setSelectedTestGroupUsers={setSelectedTestGroupUsers}
        targetTestGroupUsers={targetTestGroupUsers}
        testGroup={test_group}
        selectedSingleTestGroupUser={selectedSingleTestGroupUser}
        setSelectedSingleTestGroupUser={setSelectedSingleTestGroupUser}
        createGroupTagPath={create_group_tag_path}
      />
      <TablePagination
        currentPage={pageMetadata.current_page}
        nextPage={pageMetadata.next_page}
        prevPage={pageMetadata.prev_page}
        totalPages={pageMetadata.total_pages}
        setPage={setPage}
      />
    </React.Fragment>
  );
};

export default NewTestGroupUsers;
