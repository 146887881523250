import React from 'react';

export const TestTube = ({ color = '#000000', ...props }) => (
  <svg
    viewBox="0 0 20.856014 24.797041"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m 9.5481127,2.3174612 8.8636003,5.11743 -8.1155,14.0563998 c -1.4131003,2.4476 -4.5428003,3.2863 -6.9905003,1.8731 -2.44760001,-1.4131 -3.28620001,-4.5429 -1.8731,-6.9905 z"
      stroke={color}
      strokeWidth="1.49939"
    />
    <path
      d="m 5.0465127,10.724891 1.9344,0.3548 c 1.42,0.2604 2.6395,1.1638 3.3024003,2.4463 l 0.4067,0.7869 1.9206,3.0875"
      stroke={color}
      strokeWidth="1.49939"
      strokeLinecap="round"
    />
    <rect
      x="7.6440468"
      y="-4.6591496"
      width="13.9943"
      height="2.28478"
      rx="0.583094"
      transform="rotate(30)"
      fill={color}
    />
  </svg>
);
