export default {
  "date": {
    "order": [
      "year",
      "month",
      "day"
    ],
    "month": "Місяць",
    "day": "День",
    "year": "Рік",
    "january": "Січень",
    "february": "Лютий",
    "march": "Березень",
    "april": "Квітень",
    "may": "Травень",
    "june": "Червень",
    "july": "Липень",
    "august": "Серпень",
    "september": "Вересень",
    "october": "Жовтень",
    "november": "Листопад",
    "december": "Грудень"
  },
  "user": {
    "send_appointment_confirmation_message": "Your appointment is confirmed for {{name}}.",
    "landline_appointment_reminder_message": "Hello, {{full_name}}. This is a reminder for your appointment at {{name}}. Your appointment is on {{date}} at {{time}} at {{address}}.",
    "send_at_home_visit_confirmation_message": "Your appointment is confirmed on {{date}} after {{time}}.",
    "send_mail_order_confirmation_message": "Your mail order is confirmed",
    "send_waitlist_message": "You have been waitlisted for {{name}}. Please do not show up until we confirm an appointment time.",
    "verify_contact": {
      "phone": "Будь ласка підтвердіть ваш номер телефону натиснувши: {{confirmation_link}}",
      "email": "{{confirmation_link}}"
    },
    "duplicate_user_continue_registering": "Продовжити реєстрацію на зустріч можна тут: {{url}}",
    "on_demand_confirmation_message": "Ви успішно зареєструвалися на {{name}}.",
    "appointment_reminder": "Перед зустріччю о {{start_time}},  перейдіть за посиланням, щоб виконати всі незавершені дії та переглянути своє підтвердження. Будь ласка, приходьте не раніше ніж за 15 хвилин до початку зустрічі.",
    "appointment_reminder_base": "{{text}} {{appt_text}} НАТИСНІТЬ ТУТ: {{link}}",
    "test_result_notifier": {
      "text_message": "Пацієнтський портал для {{first_name_with_last_initial}} оновлено. Перейдіть до {{url}} та використовуйте код: {{access_code}}",
      "email_subject": "Нові оновлення у вашому пацієнтському порталі ",
      "email_intro": "Вітаємо, {{first_name_with_last_initial}}",
      "email_p1": "Для вас доступне нове повідомлення або оновлення інформації про пацієнта.",
      "email_p2": "Натисніть на кнопку нижче або перейдіть за посиланням для перегляду свого пацієнтського порталу та отримайте код доступу",
      "email_p3": "Перегляньте пацієнтський портал на:",
      "email_button": "Перегляньте портал"
    },
    "landline_appointment_reminder_message_without_time": "Вітаємо, {{full_name}}. Це нагадування про Ваш прийом у {{name}}. Ваш прийом о {{date}} за адресою: {{address}}.",
    "appointment_reminder_on_demand": "Перед Вашим прийомом {{date}}, будь ласка, перейдіть за посиланням, щоб виконати весь незавершений перелік завдань та переглянути своє підтвердження.",
    "new_test_result_notifier": {
      "email_intro": "Ваш пацієнтський портал оновлено. ",
      "email_text_1": "Відвідайте Ваш портал \"Історія хвороби\" нижче, щоб переглянути нові записи пацієнта або лабораторні звіти.",
      "email_button": "Історія хвороби",
      "email_text_2": "Ви також можете скопіювати та вставити цю URL-адресу у Ваш браузер: "
    },
    "mailers": {
      "email_contact_us": "Маєте запитання? Зверніться до нас за"
    }
  },
  "errors": {
    "generic": {
      "message": "Щось пішло не так, будь ласка, спробуйте ще раз"
    },
    "messages": {
      "blank": "не може бути пустим"
    },
    "incorrect_credentials": "Ваші особисті дані введені неправильно, будь ласка, спробуйте ще раз.",
    "error_activating_test_kit": "Виникла помилка при активації тестового набору."
  },
  "type": "Надрукувати",
  "payment": {
    "continue_label": "Перейти до оплати",
    "policy_text": "Якщо Ви скасуєте прийом або не прийдете на нього, ми повернемо вам 80% сплачених коштів через 7 днів після дати прийому. Ви можете переглянути повну версію [Terms of Service]({{link}})",
    "card_number": "Номер картки",
    "expiry_date": "Дата закінчення терміну дії",
    "pay_button": "Оплатити",
    "no_credit_card": "Я оплачу на місці",
    "cash_payment_helper": "Будь ласка, переконайтеся, що Ви взяли з собою **{{payment}}** на Ваш прийом",
    "invoice": "Рахунок-фактура",
    "pay_by_card": "Оплатити карткою",
    "cost": "Вартість",
    "total": "Разом",
    "amount_paid": "Сплачена сума ",
    "balance": "Баланс",
    "pay_cash_at_appointment": "Я оплачу готівкою під час прийому у {{appointment}}",
    "view_invoice": "Переглянути/надрукувати рахунок-фактуру",
    "refund_policy": "Якщо Ви скасуєте прийом,  ми повернемо Вам {{amount}} сплачених коштів через 7 днів після дати прийому. Ви можете переглянути повну версію [Terms of Service]({{link}})",
    "refund_window_passed": "Оплата за Ваш прийом не може бути повернена, оскільки час прийому вже минув. Будь ласка, зверніться до координаторів місця проведення для вирішення будь-яких питань."
  },
  "consent_waiver": {
    "upload_waiver_or_sign": "Завантажте дозвіл на відступ від вимоги отримання згоди або підпишіть поле для підпису нижче",
    "stop": "Згода потрібна для завершення реєстрації",
    "parent_or_guardian_required_html": "Щоб завершити Вашу реєстрацію, Ваші батьки, опікуни або уповноважені особи, які приймають рішення, повинні надати згоду у формі нижче та підписати її своїм ім'ям. Ви <b>НЕ МОЖЕТЕ</b> самостійно дати згоду на отримання цієї послуги.",
    "send_parent_or_guardian": "Ви можете надіслати батькам або опікунам це посилання або попросити їх підписати нижче на цій сторінці.",
    "consent_guardian_name_signature": "Ім'я",
    "consent_for_minor": "Підписати згоду від імені неповнолітньої особи",
    "guardian_signature": "Підпис одного з батьків, опікуна або уповноваженої особи, яка приймає медичні рішення",
    "after_you_schedule": "Замість учасників віком до %{вік} один з їхніх  батьків або опікун повинен підписати згоду від  їхнього імені. Будь ласка, продовжуйте до кінця реєстрації, щоб надіслати опікуну або підписати за свого підопічного.",
    "consent_guardian_last_name_signature": "Прізвище ",
    "consent_first_and_last_name": "Ім'я та прізвище ",
    "phone_number": "Номер телефону",
    "email": "Електронна пошта опікуна",
    "consent_registrar_with_guardian_name_signature": "Ім'я опікуна",
    "consent_registrar_with_guardian_last_name_signature": "Прізвище опікуна",
    "consent_registrar_first_name_signature": "Ім'я реєстратора",
    "consent_registrar_last_name_signature": "Прізвище реєстратора",
    "consent_on_behalf": "Я підписую від імені {{first_name}} {{last_name}}.",
    "consent_on_behalf_registrar": "Мене усно уповноважили {{first_name}} {{last_name}} підписати цю заяву від їхнього імені.",
    "consent_on_behalf_registrar_with_guardian": "Мене усно уповноважив опікун {{first_name}} {{last_name}}, щоб підписати від їхнього імені.",
    "registrar_phone": "Номер телефону реєстратора ",
    "registrar_email": "Електронна пошта реєстратора",
    "consented_by_parent_guardian": "Один з батьків/Опікун",
    "consented_by_decision_maker": "Уповноважена особа, яка приймає медичні рішення",
    "consented_by_guardian_verbal": "Реєстратор з опікуном (усна згода отримана)",
    "consented_by_registrar": "Реєстратор (усна згода отримана)",
    "full_name": "Прізвище, ім'я та по батькові",
    "print": "Друкувати"
  },
  "registration": {
    "contact_information": "Ім'я та адреса",
    "contact_information_additional": "Контактна інформація батьків або опікунів для зв'язку та інформування про результати та  результатів",
    "personal_information": "Демографічна інформація",
    "address_placeholder": "Будь ласка, введіть вашу домашню адресу",
    "first_name_label": "Ім'я",
    "last_name_label": "Прізвище",
    "errors": {
      "state": "Держава має бути дійсним 2-символьним кодом держави",
      "phone_number_invalid": "Номер телефону недійсний",
      "required": "Це значення є обов'язковим",
      "field_required": "{{field}} повинне мати значення",
      "email_domain": "Ваш запит недійсний, будь ласка, перевірте ще раз. Реєстрація обмежується учасниками, відібраними організацією-спонсором. Будь ласка, спробуйте використати вашу робочу або громадську електронну пошту. Якщо Ви вважаєте, що маєте право на участь, але сталася помилка, будь ласка, зверніться до служби підтримки.",
      "age_requirement": "Дата народження не відповідає вимозі про вік",
      "signature_required": "Потрібен підпис",
      "regex_invalid": "Здається, це значення є недійсним.",
      "date_invalid": "Ця дата не існує",
      "invalid_entry": "Будь ласка, не продовжуйте реєстрацію. Дотримуйтесь інструкцій на екрані або зверніться до контактної особи.",
      "city": "Повинне бути дійсним містом",
      "survey_unanswered": "Будь ласка, перевірте, чи на всі обов'язкові питання, позначені *, були надані відповіді.",
      "postal_code": "Повинне бути дійсним 5-ти значним поштовим індексом",
      "option_required": "Ви повинні обрати один із варіантів",
      "year_length_invalid": "Введіть дату народження з чотиризначним роком (рррр)",
      "invalid_day": "День має бути між 1 - 31",
      "reached_max_chars": "Ви досягли максимальної кількості символів",
      "chars_max": "Максимальний вміст символів",
      "minimum_length": "Вхідні дані повинні мати довжину не менше {{length}} символів."
    },
    "insurance_status": {
      "question": "У Вас є медична страховка? ",
      "have_health_insurance": "Так, у мене є медична страховка",
      "do_not_have_health_insurance": "Ні, у мене немає медичної страховки "
    },
    "insurance_policy_holder": {
      "question": "Хто є власником полісу?",
      "i_am": "Я",
      "my_spouse": "Мій чоловік/дружина або партнер",
      "my_parents": "Мої батьки",
      "someone_else": "Хтось інший ",
      "policy_first_name": "Ім'я власника полісу",
      "policy_last_name": "Прізвище власника полісу",
      "policy_dob": "Дата народження власника полісу",
      "name": "Ім'я та прізвище власника полісу"
    },
    "insurance_information": {
      "title": "Інформація щодо страхування",
      "company_name": "Назва страхової компанії",
      "id_number": "Ідентифікаційний номер учасника",
      "group_number": "Номер групи учасника",
      "secondary_insurance_label": "У мене вторинна страховка",
      "take_photo": "Будь ласка, сфотографуйте свою страхову картку. Фотографування автоматично заповнить частину інформації.",
      "front_of_card": "Лицьова сторона картки",
      "card_information": "Інформація про страхову картку",
      "back_of_card": "Зворотний бік картки"
    },
    "employment_information": {
      "label": "Зайнятість",
      "address_1": "Адреса компанії",
      "address_2": "Наприклад, номер 200 ",
      "phone_number": "Номер телефону компанії",
      "company": "Назва компанії",
      "postal_code": "Поштовий індекс роботодавця",
      "employed": "Працюю",
      "sole_prorietor": "Фізична особа-підприємець",
      "not_employed": "Не працюю",
      "reporting_supervisor": "Керівник, якому Ви підзвітні",
      "reporting_department": "Відділ, якому Ви підзвітні",
      "supervisor_name": "Ім'я та прізвище керівника",
      "supervisor_email": "Електронна пошта керівника"
    },
    "location_availability": "Наразі тестування є пріоритетним для мешканців громад, які проживають на цих територіях",
    "custom_survey": "Анкета",
    "confirmation": "Огляд",
    "waitlisted": "У списку очікування",
    "schedule_your_appointment": "Запланувати прийом",
    "information": "Інформація",
    "consent": "Згода",
    "location": "Місцезнаходження",
    "symptoms": "Симптоми",
    "address_required": "Будь ласка, введіть вашу адресу",
    "consent_required": "Будь ласка, надайти згоду для продовження",
    "required_field": "Вказує на обов'язкове поле.",
    "phone_number": "Мобільний номер телефону",
    "email": "Адреса електронної пошти",
    "date_of_birth": "Дата народження",
    "minimum_age": "Мінімальний вік участі становить {{year}} років.",
    "no_minimum_age": "Немає мінімального віку для реєстрації",
    "continue_button": "Продовжити ",
    "email_required": "Це значення має бути дійсною адресою електронної пошти.",
    "done": "Зроблено",
    "signature": "Підпис",
    "clear_button": "Чисто",
    "back_button": "Назад",
    "choose_location": "Обрати місцезнаходження",
    "no_slots": "За адресою {{location}} більше немає вільних слотів",
    "choose_appointment": "Оберіть час прийому за адресою {{location}}",
    "appointment_required": "Будь ласка, оберіть час прийому",
    "phone_number_required": "Номер телефону недійсний",
    "phone_number_label": "Будь ласка, введіть номер, на який ви можете отримувати текстові повідомлення для швидшого отримання результатів тесту",
    "symptoms_experiencing": "Будь ласка, оберіть симптоми, які ви зараз відчуваєте. Якщо у вас немає симптомів, будь ласка, продовжуйте.",
    "household": "Члени домогосподарства",
    "household_p1": "За бажанням, створіть запис на прийом для членів домогосподарства, яким також потрібно пройти тестування.",
    "add_dependent": "Додайте члена домогосподарства",
    "remove_dependent": "Видалити залежну особу",
    "dependent_consents": "Згоди",
    "submit": "Завершено",
    "add_waitlist": "Додати до списку очікування",
    "address": "Домашня адреса",
    "address_1": "Рядок адреси 1",
    "address_2": "Кв./апартаменти №",
    "address_city": "Місто",
    "address_state": "Держава ",
    "postal_code": "Поштовий індекс",
    "race_ethnicity": "Раса",
    "gender": "Гендерна ідентичність",
    "self_described_gender": "Гендер за самоописом",
    "interpreter": "Потрібен усний перекладач? Якщо так, якою мовою? ",
    "consent_to_terms": "Я надаю згоду на ці умови. ",
    "reg_not_open": "Реєстрація не відрита",
    "no_more_avail_spots": "Обраний Вами часовий інтервал більше не доступний. Будь ласка, спробуйте ще раз.",
    "consent_helper": "Натисніть і проведіть мишею або пальцем полем нижче, щоб підписати",
    "phone_number_unreachable_label": "Стаціонарний телефон?",
    "select": "Обрати",
    "test_surveys": "Питання щодо прийому",
    "edit": "редагувати",
    "continue_to_registration": "Перейти до реєстрації",
    "accounts": {
      "already_have_an_account": "Вже маєте обліковий запис?",
      "log_in": "Вхід",
      "sign_up": "Зареєструватися",
      "sign_up_description": "Будь ласка, введіть свою інформацію, щоб створити обліковий запис і заощадити час при подальших реєстраціях.",
      "log_in_description": "Будь ласка, введіть свою електронну пошту та пароль або увійдіть через Google чи Outlook нижче",
      "sign_in_with_label": "Увійдіть за допомогою {{provider}}",
      "password_must_match": "Паролі повинні збігатися",
      "password_uppercase": "Ваш пароль повинен містити щонайменше (%s) великих літер.",
      "password_lowercase": "Ваш пароль повинен містити щонайменше (%s) маленьких літер.",
      "password_number": "Ваш пароль повинен містити щонайменше (%s) цифр.",
      "password_special": "Ваш пароль повинен містити щонайменше (%s) спеціальних знаків."
    },
    "password": "Пароль",
    "password_confirmation": "Підтвердження паролю",
    "consent_for": "Згода для {{name}}",
    "book_one_time_appointment": "Запишіться на одноразову зустріч",
    "duplicate_users": {
      "exists": "Ви вже зареєстровані",
      "overlapping_email_and_phone_p1": "Ми надіслали повідомлення на Ваш номер телефону та адресу електронної пошти.",
      "p2": "За допомогою свого повідомлення Ви можете змінити запис на прийом або ввести результати аналізів.",
      "overlapping_phone_and_email_p3": "Будь ласка, перевірте свою електронну пошту або телефон, щоб дізнатися подробиці.",
      "overlapping_email_p3": "Будь ласка, перевірте свою електронну пошту, щоб дізнатися подробиці.",
      "overlapping_phone_p3": "Будь ласка, перевірте свій телефон, щоб дізнатися подробиці.",
      "overlapping_phone_p1": "Ми надіслали повідомлення на ваш номер телефону.",
      "overlapping_email_p1": "Ми надіслали повідомлення на вашу адресу електронної пошти.",
      "p4": "Якщо Ви думаєте, що це помилка, будь ласка, зверніться до нас за адресою support@primary.health",
      "overlapping_email_and_phone_p3": "Будь ласка, перевірте свою електронну пошту або телефон, щоб дізнатися подробиці."
    },
    "duplicate_waitlist": {
      "exists": "Ви вже перебуваєте в списку очікування",
      "overlapping_email_and_phone_p1": "Ми надіслали ще одне підтвердження на Ваш номер телефону та адресу електронної пошти",
      "p2": "За допомогою свого підтвердження Ви можете надати додаткову інформацію або виключити себе із списку очікування.",
      "overlapping_phone_and_email_p3": "Будь ласка, перевірте свою електронну пошту або телефон, щоб дізнатися подробиці.",
      "overlapping_email_p3": "Будь ласка, перевірте свою електронну пошту, щоб дізнатися подробиці.",
      "overlapping_phone_p3": "Будь ласка, перевірте свій телефон, щоб дізнатися подробиці.",
      "overlapping_email_p1": "Ми надіслали ще одне підтвердження на вашу адресу електронної пошти."
    },
    "insist_guardian_consent": "Я надаю згоду від імені",
    "confirmation_section": {
      "title": "Перегляньте та підтвердіть",
      "p1": "Будь ласка, перевірте свої дані перед тим, як завершити реєстрацію.",
      "edit_information": "Повернутися та редагувати"
    },
    "iemodal": {
      "title": "Непідтримуваний веб-переглядач",
      "body": "Реєстрація наразі можлива у цих веб-переглядачах"
    },
    "show_other_locations": "Натисніть, щоб побачити інші місцезнаходження",
    "non_us_address": "Це міжнародна адреса",
    "test_group_user_survey": "Скринінг учасників",
    "self_consent": "Я надаю згоду щодо себе",
    "address_country": "Держава",
    "appointment": "Прийом",
    "employee_id": "Посвідчення працівника",
    "appointment_time": "Час прийому",
    "appointment_location": "Місцезнаходження",
    "phone_or_email": "Номер телефону або адреса електронної пошти",
    "no_self_consent": "Я відмовляюся від надання згоди щодо себе",
    "no_insist_guardian_consent": "Я відмовляюся від надання згоди від імені {{full_name}}",
    "additional_consents_helpertext": "Додаткові згоди можуть бути запитані пізніше",
    "minimum_age_with_months": "Мінімальний вік для участі становить {{year}} років та {{month}} місяців. ",
    "assistive_technology": "Допоміжні технології",
    "covid_vaccine_y_n": "Бажаєте отримати щеплення від COVID-19 разом з цим замовленням?",
    "received_covid_vaccine_y_n": "Чи робили Ви щеплення від COVID-19 раніше?",
    "covid_vaccinations": "Вакцинація від Covid-19",
    "select_vaccine_dose": "Будь ласка, оберіть дозу вакцини, яку ви хотіли б отримати",
    "demographic_info": "Деографічна інформації",
    "additional_info": "Додаткова інформація ",
    "self_described_race": "Раса за самоописом",
    "verify_contact_information": "Верифікація",
    "verify_contact": {
      "we_sent_you_a_code": "Будь ласка, введіть код, який ми надіслали на %{контакт}.",
      "confirmation_code": "Код підтвердження",
      "code_resent": {
        "email": "Інший код було надіслано на Вашу адресу електронної пошти",
        "phone_number": "Інший код було надіслано на Ваш номер телефону"
      },
      "did_not_receive_code": "Не отримали код підтвердження?",
      "verify_by": {
        "email": "Замість цього підтвердіть електронною поштою",
        "phone_number": "Замість цього підтвердіть номером телефону"
      }
    },
    "preferred_method_of_communication": {
      "question": "Бажаний спосіб комунікації",
      "helper_text": "Ми повідомимо вам, коли з'явиться інформація про прийом",
      "phone_number": "Телефон (тільки текстові повідомлення, можуть застосовуватися тарифи на передачу даних)",
      "landline": "Наразі ми не можемо підтримувати стаціонарні телефони. Будь ласка, вкажіть адресу електронної пошти для важливих повідомлень.",
      "verification_method": "Як Ви хочете отримати свій код аутентифікації?",
      "verification_helper_text": "Перед входом Вам буде надіслано код для підтвердження вашої особи."
    },
    "skip_for_now": "Поки що пропустити",
    "default_information": {
      "title": "Гаразд, ми будемо використовувати цю адресу",
      "title_v2": "Гаразд, ми використаємо цю контактну інформацію"
    },
    "middle_name_label": "По батькові",
    "confirm_appointment": "Підтвердити прийом",
    "dob": "Дата народження",
    "different_appointment": "Інший прийом",
    "select_appointment_time": "Обрати час прийому",
    "decline_address": "Я відмовляюся надавати адресу. Будь ласка, використовуйте адресу програми.",
    "patient_information": "Інформація про пацієнта ",
    "scan_license": "Відскануйте водійське посвідчення",
    "how_to_contact": "Як нам з вами зв'язатися?",
    "decline_email_or_phone": "Я відмовляюся надавати адресу електронної пошти або номер телефону. Я дозволяю адміністраторам програми отримувати мої результати.",
    "medical_screening": "Медичний скринінг",
    "show_service_at_this_location": "Показати послугу в цьому місці",
    "verbal_consent_provided": "Відповідно до Закону США про американців з обмеженими можливостями, учасник або його юридично уповноважена особа, яка приймає рішення, надає усну згоду. Письмова згода повинна бути отримана від учасника або його юридично уповноваженої особи, яка приймає рішення, протягом 24 годин.",
    "unexpired_written_consent_present": "Місце проведення тестування має у своєму розпорядженні письмову згоду, термін дії якої не закінчився.",
    "select_to_proceed": "Будь ласка, оберіть варіант вище, щоб продовжити.",
    "guardians_information": "Інформація про опікуна",
    "registrars_information": "Інформація про реєстратора",
    "optional": "за бажанням",
    "vfc_eligibility": "Право на участь у програмі \"Вакцина для дітей\" ((VFC)",
    "vfc_eligibility_subtext": "Ваша дитина може отримати безкоштовні вакцини через програму \"Вакцини для дітей\" (VFC) Центрів з контролю та профілактики захворювань в офісах медичних закладів, аптеках та медичних клініках, які беруть участь у програмі VFC."
  },
  "gender_key": {
    "male": "Чоловічий",
    "female": "Жіночий",
    "cisgender": "Цисгендер",
    "non_binary": "Гендерний квір або небінарна особа",
    "other": "Інший",
    "prefer_to_self_describe": "Ідентичність не вказана",
    "prefer_not_to_disclose": "Відмовляюся відповідати",
    "transgender_male": "Трансгендер чоловічої статі/транс-чоловік",
    "transgender_female": "Трансгендер жіночої статі/транс-жінка",
    "unknown": "Невідомо",
    "non_binary_only": "Небінарна особа",
    "intersex": "Інтерсекс",
    "transgender": "Трансгендер",
    "gender_fluid": "Гендерфлюїд",
    "not_applicable": "Не застосовується"
  },
  "ethnicity": {
    "american_indian_alaska_native": "Американські індіанці або корінні жителі Аляски",
    "american_indian_central_america": "Американські індіанці з Південної або Центральної Америки",
    "asian": "Азіати",
    "black": "Чорні або афроамериканці",
    "latinx": "Латиноамериканці або іспанці",
    "middle_eastern_north_african": "Уродженці близькосхідних або північноафриканськийх регіонів",
    "native_hawaiian_pacific_islander": "Уродженці Гавайських або Тихоокеанських островів",
    "white_european": "Білі або європеоїди кавказькі",
    "unknown": "Невідомо",
    "prefer_not_to_disclose": "Бажаю не розголошувати",
    "asian_indian": "Азіатсько-індійська раса",
    "filipino": "Філіппінці",
    "japanese": "Японці",
    "korean": "Корейці",
    "vietnamese": "В'єтнамці",
    "other_asian": "Інші азіати",
    "native_hawaiian": "Корінні гавайці",
    "guamanian_or_chamorro": "Гуаманці або чаморро",
    "samoan": "Самоанці",
    "other_pacific_islander": "Інші уродженці тихоокеанських островів",
    "chinese": "Китайці",
    "help": "Раса позначає набір фізичних характеристик, які використовуються для розрізнення груп людей. Раса може збігатися або не збігатися з національністю або країною, в якій ви маєте громадянство.",
    "subtitle": "Правила вимагають, щоб ми збирали всю наведену нижче інформацію.",
    "laotian": "Лаоська",
    "cambodian": "Камбоджійська",
    "other": "Інші",
    "bangladeshi": "Bangladeshi",
    "hmong": "Hmong",
    "indonesian": "Indonesian",
    "malaysian": "Malaysian",
    "pakistani": "Pakistani",
    "sri_lankan": "Sri Lankan",
    "thai": "Thai",
    "taiwanese": "Taiwanese",
    "fijian": "Fijian",
    "guamanian": "Guamanian",
    "tongan": "Tongan"
  },
  "languages": {
    "en": "Англійська",
    "fr": "Французька",
    "es": "Іспанська",
    "so": "Сомалі",
    "hmn": "Хмонг",
    "asl": "Американська жестова мова (АЖМ)",
    "kar": "Каренська",
    "am": "Амхарська",
    "ru": "Російська",
    "om": "Оромо"
  },
  "symptoms": {
    "fever": "Лихоманка або озноб",
    "cough": "Кашель ",
    "muscle_aches": "Болі у м'язах",
    "severe_fatigue": "Сильна втома (більше, ніж зазвичай)",
    "trouble_breathing": "Утруднене дихання",
    "diarrhea": "Діарея",
    "loss_of_smell": "Втрата нюху",
    "loss_of_taste": "Втрата смаку",
    "shortness_of_breath": "Задишка або утруднене дихання",
    "headache": "Головний біль",
    "sore_throat": "Біль у горлі",
    "congestion": "Закладеність носа або нежить",
    "nausea": "Нудота або блювота",
    "close_contact": "Близький контакт із інфікованою особою*",
    "helper_text": {
      "close_contact": "*Чи були Ви в близькому контакті (ближче 6 футів протягом щонайменше 15 хвилин) з людиною, у якої підтверджено COVID-19?"
    },
    "suspected_exposure": "Підозрюваний вплив",
    "none": "Немає симптомів "
  },
  "instructions": {
    "title": "Ваша реєстрація завершена. Будь ласка, уважно ознайомтеся з інформацією та подальшими кроками нижче.",
    "p1": "Ця сторінка містить інформацію про Ваш прийом, а також штрих-код Вашого прийому.",
    "appointment_scheduled": "Ваш прийом заплановано",
    "verify_info": {
      "title": "Підтвердьте свої дані",
      "p1": "Важливо підтвердити Вашу контактну інформацію, щоб ви могли швидко отримати доступ до свого пацієнтського порталу.",
      "p2": "Підтвердження було надіслано на вашу електронну пошту. Будь ласка, перейдіть за наданим посиланням.",
      "p3": "Якщо вам потрібно змінити контактну інформацію, будь ласка, натисніть кнопку \"Допомога\"."
    },
    "verified": "Підтверджено",
    "not_verified": "Не підтверджено",
    "resend_text": "Не отримали посилання? ",
    "resend": "Направити знову",
    "skip": "Пропустити перевірку і показати підтвердження",
    "name": "Ім'я та прізвище ",
    "appointment_details": "Прийом",
    "date_and_time": "Дата та час ",
    "add_to_calendar": "Додати до календарю ",
    "instructions": "Загальні інструкції",
    "successfully_flash": "Успішно створений запис на прийом!",
    "success_flash_email": "Успішно підтверджена адреса електронної пошти",
    "success_flash_phone": "Успішно підтверджений номер телефону",
    "mail_order": "Ви отримаєте свій тест-набір поштою протягом тижня.",
    "at_home": "Приблизно в цей час до Вас додому прийде медичний працівник",
    "at_home_instructions": "Інструкції для дому",
    "mail_order_instructions": "Інструкції для замовлення поштою",
    "request_additional_appointment": "Записатися на повторний прийом",
    "book_additional_appointment": "Записатись на інший прийом",
    "confirmation_code": "Код підтвердження",
    "completed": "Завершено",
    "appointment_barcode": "Штрих-код запису на прийом",
    "dependent_links": "Посилання для запису на прийом для домогосподарств",
    "on_demand_title": "Ваша реєстрація завершена. Будь ласка, уважно перегляньте інформацію та подальші кроки нижче.",
    "save_link": "Збережіть це посилання",
    "verify_phone": "Підтвердіть свій номер телефону",
    "verify_phone_description": "Важливо підтвердити свої контактні дані, щоб ви могли швидко отримати доступ до своїх записів. На Ваш номер було надіслано текстове повідомлення з підтвердженням.",
    "verify_email_description": "Важливо підтвердити свої контактні дані, щоб Ви могли швидко отримати доступ до своїх записів. На Вашу адресу електронної пошти було надіслано лист-підтвердження.",
    "information": "Реєстраційна інформація",
    "follow_up_appointment": "Повторний прийом",
    "get_directions": "Напрямки",
    "cancel": "Скасувати прийом",
    "reschedule_appointment": "Перепризначити прийом",
    "reschedule_linked_appointments": "Перепризначити прийоми",
    "no_slots": "Більше немає вільних записів",
    "check_results": "Перевірте пацієнтський портал",
    "follow_up_modal_header": "Будь ласка, заплануйте свою 2-гу дозу",
    "are_you_sure_you_want_to_cancel": "Ви впевнені, що хочете скасувати цей прийом? ",
    "please_choose_cancellation_reason": "Будь ласка, оберіть причину скасування нижче",
    "additional_details": "Будь ласка, додайте будь-яку додаткову інформацію нижче",
    "errors": {
      "missing_cancellation_reason": "Будь ласка, оберіть причину скасування"
    },
    "verify_email": "Підтвердіть свою адресу електронної пошти",
    "redcap_url": "Натисніть, щоб завершити своє опитування",
    "verify_contact_information": "Підтвердіть свою контактну інформацію",
    "please_also": "Будь ласка, також",
    "new_title": "Підтвердження для {{name}}",
    "contact_method": "Контактний метод",
    "next_steps": {
      "title": "Наступні кроки",
      "p1": "Вам потрібно активувати тестовий набір. Коли Ви будете готові пройти тест, перейдіть за посиланням, надісланим на Вашу електронну пошту, щоб повернутися на цю сторінку та активувати свій набір нижче.",
      "administered": "Ми повідомимо Вас, коли Ваш набір надійде в лабораторію і коли будуть готові результати. Зазвичай результати надходять до лабораторії через 3-5 днів. Ви зможете переглянути результати на своїй сторінці [Історія хвороби] ({{link}}), коли вони будуть готові.",
      "resulted": "Результати доступні на Вашій сторінці [Історія хвороби]({{link}}).",
      "shipping_information": "Інформація про доставку"
    },
    "save_this_page": {
      "title": "Збережіть цю сторінку",
      "p1": "Використовуйте цю сторінку, щоб повідомити про результати самотестування або переглянути їх в історії хвороби.",
      "p2": "Щоб отримати доступ до цієї сторінки в майбутньому, натисніть на посилання в електронному листі з підтвердженням або в текстовому повідомленні.",
      "p3": "Ви можете зберегти цю сторінку, зробивши закладку, додавши її на головний екран або скопіювавши посилання в безпечне місце."
    },
    "show_my_barcode": "Показати мій штрих-код",
    "my_account": "Мій обліковий запис",
    "register_another": "Зареєструвати іншого учасника",
    "update_vaccine": "Оновити інформацію про вакцину",
    "medical_history": "Історія хвороби",
    "upcoming_appointments": "Найближчі зустрічі",
    "reschedule": "Перенести запис",
    "resend_confirmation": "Повторно надіслати підтвердження",
    "appointment_details_v2": "Подробиці прийому",
    "confirm_cancellation": "Підтвердити скасування",
    "confirm_cancellation_question": "Ви впевнені, що хочете скасувати цей прийом? ",
    "select_new_appointment_time_below": "Виберіть новий час прийому нижче. Якщо Вам потрібно змінити послуги або місце прийому, скасуйте цей прийом і призначте новий.",
    "no_take_me_back": "Ні, поверніть мене назад",
    "yes_cancel_appointment": "Так, скасуйте прийом",
    "update_appointment": "Оновити прийом",
    "select_new_appointment_time": "Обрати новий час прийому",
    "clinic": "Клініка",
    "services": "Послуги",
    "appointment_missed": "Прийом пропущено",
    "appointment_canceled": "Прийом скасовано"
  },
  "result_page": {
    "errors": {
      "invalid_access_code": "Неправильний код доступу"
    },
    "landing": {
      "p1": "Щоб переглянути свої результати, будь ласка введіть: ",
      "access_code_label": "Код доступу",
      "submit_button": "Надіслати",
      "recent_results": "Ваші нещодавні результати"
    },
    "view_results": {
      "headline": "Результат тесту для {{name}}",
      "reregister": "Перереєструвати",
      "status": {
        "likely_positive": "Результати невизначені",
        "test_not_performed": "Потрібен повторний тест",
        "results_ready": "Результати готові",
        "processing": "Обробляється",
        "test_status": "Статус тесту",
        "test_result": "Результат тесту",
        "administered": "Адмініструється",
        "results_pending": "Результати очікуються",
        "test_results": "Результати тесту"
      },
      "at": "за адресою {{address}}"
    },
    "result_label": "Результат",
    "result": {
      "flu_a": {
        "result_label": "Результат на грип А"
      },
      "flu_b": {
        "result_label": "Результат на грип В"
      },
      "covid19": {
        "result_label": "Результат на COVID-19"
      },
      "covid": {
        "result_label": "Результат на COVID-19"
      },
      "sars": {
        "result_label": "Результат ТГРС"
      },
      "control": {
        "result_label": "Control"
      },
      "rsv": {
        "result_label": "Результат РСВ"
      },
      "result": {
        "result_label": "Результат"
      },
      "hba1c": {
        "normal": "Нормальний ",
        "warning": "Переддіабет",
        "danger": "Діабет"
      },
      "lead_venous": {
        "danger": "Ненормальний",
        "normal": "Нормальний "
      },
      "tc": {
        "result_label": "Результат загального рівня холестерину"
      },
      "hdl": {
        "result_label": "Результат рівня ЛПВЩ "
      },
      "trg": {
        "result_label": "Результат рівня ТГ "
      },
      "ldl": {
        "result_label": "Результат рівня ЛПНЩ"
      },
      "non_hdl": {
        "result_label": "Результат рівня не ЛПНЩ"
      },
      "tc_hdl_ratio": {
        "result_label": "Загальний холестерин: коефіцієнт ЛПВЩ"
      },
      "glu": {
        "result_label": "Результат глюкози "
      },
      "alere_cholestech_ldx": {
        "danger": "В зоні ризику ",
        "warning": "Ненормальний",
        "normal": "Нормальний "
      },
      "lead": {
        "result_label": "Свинець"
      },
      "zinc": {
        "result_label": "Цинк"
      },
      "lead_standard_profile": {
        "danger": "Ненормальний",
        "normal": "Нормальний "
      },
      "creatinine": {
        "danger": "Ненормальний",
        "normal": "Нормальний "
      },
      "igg": {
        "result_label": "Результат на імуноглобулін G"
      },
      "igm": {
        "result_label": "Результат на імуноглобулін M"
      },
      "blood_glucose": {
        "warning": "Низький",
        "normal": "Нормальний",
        "prediabetes": "Переддіабет",
        "danger": "Діабет"
      },
      "blood_glucose_fasted": {
        "normal": "Нормальний",
        "warning": "Підвищений",
        "danger": "Високий"
      },
      "total_cholesterol_fasted": {
        "normal": "Нормальний",
        "elevated": "Підвищений",
        "high": "Високий",
        "low": "Низький"
      },
      "total_cholesterol_unfasted": {
        "normal": "Нормальний",
        "elevated": "Підвищений",
        "high": "Високий"
      },
      "a1c_now": {
        "normal": "Нормальний",
        "warning": "Предіабет",
        "danger": "Діабет"
      },
      "triglycerides": {
        "result_label": "Triglycerides"
      }
    },
    "documents": "Документи",
    "self_administered": "Тест для самостійного проходження можна забрати за адресою {{location}}",
    "patient": "Пацієнт",
    "medical_history": "Історія хвороби",
    "overview_title": "Оберіть тест або послугу, щоб переглянути докладнішу інформацію та будь-які додаткові дії, які можуть знадобитися. Зверніть увагу, що результати деяких тестів можуть бути недоступними або стануть доступними лише після розмови з лікарем. ",
    "insurance_information": "Заповність страхову інформацію",
    "contact_support": "Якщо Вам потрібна допомога, або потрібно змінти Вашу контактну ініформацію, будь ласка, зверніться до служби підтримки.",
    "show": "Показати",
    "hide": "Приховати",
    "lab_report": "Лабораторний звіт",
    "contact_provider": {
      "header": "У Вас позитивний результат",
      "description": "Ви хочете поговорити з постачальником послуг, щоб обговорити Ваші результати та лікування?",
      "yes_option_text": "Так, я хочу поговорити з постачальником послуг",
      "no_option_text": "Ні, я зрозумів(ла) свої результати, і не хочу розмовляти з постачальником послуг",
      "confirm_phone_number_header": "Підтвердьте свій номер телефону ",
      "confirm_phone_number_description": "Будь ласка, підтвердіть найкращий номер телефону для консультації.",
      "confirm_button_text": "Підтвердити",
      "consultation_confirmed_header": "Консультацію підтверджено",
      "consultation_confirmed_description": "Провайдер зателефонує Вам на номер {{phone_number}} протягом 2-3 робочих днів.",
      "acknowledgement_option_helper_text": "Щось, що пояснює важливість лікування і дозволяє користувачеві знати, як запланувати дзвінок, якщо вони передумають.",
      "acknowledgement_confirmed_header": "Підтвердження отримано",
      "acknowledgement_confirmed_description": "Щось про важливість отримання лікування за допомогою пов'язаних ресурсів. Нагадування про те, що консультація абсолютно безкоштовна, і постачальник послуг може виписати рецепт або щось інше, що допоможе впоратися з інфекцією.",
      "acknowledgement_confirmed_change_mind_text": "Якщо Ви передумаєте, просто натисніть \"Я хочу отримати консультацію\" нижче.",
      "request_consultation_button_text": "Я хочу отримати консультацію"
    }
  },
  "required": {
    "errors": {
      "address_not_in_bounding_box": "Ваша адреса не відповідає вимогам до місць для участі в цій програмі."
    }
  },
  "member": {
    "medical_history": "Історія ",
    "view_instructions": "Переглянути підтвердження",
    "next_appointment": "Наступний прийом",
    "over_18": "Я підтверджую, що мені виповнилося 18 років",
    "choose_test_configurations_title": "Будь ласка, оберіть послугу(и), яку(і) Ви хотіли б отримати під час цієї зустрічі",
    "member_taken_error": "Користувач вже має обліковий запис",
    "choose_test_configurations_subtitle": "Послуга(и) буде надаватися всім членам домогосподарства",
    "service": "Послуга",
    "group_or_location": "Група/Місцезнаходження",
    "details": "Подробиці",
    "see_all": "Побачити все",
    "no_history": "Наразі історія відсутня.",
    "medical_history_title_with_name": "Історія {{name}} ",
    "no_dashboard": "Наразі нічого не доступно",
    "product": "Продукт",
    "price": "Ціна",
    "quantity": "Кількість",
    "total_services_selected": "Загальна кількість обраних послуг",
    "total_price": "Повна ціна"
  },
  "or": "або",
  "account": {
    "errors": {
      "must_be_13": "Для реєстрації облікового запису Вам повинно виповнитися 13 років"
    }
  },
  "self_administration": {
    "title": "Самостійне застосування",
    "self_administer_action": "Самостійно застосувати",
    "skip_to_link": "Перейти до самостійного застосування ",
    "select_person": "Виберіть особу для перевірки",
    "adult": "доросла особа",
    "child": "дитина",
    "checked_out": "ПЕРЕВІРЕНО",
    "go_back": "Повернутись назад",
    "switch_to_scanner": "Переключитися на сканер",
    "enter_barcode": "Ввести штрих-код",
    "scan_barcode": "Просканувати штрих-код",
    "cancel": "Скасувати ",
    "barcode_for": "Штрих-код для ",
    "enter_barcode_manually": "Ввести штрих-код у ручну ",
    "instructions": "Відскануйте або введіть штрих-код, розташований на пробірці нижче.",
    "regex_prefix": "Штрих-код повинен складатися з ",
    "all_completed": "Завершено: Усі члени домогосподарства завершили цей крок",
    "errors": {
      "no_appointment": "Проблема з визначенням місця прийому",
      "barcode_in_use": "Цей штрих-код вже використовувався раніше. Якщо Ви раніше не застосовували самостійно цей набір, зверніться до персоналу місця, де Вам видали цей набір, і попросіть новий набір. В іншому випадку, натисніть кнопку \"Довідка\" нижче.",
      "test_error_general": "Проблема зі створенням тесту",
      "different_barcodes": "Введений Вами штрих-код не збігається."
    },
    "confirm_barcode_input": "Введіть штрих-код ще раз для підтвердження",
    "click_to_self_test": "Натисніть тут, щоб пройти самотестування"
  },
  "location_details": {
    "next_appointment": {
      "waitlist": "Список очікування ",
      "unavailable": "Записи на прийом не доступні"
    },
    "labels": {
      "address": "Адреса",
      "next_appointment": "Найближчий доступний запис на прийом",
      "services": "Доступні послуги"
    }
  },
  "waiting_room": {
    "header": "Ви перебуваєте в залі очікування, будь ласка, зачекайте, поки ми з'єднаємо вас з системою планування.",
    "p1": "Будь ласка, запасіться терпінням. Ми подолаємо цю пандемію разом.",
    "signature": "З повагою",
    "p2": "Ми знаємо, що досвід COVID-19 може засмучувати. Будь ласка, зачекайте тут, поки ми працюємо якнайшвидше, щоб ви змогли зайти на сайт і записатися на прийом."
  },
  "users": {
    "send_appointment_confirmation_message": "Вітаємо, {{full_name}}. Це нагадування про Ваш прийом у {{name}}. Ваш прийом відбудеться {{date}} о {{time}} за адресою: {{address}}.",
    "send_registration_link": "Вітаємо, {{full_name}}. Перейдіть за цим посиланням для реєстрації на Ваше {{name}} {{registration_link}}"
  },
  "follow_up": {
    "first_dose": "Перша доза ",
    "previous_appointment": "Попередні прийоми ",
    "booked_appointment": "Ваш прийом",
    "second_dose": "Друга доза ",
    "choose_second_location": "Оберіть місцезнаходження другого прийому"
  },
  "cancellation_types": {
    "cant_get_to_location": "Я не маю можливості дістатися до цього місця",
    "timing_conflict": "У мене був часовий конфлікт",
    "became_ill_with_covid19": "Я захворів(ла) на COVID-19",
    "received_service_elsewhere": "Я отримав(ла) цю послугу в іншому місці",
    "other": "Інше ",
    "duplicate_appointment": "Це була дублююча зустріч"
  },
  "translation": {
    "consent": {
      "type_name_instead": "Замість цього введіть прізвище та ім'я",
      "type_name": "Замість цього введіть підпис"
    }
  },
  "email": {
    "confirmation": {
      "your_access_code_is": "Ваш код доступу",
      "please_confirm_your_email": "Будь ласка, підтвердіть свою адресу електронної пошти, натиснувши на кнопку нижче",
      "hello": "Вітаємо",
      "click_to_reschedule": "Натисніть, щоб показати інструкції/перенести прийом",
      "click_to_reschedule_on_demand": "Натисніть, щоб показати інструкції/"
    },
    "cancellation": {
      "your_appointment_has_been_canceled": "Ваш прийом о {{time}} за адресою: {{location}}, скасовано. Якщо це помилка, будь ласка, напишіть нам на support@primary.health"
    }
  },
  "signature_lines": {
    "name": "Прізвище та ім'я учасника",
    "date": "Дата",
    "signature": "Підпис учасника",
    "and_or": "ТА/АБО",
    "guardian_name": "Ім'я та прізвище одного з батьків/опікуна ",
    "guardian_signature": "Підпис одного з батьків/опікуна "
  },
  "employer_testing": {
    "hello_name": "Вітаємо, {{name}}",
    "results": {
      "negative": "Негативний ",
      "positive": "Позитивний",
      "did_not_result": "Недійсний",
      "unknown": "Невідомий",
      "invalid": "Недійсний",
      "pending": "Очікується"
    },
    "code_reader": {
      "scan_your_code": "Відскануйте код на своїй тестовій картці",
      "find_in_top_right_hand_corner": "Код сканування є унікальним для вашого тесту і міститься у верхньому правому куті тесту",
      "center_code": "Відцентруйте код тут.",
      "already_used_error": "Цей штрих-код тесту вже використовувався. Будь ласка, зверніться до свого постачальника тестового набору за підтримкою.  ",
      "click_to_scan": "Натисніть, щоб просканувати QR-код",
      "scan_new_test_card": "Натисніть тут, щоб відсканувати нову тестову картку.",
      "not_working": "Не працює?",
      "try_again": "Натисніть тут, щоб спробувати ще раз."
    },
    "continue": "Продовжити",
    "verify_date_of_birth": {
      "confirm_date_of_birth": "Будь ласка, підтвердіть дату народження учасника, щоб продовжити",
      "contact_administrator": "Якщо відображена інформація є неправильною, зверніться за допомогою до адміністратора",
      "error": "Ви ввели неправильну дату народження - спробуйте ще раз або зверніться до адміністратора сайту, щоб оновити її."
    },
    "result_entry": {
      "code_is_registered": "Ваш код зареєстровано. ",
      "take_the_test": "Зробіть тест",
      "follow_the_instructions": "Дотримуйтесь інструкцій, що додаються до тесту, і запустіть 15-хвилинний таймер, щойно Ви вставили носовий тампон у флакон на тестовій картці.",
      "cant_find_instructions": "Не можете знайти інструкції? ",
      "view_instructions": "Подивіться інструкції Binax Now ",
      "start_timer": "ЗАПУСТІТЬ 15-ХВИЛИННИЙ ТАЙМЕР (НЕОБОВ'ЯЗКОВО)",
      "submit_within_15": "Порада: надсилайте свої результати протягом 15 хвилин",
      "enter_test_results": "Введіть результати тесту",
      "choose_a_result": "Виберіть варіант, який описує результат вашої тестової картки",
      "submit": "Надіслати",
      "must_log_result_and_photo": "Щоб продовжити, ви повинні зареєструвати результат і сфотографувати свій тест.",
      "submit_within_15_v2": "Порада: надсилайте свої результати після того, як тест триватиме 15 хвилин",
      "click_to_take_photo": "Натисніть, щоб зробити фотографію",
      "results_may_be_invalid": "Результати тесту можуть бути недійсними"
    },
    "summary": {
      "title": "Підсумок результатів",
      "negative_subtitle": "У Вас немає COVID-19",
      "negative_message": "Ми надіслали повідомлення Вашому роботодавцю про те, що Ви можете повернутися до роботи на робочому місці.",
      "positive_subtitle": "Нам шкода, але у Вас COVID-19",
      "positive_message": "Ми надіслали повідомлення Вашому роботодавцю про те, що у Вас COVID-19. ",
      "what_should_you_do": "Що Ви повинні зробити: ",
      "employer_name_resources": "Ресурси з назвами роботодавців:",
      "follow_cdc_guidelines": "Дотримуйтесь рекомендацій Центрів з контролю та профілактики захворювань "
    },
    "clarifying_results": {
      "title": "Уточнення Ваших результатів",
      "which_did_your_result_most_look_like": "На що Ваш результат був схожий найбільше?",
      "no_lines": "Ліній не видно",
      "blue_control_line_only": "Лише синя контрольна лінія",
      "pink_sample_line_only": "Лише синя контрольна лінія",
      "blue_control_line_and_pink_sample_line": "Лише рожева лінія відбору проб",
      "still_not_sure": "Досі не впевнений(а)",
      "submit_results": "Подати Результати",
      "thank_you": "Дякуємо!",
      "clarification_received_message": "Роз'яснення щодо Вашого результату отримано.",
      "only_pink_line": "Лише рожева лінія ",
      "no_lines_v2": "Немає ліній"
    },
    "return_to_confirmation": "Повернутись до сторінки підтвердження"
  },
  "preferred_language": {
    "title": "Бажана мова",
    "subtitle": "Якій мові ви віддаєте перевагу?"
  },
  "appointment_type": {
    "initial_vaccine_appointment": "Первинна серія вакцин",
    "one_dose": "Запишіться на прийом для отримання визначеної дози. Зокрема, це моя",
    "title": "Вибір дози",
    "first_dose": "Лише перша доза",
    "second_dose": "Лише друга доза",
    "single_dose_title": "Єдина доза",
    "additional": "Додаткова доза для осіб з ослабленим імунітетом",
    "booster": "Бустерна доза ",
    "supplemental": "Додаткова доза",
    "third_dose": "Лише третя доза",
    "no_vaccine": "Нічого з переліченого вище"
  },
  "activemodel": {
    "errors": {
      "models": {
        "importers/results/row": {
          "attributes": {
            "administered_at": {
              "invalid_datetime": "повинно мати формат {{datetime_format}}"
            },
            "results_at": {
              "invalid_datetime": "повинно мати формат {{datetime_format}}"
            },
            "uid": {
              "unknown_test": "тест не знайдено"
            }
          }
        },
        "importers/participants/row": {
          "attributes": {
            "access_code": {
              "not_allow_update_test_group_user": "користувач існує, але йому не дозволено оновлювати його"
            },
            "date_of_birth": {
              "invalid_date": "повинно мати формат {{date_format}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} не існує, оберіть з таких допустимих значень: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} не існують, оберіть з таких допустимих значень: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "гендер не існує, оберіть з таких допустимих значень:  {{genders}}"
            },
            "location": {
              "unknown": "місцезнаходження не існує"
            },
            "phone_number": {
              "invalid": "є недійсним номером"
            },
            "population_name": {
              "unknown_population": "{{unknown_population}} не існує, оберіть з таких допустимих значень:  {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "не можна заповнювати, якщо гендер не вказано \"prefer_to_self_describe\""
            },
            "sex": {
              "unknown_sex": "стать не існує, оберіть з таких допустимих значень: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "сексуальна_орієнтація не існує, оберіть з таких допустимих значень: {{sexual_orientations}}"
            },
            "status": {
              "unknown_status": "статус недійсний, оберіть з таких допустимих значень: {{statuses}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} не існує, оберіть з таких допустимих значень: {{races}}",
                "other": "{{unknown_races}} не існують, оберіть з таких допустимих значень: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} недійсне значення, оберіть з таких допустимих значень: {{tag_actions}}"
            },
            "vaccination_status": {
              "unknown_vaccination_status": "статус щеплення недійсний, оберіть з таких допустимих значень: {{vaccination_statuses}}"
            }
          }
        },
        "importers/organization_user/row": {
          "attributes": {
            "role": {
              "insufficient_privileges": "недостатньо дозволів для встановлення ролі"
            },
            "user_id": {
              "unknown_user": "користувача не знайдено"
            }
          }
        },
        "importers/additional_information/row": {
          "attributes": {
            "additional_information": {
              "invalid_json": "недійсний JSON"
            },
            "user_id": {
              "unknown_user": "користувача не знайдено"
            }
          }
        },
        "importers/emr/row": {
          "attributes": {
            "appointment_location": {
              "unknown_location": "Місцезнаходження прийому не знайдено"
            },
            "date_of_birth": {
              "invalid_date": "повинно мати формат {{date_format}}"
            },
            "appointment_date": {
              "invalid_date": "повинно мати формат {{date_format}}"
            },
            "email": {
              "duplicated": "вже використовується"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} не існує, оберіть з таких допустимих значень: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} не існують, оберіть з таких допустимих значень: {{ethnicities}}"
              }
            },
            "gender": {
              "unknown_gender": "гендер не існує, оберіть з таких допустимих значень: {{genders}}"
            },
            "phone_number": {
              "invalid": "недійсний номер"
            },
            "population": {
              "unknown_population": "{{unknown_population}} не існує, оберіть з таких допустимих значень: {{population_names}}"
            },
            "self_described_gender": {
              "not_allowed": "не можна заповнити це поле, якщо гендер не \"бажаю_самостійно_описати\""
            },
            "sex": {
              "unknown_sex": "стать не існує, оберіть з таких допустимих значень: {{sexes}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "сексуальна_орієнтація не існує, оберіть з таких допустимих значень: {{sexual_orientations}}"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} не існує, оберіть з таких допустимих значень: {{races}}",
                "other": "{{unknown_races}} не існують, оберіть з таких допустимих значень: {{races}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} недійсне значення, оберіть з таких допустимих значень: {{tag_actions}}"
            }
          }
        },
        "importers/additional_consents/row": {
          "attributes": {
            "consent_form_id": {
              "unknown_consent_form": "форму_згоди не знайдено"
            },
            "access_code": {
              "unknown_user": "користувача не знайдено"
            }
          }
        },
        "importers/vaccine/row": {
          "attributes": {
            "date_of_birth": {
              "invalid_date": "повинно мати формат {{date_format}}"
            },
            "phone_number": {
              "invalid": "є недійсним номером"
            },
            "email": {
              "duplicated": "вже використовується"
            },
            "races": {
              "unknown_races": {
                "one": "{{unknown_races}} не існує, оберіть з таких допустимих значень: {{races}}",
                "other": "{{unknown_races}} не існують, оберіть з таких допустимих значень: {{races}}"
              }
            },
            "gender": {
              "unknown_gender": "гендер не існує, оберіть з таких допустимих значень:  {{genders}}"
            },
            "sexual_orientation": {
              "unknown_sexual_orientation": "сексуальна_орієнтація не існує, оберіть з таких допустимих значень: {{sexual_orientations}}"
            },
            "self_described_gender": {
              "not_allowed": "не можна заповнити це поле, якщо гендер не \"бажаю_самостійно_описати\""
            },
            "sex": {
              "unknown_sex": "стать не існує, оберіть з таких допустимих значень: {{sexes}}"
            },
            "ethnicity": {
              "unknown_ethnicities": {
                "one": "{{unknown_ethnicities}} не існує, оберіть з таких допустимих значень: {{ethnicities}}",
                "other": "{{unknown_ethnicities}} не існують, оберіть з таких допустимих значень: {{ethnicities}}"
              }
            },
            "tag_action": {
              "unknown": "{{unknown_tag_action}} недійсне значення, оберіть з таких допустимих значень:  {{tag_actions}}"
            },
            "population": {
              "unknown_population": "{{unknown_population}} не існує, оберіть з таких допустимих значень: {{population_names}}"
            },
            "appointment_location": {
              "unknown_location": "Місцезнаходження прийому не знайдено"
            },
            "appointment_date": {
              "invalid_date": "повинно мати формат {{date_format}}"
            },
            "dose_type": {
              "invalid_dose": "{{unknown_dose}} недійсна доза, оберіть з таких допустимих значень: {{acceptable_doses}}",
              "unavailable_dose": "{{dose_type}} не доступний в межах лоту_щеплень, оберіть з таких доступних значень: {{available_doses}}"
            },
            "vis_version_date": {
              "invalid_date": "повинно мати формат {{date_format}}"
            }
          }
        },
        "importers/base/row": {
          "attributes": {
            "appointment_time": {
              "invalid_time": "повинно мати формат {{time_format}}"
            }
          }
        }
      }
    }
  },
  "activerecord": {
    "errors": {
      "models": {
        "test_location": {
          "attributes": {
            "metadata": {
              "wrong_json": "недійсний JSON"
            },
            "name": {
              "taken": "та адреса вже використовуються в іншому наявному місцезнаходженні"
            }
          }
        }
      }
    },
    "attributes": {
      "organization_user": {
        "organization_user_leads": {
          "one": "Користувач ",
          "other": "Користувачі"
        }
      },
      "appointment_slot_group": {
        "organization_user_leads": {
          "one": "Місцезнаходження",
          "other": "Місцезнаходження"
        }
      }
    }
  },
  "hispanic_questions": {
    "hispanic": "Іспанці або латиноамериканці",
    "not_hispanic": "Не іспанці або латиноамериканці",
    "hispanic_expanded": {
      "other": "Інше латиноамериканське або іспанське походження",
      "mexican": "Мексиканці, мексиканські американці, чикано",
      "puerto_rican": "Пуерто-риканці",
      "cuban": "Кубинці"
    },
    "subtitle": "Правила вимагають, щоб ми збирали таку інформацію.",
    "help": "Етнічна приналежність позначає набір спільних рис, таких як мова, культурні звичаї, релігія та інші характеристики, що використовуються для диференціації груп людей. Вона може збігатися або не збігатися з расовою ідентичністю.",
    "unknown": "Невідомо",
    "title": "Етнічна приналежність "
  },
  "sex_at_birth": {
    "question": "Стать",
    "female": "Жіноча",
    "male": "Чоловіча",
    "help": "Стать позначає те, що записано у вашому свідоцтві про народження.",
    "non_binary": "Небінарна особа",
    "subtitle": "Правила вимагають, щоб ми збирали усю таку інформацію.",
    "decline": "Відмовляюся відповідати",
    "unknown": "Unknown"
  },
  "gender": {
    "help": "Ґендерна ідентичність позначає те, як Ви себе ідентифікуєте. Вона може збігатися або не збігатися зі статтю, визначеною Вам при народженні.",
    "subtitle": "Якщо Ви хочете додати інформацію про свою гендерну ідентичність, будь ласка, зробіть це."
  },
  "sexual_orientation": {
    "title": "Сексуальна орієнтація",
    "subtitle": "Якщо Ви хочете додати інформацію про свою сексуальну орієнтацію, будь ласка, зробіть це.",
    "gay": "Гей, лесбіянка або гомосексуал",
    "heterosexual": "Гетеросексуальна особа",
    "bisexual": "Бісексуальна особа ",
    "questioning": "Сумніваюся/не впевнений(а)/не знаю",
    "prefer_not_to_disclose": "Вибрали не розголошувати",
    "unknown": "Невідомо",
    "orientation_not_listed": "Орієнтація не вказана",
    "not_applicable": "Не застосовується",
    "pansexual": "Пансексуал",
    "queer": "Квір"
  },
  "pronouns": {
    "title": "Займенники, яким надається перевага",
    "he": "Він/йому",
    "she": "Вона/їй",
    "they": "Вони/їм",
    "choose_not_to_disclose": "Вибрали не розголошувати",
    "prefer_to_self_describe": "Надаю перевагу самоопису"
  },
  "no_insurance_information": {
    "driver_license_or_state_id": "Посвідчення водія або державний ідентифікаційний номер",
    "driver_license": "Посвідчення водія",
    "use_ssn_instead": "Замість нього використовуйте номер соціального страхування",
    "social_security_number": "Номер соціального страхування",
    "state": "Держава",
    "upload_front_of_driver_license": "Завантажте лицьову сторону Вашого водійського посвідчення або державного ідентифікаційного номера",
    "choose_file": "Оберіть файл",
    "no_file_chosen": "Жоден файл не обрано",
    "no_identification": "У мене немає посвідчення особи.",
    "insurance_card_has_a_back": "У моєї страхової картки є зворотний бік",
    "upload_front_of_insurance_card": "Завантажте лицьову сторону Вашої страхової картки.",
    "front_of_insurance_card_uploaded": "Лицьова сторона Вашої страхової картки завантажена.",
    "insurance_card": "Страхова картка",
    "insurance_card_back": "Зворотний бік страхової картки",
    "back_of_insurance_card_uploaded": "Завантажується зворотний бік Вашої страхової картки."
  },
  "quidel": {
    "certify_text": "Я підтверджую, що реєструю результати цього тесту лише один раз.",
    "entering_results": "Ввід Ваших результатів",
    "review_instructions_1": "Уважно прочитайте інструкцію",
    "review_instructions_2": "Ви можете переглянути інструкції, що додаються до Вашого тесту, прочитати покрокову інструкцію або переглянути навчальне відео нижче:",
    "read_instructions": "Ознайомтеся з інструкцією",
    "watch_video": "Перегляньте відео",
    "timer": "Запустіть таймер після перемішування тампону в пробірці. Таймер зупиниться через 1 хвилину (для того, щоб витягти тампон з пробірки). Зверніть увагу: Ви повинні перезапустити таймер, щоб почався відлік 10 хвилин для отримання результату тесту.",
    "start_timer": "Запустіть таймер (за бажанням)",
    "restart_timer": "Зупинити та перезапустити",
    "resume": "Відновити",
    "one_minute_countdown": "1 хвилина минула! Вийміть тампон з пробірки і поновіть відлік часу.",
    "take_photo": "Сфотографуйте свою тест-смужку",
    "photo_warning": "Для продовження потрібно надати фотографію.",
    "enter_results": "Введіть результати тесту",
    "choose_result": "Виберіть варіант, який найкраще відображає Вашу тест-смужку.",
    "positive": "Позитивний",
    "negative": "Негативний ",
    "unknown": "Невідомий ",
    "clarify": "Уточнення ваших результатів",
    "strip_question": "На яку тест-смужку найбільше схожа ваша?",
    "no_lines": "Без ліній",
    "pink_line": "Лише рожева лінія ",
    "not_sure": "Ще не впевнений(а)"
  },
  "ihealth": {
    "take_a_photo_of_your_test": "Фотографія результату ",
    "retake_photo": "Повторна фотографія",
    "capture_photo": "Зафіксувати фотографію",
    "confirm_information_correct": "Я підтверджую, що наведена вище інформація є правильною.",
    "submit": "Надіслати",
    "title": "Ввести результати для {{name}}",
    "subtitle": "Вам не потрібно завантажувати або використовувати додаток iHealth COVID-19.",
    "instruction_title": "Переглянути інструкції з iHealth",
    "instruction_guide": "Ознайомтеся з інструкцією",
    "video_guide": "Подивіться відео",
    "add_photo": "Додайте фотографію",
    "confirm_result": "Підтвердьте результат",
    "result_warning": "Оберіть результат, щоб надіслати",
    "confirm_warning": "Підтвердіть результат, щоб надіслати",
    "administered_at_label": "Коли ви проходили цей тест?",
    "instruction_subtitle": "Перегляньте інструкцію, що додається до вашого тесту, або перегляньте інструкцію тут."
  },
  "public_test_groups": {
    "title": "Активація набору для домашнього тестування на COVID-19",
    "appointment_recoveries_title": "Повертаєтесь? ",
    "appointment_recoveries_button_text": "Знайти моє посилання",
    "search_test_group_title": "Вперше? ",
    "search_test_group_button_text": "Зареєструватись зараз",
    "title_2": "Проста реєстрація або звітування",
    "step_one": "Реєстрація облікового запису",
    "step_two": "Зберіть зразок слини для відправки назад",
    "step_three": "Перегляньте результати онлайн",
    "title_3": "Зареєструйтеся, щоб активувати свій набір",
    "population_title": "реєстрація ",
    "population_button": "Активувати",
    "faq_subtitle": "Дізнатися більше",
    "faq_title": "Поширені запитання",
    "faq_1": "Як мені повернути свій тест-набір?",
    "faq_1_footer": "Переглянути інструкцію до воронки для збору слини",
    "faq_2": "Я вже зареєстрований(а), але я забув(ла) мій обліковий запис.",
    "faq_3": "Як мені активувати свій набір? ",
    "faq_2_subtitle": "Як користувач, який повернувся, ви можете підтвердити свій звіт, скориставшись кнопкою \"**Знайти моє посилання**\" вище.",
    "faq_3_subtitle": "Будь ласка, пройдіть процедуру реєстрації облікового запису на Primary.Health, використовуючи наведені нижче варіанти:",
    "footer_text_1": "Цей продукт не було схвалено Управлінням з контролю за якістю харчових продуктів і медикаментів (FDA), але було дозволено FDA для використання в екстрених випадках на підставі дозволу на використання в надзвичайних ситуаціях;",
    "footer_text_2": "Цей виріб дозволено використовувати лише для збору та зберігання зразків слини з метою виявлення нуклеїнової кислоти SARS-CoV-2, а не будь-яких інших вірусів або патогенних мікроорганізмів;",
    "footer_text_3": "Екстрене використання цього виробу дозволено лише на час дії заяви про наявність обставин, що виправдовують дозвіл на екстрене використання медичних виробів відповідно до розділу 564(b)(1) Федерального закону про харчові продукти і медикаменти, 21 U.S.C. § 360bbb-3(b)(1), якщо ця заява не буде припинена або дозвіл не буде відкликаний раніше.",
    "description": "Для Вашої зручності Ви можете активувати тест-набір за допомогою кнопок нижче:",
    "subtitle": "Вперше на Primary? ",
    "subtitle_1": "Про Ваші набори для тестування на COVID-19",
    "faq_1_1": "Помістіть закриту пробірку зі зразком у пакет для біологічних зразків",
    "faq_1_2": "Помістіть пакет із біозразком у коробку з етикеткою SalivaDirect, в якій він надійшов. Помістіть коробку з етикеткою SalivaDirect у пакет FedEx UN 3373 Pak.",
    "faq_1_3": "Зніміть клейку підкладку з пакета FedEx UN 3373 і щільно притисніть її, щоб щільно запечатати пакет.",
    "faq_1_4": "Будь ласка, здайте Ваш зразок в той самий день, коли ви його зібрали, і до кінця останнього періоду забору. Не приносьте зразки до скриньки на вихідних.",
    "faq_1_5": "Відвідайте веб-сайт FedEx за адресою: **[fedex.com/labreturns](https://www.fedex.com/labreturns)** щоб переглянути місця розташування скриньок та розклад вивезення.",
    "demo": {
      "title": "Додаткова інформація та інструкції до набору для тестування на COVID-19 в домашніх умовах",
      "online_instructions": "Інструкції онлайн",
      "download_instructions": "Завантажити інструкцію з використання",
      "begin": "Розпочати",
      "instructions": {
        "welcome_title": "Вітаємо на порталі тестування на COVID-19 в домашніх умовах.",
        "welcome_text_1": "Перед початком тестування вимийте руки або скористайтеся дезінфікуючим засобом для рук.",
        "welcome_text_2": "Переконайтесь, що Ваші руки сухі перед початком.",
        "continue": "Продовжити",
        "back": "Назад",
        "step_one_title": "Крок 1: Перевірте свій тест-набір",
        "step_one_text_1": "Знайдіть компоненти набору",
        "step_one_text_2": "Рекомендовано також використовувати перчатки (не надано) під час тестування.",
        "step_two_title": "Крок 2: Помістіть пробірку в тримач для пробірок",
        "step_two_text_1": "Зніміть ковпачок з однієї **ПРОБІРКИ** і помістіть її в **УТРИМУВАЧ ПРОБІРКИ.**.",
        "step_two_text_2": "**ПРИМІТКА:** Рекомендовано використовувати перчатки.",
        "step_five_title": "Крок 5: Вийміть тампон з пробірки",
        "step_five_text_1": "Після **ОДНІЄЇ ХВИЛИНИ**, вийміть тампон із **ПРОБІРКИ**, потираючи кінець тампона об внутрішню стінку пробірки, щоб видавити якомога більше рідини.",
        "step_five_text_2": "Викиньте тампон у смітник.",
        "step_six_title": "Крок 6: Відкрийте тест-смужку",
        "step_six_text_1": "Обережно відкрийте пакет з **ТЕСТ-СМУЖКОЮ** за розріз і тримайте **ТЕСТ-СМУЖКУ**, як показано на малюнку.",
        "step_seven_title": "Крок 7: Помістіть тест-смужку у пробірку",
        "step_seven_text_1": "Помістіть **ТЕСТ-СМУЖКУ** у **ПРОБІРКУ** стрілками вниз. Залиште смужку в **ПРОБІРЦІ** на **ПОВНІ ДЕСЯТЬ ХВИЛИН** - не чіпайте і не виймайте.",
        "step_eight_title": "Крок 8: Вийміть тест-смужку із пробірки",
        "step_eight_text_1": "Через **ДЕСЯТЬ ХВИЛИН** вийміть **ТЕСТ-СМУЖКУ** з **ПРОБІРКИ**. Потім покладіть **ТЕСТ-СМУЖКУ** на контур, як описано в кроках 10-12. Переконайтеся, що **ТЕСТ-СМУЖКА** перебуває на рівній поверхні при хорошому освітленні.",
        "step_eight_text_2": "**Примітка:** Тест призначений для зчитування протягом 10 хвилин. Якщо тест зчитується раніше цього часу або більш ніж через 5 хвилин після зазначеного часу зчитування, результати можуть бути неточними (хибнонегативними, хибнопозитивними або недійсними), і тест потрібно повторити.",
        "step_nine_title": "Крок 9: Перевірте свої результати ",
        "step_nine_text_1": "Можливі три види результатів. ",
        "step_nine_text_2": "Перевірте наявність позитивного результату",
        "step_nine_text_3": "Перевірте наявність негативного результату",
        "step_nine_text_4": "Перевірте наявність недійсного результату",
        "step_ten_title": "Крок 10: Вийміть тест-смужку із пробірки",
        "step_ten_text_1": "Помістіть **ТЕСТ-СМУЖКУ** на контур тест-смужки нижче і порівняйте з наведеними прикладами результатів тесту.",
        "step_three_title": "Крок 3: Зробіть мазок з ніздрів",
        "step_three_text_1": "Вийміть **ТАМПОН** з упаковки, намагаючись не торкатися кінчика **ТАМПОНУ**.",
        "step_three_text_2": "Обережно введіть **ТАМПОН** на ½ - ¾ дюйма в ніздрю, залежно від розміру носа людини. Щільно потріть **ТАМПОН** круговими рухами по внутрішній стінці кожної ніздрі щонайменше 4 рази.",
        "step_three_text_3": "Обов'язково протріть ОБИДВІ ніздрі ОДНИМ і тим самим ТАМПОНОМ.",
        "step_three_text_5": "**ПРИМІТКА:** Неправильне проведення тампону може призвести до хибнонегативних результатів.",
        "step_four_title": "Крок 4: Помістіть тампон у пробірку",
        "step_four_text_1": "Негайно помістіть **ТАМПОН** в рідину всередині **ПРОБІРКИ** і переконайтеся, що він торкається дна. Перемішайте 3-4 рази.",
        "step_four_text_2": "Залиште тампон у розчині на **ОДНУ ХВИЛИНУ**.",
        "step_four_text_3": "**ПРИМІТКА**: Якщо тампон знаходиться в розчині більше 10 хвилин, його не можна використовувати.",
        "step_three_text_4": "**ПРИМІТКА:** Якщо Ви берете мазки в інших людей, будь ласка, надягайте маску. Для дітей, можливо, не потрібно вводити тампон так глибоко в ніздрю. Для дуже маленьких дітей може знадобитися ще одна людина, яка буде тримати голову дитини під час взяття мазка."
      },
      "choose_language": "Оберіть свою мову "
    }
  },
  "self_resulting": {
    "report_result": "Звітувати про результат",
    "report_result_subtitle": "Якщо Ваша організація пропонує самотестування, і ви готові повідомити про результати, будь ласка, натисніть кнопку нижче.",
    "need_help": "Потрібна допомога? ",
    "assistance": "Якщо Вам потрібна допомога або потрібно змінити контактну інформацію, будь ласка, зверніться до служби підтримки.",
    "contact_support": "Звернутися до служби підтримки",
    "save_link": "Збережіть це посилання, щоб повідомити про результати пізніше.",
    "send_via_email": "Направити посилання через електронну пошту",
    "send_via_text": "Направити посилання через текстове повідомлення",
    "copy_link": "Копіювати посилання",
    "resend_email": "Повторно надіслати підтвердження електронної пошти",
    "phone_verified": "Телефон підтверджено",
    "add_vaccination_status": "Додати/оновити статус щеплення від COVID-19",
    "terms_of_service": "Умови надання послуг",
    "verify_contact_information": "Перевірте свої контактні дані, щоб ви могли отримувати результати та повідомлення. Якщо вам потрібно змінити контактну інформацію, зверніться до нашої служби підтримки.",
    "resend_text": "Повторно надіслати підтвердження текстовим повідомленням",
    "loading": "Завантажується ",
    "add_to_account": "Додати до облікового запису",
    "hi": "Привіт",
    "email_verified": "Адресу електронної пошти підтверджено",
    "go_to_my_account": "Перейти до мого облікового запису",
    "activate_kit": "Активувати тестовий набір",
    "report_custom_result": "Повідомити про результат: {{test}}",
    "activate_custom_test": "Активувати тест: {{test}}",
    "update_vaccination_status": "Оновити статус щеплення",
    "order_a_test_kit": "Замовити тест-набір"
  },
  "enter_information": {
    "contact_info": "Введіть Вашу контактну інформацію",
    "label": "Телефон або адреса електронної пошти",
    "invalid": "Це недійсна адреса електронної пошти або номер телефону.",
    "send": "Ми надійшлемо код на Ваш телефон або адресу електронної пошти ",
    "find_link": "Зареєстровані? Знайдіть своє посилання",
    "not_registered": "Не зареєстровані? ",
    "search_by_keyword": "Шукайте за іменем та прізвищем, ключовим словом, містом, індексом або кодом організації!",
    "legal": "Вводячи Ваші номер телефону та адресу електронної пошти, Ви погоджуєтесь з **[Умовами надання послуг] (https://primary.health/terms-of-service/)** та **[Політикою Конфіденційності](https://primary.health/privacy-policy/)** Primary Health.",
    "subtitle": "Ми надішлемо Вам текстове повідомлення або електронний лист, щоб допомогти Вам знайти свої записи або посилання, щоб повідомити про свої результати тесту",
    "search": "Пошук",
    "register_here": "Зареєструйтеся тут"
  },
  "contact_support": {
    "invalid": "Недійсна інформація",
    "invalid_explanation": "Нещодавно ви надіслали запит на отримання посилання для входу на Primary.Health. Однак,",
    "never_registered": "Ніколи раніше не реєструвалися? Будь ласка, зверніться до вашого постачальника послуг з тестування, щоб отримати посилання для реєстрації, або знайдіть ваше місце проведення нижче.",
    "get_help": "Ви можете отримати допомогу щодо відповідності ваших записів вашим контактним даним за допомогою",
    "get_help_calling": "або подзвонивши нам",
    "invalid_explanation_bold": "надана Вами інформація недійсна",
    "get_help_label": "Отримати допомогу",
    "mobile": {
      "invalid_explanation": "Не знайдено користувачів, яких можна було б додати до вашого облікового запису.",
      "get_help": "Якщо у Вас є додаткові питання, будь ласка, зверніться до ",
      "reason": "Не всі користувачі Primary.Health  мають право на нашу нову функцію «Облікові записи». Коли Ви отримаєте право на участь, Ви отримаєте запрошення від Primary.Health або від Вашого адміністратора групи."
    },
    "zen_name": "Контактне ім'я",
    "ticket_type": "Чого це стосується",
    "appointment_access_code": "Код підтвердження (якщо відомий)",
    "zen_desc": "Повідомлення ",
    "contact_us": "Зверніться до нас ",
    "need_assistance": "Якщо Вам потрібна допомога або потрібно змінити контактну інформацію, будь ласка, зверніться до служби підтримки."
  },
  "show_appointments": {
    "welcome_not_logged_in": "Пошук посилання на сайт",
    "welcome_logged_in": "Оберіть Вашу групу",
    "welcome_subtitle_1_logged_in": "Щоб завершити додавання учасника, виберіть користувача і групу. Користувачі призначаються до одного облікового запису..",
    "welcome_subtitle_1_not_logged_in": "Виберіть, кому потрібно знайти своє місце проведення або додати нового учасника. Учасника не знайдено?",
    "welcome_subtitle_2_logged_in": "Якщо Ви не бачите свого учасника у списку вище, Ви можете",
    "welcome_subtitle_3_logged_in": "спробувати інший метод контакту",
    "welcome_subtitle_4_logged_in": "або звернутися до нас за ",
    "added_to_account": "Додано до облікового запису",
    "error": "Можливо, у цього учасника вже є обліковий запис",
    "welcome_subtitle_2_not_logged_in": "Оновити пошук.",
    "welcome": "Знайти свій профіль",
    "welcome_subtitle_1": "Нижче показано результати для",
    "welcome_subtitle_2": "Не бачите ім'я нижче? ",
    "welcome_subtitle_3": "Оновіть свій пошук "
  },
  "enter_code": {
    "code": "Який код?",
    "enter_code": "Введіть код, надісланий на",
    "incorrect_code": "Ви ввели неправильний код.",
    "verify": "Підтвердити обліковий запис",
    "verified": "Підтверджено",
    "verify_account_information": "Підтвердіть свій обліковий запис"
  },
  "general_self_checkout": {
    "choose_test": "Який тест Ви здавали?",
    "photo_required": "Фотографія результату обов'язкова",
    "find_your_test": "Знайдіть свій тест"
  },
  "login": {
    "create_account": "Створити обліковий запис",
    "create_account_help": "Створення облікового запису  - це не те саме, що реєстрація на тестування чи щеплення. Щойно Ви зареєструєтесь",
    "create_account_help_schedule": "Заплануйте прийоми",
    "create_account_help_record": "Запишіть тестування в домашніх умовах",
    "create_account_help_match": "Керуйте записами про щеплення та тестування.",
    "continue": "Продовжити як гість",
    "already": "Вже маєте обліковий запис? Натисніть",
    "login": "щоб увійти.",
    "or_sign_in": "Або увійдіть за допомогою:",
    "no_account": "Не маєте облікового запису? Натисніть",
    "signup": "щоб зареєструватися.",
    "here": "тут ",
    "email_address": "Адреса електронної пошти: ",
    "password": "Пароль:",
    "complete_registration": "Завершити реєстрацію ",
    "last_covid_test": "Останній результат тестування на COVID-19",
    "no_record": "Запис відсутній",
    "viewing_information": "Переглід інформації {{first_name}}",
    "download": "Завантажити",
    "not_registered": "Схоже, ви не зареєстровані в жодній групі",
    "please_follow_link": "Будь ласка, перейдіть за посиланням-запрошенням з Вашого електронного листа, щоб запланувати та отримати доступ до Вашої інформації за допомогою Primary.Health.",
    "log_results": "Зареєструвати результати мого тесту",
    "book_appointment": "Записатися на прийом",
    "no_new_appointments": "Наразі не приймаються нові записи на прийом",
    "upload_vaccinations": "Завантажити щеплення",
    "vaccination": "щеплення",
    "new_appointment": "Новий прийом",
    "log_otc_results": "Зареєструйте результати для безрецептурного тесту",
    "no_household_members": "У Вас поки що немає членів домогосподарства.",
    "no_upcoming": "Немає запланованих прийомів",
    "update_vaccination": "Оновити щеплення",
    "upload_vaccination": "Завантажити щеплення",
    "title": "Вітаємо на Primary.Health",
    "sub_title": "До реєстрації, будь ласка, створіть обліковий запис",
    "p1": "Створення облікового запису дозволить Вам:",
    "p2": "Планувати майбутні прийоми",
    "p3": "Робити записи щодо тестування на COVID-19 у домашніх умовах",
    "p4": "Керувати записами про щеплення та тестування залежних осіб",
    "p5": "Простий процес входу з my.primary.health",
    "no_vaccination_record": "Ще не додано щеплення",
    "vaccination_title_card": "Статус щеплення на Covid-19 ",
    "account_and_settings": "Обліковий запис та налаштування",
    "delete_account": "Видалити обліковий запис",
    "language": "Мова",
    "language_current": "Поточна мова: {{language}}",
    "log_out": "Вийти",
    "delete_account_title": "Ви впевнені, що хочете видалити свій обліковий запис? ",
    "new_encounter": "нова зустріч {{first_name}}",
    "new_encounter_subtitle": "Для якої групи призначена ця нова зустріч?",
    "no_programs": "Схоже, Ви не зареєстровані в жодній програмі",
    "edit_members": "Редагувати учасників",
    "edit_members_subtitle": "Виберіть, до якої інформаційної панелі Ви хочете перейти, або додайте іншого учасника до свого облікового запису",
    "add_members": "Додати учасника",
    "delete_member": "Видалити обліковий запис користувача {{full_name}}?",
    "delete_member_subtitle": "Видалення користувача призведе до видалення всієї інформації, пов'язаної з обліковим записом.",
    "select_member": "Вибір учасника",
    "edit_appointment": "Редагувати прийом",
    "route": {
      "me": "Інформаційна панель",
      "history": "Історія",
      "programs": "Додати тест",
      "contact_us": "Допомога",
      "choose_member": "Учасник",
      "settings": "Налаштування",
      "help": "Допомога"
    },
    "user_dashboard": "Інформаційна панель {{first_name}}",
    "view_full_results": "Переглянути на порталі результатів",
    "required": "Обов'язково, якщо Ви використовуєте застосунок Primary.",
    "no_email": "У мене немає адреси електронної пошти"
  },
  "vaccination_status": {
    "not_vaccinated": "Не щеплений(а)",
    "fully_vaccinated": "Повністю щеплений(а)",
    "partially_vaccinated": "Частково щеплений(а)",
    "vaccination_records": "Записи про щеплення",
    "title": "Який Ваш статус вакцинації проти COVID-19?",
    "definition_title": "Визначення статусу вакцинації",
    "definition_cdc": "Визначення статусу вакцинації Центрів контролю та профілактики захворювань",
    "definition_osha": "Визначення статусу вакцинації Управління охорони праці та здоров'я",
    "definition": {
      "fully_vaccinated": "Через 2 тижні після фінальної дози в серії з 2 доз вакцин Pfizer-BioNTech або Moderna, або 1 дози вакцини Janssen компанії Johnson & Johnson.",
      "partially_vaccinated": "Отримано лише 1 з 2 доз у первинній серії, або менше ніж через 2 тижні після отримання фінальної дози в серії з 2 доз або 1 дози Janssen компанії Johnson & Johnson.",
      "not_vaccinated": "Вакцина проти COVID-19 не вводилася.",
      "fully_vaccinated_with_one_booster": "Отримано бустерну дозу вакцин Pfizer-BioNTech або Moderna після повної серії або 1 дози вакцини Janssen компанії Johnson & Johnson.",
      "fully_vaccinated_with_two_boosters": "Отримано другу бустерну дозу вакцин Pfizer-BioNTech або Moderna після повної серії або 1 дози вакцини Janssen компанії Johnson & Johnson та бустерної дози."
    },
    "label": {
      "fully_vaccinated": "Повністю щеплений(а)",
      "partially_vaccinated": "Частково щеплений(а)",
      "not_vaccinated": "Не щеплений(а)",
      "fully_vaccinated_with_one_booster": "Повністю щеплений(а) + бустерна доза",
      "fully_vaccinated_with_two_boosters": "Повністю щеплений(а) + 2 бустерні дози",
      "prefer_not_to_answer": "Не хочу відповідати"
    },
    "progress_bar_title": "Історія вакцинації проти COVID-19",
    "upload_record_card": "Завантажте картку обліку щеплень від COVID-19",
    "show_example": "Показати приклад",
    "hide_example": "Сховати приклад",
    "take_photo": "Натисніть, щоб зробити фотографію",
    "or": "АБО",
    "upload_image_or_pdf_instead": "Замість цього завантажити зображення/PDF",
    "select_doses_received": "Виберіть усі отримані вами дози вакцинації проти COVID-19.",
    "first_dose": "Перша доза",
    "second_dose": "Друга доза",
    "first_booster_dose": "Перша бустерна доза",
    "second_booster_dose": "Друга бустерна доза",
    "additional_dose": "Додаткова доза ",
    "first_dose_manufacturer": "Хто був виробником вакцини від COVID-19 Вашої першої дози?",
    "first_dose_date": "Коли Ви отримали свою першу дозу вакцини? ",
    "second_dose_manufacturer": "Хто був виробником вакцини від COVID-19 Вашої другої дози?",
    "second_dose_date": "Коли Ви отримали свою другу дозу вакцини? ",
    "first_booster_dose_manufacturer": "Хто був виробником вакцини від COVID-19 Вашої першої бустерної дози?",
    "first_booster_dose_date": "Коли Ви отримали свою першу бустерну дозу вакцини? ",
    "second_booster_dose_manufacturer": "Хто був виробником вакцини від COVID-19 Вашої другої бустерної дози?",
    "second_booster_dose_date": "Коли Ви отримали свою другу бустерну дозу вакцини? ",
    "additional_dose_manufacturer": "Хто був виробником вакцини від COVID-19 Вашої додаткової дози?",
    "additional_dose_date": "Коли Ви отримали свою додаткову дозу вакцини? ",
    "completed_one": "Завершено",
    "completed_two": "Дякуємо за оновлення Вашого статусу вакцинації",
    "progress_bar_complete": "Завершено",
    "upload_image": "Завантажте фотографію",
    "retake_photo": "Зробіть повторну фотографію",
    "other": "Інше ",
    "remove_first_dose": "Видалити історію першої дози вакцини",
    "remove_second_dose": "Видалити історію другої дози вакцини",
    "remove_first_booster_dose": "Видалити історію першої бустерної дози вакцини",
    "remove_second_booster_dose": "Видалити історію другої бустерної дози вакцини",
    "remove_additional_dose": "Видалити історію додаткової дози вакцини",
    "exemption": "У вас є звільнення від вакцинації?",
    "exempt": "У мене є звільнення від вакцинації",
    "not_exempt": "У мене немає звільнення від вакцинації",
    "enter_exemption": "Введіть звільнення від вакцинації",
    "upload_exemption_documentation": "Завантажте документацію для Вашого звільнення від вакцинації",
    "remove_dose": "Видалити дозу вакцини",
    "continue": "Продовжити ",
    "enter_credentials": "Будь ласка, введіть свої облікові дані",
    "incorrect_credentials": "Неправильні облікові дані, будь ласка, спробуйте знову.",
    "add_photo": "Додайте фотографію"
  },
  "mailers": {
    "appointment_mailer": {
      "payment_pending": "Вітаємо, {{name}}, Ми помітили, що ви не завершили Ваш запис {{group_name}} на прийом до {{org_name}}. Щоб зберегти свій запис на прийом, Вам потрібно завершити етап оплати. Будь ласка, натисніть на це посилання, щоб завершити процес {{complete_url}}. Якщо Ви хочете скасувати свій прийом, будь ласка, перейдіть за цим посиланням {{cancel_url}}. Дякуємо"
    }
  },
  "otc": {
    "record_results_for_abbott": "Пройдіть тестування та запишіть свої результати для тесту BinaxNOW",
    "record_results": "Пройдіть тестування та запишіть свої результати",
    "activate_a_kit": "Активуйте набір",
    "activate_a_kit_for_test": "Активуйте набір для {{test}}",
    "todays_test_process": "Сьогоднішній процес тестування: ",
    "date_of_birth_question": "Яка дата народження {{first_name}}?",
    "confirm_dob_to_continue": "Щоб продовжити, будь ласка, підтвердіть дату народження учасника",
    "incorrect_dob": "Неправильна дата народження",
    "file_too_large": "Будь ласка, переконайтеся, що розмір завантаженого файлу не перевищує {{size}}.",
    "enter_valid_date_in_last_x_days": "Введіть дійсну дату за останні {{n}} днів",
    "barcode_format_not_valid": "Формат штрих-коду недійний",
    "complete_button": "Завершено",
    "next_button": "Далі",
    "back_button": "Назад",
    "report_a_test": "Повідомте про тест:",
    "x_%_completed": "{{n}}% завершено",
    "answer_survey": "Дайте відповідь на запитання опитувальника",
    "steps": {
      "answer_survey": "Дайте відповідь на опитування",
      "review_instructions": "Перегляньте інструкції",
      "scan_code": "Проскануйте код",
      "enter_time": "Введіть час",
      "enter_result": "Введіть результат",
      "take_photo": "Зробіть фотографію",
      "confirm_result": "Підтвердіть результат",
      "selest_test_kit": "Оберіть вид тесту",
      "enter_date": "Введіть дату",
      "enter_date_and_time": "Введіть дату та час"
    },
    "review_instructions": "Перегляньте інструкції до тесту",
    "follow_instructions_or_below": "Дотримуйтесь інструкцій, що додаються до Вашого тесту, або перегляньте інструкції нижче.",
    "watch_video": "Перегляньте відео",
    "view_instructions": "Перегляньте інструкції",
    "follow_instructions": "Дотримуйтесь інструкцій, що додаються до Вашого тесту",
    "scan_code": "Відскануйте QR-код тесту",
    "qr_code_description": "QR-код є унікальним для Вашого тесту і розміщений у верхньому правому куті вашого тесту",
    "show_example": "Показати приклад",
    "hide_example": "Сховати приклад",
    "barcode_scanned": "Штрих-код успішно проскановано",
    "enter_barcode": "Введіть штрих-код",
    "scan_qr_code": "Проскануйте QR-код",
    "which_test_did_you_take": "Який тест Ви робили?",
    "when_did_you_take_test": "Коли Ви робили цей тест?",
    "what_day_did_you_take_test": "У який день Ви робили цей тест? ",
    "what_time_did_you_take_test": "О котрій Ви робили цей тест? ",
    "time": {
      "today": "Сьогодні ",
      "yesterday": "Вчора",
      "two_days_ago": "2 дні тому"
    },
    "enter_current_time": "Введіть поточний час",
    "enter_result": "Введіть результат тестування",
    "choose_result_option": "Виберіть варіант, який описує результат Вашої тестової картки:",
    "clarify_results": "Уточніть Ваш результат",
    "take_result_photo": "Зробіть фотографію результату",
    "take_result_photo_description": "Зафіксуйте заповнену картку результатів, щоб записати свої результати.",
    "my_result": "Мої результати",
    "switch_camera": "Переключіть камеру",
    "confirm_result": "Підтвердіть результат",
    "name": "Прізвище та ім'я:",
    "date": "Дата: ",
    "result": "Результат:",
    "results": "Результати",
    "test_submitted": "Дякуємо, {{first_name}}! Ваш {{test}} успішно надіслано.",
    "dob": "Дата народження:",
    "administered": "Зроблено:",
    "or": "АБО",
    "upload": "Завантажте",
    "change_uploaded_file": "Змініть завантажений файл",
    "take_photo": "Зробіть фотографію",
    "confirm_information": "Підтвердіть інформацію",
    "barcode": "Штрих-код:",
    "scan_test_barcode": "Проскануйте штрих-код тесту ",
    "barcode_description": "Штрих-код є унікальним для Вашого тесту і міститься на Вашому тесті",
    "enter_date_in_last_30_days": "Введіть дату за останні 30 днів і приблизний час проходження тесту",
    "add_image": "Додайте зображення ",
    "change_image": "Змініть зображення",
    "skip_barcode_reader": "Не вдається відсканувати код? Натисніть \"Далі\", щоб повідомити про результат тесту в будь-якому випадку",
    "enter_date_and_time": "Введіть дату та час тесту",
    "enter_date": "Введіть дату тесту",
    "did_you_take_test_today": "Ви зробили тест сьогодні? ",
    "record_results_for_generic": "Зробіть тест та запишіть свої результати для тесту {{name}}",
    "choose_result_option_custom": "Оберіть варіант, що описує результат Вашого тесту {{test_name}}:"
  },
  "yes": "Так",
  "no": "Ні",
  "event_token": {
    "title": "Будь ласка, введіть код, наведений нижче, у торговельний автомат, щоб отримати свій тест-набір",
    "loading_text": "Завантажується, будь ласка, перевірте через хвилину"
  },
  "appointment_recovery": {
    "complete_title": "Все готово!",
    "subtitle_1": "Якщо Ви використовуєте iPhone, натисніть **Готово** у верхньому лівому кутку цього екрана, щоб повернутися до інформаційної панелі.",
    "subtitle_2": "Якщо Ви використовуєте Android, натисніть **X** у верхньому лівому кутку цього екрана.",
    "subtitle_0": "Будь ласка, закрийте веб-переглядач, щоб продовжити."
  },
  "primary": {
    "support_email": "support@primary.health"
  },
  "consented_relationship": "Відносини за згодою",
  "finish_registration": {
    "title": "Завершіть свою реєстрацію",
    "subtitle": "Будь ласка, заповніть наведену нижче інформацію перед прийомом:",
    "button": "Завершити реєстрацію"
  },
  "arab_ethnicity": {
    "arab": "Арабська",
    "non_arab": "Не арабська",
    "prefer_not_to_answer": "Не хочу відповідати",
    "question": "Арабська етнічна приналежність",
    "subtitle": "Правила вимагають, щоб ми збирали наведену нижче інформацію.",
    "help": "Етнічна приналежність позначає набір спільних рис, таких як мова, культурні звичаї, релігія та інші характеристики, що використовуються для диференціації груп людей. Вона може збігатися або не збігатися з расовою ідентичністю."
  },
  "custom_comms_quick_add": {
    "mobile_app_links": {
      "email_text": "Залежно від Вашого пристрою, натисніть на посилання нижче, щоб завантажити Primary Health Pass."
    }
  },
  "stepper": {
    "next": "Далі",
    "back": "Назад",
    "complete": "Завершено"
  },
  "registration_direcory": {
    "keyword_search_label": "Ключове слово, місто, поштовий індекс, назва організації",
    "keyword_search_label_mobile": "Місто, поштовий індекс, назва організації",
    "zero_results": {
      "title": "Ми не знайшли жодного надавача послуг за цим пошуковим запитом.",
      "sub_title": "Спробуйте зробити пошук надавача послуг за: ",
      "p1": "Поштовий індекс ",
      "p2": "Назва великого або малого міста",
      "p3": "Назва округу",
      "p4": "Назва надавача послуг або організації"
    }
  },
  "registration_flyer": {
    "open_camera": "Відкрийте програму камери на своєму пристрої",
    "point_to_qr_code": "Наведіть на QR-код і натисніть на посилання",
    "complete_registration": "Завершити реєстрацію",
    "need_help": "Потрібна допомога? Зверніться до нас "
  },
  "vaccine_info_sheets": {
    "vaccine_info_attestation": "Засвідчення інформації про вакцину",
    "select_checkbox": "Будь ласка, ознайомтеся з наведеною нижче інформацією та встановіть відповідний прапорець перед тим, як продовжити.",
    "opportunity_to_read": "Я прочитав(ла) або мені надали можливість прочитати, або мені прочитали інформаційне повідомлення про вакцину (\"VIS\") або інформаційну довідку про дозвіл на екстрене застосування (\"EUA\"), що надається для вакцини (вакцин), яка (які) має (мають) бути введена (введені):",
    "vaccine_info_sheet": "інформаційне повідомлення про вакцину",
    "vaccine_fact_sheet": "інформаційна довідка про вакцину",
    "vaccine_info_statement": "заява про інформацію про вакцину"
  },
  "exemption": {
    "dtap_tdap": "Дифтерія, правець і кашлюк (DTaP) /АКДП",
    "mmr": "Кір, паротит та краснуха (MMR)",
    "ipv_opv": "Поліомієліт",
    "hib": "ХІБ-інфекція ",
    "hep_b": "Гепатит В",
    "var": "Вітряна віспа",
    "hep_a": "Гепатит А ",
    "pcv": "Пневмококова кон'югована вакцина",
    "mcv": "Менінгококова вакцина"
  },
  "test_strip": {
    "A": "цей набір включає тести на хламідіоз та гонорею.",
    "B": "цей набір включає тести на хламідіоз та гонорею.",
    "C": "цей набір включає тести на сифиліс.",
    "D": "цей набір включає тести на ВІЛ, сифіліс, хламідіоз та гонорею.",
    "E": "цей набір включає тести на ВІЛ та сифіліс.",
    "box_type": "Коробка {{type}}",
    "administered_text": "Ми повідомимо Вас, коли Ваш набір надійде в лабораторію і коли будуть готові Ваші результати.",
    "activate_kit": "Активувати мій тестовий набір",
    "register_another_test": "Зареєструвати інший тест ",
    "credentials": {
      "title": "Давайте почнемо",
      "label": "Звернутися",
      "p1": "Ми почнемо з деякої інформації про Вас.",
      "location_step": {
        "p1": "Хто надав Вам тестовий набір? ",
        "load": "Завантажити більше"
      }
    },
    "checklist": {
      "not_urinated": "Ви не мочилися протягом останньої години.",
      "bathroom": "У Вас є доступ до туалету для збору сечі.",
      "anal_swab": "Ви маєте доступ до приватного місця для взяття анального мазка.",
      "blood_extraction": "Ви маєте доступ до чистого середовища для забору крові.",
      "washed_hands": "Ви вимили руки теплою водою з милом протягом 30 секунд.",
      "title": "Ви готові розпочати відбір зразків?",
      "no_alcohol": "Не вживайте алкоголь у день збору.",
      "menstruation": "Не беріть вагінальний мазок під час менструації або протягом 24 годин після статевого акту."
    },
    "confirm": {
      "title": "Підтвердіть свою інформацію",
      "p1": "Ця інформація правильна? "
    },
    "display_name": {
      "A": "Хламідіоз та гонорея (єдина ділянка)",
      "B": "Хламідіоз та гонорея (3 ділянки)",
      "C": "Сифіліс",
      "shortened": {
        "A": "Хламідіоз та гонорея",
        "B": "Хламідіоз та гонорея",
        "C": "Сифіліс"
      },
      "CC": "Скринінг колоректального раку (FIT)",
      "CE": "Скринінг на целіакію",
      "CR": "Аналіз на креатинін",
      "D": "4 Панельний тест - ВІЛ, сифіліс, хламідіоз та гонорея (в одній ділянці)",
      "DD": "Скринінг на діабет (HbA1c)",
      "E": "ВІЛ та сифіліс",
      "H": "ВПЛ",
      "P": "Гепатит С",
      "HS": "Простий герпес",
      "I": "ВІЛ",
      "TT": "Рівень тестостерону ",
      "VD": "Вітамін Д",
      "VS": "Вірусний контроль",
      "AP": "Хламідіоз та гонорея"
    },
    "rescan": "Перескануйте",
    "scanned": "Ви просканували Коробку {{type}}",
    "not_yet": "Ще ні ",
    "success_registration": "Реєстрація успішна! ",
    "no_box": "Не маєте коробки?",
    "faq_link": "Переглянути поширені запитання",
    "welcome": "Ласкаво просимо!",
    "scan": {
      "title": "Ви стали на крок ближче до свого здоров'я.",
      "p1": "Відскануйте або введіть **штрих-код набору** збоку вашої коробки."
    },
    "appointments": {
      "title": "Оберіть пацієнта та надавача послуг",
      "p1": "Ми знайшли більше одного профілю, пов'язаного з вашою контактною інформацією. Виберіть правильний профіль:",
      "new": "Новий пацієнт або надавач послуг"
    },
    "verify": {
      "title": "Підтвердіть особу",
      "p1": "Ми знайшли Вашу медичну карту."
    },
    "section_1": "Підтвердіть вміст набору",
    "section_2": "Збір тесту ",
    "section_3": "Пакування",
    "section_4": "Відправлення",
    "box_a": "Коробка А",
    "box_b": "Коробка Б",
    "box_c": "Коробка В",
    "kit_flow": {
      "button_1": "Перейдіть до пакування",
      "button_2": "Я готовий(а)",
      "button_3": "Мій комплект укомплектований і упакований",
      "button_4": "Я завершив(ла)",
      "button_5": "Подальший тест",
      "button_6": "Пропустити інструкції"
    },
    "contents": {
      "header": "Чудово! Давайте перевіримо, чи укомплектований ваш набір",
      "text_1": "Чи є у Вашій коробці з тестовим набором таке?",
      "blood_collection_card": "Картка для забору крові",
      "blood_collection_card_subtitle": "(1 або 2 залежно від тестового набору)",
      "alchohol_pads": "2 спиртові диски",
      "lancets": "Ланцети одноразового використання",
      "up_to_other": "(До 4 залежно від тестового набору)",
      "adhesive_bandages": "Адгезивні бинти",
      "pipette": "Піпетка",
      "urine_tube": "Пробірка для збору сечі",
      "urine_cup": "Стаканчик для збору сечі",
      "oral_swab": "Тампон для порожнини рота",
      "oral_tube": "Пробірка для збору слини з ротової порожнини",
      "anal_swab": "Анальний тампон",
      "anal_tube": "Анальна пробірка для збору сечі",
      "vaginal_tube": "1 пробірка для вагінального збору",
      "vaginal_swab": "1 вагінальний тампон",
      "biodegradable_paper": "Біорозкладний папір для збору проб",
      "bottle_and_probe": "Пляшка для відбору проб та зонд",
      "prepaid_envelope": "Конверт для пересилки з передоплатою",
      "biohazard_subtitle": "(з абсорбуючою прокладкою)",
      "biohazard_bag": "1 пакет для зразків",
      "biohazard_bag_plural": "({{count}}) пакети для біологічно небезпечних матеріалів",
      "alcohol_pads_plural": "{{count}} спиртові тампони",
      "sterile_gauze_pad": "1 стерильний марлевий тампон"
    },
    "packaging": {
      "title": "Контрольний перелік для пакування",
      "subtitle": "Перш ніж відправляти набір, переконайтеся, що:"
    },
    "packaging_box_a": {
      "step_1": "Ви запакували увесь вміст тестового набору",
      "step_2": "Ви використали всі матеріали, що містяться в наборі, для збору потрібних зразків",
      "step_3": "Ваша**дата-народження** написана на всіх пробірках для збору зразків у форматі МІсяць/День/Рік",
      "step_4": "**Дата збору** написана на всіх пробірках для збору зразків у форматі МІсяць/День/Рік",
      "step_5": "Пробирка для збору зразків запечатана у пакет для біологічно небезпечних матеріалів",
      "step_6": "Усі зразки поміщені до коробки",
      "step_7": "Коробка вкладена в попередньо оплачений транспортний конверт",
      "step_8": "Конверт для зворотної доставки повністю запечатаний"
    },
    "packaging_box_b": {
      "step_1": "Ви упакували весь вміст набору, навіть якщо пропустили тест(и)",
      "step_2": "Ви використали всі матеріали, що містяться в наборі, для збору потрібних зразків",
      "step_3": "Ваша**дата-народження** написана на всіх пробірках для збору зразків у форматі МІсяць/День/Рік",
      "step_4": "**Дата збору** написана на всіх пробірках для збору зразків у форматі МІсяць/День/Рік",
      "step_5": "Кожна пробірка для збору зразків запечатана у власний пакет для біологічно небезпечних матеріалів (тільки **один** зразок у одному пакеті)",
      "step_6": "Усі зразки поміщені до коробки",
      "step_7": "Коробка вкладена в попередньо оплачений транспортний конверт",
      "step_8": "Конверт для зворотної доставки повністю запечатаний"
    },
    "packaging_box_c": {
      "step_1": "Ви упакували весь вміст набору, включно із використаними ланцетами.",
      "step_2": "Ваша**дата-народження** написана на всіх картках для забору крові у форматі МІсяць/День/Рік",
      "step_3": "**Дата забору** написана на всіх картках для забору крові у форматі МІсяць/День/Рік",
      "step_4": "Картка забору крові та всі використані ланцети поміщені в пакет для біологічно небезпечних матеріалів",
      "step_5": "Пакет для біологічно небезпечних матеріалів поміщено у коробку",
      "step_6": "Коробка вкладена в попередньо оплачений транспортний конверт",
      "step_7": "Конверт для зворотної доставки повністю запечатаний"
    },
    "success": {
      "header_1": "Чудово! ",
      "text_1": "Ви готові взяти свій перший зразок.",
      "header_2": "Ви зробили це! ",
      "text_2": "**Далі:** упакуйте свій набір, щоб відправити назад.",
      "header_3": "Чудова робота!",
      "text_3": "Ви стали на крок ближче до кращого здоров'я.",
      "header_4": "Гарна робота!",
      "text_4": "Ще один крок і Ви впоралися! ",
      "text_5": "Ви готові взяти свій перший зразок."
    },
    "instructions": {
      "title": "Оберіть тест, щоб переглянути інструкцію:",
      "subtitle": "Тест",
      "button": "Інструкції",
      "pdf": "Інструкції у форматі PDF "
    },
    "instructions_box_a": {
      "title": "Збір сечі",
      "subtitle": "Для цього тесту Вам знадобляться такі матеріали: ",
      "step_1": "Не мочіться принаймні за 1 годину до збору сечі. Найкраще використовувати першу ранкову сечу.",
      "step_2": "Мочіться безпосередньо в сечоприймач PeeCanter або збірний стаканчик, заповнюючи його від 1/3 до 1/2.",
      "step_3": "Перелийте сечу з сечоприймача PeeCanter або за допомогою піпетки перенесіть сечу з чашки для збору сечі в пробірку(и) для збору сечі, позначену помаранчевою етикеткою <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
      "step_4": "Наповніть пробірку(и) до тих пір, поки об'єднана рідина не опиниться між позначеними лініями заповнення.",
      "step_4_sublabel": "Не заповнюйте пробірку за межами максимальних ліній заповнення або зразок буде відхилено.",
      "step_5": "Встановіть кришку на пробірку і переконайтеся, що вона закрита рівномірно і щільно.",
      "step_6": "Напишіть свою дату народження та дату збору зразка (сьогоднішню дату) у форматі Місяць/День/Рік на пробірці у спеціально відведеному для цього місці.",
      "step_7": "Помістіть зразок у порожній пакет для біологічно небезпечних матеріалів з абсорбуючою прокладкою і переконайтеся, що пломба повністю закрита."
    },
    "instructions_box_b": {
      "test_1": {
        "title": "Збір сечі",
        "subtitle": "Для цього тесту Вам знадобляться такі матеріали: ",
        "step_1": "Не мочіться принаймні за 1 годину до збору сечі. Найкраще використовувати першу ранкову сечу.",
        "step_2": "Мочіться безпосередньо в сечоприймач PeeCanter або збірний стаканчик, заповнюючи його від 1/3 до 1/2.",
        "step_3": "Перелийте сечу з сечоприймача PeeCanter або за допомогою піпетки перенесіть сечу з чашки для збору сечі в пробірку(и) для збору сечі, позначену помаранчевою етикеткою <span style=\"color: #FF5000\">**\"URINE\"**</span>.",
        "step_4": "Наповніть пробірку(и) до тих пір, поки об'єднана рідина не опиниться між позначеними лініями заповнення.",
        "step_4_subtitle": "Не заповнюйте пробірку за межами максимальних ліній заповнення або зразок буде відхилено.",
        "step_5": "Встановіть кришку на пробірку і переконайтеся, що вона закрита рівномірно і щільно.",
        "step_6": "Напишіть свою дату народження та дату збору зразка (сьогоднішню дату) у форматі Місяць/День/Рік на пробірці у спеціально відведеному для цього місці.",
        "step_7": "Помістіть зразок у порожній пакет для біологічно небезпечних матеріалів з абсорбуючою прокладкою і переконайтеся, що пломба повністю закрита."
      },
      "test_2": {
        "title": "Мазок з ротової порожнини",
        "subtitle": "Для цього тесту Вам знадобляться такі матеріали: ",
        "step_1": "Тримайте тампон між світлою лінією (якщо вона є) і ватною частиною тампона.",
        "step_2": "Вставте тампон у рот і потріть кінцем тампона по задній стінці горла протягом 10 секунд.",
        "step_3": "Обережно витягніть тампон і помістіть його в пробірку для збору зразків, позначену синьою <span style=\"color: #001F70\">**\"ORAL\"**</span> етикеткою. Зламайте тампон на лінії відліку, зігнувши його до пробірки.",
        "step_4": "Встановіть кришку на пробірку і переконайтеся, що вона закрита рівномірно і щільно.",
        "step_5": "Напишіть свою дату народження та дату збору зразка (сьогоднішню дату) у форматі Місяць/День/Рік на пробірці у спеціально відведеному для цього місці.",
        "step_6": "Помістіть зразок у порожній пакет для біологічно небезпечних матеріалів з абсорбуючою прокладкою і переконайтеся, що пломба повністю закрита."
      },
      "test_3": {
        "title": "Анальний мазок",
        "subtitle": "Для цього тесту Вам знадобляться такі матеріали: ",
        "step_1": "Тримайте тампон між світлою лінією (якщо вона є) і ватною частиною тампона.",
        "step_2": "Введіть тампон на 3-5 см (1-2\") в анальний канал. Обережно поверніть тампон протягом 5-10 секунд, щоб зібрати всі можливі мікроорганізми. ",
        "step_3": "Обережно витягніть тампон і помістіть його в пробірку для збору зразків, позначену зеленою  <span style=\"color: #00C08C\">**“ANAL”**</span> етикеткою. Зламайте тампон на лінії відліку, зігнувши його до пробірки.",
        "step_4": "Встановіть кришку на пробірку і переконайтеся, що вона закрита рівномірно і щільно.",
        "step_5": "Напишіть свою дату народження та дату збору зразка (сьогоднішню дату) у форматі Місяць/День/Рік на пробірці у спеціально відведеному для цього місці.",
        "step_6": "Помістіть зразок у порожній пакет для біологічно небезпечних матеріалів з абсорбуючою прокладкою і переконайтеся, що пломба повністю закрита."
      }
    },
    "instructions_box_c": {
      "title": "Забір крові",
      "subtitle": "Для цього тесту Вам знадобляться такі матеріали: ",
      "step_1": "Напишіть Ваше ім'я та прізвище, дату народження, дату забору у спеціальне поле. Використовуйте формат Місяць/День/Рік. ",
      "step_2": "Відкрийте кришку картки для крові, щоб відкрити кола на папері для забору крові. Не торкайтеся паперу для забору крові.",
      "step_3": "Помийте руки теплою водою протягом щонайменше 30 секунд, після цього енергійно потисніть руки протягом 15 секунд, щоб стимулювати приплив крові до пальців.",
      "step_4": "Протріть кінчик пальця спиртовим диском. Найкраще використовувати середній або безіменний палець недомінантної руки.",
      "step_5": "Візьміть ланцет і відкрутіть ковпачок. Щільно притисніть маленький кінчик до кінчика пальця, доки голка не вийде з нього з клацанням. Тримаючи руку нижче серця під час збору крові, помасажуйте палець від основи до кінчика, щоб стимулювати приплив крові.",
      "step_5_subtitle": "Ланцети одноразового використання. Усі ланцети потрібно повернути із Вашим зразком до лабораторії для утилізації.",
      "step_6": "Починаючи з центру, нанесіть 3-6 крапель, щоб заповнити коло і просочити папір для забору крові. Не торкайтеся паперу пальцем, оскільки це обмежить потік крові. Коли коло буде заповнене, переходьте до наступного кола. Це нормально, коли кров виходить за межі ліній, але не допускайте, щоб плями крові перетікали одна в одну.",
      "step_6_subtitle": "Не додавайте додаткову кров до кола, коли Ви завершели або коли він висох. \"Нашарування\" крові зробить забір недійсним.",
      "step_7": "Не закриваючи картку для крові, покладіть її на рівну поверхню і дайте паперу для забору крові висохнути на повітрі при кімнатній температурі щонайменше 30 хвилин. Не нагрівайте, не сушіть феном і не піддавайте папір для забору крові впливу прямих сонячних променів. Нагрівання може пошкодити зразок.",
      "step_7_subtitle": "Перевірте задню сторону паперу для забору крові. Кров повинна просочити весь папір і заповнити кожне коло забору крові.",
      "step_8": "Коли папір для забору крові висохне, закрийте картку для крові, підігнувши клапан. Помістіть картку для крові та ланцети в пакет для біологічно небезпечних матеріалів разом з пакетом з вологопоглиначем. Переконайтеся, що пакет для біологічно небезпечних матеріалів належним чином закритий."
    },
    "test_tips": {
      "title": "Поради для належного забору крові",
      "subtitle": "Для найкращого результату: ",
      "step_1": "Переконайтеся, що ви не зневоднені під час збору. Гідратація сприяє притоку крові.",
      "step_2": "Не робіть забір одразу після куріння. ",
      "step_3": "Миття і зігрівання рук під теплою водою допоможе прискорити кровообіг у руках.",
      "step_4": "Енергійно потрясіть руками в напрямку до підлоги, щоб стимулювати приплив крові до пальців.",
      "step_5": "Тримайте прилад для забору крові та свої руки нижче серця під час забор для кращого кровообігу. ",
      "step_6": "Можливо, вам знадобиться більше одного проколу. Повторюйте ці поради між кожним проколом."
    },
    "shipping": {
      "header": "Ваш набір готовий до відправки!",
      "text": "**Вітаємо!** Ви завершили свій скринінг сексуального здоров'я."
    },
    "pick_up": "Отримайте рекомендований тест-набір у персоналу місця розташування та зареєструйтеся нижче",
    "short_display_name": {
      "A": "Хламідія та гонорея",
      "B": "Хламідія та гонорея",
      "C": "Сифіліс",
      "CC": "Рак товстої кишки",
      "CE": "Целіакія",
      "CR": "Креатинін",
      "D": "ВІЛ, сифіліс, хламідія та гонорея ",
      "DD": "Діабет",
      "E": "ВІЛ ТА сифіліс",
      "H": "ВПЛ",
      "P": "Гепатит С",
      "HS": "Простий герпес",
      "I": "ВІЛ",
      "TT": "Тестостерон",
      "VD": "Вітамін Д",
      "VS": "Вірусний контроль"
    },
    "test_kit": "Тестовий набір",
    "view_results": "Побачити результати",
    "recommended_kit_title": "Рекомендований тестовий набір",
    "instructions_box_h": {
      "title": "Колекція вагінальних тампонів",
      "subtitle": "Пробірки для збору можуть містити рідкий консервант. <span class=\"text-danger\">**Не виливайте рідкий консервант із пробірки для збору, якщо він наявний.**</span>",
      "step_1": "Тримаючи тампон між світлою лінією (якщо вона є) і ватною частиною тампона в одній руці, відокремте статеві губи (складки шкіри навколо вагінального отвору).",
      "step_2": "Введіть тампон на 5 см (2 дюйми) у вагінальний отвір. Обережно повертайте тампон протягом 30 секунд, потираючи його об стінки вагіни.",
      "step_3": "Обережно витягніть тампон і помістіть його в пробірку для збору зразків, позначену <span class=\"text-danger\">**ЧЕРВОНОЮ ЕТИКЕТКОЮ «ВАГІНАЛЬНО»**</div>Розламайте тампон, зігнувши його до пробірки для збору.",
      "step_4": "Встановіть кришку на пробірку і переконайтеся, що вона закрита рівномірно і щільно.",
      "step_5": "Напишіть свою дату народження у форматі Місяць/День/Рік на пробірці у передбаченому для цьому місці (DOB).",
      "step_6": "Напишіть дату збору зразка (сьогоднішню дату) у форматі Місяць/День/Рік на пробірці у спеціально відведеному місці (Coll. Date).",
      "step_7": "Помістіть зразок у порожній пакет для біологічно небезпечних матеріалів з абсорбуючою прокладкою і переконайтеся, що пломба повністю закрита."
    },
    "packaging_box_h": {
      "step_3": "Вашу **дату-народження** написано на пробірці для збору зразків, використовуючи формат Місяць/День/Рік",
      "step_4": "Вашу **дату забору** написано на пробірці для збору зразків, використовуючи формат Місяць/День/Рік",
      "step_6": "Пакет для біологічно небезпечних матеріалів поміщено у коробку"
    },
    "instructions_box_cc": {
      "title": "Збір калу",
      "step_1": "Напишіть на пробірці у спеціально відведеному місці дату свого народження та дату збору зразка (сьогоднішня дата) у форматі Місяць/День/Рік. Відкрийте зелений ковпачок, покрутивши та піднявши його.",
      "step_2": "Покладіть папір, що входить до комплекту, в унітаз поверх води.",
      "step_3": "Покладіть зразок калу на папір для збору.",
      "step_4": "Зберіть зразок калу до того, як папір потоне і зразок калу торкнеться води.",
      "step_5": "Зішкребіть поверхню зразка калу за допомогою зонда.",
      "step_6": "Повністю покрийте рифлену частину зонду зразком калу.",
      "step_7": "Закрийте пляшечку для зразків, вставивши зонд і щільно закрутивши зелений ковпачок. Не відкривайте повторно.",
      "step_8": "Змийте. Папір для збору біологічно розкладається і не шкодить септичним системам.",
      "step_9": "Загорніть пляшку для відбору проб в абсорбуючу прокладку і помістіть її в пакет для біологічної небезпеки."
    },
    "contact_support": {
      "title": "Давайте виправимо це",
      "text_1": "Нам шкода чути, що щось пішло не так!",
      "text_2": "Будь ласка, зверніться до нас, щоб повідомити, що сталося, і ми допоможемо замінити ваш набір."
    },
    "contact_support_success": {
      "title": "Ми отримали Ваше повідомлення",
      "text_1": "Дякуємо, що звернулися до нас. ",
      "text_2": "Незабаром представник нашої команди підтримки зв'яжеться з вами.",
      "button_text": "Повернутися додому"
    },
    "kit_in_transit_to_patient": "Ваше замовлення вже в дорозі! Більшість замовлень доставляються протягом 2-5 робочих днів. Щойно ви отримаєте свій набір, ми надішлемо вам додаткову інформацію про те, як його активувати та відправити назад.\nПосилання на відстеження: https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum={{tracking_number}}",
    "kit_received_at_lab": "Лабораторія отримала ваш набір! Ви отримаєте сповіщення, щоб перевірити результати на своєму порталі пацієнта, коли вони будуть готові",
    "registration_confirmation": "Ваша реєстрація завершена! Відвідайте сторінку підтвердження реєстрації, щоб активувати свій тест-набір:",
    "kit_ordered_online": "Ми отримали Ваше замовлення на набір і надішлемо оновлення, як тільки він буде відправлений! \nЗамовлення №: {{order_number}} \nДата замовлення: {{order_date}}",
    "kit_ordered_online_email": {
      "header": "Ми отримали Ваше замовлення!",
      "p1": "Ми отримали Ваше замовлення! Щойно Ваше замовлення буде відправлено, ми надішлемо Вам ще одне оновлення.",
      "order_number": "Замовлення №",
      "order_date": "Дата замовлення "
    },
    "completed": "Завершено",
    "I": "цей набір включає тест на ВІЛ.",
    "CC": "цей набір включає тест на колоректальний рак.",
    "CE": "цей набір включає тест на целіакію. ",
    "CR": "цей набір включає тест на креатинін.",
    "DD": "цей набір включає тест на діабет.",
    "H": "\nцей набір включає тест на вірус папіломи людини.",
    "P": "цей набір включає тест на гепатит С.",
    "HH": "цей набір включає тест на гемоглобін.",
    "HS": "цей набір включає тест на простий герпес 2 типу",
    "TT": "цей набір включає тест на тестостерон.",
    "VD": "цей набір включає тест на вітамін Д."
  },
  "copy_link": "Скопіювати посилання на сторінку",
  "copied_link": "Посилання скопійовано! ",
  "vaccines": {
    "descriptions": {
      "vaccine_flu": "Захист від поширених вірусів грипу ",
      "vaccine_covid": "Захист від коронавірусів",
      "tdap": "Вакцина АКДП може запобігти правцю",
      "polio": "Захист від вірусу поліомієліту. Потрібен дітям перед початком навчання в школі.",
      "varicella": "Вакцина, що захищає від вітряної віспи",
      "shingles": "Вакцина, що захищає від оперізувального лишаю. Мінімальний вік - 18 років.",
      "human_papillomavirus": "Вакцина, що захищає від ВПЛ. Рекомендовано для дітей віком 11 років",
      "meningococcal": "Вакцина захищає від чотирьох типів менінгококових бактерій."
    }
  },
  "viral_surveillance": {
    "shipping": {
      "title": "Підготуйте зразок до відправки:",
      "step_1": {
        "label": "Помістіть пакет для біологічно небезпечних матеріалів у коробку.",
        "substep_1": "Зніміть конверт для зворотного відправлення з набору для тестування.",
        "substep_2": "Помістіть пакет для біологічно небезпечних матеріалів в оригінальну коробку набору для збору і закрийте коробку."
      },
      "step_2": {
        "label": "Покладіть коробку в поштову скриньку для зворотної доставки.",
        "substep_1": "Помістіть коробку в поштову скриньку для зворотної доставки та запечатайте її.",
        "substep_2": "Не складайте і не закривайте діамантовий символ UN3373."
      },
      "step_3": {
        "label": "Поверніться до відділення Fedex.",
        "substep_1": "Поверніть набір у день отримання (до останнього вивезення) до відділення Fedex.",
        "substep_2": "Зателефонуйте, щоб замовити самовивіз від FedEx із 1.800.GoFedEx."
      }
    },
    "overview": {
      "title": "Ласкаво просимо до програми секвенування вірусів!",
      "subtitle": "Ваша участь допоможе отримати цінну інформацію про те, які варіанти респіраторних патогенів циркулюють у вашому регіоні. Будь ласка, дотримуйтесь інструкцій, що додаються до вашого набору, щоб розпочати."
    },
    "instructions": {
      "title": "Зберіть свій зразок:",
      "step_1": "Помийте руки.",
      "step_2": "Зніміть кришку пробірки для збору та відкрийте упаковку з тампонами.",
      "step_2a": "Вийміть тампон з упаковки. Не торкайтеся м'якого кінця тампона руками або іншими предметами.",
      "step_3": "Візьміть мазок з носа.",
      "step_3a": "Введіть весь м'який кінець тампона в ніздрю не більше ніж на 3/4 дюйма (1/5 см) у ніс.",
      "step_3b": "Повільно обертайте тампон",
      "step_3c": "Обережно видаліть тампон.",
      "step_3d": "Використовуючи той самий тампон, повторіть процедуру в іншій ніздрі тим самим кінцем тампона.",
      "step_4": "Помістіть тампон у пробірку для збору зразків і надійно закрийте пробірку кришкою.",
      "step_5": "Помістіть пробірку для збору в пакет для біологічно небезпечних матеріалів і запечатайте.",
      "step_6": "Рівномірно натисніть на блискавку, щоб запечатати сумку."
    },
    "click_to_get_started_button": "Натисніть, щоб розпочати ",
    "get_started_button": "Розпочати",
    "thank_you": {
      "title": "Дякуємо, що використовуєте Primary.Health!",
      "p_3": "Тепер Ви можете закрити веб-переглядач.",
      "p_2": "Будь ласка, не забудьте доставити набір для вірусного спостереження до найближчого відділення FedEx.",
      "p_1": "Ваша участь активно допомагає посадовцям у сфері громадського здоров'я боротися з поширенням хвороб. Якщо Ви хочете побачити, який вплив Ви маєте на громадське здоров'я, будь ласка, знайдіть хвилинку, щоб дізнатися більше за [цим посиланням] ({{link}}). З вами не зв'яжуться щодо ваших результатів, оскільки вони є анонімними."
    },
    "verify_dob_title": "Спершу, давайте підтвердимо Ваші дані",
    "verify_dob_subtitle": "Це допомагає нам упевнитися, що набір для тестування використовує саме та людина, якій він призначений.",
    "activate_kit": "Активуйте набір:",
    "steps_complete": "Кроки завершено",
    "opt_in": {
      "section_1_title": "Як це працює",
      "section_1_text_1": "Зареєструйтесь нижче та отримайте тест-набір для вірусного секвенування протягом 1-3 робочих днів.",
      "section_1_text_2": "Після отримання тест-набору",
      "section_1_text_3": "Надішліть зразок назад згідно з інструкціями в наборі, використовуючи нашу передплачену етикетку.",
      "sign_up": "Зареєструйте мене!",
      "no_to_participate": "Ні",
      "section_2_title": "Який вплив матиме ваша участь?",
      "section_2": {
        "card_1_title": "Швидке реагування на нові варіанти",
        "card_1_text": "Генетичне секвенування допомагає відстежувати поширення і появу нових варіантів респіраторних патогенів, що дозволяє системам охорони здоров'я швидко нарощувати зусилля з підвищення рівня готовності.",
        "card_2_title": "Відстежуйте, як змінюються респіраторні патогени",
        "card_2_text": "Секвенування респіраторних патогенів аналізує «генетичний код» вірусу. Це допомагає вченим простежити, як змінюється вірус, що дозволяє йому легше поширюватися і робити людей більш хворими.",
        "card_3_title": "У вас є повна конфіденційність",
        "card_3_text": "Секвенування буде оброблятися анонімно. Лабораторія ніколи не зв'яжеться з вами щодо ваших результатів"
      },
      "title": "Ви маєте право на участь у Каліфорнійському проекті безкоштовного секвенування вірусів респіраторних патогенів!",
      "subtitle": "Ви можете допомогти посадовцям у сфері громадського здоров'я захистити громади від респіраторних патогенів! Якщо Ви вирішите взяти участь у проекті, Ви отримаєте безкоштовний набір для секвенування вірусів, який визначить, яким штамом респіраторного патогену Ви інфіковані. Результати допоможуть посадовцям відстежувати поширення варіантів респіраторних патогенів. Дивіться нижче для отримання додаткової інформації та реєстрації.",
      "continue_to_result": "Продовжити до моїх результатів",
      "create_page_title": "Дякуємо!",
      "create_page_text_1": "Ваш набір повинен надійти у найближчі 1-3 робочі дні.",
      "create_page_text_2": "Ваша участь активно допомагає посадовцям системи громадського здоров'я боротися з поширенням хвороб. Ви отримаєте повідомлення, коли ваше замовлення на набір буде схвалено та коли його буде відправлено."
    }
  },
  "family_registrations": {
    "find_clinic": "Знайти клініку",
    "select_pin": "Виберіть пін, щоб побачити деталі",
    "miles_shorten": "{{miles}} миля",
    "next_available": "Наступний доступний",
    "appointment_type": "Вид прийому",
    "individual_appointment": "Індивідуальний прийом",
    "family_appointment": "Сімейний прийом",
    "vaccines": "Вакцини",
    "what_kind_appointment": "Який вид прийому ви хочете забронювати?",
    "select_family_or_individual": "Виберіть сімейний прийом якщо Ви плануєте прийом для кількох членів сім'ї одночасно.",
    "vaccines_selected": "Вакцини обрано",
    "where_care": "Де Ви хочете отримувати допомогу?",
    "select_vaccines_for_individual": "Оберіть вакцини для Вашого прийому (необов'язково).",
    "select_vaccines_for_family": "Оберіть вакцини для Вашого сімейного прийому (необов'язково).",
    "schedule_services": "Заплануйте послуги",
    "add_family": "Add family members and select services below.",
    "family_member": "Член сім'ї {{number}}",
    "morning": "Ранок",
    "afternoon": "День",
    "slot_available": "{{number}} доступно",
    "within_x_miles": "В межах %{{miles} миль від",
    "any_distance": "Будь-яка відстань від",
    "partial_results": "Часткові результати",
    "partial_matches": "Наведені нижче клініки пропонують деякі, але не всі вакцини, які Ви шукаєте",
    "no_matches": "За Вашим запитом не знайдено жодного збігу. Спробуйте змінити фільтри для покращення результатів.",
    "no_clinics_found": "Немає клінік, що відповідають вашому запиту.",
    "broaden_filters": "Спробуйте розширити фільтр для покращення результатів.",
    "unavailable_vaccines": "Недоступні вакцини:",
    "available_vaccines": "Доступні вакцини:",
    "select_date": "Оберіть дату",
    "available_appointments": "ДОСТУПНІ ЗАПИСИ НА ПРИЙОМ",
    "appointment_scheduling_info": "На наступному кроці ви зможете призначити час прийому для окремих членів сім'ї. За бажанням Ви можете призначити прийом на різні дні.",
    "hold_selected_appointments": "Ми проведемо обрані Вами прийоми за 15 хвилин.",
    "appointments_selected": "ПРИЙОМИ ОБРАНО",
    "no_appointments_selected": "Не обрано прийоми",
    "vaccines_needed": "Потрібні вакцини",
    "select_x_appointments": "Оберіть {{number}} прийомів.",
    "more": "Більше",
    "less": "Менше",
    "register_next_family_member": "Зареєструвати наступного члена сім'ї",
    "successfully_registered_x_of_y_family_members": "Ви успішно зареєстрували {{x}} з {{y}} членів сім'ї",
    "next_family_member": "Наступний член сім'ї",
    "appointments_abbreviated": "додатки",
    "register_for_clinic": "Register for clinic",
    "select_services": "Select services",
    "person": "Person {{number}}",
    "add_person": "Add another person",
    "registration_confirmation": "Registration confirmation"
  },
  "user_mailer": {
    "verify_email": "Давайте підтвердимо Вашу електронну пошту!",
    "hi_full_name": "Привіт {{full_name}},",
    "verify_email_button": "Підтвердьте електронну пошту",
    "please_verify_email": "Будь ласка, скористайтеся кнопкою нижче, щоб підтвердити свою електронну пошту."
  },
  "services": {
    "dptap_dt": "Дифтерія, правець і кашлюк (DTaP) /АКДП / Дифтерія та правець",
    "hepatitis_a": "Гепатит А",
    "hepatitis_b": "Гепатит B",
    "hepatitis_a_b": "Гепатит А та B",
    "hib": "Гемофільна паличка типу b",
    "mmr": "Кір, паротит, краснуха",
    "meningococcal": "Менінгококова інфекція",
    "mpox": "Віспа",
    "pneumococcal": "Пневмококова інфекція",
    "polio": "Поліомієліт",
    "rsv": "Респіраторно-синцитіальний вірус",
    "rotovirus": "Ротавірус",
    "zoster": "Зостер (оперізувальний лишай)",
    "tetanus_diptheria": "Правець та дифтерія",
    "tdap": "АКДП",
    "typhoid": "Черевний тиф",
    "varicella": "Вітряна віспа",
    "covid-19": "COVID-19",
    "covid-19_adult": "COVID-19 (дорослий)",
    "covid-19_adult_description": "Для осіб віком від 12 років.",
    "covid-19_children": "COVID-19 (Дитячий)",
    "covid-19_children_description": "Для дітей віком від 4 до 11 років.",
    "covid-19_infants": "COVID-19 (Для немовлят)",
    "covid-19_infants_description": "Для дітей віком від 6 місяців до 3 років.",
    "influenza": "Грип",
    "mmrv": "Кір, паротит, краснуха та вітряна віспа"
  },
  "deep_archived": {
    "admin": "З міркувань безпеки даних, дані в групі %{назва_групи_тесту} ({{slug}}) архівуються і більше не можуть бути переглянуті або змінені з цієї сторінки. Будь ласка, зв'яжіться зі своїм менеджером зв'язків з клієнтами або support@primary.health, якщо Вам потрібна підтримка.",
    "participant": "Ця сторінка більше не доступна. Будь ласка, зверніться до своїх контактів з медичної кампанії:  {{test_group_name}}. Якщо у Вас виникли проблеми з переглядом старого запису, зверніться за допомогою до support@primary.health."
  }
}