import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

import ClinicsMap from './ClinicsMap';
import Icon from '../../common/components/AssetIcon';
import LocalizedMarkdown from '../../Registration/components/registration/fields/LocalizedMarkdown';
import { isMobile } from '../../ImmunizationStatus/components/UploadRecords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { faAngleUp } from '@fortawesome/pro-solid-svg-icons';

const DESCRIPTION_MAX_HEIGHT = 75;

const AppointmentSlotGroupCard = ({ appointmentSlotGroup, t }) => {
  const [descriptionMaxHeight, setDescriptionMaxHeight] = useState(`${DESCRIPTION_MAX_HEIGHT}px`);
  const [descriptionCurrentHeight, setDescriptionCurrentHeight] = useState(null);

  useEffect(() => {
    const height = document.getElementById("location-description-box").offsetHeight;
    setDescriptionCurrentHeight(height);
  }, [descriptionMaxHeight]);

  return (
    <Card body className="my-4 appointment_slot_group_card">
      <Row className="gx-5">
        <Col lg={3} xs={12}>
          <ClinicsMap
            zoomControl={false}
            markers={[appointmentSlotGroup]}
          />
        </Col>
        <Col lg={9} xs={12}>
          <div className={`inter medium mb-2 big ${isMobile() && 'mt-3'}`}>
            {appointmentSlotGroup?.title}
          </div>
          <div className="text-muted">
            <Icon
              className="me-1"
              path="/images/icons/icon-location-marker.svg"
              style={{ width: 14 }}
            />
            {appointmentSlotGroup?.address}
          </div>
          <div className="row">
            <div
              className="col-10 mt-3"
              id="location-description-box"
              style={{ maxHeight: descriptionMaxHeight, overflowY: "hidden" }}
            >
              <h6 className="mb-1">Clinic details:</h6>
              <LocalizedMarkdown
                container={appointmentSlotGroup}
                stringKey="description"
              />
            </div>
            {descriptionCurrentHeight >= DESCRIPTION_MAX_HEIGHT && (
              <div className="col-2 text-end mt-3 pt-4">
                <a
                  className={`py-3 body-medium-bold inter bold pointer text-muted`}
                  onClick={() => {
                    const newHeight = descriptionMaxHeight === `${DESCRIPTION_MAX_HEIGHT}px`
                      ? ""
                      : `${DESCRIPTION_MAX_HEIGHT}px`;
                    setDescriptionMaxHeight(newHeight);
                  }}
                >
                  {descriptionMaxHeight === `${DESCRIPTION_MAX_HEIGHT}px`
                    ? <FontAwesomeIcon icon={faAngleDown} />
                    : <FontAwesomeIcon icon={faAngleUp} />
                  }
                </a>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default AppointmentSlotGroupCard;
