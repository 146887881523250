import React, { useState } from "react";
import { Button, Col, Dropdown, Form, Row } from "react-bootstrap";
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import {
  faEllipsis,
  faArrowRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { hasPermissionToResultTest, renderResultInterfaceLink, saveAndGoToResultInterface, saveTestGroupUrlToLocalStorage } from '../UpdateTestForm';
import { printPdfLabelOnClick } from '../PdfLabelPrinter';
import TooltipWrapper from '../../../common/components/TooltipWrapper';
import Wrapped from '../../../common/components/Wrapped';
import { zplCodeOnClick } from '../ZplCodePrinter';

const ActionsDropdown = ({
  editableTestTypes,
  isGroupOnDemandOnly,
  permissionKinds,
  submitTests,
  setRedirectCallbackPath,
  test,
}) => {

  const hasPermissionToResult = hasPermissionToResultTest(permissionKinds, editableTestTypes, test);

  const redirectToResultInterface = () => renderResultInterfaceLink(
    test.test_configuration?.service_specification,
    test,
    saveAndGoToResultInterface,
    submitTests,
    saveTestGroupUrlToLocalStorage,
    setRedirectCallbackPath,
    isGroupOnDemandOnly
  );

  if (!test || (!test.administered_at && !redirectToResultInterface)) return <span></span>;

  return (
    <span className="ms-3">
      <Button
        variant="outline-tertiary"
        data-test-hook="edit-dropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        style={{
          height: "40px",
          width: "40px",
          minWidth: "40px",
          maxWidth: "40px",
          minHeight: "40px",
          maxHeight: "40px",
          padding: "0px",
        }}
      >
        <FontAwesomeIcon icon={faEllipsis} />
      </Button>
      <ul className="dropdown-menu dropdown-menu-end">
        {!!test.administered_at && (
          <Dropdown.Item
            data-test-hook="edit"
            href={test.case_report_path}
            target="_blank"
          >
            <FontAwesomeIcon icon={faPen} className="me-1" /> Edit
          </Dropdown.Item>
        )}
        {redirectToResultInterface && (
          <Wrapped
            WrapperComponent={TooltipWrapper}
            wrapIf={!hasPermissionToResult}
            componentProps={{
              tooltipContent: 'You do not have permission to log results.',
            }}
          >
            <Dropdown.Item
              onClick={hasPermissionToResult && redirectToResultInterface}
              className={!hasPermissionToResult && 'text-muted'}
            >
              <FontAwesomeIcon icon={faArrowRight} className="me-1" />
              Log results
            </Dropdown.Item>
          </Wrapped>
        )}
      </ul>
    </span>
  );
};

const GenericTestCardBody = ({
  test,
  submitTests,
  updateTest,
  ...props
}) => {

  const [notes, setNotes] = useState(test.notes);

  const handleBlur = async (e) => {
    const response = await submitTests([{...test, notes: e.target.value}]);
    if (response.data.success) {
      console.log("Notes saved");
    } else {
      console.log("Something went wrong while saving the notes");
    }
  };

  const handleClickOnPrintLabel = () => {
    if (!test.saved) return;

    if (test.label_code == "zebra_label_code") {
      zplCodeOnClick(test, updateTest);
    } else {
      printPdfLabelOnClick(test, updateTest, submitTests);
    }
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label>Barcode</Form.Label>
            <Form.Control
              disabled={true}
              value={test.uid}
              data-test-hook={`barcode-${test.uid}`}
            />
          </Form.Group>
        </Col>
        <Col>
          <div className="d-flex justify-content-end">
            <Button
              disabled={!test.saved}
              onClick={handleClickOnPrintLabel}
            >
              Print label
            </Button>
            <ActionsDropdown
              submitTests={submitTests}
              test={test}
              {...props}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Form.Group>
          <Form.Label>Notes</Form.Label>
          <Form.Control
            as="textarea"
            onBlur={handleBlur}
            onChange={(e) => setNotes(e.target.value)}
            value={notes}
          />
        </Form.Group>
      </Row>
    </div>
  );
};

export default GenericTestCardBody;
