
export const vaccinationStatus = (status) => {
  if (["not_vaccinated", "exempt_with_no_vaccination"].includes(status)){
    return ({class: "text-secondary", key: 'vaccination_status.not_vaccinated'})
  }
  if (["partially_vaccinated", "exempt_with_partial_vaccination"].includes(status)){
    return ({class: "text-warning", key: 'vaccination_status.partially_vaccinated'})
  }
  if (["fully_vaccinated", "fully_vaccinated_with_booster", "fully_vaccinated_with_second_booster"].includes(status)) {
    return ({class: "text-success", key: 'vaccination_status.fully_vaccinated'})
  }
  return ({class: "text-muted", key: "No status" })
}