import React, { useState } from 'react';

import MultiplexSubTestRow from './MultiplexSubTestRow';

const MultiplexSubTests = ({
  results,
  negativeHighlight,
}) => {
  return results.map((result, index) => {
    return <MultiplexSubTestRow key={index} {...{...result, negativeHighlight}}></MultiplexSubTestRow>;
  });
};

export default MultiplexSubTests;
