import React from 'react';

export default ({currentStep, steps}) => {
  return (
    <ul className="self-serve-indicators">
      {steps.map((s, i) => {
        const isActive = currentStep === i;
        if (i === steps.length - 1) {
          return <>
            <li className={isActive ? "active" : null}>{s}</li>
          </>
        } else {
          return <>
            <li className={isActive ? "active" : null}>{s}</li>
            <li className="self-serve-spacer">&nbsp;</li>
          </>
        }
      })}
    </ul>
  );
};
