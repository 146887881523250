import { parseISO, differenceInCalendarDays } from 'date-fns';

export const isValidPair = (appointmentSlot, followUpAppointmentSlot, followUpMinimumDays, followUpMaximumDays) => {
  const diff = calendarDaysBetweenAppointmentSlots(appointmentSlot, followUpAppointmentSlot);
  return diff >= followUpMinimumDays && diff <= followUpMaximumDays;
}

export const toDateString = (appointmentSlot) => {
  const dateString = appointmentSlot.localized_day;
  if (dateString === undefined) {
    console.error("Date string undefined: ", appointmentSlot)
  }
  return dateString
}

export const calendarDaysBetweenDates = (date1, date2) => {
  return differenceInCalendarDays(parseISO(date1), parseISO(date2));
}

export const calendarDaysBetweenAppointmentSlots = (appointmentSlot, followUpAppointmentSlot) => {
  return calendarDaysBetweenDates(toDateString(followUpAppointmentSlot), toDateString(appointmentSlot));
}

const appointmentSlotsWithFallback = (appointmentSlotGroup) => {
  return appointmentSlotGroup.appointment_slots || [];
}

export default class Slotter {
  constructor(testGroup) {
    this.testGroup = testGroup;
    this.appointmentSlotGroups = testGroup.appointment_slot_groups;

    this.availableDatesByTestConfigurationId = {};
    this.pairedDatesCacheByTestConfigurationId = {};

    // We need to keep 2 other caches for testGroup.only_one_location_for_follow_up
    this.availableDatesByTestConfigurationIdAndLocationId = {};
    this.pairedDatesCacheByTestConfigurationIdAndLocationId = {};

    this.appointmentSlotGroups.forEach(appointmentSlotGroup => {
      const testConfigurationId = appointmentSlotGroup.follow_up_test_configuration_id;
      if (testConfigurationId === undefined) return;

      if (!(testConfigurationId in this.availableDatesByTestConfigurationId)) {
        this.availableDatesByTestConfigurationId[testConfigurationId] = {};
        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId] = {};
      }

      if (!(appointmentSlotGroup.id in this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId])) {
        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id] = {};
      }
      if (!(testConfigurationId in this.pairedDatesCacheByTestConfigurationId)) {
        this.pairedDatesCacheByTestConfigurationId[testConfigurationId] = {};
        this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId] = {};
      }
      if (!(appointmentSlotGroup.id in this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId])) {
        this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id] = {};
      }

      (appointmentSlotsWithFallback(appointmentSlotGroup)).forEach(appointmentSlot => {
        const date = toDateString(appointmentSlot);
        this.availableDatesByTestConfigurationId[testConfigurationId][date] = true;

        this.availableDatesByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id][date] = true;
      });
    });
    window.availableDatesByTestConfigurationIdAndLocationId = this.availableDatesByTestConfigurationIdAndLocationId;
  }

  availableSlotsForAppointmentSlotGroup(selectedAppointmentSlotGroup, twoDose=true) {
    const appointmentSlots = appointmentSlotsWithFallback(selectedAppointmentSlotGroup);
    if (selectedAppointmentSlotGroup.follow_up_required && this.testGroup.only_show_first_dose_appointment_slots_with_pair && twoDose) {
      // return paired slots
      return appointmentSlots.filter(appointmentSlot => this.canPair(selectedAppointmentSlotGroup, appointmentSlot));
    } else {
      return appointmentSlots;
    }
  }

  /*
  Returns a list of follow up appointment slot groups
  */
  followUpAppointmentSlotGroups(selectedAppointmentSlotGroup, selectedAppointmentSlot=null) {
    if (this.testGroup.only_one_location_for_follow_up) {
      return [selectedAppointmentSlotGroup];
    }
    return this.appointmentSlotGroups.filter(appointmentSlotGroup => {
      const hasSameTestConfiguration = selectedAppointmentSlotGroup.follow_up_test_configuration_id === appointmentSlotGroup.follow_up_test_configuration_id;
      let hasSlots = true;
      if (selectedAppointmentSlot) {
        hasSlots = this.followUpAppointmentSlots(selectedAppointmentSlot, appointmentSlotGroup).length > 0
      }
      return hasSameTestConfiguration && hasSlots
    })
  }

  /*
  Returns a list of follow up appointment slots
  */
  followUpAppointmentSlots(
    selectedAppointmentSlot,
    selectedFollowUpAppointmentSlotGroup
  ) {
    return (appointmentSlotsWithFallback(selectedFollowUpAppointmentSlotGroup)).filter(followUpAppointmentSlot => {
      return isValidPair(
        selectedAppointmentSlot,
        followUpAppointmentSlot,
        selectedFollowUpAppointmentSlotGroup.follow_up_minimum_days,
        selectedFollowUpAppointmentSlotGroup.follow_up_maximum_days,
      );
    });
  }

  canPair(appointmentSlotGroup, appointmentSlot) {
    // testGroup.only_one_location_for_follow_up
    const testConfigurationId = appointmentSlotGroup.follow_up_test_configuration_id;
    const appointmentSlotDate = toDateString(appointmentSlot);
    let cache;
    if (this.testGroup.only_one_location_for_follow_up) {
      cache = this.pairedDatesCacheByTestConfigurationIdAndLocationId[testConfigurationId][appointmentSlotGroup.id];
    } else {
      cache = this.pairedDatesCacheByTestConfigurationId[testConfigurationId];
    }

    if (appointmentSlotDate in cache) {
      return cache[appointmentSlotDate];
    }
    cache[appointmentSlotDate] = this.computeCanPair(appointmentSlotGroup, appointmentSlotDate)
    return cache[appointmentSlotDate];
  }

  computeCanPair(appointmentSlotGroup, appointmentSlotDate) {
    let availableDates;
    if (this.testGroup.only_one_location_for_follow_up) {
      availableDates = this.availableDatesByTestConfigurationIdAndLocationId[appointmentSlotGroup.follow_up_test_configuration_id][appointmentSlotGroup.id];
    } else {
      availableDates = this.availableDatesByTestConfigurationId[appointmentSlotGroup.follow_up_test_configuration_id];
    }

    for (let date in availableDates) {
      const diff = calendarDaysBetweenDates(date, appointmentSlotDate)
      if (diff >= appointmentSlotGroup.follow_up_minimum_days && diff <= appointmentSlotGroup.follow_up_maximum_days) {
        return true;
      }
    }
    return false;
  }
}
