const UPDATEABLE_VALUES = [
    "first_name",
    "middle_name",
    "last_name",
    "phone_number",
    "email",
    "gender_string",
    "address_1",
    "address_2",
    "state",
    "postal_code",
    "city",
    "county",
    "ethnicity_blob.hispanic",                    //bool
    "ethnicity_blob.not_hispanic",                // bool
    "ethnicity_blob.unknown",                     // bool
    "language_preference",
    "self_described_gender",
    "phone_number_unreachable",                   // bool
    "sex_at_birth",
    "sexual_orientation",
    "pronoun",
    "date_of_birth_datetime(1i)",                 // int
    "date_of_birth_datetime(2i)",                 // int
    "date_of_birth_datetime(3i)",                 // int
    "appointment.chosen_test_configurations",     // array[]: {test_configuration_id, quantity }
    "recommended_kits",                           // array[]: :test_strip.:idx.:kit_type,  "test_strip.1.B" < Last being the test_strip type
]

const ARRAY_ANSWERS = [
  "appointment.chosen_test_configurations",
  "recommended_kits",   
]

const getNestedValue = (str, obj) => {
  return str.split('.').reduce((acc, k) => acc && acc[k], obj);
}

export const updateValuesFromSurvey = (values, surveyAnswers, setFieldValue) => {
  Object.keys(surveyAnswers).forEach(answerKey => {
    if (!answerKey.startsWith("custom_survey.")) return;

    const key = answerKey.split("custom_survey.")[1].replace(/^\d*\./, "");

    if (!UPDATEABLE_VALUES.includes(key)) return;

    let answers = surveyAnswers[answerKey];

    if (ARRAY_ANSWERS.includes(key)) {
      const originalAnswer = getNestedValue(key, values) || [];
      try {
        answers = originalAnswer.concat(answers.map(answer => JSON.parse(answer))).flat(Infinity); 
      } catch {
        answers = originalAnswer.concat(answers).flat(Infinity); 
      }
    }
    setFieldValue(key, answers);
  })
}

export const showFieldOnRegistration = (testGroup, field) =>
  testGroup.fields_requested.includes(field) ||
  testGroup.required_attributes[field] ||
  testGroup.fields_required.includes(field);

export const handleAddressChange = ({address1, city, state, county, postalCode}, setFieldValue, prefix="") => {
  setFieldValue(`${prefix}address_1`, address1.trim());
  setFieldValue(`${prefix}city`, city.trim());
  setFieldValue(`${prefix}state`, state.trim());
  setFieldValue(`${prefix}county`, county?.trim());
  setFieldValue(`${prefix}postal_code`, postalCode.trim());
}

export const geocode = (address, setFieldValue) => {
  if (!address) return;
  var geocoder = new google.maps.Geocoder();
  geocoder.geocode({ address }, function(data) {
    if (!data) {
      return;
    }
    var lat = data[0].geometry.location.lat();
    var lng = data[0].geometry.location.lng();
    setFieldValue('lat', lat);
    setFieldValue('lng', lng);
  });
}

export const onAutocompleteChange = (e, setFieldValue, prefix='', withGeocode=true) => {
  if (e.target.address) {
    let address1 =  [e.target.address.houseNumber, e.target.address.street].filter(e => !!e).join(" "),
    state = e.target.address.stateCode;
    handleAddressChange({address1, ...e.target.address, state}, setFieldValue, prefix)
    if (withGeocode) geocode(e.target.value, setFieldValue);
    return; 
  } 
  setFieldValue(`${prefix}address_1`, e.target.value);
};
