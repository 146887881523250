import React, { useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import styled from 'styled-components';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { Calendar } from 'react-multi-date-picker';
import { DateObject } from 'react-multi-date-picker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faSlidersUp } from '@fortawesome/pro-solid-svg-icons';

import EditServiceSpecifications from "./EditServiceSpecifications";
import AutocompleteLocation from '../../PrimaryDesignSystem/AutocompleteLocation';
import Select from '../../common/components/Select';
import ServiceSpecificationBadge from './ServiceSpecificationBadge';
import ServiceSpecificationSelector from './ServiceSpecificationSelector';
import {
  FamilyOrIndividualButton,
  RADIUS_OPTIONS,
  REGISTRATION_TYPE_OPTIONS,
} from './IndividualOrFamily';
import { findClinicAsync } from '../helpers/requests';
import { isMobile } from '../../ImmunizationStatus/components/UploadRecords';

const FilterBarMobileContainer = styled.div`
  position: relative;

  .search_bar_wrapper {
    overflow-x: scroll;
    margin-bottom: 0.8rem;
    padding-bottom: 1.5rem;
    max-width: 100vw;

    .search_bar {
      display: flex;
      width: max-content;
      align-items: center;
    }

    .next-available {
      position: relative;
      min-width: 240px;
    }

    .filter-icon {
      margin-right: 0.5rem;
      font-size: x-large;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 99;
      background-color: white;
      padding: 4px; 
    }
  }
`;

const EditClinicSearchParams = ({
  dispatch,
  state,
  t,
}) => {
  const { address, radius, registrationType, startOn } = state.formState;
  const [hideCalendar, setHideCalendar] = useState(false);
  const [showCalendarPickerModal, setShowCalendarPickerModal] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const handleClose = () => {
    setShowSearch(false);
    findClinicAsync(state, dispatch);
  }

  if (isMobile()) {
    return (
      <>
        <FilterBarMobileContainer>
          <div className='search_bar_wrapper'>
            <div className='search_bar'>
              <FontAwesomeIcon
                icon={faSlidersUp}
                onClick={() => setShowSearch(true)}
                className='filter-icon'
              />
                <div className="next-available">
                  {!hideCalendar && !startOn && (
                    <FontAwesomeIcon
                      icon={faCalendar}
                      className="text-muted"
                      color="#CBD2E0"
                      style={{
                        position: 'absolute',
                        top: 15,
                        left: 15,
                      }}
                    />
                  )}
                  <FormControl
                    style={{ width: 220 }}
                    placeholder={`     ${t('family_registrations.next_available')}`}
                    onClick={() => {
                      setShowCalendarPickerModal(true);
                    }}
                    value={startOn ? new DateObject(startOn).format() : ""}
                  />
                </div>
                <Row className="ms-2 mt-3">
                  {state.filteredServiceSpecifications.map(
                    (serviceSpecification, idx) => (
                      <Col xs="auto" key={idx} className="mb-4 my-auto">
                        <ServiceSpecificationBadge
                          key={idx}
                          t={t}
                          serviceSpecification={serviceSpecification}
                          onRemove={() =>
                            dispatch({
                              type: 'updateServiceSpecifications',
                              values: {
                                [serviceSpecification.key]: false,
                              },
                            })
                          }
                        />
                      </Col>
                    ),
                  )}
                </Row>
            </div> 
          </div>
          <Modal 
            body
            show={showCalendarPickerModal}
            onHide={() => {
              setShowCalendarPickerModal(false);
              findClinicAsync(state, dispatch);
            }}
            centered
            size="mobile-calendar"
          >
            <h4 className='text-capitalize'>{t('appointment_time')}</h4>
           <Calendar
              shadow={false}
              value={startOn}
              className='mobile-calendar'
              disableYearPicker
              minDate={new Date()}
              onChange={(objectDate) => {
                dispatch({ type: 'updateForm', values: { startOn: objectDate } });
                setShowCalendarPickerModal(false);
                setHideCalendar(true);
                findClinicAsync(state, dispatch);
              }}
            /> 
          </Modal>
        </FilterBarMobileContainer>
        <Modal
          show={showSearch}
          onHide={handleClose}
          size="mobile"
        >
          <h4>{t('family_registrations.appointment_type')}</h4>
          <FamilyOrIndividualButton
            active={registrationType === 'individual'}
            className="me-3 my-2"
            icon="icon-individual"
            label={t('family_registrations.individual_appointment')}
            onClick={() =>
              dispatch({
                type: 'updateForm',
                values: { registrationType: 'individual' },
              })
            }
          />
          <FamilyOrIndividualButton
            active={registrationType === 'family'}
            className="me-3 my-2"
            icon="icon-family"
            label={t('family_registrations.family_appointment')}
            onClick={() =>
              dispatch({
                type: 'updateForm',
                values: { registrationType: 'family' },
              })
            }
          />
          <h4 className='mt-4 mb-3'>{t('registration.location')}</h4>
          <Select
            ariaLabel="radius select"
            className="my-2"
            styles={{
              option: (base) => ({
                ...base,
                borderBottom: `1px solid #E2E7F0`,
                fontWeight: 500,
              }),
            }}
            onChange={(value) => {
              dispatch({
                type: 'updateForm',
                values: {
                  radius: value,
                },
              });
            }}
            options={RADIUS_OPTIONS.map(option => ({
              label: t(option.label, { miles: option.value }),
              value: option.value,
            }))}
            value={radius}
          />
          <AutocompleteLocation
            value={address}
            name="address"
            onChange={(e) => {
              dispatch({
                type: 'updateForm',
                values: {
                  address: e.target.value,
                },
              });
            }}
            className="my-2"
            useHereTech={state.useHereTech}
          />
          <h4 className='mt-4 mb-2'>{t('family_registrations.vaccines')}</h4>
          <div className='d-flex mb-2' style={{flexFlow: "wrap"}}>
            <Row className="my-2">
              {state.filteredServiceSpecifications.map(
                (serviceSpecification, idx) => (
                  <Col xs="auto" key={idx} className="mb-4">
                    <ServiceSpecificationBadge
                      key={idx}
                      t={t}
                      serviceSpecification={serviceSpecification}
                      onRemove={() =>
                        dispatch({
                          type: 'updateServiceSpecifications',
                          values: {
                            [serviceSpecification.key]: false,
                          },
                        })
                      }
                    />
                  </Col>
                ),
              )}
            </Row>
          </div>
          <ServiceSpecificationSelector
            dispatch={dispatch}
            serviceSpecifications={state.serviceSpecifications}
            selectedServiceSpecifications={state.selectedServiceSpecifications}
          />
          <Button className='mt-2' onClick={handleClose}>
            {t('enter_information.search')}
          </Button>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Row>
        <Col md={6} lg={3} xl={3}>
          <Select
            ariaLabel="registration_type select"
            className="my-2 flex-shrink-0"
            icon={registrationType === 'family' && 'icon-family'}
            fontAwesomeIcon={registrationType !== 'family' && faCircleUser}
            onChange={(value) => {
              dispatch({
                type: 'updateForm',
                values: { registrationType: value },
              });
            }}
            options={REGISTRATION_TYPE_OPTIONS.map(option => ({
              label: `${t(option.label)}`,
              value: option.value,
            }))}
            value={registrationType}
          />
        </Col>
        <Col md={6} lg={3} xl={2}>
          <Select
            ariaLabel="radius select"
            className="my-2 flex-shrink-0"
            onChange={(value) => {
              findClinicAsync(state, dispatch, { radius: value });
            }}
            options={RADIUS_OPTIONS.map(option => ({
              label: t(option.label, { miles: option.value }),
              value: option.value,
            }))}
            value={radius}
          />
        </Col>
        <Col md={6} lg={4} xl={5}>
          <div className="update-origin">
            <AutocompleteLocation
              value={address}
              name="address"
              onChange={(e) => {
                dispatch({
                  type: 'updateForm',
                  values: {
                    address: e.target.value,
                  },
                });
                if (!(event instanceof Event)) { // if location selected by click on dropdown, search immediately
                  findClinicAsync(state, dispatch, { address: e.target.value });
                }
              }}
              onEnter={() => findClinicAsync(state, dispatch)}
              className="my-2"
              useHereTech={state.useHereTech}
            />
          </div>
        </Col>
        <Col md={6} lg={2} xl={2}>
          <div className="next-available my-2">
            {!hideCalendar && !startOn && (
              <FontAwesomeIcon
                icon={faCalendar}
                className="text-muted"
                color="#CBD2E0"
                style={{
                  position: 'absolute',
                  top: 15,
                  left: 15,
                }}
              />
            )}
            <DatePicker
              inputClass="form-control"
              placeholder={`     ${t('family_registrations.next_available')}`}
              onOpen={() => setHideCalendar(true)}
              shadow={false}
              value={startOn}
              disableYearPicker
              minDate={new Date()}
              format="MM/DD/YYYY"
              containerStyle={{
                width: '100%',
              }}
              onChange={(objectDate) =>
                dispatch({ type: 'updateForm', values: { startOn: objectDate } })
              }
              onClose={() => {
                findClinicAsync(state, dispatch);
                setHideCalendar(false);
              }}
            />
          </div>
        </Col>
      </Row>
      <EditServiceSpecifications
        dispatch={({values}) => {
          dispatch({
            type: "updateServiceSpecifications",
            values,
          });
          findClinicAsync(state, dispatch, { serviceSpecifications: values });
        }}
        state={state}
      />
    </>
  );
};

export default EditClinicSearchParams;
