import React, { useState, useEffect } from 'react';
import { Modal, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import { SlotGroupsTable } from './TableComponents';
import { DetailCard } from './DaySummary';
import ModalHeader from '../../common/components/ModalHeader';

const SecondDoseSlotsModal = ({
  follow_up_slots_path,
  show,
  onHide,
  doseManagementData,
  setDoseManagementData,
}) => {
  const {
    additional_capacity: additionalCapacity,
    additional_access_key_capacity: additionalAccessKeyCapacity,
  } = doseManagementData;
  const [slotGroups, setSlotGroups] = useState([]);
  const [testConfiguration, setTestConfiguration] = useState(null);

  const fetchSlots = () => {
    axios.get(follow_up_slots_path).then((response) => {
      const { data } = response;
      setSlotGroups(data.slot_groups);
      setTestConfiguration(data.test_configuration);
    });
  };

  useEffect(() => {
    if (show) fetchSlots();
  }, [show]);

  return (
    <Modal
      id="no-shows-modal"
      size="xl"
      show={show}
      onHide={onHide}
      aria-labelledby="no-shows-sizes-title-lg"
    >
      <ModalHeader closeButton onHide={onHide}>
        <Modal.Title as="h2" id="no-shows-sizes-title-lg">
          <div className="d-flex flex-column">
            <span className="text-nowrap">2nd Dose Appointment Slots</span>
            {testConfiguration && (
              <small className="text-muted text-nowrap overflow-hidden">
                {testConfiguration.follow_up_minimum_days} -{' '}
                {testConfiguration.follow_up_maximum_days} days
              </small>
            )}
          </div>
        </Modal.Title>
      </ModalHeader>
      <Modal.Body>
        <Row className="mb-3">
          <Col>
            <DetailCard
              title="Additional Capacity"
              count={additionalCapacity}
              tooltip="Extra Capacity added for this day"
            />
          </Col>
          <Col>
            <DetailCard
              title="Additional Access Key Capacity"
              count={additionalAccessKeyCapacity}
              tooltip="Extra Access Key Capacity added for this day"
            />
          </Col>
        </Row>
        <SlotGroupsTable
          slotGroups={slotGroups}
          doseManagementData={doseManagementData}
          setDoseManagementData={setDoseManagementData}
        />
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-primary" onClick={onHide}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SecondDoseSlotsModal;
