import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { capitalize } from 'lodash';
import {
  Button,
  Container,
  Form,
  Row,
  Col,
  Card,
  Badge,
} from 'react-bootstrap';
import Test from './components/Test';

const TestStripsEdit = ({ test_strip }) => {
  const [testStrip, setTestStrip] = useState(test_strip);
  const [loading, setLoading] = useState(false);
  const { appointment, user, tests } = test_strip;
  const baseTestGroupURL = `/test_groups/${testStrip.test_group.slug}`;

  const submitTestStrip = () => {
    setLoading(true);
    axios
      .put(`/test_strips/${test_strip.id}`, {
        test_strip: {
          barcode: testStrip.barcode,
          external_order_id: testStrip.external_order_id,
          fulfillment: testStrip.fulfillment,
        },
      })
      .then((response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          toastr.success('Successfully updated!');
        } else {
          toastr.error('Something went wrong');
        }
        setLoading(false);
      });
  };

  const sendToConnect = () => {
    setLoading(true);
    axios
      .post(`/test_strips/${testStrip.id}/send_to_connect`)
      .then((response) => {
        window.scrollTo(0, 0);
        if (response.status === 200) {
          toastr.success('Successfully updated!');
        } else {
          toastr.error('Something went wrong');
        }
        setLoading(false);
      })
      .catch((error) => {
        toastr.error('Something went wrong');
        console.error(error);
        setLoading(false);
      });
  };

  return (
    <Container>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Overview</Card.Title>
          <Row>
            <Col>
              <div>
                <a
                  href={`${baseTestGroupURL}/participants/${testStrip.user.id}/edit`}
                >
                  {user.first_name} {user.last_name_string}
                </a>
              </div>
              <div>
                DOB: {user.date_of_birth} (age: {user.age})
              </div>
              <div>Phone number: {user.phone_formatted}</div>
              <div>Email: {user.email}</div>
            </Col>
            <Col>
              <div>
                Test group:{' '}
                <a href={`${baseTestGroupURL}/test_group_users`}>
                  {testStrip.test_group.name}
                </a>
              </div>
              <div>
                Appointment ID:{' '}
                <a
                  href={`${baseTestGroupURL}/appointments/${appointment.id}/edit`}
                >
                  {appointment.id}
                </a>
              </div>
              {appointment.external_id && (
                <>
                  <div>
                    External type:{' '}
                    {appointment.external_type === 'woo_commerce'
                      ? 'WooCommerce'
                      : appointment.external_type}
                  </div>
                  <div>External ID: {appointment.external_id}</div>
                </>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Test kit</Card.Title>
          <Row>
            <Col>
              {testStrip.administered ? (
                <Badge
                  className="badge-pill me-2"
                  style={{ color: '#006EA7', backgroundColor: '#E5F3FA' }}
                >
                  Administered
                </Badge>
              ) : (
                <Badge
                  className="badge-pill m-2"
                  style={{ color: '#AA2222', backgroundColor: '#FFCCCC' }}
                >
                  Not administered
                </Badge>
              )}
              <Badge
                className="badge-pill me-2"
                style={{ color: '#197642', backgroundColor: '#CAFFE1' }}
              >
                {capitalize(testStrip.status)}
              </Badge>
            </Col>
            <Col className="text-end">
              <Button
                className="btn btn-outline-secondary"
                disabled={!!testStrip.external_order_id}
                onClick={sendToConnect}
              >
                Send to Connect
              </Button>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col>
              <Form.Label>Barcode</Form.Label>
              <Form.Control
                value={testStrip.barcode || ''}
                onChange={(e) => {
                  setTestStrip({ ...testStrip, barcode: e.target.value });
                }}
              />
            </Col>
            <Col>
              <Form.Label>Fulfillment</Form.Label>
              <Form.Control
                as="select"
                value={testStrip.fulfillment.toString()}
                onChange={(e) => {
                  const updatedFulfillmentValue = e.target.value === 'true';
                  setTestStrip({
                    ...testStrip,
                    fulfillment: updatedFulfillmentValue,
                  });
                }}
              >
                <option value="true">True</option>
                <option value="false">False</option>
              </Form.Control>
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Label>External order ID</Form.Label>
              <Form.Control
                value={testStrip.external_order_id || ''}
                onChange={(e) => {
                  setTestStrip({
                    ...testStrip,
                    external_order_id: e.target.value,
                  });
                }}
              />
            </Col>
            <Col>
              <Form.Label>Status</Form.Label>
              <Form.Control
                value={capitalize(testStrip.status) || ''}
                disabled={true}
              />
            </Col>
          </Row>
          <Row className="my-4">
            <Col>
              <Form.Label>Administered at</Form.Label>
              <Form.Control
                value={testStrip.administered_at || ''}
                disabled={true}
              />
            </Col>
            <Col>
              <Form.Label>Created at</Form.Label>
              <Form.Control
                value={testStrip.created_at || ''}
                disabled={true}
              />
            </Col>
            <Col>
              <Form.Label>Updated at</Form.Label>
              <Form.Control
                value={testStrip.updated_at || ''}
                disabled={true}
              />
            </Col>
          </Row>
          {testStrip.fulfillment &&
            (testStrip.inbound_shipment || testStrip.outbound_shipment) && (
              <Row className="my-4">
                {testStrip.inbound_shipment && (
                  <Col>
                    <Form.Label>Inbound shipment tracking ID</Form.Label>
                    <Form.Control
                      value={testStrip.inbound_shipment.value || ''}
                      disabled={true}
                    />
                  </Col>
                )}
                {testStrip.outbound_shipment && (
                  <Col>
                    <Form.Label>Outbound shipment tracking ID</Form.Label>
                    <Form.Control
                      value={testStrip.outbound_shipment.value || ''}
                      disabled={true}
                    />
                  </Col>
                )}
              </Row>
            )}
        </Card.Body>
      </Card>
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Tests</Card.Title>
          {tests.map(({ test }, i) => {
            return (
              <Test test={test} key={i} baseTestGroupURL={baseTestGroupURL} />
            );
          })}
        </Card.Body>
      </Card>
      <Row>
        <Col>
          <Button
            variant="link"
            onClick={() => {
              window.location.href = '/test_strips';
            }}
          >
            Back
          </Button>
        </Col>
        <Col className="text-end">
          <Button disabled={loading} onClick={submitTestStrip}>
            Update
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default TestStripsEdit;
