import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import '../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';

import {
  buildCustomizedLabel,
  buildLocalizedString,
} from '../../common/locales/langs';
import { loadAppointmentSlots } from '../../common/utils';
import RegistrationContext from '../contexts/RegistrationContext';
import AppointmentSlot, {
  validateAppointmentSlot,
} from './registration/AppointmentSlot';
import ConfirmAppointment from './registration/ConfirmAppointment';
import Consent, { validateConsent } from './registration/Consent';
import ContactInformation, {
  onAfterStepValidateContactInformation,
  validateContactInformation,
  validatePhoneAndEmailInformation,
} from './registration/ContactInformation';
import CustomSurvey, { validateSurvey } from './registration/CustomSurvey';
import Dependents, { validateDependents } from './registration/Dependents';
import EmploymentInformation, {
  validateEmploymentInformation,
} from './registration/EmploymentInformation';
import PersonalInformation, {
  validatePersonalInformation,
} from './registration/PersonalInformation';
import RegistrationText from './registration/RegistrationText';
import RegistrationCore from './RegistrationCore';
import Symptoms from './registration/Symptoms';
import Confirmation from './registration/Confirmation';
import {
  Briefcase,
  Home,
  Questionnaire,
  PaperPlane,
  Consent as ConsentIcon,
  Location,
  TestTube,
  CircleCheck,
  Shield,
  UserCircle,
} from '../../common/components/Icons/registration';
import VerifyContactInformation from './registration/VerifyContactInformation';
import PhoneAndEmail from './registration/PhoneAndEmail';

const sectionList = (
  { check_duplicate_appointments: checkDuplicateAppointments, ...testGroup },
  initialValues,
  localFormState,
) => {
  const sections = [];
  (buildLocalizedString(testGroup.population, 'registration_text') ||
    buildLocalizedString(testGroup, 'registration_text')) &&
    sections.push({
      view: () => <RegistrationText />,
      header: 'registration.information',
      name: 'registration_text',
      validate: () => {},
      Icon: Home,
    });

  sections.push({
    view: (props) => <ContactInformation {...props} />,
    header: buildCustomizedLabel(
      testGroup.population,
      'registration.contact_information',
    ),
    Icon: UserCircle,
    validate: validateContactInformation,
    name: 'contact_information',
    onAfterStep: checkDuplicateAppointments
      ? onAfterStepValidateContactInformation
      : null,
  });

  testGroup.verify_contact_information &&
    sections.push({
    view: (props) => <PhoneAndEmail {...props} />,
    header: "Contact",
    Icon: PaperPlane,
    validate: validatePhoneAndEmailInformation,
    name: 'phone_and_email',
    onAfterStep: null,
  });

  testGroup.verify_contact_information &&
    sections.push({
      header: 'registration.verify_contact_information',
      view: (props) => (
        <VerifyContactInformation {...props} />
      ),
      validate: () => { },
      name: 'verify_contact_information',
      Icon: Shield,
    });

  testGroup.show_personal_information &&
    sections.push({
      view: (props) => <PersonalInformation {...props} />,
      header: buildCustomizedLabel(
        testGroup.population,
        'registration.personal_information',
      ),
      validate: localFormState.isWaitlisting
        ? () => {}
        : validatePersonalInformation,
      name: 'personal_information',
      Icon: Questionnaire,
    });

  testGroup.request_employment_information &&
    sections.push({
      header: 'registration.employment_information.label',
      view: ({
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        employment_statuses: employmentStatuses,
      }) => (
        <EmploymentInformation
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          employmentStatuses={employmentStatuses}
        />
      ),
      validate: validateEmploymentInformation,
      name: 'employment_information',
      Icon: Briefcase,
    });

  Object.keys(testGroup.medical_screening_survey || {}).length &&
    sections.push({
      header: 'registration.medical_screening',
      view: ({ values, errors, setFieldValue }) => (
        <CustomSurvey
          customSurvey={testGroup.medical_screening_survey}
          valuePath="appointment.survey"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
          title='registration.medical_screening'
        />
      ),
      validate: validateSurvey.bind(
        null,
        'appointment.survey',
        testGroup.medical_screening_survey,
      ),
      name: 'medical_screening_survey',
      Icon: Questionnaire,
    });

  testGroup.show_custom_survey &&
    sections.push({
      header: 'registration.custom_survey',
      view: ({ values, errors, setFieldValue }) => (
        <CustomSurvey
          customSurvey={testGroup.custom_survey}
          valuePath="appointment.questionnaire"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      ),
      validate: validateSurvey.bind(
        null,
        'appointment.questionnaire',
        testGroup.custom_survey,
      ),
      name: 'questionnaire',
      Icon: Questionnaire,
    });

  Object.keys(testGroup.test_group_user_survey).length &&
    sections.push({
      header: 'registration.test_group_user_survey',
      view: ({ values, errors, setFieldValue }) => (
        <CustomSurvey
          customSurvey={testGroup.test_group_user_survey}
          valuePath="test_group_user.survey"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
      ),
      validate: validateSurvey.bind(
        null,
        'test_group_user.survey',
        testGroup.test_group_user_survey,
      ),
      name: 'test_group_user_survey',
      Icon: Questionnaire,
    });

  initialValues.appointment.appointment_slots_by_dates && !initialValues.appointment.on_demand && // cdphe family registration
    sections.push({
      header: 'registration.confirm_appointment',
      view: (props) => <ConfirmAppointment {...props} />,
      validate: () => { },
      name: 'confirm_appointment',
      Icon: Questionnaire,
    });

  sections.push({
    header: 'registration.consent',
    view: (props) => <Consent {...props} />,
    validate: validateConsent,
    name: 'consent',
    Icon: ConsentIcon,
  });

  (!initialValues.appointment.appointment_slot_id ||
    initialValues.review_user_information) &&
      sections.push({
        header: 'registration.location',
        view: (props) => <AppointmentSlot alwaysCollapse {...props} />,
        validate: localFormState.isWaitlisting
          ? () => {}
          : validateAppointmentSlot,
        name: 'appointment_time',
        Icon: Location,
      });

  testGroup.show_symptoms_page &&
    sections.push({
      header: 'registration.symptoms',
      view: ({
        values,
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        symptoms,
      }) => (
        <Symptoms
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          symptoms={symptoms}
        />
      ),
      validate: () => {},
      name: 'symptoms',
      Icon: TestTube,
    });

  testGroup.allow_dependents &&
    sections.push({
      header: 'registration.household',
      view: (props) => <Dependents {...props} />,
      validate: validateDependents,
      name: 'dependents',
    });

  testGroup.show_confirmation_page &&
    sections.push({
      header: 'registration.confirmation',
      view: (props) => <Confirmation {...props} />,
      validate: () => {},
      name: 'confirmation',
      Icon: CircleCheck,
    });

  return testGroup.metadata &&
    typeof testGroup.metadata === 'object' &&
    'form_order' in testGroup.metadata
    ? sections.sort((a, b) => {
        const aVal = testGroup.metadata['form_order'].indexOf(a.name);
        const bVal = testGroup.metadata['form_order'].indexOf(b.name);

        if (aVal < bVal) return -1;
        if (bVal > aVal) return 1;
        return 0;
      })
    : sections;
};

const Registration = (props) => {
  const { t, i18n } = useTranslation();
  const { user: initialValues, test_group: testGroup } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const onSubmitText = props.payment_required
    ? t('payment.continue_label')
    : t('registration.continue_button');

  const [localFormState, setLocalFormState] = useState({
    isWaitlisting: false,
    isLanguageInterpreterYes: !props.user.language_preference,
    isLanguageInterpreterNo: false,
    describeGender: props.user.gender_string === 'prefer_to_self_describe',
    countryCodes: props.country_codes,
    employeeID: urlParams.get('user_employee_id'),
    showUserAlreadyExistsModal: false,
    alreadyShownDuplicateUserModal: false,
    overlappingContactInformation: { email: null, phone_number: null },
    key:
      initialValues.appointment.registration_access_key || urlParams.get('key'),
    accessCode: urlParams.get('access_code'),
    errorMessage: null,
    loggedIn: props.logged_in,
    continueKey: {},
  });
  const sections = sectionList(testGroup, initialValues, localFormState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const jumpToConfirmationPage = urlParams.get('review_user_information') && testGroup.show_confirmation_page;
  const [currentRegistrationStep, setCurrentRegistrationStep] = useState(jumpToConfirmationPage ? sections.findIndex((s) => s.name === 'confirmation') : 0);
  const [appointmentSlotGroupsLoaded, setAppointmentSlotGroupsLoaded] =
    useState(false);

  const loadAppointmentSlotGroups = () => {
    if (
      testGroup.appointment_slot_groups.length < 20 ||
      testGroup.only_show_first_dose_appointment_slots_with_pair
    ) {
      loadAppointmentSlots({
        accessKey: localFormState.key,
        testGroupSlug: testGroup.slug,
        onLoad: (data) => {
          testGroup.appointment_slot_groups = data.appointment_slot_groups;
          setAppointmentSlotGroupsLoaded(true);
        },
        accessCode: localFormState.accessCode,
      });
    }
  };

  const context = {
    testGroup,
    isSubmitting,
    setIsSubmitting,
    submitButtonDisabled,
    setSubmitButtonDisabled,
    localFormState,
    setLocalFormState,
    currentRegistrationStep,
    setCurrentRegistrationStep,
    appointmentSlotGroupsLoaded,
    sectionsLength: sections.length,
    currentSectionHeader: sections[currentRegistrationStep].header,
    sections: sections,
    assignedAppointmentSlotGroupTitle: initialValues.appointment.assigned_appointment_slot_group_title,
  };

  const onSubmit = (values, bag) => {
    if (
      (currentRegistrationStep === sections.length - 1 ||
      localFormState.isWaitlisting) && !isSubmitting
    ) {
      setIsSubmitting(true);
      let testGroupUrl;
      if (currentRegistrationStep === sections.length - 1)
        testGroupUrl = testGroup.submit_registration_url;
      if (localFormState.isWaitlisting === true)
        testGroupUrl = testGroup.waitlist_url;

      // Need to reformat the values somewhat to fit API.
      const valuesToSubmit = cloneDeep(values);
      const attributes = values.dependents_attributes;
      const dependentAttributes = attributes.reduce((obj, item, idx) => {
        return { ...obj, [idx]: item };
      }, {});
      valuesToSubmit.dependents_attributes = dependentAttributes;
      valuesToSubmit.appointment.population_id = testGroup.population.id;
      valuesToSubmit.appointment.registration_access_key = localFormState.key;
      valuesToSubmit.locale = i18n.language;

      Object.keys(valuesToSubmit.ethnicity_blob).map((key) => {
        valuesToSubmit.ethnicity_blob[key] = ['1', true].includes(
          valuesToSubmit.ethnicity_blob[key],
        )
          ? '1'
          : '0';
      });

      const urlParams = new URLSearchParams(window.location.search);
      setLocalFormState({ ...localFormState, errorMessage: null });

      const afterHooks = urlParams.get("after_hooks") ? JSON.parse(urlParams.get("after_hooks")) : [];
      if (values.recommended_kits) {
        afterHooks.push({ action: "CREATE_RECOMMENDED_KIT", params: { kit_types: values.recommended_kits.map(v => v.split(".").at(-1)) } })
      }
      const params = {
        review_user_information: urlParams.get('review_user_information'),
        after_hooks: afterHooks,
        consent_bypass: urlParams.get('consent_bypass'),
        user: valuesToSubmit,
        access_token: urlParams.get('access_token') || urlParams.get('token'),
      };

      if (urlParams.get('on_behalf_registration')) {
        params['on_behalf_registration'] = true;
      }

      axios
        .post(testGroupUrl + '.json', params)
        .then((response) => {
          window.location.href = response.data.redirect_url || response.request.responseURL
        })
        .catch((err) => {
          setIsSubmitting(false);
          if (
            err.response.data &&
            (err.response.data.error_code === 'collision' || err.response.data.error_code === 'invalid_date')
          ) {
            // Go back to the appointment slot page and show error message
            const errorMessage = err.response.data.error_message;
            setLocalFormState({
              ...localFormState,
              errorMessage: errorMessage,
            });
            toastr.error(errorMessage, null, { timeOut: 30000 });
            // Go back to error page
            const indexOfLocationSelection = sections.findIndex(
              (x) => x.name === 'appointment_time',
            );
            setAppointmentSlotGroupsLoaded(false);
            loadAppointmentSlotGroups();
            setCurrentRegistrationStep(indexOfLocationSelection);
            return;
          }

          const redirect_url =
            window.location.origin + window.location.pathname + '?' + urlParams;
          if (err.response.data && err.response.data.error_message) {
            const errorMessage = err.response.data.error_message;
            urlParams.append('error', errorMessage);
            location.href = redirect_url;
          } else if (
            err.response.status === 406 &&
            err.response.data.resolved
          ) {
            setLocalFormState({
              ...localFormState,
              isWaitlisting: false,
              showUserAlreadyExistsModal: true,
              overlappingContactInformation:
                err.response.data.overlapping_contact_information,
              duplicationType: err.response.data.duplication_type,
            });
          } else {
            urlParams.append('error', 'Something went wrong');
            location.href = redirect_url;
          }
        });
    } else if (sections[currentRegistrationStep].onAfterStep) {
      sections[currentRegistrationStep].onAfterStep(values, context).then(
        (shouldAdvance) => {
          if (shouldAdvance) {
            setCurrentRegistrationStep(currentRegistrationStep + 1);
          }
        },
        () => {
          setCurrentRegistrationStep(currentRegistrationStep + 1);
        },
      );
    } else if (!isSubmitting) {
      setCurrentRegistrationStep(currentRegistrationStep + 1);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('jump_to_consent') === 'true') {
      setCurrentRegistrationStep(
        sections.findIndex((section) => section.name === 'consent'),
      );
    }
  }, []);

  useEffect(loadAppointmentSlotGroups, []);

  return (
    <div className="mb-5">
      <RegistrationContext.Provider value={context}>
        <RegistrationCore
          initialValues={initialValues}
          onSubmit={onSubmit}
          sections={sections}
          registrationProps={props}
          currentRegistrationStep={currentRegistrationStep}
          setCurrentRegistrationStep={setCurrentRegistrationStep}
          onSubmitText={onSubmitText}
        />
      </RegistrationContext.Provider>
    </div>
  );
};

Registration.propTypes = {
  test_group: PropTypes.object.isRequired, // this is passed from the Rails view
  valid_access_key: PropTypes.string, // this is passed from the Rails view
  employment_statuses: PropTypes.array.isRequired, // this is passed from the Rails view
  country_codes: PropTypes.object.isRequired, // this is passed from the Rails view
  symptoms: PropTypes.array.isRequired, // this is passed from the Rails view
  user: PropTypes.object.isRequired, // this is passed from the Rails view
};

export default Registration;
